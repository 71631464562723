import React, { useState, useEffect, useContext } from "react";
import ReactPlayer from "react-player/lazy";
import "./style.css";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { useTheme, useMediaQuery } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import { AllVideoPlayListContext } from "../../Context/UserContext";
import ApiEndPoint from "../../../utils/apiEndPoints";
import { setDefaultHeader, apiCall } from "../../../utils/httpClient";
import { confirmAlert } from "react-confirm-alert";
import { RWebShare } from "react-web-share";
import "react-confirm-alert/src/react-confirm-alert.css";
import loader from "../../../assets/images/logo.png";
import Delete from "../../../assets/Phase2/delete.png";
import {
  GLOBAL_URL,
  VideoPlaylistShareUrl,
  AllImages,
  RemoveDuplicates,
  getImageNameFromResolution,
  MinutesToHoursMinutes,
} from "../../../utils/constants";
import {
  LoaderContext,
  SuggestedVideoListContext,
  UserloggedContext,
  UserPlayListContext,
} from "../../Context/UserContext";
import NoDataAvailable from "../../common/NoDataFound/NoDataAvailable";

function MyCreatePlayListVideoAddBtnSuggested() {
  const location = useLocation();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useContext(LoaderContext);
  const [auth, setAuth] = useContext(UserloggedContext);
  const { id, title } = location.state || {};
  const [suggestedVideoList, setSuggestedVideoList] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [scrollHashMap, setScrollHashMap] = useState(true);
  const [scrollLast, setScrollLast] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [listRefresh, setListRefresh] = useState(true);
  const [playlistVideoLists, setPlaylistVideoLists] =
    useState(UserPlayListContext);
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.up("767"));

  useEffect(() => {
    fetchVideoList();
  }, [id, title]);

  const handleFetchData = () => {
    fetchVideoList();
  };

  async function fetchVideoList() {
    try {
      const params = {
        searchValue: title,
        pageNo: pageNo,
        pageSize: 9,
        playlistId: id,
      };
      const { data } = await apiCall(
        "POST",
        `${ApiEndPoint.SUGGESTEDVIDEO}`,
        params
      );
      if (data.length > 0) {
        setIsLoading(false);
        setRefresh(!refresh);
        setListRefresh(true);
        setPageNo(pageNo + 1);
        setSuggestedVideoList(
          RemoveDuplicates(suggestedVideoList.concat(data))
        );
        data.length < 9 && setScrollHashMap(false);
        setScrollLast(true);
      } else {
        setRefresh(!refresh);
        setListRefresh(true);
        setIsLoading(false);
        setScrollLast(false);
      }
    } catch (err) {
      setListRefresh(true);
      setRefresh(!refresh);
      setIsLoading(false);
      setScrollLast(false);
    }
  }

  const addVideoBtn = () => {
    navigate("/myCreatePlayListSelectVideo", { state: { id: id } });
  };

  const addFavorite = async (item, index) => {
    if (auth) {
      suggestedVideoList[index].myFavourite = item.myFavourite ? false : true;
      try {
        const { data } = await apiCall("POST", `/my-favourites/${item.id}`);
        if (data) {
          setRefresh(!refresh);
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
      }
    } else {
      // setGoogleLoginShow(true)
    }
  };

  const videoWatchLater = async (item, index) => {
    if (auth) {
      suggestedVideoList[index].watchLater = item.watchLater ? false : true;
      try {
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.VIDEOWATCHLATER}/${item.id}`
        );
        if (data) {
          setRefresh(!refresh);
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
      }
    } else {
      // setGoogleLoginShow(true)
    }
  };
  const addVideo = async (item, index) => {
    const params = {
      contentId: item.id,
      myPlayList: { id: id },
      contentType: "VIDEO",
    };
    if (auth) {
      try {
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.USERPLAYLSITADDVIDEO}`,
          params
        );
        if (data) {
          setRefresh(!refresh);
          suggestedVideoList[index].isAddedToPlaylist = true;
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
      }
    } else {
      // setGoogleLoginShow(true)
    }
  };

  const RemoveWatchLaterVideo = async (item, index) => {
    if (auth) {
      suggestedVideoList[index].watchLater = item.watchLater ? false : true;
      try {
        const { data } = await apiCall(
          "DELETE",
          `${ApiEndPoint.WATCHLATEDREMOVE}/${item.id}`
        );
        if (data) {
          setRefresh(!refresh);
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
      }
    } else {
      // setGoogleLoginShow(true)
    }
  };

  function takeSubcription(params) {
    params?.subscriptionName
      ? confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="card">
                <div className="info__padding">
                  <h3>
                    {`Please Subscribe to ${params?.subscriptionName} plan to play this video. `}
                  </h3>
                  <h3>{!auth ? "Sign In To Subscribe..." : ""}</h3>
                </div>
                {!auth ? (
                  <>
                    <div className="button__group">
                      <Button
                        onClick={() => {
                          // signIn();
                          onClose();
                        }}
                        variant="contained"
                        className="signBtnHome"
                      >
                        {/* <img className="g-icon" src={Google}></img> */}
                        Sign-in
                      </Button>
                      <button className="no-btn" onClick={onClose}>
                        Cancel
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="button__group">
                      <Button
                        onClick={() => {
                          navigate("/subscriptionPlanList");
                          onClose();
                        }}
                        variant="contained"
                        className="subscriptionBtn"
                        // className="signBtnHome"
                      >
                        {/* <img className="g-icon" src={Google}></img> */}
                        Subscribe
                      </Button>
                      <button className="no-btn" onClick={onClose}>
                        Cancel
                      </button>
                    </div>
                  </>
                )}
              </div>
            );
          },
        })
      : confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="card">
                <div className="info__padding">
                  <h3>{`This Video is not yet available`}</h3>
                </div>
                <div className="button__group">
                  <button className="button btn-ok" onClick={onClose}>
                    Ok
                  </button>
                </div>
              </div>
            );
          },
        });
  }

  return (
    <div className="myCreatedPlaylistVideo">
      <InfiniteScroll
        dataLength={suggestedVideoList.length}
        next={() => handleFetchData()}
        // hasMore={true}
        hasMore={scrollHashMap}
        loader={
          scrollLast ? (
            scrollHashMap && (
              <center>
                <img
                  src={loader}
                  alt="loading..."
                  style={{ height: 25, width: 25, marginTop: 20 }}
                />
              </center>
            )
          ) : (
            <p className="subtitle fancy">
              <span>End of results</span>
            </p>
          )
        }
      >
        <section className="create-playlist-video">
          {/*----- Video Player -----*/}
          {/* <div className="create-playlist-video-top-div">
            <img
              src="https://i.ytimg.com/vi/Y_lpphhXBBE/sddefault.jpg"
              style={{ width: "100%", height: 450 }}
            />

    
            <div
              onClick={() => navigate(-1)}
              className="create-playlist-video-top-left backTooltip"
            >
              <img src={AllImages?.BackArrow} alt="Back" title="Back"></img>
              <span className="tooltiptext">{"Back"}</span>
            </div>

           
          </div> */}
      

          <div className="add-any-video-div">
            <p className="add-any-video-txt">You haven’t added any video yet</p>
            <button onClick={() => addVideoBtn()} className="add-video-btn">
              {"add videos"}
            </button>
          </div>

          {/*----- Suggested Video -----*/}
          <div className="suggested-video-div">
            <p className="suggested-video-title">{"Suggested Video"}</p>
            <div className="suggested-video-column-count-3 custom-row">
              {listRefresh && suggestedVideoList.length > 0
                ? suggestedVideoList?.map((item, index) => (
                    <div key={index} className=" custom-col-4 custom-col">
                      <div className="suggested-video-play-card-wrap">
                        <div
                          className="suggested-video-play-inside-wrap"
                          style={{ position: "relative" }}
                        >
                          {item.videoUrl ? (
                            <div className="suggested-video-play-list-image">
                              <img
                                className={`suggested-video-img-banner ${
                                  getImageNameFromResolution(
                                    item?.thumbnails
                                  ) != "maxresdefault" && "image-resolution"
                                }`}
                                src={item.thumbnails}
                                // key={item.id}
                                alt="Card Image"
                                title="Card Image"
                              />
                              <div className="playIconTooltip">
                                <img
                                  className="suggested-video-play-play-icon"
                                  src={AllImages.PlayIcon}
                                  alt="Play Icon"
                                  title="Play Icon"
                                />
                                <span className="tooltiptext">
                                  {"Play Icon"}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="ind-card-play-list-image"
                              style={{ position: "static" }}
                            >
                              <div onClick={() => takeSubcription(item)}>
                                <img
                                  className={`ind-card-img-banner ${
                                    getImageNameFromResolution(
                                      item?.thumbnails
                                    ) != "maxresdefault" && "image-resolution"
                                  }`}
                                  src={
                                    item?.thumbnails
                                      ? `${item?.thumbnails}`
                                      : `${AllImages.DefaultThumbnail}`
                                  }
                                  alt="Card Image"
                                  title="Card Image"
                                />
                                <div className="layerImage"></div>
                              </div>
                              <div className="star1 badgestarInvisible">
                                <div className="LikeCount badgeShow">
                                  <i
                                    className="fa fa-lock"
                                    onClick={() => takeSubcription(item)}
                                  ></i>
                                </div>
                                <span className="tooltiptext">
                                  {"Subscribe for more Videos"}
                                </span>
                              </div>
                            </div>
                          )}
                          <div className="suggested-video-play-card-content-item">
                            <div className="suggested-video-play-video-content">
                              <div>
                                <p className="suggested-video-play-title-text f-poppins">
                                  {item.videoTitle}
                                </p>
                                <p className="suggested-video-play-text-hrs f-poppins">
                                  {MinutesToHoursMinutes(item.videoDuration)}{" "}
                                  {item.videoDuration ? "hrs" : null}
                                </p>
                              </div>
                              <div className="suggested-video-mt-10">
                                <div className="d-flex align-center">
                                  <div className="timerTooltip">
                                    <img
                                      onClick={() =>
                                        item?.watchLater
                                          ? RemoveWatchLaterVideo(item, index)
                                          : videoWatchLater(item, index)
                                      }
                                      src={
                                        item?.watchLater
                                          ? AllImages.SelectedTimer
                                          : AllImages.Timer
                                      }
                                      alt="Timer"
                                      title="Timer"
                                    ></img>
                                    <span className="tooltiptext">
                                      {!item.watchLater
                                        ? "Watch Later"
                                        : "Remove Watch Later"}
                                    </span>
                                  </div>
                                  <div className="favTooltip">
                                    <img
                                      src={
                                        item?.myFavourite
                                          ? AllImages.Favorite_sel
                                          : AllImages.Fav
                                      }
                                      onClick={() => addFavorite(item, index)}
                                      alt="Favourite"
                                      title="Favourite"
                                    ></img>
                                    <span className="tooltiptext">
                                      {!item.myFavourite
                                        ? "Mark Favorite"
                                        : "Unfavorite"}
                                    </span>
                                  </div>
                                  <RWebShare
                                    data={{
                                      url: `${VideoPlaylistShareUrl}/videoPlayPage/${item.id}`,
                                      title: "Video Share",
                                    }}
                                    // onClick={() => console.log('share')}
                                  >
                                    <img src={AllImages.Copy}></img>
                                  </RWebShare>
                                </div>
                                <div
                                  onClick={
                                    item?.isAddedToPlaylist
                                      ? null
                                      : () => addVideo(item, index)
                                  }
                                >
                                  <p className="suggested-video-add-btn">
                                    {item.isAddedToPlaylist ? "Added" : "Add"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : !IsLoading && <NoDataAvailable />}
            </div>
          </div>
        </section>
      </InfiniteScroll>
    </div>
  );
}
export default MyCreatePlayListVideoAddBtnSuggested;
