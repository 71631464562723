import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

import { GLOBAL_URL } from "./constants";

const httpClient = axios.create({
  baseURL: `${GLOBAL_URL}/`,
});

export function setDefaultHeader(header, value) {
  httpClient.defaults.headers.common[header] = value;
}

export async function apiCall(
  method,
  url,
  data,
  header = { "Content-Type": "application/json" }
) {
  try {
    const response = await httpClient({
      method,
      url,
      data,
      headers: header,
      withCredentials: false,
    });
    // console.log(`${url}: `, response);
    if (response.status === 200) {
      return response;
    }
    if (response.status === 201) {
      return response;
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        await localStorage.removeItem("userData");
        await localStorage.removeItem("userToken");
        await localStorage.removeItem("searchData");
        window.location.assign("/");
        toast.error(error.response, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return error.response;
      } else if (error.response.status === 500) {
        await localStorage.removeItem('userData');
        await localStorage.removeItem('userToken');
        await localStorage.removeItem('searchData');
        window.location.assign('/')
        toast.error(error?.response?.data?.title, {
            position: toast.POSITION.TOP_RIGHT
        });
      } else if (error.response.status === 403) {
        await localStorage.removeItem("userData");
        await localStorage.removeItem("userToken");
        await localStorage.removeItem("searchData");
        window.location.assign("/");
        toast.error(error?.response?.data?.title, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else if (error.request) {
      toast.error(error.request, {
        position: toast.POSITION.TOP_RIGHT,
      });
      // console.log('Error request : ', error.request);
    } else {
      // console.log('Error message : ', error.message);
    }
    return false;
  }
}
