import React, { useEffect, useState, useContext, useLayoutEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { AllVideoPlayListContext } from "../../Context/UserContext";
import { Typography, Box, Grid, ListItem, List } from "@material-ui/core";
import Tabs from "@mui/material/Tabs";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import ApiEndPoint from "../../../utils/apiEndPoints";
import { setDefaultHeader, apiCall } from "../../../utils/httpClient";
import loader from "../../../assets/images/logo.png";
import Delete from "../../../assets/Phase2/delete.png";
import useGoogleLoginFlow from "components/common/useGoogleLoginFlow";
import { confirmAlert } from "react-confirm-alert";
import Button from "@material-ui/core/Button";
import Google from "../../../assets/images/google.png";
import { CiCircleList } from "react-icons/ci";
import { CiGrid2V } from "react-icons/ci";
import {
  GLOBAL_URL,
  AllImages,
  RemoveDuplicates,
  DateTimeAgo,
  VideoPlaylistShareUrl,
  emojiRegex,
} from "../../../utils/constants";
import {
  LoaderContext,
  UserloggedContext,
  UserPlayListContext,
  EBooksListContext,
} from "../../Context/UserContext";
import "./style.css";
import ss from "../../../assets/Patrank-62-SinglePage.epub";
// import { Document, Page, pdfjs } from "react-pdf";
// import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { ReactReader } from "react-reader";
import NoDataAvailable from "../../common/NoDataFound/NoDataAvailable";
import { toast } from "react-toastify";
import { RWebShare } from "react-web-share";

const SortingOptions = {
  NEWLY_ADDED: "NEWLY_ADDED",
  PREVIOUSLY_ADDED: "PREVIOUSLY_ADDED",

  LAST_MODIFIED_DATE: "LAST_MODIFIED_DATE",
  Sort_A_To_Z: "A_TO_Z",
  Sort_Z_To_A: "Z_TO_A",
};
const EBooks = (props) => {
  // pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [auth, setAuth] = useContext(UserloggedContext);
  const { viewMore } = location.state || {};
  const [eBooksListData, setEBooksListData] = useContext(EBooksListContext);
  const [userPlayList, setUserPlayList] = useContext(UserPlayListContext);
  const [pageNo, setPageNo] = useState(0);
  const [scrollHashMap, setScrollHashMap] = useState(viewMore);
  const [scrollLast, setScrollLast] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [playlistName, setPlaylistName] = useState("");
  const [logo, setLogo] = useState(null);
  const [logoPreview, setLogoPreview] = useState("");
  const [isValid, setIsValid] = useState(true);
  const path = location.pathname;
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [isListView, setIsListView] = useState(false);
  const {
    openRecaptcha,

    recaptchaFun,

    onChange,
  } = useGoogleLoginFlow();
  const [pdfShow, setPdfShow] = useState(false);
  const [readFile, setReadFile] = useState(null);
  const [isOpenSortBy, setIsOpenSortBy] = useState(false);
  const [epubcifi, setEpubcifi] = useState();

  const [searchText, setSearchText] = useState(null);

  const [selectedFilterOp, setSelectedFilterOp] = useState(
    SortingOptions.NEWLY_ADDED
  );

  const [numPages, setNumPages] = useState(null);
  const [selectedWord, setSelectedWord] = useState("");
  const [tabValue, setTabValue] = useState("ebook");
  useLayoutEffect(() => {
    fetchData();
  }, [tabValue, selectedFilterOp]);

  const readPdfFile = (item) => {
    setPdfShow(true);
    setReadFile(item.contentUrl);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const fetchData = () => {
    setScrollHashMap(true);
    (tabValue === "ebook"||tabValue === "transcript") ? fetchEbooksList(1) : fetchEbooksSeriesList(1);
  };

  // const fetchData = () => {
  //   setScrollHashMap(true);

  //   fetchEbooksList(1);
  // };
  const fetchMoreData = () => {
    (tabValue === "ebook"||tabValue === "transcript") ? fetchEbooksList() : fetchEbooksSeriesList();
  };

  const handleTextSelection = () => {
    if (window.getSelection) {
      const selectedText = window.getSelection().toString();

      setSelectedWord(selectedText);
    }
  };

  async function fetchEbooksList(fetchPage = 0) {
    setIsLoading(true);
    try {
      const params = {
        pageNo: fetchPage ? fetchPage - 1 : pageNo,
        pageSize: 20,
        searchValue: searchText,
        sortColumn: selectedFilterOp,
        sortDirection: "",
        ebook:tabValue=='ebook'?true:false
      };

      const { data } = await apiCall(
        "POST",
        `${
          auth
            ? ApiEndPoint.GETEBOOKSLISTFORLOGGEDINUSER
            : ApiEndPoint.GETEBOOKSLISTFORNONLOGGEDINUSER
        }`,
        params
      );
      if (data.length > 0) {
        setIsLoading(false);

        setEBooksListData(
          RemoveDuplicates(fetchPage ? data : eBooksListData.concat(data))
        );
        setPageNo(fetchPage ? fetchPage : pageNo + 1);

        setScrollHashMap(data.length == 20);
        setScrollLast(true);
      } else {
        setIsLoading(false);
        fetchPage && setEBooksListData([]);
        setScrollLast(false);
        setScrollHashMap(false);
        setPageNo(0);
      }
    } catch (err) {
      setIsLoading(false);
      setScrollLast(false);
      setScrollHashMap(false);
    }
    isOpenSortBy && closeModalSortBy();
  }

  async function fetchEbooksSeriesList(fetchPage = 0) {
    setIsLoading(true);
    try {
      const params = {
        pageNo: fetchPage ? fetchPage - 1 : pageNo,
        pageSize: 20,
        searchValue: searchText,
        sortColumn: selectedFilterOp,
      };

      const { data } = await apiCall(
        "POST",
        `${
          auth ? ApiEndPoint.EBOOKSERIESLOGIN : ApiEndPoint.EBOOKSERIESNONLOGIN
        }`,
        params
      );
      if (data.length > 0) {
        setIsLoading(false);
        setPageNo(fetchPage ? fetchPage : pageNo + 1);

        setEBooksListData(
          RemoveDuplicates(fetchPage ? data : eBooksListData.concat(data))
        );
        // setEBooksListData(fetchPage ? data : (prev) => [...prev, ...data]);

        setScrollHashMap(data.length == 20);
        setScrollLast(true);
      } else {
        setIsLoading(false);
        fetchPage && setEBooksListData([]);
        setScrollLast(false);
        setScrollHashMap(false);
        setPageNo(0);
      }
    } catch (err) {
      setIsLoading(false);
      setScrollLast(false);
      setScrollHashMap(false);
    }
    isOpenSortBy && closeModalSortBy();
  }

  async function myFavouritePlaylist(item, index) {
    try {
      if (auth) {
        if (item.contentType == "Transcript") {
          eBooksListData[index].myFavouriteTranscript =
            item.myFavouriteTranscript ? false : true;
        } else if (item.contentType == "Patra") {
          eBooksListData[index].myFavouritePatra = item.myFavouritePatra
            ? false
            : true;
        } else {
          eBooksListData[index].myFavouriteEbook = item.myFavouriteEbook
            ? false
            : true;
        }
        const { data } = await apiCall(
          "POST",
          `${
            item.contentType == "Transcript"
              ? ApiEndPoint.TRANSCRIPTFAVOURITE
              : item.contentType == "Patra"
              ? ApiEndPoint.PATRAFAVOURITE
              : ApiEndPoint.EBOOKFAVOURITE
          }/${item.contentId}`
        );
        if (data.length > 0) {
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      } else {
        takeSubcription();
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }
  async function myFavouriteEbooksRemove(item, index) {
    try {
      if (auth) {
        if (item.contentType == "Transcript") {
          eBooksListData[index].myFavouriteTranscript =
            item.myFavouriteTranscript ? false : true;
        } else if (item.contentType == "Patra") {
          eBooksListData[index].myFavouritePatra = item.myFavouritePatra
            ? false
            : true;
        } else {
          eBooksListData[index].myFavouriteEbook = item.myFavouriteEbook
            ? false
            : true;
        }
        const { data } = await apiCall(
          "DELETE",
          `${
            item.contentType == "Transcript"
              ? ApiEndPoint.DELETEFAVOURITETRANSCRIPT
              : item.contentType == "Patra"
              ? ApiEndPoint.DELETEFAVOURITEPATRA
              : ApiEndPoint.DELETEFAVOURITE
          }/${item.contentId}`
        );
        if (data.length > 0) {
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }

  async function myFavouriteEbookSeries(item, index) {
    try {
      if (auth) {
        eBooksListData[index].favourite = item.favourite ? false : true;
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.MYFAVOURITEADDEBOOKSERIES}/${item.id}`
        );
        if (data.length > 0) {
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      } else {
        takeSubcription();
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }

  async function myFavouriteEbookSeriesRemove(item, index) {
    try {
      if (auth) {
        eBooksListData[index].favourite = item.favourite ? false : true;
        const { data } = await apiCall(
          "DELETE",
          `${ApiEndPoint.MYFAVOURITEREMOVEEBOOKSERIES}/${item.id}`
        );
        if (data.length > 0) {
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }
  async function downloadsEbooks(item, index) {
    try {
      if (auth) {
        const params = {
          ebooksContents: item.id,
        };
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.EBOOKDOWNLOAD}`,
          params
        );
        if (data.length > 0) {
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }

  const handleLocationChanged = (epubcfi) => {
    setEpubcifi(epubcfi);
  };

  function handleSelectionChange(selection) {}
  function getFileExtension(url) {
    const parts = url.split(".");
    return parts[parts.length - 1];
  }
  const handleRadioChange = (event) => {
    setSelectedFilterOp(event.target.value);
  };

  const openModalSortBy = () => {
    setIsOpenSortBy(true);
  };

  const closeModalSortBy = () => {
    setIsOpenSortBy(false);
  };
  function capitalizeFirstWord(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  function handleSearchPlaylist(e) {
    if (!emojiRegex.test(e.target.value)) {
      if (e.target.value) {
        setSearchText(e.target.value);
      } else {
        setSearchText(null);
      }
    }
  }
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchData();
    }
  };

  const handleDownloadClick = async (item, index) => {
    try {
      if (auth) {
        const params = {
          ebooksContents: item.contentType === "Ebook" ? item.contentId : "",
          patraContents: item.contentType === "Patra" ? item.contentId : "",
          transcriptContent:
            item.contentType === "Transcript" ? item.contentId : "",
        };

        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.EBOOKDOWNLOAD}`,
          params
        );
        if (data.status == 200) {
          fileDownload(item.contentUrl, index);
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      } else {
        takeSubcription();
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  };

  const [fileDownloadLoader, setFileDownloadLoader] = useState(null);
  const fileDownload = async (contentUrl, index) => {
    try {
      setFileDownloadLoader(index);
      let fileUrl = "";
      let fileExtension = getFileExtension(contentUrl);

      switch (fileExtension) {
        case "epub":
        case "pdf":
        case "mp3":
          fileUrl = contentUrl;
          break;
        default:
          console.error("Invalid file type:", fileExtension);
          return;
      }

      const response = await fetch(fileUrl);
      if (!response.ok) {
        setFileDownloadLoader(null);
        throw new Error(`Failed to download file (HTTP ${response.status})`);
      }
      let fileName = contentUrl?.substring(contentUrl.lastIndexOf("/") + 1);

      fileName = fileName?.replace(
        /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/,
        ""
      );

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      setFileDownloadLoader(null);
      // Display a success toast message
      toast.success("File downloaded successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error("Error downloading the file:", error);

      // Display an error toast message
      toast.error("Error downloading the file. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleReadOrPlayClick = (contentUrl, contentType) => {
    if (tabValue == "ebook"||tabValue == "transcript") {
      const newTabUrl = `/fileViewer?contentUrl=${encodeURIComponent(
        contentUrl
      )}`;
      window.open(newTabUrl, "_blank");
    } else {
      navigate(`/eBookSeries/${contentUrl}`);
    }
  };

  const getFavoriteStatus = (item) => {
    if (item.contentType === "Transcript") {
      return item.myFavouriteTranscript;
    } else if (item.contentType === "Patra") {
      return item.myFavouritePatra;
    } else {
      return item.myFavouriteEbook;
    }
  };

  const StyledTab = styled(Tab)({
    "&.Mui-selected": {
      color: "#FFB200",
      fontSize: 15,
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      textTransform: "uppercase",
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 5,
      alignItems: "flex-start",
      marginRight: 15,
      justifyContent: "end",
    },

    "&.MuiTab-root": {
      fontSize: 15,
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      textTransform: "uppercase",
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 5,
      alignItems: "flex-start",
      marginRight: 15,
      justifyContent: "end",
      minWidth: "auto",
    },
  });

  const handleTabChange = (event, newValue) => {
    setEBooksListData([]);
    setTabValue(newValue);
  };
  function takeSubcription() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card">
            <div className="info__padding">
              <h3>{`Please sign in to use this feature. `}</h3>
              {/* <h3>{!auth ? "Sign In" : ""}</h3> */}
            </div>
            {!auth ? (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      onChange({ true: true });
                      onClose();
                    }}
                    variant="contained"
                    className="signBtnHome"
                  >
                    <img
                      style={{ height: "23px", width: "23px" }}
                      className="g-icon"
                      src={Google}
                    ></img>
                    Sign-in
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      navigate("/subscriptionPlanList");
                      onClose();
                    }}
                    variant="contained"
                    className="subscriptionBtn"
                    // className="signBtnHome"
                  >
                    {/* <img className="g-icon" src={Google}></img> */}
                    Subscribe
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        );
      },
    });
  }
  const handleToggleListView = () => {
    setIsListView((prevView) => !prevView);
  };

  return (
    <>
      <div className="ebook-search-div-header-outer">
        {/*----- E-Books Title -----*/}
        <div className="ebook-heading-mt-40">
          <span className="ebook-heading-d-flex">
            <p className="ebook-heading-title">{"E-Books"}</p>
          </span>
        </div>

        {/*----- Search Bar Desktop Start -----*/}
        <div className="ebook-search-div">
        
          <div className="ebook-search-wrap search-wrap">
          
            <div className="ebook-search-input search-input">

              <span className="ebook-search-icon" onClick={fetchData}>
                {isLoading ? (
                  <img
                    src={loader}
                    alt="loading..."
                    style={{ height: 15, width: 15 }}
                  />
                ) : (
                  <div className="searchTooltip">
                    <img src={AllImages.Search} alt="Search" title="Search" />
                    <span className="tooltiptext">{"Search"}</span>
                  </div>
                )}
                {/* <img src={AllImages.Search} alt="Search" title="Search" /> */}
              </span>
              <input
                className="f-poppins"
                type="text"
                placeholder="Search for E-Books"
                onChange={(e) => handleSearchPlaylist(e)}
                onKeyUp={handleKeyPress}
              />
            </div>
            <div className="searchIcon-outer">
              <img
                src={AllImages.SearchIcon}
                alt="Search"
                title="Search"
                className="searchIcon"
              />
            </div>
            <div
              className="ebook-filter-icon sortbyTooltip"
              onClick={() => openModalSortBy()}
            >
              <img src={AllImages.SearchToggle} alt="Search" title="Search" />
              <span className="tooltiptext">{"Sort By"}</span>
            </div>
            <span 
              className="view-toggle"
              onClick={handleToggleListView}
            >
                  {isListView ? (
                <CiGrid2V  strokeWidth={1} size={20} color="#F6AE06" className="viewToggleIcon" />
              ) : (
                <CiCircleList strokeWidth={1}  size={20} color="#F6AE06" className="viewToggleIcon" />
              )}
            </span>
          </div>
        </div>
        {/*----- Search Bar Desktop End -----*/}
      </div>

      <InfiniteScroll
        className="mt-15"
        dataLength={eBooksListData.length}
        next={() => fetchMoreData()}
        // hasMore={true}
        hasMore={scrollHashMap}
        loader={
          scrollLast ? (
            isLoading && (
              <center>
                <img
                  src={loader}
                  alt="loading..."
                  style={{ height: 25, width: 25, marginTop: 20 }}
                />
              </center>
            )
          ) : (
            <p className="subtitle fancy">
              <span>End of results</span>
            </p>
          )
        }
      >
        <div className="my-ebook-tab-div">
          <Box className="my-ebook-tab-a-center">
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#FFB200",
                  minWidth: "auto",
                },
              }}
              aria-label="secondary tabs example"
            >
              <StyledTab value="ebook" label="E-BOOKS" />
              <StyledTab value="transcript" label="TRANSCRIPTS" />

              <StyledTab value="ebook-series" label="E-BOOK SERIES" />
            </Tabs>
          </Box>
        </div>

        {(tabValue == "ebook"||tabValue == "transcript") && (
          <div className={`ebook-outer-wrap custom-row ${isListView ? `listView` : null}`}>
            {eBooksListData.length > 0
              ? eBooksListData?.map((item, index) => (
                  <div key={index} className="custom-col-4 custom-col">
                    <div key={index} className="ebook-card-wrap">
                      <div className="ebook-inside-wrap">
                        <div className="list-image">
                          <img
                            className="img-banner"
                            src={item.thumbnailImageUrl}
                            key={item.id}
                            alt="Card Image"
                          />
                          {/* <img className="ebook-play-icon" src={AllImages?.PlayIcon} alt="play Icon" /> */}
                        </div>
                        <div className="ebook-card-content-item">
                          <div className="ebook-video-content">
                            <div className="ebook-video-content-inner">
                              <p className="ebook-title-text f-poppins">
                                {item.title}
                              </p>
                              <div
                                style={{ gap: "10px" }}
                                className="ebook-textDetails ebook-text-hrs f-poppins"
                              >
                                {/* <p className="ebook-text-hrs">200 pages</p>
                          <img
                            className="dot"
                            src={AllImages?.Dot}
                            alt="dot"
                            title="dot"
                          ></img> */}
                                <p className="ebook-text-hrs">
                                  {item?.pageCount} pages
                                </p>
                                {/* <p className="ebook-text-hrs">•</p>
                          <p className="ebook-text-hrs">
                            {DateTimeAgo(item.publishDateTime)}
                          </p> */}
                              </div>
                              {/* <p className="ebook-text-hrs f-poppins">22</p> */}
                            </div>
                            <div className="ebook-icon-read-div">
                              <div className="d-flex align-center">
                                <RWebShare
                                  data={{
                                    // text: "Like humans, flamingos make friends for life",
                                    url: `${VideoPlaylistShareUrl}/fileViewer?contentUrl=${encodeURIComponent(
                                      item?.contentUrl?.replace(/ /g, "%20")
                                    )}`,
                                    title: "Ebook Share",
                                  }}
                                  onClick={() => console.log("share")}
                                >
                                  <div className="copyTooltip">
                                    <img
                                      src={AllImages.Copy}
                                      alt="Copy"
                                      title="Copy"
                                    ></img>
                                    {/* <span className="tooltiptext">
                                {!item.myFavourite
                                  ? "Mark Favorite"
                                  : "Unfavorite"}
                              </span> */}
                                    <span className="tooltiptext">
                                      {"Copy"}
                                    </span>
                                  </div>
                                </RWebShare>

                                <div className="favTooltip">
                                  <img
                                    onClick={() =>
                                      getFavoriteStatus(item)
                                        ? myFavouriteEbooksRemove(item, index)
                                        : myFavouritePlaylist(item, index)
                                    }
                                    src={
                                      getFavoriteStatus(item)
                                        ? AllImages.Favorite_sel
                                        : AllImages?.Fav
                                    }
                                  ></img>
                                  <span className="tooltiptext">
                                    {!getFavoriteStatus(item)
                                      ? "Mark Favorite"
                                      : "Unfavorite"}
                                  </span>
                                </div>
                                {fileDownloadLoader == index ? (
                                  <img
                                    src={loader}
                                    alt="loading..."
                                    style={{ height: 20, width: 20 }}
                                  />
                                ) : (
                                  <div className="downaloadTooltip">
                                    <img
                                      onClick={() =>
                                        handleDownloadClick(item, index)
                                      }
                                      src={AllImages.Download}
                                      alt="Download"
                                      title="Download"
                                    ></img>
                                    <span className="tooltiptext">
                                      {"Download"}
                                    </span>
                                  </div>
                                )}
                              </div>

                              <div
                                className="readTooltip"
                                onClick={() =>
                                  handleReadOrPlayClick(item.contentUrl)
                                }
                              >
                                <p className="ebook-read">Read</p>
                                <span className="tooltiptext">{"Read"}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : !isLoading && <NoDataAvailable />}
          </div>
        )}

        {tabValue == "ebook-series" && (
          <div className={`ebook-outer-wrap custom-row  ${isListView ? `listView` : null}`}>
            {eBooksListData.length > 0
              ? eBooksListData?.map((item, index) => (
                  <div key={index} className="custom-col-4 custom-col">
                    <div key={index} className="ebook-card-wrap">
                      <div className="ebook-inside-wrap">
                        <div className="list-image">
                          <img
                            className="img-banner"
                            src={item.thumbnail}
                            key={item.id}
                            alt="Card Image"
                          />
                          {/* <img className="ebook-play-icon" src={AllImages?.PlayIcon} alt="play Icon" /> */}
                        </div>
                        <div className="ebook-card-content-item">
                          <div className="ebook-video-content">
                            <div className="ebook-video-content-inner">
                              <p className="ebook-title-text f-poppins">
                                {item.playlistName}
                              </p>
                              <div
                                style={{ gap: "10px" }}
                                className="ebook-textDetails ebook-text-hrs f-poppins"
                              >
                                {/* <p className="ebook-text-hrs">200 pages</p>
                          <img
                            className="dot"
                            src={AllImages?.Dot}
                            alt="dot"
                            title="dot"
                          ></img> */}
                                <p className="ebook-text-hrs">
                                  {item?.ebookCount ? item?.ebookCount : 0}{" "}
                                  E-Books
                                </p>
                                {/* <p className="ebook-text-hrs">•</p>
                          <p className="ebook-text-hrs">
                            {DateTimeAgo(item.publishDateTime)}
                          </p> */}
                              </div>
                              {/* <p className="ebook-text-hrs f-poppins">22</p> */}
                            </div>
                            <div className="ebook-icon-read-div">
                              <div className="d-flex align-center">
                                <RWebShare
                                  data={{
                                    // text: "Like humans, flamingos make friends for life",
                                    url: `${VideoPlaylistShareUrl}/eBookSeries/${item.id}`,
                                    title: "Ebook Series Share",
                                  }}
                                  onClick={() => console.log("share")}
                                >
                                  <div className="copyTooltip">
                                    <img
                                      src={AllImages.Copy}
                                      alt="Copy"
                                      title="Copy"
                                    ></img>
                                    {/* <span className="tooltiptext">
                                {!item.myFavourite
                                  ? "Mark Favorite"
                                  : "Unfavorite"}
                              </span> */}
                                    <span className="tooltiptext">
                                      {"Copy"}
                                    </span>
                                  </div>
                                </RWebShare>

                                <div className="favTooltip">
                                  <img
                                    onClick={() =>
                                      item.favourite
                                        ? myFavouriteEbookSeriesRemove(
                                            item,
                                            index
                                          )
                                        : myFavouriteEbookSeries(item, index)
                                    }
                                    src={
                                      item.favourite
                                        ? AllImages.Favorite_sel
                                        : AllImages?.Fav
                                    }
                                  ></img>
                                  <span className="tooltiptext">
                                    {!item.favourite
                                      ? "Mark Favorite"
                                      : "Unfavorite"}
                                  </span>
                                </div>
                              </div>

                              <div
                                className="readTooltip"
                                onClick={() => handleReadOrPlayClick(item.id)}
                              >
                                <p className="ebook-read">Open</p>
                                <span className="tooltiptext">{"Open"}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : !isLoading && <NoDataAvailable />}
          </div>
        )}
      </InfiniteScroll>

      {/* {pdfShow && (
        <Dialog
          open={pdfShow}
          className="modalFull"
          // aria-labelledby="alert-dialog-title"
          // aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            {getFileExtension(readFile) == "pdf" ? (
              <Document
                file={readFile} // Replace with your actual PDF URL
                onLoadSuccess={onDocumentLoadSuccess}
                onItemClick={() => console.log("sss")}
              >
                {Array.from(new Array(numPages), (_, index) => (
                  <Page
                    // key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    // onLoadSuccess={handleTextSelection}
                    onSelectionChange={handleSelectionChange}
                    // onItemClick={() => console.log('sss')}
                    // renderAnnotationLayer={true} // Disable annotation layer for better text selection
                  />
                ))}
              </Document>
            ) : (
              <div
                style={{ position: "relative", height: "100vh", width: 400 }}
              >
                <ReactReader
                  url={ss}
                  title="EPUB Viewer"
                  // locationChanged={(epubcfi) => console.log(epubcfi)}
                  location={epubcifi} // Set the location (EPUB cifi) of the current page
                  locationChanged={handleLocationChanged}
                  fontSize={60}
                />
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <button
              className="my-create-playlist-cancel-btn"
              onClick={() => setPdfShow(false)}
            >
              {"Cancel"}
            </button>
          </DialogActions>
        </Dialog>
      )} */}
      {isOpenSortBy && (
        <Dialog
          open={openModalSortBy}
          className="sortBy"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent
            style={{
              overflowY: "hidden",
              overflowX: "hidden",
            }}
          >
            <div className="qu-history-modal-msg-content">
              <p className="qu-history-modal-msg-text">{"Sort by"}</p>
              <span
                className="qu-history-modal-close-button"
                onClick={() => closeModalSortBy()}
              >
                &times;
              </span>
            </div>
            <ul>
              {Object.keys(SortingOptions).map((key) => (
                <li key={key}>
                  <label className="customRadio">
                    {SortingOptions[key] === "A_TO_Z" ||
                    SortingOptions[key] === "Z_TO_A"
                      ? key.replace(/_/g, " ")
                      : capitalizeFirstWord(
                          key.replace(/_/g, " ").toLowerCase()
                        )}
                    <input
                      type="radio"
                      value={SortingOptions[key]}
                      checked={selectedFilterOp === SortingOptions[key]}
                      onChange={handleRadioChange}
                      name="radio"
                    />
                    <span className="checkmark"></span>
                  </label>
                </li>
              ))}
            </ul>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
export default EBooks;
