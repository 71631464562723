import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import CommonCarousal from "../../common/Carousal/CommonCarousal";
import AllPlayList from "../AllPlayList";
import ApiEndPoint from "../../../utils/apiEndPoints";
import { setDefaultHeader, apiCall } from "../../../utils/httpClient";
import {
  LoaderContext,
  UserloggedContext,
  WatchLaterVideosContext,
  SurpriseMeContext,
  RecentWatchedVideosListContext,
  SurpriseMeDataContext,
  AdvanceSearchTextContext,
  AdvanceSearchTabContext,
  AdvanceFilterValContext,
  AdvanceSearchDataContext,
  SatsangAllLocationContext,
  SatsangAllSpeakersContext,
  userDetailsContext,
  MusicTrackContext,
  MusicTracksContext,
  MusicPlayerVisibilityContext,
  PlayingAlbumIdContext,
  PlayingShuffledAlbumIdContext,
  PlayNextContext,
  CurrentTrackPlayingContext
} from "../../Context/UserContext";
import "./style.css";
import {
  AllImages,
  RemoveDuplicates,
  getImageNameFromResolution,
} from "../../../utils/constants";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ReactPlayer from "react-player/lazy";
import socket from "../../../utils/socket";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box } from "@material-ui/core";
import Slider from "@mui/material/Slider";
import { useTheme, useMediaQuery, TextField } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SubscriptionRenewalModal from "./modal";


function Home2() {
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useContext(LoaderContext);
  const [auth, setAuth] = useContext(UserloggedContext);
  const [watchLaterVideos, setWatchLaterVideos] = useContext(WatchLaterVideosContext);
  const [recentWatchedVideoList, setRecentWatchedVideoList] = useContext(RecentWatchedVideosListContext);
  const [surpriseMeData, setSurpriseMeData] = useContext(SurpriseMeDataContext);
  const [surpriseMeShow, setSurpriseMeShow] = useContext(SurpriseMeContext);
  const [satsangLocations, setSatsangLocations] = useContext(SatsangAllLocationContext);
  const [satsangSpeaker, setSatsangSpeaker] = useContext(SatsangAllSpeakersContext);
  const [refresh, setRefresh] = useState(false);
  const [liveVideo, setLiveVideo] = useState([]);
  const [isValid, setIsValid] = useState(true);
  const [advaceTabValue, setAdvaceTabValue] = useContext(AdvanceSearchTabContext);
  const [advaceFilterVal, setAdvaceFilterVal] = useContext(AdvanceFilterValContext);
  const [advaceData, setAdvaceData] = useContext(AdvanceSearchDataContext);
  const [value, setValue] = React.useState([1, 500]);
  const [playlistValue, setPlaylistValue] = useState([0, 6]);
  const [ebookValue, setEbookValue] = useState([80, 240]);
  const [patraValue, setPatraValue] = useState([80, 240]);
  const [padValue, setPadValue] = useState([80, 240]);
  const [sliderMax, setSliderMax] = useState(180);
  const [sliderMin, setSliderMin] = useState(0);
  const [filterlocation, setFilterLocation] = useState(null);
  const [searchValue, setSearchValue] = useContext(AdvanceSearchTextContext);
  const [isOpenAdvance, setIsOpenAdvance] = useState(false);
  const [inputTitle, setInputTitle] = useState(null);
  const [userDetails, setUserDetails] = useContext(userDetailsContext);
  const [trackDeatilsUsingParams, setTrackDeatilsUsingParam] = useState();
  const { singleTrack, setSingleTrack } = useContext(MusicTrackContext);
  const { track, setTrack } = useContext(MusicTracksContext)
  const { playNextTrack, setPlayNextTrack } = useContext(PlayNextContext)
  const { currentTrackPlaying, setCurrentTrackPlaying } = useContext(CurrentTrackPlayingContext);
  const { playingAlbumId, setPlayingAlbumId } = useContext(PlayingAlbumIdContext);
  const { playingShuffledAlbumId, setPlayingShuffledAlbumId } = useContext(PlayingShuffledAlbumIdContext);
  const [isMusicPlayerVisible, setIsMusicPlayerVisible] = useContext(MusicPlayerVisibilityContext);

  useEffect(() => {
    getVideoList();
    watchLaterVideolist();
    recentWatchVideolist();
  }, []);

  async function getToken() {
    const userData = await localStorage.getItem("userData");
    return userData;
  }

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [type, setType] = useState(null);
  const [filLanguage, setFilLanguage] = useState(null);

  const [filterSpeakers, setFilterSpeakers] = useState(null);

  const getFormatedDate = (date) => {
    if (date) {
      const today = new Date(date);
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;

      const formattedToday = yyyy + "-" + mm + "-" + dd;
      return formattedToday;
    }
  };

  const handleChangeFromDate = (date) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === "VIDEO") {
        return {
          ...item,
          startDate: getFormatedDate(date),
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    setFromDate(date);
  };

  const handleChangeToDate = (date) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === "VIDEO") {
        return {
          ...item,
          endDate: getFormatedDate(date),
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    setToDate(date);
  };

  const handleChangeLanguages = (event) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === "VIDEO") {
        return {
          ...item,
          language: event.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    setFilLanguage(event.target.value);
  };

  const handleChangeType = (event) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === "VIDEO") {
        return {
          ...item,
          videotype: event.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    setType(event.target.value);
  };

  const handleSpeakers = (event) => {
    const params = advaceFilterVal.filter(
      (item) => item.contentType == advaceTabValue
    )[0];
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === "VIDEO") {
        return {
          ...item,
          speaker: event.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    setFilterSpeakers(event.target.value);
  };

  const generateDynamicMarksArray = (start, end, numElements) => {
    const marksArray = [];
    const step = (end - start) / (numElements - 1);

    for (let i = start; i <= end; i += step) {
      marksArray.push({ value: Math.round(i), label: `${Math.round(i)}m` });
    }

    return marksArray;
  };

  

  async function getVideoList() {
    try {
      const { data } = await apiCall("GET", ApiEndPoint.LIVEVIDEO);
      if (data) {
        setLiveVideo(data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);

      
    }
  }

  //Watch later videos
  async function watchLaterVideolist() {
    !watchLaterVideos.length > 0 && setIsLoading(true);
    try {
      if (auth) {
        const params = {
          searchValue: null,
          sortColumn: "LAST_MODIFIED_DATE",
          pageNo: 0,
          pageSize: 10,
        };
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.ALLWATCHLATERVIDEOS}`,
          params
        );
        if (data.length > 0) {
          setWatchLaterVideos(RemoveDuplicates(data));
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setWatchLaterVideos([]);
        }
      }
    } catch (err) {
      setIsLoading(false);
    }
  }
  //recent watched video
  async function recentWatchVideolist() {
    !recentWatchedVideoList.length > 0 && setIsLoading(true);
    try {
      if (auth) {
        const params = {
          searchValue: null,
          sortColumn: "LAST_MODIFIED_DATE",
          pageNo: 0,
          pageSize: 10,
        };
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.RECENTWATCHEDVIDEOLIST}`,
          params
        );
        if (data.length > 0) {
          setRecentWatchedVideoList(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setRecentWatchedVideoList([]);
        }
      }
    } catch (err) {
      setIsLoading(false);
    }
  }

  const addFavorite = async (item, id, index, type) => {
    if (auth) {
      const recentIndex = recentWatchedVideoList.findIndex(
        (item) => item.id === id
      );
      const watchIndex = watchLaterVideos.findIndex((item) => item.id === id);
      if (watchIndex !== -1) {
        setWatchLaterVideos((prevWatchLaterVideos) => {
          const updatedWatchLaterVideos = [...prevWatchLaterVideos];
          updatedWatchLaterVideos[watchIndex].myFavourite =
            !updatedWatchLaterVideos[watchIndex].myFavourite;
          return updatedWatchLaterVideos;
        });
      }

      if (recentIndex !== -1) {
        setRecentWatchedVideoList((prevRecentWatchedVideoList) => {
          const updatedRecentWatchedVideoList = [...prevRecentWatchedVideoList];
          updatedRecentWatchedVideoList[recentIndex].myFavourite =
            !updatedRecentWatchedVideoList[recentIndex].myFavourite;
          return updatedRecentWatchedVideoList;
        });
      }

      setRefresh(!refresh);
      try {
        const { data } = await apiCall("POST", `/my-favourites/${item.id}`);
        if (data) {
          setRefresh(!refresh);
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
      }
    } else {
      // setGoogleLoginShow(true)
    }
  };

  const videoWatchLater = async (item, id, index, type) => {
    if (auth) {
      if (type == "WatchLater") {
        const recentIndex = recentWatchedVideoList.findIndex(
          (item) => item.id === id
        );

        if (recentIndex !== -1) {
          setRecentWatchedVideoList((prevRecentWatchedVideoList) => {
            const updatedRecentWatchedVideoList = [
              ...prevRecentWatchedVideoList,
            ];
            updatedRecentWatchedVideoList[recentIndex].watchLater =
              !updatedRecentWatchedVideoList[recentIndex].watchLater;
            return updatedRecentWatchedVideoList;
          });
        }

        try {
          const { data } = await apiCall(
            "DELETE",
            `${ApiEndPoint.WATCHLATEDREMOVE}/${item.id}`
          );
          if (data) {
            setRefresh(!refresh);
            const removeWatchLatedVieo = watchLaterVideos.filter(
              (x) => x.id != item.id
            );
            setWatchLaterVideos(removeWatchLatedVieo);
            if (removeWatchLatedVieo.length <= 1) {
              watchLaterVideolist();
            }
          } else {
            setRefresh(!refresh);
          }
        } catch (err) {
          setRefresh(!refresh);
          console.log("err: ", err);
        }
      } else {
        if (!item.watchLater) {
          recentWatchedVideoList[index].watchLater = item.watchLater
            ? false
            : true;
          try {
            const { data } = await apiCall(
              "POST",
              `${ApiEndPoint.VIDEOWATCHLATER}/${item.id}`
            );
            if (data) {
              setRefresh(!refresh);
              watchLaterVideolist();
            } else {
              setRefresh(!refresh);
            }
          } catch (err) {
            setRefresh(!refresh);
            console.log("err: ", err);
          }
        } else {
          recentWatchedVideoList[index].watchLater = item.watchLater
            ? false
            : true;
          try {
            const { data } = await apiCall(
              "DELETE",
              `${ApiEndPoint.WATCHLATEDREMOVE}/${item.id}`
            );
            if (data) {
              setRefresh(!refresh);
              watchLaterVideolist();
            } else {
              setRefresh(!refresh);
            }
          } catch (err) {
            setRefresh(!refresh);
            console.log("err: ", err);
          }
        }
      }
    } else {
      // setGoogleLoginShow(true)
    }
  };

  const viewMorePlayListNav = () => {
    navigate("/allPlayList", { state: { viewMore: true } });
  };

  const watchLaterListNav = () => {
    navigate("/watchLater", { state: { viewMore: true } });
  };

  const liveSatsangNav = () => {
    navigate("/liveSatsang", { state: { liveData: liveVideo } });
  };

  const [isOpenSortBy, setIsOpenSortBy] = useState(false);

  const SurpriseMeOpen = () => {
    surpriseMeShow(true);
  };

  const closeModalSortBy = () => {
    setSurpriseMeShow(false);
  };

  const handleReadOrPlayClick = (item) => {
    setSurpriseMeShow(false);
    if (item.type == "video") {
      navigate(`/videoPlayPage/${item.id}`);
    } else {
      const newTabUrl = `/fileViewer?contentUrl=${encodeURIComponent(
        item.contenturl
      )}`;
      window.open(newTabUrl, "_blank");
    }
  };

  //advance search

  const handleLocationChange = (event) => {
    setFilterLocation(event.target.value);
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === "VIDEO") {
        return {
          ...item,
          location: event.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
  };

  const openAdvanceModal = () => {
    // if (searchValue == null || searchValue == '') {
    //   setIsValid(false);
    // } else {
    setIsValid(true);
    setIsOpenAdvance(true);
    // }
  };
  const closeAdvanceModal = () => {
    setIsOpenAdvance(false);
  };

  const uploadDurationArray = [
    {
      value: "TODAY",
      lable: "TODAY",
    },
    {
      value: "THIS_WEEK",
      lable: "This week",
    },
    {
      value: "THIS_MONTH",
      lable: "This month",
    },
    {
      value: "THIS_YEAR",
      lable: "This year",
    },
    {
      value: "THREE_YEARS_AGO",
      lable: "2-3 year ago",
    },
  ];

  const onChangeSlider = (e, value) => {
    const [min, max] = value;
    if (advaceTabValue == "VIDEO") {
      setValue(value);
    } else if (advaceTabValue == "EBOOK") {
      setEbookValue(value);
    } else if (advaceTabValue == "PATRA") {
      setPatraValue(value);
    } else if (advaceTabValue == "SYSTEM_PLAYLIST") {
      setPlaylistValue(value);
    } else if (advaceTabValue == "PAD") {
      setEbookValue(setPadValue);
    }

    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          pageNo: 0,
          pageSize: 10,
          searchValue: searchValue,
          startDuration: min,
          endDuration: max,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
  };

  const handleTitle = (e) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === "VIDEO") {
        return {
          ...item,
          title: e.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    setInputTitle(e.target.value);
  };
  const uploadDurationselect = (value) => {
    const params = advaceFilterVal.filter(
      (item) => item.contentType == advaceTabValue
    )[0];
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          uploadDuration: value == params.uploadDuration ? null : value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      advanceSearch();
    }
  };

  async function advanceSearch(value) {
    // if (searchValue == null || searchValue == '') {
    //   setIsValid(false);
    // } else {
    setIsValid(true);
    const params = advaceFilterVal.filter(
      (item) => item.contentType == advaceTabValue
    )[0];
    const navigateUrl =
      `/advanceSearch/${encodeURIComponent(
        advaceTabValue
      )}/${encodeURIComponent(
        searchValue?.trim() === "" ? null : searchValue
      )}?` +
      `${params.startDuration
        ? `startDuration=${encodeURIComponent(params.startDuration)}`
        : ""
      }` +
      `${params.endDuration
        ? `&endDuration=${encodeURIComponent(params.endDuration)}`
        : ""
      }` +
      `${params.location
        ? `&videolocation=${encodeURIComponent(params.location)}`
        : ""
      }` +
      `${params.startDate
        ? `&startDate=${encodeURIComponent(params.startDate)}`
        : ""
      }` +
      `${params.videotype
        ? `&videotype=${encodeURIComponent(params.videotype)}`
        : ""
      }` +
      `${params.title ? `&title=${encodeURIComponent(params.title)}` : ""}` +
      `${params.endDate ? `&endDate=${encodeURIComponent(params.endDate)}` : ""
      }` +
      `${params.language
        ? `&language=${encodeURIComponent(params.language)}`
        : ""
      }` +
      `${params.speaker ? `&speaker=${encodeURIComponent(params.speaker)}` : ""
      }` +
      `${params.uploadDuration
        ? `&uploadDuration=${encodeURIComponent(params.uploadDuration)}`
        : ""
      }`;
    navigate(navigateUrl);
    // }
  }

  const emptyAllValus = () => {
    setValue([1, 500]);
    setFromDate(null);
    setToDate(null);
    setFilLanguage("");
    setFilterSpeakers("");
    setType("");
    setFilterLocation("");
    closeAdvanceModal();
  };

  const handleSearchBox = (event) => {
    setSearchValue(event.target.value);
  };
  function openLinkInNewTab(url) {
    window.open(url, "_blank");
  }

  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.up("480"));



  const [showModal, setShowModal] = useState(userDetails?.user?.subscriptionRenew === true);

  const handleCloseModal = () => setShowModal(false);

  const handleTitleClick = (item) => {
    setSingleTrack([])
    setTrack([])
    setPlayNextTrack([])
    setPlayingShuffledAlbumId(null)
    setPlayingAlbumId(null)
    setPlayingAlbumId(null);
    let trackList = [];
    trackList[0] = item;
    setTrack(trackList);
    setIsMusicPlayerVisible(true);
  };

  async function fetchDataUsingParams(e) {
    try {
        const res = await apiCall('GET', `${ApiEndPoint.GETTRACKDETAILSBYTRACKID}/${e?.id}`);
        const data = res;
        if (data?.data) {
          const record = data?.data;
          setTrackDeatilsUsingParam(record);
          // handleTitleClick(record);
        } 
    } catch (error) {
      console.error('Error fetching audio tracks:', error);
    }
  }


  const handleNavigation = (data) => {
    fetchDataUsingParams(data);
    setSurpriseMeShow(false);
  };



  return (
    <section className={`home-top${isMusicPlayerVisible ? " ytPlayerOpen home" : ""}`}>
    {/* <section className="home-top"> */}
      {/*----- Home Start -----*/}
      <div>
        <p className="home-title">Home</p>
      </div>
      {/* Renew Subcription model */}
      {(showModal) ?
        <div className="ModalOuter" style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: showModal ? 'rgba(0, 0, 0, 0.5)' : null, zIndex: 1000 }}>
          <div
            style={{
              position: 'absolute',
              top: "45%",
              zIndex: 1,
              left: "40%",
              background: 'white',
              borderRadius: "10px"
            }}
          >
            <div style={{ zIndex: 1 }} >  <SubscriptionRenewalModal isOpen={showModal} onClose={handleCloseModal} /></div>
          </div>
        </div> : ''
      }

      <div className="home-search-box-mobile">
        <div className="home-search-wrap">
          <div className={`search-input ${isValid ? "" : "invalid"}`}>
            <span onClick={() => advanceSearch(1)} className="search-icon">
              <img src={AllImages.Search} alt="Search" title="Search" />
            </span>
            <input
              className="f-poppins"
              type="text"
              placeholder="Search for Satsang,Bhakti Pad,Playlist etc..."
              onChange={handleSearchBox}
              value={searchValue}
              onKeyUp={handleKeyPress}
            />
          </div>
          <div className="searchIcon-outer">
            <img
              src={AllImages.SearchIcon}
              alt="Search"
              title="Search"
              className="searchIcon"
            />
          </div>
          <div className="filter-icon" onClick={() => openAdvanceModal(2)}>
            {/* <img src={AllImages.SearchToggle} alt="" /> */}
            <img src={AllImages.SearchToggle2} alt="Search" title="Search" />
          </div>
        </div>
      </div>

      {/*----- Live Satsang Start -----*/}
      {liveVideo && liveVideo?.liveUrl && (
        <div>
          <p className="live-satsang-title">Live Satsang</p>
        </div>
      )}

      {/*----- Desktop Top Banner Start -----*/}
      {liveVideo && liveVideo?.liveUrl && (
        <div
          onClick={() => liveSatsangNav()}
          className="home-top-main-banner home-top-banner"
        >
          {liveVideo ? (
            <img
              className={`home-top-img-banner home-b-radius-27 ${getImageNameFromResolution(liveVideo?.thumbnails) !=
                "maxresdefault" && "image-resolution"
                }`}
              src={liveVideo?.thumbnails}
              alt="Mask Group"
              title="Mask Group"
            />
          ) : (
            <img
              className={`home-top-img-banner home-b-radius-27 ${getImageNameFromResolution(AllImages?.MaskGroup) !=
                "maxresdefault" && "image-resolution"
                }`}
              src={AllImages?.MaskGroup}
              alt="Mask Group"
              title="Mask Group"
            />
          )}
          <div className="bottom-left titleTooltip">
            <p>{liveVideo ? liveVideo?.title : null}</p>
            <span className="tooltiptext">
              {liveVideo ? liveVideo?.title : null}
            </span>
          </div>
          {/* <div className="top-right copyTooltip">
          <img width={"24px"} height={"24px"}
            src={AllImages?.CopyContent}
            alt="Copy Content Icon"
            title="Copy Cotent"
          />
          <span className='tooltiptext'>
            {"Copy"}
          </span>
        </div> */}
          <div className="bottom-right liveTooltip">
            <img
              width={"60px"}
              height={"30px"}
              src={AllImages?.LiveBackground}
              alt="Live Icon"
              title="Live"
            />
            <div className="live-div">
              <div className="live-bullet"></div>
              <p className="live">Live</p>
            </div>
            <span className="tooltiptext">{"Live"}</span>
          </div>
        </div>
      )}
      {/*----- Desktop Top Banner End -----*/}

      {/*----- Mobile Card Start -----*/}
      {liveVideo && liveVideo?.liveUrl && (
        <div onClick={() => liveSatsangNav()} className="mobile-live-satsang">
          <div className="mobile-live-satsang-wrap">
            <div className="play-list-image">
              {liveVideo ? (
                <img
                  className={`img-banner ${getImageNameFromResolution(liveVideo?.thumbnails) !=
                    "maxresdefault" && "image-resolution"
                    }`}
                  src={liveVideo?.thumbnails}
                  alt="Card Image"
                  title="Card Image"
                />
              ) : (
                <img
                  className={`img-banner ${getImageNameFromResolution(AllImages?.MobileMaskGroup) !=
                    "maxresdefault" && "image-resolution"
                    }`}
                  src={AllImages?.MobileMaskGroup}
                  alt="Card Image"
                  title="Card Image"
                />
              )}
              <img
                className="play-play-icon"
                src={AllImages?.PlayIcon}
                alt="play Icon"
                title="Play Icon"
              />
            </div>

            <div className="mobile-live-satsang-card-content">
              <div className="mobile-live-satsang-play-video">
                <div>
                  <p className="mobile-live-satsang-title f-poppins">
                    {liveVideo ? liveVideo?.title : null}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "5px",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={AllImages?.Copy}
                    alt="Copy"
                    title="Copy"
                    style={{ width: "22px", height: "22px" }}
                  ></img>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        height: "8px",
                        width: "8px",
                        backgroundColor: "#DC1212",
                        borderRadius: "50%",
                        alignSelf: "center",
                      }}
                    ></div>
                    <p
                      style={{
                        color: "#DC1212",
                        fontSize: "14px",
                        textAlign: "right",
                        marginLeft: "5px",
                      }}
                    >
                      Live
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/*----- Recently Watched Start -----*/}
      {recentWatchedVideoList.length > 0 && (
        <div className="container watch-later pl-pr-10">
          <div className="title-wrapper">
            <p className="left f-poppins">Recently Watched</p>
            <p
              className="right f-poppins c-pointer"
              onClick={() => navigate("/recentWatchedVideos")}
            >
              View More
            </p>
          </div>
          <CommonCarousal
            addFavorite={(item, index) =>
              addFavorite(item, item.id, index, "RecentlyWatched")
            }
            carousalVideoList={recentWatchedVideoList}
            videoWatchLater={(item, index) =>
              videoWatchLater(item, item.id, index, "RecentlyWatched")
            }
            type="RecentlyWatched"
          />
        </div>
      )}

      {/*----- Watch Later Start -----*/}
      {watchLaterVideos.length > 0 && (
        <div className="container watch-later pl-pr-10">
          <div className="title-wrapper">
            <p className="left f-poppins">Watch Later</p>
            <p
              className="right f-poppins c-pointer"
              onClick={() => watchLaterListNav()}
            >
              View More
            </p>
          </div>
          <CommonCarousal
            carousalVideoList={watchLaterVideos}
            addFavorite={(item, index) =>
              addFavorite(item, item.id, index, "WatchLater")
            }
            videoWatchLater={(item, index) =>
              videoWatchLater(item, item.id, index, "WatchLater")
            }
            type="WatchLater"
          />
        </div>
      )}

      {/* List of Playlists */}
      <div className="pl-pr-10">
        <div className="title-wrapper">
          <p className="left f-poppins">List of Playlists</p>
          <p
            className="right f-poppins c-pointer"
            onClick={() => viewMorePlayListNav()}
          >
            View More
          </p>
        </div>
      </div>
      <AllPlayList />

      {/* surpriseMeShow */}
      {surpriseMeShow && (
      <Dialog
        open={SurpriseMeOpen}
        className="surpriseMe"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          style={{
            overflowY: "hidden",
            overflowX: "hidden",
          }}
        >
          <div className="qu-history-modal-msg-content">
            {/* Close button */}
            <img
              src={AllImages?.CrossOrange}
              className="crossOrange"
              onClick={() => closeModalSortBy()}
              alt="Close"
              title="Close"
            />

          <div>
            {/* Conditional rendering for READ/PLAY or VIEW ALBUM button */}
            {(surpriseMeData.type === "pad" || surpriseMeData.type === "audio") ? null : (
              <span
                className="read-play-type-btn"
                onClick={() => {
                  if (surpriseMeData.type === "YOUTUBE_TRACK") {
                    handleNavigation(surpriseMeData);
                    setCurrentTrackPlaying(null)
                    navigate(`individualBhaktipad/${surpriseMeData?.id}`);
                  } 
                  else if (surpriseMeData.type === "YOUTUBE_ALBUM") {
                    navigate(`/playlist/${surpriseMeData?.id}`);
                  } else {
                    handleReadOrPlayClick(surpriseMeData);
                  }
                }}
              >
                {surpriseMeData.type === "video" ? "PLAY" : 
                (surpriseMeData.type === "YOUTUBE_TRACK") ? "LISTEN" : 
                (surpriseMeData.type === "YOUTUBE_ALBUM") ? "VIEW ALBUM" : "READ"}
              </span>
            )}

            {(surpriseMeData.type === "pad" ||
              surpriseMeData.type === "audio" ||
              surpriseMeData.type === "YOUTUBE_ALBUM" ||
              surpriseMeData.type === "YOUTUBE_TRACK") && (
              <div style={{ display: "flex", gap: "5px" }}>
                {surpriseMeData?.contentUrlApple && (
                  <img
                    src={AllImages?.appleMusicSvg}
                    className="musicIcons read-play-type-btn"
                    onClick={() =>
                      openLinkInNewTab(surpriseMeData.contentUrlApple)
                    }
                    alt="Apple Music"
                    title="Apple Music"
                  />
                )}
                {surpriseMeData?.contentUrlSpotify && (
                  <img
                    src={AllImages?.spotifyMusicSvg}
                    className="musicIcons read-play-type-btn"
                    onClick={() =>
                      openLinkInNewTab(surpriseMeData.contentUrlSpotify)
                    }
                    alt="Spotify"
                    title="Spotify"
                  />
                )}
                {surpriseMeData?.contentUrlYoutube && (
                  <img
                    src={AllImages?.youtubeMusicSvg}
                    className="musicIcons read-play-type-btn"
                    onClick={() =>
                      openLinkInNewTab(surpriseMeData.contentUrlYoutube)
                    }
                    alt="YouTube Music"
                    title="YouTube Music"
                  />
                )}
              </div>
            )}
          </div>
        </div>

      <div className="surpriseMeThumbnailOuter">
        <img
          src={surpriseMeData?.thumbnailurl}
          className={`surpriseMeThumbnail ${
            surpriseMeData.type === "pad" ||
            surpriseMeData.type === "audio" ||
            surpriseMeData.type === "YOUTUBE_ALBUM" ||
            surpriseMeData.type === "YOUTUBE_TRACK"
              ? ""
              : surpriseMeData.type === "video"
              ? `${getImageNameFromResolution(
                  surpriseMeData?.thumbnailurl
                ) !== "maxresdefault" && "image-resolution"}`
              : "surpriseMeEbbok"
          }`}
          alt={surpriseMeData?.title}
        />
        <p className="surpriseMeThumbnailTitle">{surpriseMeData?.title}</p>
      </div>
    </DialogContent>
  </Dialog>
      )}

      {/* advace search */}
      {isOpenAdvance && (
        <Dialog
          open={openAdvanceModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="filterPopup"
        >
          <DialogContent
            style={{
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {advaceTabValue == "VIDEO" || advaceTabValue == "TRANSCRIPT" || advaceTabValue == "EBOOK_SYSTEM_PLAYLIST"
              || advaceTabValue == "YOUTUBE_TRACK" || advaceTabValue == "YOUTUBE_ALBUM" ? (
              //Video filter
              <div>
                <div className="filter-search-modal-content">
                  <p className="filter-search-modal-msg-text">
                    {"Filter for Video"}
                  </p>
                  <span
                    className="filter-search-modal-close-button"
                    onClick={() => closeAdvanceModal()}
                  >
                    &times;
                  </span>
                </div>

                {/*----- Video Duration -----*/}
                <div className="filter-search-modal-mt-15">
                  <p className="filter-search-modal-comm-font-13">
                    Video Duration
                  </p>
                  <Box sx={{ width: "92%", paddingLeft: "10px" }}>
                    <Slider
                      sx={{
                        "& .MuiSlider-thumb": {
                          color: "#FFB200",
                        },
                        "& .MuiSlider-track": {
                          color: "#FFB200",
                        },
                        "& .MuiSlider-rail": {
                          color: "#212121",
                        },
                        "& .MuiSlider-active": {
                          color: "#212121",
                        },
                      }}
                      valueLabelDisplay="auto"
                      value={value}
                      step={6}
                      marks={generateDynamicMarksArray(1, 500, 6)}
                      min={1}
                      max={500}
                      onChange={onChangeSlider}
                    />
                  </Box>
                </div>

                <div className="filter-search-modal-mt-15">
                  <p className="filter-search-modal-comm-font-13">Title</p>
                  <input
                    className="filter-search-modal-location-div"
                    style={{
                      fontSize: 17,
                      paddingLeft: 8,
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                    value={inputTitle}
                    onChange={(e) => handleTitle(e)}
                  />
                </div>

                {/* fromDate */}
                <div className="filter-search-modal-mt-15 customDate">
                  <p className="filter-search-modal-comm-font-13">From Date</p>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      // label="Date desktop"
                      className="form-control input-font"
                      inputFormat="DD/MM/YYYY"
                      name="fromDate"
                      // minDate={"2022-11-30"}
                      maxDate={new Date().toISOString().slice(0, 10)}
                      value={fromDate}
                      onChange={handleChangeFromDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ width: "100%" }}
                        // value={fromDate}
                        // onChange={handleChangeFromDate}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>

                {/* todateI */}
                <div className="filter-search-modal-mt-15 customDate">
                  <p className="filter-search-modal-comm-font-13">To Date</p>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      // label="Date desktop"
                      className="form-control input-font"
                      inputFormat="DD/MM/YYYY"
                      name="todate"
                      minDate={fromDate}
                      maxDate={new Date().toISOString().slice(0, 10)}
                      value={toDate}
                      onChange={handleChangeToDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="input-font"
                          style={{ width: "100%" }}
                        // value={toDate}
                        // onChange={handleChangeToDate}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>

                {/* Type */}
                <div className="filter-search-modal-mt-15">
                  <p className="filter-search-modal-comm-font-13">Type</p>
                  <div className="filter-search-modal-location-div">
                    <FormControl
                      fullWidth
                      size="small"
                      style={{ paddingTop: "5px" }}
                    >
                      <Select
                        sx={{
                          boxShadow: "none",
                          ".MuiOutlinedInput-notchedOutline": { border: 0 },
                          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            border: 0,
                          },
                          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            border: 0,
                          },
                          ".MuiSvgIcon-root ": {
                            fill: "#FFB200 !important",
                          },
                        }}
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={type?.trim()}
                        onChange={handleChangeType}
                      >
                        <MenuItem value="">Select</MenuItem>
                        <MenuItem value="audio">Audio</MenuItem>
                        <MenuItem value="video">Video</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>

                {/* //Language */}
                <div className="filter-search-modal-mt-15">
                  <p className="filter-search-modal-comm-font-13">Language</p>
                  <div className="filter-search-modal-location-div">
                    <FormControl
                      fullWidth
                      size="small"
                      style={{ paddingTop: "5px" }}
                    >
                      <Select
                        sx={{
                          boxShadow: "none",
                          ".MuiOutlinedInput-notchedOutline": { border: 0 },
                          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            border: 0,
                          },
                          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            border: 0,
                          },
                          ".MuiSvgIcon-root ": {
                            fill: "#FFB200 !important",
                          },
                        }}
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={filLanguage?.trim()}
                        onChange={(e) => handleChangeLanguages(e)}
                      >
                        <MenuItem value="">Select</MenuItem>
                        <MenuItem value="English">English</MenuItem>
                        <MenuItem value="Hindi"> हिंदी</MenuItem>
                        <MenuItem value="Gujrati">ગુજરાતી</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>

                {/* speaker */}
                <div className="filter-search-modal-mt-15">
                  <p className="filter-search-modal-comm-font-13">speaker</p>
                  <div className="filter-search-modal-location-div">
                    <FormControl
                      fullWidth
                      size="small"
                      style={{ paddingTop: "5px" }}
                    >
                      <Select
                        sx={{
                          boxShadow: "none",
                          ".MuiOutlinedInput-notchedOutline": { border: 0 },
                          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            border: 0,
                          },
                          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            border: 0,
                          },
                          ".MuiSvgIcon-root ": {
                            fill: "#FFB200 !important",
                          },
                        }}
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={filterSpeakers?.trim()}
                        onChange={handleSpeakers}
                      >
                        <MenuItem value="">Select</MenuItem>
                        {satsangSpeaker.map((item) => {
                          return <MenuItem value={item}>{item}</MenuItem>;
                        })}
                        {/* <MenuItem value=''></MenuItem>
                        <MenuItem value='Ahmedabad'>Ahmedabad</MenuItem>
                        <MenuItem value='Mumbai'>Mumbai</MenuItem>
                        <MenuItem value='Pune'>Pune</MenuItem>
                        <MenuItem value='india'>India</MenuItem> */}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                {/*----- Location -----*/}
                <div className="filter-search-modal-mt-15">
                  <p className="filter-search-modal-comm-font-13">Location</p>
                  <div className="filter-search-modal-location-div">
                    <FormControl
                      fullWidth
                      size="small"
                      style={{ paddingTop: "5px" }}
                    >
                      <Select
                        sx={{
                          boxShadow: "none",
                          ".MuiOutlinedInput-notchedOutline": { border: 0 },
                          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            border: 0,
                          },
                          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            border: 0,
                          },
                          ".MuiSvgIcon-root ": {
                            fill: "#FFB200 !important",
                          },
                        }}
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={filterlocation?.trim()}
                        onChange={handleLocationChange}
                      >
                        <MenuItem value="">Select</MenuItem>
                        {satsangLocations.map((item) => {
                          return <MenuItem value={item}>{item}</MenuItem>;
                        })}
                        {/* <MenuItem value=''></MenuItem>
                        <MenuItem value='Ahmedabad'>Ahmedabad</MenuItem>
                        <MenuItem value='Mumbai'>Mumbai</MenuItem>
                        <MenuItem value='Pune'>Pune</MenuItem>
                        <MenuItem value='india'>India</MenuItem> */}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            ) : advaceTabValue == "SYSTEM_PLAYLIST" ? (
              //playlists
              <div>
                <div className="filter-search-modal-content">
                  <p className="filter-search-modal-msg-text">
                    {"Filter for Playlist"}
                  </p>
                  <span
                    className="filter-search-modal-close-button"
                    onClick={() => closeAdvanceModal()}
                  >
                    &times;
                  </span>
                </div>

                {/*----- Video Duration -----*/}
                <div className="filter-search-modal-mt-15">
                  <p className="filter-search-modal-comm-font-13">
                    Playlist Duration
                  </p>
                  <Box
                    sx={{
                      width: matchesMD === true ? 310 : 270,
                      paddingLeft: "10px",
                    }}
                  >
                    <Slider
                      sx={{
                        "& .MuiSlider-thumb": {
                          color: "#FFB200",
                        },
                        "& .MuiSlider-track": {
                          color: "#FFB200",
                        },
                        "& .MuiSlider-rail": {
                          color: "#212121",
                        },
                        "& .MuiSlider-active": {
                          color: "#212121",
                        },
                      }}
                      valueLabelDisplay="auto"
                      value={playlistValue}
                      step={1}
                      marks={[
                        { value: 0, label: "0hr" },
                        { value: 2, label: "2hr" },
                        { value: 4, label: "4hr" },
                        { value: 6, label: "6hr" },
                        { value: 8, label: "8hr" },
                        { value: 10, label: "10hr" },
                      ]}
                      min={0}
                      max={10}
                      onChange={onChangeSlider}
                    />
                  </Box>
                </div>

                {/*----- Upload Duration -----*/}
                <div className="filter-search-modal-mt-15">
                  <p className="filter-search-modal-comm-font-13">
                    Upload Duration
                  </p>
                  <div className="filter-search-modal-d-flex filter-search-modal-mt-5">
                    {uploadDurationArray.map((item) => {
                      return (
                        <div
                          className={`filter-search-modal-upload-dur-div filter-search-modal-mlr-8  ${advaceFilterVal.find(
                            (item) => item.contentType === advaceTabValue
                          )?.uploadDuration == item.value
                            ? "active"
                            : null
                            }`}
                          onClick={() => uploadDurationselect(item.value)}
                        >
                          <p className="filter-search-modal-today">
                            {item.lable}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : advaceTabValue == "EBOOK" ? (
              //ebook
              <div>
                <div className="filter-search-modal-content">
                  <p className="filter-search-modal-msg-text">
                    {"Filter for E-Books"}
                  </p>
                  <span
                    className="filter-search-modal-close-button"
                    onClick={() => closeAdvanceModal()}
                  >
                    &times;
                  </span>
                </div>

                {/*----- Video Duration -----*/}
                <div className="filter-search-modal-mt-15">
                  <p className="filter-search-modal-comm-font-13">Pages</p>
                  <Box
                    sx={{
                      width: matchesMD === true ? 310 : 270,
                      paddingLeft: "10px",
                    }}
                  >
                    <Slider
                      sx={{
                        "& .MuiSlider-thumb": {
                          color: "#FFB200",
                        },
                        "& .MuiSlider-track": {
                          color: "#FFB200",
                        },
                        "& .MuiSlider-rail": {
                          color: "#212121",
                        },
                        "& .MuiSlider-active": {
                          color: "#212121",
                        },
                      }}
                      valueLabelDisplay="auto"
                      value={ebookValue}
                      step={6}
                      marks={[
                        { value: 80, label: "80pp" },
                        { value: 160, label: "160pp" },
                        { value: 240, label: "240pp" },
                        { value: 320, label: "320pp" },
                        { value: 400, label: "400pp" },
                        { value: 480, label: "480pp" },
                      ]}
                      min={80}
                      max={480}
                      onChange={onChangeSlider}
                    />
                  </Box>
                </div>

                {/*----- Upload Duration -----*/}
                <div className="filter-search-modal-mt-15">
                  <p className="filter-search-modal-comm-font-13">
                    Upload Duration
                  </p>
                  <div className="filter-search-modal-d-flex filter-search-modal-mt-5">
                    {uploadDurationArray.map((item) => {
                      return (
                        <div
                          className={`filter-search-modal-upload-dur-div filter-search-modal-mlr-8  ${advaceFilterVal.find(
                            (item) => item.contentType === advaceTabValue
                          )?.uploadDuration == item.value
                            ? "active"
                            : null
                            }`}
                          onClick={() => uploadDurationselect(item.value)}
                        >
                          <p className="filter-search-modal-today">
                            {item.lable}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : advaceTabValue == "PATRA" ? (
              //patra
              <div>
                <div className="filter-search-modal-content">
                  <p className="filter-search-modal-msg-text">
                    {"Filter for Patra"}
                  </p>
                  <span
                    className="filter-search-modal-close-button"
                    onClick={() => closeAdvanceModal()}
                  >
                    &times;
                  </span>
                </div>

                {/*----- Video Duration -----*/}
                <div className="filter-search-modal-mt-15">
                  <p className="filter-search-modal-comm-font-13">Pages</p>
                  <Box
                    sx={{
                      width: matchesMD === true ? 310 : 270,
                      paddingLeft: "10px",
                    }}
                  >
                    <Slider
                      sx={{
                        "& .MuiSlider-thumb": {
                          color: "#FFB200",
                        },
                        "& .MuiSlider-track": {
                          color: "#FFB200",
                        },
                        "& .MuiSlider-rail": {
                          color: "#212121",
                        },
                        "& .MuiSlider-active": {
                          color: "#212121",
                        },
                      }}
                      valueLabelDisplay="auto"
                      value={patraValue}
                      step={6}
                      marks={[
                        { value: 80, label: "80pp" },
                        { value: 160, label: "160pp" },
                        { value: 240, label: "240pp" },
                        { value: 320, label: "320pp" },
                        { value: 400, label: "400pp" },
                        { value: 480, label: "480pp" },
                      ]}
                      min={80}
                      max={480}
                      onChange={onChangeSlider}
                    />
                  </Box>
                </div>

                {/*----- Upload Duration -----*/}
                <div className="filter-search-modal-mt-15">
                  <p className="filter-search-modal-comm-font-13">
                    Upload Duration
                  </p>
                  <div className="filter-search-modal-d-flex filter-search-modal-mt-5">
                    {uploadDurationArray.map((item) => {
                      return (
                        <div
                          className={`filter-search-modal-upload-dur-div filter-search-modal-mlr-8  ${advaceFilterVal.find(
                            (item) => item.contentType === advaceTabValue
                          )?.uploadDuration == item.value
                            ? "active"
                            : null
                            }`}
                          onClick={() => uploadDurationselect(item.value)}
                        >
                          <p className="filter-search-modal-today">
                            {item.lable}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : advaceTabValue == "PAD" ? (
              //pad
              <div>
                <div className="filter-search-modal-content">
                  <p className="filter-search-modal-msg-text">
                    {"Filter for Pad"}
                  </p>
                  <span
                    className="filter-search-modal-close-button"
                    onClick={() => closeAdvanceModal()}
                  >
                    &times;
                  </span>
                </div>

                {/*----- Video Duration -----*/}
                <div className="filter-search-modal-mt-15">
                  <p className="filter-search-modal-comm-font-13">
                    Audio Duration
                  </p>
                  <Box
                    sx={{
                      width: matchesMD === true ? 310 : 270,
                      paddingLeft: "10px",
                    }}
                  >
                    <Slider
                      sx={{
                        "& .MuiSlider-thumb": {
                          color: "#FFB200",
                        },
                        "& .MuiSlider-track": {
                          color: "#FFB200",
                        },
                        "& .MuiSlider-rail": {
                          color: "#212121",
                        },
                        "& .MuiSlider-active": {
                          color: "#212121",
                        },
                      }}
                      valueLabelDisplay="auto"
                      value={padValue}
                      step={6}
                      marks={[
                        { value: 0, label: "0m" },
                        { value: 60, label: "60m" },
                        { value: 90, label: "90m" },
                        { value: 120, label: "120m" },
                        { value: 150, label: "150m" },
                        { value: 180, label: "180m" },
                      ]}
                      min={0}
                      max={180}
                      onChange={onChangeSlider}
                    />
                  </Box>
                </div>

                {/*----- Upload Duration -----*/}
                <div className="filter-search-modal-mt-15">
                  <p className="filter-search-modal-comm-font-13">
                    Upload Duration
                  </p>
                  <div className="filter-search-modal-d-flex filter-search-modal-mt-5">
                    {uploadDurationArray.map((item) => {
                      return (
                        <div
                          className={`filter-search-modal-upload-dur-div filter-search-modal-mlr-8  ${advaceFilterVal.find(
                            (item) => item.contentType === advaceTabValue
                          )?.uploadDuration == item.value
                            ? "active"
                            : null
                            }`}
                          onClick={() => uploadDurationselect(item.value)}
                        >
                          <p className="filter-search-modal-today">
                            {item.lable}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : null}
          </DialogContent>
          <DialogActions>
            <div className="filter-search-modal-action-content">
              <button
                className="filter-search-modal-cancel-btn"
                onClick={() => emptyAllValus()}
              >
                {"Clear"}
              </button>
              <button
                className="filter-search-modal-apply-btn"
                onClick={() => advanceSearch(2)}
              >
                {"Apply"}
              </button>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </section>
  );
}
export default Home2;
