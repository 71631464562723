import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { AllVideoPlayListContext } from "../../Context/UserContext";
import ApiEndPoint from "../../../utils/apiEndPoints";
import { setDefaultHeader, apiCall } from "../../../utils/httpClient";
import loader from "../../../assets/images/logo.png";
import Delete from "../../../assets/Phase2/delete.png";
import {
  GLOBAL_URL,
  AllImages,
  RemoveDuplicates,
  emojiRegex,
} from "../../../utils/constants";
import {
  LoaderContext,
  UserloggedContext,
  QuestionHistoryContext,
} from "../../Context/UserContext";
import "./style.css";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import NoDataAvailable from "components/common/NoDataFound/NoDataAvailable";
const SortingOptions = {
  NEWLY_ADDED: "NEWLY_ADDED",
  PREVIOUSLY_ADDED: "PREVIOUSLY_ADDED",

  LAST_MODIFIED_DATE: "LAST_MODIFIED_DATE",
  Sort_A_To_Z: "A_TO_Z",
  Sort_Z_To_A: "Z_TO_A",
};
const QuestionHistory = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [IsLoading, setIsLoading] = useState(false);
  const [auth, setAuth] = useContext(UserloggedContext);
  const { viewMore } = location.state || {};
  const [allVideoPlayList, setAllVideoPlayList] = useContext(
    AllVideoPlayListContext
  );
  const [questionHistoryList, setQuestionHistoryList] = useContext(
    QuestionHistoryContext
  );
  const [pageNo, setPageNo] = useState(0);
  const [scrollHashMap, setScrollHashMap] = useState(viewMore);
  const [scrollLast, setScrollLast] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [selectedFilterOp, setSelectedFilterOp] = useState(
    SortingOptions.NEWLY_ADDED
  );
  const openModal = (id) => {
    setDeleteId(id);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    questionHistoryListFun();
  }, [selectedFilterOp]);
  // const questionHistoryListFun = async (fetchPage = 0)
  const questionHistoryListFun = async (type = 0) => {
    if (auth) {
      try {
        setIsLoading(true);
        const params = {
          sortColumn: selectedFilterOp,
          searchValue: searchText || null,
          pageNo: type ? pageNo + 1 : 0,
          pageSize: 20,
        };
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.QUESTIONHISTORY}`,
          params
        );

        if (data?.length > 0) {
          setIsLoading(false);
          setQuestionHistoryList(
            type ? RemoveDuplicates(questionHistoryList.concat(data)) : data
          );
          setScrollHashMap(data.length == 20);
          setPageNo(type ? pageNo + 1 : 0);

          setScrollLast(true);
        } else {
          setIsLoading(false);
          setScrollLast(false);
          !type && setQuestionHistoryList([]);
          setPageNo(0);
          setScrollHashMap(false);
        }
      } catch (err) {
        setIsLoading(false);
        setScrollLast(false);
        setScrollHashMap(false);
      }
    }
    isOpenSortBy && closeModalSortBy();
  };

  async function questionRemove() {
    try {
      if (auth) {
        const params = {
          questionId: deleteId,
        };

        const data = await apiCall(
          "POST",
          `${ApiEndPoint.QUESTIONDELETE}`,
          params
        );

        if (data.status === 200) {
          const newArray = questionHistoryList.filter(
            (x, i) => x.questionId != deleteId
          );
          setQuestionHistoryList(newArray);
          if (newArray.length <= 1) {
            questionHistoryListFun();
          }
          setDeleteId(null);
          closeModal();
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          closeModal();
          setDeleteId(null);
          setRefresh(!refresh);
          setIsLoading(false);
        }
      }
    } catch (err) {
      closeModal();
      setDeleteId(null);
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }

  const individualPlayListNav = (item, type) => {
    navigate("/individualPlayList", {
      state: { playListDetails: item, playListType: type },
    });
  };

  const [isOpenSortBy, setIsOpenSortBy] = useState(false);

  const openModalSortBy = () => {
    setIsOpenSortBy(true);
  };

  const closeModalSortBy = () => {
    setIsOpenSortBy(false);
  };
  const handleReadQuestionHistory = (item, questionId) => {
    navigate("/liveSatsang", {
      state: {
        liveData: item,
        questionHistory: true,
        historyQuestionId: questionId,
      },
    });
  };
  function handleSearchPlaylist(e) {
    if (!emojiRegex.test(e.target.value)) {
      if (e.target.value) {
        setSearchText(e.target.value);
      } else {
        setSearchText(null);
      }
    }
  }
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      questionHistoryListFun();
    }
  };
  function capitalizeFirstWord(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const handleRadioChange = (event) => {
    setSelectedFilterOp(event.target.value);
  };

  return (
    <>
      <section className="questionHistory">
        <InfiniteScroll
          dataLength={
            questionHistoryList?.slice(
              0,
              viewMore ? questionHistoryList.length : 3
            )?.length
          }
          hasMore={true}
          next={() => questionHistoryListFun(1)}
          loader={
            scrollLast
              ? IsLoading && (
                  <center>
                    <img
                      src={loader}
                      alt="loading..."
                      style={{ height: 25, width: 25, marginTop: 20 }}
                    />
                  </center>
                )
              : questionHistoryList?.length > 0 &&
                viewMore && (
                  <p className="subtitle fancy">
                    <span>End of results</span>
                  </p>
                )
          }
        >
          <div className="qu-history">
            {viewMore && (
              <div className="qu-history-title-search-div">
                {/*----- Question History Title Start -----*/}
                <div>
                  <div className="qu-history-title-mt-20">
                    <span
                      className="qu-history-d-flex"
                      onClick={() => navigate(-1)}
                    >
                      <img
                        className="qu-history-c-pointer"
                        src={AllImages.YellowArrow}
                        alt="Back Arrow"
                        title="Back Arrow"
                      ></img>
                      <p className="qu-history-title">Question History</p>
                    </span>
                  </div>
                </div>
                {/*----- Question History Title End -----*/}

                {/*----- Search Box Start -----*/}
                <div className="qu-history-search-wrap-outer">
                  <div className="qu-history-search-wrap">
                    <div className="search-input">
                      <span
                        onClick={() => questionHistoryListFun()}
                        className="search-icon"
                      >
                        {IsLoading ? (
                          <img
                            src={loader}
                            alt="loading..."
                            style={{ height: 15, width: 15 }}
                          />
                        ) : (
                          <div className="searchTooltip">
                            <img
                              src={AllImages.Search}
                              alt="Search"
                              title="Search"
                            />
                            <span className="tooltiptext">{"Search"}</span>
                          </div>
                        )}
                        {/* <div className='searchTooltip'>
                                                    <img src={AllImages.Search} alt="Search" title="Search" />
                                                    <span className='tooltiptext'>
                                                        {"Search"}
                                                    </span>
                                                </div> */}
                      </span>
                      <input
                        className="f-poppins"
                        type="text"
                        placeholder="Search for Video"
                        onChange={(e) => handleSearchPlaylist(e)}
                        onKeyUp={handleKeyPress}
                      />
                    </div>
                    <div className="searchIcon-outer">
                      <img
                        src={AllImages.SearchIcon}
                        alt="Search"
                        title="Search"
                        className="searchIcon"
                      />
                    </div>
                    <div
                      className="filter-icon sortbyTooltip"
                      onClick={() => openModalSortBy()}
                    >
                      <img
                        src={AllImages.SearchToggle}
                        alt="Search"
                        title="Search"
                      />
                      <span className="tooltiptext">{"Sort By"}</span>
                    </div>
                  </div>
                </div>
                {/*----- Search Box End -----*/}
              </div>
            )}
            {questionHistoryList?.length > 0 ? (
              <div className="qu-history-col-count-3 custom-row">
                {questionHistoryList
                  ?.slice(0, viewMore ? questionHistoryList.length : 3)
                  ?.map((item, index) => (
                    <div className="custom-col-4 custom-col">
                      <div key={index} className="qu-history-card-wrap">
                        <div className="qu-history-inside-wrap">
                          <div className="qu-history-card-content-item">
                            <div className="qu-history-video-content">
                              <div>
                                <p className="qu-history-title-text f-poppins">
                                  Que: {item.question}
                                </p>
                                <p className="qu-history-title-description f-poppins">
                                  {item?.childComments.length > 0
                                    ? item?.childComments[0].comment
                                    : null}
                                </p>
                                {/* <p className='qu-history-text-hrs f-poppins'>{item.hour}</p> */}
                              </div>
                              <div className="qu-history-mt-10 qu-history-bottom-section-div">
                                <div className="qu-history-ta-left">
                                  <img
                                    className="qu-history-c-pointer"
                                    onClick={() => openModal(item.questionId)}
                                    src={AllImages.DeleteCard}
                                    alt="Delete"
                                    title="Delete"
                                  ></img>
                                  {/* <img className="qu-history-c-pointer" src={AllImages.Copy} alt="Copy" title="Copy"></img> */}
                                </div>
                                <div
                                  onClick={() =>
                                    handleReadQuestionHistory(
                                      item.youtubeLiveVideodto,
                                      item.questionId
                                    )
                                  }
                                  className="qu-history-ta-right"
                                >
                                  <p className="qu-history-read">Read</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              viewMore && <NoDataAvailable />
            )}
          </div>
        </InfiniteScroll>

        {/*----- Delete Modal -----*/}
        {isOpen && (
          <Dialog
            open={openModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <div className="qu-history-modal-msg-content">
                <p className="qu-history-modal-msg-text">
                  {"Are you sure you want to Delete this Question?"}
                </p>
                <span
                  className="qu-history-modal-close-button"
                  onClick={() => closeModal()}
                >
                  &times;
                </span>
              </div>
            </DialogContent>
            <DialogActions>
              <div className="qu-history-modal-action-content">
                <button
                  className="qu-history-modal-yes-btn"
                  onClick={() => questionRemove()}
                >
                  {"Yes"}
                </button>
                <button
                  className="qu-history-modal-dont-delete-btn"
                  onClick={closeModal}
                >
                  {"DON’T delete"}
                </button>
              </div>
            </DialogActions>
          </Dialog>
        )}

        {isOpenSortBy && (
          <Dialog
            open={openModalSortBy}
            className="sortBy"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent
              style={{
                overflowY: "hidden",
                overflowX: "hidden",
              }}
            >
              <div className="qu-history-modal-msg-content">
                <p className="qu-history-modal-msg-text">{"Sort by"}</p>
                <span
                  className="qu-history-modal-close-button"
                  onClick={() => closeModalSortBy()}
                >
                  &times;
                </span>
              </div>
              <ul>
                {Object.keys(SortingOptions).map((key) => (
                  <li key={key}>
                    <label className="customRadio">
                      {SortingOptions[key] === "A_TO_Z" ||
                      SortingOptions[key] === "Z_TO_A"
                        ? key.replace(/_/g, " ")
                        : capitalizeFirstWord(
                            key.replace(/_/g, " ").toLowerCase()
                          )}
                      <input
                        type="radio"
                        value={SortingOptions[key]}
                        checked={selectedFilterOp === SortingOptions[key]}
                        onChange={handleRadioChange}
                        name="radio"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                ))}
              </ul>
            </DialogContent>
          </Dialog>
        )}
      </section>
    </>
  );
};
export default QuestionHistory;
