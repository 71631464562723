import React, { useState, useContext, useEffect, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./style.css";
import {
  GLOBAL_URL,
  AllImages,
  googleClientId,
} from "../../../utils/constants";
import {
  UserloggedContext,
  SubcriptionListContext,
  GSTContext,
  SelectedSubcriptionContext,
  LoaderContext,
  userDetailsContext,
  SubcriptionHistoryContext,
} from "../../Context/UserContext";
import ApiEndPoint from "../../../utils/apiEndPoints";
import { setDefaultHeader, apiCall } from "../../../utils/httpClient";
import moment from "moment";
import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Google from "../../../assets/images/google.png";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ToastContainer, toast } from "react-toastify";
import useRazorpay from "react-razorpay";
import { gapi } from "gapi-script";

import ReCAPTCHA from "react-google-recaptcha";
import {
  GoogleLogin,
  useGoogleLogout,
  useGoogleLogin,
} from "react-google-login";
import { AuthContext } from "../../Context/context";
import useGoogleLoginFlow from "components/common/useGoogleLoginFlow";
const clientId = googleClientId;

function SubscriptionPlanList() {
  const TEST_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
  const location = useLocation();
  const {
    openRecaptcha,

    recaptchaFun,

    onChange,
  } = useGoogleLoginFlow();
  const Razorpay = useRazorpay();
  const navigate = useNavigate();
  const [auth, setAuth] = useContext(UserloggedContext);
  const { signInFun, signOutFun } = React.useContext(AuthContext);
  const [userDetails, setUserDetails] = useContext(userDetailsContext);
  const [subscriptionList, setSubcriptionList] = useContext(
    SubcriptionListContext
  );
  const [gstpagemanage, setGstpagemanage] = useContext(GSTContext);
  const [selectedSubscription, setSelectedSubscription] = useContext(
    SelectedSubcriptionContext
  );
  const [subscriptionHistory, setSubscriptionHistory] = useContext(
    SubcriptionHistoryContext
  );
  const [allVideoFree, setAllVideoFree] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [empty, setEmpty] = useState(false);

  useEffect(() => {
    getSubcriptionList();
    getSubcriptionHistoryDetails();
    allVideoFreeStatus();
  }, []);

  useEffect(() => {
    gstpagemanage == "user" &&
      userDetails?.user?.phoneNumber &&
      addSubcriptionFun(selectedSubscription);
    return () => {
      gstpagemanage == "user" && setGstpagemanage("");
    };
  }, [gstpagemanage, userDetails]);

  async function getSubcriptionList() {
    try {
      if (auth == "01") {
        const { data } = await apiCall("GET", ApiEndPoint.LOGINSUBCRIPTION);
        if (data?.length > 0) {
          setSubcriptionList(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else {
        const { data } = await apiCall(
          "GET",
          ApiEndPoint.SUBCRIPTIONPLANWITHOUTLOGIN
        );
        if (data?.length > 0) {
          setSubcriptionList(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  async function getSubcriptionHistoryDetails() {
    try {
      !subscriptionHistory && setIsLoading(true);
      if (auth == "01") {
        const { data } = await apiCall(
          "GET",
          ApiEndPoint.GETSUBSCRIPTIONHISTORY
        );
        if (data) {
          setSubscriptionHistory(data);
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  async function allVideoFreeStatus() {
    try {
      const { data } = await apiCall("GET", ApiEndPoint.GETOFFERISAVAILBLE);
      setAllVideoFree(data);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setAllVideoFree(false);
    }
  }

  async function addSubcriptionFun(item) {
    try {
      // setIsLoading(true);
      const Auth = await localStorage.getItem("userToken");
      if (Auth == "01") {
        const params = {
          amount: item.price,
          subscriptionId: item.id,
        };
        const { data } = await apiCall("POST", `orders`, params);

        if (data) {
          handlePayment(data, item.id, userDetails?.user?.phoneNumber);
          document.cookie =
            "rzp_contact=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          setIsLoading(false);
          // toast.success('Successfully', {
          //   position: toast.POSITION.TOP_RIGHT
          // });
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  async function Subcription(item) {
    if (
      userDetails?.user?.country &&
      userDetails?.user?.state &&
      userDetails?.user?.city &&
      userDetails?.user?.pinCode &&
      userDetails?.user?.phoneNumber
    ) {
      addSubcriptionFun(item);
    } else {
      if (gstpagemanage == "user") {
        addSubcriptionFun(item);
      } else {
        setSelectedSubscription(item);
        navigate("/editProfile");
        setGstpagemanage("subscription");
      }
    }
  }

  function takePlanSubcription(item) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card">
            <div className="info__padding">
              <h3>{`Are you sure you want to buy subcription plan`}</h3>
            </div>
            <div className="button__group">
              <button
                className="button btn-ok"
                onClick={() => {
                  addSubcriptionFun(item);
                  onClose();
                }}
              >
                Yes
              </button>
            </div>
          </div>
        );
      },
    });
  }

  function takeSubcription() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card">
            <div className="info__padding">
              <h3>{`Please Sign In To Subscribe...`}</h3>
            </div>
            <div className="button__group">
              {/* <button
                style={{ border: "2px solid green", marginRight: "10px", 'width': '170px' }}
                onClick={() => {
                  signIn();
                  onClose();
                }}
              >
                Sign-in with Google
              </button> */}
              <Button
                onClick={() => {
                  onChange({ true: true });
                  onClose();
                }}
                variant="contained"
                className="signBtnHome"
              >
                {/* Sign-in with  */}
                <img
                  style={{ height: "23px", width: "23px" }}
                  className="g-icon"
                  src={Google}
                ></img>
                Sign-in
              </Button>
              <button className="no-btn" onClick={onClose}>
                Cancel
              </button>
            </div>
          </div>
        );
      },
    });
  }

  const handlePayment = useCallback(
    async (data, subscriptionId, phoneNumber) => {
      const options: RazorpayOptions = {
        key: data.keyId,
        amount: data.amount,
        currency: "INR",
        name: "Satshrut",
        description: "",
        image: "https://vitraagvigyaan.org/img/logo.png",
        order_id: data.orderId,
        handler: (res) => {
          getUserDetails();
          setGstpagemanage("");
          toast.success("Payment successful", {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate("/");
          // console.log(res);
        },
        prefill: {
          name: userDetails?.user?.firstName,
          email: userDetails?.user?.email,
          // contact: 'UNIQUE_IDENTIFIER',
          contact: phoneNumber ? phoneNumber : "UNIQUE_IDENTIFIER",
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
        method: {
          netbanking: true,
          card: true,
          wallet: false,
          upi: true,
        },
        config: {
          display: {
            hide: [{ method: "paylater" }],
          },
        },
      };
      // localStorage.removeItem('rzp_contact');
      const rzpay = new Razorpay(options);
      rzpay.on("payment.failed", function (response) { });
      rzpay.open();
      // rzpay.clearContact();
    },
    [Razorpay]
  );

  async function getUserDetails() {
    try {
      const Auth = await localStorage.getItem("userToken");
      if (Auth == "01") {
        const { data } = await apiCall(
          "GET",
          ApiEndPoint.GETSUBCRIPTIONDETAILS
        );
        if (data) {
          setUserDetails(data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <section className="subscriptionPlanList">
      <p className="mainTitle">Subscription</p>
      <div className="subscriptionPlanListInner">
        <div className="first">
          <p className="plansTitle">Our Valuable Plans</p>

          <div className="SubscriptionCard">
            <div className="header">
              <p className="title">Free Plan</p>
              <div className="status">
                <div className="active">&nbsp;</div>
                <p className="text activeText">Active</p>
              </div>
            </div>
            <p className="desc">Free Forever</p>
          </div>
          {subscriptionList.map((item) => (
            <div className="SubscriptionCard">
              <div className="header">
                <p className="title">Annual Plan</p>
                <div className="status">
                  <p className="text">({item?.subscriptionName})</p>
                </div>
              </div>
              <p className="desc">
                &#8377;<span className="amount">{item?.price}</span>
                <span className="subText">(Including GST)</span>
              </p>

              <div className="benefits">
                <p className="title">Benefits Included</p>
                {item?.specifications?.map((x) => (
                  <ul>
                    <li>{x.specificationName}</li>
                  </ul>
                ))}
              </div>
              <div
                className="buySubscriptionOuter"
                onClick={() =>
                  auth == "01" ? Subcription(item) : takeSubcription()
                }
              >
                {
                  (userDetails?.user?.subscriptionRenew === true) ?
                    <button className="button btn buySubscription">Renew Subscription</button>
                    :
                    <button className="button btn buySubscription">Buy Subscription</button>
                }
              </div>
              {/* <div className="subscription-not-available">
                <p>We are unable to take new subscriptions at the moment</p>
              </div> */}
            </div>
          ))}
        </div>
        {subscriptionHistory?.length > 0 && (
          <div className="second">
            <p className="historyTitle">Subscription History</p>
            {subscriptionHistory?.map((item) => (
              <div className="SubscriptionCard">
                <div className="history">
                  <div className="left">
                    <p className="title">Type of Plan</p>
                    <p className="subTitle">
                      {item?.subscriptionPlan?.subscriptionName
                        ? item?.subscriptionPlan?.subscriptionName
                        : "--"}
                    </p>
                  </div>
                  <div className="right">
                    <p className="title">Price</p>
                    <p className="subTitle">
                      {item?.subscriptionPlan?.price
                        ? item?.subscriptionPlan?.price
                        : "--"}
                    </p>
                  </div>
                </div>
                <div className="history">
                  <div className="left">
                    <p className="title">Start Date</p>
                    <p className="subTitle">
                      {item?.startDate
                        ? moment(item?.startDate).format("YYYY-MM-DD HH:mm")
                        : "--"}
                    </p>
                  </div>
                  <div className="right">
                    <p className="title">End Date</p>
                    <p className="subTitle">
                      {item?.endDate
                        ? moment(item?.endDate).format("YYYY-MM-DD HH:mm")
                        : "--"}
                    </p>
                  </div>
                </div>
                <div className="history">
                  <div className="left">
                    <p className="title">Transaction ID</p>
                    <p className="subTitle">
                      {item?.transactionId ? item?.transactionId : "--"}
                    </p>
                  </div>
                  <div className="right">
                    <p className="title">Transaction Date</p>
                    <p className="subTitle">
                      {item?.transactionDate ? item?.transactionDate : "--"}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {/* {openRecaptcha &&
          <Dialog
            open={openRecaptcha}
            className="sortBy captcha"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent style={{
              overflowY: "hidden",
              overflowX: "hidden",
            }}>

              <ReCAPTCHA
                sitekey={TEST_SITE_KEY}
                onChange={onChange}
                style={{ display: "inline-block", }}
              />
            </DialogContent>
          </Dialog>
        } */}
    </section>
  );
}
export default SubscriptionPlanList;
