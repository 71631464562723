import React, { useState, useContext, useEffect } from "react";
import "./style.css";
import { AllImages } from "../../../utils/constants";
import { UserloggedContext, SurpriseMeContext, userDetailsContext, SurpriseMeDataContext } from "../../Context/UserContext";
import Logout from "../../../assets/Phase2/logout.png"
import LogoutWhite from "../../../assets/Phase2/logoutWhite.png"
import { AuthContext } from "../../Context/context";
import ApiEndPoint from "../../../utils/apiEndPoints";
import { setDefaultHeader, apiCall } from "../../../utils/httpClient";
import useGoogleLoginFlow from "components/common/useGoogleLoginFlow";
import { confirmAlert } from "react-confirm-alert";
import Button from "@material-ui/core/Button";
import Google from "../../../assets/images/google.png";
import {
  BrowserRouter as Router,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";

export default function Sidemenu(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const {

    openRecaptcha,

    recaptchaFun,

    onChange,
  } = useGoogleLoginFlow()
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [auth, setAuth] = useContext(UserloggedContext);
  const [surpriseMeShow, setSurpriseMeShow] = useContext(SurpriseMeContext)
  const [userDetails, setUserDetails] = useContext(userDetailsContext);
  const [surpriseMeData, setSurpriseMeData] = useContext(SurpriseMeDataContext)
  const { signInFun, signOutFun } = React.useContext(AuthContext);

  function LogoutFun(params) {
    navigate('/')
    signOutFun()
  }

  useEffect(() => {
    // LogoutFun()
  }, [])

  const handleHome = (item) => {
    navigate(`/`)
  }

  const handleMyFavorites = (item) => {
    if (auth) {
      navigate(`/MySpace`)
    } else {
      takeOtherSubcription()
    }
  }
  const handleSparshTab = (item) => {
    if (auth) {
      navigate('/sparsh')
    } else {
      takeOtherSubcription()
    }
  }

  const handleEBooks = (item) => {
    navigate(`/eBooks`)
  }


  const handleSurpriseMe = async (item) => {
    try {
      if (auth) {
        const { data } = await apiCall("GET", ApiEndPoint.SURPRISEME);
        if (data) {
          setSurpriseMeData(data)
          setSurpriseMeShow(true)
          navigate(`/`)
        }
      } else {
        takeOtherSubcription()
      }
    } catch (error) {
      // console.log(error);
    }

  }

  const handleSubcription = (item) => {
    userDetails?.subscriptionPlan?.subscriptionLevel == 2 ?
      navigate('/activeSubscriptionPlan')
      :
      navigate('/subscriptionPlanList')
  }
  function takeOtherSubcription() {

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card">
            <div className="info__padding">
              <h3>
                {`Please sign in to use this feature. `}
              </h3>
              {/* <h3>{!auth ? "Sign In" : ""}</h3> */}
            </div>
            {!auth ? (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      onChange({ true: true });
                      onClose();
                    }}
                    variant="contained"
                    className="signBtnHome"
                  >
                    <img style={{ height: '23px', width: '23px' }} className="g-icon" src={Google}></img>

                    Sign-in
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      navigate("/subscriptionPlanList");
                      onClose();
                    }}
                    variant="contained"
                    className="subscriptionBtn"
                  // className="signBtnHome"
                  >
                    {/* <img className="g-icon" src={Google}></img> */}
                    Subscribe
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        );
      },
    })

  }

  return (
    splitLocation[1] != 'fileViewer' && <div className={splitLocation[1] === "viewMore" || splitLocation[1] === "liveSatsang" || splitLocation[1] === "videoPlayPage" ? "side-menu-bar viewMore" : "side-menu-bar"}>
      {/*----- Logo -----*/}
      <div className="side-menu-bar-logo">
        <img onClick={() => handleHome()} className="main-logo" src={AllImages?.Logo} alt="Logo" title="Logo" />
      </div>

      <div className={auth ? "sidemenuInner" : "sidemenuInner loggedOut"} >
        {/*----- Home -----*/}
        <div className={splitLocation[1] == "" ? surpriseMeShow ? 'side-menu-bar-item' : "side-menu-bar-item-active" : 'side-menu-bar-item'} onClick={() => handleHome()}>
          <img src={AllImages?.HomeSideBar} alt="Home" title="Home" className="side-menu-bar-item-img" />
          <p className="side-menu-bar-item-text">home</p>
        </div>
        {/*----- Sparsh -----*/}
        <div className={splitLocation[1] == "sparsh" ? "side-menu-bar-item-active" : 'side-menu-bar-item'} onClick={() => handleSparshTab()}>
          <img src={AllImages?.Sparsh} alt="Sparsh" title="Sparsh" className="side-menu-bar-item-img" />
          <p className="side-menu-bar-item-text">sparsh</p>
        </div>

        {/*----- E-Books -----*/}
        <div className={splitLocation[1] == "eBooks" ? "side-menu-bar-item-active" : 'side-menu-bar-item'} onClick={() => handleEBooks()}>
          <img src={AllImages?.EBook} alt="e-book" title="e-book" className="side-menu-bar-item-img" />
          <p className="side-menu-bar-item-text">e-books</p>
        </div>

        {/*----- Surprise -----*/}
        <div className={surpriseMeShow ? "side-menu-bar-item-active" : 'side-menu-bar-item'} onClick={() => handleSurpriseMe()}>
          <img src={AllImages?.Surprise} alt="surprise" title="surprise" className="side-menu-bar-item-img" />
          <p className="side-menu-bar-item-text">surprise</p>
        </div>

        {/*----- my space -----*/}
        <div className={splitLocation[1] == "MySpace" ? "side-menu-bar-item-active" : 'side-menu-bar-item'} onClick={() => handleMyFavorites()}>
          <img src={AllImages?.MySpace} alt="my space" title="my sace" className="side-menu-bar-item-img" />
          <p className="side-menu-bar-item-text">my space</p>
        </div>

        {/*----- Border -----*/}
        <div className="side-menu-bar-border"></div>

        {/*----- Subscription -----*/}
        <div className={splitLocation[1] == "subscriptionPlanList" || splitLocation[1] == "activeSubscriptionPlan" ? "side-menu-bar-item-active" : 'side-menu-bar-item'} onClick={() => handleSubcription()}>
          <img src={AllImages?.Subscriptions} alt="subscription" title="suscrition" className="side-menu-bar-item-img" />
          <p className="side-menu-bar-item-text">Subscription</p>
        </div>

        {/*----- About Us -----*/}
        <div className={splitLocation[1] == "aboutUs" ? "side-menu-bar-item-active" : 'side-menu-bar-item'} onClick={() => navigate('/aboutUs')}>
          <img src={AllImages?.AboutUs} alt="about us" title="about us" className="side-menu-bar-item-img" />
          <p className="side-menu-bar-item-text">about us</p>
        </div>

        {/*----- FAQs -----*/}
        <div className={splitLocation[1] == "fAQS" ? "side-menu-bar-item-active" : 'side-menu-bar-item'} onClick={() => navigate('/fAQS')}>
          <img src={AllImages?.FAQs} alt="FAQs" title="FAQs" className="side-menu-bar-item-img" />
          <p className="side-menu-bar-item-text">FAQs</p>
        </div>

        {/*----- Video Index -----*/}
        <div className={splitLocation[1] == "videosIndex" ? "side-menu-bar-item-active" : 'side-menu-bar-item'} onClick={() => navigate('/videosIndex')}>
          <img src={AllImages?.VideoIndex} alt="video-index" title="Video Index" className="side-menu-bar-item-img" />
          <p className="side-menu-bar-item-text">Video index</p>
        </div>

        {/*----- Contact us -----*/}
        <div className={splitLocation[1] == "contactUs" ? "side-menu-bar-item-active" : 'side-menu-bar-item'} onClick={() => navigate('/contactUs')}>
          <img src={AllImages?.ContactUs} alt="contact us" title="contact us" className="side-menu-bar-item-img" />
          <p className="side-menu-bar-item-text">Contact us</p>
        </div>

        {/*----- Privacy Policy -----*/}
        <div className={splitLocation[1] === "privacyPolicy" ? "side-menu-bar-item-active" : 'side-menu-bar-item'} 
        onClick={() => navigate('/privacyPolicy')}
        >
          <img src={AllImages?.FAQs} alt="privacy policy" title="Privacy Policy" className="side-menu-bar-item-img" />
          <p className="side-menu-bar-item-text">Privacy Policy</p>
        </div>
      </div>

      {auth &&
        <div onClick={() => LogoutFun()} className={splitLocation[1] == "contactUs" ? "side-menu-bar-item-active logoutOuter" : 'side-menu-bar-item logoutOuter'} >
          <img src={Logout} alt="Logout" title="Logout" className="side-menu-bar-item-img logout" />
          <img src={LogoutWhite} alt="Logout" title="Logout" className="side-menu-bar-item-img logoutActive" />
          <p className="side-menu-bar-item-text">Logout</p>
        </div>
      }
    </div>
  );
}
