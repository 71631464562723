import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

// import { setDefaultHeader, apiCall } from "../../utils/httpClient";
// import ApiEndPoint from "../../utils/apiEndPoints";
// import Platinum from '../../assets/images/platinum.png'
import "./style.css";
import { toast } from "react-toastify";
import apiEndPoints from "utils/apiEndPoints";
import { apiCall } from "utils/httpClient";

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundImage: 'url("nereus-assets/img/bg/pattern1.png")',
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  cardHeader: {
    paddingTop: theme.spacing(3),
  },
}));

export default function PrivacyPolicy(props) {
  const [contactDetails, setContactDetails] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [error, setError] = useState(false);
  const [emailCheck, setEmailCheck] = useState(true);
  const [validEmail, setValidEmail] = useState(false);

  const validation = () => {
    if (
      contactDetails.name?.length == "" ||
      contactDetails.name?.length == null
    ) {
      setError(true);
      return false;
    }
    if (
      contactDetails.email?.length == "" ||
      contactDetails.email?.length == null
    ) {
      setError(true);
      return false;
    }

    // var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const validRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!contactDetails.email.match(validRegex)) {
      setValidEmail(true);
      return false;
    } else {
      setValidEmail(false);
    }
    // if (emailCheck) {
    //   return false;
    // }
    if (contactDetails.phone?.length != 10) {
      setError(true);
      return false;
    }
    if (
      contactDetails.message?.length == "" ||
      contactDetails.message?.length == null
    ) {
      setError(true);
      return false;
    }
    setError(false);
    return true;
  };

  const handleChangePhoneNumber = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      event?.target?.value
        ? setContactDetails({
          ...contactDetails,
          phone: event.target.value,
        })
        : setContactDetails({
          ...contactDetails,
          phone: "",
        });
    }
  };

  function isValidEmail(email) {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return regex.test(email);
  }

  const handleChangeEmail = (event) => {
    // if (!isValidEmail(event.target.value)) {
    //     setEmailCheck(false);
    // } else {
    //   setEmailCheck(true);
    // }

    event?.target?.value
      ? setContactDetails({
        ...contactDetails,
        email: event.target.value,
      })
      : setContactDetails({
        ...contactDetails,
        email: "",
      });
    setValidEmail(false);
  };

  async function sendEmailFun() {
    const valid = validation();
    if (valid) {
      const params = {
        name: contactDetails.name,
        email: contactDetails.email,
        massage: contactDetails.message,
        contactDetails: contactDetails.phone,
      };
      const { data } = await apiCall("POST", apiEndPoints.MAILSEND, params);
      if (data) {
        toast.success("Email Sent Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setContactDetails({
          name: "",
          email: "",
          message: "",
          phone: "",
        });
      }
    }
  }


  return (
    <section className="contactPage">
      <Container maxWidth="lg">
        <Box py={2} textAlign="center">
          <Box mb={3}>
            <Container maxWidth="sm">
              {/* <div className='smallTitle'>Best Video</div> */}
              <div className="twoColorTagLine"></div>
            </Container>
          </Box>
          <Grid container spacing={3}>
            <Grid item sm={12} xs={12}>
              <div className="pricingTitle text-center">Privacy Policy</div>
            </Grid>
            <Grid>
              <div className="termView">
                <p className="privacyPolicy">
                  This privacy policy has been compiled to better serve those who are concerned with how their 'Personally Identifiable Information' (PII) is being used online. PII, as described in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.

                </p>



                <h3 style={{ textAlign: "start" }}>
                  SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?
                </h3>
                <p className="privacyPolicy">
                  When you purchase something from our store, as part of the
                  buying and selling process, we collect the personal information
                  you give us such as your name, address and email address.
                  <br />
                  <br />
                  When you browse our store, we also automatically receive your
                  computer’s internet protocol (IP) address in order to provide us
                  with information that helps us learn about your browser and
                  operating system.
                  <br />
                  <br />
                  Email marketing (if applicable): With your
                  permission, we may send you emails about our store, new products
                  and other updates.
                </p>

                <h3 style={{ textAlign: "start" }}>What personal information do we collect from the people that visit our blog, website or app?
                </h3>

                <p className="privacyPolicy">
                  When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address, mailing address, phone number or other details to help you with your experience.

                </p>
                <h3 style={{ textAlign: "start" }}>SECTION 2 - CONSENT</h3>
                <p className="privacyPolicy">
                  How do you get my consent?
                  <br />
                  <br />
                  When you provide us with personal
                  information to complete a transaction, verify your credit card,
                  place an order, arrange for a delivery or return a purchase, we
                  imply that you consent to our collecting it and using it for
                  that specific reason only.
                  <br />
                  <br />
                  If we ask for your personal
                  information for a secondary reason, like marketing, we will
                  either ask you directly for your expressed consent, or provide
                  you with an opportunity to say no.
                  <br />
                  <br />
                  How do I withdraw my consent?
                  <br />
                  <br />
                  If after you opt-in, you change your mind, you may withdraw your consent for us to contact you,
                  for the continued collection, use or disclosure of your information, at any time, by contacting us
                  at <b style={{ color: '#4040ff' }}> ss@vitraagvigyaan.org   </b> or mailing us at: Raj Nagar, Parli, Post Gothavade, Taluka
                  Sudhagadh, Off Khopoli-Pali Road (same road as Adlabs Imagica), Dist. Raigad- 410205
                </p>
                <h3 style={{ textAlign: "start" }}>SECTION 3 - DISCLOSURE</h3>
                <p className="privacyPolicy">
                  We may disclose your personal information if we are required by
                  law to do so or if you violate our Terms of Service.
                </p>
                <h3 style={{ textAlign: "start" }}>SECTION 4 - PAYMENT</h3>
                <p className="privacyPolicy">
                  We use Razorpay for processing payments. We/Razorpay do not store your card data on their
                  servers. The data is encrypted through the Payment Card Industry Data Security Standard
                  (PCI-DSS) when processing payment. Your purchase transaction data is only used as long as is
                  necessary to complete your purchase transaction. After that is complete, your purchase
                  transaction information is not saved.
                  <br />
                  <br />
                  Our payment gateway adheres to the standards set by PCI-DSS as managed by the PCI
                  Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American
                  Express and Discover.
                  <br />
                  <br />
                  PCI-DSS requirements help ensure the secure handling of credit card information by our store
                  and its service providers.
                  <br />
                  <br />
                  For more insight, you may also want to read terms and conditions of razorpay on
                  <a href="https://razorpay.com/" style={{ color: '#4040ff', marginLeft: 8 }}>https://razorpay.com/</a>
                </p>
                <h3 style={{ textAlign: "start" }}>
                  SECTION 5 - THIRD-PARTY SERVICES
                </h3>
                <p className="privacyPolicy">
                  In general, the third-party providers used by us will only
                  collect, use and disclose your information to the extent
                  necessary to allow them to perform the services they provide to
                  us.
                  <br />
                  <br />
                  However, certain third-party service providers, such as
                  payment gateways and other payment transaction processors, have
                  their own privacy policies in respect to the information we are
                  required to provide to them for your purchase-related
                  transactions.
                  <br />
                  <br />
                  For these providers, we recommend that you read
                  their privacy policies so you can understand the manner in which
                  your personal information will be handled by these providers.
                  <br />
                  <br />
                  In particular, remember that certain providers may be located in or
                  have facilities that are located a different jurisdiction than
                  either you or us. So if you elect to proceed with a transaction
                  that involves the services of a third-party service provider,
                  then your information may become subject to the laws of the
                  jurisdiction(s) in which that service provider or its facilities
                  are located.
                  <br />
                  <br />
                  Once you leave our store’s website or are
                  redirected to a third-party website or application, you are no
                  longer governed by this Privacy Policy or our website’s Terms of
                  Service.
                  <br />
                  <br />
                  Links
                  <br />
                  <br />
                  When you click on links on our store, they may
                  direct you away from our site. We are not responsible for the
                  privacy practices of other sites and encourage you to read their
                  privacy statements.
                </p>
                <h3 style={{ textAlign: "start" }}>SECTION 6 - SECURITY</h3>
                <p className="privacyPolicy">
                  To protect your personal information, we take reasonable
                  precautions and follow industry best practices to make sure it
                  is not inappropriately lost, misused, accessed, disclosed,
                  altered or destroyed.
                </p>
                <h3 style={{ textAlign: "start" }}>SECTION 7 - COOKIES</h3>
                <p className="privacyPolicy">
                  We use cookies to maintain session of your user. It is not used
                  to personally identify you on other websites.
                </p>
                <h3 style={{ textAlign: "start" }}> SECTION 8 - AGE</h3>
                <p className="privacyPolicy">
                  OF CONSENT By using this site, you represent that you are at
                  least the age of majority in your state or province of
                  residence, or that you are the age of majority in your state or
                  province of residence and you have given us your consent to
                  allow any of your minor dependents to use this site.
                </p>
                <h3 style={{ textAlign: "start" }}>
                  {" "}
                  SECTION 9 - CHANGES TO THIS PRIVACY POLICY
                </h3>
                <p className="privacyPolicy">
                  We reserve the right to modify this privacy policy at any time,
                  so please review it frequently. Changes and clarifications will
                  take effect immediately upon their posting on the website. If we
                  make material changes to this policy, we will notify you here
                  that it has been updated, so that you are aware of what
                  information we collect, how we use it, and under what
                  circumstances, if any, we use and/or disclose it.
                  <br />
                  <br />
                  If our store
                  is acquired or merged with another company, your information may
                  be transferred to the new owners so that we may continue to sell
                  products to you.
                  <br />
                  <br />
                  QUESTIONS AND CONTACT INFORMATION
                  <br />
                  <br />
                  If you would
                  like to: access, correct, amend or delete any personal
                  information we have about you,
                  register a complaint, or simply
                  want more information contact our Sat Shrut Team at
                  <b> ss@vitraagvigyaan.org </b>or by mail at Raj Nagar, Parli, Post
                  Gothavade, Taluka Sudhagadh, Off Khopoli-Pali Road (same road as
                  Adlabs Imagica), Dist. Raigad- 410205
                  <br />
                  <br />
                  <b> Refund and Cancellation policy</b>
                  <br />
                  <br />
                  Once the purchase has been made, there is no
                  cancellation, return or refund policy applicable in context of
                  this purchase.
                </p>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  );
}