// utils/generateImageDataURL.js
export const generateImageDataURL = (text) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
  
    // Set canvas dimensions
    canvas.width = 200;
    canvas.height = 200;
  
    // Draw background with a very light orange color
    ctx.fillStyle = '#FFEBCC'; // Light orange background color
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  
    // Set initial font size
    let fontSize = 40;
    ctx.font = `bold ${fontSize}px Arial`;
  
    // Adjust font size to fit text within the canvas
    while (ctx.measureText(text).width > canvas.width - 20 && fontSize > 10) {
      fontSize -= 2;
      ctx.font = `bold ${fontSize}px Arial`;
    }
  
    // Draw text
    ctx.fillStyle = '#000000'; // Text color
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(text, canvas.width / 2, canvas.height / 2);
  
    return canvas.toDataURL('image/png');
  };
  