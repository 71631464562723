import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import NoDataAvailable from "../../common/NoDataFound/NoDataAvailable";
import loader from "../../../assets/images/logo.png";
import "./style.css";
import {
  GLOBAL_URL,
  AllImages,
  formatTimestampWithAMPM,
  RemoveDuplicates,
} from "../../../utils/constants";
import ApiEndPoint from "../../../utils/apiEndPoints";
import { setDefaultHeader, apiCall } from "../../../utils/httpClient";
import {
  MusicTrackContext,
  MusicTracksContext,
  PlayNextContext,
  userDetailsContext,
  UserloggedContext,
} from "../../Context/UserContext";
// import socketIOClient from 'socket.io-client';
import InfiniteScroll from "react-infinite-scroll-component";
import { socket } from "../../../utils/socket";
import ReactPlayer from "react-player/lazy";

function LiveSatsang() {
  const navigate = useNavigate();
  const location = useLocation();

  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const { liveData, questionHistory, historyQuestionId } = location.state || {};
  const [auth, setAuth] = useContext(UserloggedContext);
  const [receivedMessage, setReceivedMessage] = useState("");
  const [userDetails, setUserDetails] = useContext(userDetailsContext);
  const [liveVideo, setLiveVideo] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const [replyPageShow, setReplyPageShow] = useState(false);
  const [questionid, setQuestionId] = useState(null);
  const [msgName, setMsgName] = useState(null);
  const [getCommentReplyList, setGetCommentReplyList] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [pageNoReply, setPageNoReply] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [hasMoreQuestions, setHasMoreQuestions] = useState(true);
  const [hasMoreComments, setHasMoreComments] = useState(true);
  const [fileDownloadLoader, setFileDownloadLoader] = useState(null);
  const [fileDownloadLoaderType, setFileDownloadLoaderType] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const [newReplyArray, setNewReplyArray] = useState([]);
  const [replyData, setReplyData] = useState(null);

  // STOP THE MUSIC PLAYER  STATE
  const { track, setTrack } = useContext(MusicTracksContext);
  const { singleTrack, setSingleTrack } = useContext(MusicTrackContext);
  const { playNextTrack, setPlayNextTrack } = useContext(PlayNextContext);


  useEffect(() => {
    auth && getALLChat();
  }, []);

  const chatBoxRef = useRef(null);

  useEffect(() => {
    if (!pageNo > 0) {
      if (chatBoxRef.current) {
        chatBoxRef.current.scrollTop = 0;
      }
    }
  }, [chatMessages]);

  async function getALLChat() {
    try {
      // STOP THE MUSIC PLAYER 
      setTrack([])
      setSingleTrack([])
      setPlayNextTrack([])
      let params;
      if (questionHistory && historyQuestionId) {
        params = {
          pageNo: pageNo,
          pageSize: 10,
          videoid: liveData.id,
          questionid: historyQuestionId,
        };
      } else {
        params = { pageNo: pageNo, pageSize: 10, videoid: liveData.id };
      }
      setIsLoading(true);

      const { data } = await apiCall(
        "POST",
        questionHistory
          ? ApiEndPoint.GETALLQUESTIONSFORQUESTIONHISTORY
          : ApiEndPoint.GETALLQUESTOINS,
        params
      );
      if (data) {
        setIsLoading(false);

        setPageNo(pageNo + 1);
        setChatMessages(chatMessages.concat(RemoveDuplicates(data)));
        data.length < 10 && setHasMoreQuestions(false);
      } else {
        setIsLoading(false);

        setHasMoreQuestions(false);
      }
    } catch (error) {
      setIsLoading(false);

      setHasMoreQuestions(false);
    }
  }

  async function getCommentReply(id) {
    try {
      const params = {
        pageNo: pageNoReply,
        pageSize: 10,
        questionid: id,
      };
      setIsLoading(true);
      const { data } = await apiCall(
        "POST",
        ApiEndPoint.GETQUESTIONREPLY,
        params
      );
      if (data) {
        setIsLoading(false);

        setPageNoReply(pageNoReply + 1);
        setGetCommentReplyList(
          getCommentReplyList.concat(RemoveDuplicates(data))
        );
        data.length < 10 && setHasMoreComments(false);
      } else {
        setIsLoading(false);

        setHasMoreComments(false);
      }
    } catch (error) {
      setIsLoading(false);

      setHasMoreComments(false);
    }
  }

  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const sendMessage = () => {
    if (socket) {
      const newMessage = {
        question: message,
        user: {
          id: userDetails?.user?.id,
        },
        globalchat: questionid ? true : false,
        youtubeLiveVideo: liveData.id,
        questionid: questionid,
      };
      socket.emit("send_message", newMessage, (response) => {
        setMessage(""); // Clear the input field after sending
      });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };
  useEffect(() => {
    if (replyData?.videoId == liveData.id) {
      if (replyData?.comment) {
        if (questionid) {
          setGetCommentReplyList((prevData) => [replyData, ...prevData]);
        }

        const chatMessageIndex = chatMessages.findIndex(
          (item) => item.questionId == replyData.questionId
        );

        if (chatMessageIndex !== -1) {
          const updatedChatMessage = { ...chatMessages[chatMessageIndex] };

          if (!updatedChatMessage.childComment) {
            updatedChatMessage.childComment = true;
            chatMessages.splice(chatMessageIndex, 1, updatedChatMessage);
            // setChatMessages(prev=>[...prev.splice(chatMessageIndex, 1, updatedChatMessage)])
          }

          if (
            !newReplyArray.includes(chatMessages[chatMessageIndex].questionId)
          ) {
            if (
              questionid &&
              questionid == chatMessages[chatMessageIndex].questionId
            ) {
              return;
            }
            setNewReplyArray((prev) => [
              ...prev,
              chatMessages[chatMessageIndex].questionId,
            ]);
          }
        }
      } else {
        setChatMessages((prevData) => [replyData, ...prevData]);
      }
    }
    setReplyData(null);
  }, [replyData]);

  socket.on("get_message", (data) => {
    setReplyData(data);
  });

  const commentReply = (item) => {
    setHasMoreQuestions(true);
    setReplyPageShow(true);
    setQuestionId(item.questionId);
    setMsgName(item.question);
    getCommentReply(item.questionId);
    setChatMessages([]);
    setPageNo(0);
  };

  const commentReplyClose = () => {
    setHasMoreComments(true);
    setReplyPageShow(false);
    setQuestionId(null);
    setMsgName(null);
    setPageNoReply(0);
    setGetCommentReplyList([]);
    auth && getALLChat();
  };
  const handleViewReplies = (chatMessage) => {
    commentReply(chatMessage);
    setNewReplyArray((prev) => [
      ...prev.filter((item) => item != chatMessage.questionId),
    ]);
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function getFileExtension(url) {
    const parts = url.split(".");
    return parts[parts.length - 1];
  }
  const handleReadOrPlayClick = (contentUrl, contentType) => {
    const newTabUrl = `/fileViewer?contentUrl=${encodeURIComponent(
      contentUrl
    )}`;
    window.open(newTabUrl, "_blank");
  };
  const handleDownloadClick = async (contentUrl, index, type) => {
    try {
      let fileUrl = "";
      let fileExtension = getFileExtension(contentUrl);
      setFileDownloadLoader(index);
      setFileDownloadLoaderType(type);
      switch (fileExtension) {
        case "epub":
        case "pdf":
        case "mp3":
          fileUrl = contentUrl;
          break;
        default:
          console.error("Invalid file type:", fileExtension);
          return;
      }
      let fileName = contentUrl?.substring(contentUrl.lastIndexOf("/") + 1);

      fileName = fileName?.replace(
        /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/,
        ""
      );

      const response = await fetch(fileUrl);
      if (!response.ok) {
        setFileDownloadLoader(null);
        setFileDownloadLoaderType(null);
        throw new Error(`Failed to download file (HTTP ${response.status})`);
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      setFileDownloadLoader(null);
      setFileDownloadLoaderType(null);
      // Display a success toast message
      toast.success("File downloaded successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error("Error downloading the file:", error);

      // Display an error toast message
      toast.error("Error downloading the file. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  return (
    <section className="liveSatsangMain">
      <div className="d-flex liveSatsangHeader">
        <img
          src={AllImages.YellowArrow}
          alt="Back Arrow"
          title="Back Arrow"
          className="notification-icon"
          onClick={() => navigate(-1)}
        />
        <p className="liveSatsang-title">Live Satsang</p>
      </div>
      <div className="liveSatsang">
        <div className="liveStream">
          <img
            src={AllImages.YellowArrow}
            alt="Back Arrow"
            title="Back Arrow"
            className="liveStreamBackArrow notification-icon"
            onClick={() => navigate(-1)}
          />
          <ReactPlayer
            url={liveData.liveUrl}
            controls={true}
            playing={true}
            pip={true}
            stopOnUnmount={false}
            config={{
              youtube: {
                playerVars: { showinfo: 0 },
              },
            }}
            className="liveVideoPlay"
            // width="100%"
            // height="400px"
          />
          <div className="liveStreamDetails">
            <p className="streamTitle">{liveData?.title}</p>
            {/* <div className="streamDesc">
              <p className="streamDescText">Lorem ipsum dolor sit amet consectetur. Vitae tristique praesent tristique aliquet. In at eget netus sed maecenas aliquam. Vitae suspendisse sit eu tellus consequat. Vitae egestas venenatis nec sed cum ornare. Mauris eget eget massa adipiscing morbi... </p>
              <p className="streamDescReadMore cursor">Read More</p>
            </div> */}
          </div>
          {auth && (
            <div style={{ marginTop: "30px" }} className="mobile-ref-material">
              <p className="video-play-reference-material">
                {"Reference Material"}
              </p>
              {liveData.attachments && liveData.attachments.length > 0 ? (
                <div className="document_card">
                  {liveData.attachments?.map((content, index) => (
                    <div className="video-play-reference-material-card">
                      <p className="reference-material-card-title">
                        {capitalizeFirstLetter(content.contentType)}
                        <span className="reference-material-card-type">{`(${capitalizeFirstLetter(
                          getFileExtension(content.attachmentUrl)
                        )})`}</span>
                      </p>
                      <div className="video-play-d-flex video-play-jc-sb video-play-mt-30">
                        {fileDownloadLoader == index &&
                        fileDownloadLoaderType ==
                          capitalizeFirstLetter(content.contentType) ? (
                          <img
                            src={loader}
                            alt="loading..."
                            style={{ height: 20, width: 20 }}
                          />
                        ) : (
                          <div className="readTooltip">
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleDownloadClick(
                                  content.attachmentUrl,
                                  index,
                                  capitalizeFirstLetter(content.contentType)
                                )
                              }
                              src={AllImages?.YellowDownload}
                              alt="Download"
                              title="Download"
                            ></img>
                            <span className="tooltiptext">{"Download"}</span>
                          </div>
                        )}
                        <span
                          style={{ cursor: "pointer" }}
                          className="reference-material-download-type"
                          onClick={() =>
                            handleReadOrPlayClick(content.attachmentUrl)
                          }
                        >
                          {" "}
                          Read
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <NoDataAvailable />
              )}
            </div>
          )}
        </div>

        {auth && (
          <>
            {!replyPageShow ? (
              <div className="liveChatOuter">
                <div className="liveChatInner">
                  <div className="liveChat">
                    <img
                      className="closeDash"
                      src={AllImages?.CloseDash}
                      alt="Close"
                      title="Close"
                    ></img>
                    <div className="header">
                      <div className="headerTop">
                        <p className="title">Questions</p>
                        {/* <img className='closeOrange' src={AllImages?.CloseOrange} alt="Close" title="Close"></img> */}
                      </div>
                      <p className="subTitle">Top Questions</p>
                    </div>

                    <div className="chatBox" ref={chatBoxRef}>
                      <InfiniteScroll
                        dataLength={chatMessages.length}
                        next={getALLChat}
                        hasMore={hasMoreQuestions}
                        loader={hasMoreQuestions && <p>Loading...</p>}
                      >
                        {chatMessages?.map((chatMessage, index) => (
                          <div
                            key={index}
                            className={`chatCard ${
                              questionHistory
                                ? chatMessage.questionId === historyQuestionId
                                  ? "focused-question"
                                  : null
                                : null
                            }`}
                          >
                            <img
                              className="chatUser"
                              src={
                                chatMessage?.user?.imageUrl
                                  ? chatMessage?.user?.imageUrl
                                  : AllImages?.UserImage
                              }
                              alt="User Image"
                              title="User Image"
                            ></img>
                            <div className="chatMsg">
                              <p className="userName">
                                {chatMessage?.user?.firstName}{" "}
                                {chatMessage?.user?.lastName}
                                <span className="time">
                                  {formatTimestampWithAMPM(
                                    chatMessage.createdon
                                  )}
                                </span>
                              </p>
                              {/* <p className="msg">sachin</p> */}
                              <p className="msg">{chatMessage.question}</p>
                              <div className="user-interraction-section">
                                <p
                                  className="comment"
                                  onClick={() => commentReply(chatMessage)}
                                >
                                  Comment
                                </p>
                                {chatMessage?.childComment && (
                                  <div
                                    onClick={() =>
                                      handleViewReplies(chatMessage)
                                    }
                                  >
                                    {" "}
                                    {newReplyArray.includes(
                                      chatMessage.questionId
                                    ) ? (
                                      <img
                                        className="more"
                                        src={AllImages?.MoreGolden}
                                        alt="more"
                                        title="More"
                                      />
                                    ) : (
                                      <img
                                        className="more"
                                        src={AllImages?.More}
                                        alt="more"
                                        title="More"
                                      />
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </InfiniteScroll>
                      {hasMoreQuestions && !loading && (
                        <div onClick={getALLChat} className="load-more">
                          Load More+
                        </div>
                      )}
                    </div>
                    <div className="chat">
                      <input
                        type="text"
                        id="pincode"
                        name="pincode"
                        disabled={questionHistory}
                        placeholder="Type here..."
                        value={message}
                        onChange={(e) => handleMessage(e)}
                        onKeyUp={handleKeyPress}
                      />
                      <img
                        onClick={() => message.length > 0 && sendMessage()}
                        className="msgSend"
                        src={AllImages?.MsgSend}
                        alt="Msg Send"
                        title="Msg Send"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="liveChatOuter">
                <div className="liveChatInner">
                  <div className="liveChat">
                    <img
                      className="closeDash"
                      src={AllImages?.CloseDash}
                      alt="Close"
                      title="Close"
                    ></img>
                    <div className="header">
                      <div className="headerTop">
                        <p className="title">Questions</p>
                        <img
                          onClick={() => commentReplyClose()}
                          className="closeOrange"
                          src={AllImages?.CloseOrange}
                          alt="Close"
                          title="Close"
                        ></img>
                      </div>
                      <p className="subTitle multiline-text">{msgName} </p>
                    </div>
                    <div className="chatBox">
                      <InfiniteScroll
                        dataLength={getCommentReplyList.length}
                        next={() => getCommentReply(questionid)}
                        hasMore={hasMoreComments}
                        loader={hasMoreComments && <p>Loading...</p>}
                      >
                        {getCommentReplyList.map((chatMessage, index) => (
                          <div className="chatCard">
                            <img
                              className="chatUser"
                              src={
                                chatMessage?.user?.imageUrl
                                  ? chatMessage?.user?.imageUrl
                                  : AllImages?.UserImage
                              }
                              alt="User Image"
                              title="User Image"
                            ></img>
                            <div className="chatMsg">
                              <p className="userName">
                                {chatMessage?.user?.firstName}{" "}
                                {chatMessage?.user?.lastName}
                                <span className="time">
                                  {formatTimestampWithAMPM(
                                    chatMessage.createdon
                                  )}
                                </span>
                              </p>
                              <p className="msg">{chatMessage?.comment}</p>
                            </div>
                          </div>
                        ))}
                      </InfiniteScroll>
                      {hasMoreComments && !loading && (
                        <div
                          onClick={() => getCommentReply(questionid)}
                          className="load-more"
                        >
                          Load More+
                        </div>
                      )}
                    </div>
                    <div className="chat">
                      <input
                        type="text"
                        id="pincode"
                        name="pincode"
                        disabled={questionHistory}
                        placeholder="Type here..."
                        value={message}
                        onChange={(e) => handleMessage(e)}
                        onKeyUp={handleKeyPress}
                      />
                      <img
                        onClick={() => message.length > 0 && sendMessage()}
                        className="msgSend"
                        src={AllImages?.MsgSend}
                        alt="Msg Send"
                        title="Msg Send"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </section>
  );
}
export default LiveSatsang;
