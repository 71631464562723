import { PlusOne } from "@mui/icons-material";
import CurrentlyPlayingAudioPlaylist from "components/Pages/CurrentlyPlayingPlaylist";


// export const GLOBAL_URL = 'https://satshrut.vitraagvigyaan.org/api'
export const GLOBAL_URL = process.env.REACT_APP_BACKEND_API_URL;
export const VideoPlaylistShareUrl = process.env.REACT_APP_URL;
export const BhaktiPadPlaylistShareUrl = process.env.REACT_APP_URL;
// export const VideoPlaylistShareUrl = 'https://satshrut.vitraagvigyaan.org'
export const googleClientId =
  "795835921840-krm8aufr5776f1oonl5q4v8196jujk6m.apps.googleusercontent.com";

export const AllImages = {
  AboutUS: require("../assets/images/aboutUs.jpg"),
  PlayIcon: require("../assets/Phase2/play.svg").default,
  StopAlbum: require("../assets/Phase2/stopAlbum.png"),
  PlayAlbum: require("../assets/Phase2/playAlbum.png"),
  DeletePlayList: require("../assets/Phase2/deletePlaylist.png"),
  DeletePlayList2: require("../assets/Phase2/deletePlaylist2.png"),
  Fav: require("../assets/Phase2/favorite_border.svg").default,
  Timer: require("../assets/Phase2/timer.svg").default,
  SelectedTimer: require("../assets/Phase2/svg-selected.svg").default,
  Copy: require("../assets/Phase2/content_copy.svg").default,
  SearchToggle: require("../assets/Phase2/searchToggle.svg").default,
  SearchToggle2: require("../assets/Phase2/searchToggle2.svg").default,
  Search: require("../assets/Phase2/search.svg").default,
  SearchIcon: require("../assets/Phase2/searchIcon.svg").default,
  ListingBanner: require("../assets/Phase2/listingBanner.svg").default,
  BackArrow: require("../assets/Phase2/backArrow.svg").default,
  FavWhite: require("../assets/Phase2/fav-white.svg").default,
  CopyWhiteIcon: require("../assets/Phase2/copy-white.svg").default,
  PlayIconBig: require("../assets/Phase2/play-Iconbig.svg").default,
  PlayIconBigPNG: require("../assets/Phase2/playSparshPlaylist.svg").default,
  Favorite_sel: require("../assets/Phase2/favorite.svg").default,
  DefaultThumbnail: require("../assets/images/sddefault.jpg").default,
  Home: require("../assets/images/home.svg").default,
  HomeWhite: require("../assets/images/homeWhite.svg").default,
  EBbook: require("../assets/images/e-book.svg").default,
  EBbookWhite: require("../assets/images/e-bookWhite.svg").default,
  Gift: require("../assets/images/card_giftcard.svg").default,
  GiftWhite: require("../assets/images/card_giftcardWhite.svg").default,
  User: require("../assets/images/user.svg").default,
  UserWhite: require("../assets/images/userWhite.svg").default,
  More: require("../assets/images/more.svg").default,
  MoreWhite: require("../assets/images/moreWhite.svg").default,
  MoreGolden: require("../assets/images/moreGolden.svg").default,

  Dot: require("../assets/Phase2/dot.svg").default,
  UserImage: require("../assets/Phase2/defaultProfile.png"),
  CreateNote: require("../assets/Phase2/createnote.png"),
  SubscriptionsMore: require("../assets/Phase2/subscriptions.svg").default,
  AboutUsMore: require("../assets/Phase2/aboutUs.svg").default,
  FaqsMore: require("../assets/Phase2/faqs.svg").default,
  VideoIndexMore: require("../assets/Phase2/videoIndex.svg").default,
  ContactUsMore: require("../assets/Phase2/contactUs.svg").default,
  RightArrowOrange: require("../assets/Phase2/rightArrowOrange.svg").default,
  benefitsIcon: require("../assets/Phase2/beneIcon.svg").default,
  leftArrowOrange: require("../assets/Phase2/leftArrowOrange.svg").default,
  CloseOrange: require("../assets/Phase2/closeOrange.svg").default,
  CloseDash: require("../assets/Phase2/closeDash.svg").default,
  MsgSend: require("../assets/Phase2/msgSend.svg").default,
  EditIconOrange: require("../assets/Phase2/editIconOrange.svg").default,
  Photo: require("../assets/Phase2/photo.svg").default,
  Library: require("../assets/Phase2/library.svg").default,
  FormSubmit: require("../assets/Phase2/formSubmit.svg").default,
  BgRoundTop: require("../assets/Phase2/bgRoundTop.svg").default,
  ProfileBg: require("../assets/Phase2/profileBg.svg").default,
  VideoImage: require("../assets/Phase2/videoImage.svg").default,
  Download: require("../assets/Phase2/download.svg").default,
  Banned: require("../assets/Phase2/banned.svg").default,
  TableArrowDown: require("../assets/Phase2/tableArrowDown.svg").default,
  MultiLangSearch: require("../assets/Phase2/multiLangSearch.svg").default,
  Keyboard: require("../assets/Phase2/keyboard.svg").default,
  SearchOrange: require("../assets/Phase2/searchOrange.svg").default,
  CrossOrange: require("../assets/Phase2/crossOrange.svg").default,
  FullScreen: require("../assets/Phase2/fullscreen.svg").default,
  PauseOrange: require("../assets/Phase2/pauseOrange.svg").default,
  Logout: require("../assets/Phase2/logout.png").default,
  LogoutWhite: require("../assets/Phase2/logoutWhite.png").default,
  appleMusicSvg: require("../assets/Phase2/appleMusicIcon.svg").default,
  spotifyMusicSvg: require("../assets/Phase2/spotifyIconSvg.svg").default,

  MaskGroup: require("../assets/Phase2/maskGroup.svg").default,
  MobileMaskGroup: require("../assets/Phase2/mobileMaskGroup.svg").default,
  HomeBanner: require("../assets/Phase2/homeBanner.svg").default,
  CopyContent: require("../assets/Phase2/contentcopy.svg").default,
  LiveBackground: require("../assets/Phase2/liveBackground.svg").default,

  Logo: require("../assets/images/m-logo.svg").default,
  HomeSideBar: require("../assets/Phase2/SideBar/home.svg").default,
  Sparsh: require("../assets/Phase2/SideBar/sparsh.svg").default,
  sparshWhite: require("../assets/Phase2/SideBar/sparshWhite.svg").default,
  EBook: require("../assets/Phase2/SideBar/e-book.svg").default,
  Surprise: require("../assets/Phase2/SideBar/surprise.svg").default,
  MySpace: require("../assets/Phase2/SideBar/person.svg").default,
  Subscriptions: require("../assets/Phase2/SideBar/subscriptions.svg").default,
  AboutUs: require("../assets/Phase2/SideBar/about-us.svg").default,
  FAQs: require("../assets/Phase2/SideBar/forum.svg").default,
  VideoIndex: require("../assets/Phase2/SideBar/video-index.svg").default,
  ContactUs: require("../assets/Phase2/SideBar/contact-us.svg").default,
  Delete: require("../assets/Phase2/delete.png").default,
  YellowArrow: require("../assets/Phase2/yellowArrow.svg").default,
  ControlPoint: require("../assets/Phase2/control-point.svg").default,
  ReaderMode: require("../assets/Phase2/reader-mode.svg").default,
  ShareOutline: require("../assets/Phase2/share-outline.svg").default,
  YellowDownload: require("../assets/Phase2/save-alt.svg").default,
  DeleteCard: require("../assets/Phase2/delete.svg").default,
  AboutUSImage: require("../assets/images/about-us.svg").default,
  // AboutUSImageDesktop: require('../assets/images/about-usDesktop.svg').default,
  AboutUSImageDesktop: require("../assets/Phase2/playlistImage.svg").default,
  ReferenceMaterial: require("../assets/Phase2/reference-material-card.svg")
    .default,
  UploadDuration: require("../assets/Phase2/upload-duration.svg").default,
  NoDataAvailable: require("../assets/Phase2/noDataAvailable.svg").default,

  SparshTopImg: require("../assets/Phase2/sparsh.png"),
  Bhakti: require("../assets/Phase2/bhakti.png"),
  TablePlus: require("../assets/Phase2/plus.png"),
  TableMinus: require("../assets/Phase2/minus.png"),
  TableMore: require("../assets/Phase2/more.svg"),
  Time: require("../assets/Phase2/time.png"),
  Heart: require("../assets/Phase2/heart.png"),
  CopyPNG: require("../assets/Phase2/copy.png"),
  Album1: require("../assets/Phase2/album1.png"),
  Album2: require("../assets/Phase2/album2.png"),
  Album3: require("../assets/Phase2/album3.png"),
  Album4: require("../assets/Phase2/album4.png"),
  Album5: require("../assets/Phase2/album5.png"),
  Album6: require("../assets/Phase2/album6.png"),
  Album7: require("../assets/Phase2/album7.png"),
  Album8: require("../assets/Phase2/album8.png"),
  AlbumBig: require("../assets/Phase2/albumBig.png"),
  TrackPlayButton: require("../assets/Phase2/trackPlayButton.png"),

  AudioAvailable: require("../assets/Phase2/audioAvailable.png"),
  AudioNotAvailable: require("../assets/Phase2/audioNotAvailable.png"),


  Shuffle: require("../assets/Phase2/shuffle.png"),
  ShuffleActive: require("../assets/Phase2/shuffleActive.png"),
  Prev: require("../assets/Phase2/prev.png"),
  PadPlayBg: require("../assets/Phase2/padPlayBg.png"),
  PadPauseIcon: require("../assets/Phase2/padPlayIcon.png"),
  Next: require("../assets/Phase2/next.png"),
  PlayAll: require("../assets/Phase2/playAll.png"),
  RunningBar: require("../assets/Phase2/runningBar.png"),
  PlayDot: require("../assets/Phase2/playDot.png"),
  PlayBar: require("../assets/Phase2/playBar.png"),
  Volume: require("../assets/Phase2/volume.png"),
  Info: require("../assets/Phase2/info.png"),
  InfoIcon: require("../assets/Phase2/msgIcon.png"),
  LyricsAvailable: require("../assets/Phase2/lyricsAvailable.png"),
  LyricsNotAvailable: require("../assets/Phase2/lyricsNotAvailable.png"),
  Volume: require("../assets/Phase2/volume.png"),
  FavouriteOutline: require("../assets/Phase2/heartGray.png"),
  Song: require("../assets/Phase2/songGray.png"),
  CopyGray: require("../assets/Phase2/copyGray.png"),
  muteMusic: require("../assets/Phase2/muteMusic.png"),
  muteMusicOff: require("../assets/Phase2/muteMusicOff.png"),

  refMaterialNotAvailable: require("../assets/Phase2/refMaterialNotAvailable.png"),
  refMaterialAvailable: require("../assets/Phase2/refMaterialAvailable.png"),

  PlayNextAction: require("../assets/Phase2/playNextAction.svg").default,


};

export const emojiRegex =
  /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{2300}-\u{23FF}\u{2B50}\u{2B06}\u{1F004}\u{1F0CF}\u{1F004}\u{2B06}\u{2935}\u{2934}\u{25AA}\u{25AB}\u{25FE}\u{25FD}\u{25FB}\u{25FC}\u{25B6}\u{25C0}\u{1F200}-\u{1F251}]/u;

export function getImageNameFromResolution(imageUrl) {
  // Split the URL by '/' and get the last part, which is the filename
  const parts = imageUrl?.split("/");
  const filenameWithExtension = parts && parts[parts.length - 1];

  // Split the filename by '.' and take the first part (without extension)
  const filenameWithoutExtension = filenameWithExtension?.split(".")[0];

  return filenameWithoutExtension;
}

export function MinutesToHoursMinutes(minutes) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return hours + ":" + remainingMinutes;
}
export async function Authentication() {
  let userToken;
  userToken = null;
  try {
    userToken = await localStorage.getItem("userToken");
    if (userToken) {
      return userToken;
    } else {
      return userToken;
    }
  } catch (e) {
    console.log(e);
  }
}
export const formatTimeForTimeStamp = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;

  return formattedTime;
};
export function RemoveDuplicates(data) {
  const newdata = data.reduce((acc, current) => {
    const x = acc.find((item) => item.id === current.id);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
  return newdata;
}
export const isStringValid = (inputValue) => {
  const isValid = /.*[a-zA-Z].*/.test(inputValue);
  if (isValid) {
    return true;
  } else {
    return false;
  }
};

export const isNumberValid = (inputValue) => {
  const isValid = /^[0-9]+$/.test(inputValue);
  if (isValid) {
    return true;
  } else {
    return false;
  }
};
export function DateTimeAgo(inputDate) {
  if (inputDate) {
    const currentDate = new Date();
    const targetDate = new Date(inputDate);
    const differenceInMilliseconds = currentDate - targetDate;
    const oneMinute = 60 * 1000;
    const oneHour = 60 * oneMinute;
    const oneDay = 24 * oneHour;
    const oneMonth = 30 * oneDay;
    const oneYear = 12 * oneMonth;
    let timeAgoString = "";
    if (differenceInMilliseconds < oneMinute) {
      timeAgoString = "Just now";
    } else if (differenceInMilliseconds < oneHour) {
      const minutesAgo = Math.floor(differenceInMilliseconds / oneMinute);
      timeAgoString = `${minutesAgo} ${minutesAgo === 1 ? "Minute" : "Minutes"
        } ago`;
    } else if (differenceInMilliseconds < oneDay) {
      const hoursAgo = Math.floor(differenceInMilliseconds / oneHour);
      timeAgoString = `${hoursAgo} ${hoursAgo === 1 ? "Hour" : "Hours"} ago`;
    } else if (differenceInMilliseconds < oneMonth) {
      const daysAgo = Math.floor(differenceInMilliseconds / oneDay);
      timeAgoString = `${daysAgo} ${daysAgo === 1 ? "Day" : "Days"} ago`;
    } else if (differenceInMilliseconds < oneYear) {
      const monthsAgo = Math.floor(differenceInMilliseconds / oneMonth);
      timeAgoString = `${monthsAgo} ${monthsAgo === 1 ? "Month" : "Months"
        } ago`;
    } else {
      const yearsAgo = Math.floor(differenceInMilliseconds / oneYear);
      timeAgoString = `${yearsAgo} ${yearsAgo === 1 ? "Year" : "Years"} ago`;
    }
    return timeAgoString;
  } else {
    return "";
  }
}

// 10:20 pm
export function formatTimestampWithAMPM(timestamp) {
  if (timestamp) {
    const date = new Date(timestamp.replace(/-/g, "/"));
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }
}

// export const formatTimeForTimeStamp = (seconds) => {
//   const hours = Math.floor(seconds / 3600);
//   const minutes = Math.floor((seconds % 3600) / 60);
//   const remainingSeconds = Math.floor(seconds % 60);

//   const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;

//   return formattedTime;
// };

export const HeaderArray = {
  recentWatchedVideos: "Recently Watched",
  aboutUs: "About Us",
  createdPlaylist: "My Created Playlist",
  MySpace: "My Space",
  sharedPlaylist: "Shared Playlists",
  downloadedEbooks: "Downloaded E-BOOKS",
  questionHistory: "Question History",
  myFavorites: "My Favourites",
  watchLater: "Watch Later",
  allPlayList: "List of Playlists",
  downloadedEbooks: "Downloaded E-Books",
  sharedPlaylist: "Shared Playlists",
  questionHistory: "Question History",
  profile: "Profile",
  editProfile: "Edit Profile",
  subscriptionPlanList: "Subscription",
  activeSubscriptionPlan: "Subscription",
  fAQS: "FAQs",
  videosIndex: "Videos Index",
  contactUs: "Contact Us",
  eBooks: "E-Books",
  surpriseMe: "Surprise Me",
  eBookSeries: "E-Book Series",
  popularBhaktiPads: "Popular Bhakti Pads",
  newReleasePads: "New Release Bhakti Pads",
  allBhaktiPads: "All Bhakti Pads",
  sparsh: "Sparsh",
  albumList: "Album List",
  playlist: "Album",
  privacyPolicy: "Privacy Policy",
  individualBhaktipad: 'Bhakti Pad Lyrics',
  currentlyPlayingAudioPlaylist: 'Temporary Playlist'
};

export const hideHeader = {
  // myCreatePlayListSelectVideo: 'myCreatePlayListSelectVideo',
  // myCreatePlayListVideoAddBtnSuggested: 'myCreatePlayListVideoAddBtnSuggested',
  viewMore: "viewMore",
  // individualPlayList: 'individualPlayList',
  // liveSatsang: 'liveSatsang',
  // videoPlayPage: 'videoPlayPage',
  // playListPlayPage: 'playListPlayPage'
  // advanceSearch: 'advanceSearch'
};

export const MobileHideHeader = {
  individualPlayList: "individualPlayList",
  advanceSearch: "advanceSearch",
  playListPlayPage: "playListPlayPage",
  videoPlayPage: "videoPlayPage",
  liveSatsang: "liveSatsang",
  myCreatePlayListVideoAddBtnSuggested: "myCreatePlayListVideoAddBtnSuggested",
  myCreatePlayListSelectVideo: "myCreatePlayListSelectVideo",
  individualUserPlayList: "individualUserPlayList",
  PopularBhaktiPads: "Popular Bhakti Pads"
};

export function HeaderTitle(title) { }
