import React, { useState, useEffect, createContext } from "react";
export const VideoListContext = React.createContext();
export const FavoritesVideoListContext = React.createContext();
export const SearchVideoListContext = React.createContext();
export const SearchTextContext = React.createContext();
export const userDetailsContext = React.createContext();
export const SubcriptionDetailsContext = React.createContext();
export const SubcriptionHistoryContext = React.createContext();
export const SubcriptionListContext = React.createContext();
export const SearchLoaderContext = React.createContext();
export const PageReloadContext = React.createContext();
export const GSTContext = React.createContext();
export const SelectedSubcriptionContext = React.createContext();
export const SearchFiterClearContext = React.createContext();
export const VideoIndexContext = React.createContext();
export const SearchHistoryContext = React.createContext();
export const DataContext = React.createContext();

//second phase context
export const UserloggedContext = React.createContext();
export const AllVideoPlayListContext = React.createContext();
export const UserPlayListContext = React.createContext();
export const LoaderContext = React.createContext();
export const GoogleLoginShowContext = React.createContext();
export const WatchLaterVideosContext = React.createContext();
export const RecentWatchedVideosListContext = React.createContext();
export const SharedPlayListContext = React.createContext();
export const SharedPlayListContextAudio = React.createContext();

export const FavoritesPlayListContext = React.createContext();
export const ShowCreatePlayListContext = React.createContext();
export const RecentlyPlayListContext = React.createContext();
export const EBooksListContext = React.createContext();
export const EBooksFavoriteListContext = React.createContext();
export const AudiosFavoriteListContext = React.createContext();
export const EBooksSeriesFavouriteListContext = React.createContext();

export const SuggestedVideoListContext = React.createContext();
export const SurpriseMeContext = React.createContext();
export const SurpriseMeDataContext = React.createContext();
export const GetDowloadedEbooksContext = React.createContext();
export const ChangeFAQsLangContext = React.createContext();
export const AdvanceSearchTabContext = React.createContext();
export const AdvanceSearchDataContext = React.createContext();
export const AdvanceFilterValContext = React.createContext();
export const AdvanceSearchTextContext = React.createContext();
export const AdvanceNoDataFoundContext = React.createContext();
export const ActiveTabsContext = React.createContext();
export const SatsangAllLocationContext = React.createContext();
export const SatsangAllSpeakersContext = React.createContext();
export const QuestionHistoryContext = React.createContext();
export const EBookSeriesContext = React.createContext();
export const MusicPlayerVisibilityContext = createContext();
export const MusicTracksContext = createContext();
export const MusicTracksTestContext = createContext();
export const MusicTrackContext = createContext();
export const PlayNextContext = React.createContext();
export const CurrentTrackPlayingContext = React.createContext();
export const AlbumFavContext = createContext();
export const PlayingAlbumIdContext = createContext();
export const PlayingShuffledAlbumIdContext = createContext();
export const CurrentTrackIndex = createContext();
export const MusicContext = createContext();
export const StopVideoTimeContext = createContext();
export const PlayTimeForVideoContext = createContext();
export const MusicDataContext = createContext()
export const MusicPlayBlockContext = createContext()


export const MusicProvider = (props) => {
  const [musicData, setMusicData] = useState({
    popularPads: [],
    newPads: [],
    allPads: [],
    albums: []
  });

  const value = {
    musicData,
    setMusicData
  };

  return (
    <MusicContext.Provider value={value}>
      {props.children}
    </MusicContext.Provider>
  );
};


export const MusicDataProvider = (props) => {
  const [musicData, setMusicData] = useState([]);
  return (
    <MusicDataContext.Provider
      value={[musicData, setMusicData]}
    >
      {props.children}
    </MusicDataContext.Provider>
  );
};

export const MusicPlayBlockProvider = (props) => {
  const [playingBlock, setPlayingBlock] = useState(false);
  return (
    <MusicPlayBlockContext.Provider
      value={[playingBlock, setPlayingBlock]}
    >
      {props.children}
    </MusicPlayBlockContext.Provider>
  );
};

export const AllVideoPlayListProvider = (props) => {
  const [allVideoPlayList, setAllVideoPlayList] = useState([]);
  return (
    <AllVideoPlayListContext.Provider
      value={[allVideoPlayList, setAllVideoPlayList]}
    >
      {props.children}
    </AllVideoPlayListContext.Provider>
  );
};

export const UserPlayListProvider = (props) => {
  const [userPlayList, setUserPlayList] = useState([]);
  return (
    <UserPlayListContext.Provider value={[userPlayList, setUserPlayList]}>
      {props.children}
    </UserPlayListContext.Provider>
  );
};

export const LoaderProvider = (props) => {
  const [IsLoading, setIsLoading] = useState(false);
  return (
    <LoaderContext.Provider value={[IsLoading, setIsLoading]}>
      {props.children}
    </LoaderContext.Provider>
  );
};

export const UserloggedProvider = (props) => {
  const [auth, setAuth] = useState(null);
  return (
    <UserloggedContext.Provider value={[auth, setAuth]}>
      {props.children}
    </UserloggedContext.Provider>
  );
};

export const GoogleLoginShowProvider = (props) => {
  const [googleLoginShow, setGoogleLoginShow] = useState(false);
  return (
    <GoogleLoginShowContext.Provider
      value={[googleLoginShow, setGoogleLoginShow]}
    >
      {props.children}
    </GoogleLoginShowContext.Provider>
  );
};

export const WatchLaterVideosProvider = (props) => {
  const [watchLaterVideos, setWatchLaterVideos] = useState([]);
  return (
    <WatchLaterVideosContext.Provider
      value={[watchLaterVideos, setWatchLaterVideos]}
    >
      {props.children}
    </WatchLaterVideosContext.Provider>
  );
};

export const RecentWatchVideosListProvider = (props) => {
  const [recentWatchedVideoList, setRecentWatchedVideoList] = useState([]);
  return (
    <RecentWatchedVideosListContext.Provider
      value={[recentWatchedVideoList, setRecentWatchedVideoList]}
    >
      {props.children}
    </RecentWatchedVideosListContext.Provider>
  );
};

export const SharedPlayListProvider = (props) => {
  const [SharedPlaylist, setSharedPlayList] = useState([]);
  return (
    <SharedPlayListContext.Provider value={[SharedPlaylist, setSharedPlayList]}>
      {props.children}
    </SharedPlayListContext.Provider>
  );
};

export const SharedPlayListProviderAudio = (props) => {
  const [SharedPlaylistAudio, setSharedPlayListAudio] = useState([]);
  return (
    <SharedPlayListContextAudio.Provider value={[SharedPlaylistAudio, setSharedPlayListAudio]}>
      {props.children}
    </SharedPlayListContextAudio.Provider>
  );
};

export const MyFavoritePlayListProvider = (props) => {
  const [favoritePlayListData, setFavoritePlayListData] = useState([]);
  return (
    <FavoritesPlayListContext.Provider
      value={[favoritePlayListData, setFavoritePlayListData]}
    >
      {props.children}
    </FavoritesPlayListContext.Provider>
  );
};

export const ShowCreatePlayListProvider = (props) => {
  const [showCreatePlayListModel, setShowCreatePlayListModel] = useState(false);
  return (
    <ShowCreatePlayListContext.Provider
      value={[showCreatePlayListModel, setShowCreatePlayListModel]}
    >
      {props.children}
    </ShowCreatePlayListContext.Provider>
  );
};

export const RecentlyPlayListProvider = (props) => {
  const [recentlyPlayListData, setRecentlyPlayListData] = useState([]);
  return (
    <RecentlyPlayListContext.Provider
      value={[recentlyPlayListData, setRecentlyPlayListData]}
    >
      {props.children}
    </RecentlyPlayListContext.Provider>
  );
};

export const EBooksListProvider = (props) => {
  const [eBooksListData, setEBooksListData] = useState([]);
  return (
    <EBooksListContext.Provider value={[eBooksListData, setEBooksListData]}>
      {props.children}
    </EBooksListContext.Provider>
  );
};
export const EBooksFavoriteListProvider = (props) => {
  const [eBooksFavoriteList, setEBooksFavoriteList] = useState([]);
  return (
    <EBooksFavoriteListContext.Provider
      value={[eBooksFavoriteList, setEBooksFavoriteList]}
    >
      {props.children}
    </EBooksFavoriteListContext.Provider>
  );
};
export const AudiosFavoriteListProvider = (props) => {
  const [audiosFavoriteList, setAudiosFavoriteList] = useState([]);
  return (
    <AudiosFavoriteListContext.Provider
      value={[audiosFavoriteList, setAudiosFavoriteList]}
    >
      {props.children}
    </AudiosFavoriteListContext.Provider>
  );
};
export const EBooksSeriesFavoriteListProvider = (props) => {
  const [eBookSeriesFavoriteList, setEBookSeriesFavoriteList] = useState([]);
  return (
    <EBooksSeriesFavouriteListContext.Provider
      value={[eBookSeriesFavoriteList, setEBookSeriesFavoriteList]}
    >
      {props.children}
    </EBooksSeriesFavouriteListContext.Provider>
  );
};

export const SuggestedVideoProvider = (props) => {
  const [suggestedVideoList, setSuggestedVideoList] = useState([]);
  return (
    <SuggestedVideoListContext.Provider
      value={[suggestedVideoList, setSuggestedVideoList]}
    >
      {props.children}
    </SuggestedVideoListContext.Provider>
  );
};

export const SurpriseMeProvider = (props) => {
  const [surpriseMeShow, setSurpriseMeShow] = useState(false);
  return (
    <SurpriseMeContext.Provider value={[surpriseMeShow, setSurpriseMeShow]}>
      {props.children}
    </SurpriseMeContext.Provider>
  );
};

export const SurpriseMeDataProvider = (props) => {
  const [surpriseMeData, setSurpriseMeData] = useState({});
  return (
    <SurpriseMeDataContext.Provider value={[surpriseMeData, setSurpriseMeData]}>
      {props.children}
    </SurpriseMeDataContext.Provider>
  );
};

export const GetDowloadedEbookProvider = (props) => {
  const [getdownloadedEBook, setGetDownloadedEbook] = useState([]);
  return (
    <GetDowloadedEbooksContext.Provider
      value={[getdownloadedEBook, setGetDownloadedEbook]}
    >
      {props.children}
    </GetDowloadedEbooksContext.Provider>
  );
};

export const ChangeFAQsLangProvider = (props) => {
  const [language, setLanguage] = useState("english");
  return (
    <ChangeFAQsLangContext.Provider value={[language, setLanguage]}>
      {props.children}
    </ChangeFAQsLangContext.Provider>
  );
};

export const AdvanceSearchTabProvider = (props) => {
  const [advaceTabValue, setAdvaceTabValue] = useState("VIDEO");
  return (
    <AdvanceSearchTabContext.Provider
      value={[advaceTabValue, setAdvaceTabValue]}
    >
      {props.children}
    </AdvanceSearchTabContext.Provider>
  );
};

export const AdvanceSearchDataProvider = (props) => {
  const [advaceData, setAdvaceData] = useState([]);
  return (
    <AdvanceSearchDataContext.Provider value={[advaceData, setAdvaceData]}>
      {props.children}
    </AdvanceSearchDataContext.Provider>
  );
};

export const AdvanceFilterValProvider = (props) => {
  const [advaceFilterVal, setAdvaceFilterVal] = useState([
    {
      contentType: "VIDEO",
      pageNo: 1,
      pageSize: 10,
      title: null,
      searchValue: null,
      startDuration: null,
      endDuration: null,
      location: null,
      startDate: null,
      endDate: null,
      language: null,
      speaker: null,
      videotype: null,
    },
    {
      contentType: "EBOOK",
      pageNo: 0,
      pageSize: 10,
      searchValue: null,
      startDuration: null,
      endDuration: null,
      uploadDuration: null,
      author: null,
      language: null,
      publisher: null,
      comments: null,
      version: null,
    },
    {
      contentType: "PAD",
      pageNo: 0,
      pageSize: 10,
      searchValue: null,
      startDuration: null,
      endDuration: null,
      uploadDuration: null,
      title: null,
      author: null,
      writtenTo: null,
      language: null,
      startDate: null, // Start date and End date are based on Published English Date
      endDate: null,
      indianStartdate: null,
      indianEndDate: null,
    },
    {
      contentType: "PATRA",
      pageNo: 0,
      pageSize: 10,
      searchValue: null,
      startDuration: null,
      endDuration: null,
      uploadDuration: null,
      patraNoEnd: null, // integer value
      patraNoStart: null, // integer value
      author: null,
      writtenTo: null,
      yearOfPatraStart: null, // send only year as integer
      yearOfPatraEnd: null, // send only year as integer
      startDate: null, // Start date and End date are based on Published English Date
      endDate: null,
      indianStartdate: null,
      indianEndDate: null,
    },
    {
      contentType: "SYSTEM_PLAYLIST",
      pageNo: 0,
      pageSize: 10,
      searchValue: null,
      startDuration: null,
      endDuration: null,
      uploadDuration: null,
      speaker: null,
      location: null,
      startDate: null,
      endDate: null,
      title: null,
    },
    {
      contentType: "TRANSCRIPT",
      pageNo: 0,
      pageSize: 10,
      searchValue: null,
      startDuration: null,
      endDuration: null,
      uploadDuration: null,

      patraNoEnd: null, // patraNoStart , patraNoEnd   both are integer value
      patraNoStart: null,
      language: null,
      speaker: null,
      topic: null,
      startDate: null, // Start date and End date are based on Satsang Date
      endDate: null,
    },
    {
      contentType: "EBOOK_SYSTEM_PLAYLIST",
      pageNo: 0,
      pageSize: 10,
      searchValue: null,
      location: null,
      title: null,
      author: null,
      writtenTo: null,
      language: null,
      startDate: null, // Start date and End date are based on Published English Date
      endDate: null,
      writtenBy: null,
      recommendedBy: null,
    },
    {
      contentType: "YOUTUBE_TRACK",
      endDate: null,
      endDuration: null,
      pageNo: 0,
      pageSize: 10,
      searchValu: null,
      startDate: null,
      startDuration: null,
      title: null,
      author: null
    },
    {
      contentType: "YOUTUBE_ALBUM",
      endDate: null,
      endDuration: null,
      pageNo: 0,
      pageSize: 10,
      searchValue: null,
      startDate: null,
      startDuration: null,
      title: null,
      author: null
    }
  ]);
  return (
    <AdvanceFilterValContext.Provider
      value={[advaceFilterVal, setAdvaceFilterVal]}
    >
      {props.children}
    </AdvanceFilterValContext.Provider>
  );
};

export const AdvanceSearchTextProvider = (props) => {
  const [searchValue, setSearchValue] = useState(null);
  return (
    <AdvanceSearchTextContext.Provider value={[searchValue, setSearchValue]}>
      {props.children}
    </AdvanceSearchTextContext.Provider>
  );
};

export const AdvanceNoDataFoundProvider = (props) => {
  const [dataNoFound, setDataNotFound] = useState(false);
  return (
    <AdvanceNoDataFoundContext.Provider value={[dataNoFound, setDataNotFound]}>
      {props.children}
    </AdvanceNoDataFoundContext.Provider>
  );
};

export const ActiveTabsProvider = (props) => {
  const [activetabs, setActiveTabs] = useState([
    {
      active: "videos",
      page: "recentWatchedVideos",
    },
    {
      active: "videos",
      page: "watchLater",
    },
    {
      active: "videos",
      page: "MySpace",
    },
    {
      active: "videos",
      page: "myFavorites",
    },
    {
      active: "videos",
      page: "createdPlaylist",
    },
  ]);
  return (
    <ActiveTabsContext.Provider value={[activetabs, setActiveTabs]}>
      {props.children}
    </ActiveTabsContext.Provider>
  );
};

export const SatsangAllLocationProvider = (props) => {
  const [satsangLocations, setSatsangLocations] = useState([]);
  return (
    <SatsangAllLocationContext.Provider
      value={[satsangLocations, setSatsangLocations]}
    >
      {props.children}
    </SatsangAllLocationContext.Provider>
  );
};

export const SatsangAllSpeakersProvider = (props) => {
  const [satsangSpeaker, setSatsangSpeaker] = useState([]);
  return (
    <SatsangAllSpeakersContext.Provider
      value={[satsangSpeaker, setSatsangSpeaker]}
    >
      {props.children}
    </SatsangAllSpeakersContext.Provider>
  );
};

export const QuestionHistoryProvider = (props) => {
  const [questionHistoryList, setQuestionHistoryList] = useState([]);
  return (
    <QuestionHistoryContext.Provider
      value={[questionHistoryList, setQuestionHistoryList]}
    >
      {props.children}
    </QuestionHistoryContext.Provider>
  );
};

export const EBookSeriesProvider = (props) => {
  const [ebookSeriesList, setEbookSeriesListt] = useState([]);
  return (
    <EBookSeriesContext.Provider value={[ebookSeriesList, setEbookSeriesListt]}>
      {props.children}
    </EBookSeriesContext.Provider>
  );
};

export const UserDetailsProvider = (props) => {
  const [userDetails, setUserDetails] = useState([]);
  return (
    <userDetailsContext.Provider value={[userDetails, setUserDetails]}>
      {props.children}
    </userDetailsContext.Provider>
  );
};

export const VideoListProvider = (props) => {
  const [videoList, setVideoList] = useState([]);
  return (
    <VideoListContext.Provider value={[videoList, setVideoList]}>
      {props.children}
    </VideoListContext.Provider>
  );
};

export const SearchVideoListProvider = (props) => {
  const [searchVideoList, setSearchVideoList] = useState([]);
  return (
    <SearchVideoListContext.Provider
      value={[searchVideoList, setSearchVideoList]}
    >
      {props.children}
    </SearchVideoListContext.Provider>
  );
};

export const SearchTextProvider = (props) => {
  const [searchText, setSearchText] = useState(null);
  return (
    <SearchTextContext.Provider value={[searchText, setSearchText]}>
      {props.children}
    </SearchTextContext.Provider>
  );
};

export const FavoritesVideoListProvider = (props) => {
  const [favoritesVideoList, setFavoritesVideoList] = useState([]);
  return (
    <FavoritesVideoListContext.Provider
      value={[favoritesVideoList, setFavoritesVideoList]}
    >
      {props.children}
    </FavoritesVideoListContext.Provider>
  );
};

export const SubcriptionDetailProvider = (props) => {
  const [subscriptionDetails, setSubcriptionDetails] = useState([]);
  return (
    <SubcriptionDetailsContext.Provider
      value={[subscriptionDetails, setSubcriptionDetails]}
    >
      {props.children}
    </SubcriptionDetailsContext.Provider>
  );
};

export const SubcriptionHistoryProvider = (props) => {
  const [subscriptionHistory, setSubscriptionHistory] = useState([]);
  return (
    <SubcriptionHistoryContext.Provider
      value={[subscriptionHistory, setSubscriptionHistory]}
    >
      {props.children}
    </SubcriptionHistoryContext.Provider>
  );
};

export const SubcriptionListProvider = (props) => {
  const [subscriptionHistory, setSubscriptionHistory] = useState([]);
  return (
    <SubcriptionListContext.Provider
      value={[subscriptionHistory, setSubscriptionHistory]}
    >
      {props.children}
    </SubcriptionListContext.Provider>
  );
};

export const SearchLoaderProvider = (props) => {
  const [searchLoader, setSearchLoader] = useState(false);
  return (
    <SearchLoaderContext.Provider value={[searchLoader, setSearchLoader]}>
      {props.children}
    </SearchLoaderContext.Provider>
  );
};

export const PageReloadProvider = (props) => {
  const [pageReload, setPageReload] = useState(false);
  return (
    <PageReloadContext.Provider value={[pageReload, setPageReload]}>
      {props.children}
    </PageReloadContext.Provider>
  );
};

export const GSTProvider = (props) => {
  const [gstpagemanage, setGstpagemanage] = useState("");
  return (
    <GSTContext.Provider value={[gstpagemanage, setGstpagemanage]}>
      {props.children}
    </GSTContext.Provider>
  );
};

export const SelectedSubcriptionProvider = (props) => {
  const [selectedSubscription, setSelectedSubscription] = useState("");
  return (
    <SelectedSubcriptionContext.Provider
      value={[selectedSubscription, setSelectedSubscription]}
    >
      {props.children}
    </SelectedSubcriptionContext.Provider>
  );
};

export const SearchFiterClearProvider = (props) => {
  const [filterValueClear, setFilterValueClear] = useState(true);
  return (
    <SearchFiterClearContext.Provider
      value={[filterValueClear, setFilterValueClear]}
    >
      {props.children}
    </SearchFiterClearContext.Provider>
  );
};

export const VideoIndexProvider = (props) => {
  const [videoIndexData, setVideIndexData] = useState([]);
  return (
    <VideoIndexContext.Provider value={[videoIndexData, setVideIndexData]}>
      {props.children}
    </VideoIndexContext.Provider>
  );
};

export const SearchHistoryProvider = (props) => {
  const [searchHistory, setSearchHistory] = useState(null);
  return (
    <SearchHistoryContext.Provider value={[searchHistory, setSearchHistory]}>
      {props.children}
    </SearchHistoryContext.Provider>
  );
};
export const MusicPlayerVisibilityProvider = (props) => {
  const [isMusicPlayerVisible, setIsMusicPlayerVisible] = useState(false);
  return (
    <MusicPlayerVisibilityContext.Provider value={[isMusicPlayerVisible, setIsMusicPlayerVisible]}>
      {props.children}
    </MusicPlayerVisibilityContext.Provider>
  )
}
export const MusicTracksProvider = (props) => {
  const [track, setTrack] = useState({
    trackId: '',
    title: '',
    subtitle: '',
    play: false,
    imgUrl: '',
  });
  return (
    <MusicTracksContext.Provider value={{ track, setTrack }}>
      {props.children}
    </MusicTracksContext.Provider>
  );
};

export const MusicTracksTestProvider = (props) => {
  const [testTrack, setTestTrack] = useState({
    trackId: '',
    title: '',
    subtitle: '',
    play: false,
    imgUrl: '',
  });
  return (
    <MusicTracksTestContext.Provider value={{ testTrack, setTestTrack }}>
      {props.children}
    </MusicTracksTestContext.Provider>
  );
};


export const MusicTrackProvider = (props) => {
  const [singleTrack, setSingleTrack] = useState({
    trackId: '',
    title: '',
    subtitle: '',
    play: false,
    imgUrl: '',
  });
  return (
    <MusicTrackContext.Provider value={{ singleTrack, setSingleTrack }}>
      {props.children}
    </MusicTrackContext.Provider>
  );
};

export const PlayNextProvider = (props) => {
  const [playNextTrack, setPlayNextTrack] = useState({
    trackId: '',
    title: '',
    subtitle: '',
    play: false,
    imgUrl: '',
  });
  return (
    <PlayNextContext.Provider value={{ playNextTrack, setPlayNextTrack }}>
      {props.children}
    </PlayNextContext.Provider>
  );
};
export const PlayingAlbumIdProvider = (props) => {
  const [playingAlbumId, setPlayingAlbumId] = useState(null);
  return (
    <PlayingAlbumIdContext.Provider value={{ playingAlbumId, setPlayingAlbumId }}>
      {props.children}
    </PlayingAlbumIdContext.Provider>
  );
};

export const PlayingShuffledAlbumIdProvider = (props) => {
  const [playingShuffledAlbumId, setPlayingShuffledAlbumId] = useState(null);
  return (
    <PlayingShuffledAlbumIdContext.Provider value={{ playingShuffledAlbumId, setPlayingShuffledAlbumId }}>
      {props.children}
    </PlayingShuffledAlbumIdContext.Provider>
  );
};

export const CurrentTrackIndexProvider = (props) => {
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  return (
    <CurrentTrackIndex.Provider value={{ currentTrackIndex, setCurrentTrackIndex }}>
      {props.children}
    </CurrentTrackIndex.Provider>
  );
};

export const CurrentTrackPlayingProvider = (props) => {
  const [currentTrackPlaying, setCurrentTrackPlaying] = useState({
    trackId: '',
    title: '',
    subtitle: '',
    play: false,
    imgUrl: '',
  });
  return (
    <CurrentTrackPlayingContext.Provider value={{ currentTrackPlaying, setCurrentTrackPlaying }}>
      {props.children}
    </CurrentTrackPlayingContext.Provider>
  );
};

export const StopVideoTimeProvider = (props) => {
  const [stopTime, setStopTime] = useState([]);
  return (
    <StopVideoTimeContext.Provider value={[ stopTime, setStopTime ]}>
      {props.children}
    </StopVideoTimeContext.Provider>
  );
};

export const PlayTimeForVideoProvider = (props) => {
  const [playTimeForVideo, setPlayTimeForVideo] = useState(0);
  return (
    <PlayTimeForVideoContext.Provider value={[ playTimeForVideo, setPlayTimeForVideo ]}>
      {props.children}
    </PlayTimeForVideoContext.Provider>
  );
};

export const AlbumFavProvider = (props) => {
  const [albumFavList, setAlbumFavList] = useState([]);
  return (
    <AlbumFavContext.Provider value={[albumFavList, setAlbumFavList]}>
      {props.children}
    </AlbumFavContext.Provider>
  )
}