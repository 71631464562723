export const addTrack = (track) => ({
  type: 'ADD_TRACK',
  payload: track
});

export const deleteTracks = () => ({
  type: 'DELETE_TRACKS',
});

export const deleteTrack = (trackId) => ({
  type: 'DELETE_TRACK',
  payload: trackId,
});


