import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import "./style.css";
import InputIcon from "react-multi-date-picker/components/input_icon";
import Bell from "../../../assets/images/bell.svg";
import {
  LoaderContext,
  UserloggedContext,
  ShowCreatePlayListContext,
  ChangeFAQsLangContext,
  AdvanceSearchTabContext,
  AdvanceSearchDataContext,
  AdvanceFilterValContext,
  AdvanceSearchTextContext,
  AdvanceNoDataFoundContext,
  SatsangAllLocationContext,
  SatsangAllSpeakersContext,
} from "../../Context/UserContext";
import { AuthContext } from "../../Context/context";
import { userDetailsContext, GSTContext } from "../../Context/UserContext";
import ApiEndPoint from "../../../utils/apiEndPoints";
import { apiCall } from "../../../utils/httpClient";
import {
  googleClientId,
  hideHeader,
  AllImages,
  HeaderArray,
  MobileHideHeader,
  emojiRegex,
  isStringValid,
  isNumberValid,
} from "../../../utils/constants";

import "react-confirm-alert/src/react-confirm-alert.css";

import { gapi } from "gapi-script";
import {
  BrowserRouter as Router,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Google from "../../../assets/images/google.png";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box } from "@material-ui/core";
import Slider from "@mui/material/Slider";
import { useTheme, useMediaQuery, TextField } from "@material-ui/core";

import ReCAPTCHA from "react-google-recaptcha";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import useGoogleLoginFlow from "../useGoogleLoginFlow";
import { confirmAlert } from "react-confirm-alert";
import Button from "@material-ui/core/Button";
import DatePicker, { DatePickerRef } from "react-multi-date-picker";
import indian from "react-date-object/calendars/indian";

import indian_hi from "react-date-object/locales/indian_hi";
import Icon from "react-multi-date-picker/components/icon";
import { toast } from "react-toastify";

const clientId = googleClientId;
const TEST_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathParts = location?.pathname?.split("/");
  const currentPage = pathParts[1];
  const contentType = pathParts[2];
  const searchKey = pathParts[3];

  const startDuration = new URLSearchParams(location.search).get(
    "startDuration"
  );
  const endDuration = new URLSearchParams(location.search).get("endDuration");
  const uploadDuration = new URLSearchParams(location.search).get(
    "uploadDuration"
  );
  const videolocation = new URLSearchParams(location.search).get(
    "videolocation"
  );
  const startDate = new URLSearchParams(location.search).get("startDate");
  const endDate = new URLSearchParams(location.search).get("endDate");
  const languages = new URLSearchParams(location.search).get("language");
  const speaker = new URLSearchParams(location.search).get("speaker");
  const videotype = new URLSearchParams(location.search).get("videotype");
  const title = new URLSearchParams(location.search).get("title");
  const author = new URLSearchParams(location.search).get("author");
  const writtenTo = new URLSearchParams(location.search).get("writtenTo");
  const writtenBy = new URLSearchParams(location.search).get("writtenBy");
  const recommendedBy = new URLSearchParams(location.search).get(
    "recommendedBy"
  );
  const publisher = new URLSearchParams(location.search).get("publisher");
  const comments = new URLSearchParams(location.search).get("comments");
  const version = new URLSearchParams(location.search).get("version");
  const topic = new URLSearchParams(location.search).get("topic");
  const patraNoStart = new URLSearchParams(location.search).get("patraNoStart");
  const patraNoEnd = new URLSearchParams(location.search).get("patraNoEnd");
  const yearOfPatraStart = new URLSearchParams(location.search).get(
    "yearOfPatraStart"
  );
  const yearOfPatraEnd = new URLSearchParams(location.search).get(
    "yearOfPatraEnd"
  );
  const indianCalFrom = new URLSearchParams(location.search).get(
    "indianCalFrom"
  );
  const indianCalTo = new URLSearchParams(location.search).get("indianCalTo");

  const {
    openRecaptcha,

    recaptchaFun,

    onChange,
  } = useGoogleLoginFlow();
  const [IsLoading, setIsLoading] = useContext(LoaderContext);

  const [auth, setAuth] = useContext(UserloggedContext);
  const [advaceTabValue, setAdvaceTabValue] = useContext(
    AdvanceSearchTabContext
  );
  const [advaceFilterVal, setAdvaceFilterVal] = useContext(
    AdvanceFilterValContext
  );
  const [advaceData, setAdvaceData] = useContext(AdvanceSearchDataContext);
  const [dataNoFound, setDataNotFound] = useContext(AdvanceNoDataFoundContext);
  const [language, setLanguage] = useContext(ChangeFAQsLangContext);
  const [showCreatePlayListModel, setShowCreatePlayListModel] = useContext(ShowCreatePlayListContext);













  //YOUTUBE_TRACK
  const [bhaktiPadFromDate, setBhaktiPadFromDate] = useState(null);
  const [bhaktiPadToDate, setBhaktiPadToDate] = useState(null);
  const [bhaktiPadAuthor, setBhaktiPadAuthor] = useState(null);
  const [bhaktiPadTitle, setBhaktiPadTitle] = useState(null);
  const [bhaktiPadArtist, setBhaktiPadArtist] = useState(null);
  const [bhaktiPadDuration, setBhaktiPadDuration] = useState([
    startDuration ? startDuration : 1,
    endDuration ? endDuration : 500,]);

  //YOUTUBE_ALBUM
  const [albumFromDate, setAlbumFromDate] = useState(null);
  const [albumToDate, setAlbumToDate] = useState(null);
  const [albumAuthor, setAlbumAuthor] = useState(null);
  const [albumTitle, setAlbumTitle] = useState(null);
  const [albumDuration, setAlbumDuration] = useState([
    startDuration ? startDuration : 1,
    endDuration ? endDuration : 500,]);



  const [currentTimeOut, setCurrentTimeOut] = useState(null);
  const path = location.pathname;
  const { pathname } = location;
  const splitLocation = pathname?.split("/");
  
  
  
  

  const [isOpen, setIsOpen] = useState(false);
  const [userDetails, setUserDetails] = useContext(userDetailsContext);
  const [satsangLocations, setSatsangLocations] = useContext(
    SatsangAllLocationContext
  );
  const [satsangSpeaker, setSatsangSpeaker] = useContext(
    SatsangAllSpeakersContext
  );

  const [value, setValue] = React.useState([
    startDuration ? startDuration : 1,
    endDuration ? endDuration : 500,
  ]);
  const [playlistValue, setPlaylistValue] = useState([0, 6]);
  const [ebookValue, setEbookValue] = useState([80, 240]);
  const [patraValue, setPatraValue] = useState([80, 240]);
  const [padValue, setPadValue] = useState([80, 240]);
  const [transcriptValue, setTranscriptValue] = useState([80, 240]);

  const [filterlocation, setFilterLocation] = useState(null);
  const [filterPlayListlocation, setFilterPlayListlocation] = useState(null);
  const [filterEbookSerieslocation, setFilterEbookSerieslocation] =
    useState(null);

  const [searchValue, setSearchValue] = useContext(AdvanceSearchTextContext);

  const [isValid, setIsValid] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [filterValChange, setFilterValChnage] = useState(false);

  const [fromDate, setFromDate] = useState(null);
  const [playListFromDate, setPlayListFromDate] = useState(null);
  const [padFromDate, setPadFromDate] = useState(null);
  const [transcriptFromDate, setTranscriptFromDate] = useState(null);
  const [ebookSeriesFromDate, setEbookSeriesFromDate] = useState(null);
  const [transcriptToDate, setTranscriptToDate] = useState(null);
  const [ebookSeriesToDate, setEbookSeriesToDate] = useState(null);

  const [patraToDate, setPatraToDate] = useState(null);
  const [patraFromDate, setPatraFromDate] = useState(null);

  const [toDate, setToDate] = useState(null);
  const [playListToDate, setPlayListToDate] = useState(null);
  const [padToDate, setPadToDate] = useState(null);

  const [type, setType] = useState("");
  const [filLanguage, setFilLanguage] = useState(null);
  const [ebookLanguage, setEbookLanguage] = useState(null);
  const [padLanguage, setPadLanguage] = useState(null);
  const [transcriptLanguage, setTranscriptLanguage] = useState(null);

  const [inputTitle, setInputTitle] = useState(null);
  const [playListTitle, setPlayListTitle] = useState(null);
  const [padTitle, setPadTitle] = useState(null);
  const [ebookSeriesTitle, setEbookSeriesTitle] = useState(null);

  const [padAuthor, setPadAuthor] = useState(null);
  const [ebookAuthor, setEbookAuthor] = useState(null);
  const [patraAuthor, setPatraAuthor] = useState(null);
  const [ebookSeriesAuthor, setEbookSeriesAuthor] = useState(null);

  const [padWrittenTo, setPadWrittenTo] = useState(null);
  const [patraWrittenTo, setPatraWrittenTo] = useState(null);
  const [ebookSeriesWrittenTo, setEbookSeriesWrittenTo] = useState(null);
  const [ebookSeriesWrittenBy, setEbookSeriesWrittenBy] = useState(null);
  const [ebookSeriesRecommendedBy, setEbookSeriesRecommendedBy] =
    useState(null);

  const [ebookPublisher, setEbookPublisher] = useState(null);
  const [ebookVersion, setEbookVersion] = useState(null);
  const [ebookComments, setEbookComments] = useState(null);
  const [transcriptTopic, setTranscriptTopic] = useState(null);

  const [filterSpeakers, setFilterSpeakers] = useState(null);
  const [filterPlaylistSpeakers, setFilterPlaylistSpeakers] = useState(null);
  const [transcriptSpeakers, setTranscriptSpeakers] = useState(null);

  const [transcriptPatraNoStart, setTranscriptPatraNoStart] = useState(null);
  const [patrapatraNoStart, setPatraPatraNoStart] = useState(null);
  const [transcriptPatraNoEnd, setTranscriptPatraNoEnd] = useState(null);
  const [patrapatraNoEnd, setPatraPatraNoEnd] = useState(null);
  const [yearPatraStart, setYearPatraStart] = useState(null);
  const [yearPatraEnd, setYearPatraEnd] = useState(null);
  const [indianCalndrFromDate, setIndianCalndrFromDate] = useState(null);
  const [padIndianCalndrFromDate, setPadIndianCalndrFromDate] = useState(null);
  const [indianCalndrToDate, setIndianCalndrToDate] = useState(null);
  const [padIndianCalndrToDate, setPadIndianCalndrToDate] = useState(null);
  const [indianCalndrFromDateObj, setIndianCalndrFromDateObj] = useState(null);
  const [indianCalndrToDateObj, setIndianCalndrToDateObj] = useState(null);
  const [errorArray, setErrorArray] = useState([]);
  const [currentPromptTimeout, setCurrentPromptTimeout] = useState(null);
  const [isLoginModalOpen,setIsLoginModalOpen] = useState(false)
  const handleOpenLoginModal=()=>{
    setIsLoginModalOpen(true)
  }
  const handleCloseLoginModal=()=>{
    setIsLoginModalOpen(false)
  }
  useLayoutEffect(() => {

    if (currentPromptTimeout) {
      clearTimeout(currentPromptTimeout);
      setCurrentPromptTimeout(null);
    }
    if (!auth) {
      const timeOut = setTimeout(handleOpenLoginModal, 3000);
      setCurrentPromptTimeout(timeOut);
    }
  }, [auth]);
  // useEffect(() => {
  //   function askForLogin() {
  //     confirmAlert({
  //       customUI: ({ onClose }) => {
  //         return (
  //           <div className="card">
  //             <div className="info__padding">
  //               <h3>{`You are logged out. Do you want to log in ? `}</h3>
  //               {/* <h3>{!auth ? "Sign In" : ""}</h3> */}
  //             </div>

  //             <div className="button__group">
  //               <Button
  //                 onClick={() => {
  //                   onChange(true);
  //                   // onClose();
  //                 }}
  //                 variant="contained"
  //                 className="signBtnHome"
  //               >
  //                 <img
  //                   style={{ height: "23px", width: "23px" }}
  //                   className="g-icon"
  //                   src={Google}
  //                 ></img>
  //                 Sign-in
  //               </Button>
  //               <button className="no-btn" onClick={onClose}>
  //                 Cancel
  //               </button>
  //             </div>
  //           </div>
  //         );
  //       },
  //     });
  //   }
  //   if (currentPromptTimeout) {
  //     clearTimeout(currentPromptTimeout);
  //     setCurrentPromptTimeout(null);
  //   }
  //   if (!auth) {
  //     const timeOut = setTimeout(askForLogin, 3000);
  //     setCurrentPromptTimeout(timeOut);
  //   }
  // }, [auth]);
  useEffect(() => {
    // setValue([1, 500])
    if (contentType == "VIDEO") {
      setFromDate(startDate ? startDate : "");
      setToDate(endDate ? endDate : "");
      setFilLanguage(languages ? languages : "");
      setFilterSpeakers(speaker ? speaker : "");
      setType(videotype ? videotype : "");
      setFilterLocation(videolocation ? videolocation : "");
      setInputTitle(title ? title : null);
    } else if (contentType == "SYSTEM_PLAYLIST") {
      setFilterPlayListlocation(videolocation ? videolocation : "");
      setFilterPlaylistSpeakers(speaker ? speaker : "");
      setPlayListToDate(endDate ? endDate : "");
      setPlayListFromDate(startDate ? startDate : "");
      setPlayListTitle(title ? title : null);
    } else if (contentType == "EBOOK") {
      setEbookAuthor(author ? author : null);
      setEbookLanguage(languages ? languages : "");
      setEbookPublisher(publisher ? publisher : null);
      setEbookVersion(version ? version : null);
      setEbookComments(comments ? comments : null);
    } else if (contentType == "PAD") {
      setPadTitle(title ? title : null);
      setPadFromDate(startDate ? startDate : "");
      setPadToDate(endDate ? endDate : "");
      setPadLanguage(languages ? languages : "");
      setPadAuthor(author ? author : null);
      setPadWrittenTo(writtenTo ? writtenTo : null);
      setPadIndianCalndrFromDate(indianCalFrom ? indianCalFrom : null);
      setPadIndianCalndrToDate(indianCalTo ? indianCalTo : null);
    } else if (contentType == "PATRA") {
      setPatraFromDate(startDate ? startDate : "");
      setPatraAuthor(author ? author : null);
      setPatraToDate(endDate ? endDate : "");
      setPatraWrittenTo(writtenTo ? writtenTo : null);
      setPatraPatraNoStart(patraNoStart ? patraNoStart : null);
      setPatraPatraNoEnd(patraNoEnd ? patraNoEnd : null);
      setYearPatraStart(yearOfPatraStart ? yearOfPatraStart : null);
      setYearPatraEnd(yearOfPatraEnd ? yearOfPatraEnd : null);
      setIndianCalndrFromDate(indianCalFrom ? indianCalFrom : null);
      setIndianCalndrToDate(indianCalTo ? indianCalTo : null);
    } else if (contentType == "TRANSCRIPT") {
      setTranscriptFromDate(startDate ? startDate : "");
      setTranscriptToDate(endDate ? endDate : "");
      setTranscriptLanguage(languages ? languages : "");
      setTranscriptSpeakers(speaker ? speaker : "");
      setTranscriptTopic(topic ? topic : null);
      setTranscriptPatraNoStart(patraNoStart ? patraNoStart : null);
      setTranscriptPatraNoEnd(patraNoEnd ? patraNoEnd : null);
    } else if (contentType == "EBOOK_SYSTEM_PLAYLIST") {
      setEbookSeriesTitle(title ? title : null);
      setEbookSeriesAuthor(author ? author : null);
      setEbookSeriesWrittenTo(writtenTo ? writtenTo : null);
      setEbookSeriesWrittenBy(writtenBy ? writtenBy : null)
      setEbookSeriesFromDate(startDate ? startDate : "")
      setEbookSeriesToDate(endDate ? endDate : "");
      setFilterEbookSerieslocation(videolocation ? videolocation : "");
      setEbookSeriesRecommendedBy(recommendedBy ? recommendedBy : null)
    } else if (contentType == "YOUTUBE_TRACK") {
      setBhaktiPadTitle(title ? title : null);
      setBhaktiPadAuthor(author ? author : null);
      setBhaktiPadFromDate(startDate)
      setBhaktiPadToDate(endDate);
    } else if (contentType == "YOUTUBE_ALBUM") {
      setAlbumFromDate(startDate);
      setAlbumToDate(endDate);
      setAlbumAuthor(author ? author : null);
      setAlbumTitle(title ? title : null)
    }
  }, [
    videotype,
    languages,
    endDate,
    startDate,
    speaker,
    videolocation,
    title,
    topic,
    patraNoStart,
    patraNoEnd,
    yearOfPatraEnd,
    yearOfPatraStart,
    writtenTo,
    author,
    comments,
    version,
    publisher,
    indianCalFrom,
    indianCalTo,
  ]);

  const getFormatedDate = (date) => {
    if (date) {
      const today = new Date(date);
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;

      const formattedToday = yyyy + "-" + mm + "-" + dd;
      return formattedToday;
    }
  };

  const generateDynamicMarksArray = (start, end, numElements) => {
    const marksArray = [];
    const step = (end - start) / (numElements - 1);

    for (let i = start; i <= end; i += step) {
      marksArray.push({ value: Math.round(i), label: `${Math.round(i)}m` });
    }

    return marksArray;
  };

  const handleChangeFromDate = (date) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          startDate: getFormatedDate(date),
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    if (contentType == "VIDEO") {
      setFromDate(date);
    } else if (contentType == "SYSTEM_PLAYLIST") {
      setPlayListFromDate(date);
    } else if (contentType == "EBOOK") {
    } else if (contentType == "PAD") {
      setPadFromDate(date);
    } else if (contentType == "PATRA") {
      setPatraFromDate(date);
    } else if (contentType == "TRANSCRIPT") {
      setTranscriptFromDate(date);
    } else if (contentType == "EBOOK_SYSTEM_PLAYLIST") {
      setEbookSeriesFromDate(date);
    } else if (contentType == "YOUTUBE_TRACK") {
      setBhaktiPadFromDate(date)
    } else if (contentType == "YOUTUBE_ALBUM") {
      setAlbumFromDate(date)
    }
  };

  const handleChangeToDate = (date) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          endDate: getFormatedDate(date),
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);

    if (contentType == "VIDEO") {
      setToDate(date);
    } else if (contentType == "SYSTEM_PLAYLIST") {
      setPlayListToDate(date);
    } else if (contentType == "EBOOK") {
    } else if (contentType == "PAD") {
      setPadToDate(date);
    } else if (contentType == "PATRA") {
      setPatraToDate(date);
    } else if (contentType == "TRANSCRIPT") {
      setTranscriptToDate(date);
    } else if (contentType == "EBOOK_SYSTEM_PLAYLIST") {
      setEbookSeriesToDate(date);
    } else if (contentType == "YOUTUBE_TRACK") {
      setBhaktiPadToDate(date)
    } else if (contentType == "YOUTUBE_ALBUM") {
      setAlbumToDate(date)
    }
  };

  const handleChangeLanguages = (event) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          language: event.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    if (contentType == "VIDEO") {
      setFilLanguage(event.target.value);
    } else if (contentType == "SYSTEM_PLAYLIST") {
    } else if (contentType == "EBOOK") {
      setEbookLanguage(event.target.value);
    } else if (contentType == "PAD") {
      setPadLanguage(event.target.value);
    } else if (contentType == "PATRA") {
    } else if (contentType == "TRANSCRIPT") {
      setTranscriptLanguage(event.target.value);
    }
  };

  const handleChangeType = (event) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === "VIDEO") {
        return {
          ...item,
          videotype: event.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    setType(event.target.value);
  };

  const handleSpeakers = (event) => {
    const updatedSpeakerValue = event.target.value;

    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        if (contentType === "YOUTUBE_TRACK") {
          return {
            ...item,
            speaker: updatedSpeakerValue,
            author: updatedSpeakerValue,
          };
        } else {
          return {
            ...item,
            speaker: updatedSpeakerValue,
          };
        }
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    if (contentType === "VIDEO") {
      setFilterSpeakers(updatedSpeakerValue);
    } else if (contentType === "SYSTEM_PLAYLIST") {
      setFilterPlaylistSpeakers(updatedSpeakerValue);
    } else if (contentType === "EBOOK") {
    } else if (contentType === "PAD") {
    } else if (contentType === "PATRA") {
    } else if (contentType === "TRANSCRIPT") {
      setTranscriptSpeakers(updatedSpeakerValue);
    } else if (contentType === "YOUTUBE_TRACK") {
      setBhaktiPadArtist(updatedSpeakerValue);
    }
  };


  const uploadDurationArray = [
    {
      value: "TODAY",
      lable: "TODAY",
    },
    {
      value: "THIS_WEEK",
      lable: "This week",
    },
    {
      value: "THIS_MONTH",
      lable: "This month",
    },
    {
      value: "THIS_YEAR",
      lable: "This year",
    },
    {
      value: "THREE_YEARS_AGO",
      lable: "2-3 year ago",
    },
  ];

  useEffect(() => {
    var updatedAuthor = author;
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === contentType) {
        return {
          ...item,
          searchValue: searchKey,
          startDuration: startDuration ? parseInt(startDuration) : null,
          endDuration: endDuration ? parseInt(endDuration) : null,
          uploadDuration: uploadDuration,
          location: videolocation,
          startDate: startDate,
          endDate: endDate,
          language: languages,
          speaker: speaker,
          videotype: videotype,
          title: title,
          author: updatedAuthor,
          writtenTo: writtenTo,
          writtenBy: writtenBy,
          publisher: publisher,
          version: version,
          comments: comments,
          topic: topic,
          patraNoStart: patraNoStart,
          patraNoEnd: patraNoEnd,
          yearOfPatraStart: yearOfPatraStart,
          yearOfPatraEnd: yearOfPatraEnd,
          indianCalTo: indianCalTo,
          indianCalFrom: indianCalFrom,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);

    if (contentType == "VIDEO") {
      setValue([
        startDuration ? startDuration : 1,
        endDuration ? endDuration : 500,
      ]);
      setFilterLocation(videolocation);
      setFromDate(startDate);
      setToDate(endDate);
      setFilLanguage(languages);
      setFilterSpeakers(speaker);
      setType(videotype);
      setInputTitle(title);
    } else if (contentType == "SYSTEM_PLAYLIST") {
      setPlaylistValue([
        startDuration ? startDuration : 0,
        endDuration ? endDuration : 6,
      ]);
      setFilterPlayListlocation(videolocation ? videolocation : "");
      setFilterPlaylistSpeakers(speaker);
      setPlayListToDate(endDate);
      setPlayListFromDate(startDate);
      setPlayListTitle(title);
    } else if (contentType == "EBOOK") {
      setEbookValue([
        startDuration ? startDuration : 80,
        endDuration ? endDuration : 240,
      ]);
      setEbookAuthor(author ? author : null);
      setEbookLanguage(languages ? languages : "");
      setEbookPublisher(publisher ? publisher : null);
      setEbookVersion(version ? version : null);
      setEbookComments(comments ? comments : null);
    } else if (contentType == "PAD") {
      setPadValue([
        startDuration ? startDuration : 1,
        endDuration ? endDuration : 180,
      ]);
      setPadTitle(title ? title : null);
      setPadFromDate(startDate);
      setPadToDate(endDate);
      setPadLanguage(languages);
      setPadAuthor(author ? author : null);
      setPadWrittenTo(writtenTo ? writtenTo : null);
      setPadIndianCalndrFromDate(indianCalFrom);
      setPadIndianCalndrToDate(indianCalTo);
    } else if (contentType == "PATRA") {
      setPatraValue([
        startDuration ? startDuration : 80,
        endDuration ? endDuration : 240,
      ]);
      setPatraFromDate(startDate ? startDate : "");
      setPatraToDate(endDate ? endDate : "");
      setPatraAuthor(author ? author : null);
      setPatraWrittenTo(writtenTo ? writtenTo : null);
      setPatraPatraNoStart(patraNoStart);
      setPatraPatraNoEnd(patraNoEnd);
      setYearPatraStart(yearOfPatraStart);
      setYearPatraEnd(yearOfPatraEnd);
      setIndianCalndrFromDate(indianCalFrom);
      setIndianCalndrToDate(indianCalTo);
    } else if (contentType == "TRANSCRIPT") {
      setTranscriptFromDate(startDate);
      setTranscriptToDate(endDate);
      setTranscriptLanguage(languages);
      setTranscriptSpeakers(speaker);
      setTranscriptTopic(topic);
      setTranscriptPatraNoStart(patraNoStart);
      setTranscriptPatraNoEnd(patraNoEnd);
    } else if (contentType == "EBOOK_SYSTEM_PLAYLIST") {
      setEbookSeriesTitle(title ? title : null);
      setEbookSeriesAuthor(author ? author : null);
      setEbookSeriesWrittenTo(writtenTo ? writtenTo : null);
      setEbookSeriesWrittenBy(writtenBy ? writtenBy : null)
      setEbookSeriesRecommendedBy(recommendedBy ? recommendedBy : null)
      setEbookSeriesFromDate(startDate)
      setEbookSeriesToDate(endDate);
      setFilterEbookSerieslocation(videolocation ? videolocation : "");
    } else if (contentType == "YOUTUBE_TRACK") {
      setBhaktiPadTitle(title ? title : null);
      setBhaktiPadAuthor(author ? author : null);
      setBhaktiPadFromDate(startDate)
      setBhaktiPadToDate(endDate);
    } else if (contentType == "YOUTUBE_ALBUM") {
      setAlbumFromDate(startDate);
      setAlbumToDate(endDate);
      setAlbumAuthor(author ? author : null);
      setAlbumTitle(title ? title : null)
    }
  }, [
    videotype,
    languages,
    endDate,
    startDate,
    speaker,
    videolocation,
    title,
    topic,
    patraNoStart,
    patraNoEnd,
    yearOfPatraEnd,
    yearOfPatraStart,
    writtenTo,
    writtenBy,
    author,
    comments,
    version,
    publisher,
    indianCalFrom,
    indianCalTo,
  ]);

  useEffect(() => {
    fetchLocationList();
    fetchSpeakersList();
  }, []);

  async function fetchLocationList() {
    if (!satsangLocations.length > 0) {
      try {
        const { data } = await apiCall("GET", `${ApiEndPoint.GETALLLOCATOIN}`);
        if (data.length > 0) {
          setSatsangLocations(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
      }
    }
  }

  async function fetchSpeakersList() {
    if (!satsangSpeaker.length > 0) {
      try {
        const { data } = await apiCall("GET", `${ApiEndPoint.GETALLSPEAKERS}`);
        if (data.length > 0) {
          setSatsangSpeaker(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
      }
    }
  }

  const handleLocationChange = (event) => {
    const params = advaceFilterVal.filter(
      (item) => item.contentType == advaceTabValue
    )[0];
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          location: event.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);

    if (contentType == "VIDEO") {
      setFilterLocation(event.target.value);
    } else if (contentType == "SYSTEM_PLAYLIST") {
      setFilterPlayListlocation(event.target.value);
    } else if (contentType == "EBOOK_SYSTEM_PLAYLIST") {
      setFilterEbookSerieslocation(event.target.value);
    } else if (contentType == "PAD") {
    } else if (contentType == "PATRA") {
    }

    setFilterValChnage(
      event.target.value == params.uploadDuration ? true : true
    );
  };

  useEffect(() => {
    if (currentPage != "advanceSearch") {
      const updateArray = advaceFilterVal.map((item) => {
        return {
          ...item,
          uploadDuration: uploadDuration,
          searchValue: null,
          startDuration: null,
          endDuration: null,
          location: null,
          startDate: null,
          endDate: null,
          language: null,
          speaker: null,
          videotype: null,
          title: null,
        };
      });
      if (contentType == "VIDEO") {
        setValue([1, 500]);
      } else if (contentType == "SYSTEM_PLAYLIST") {
        setPlaylistValue([0, 6]);
      } else if (contentType == "EBOOK") {
        setEbookValue([80, 240]);
      } else if (contentType == "PAD") {
        setPadValue([1, 180]);
      } else if (contentType == "PATRA") {
        setPatraValue([80, 240]);
      }
      setAdvaceFilterVal(updateArray);
    }
  }, [currentPage]);

  async function ClearFilter(params) {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === contentType) {
        return {
          ...item,
          uploadDuration: uploadDuration,
          searchValue: searchKey,
          startDuration: startDuration ? parseInt(startDuration) : null,
          endDuration: endDuration ? parseInt(endDuration) : null,
          location: videolocation,
          startDate: startDate,
          endDate: endDate,
          language: languages,
          speaker: speaker,
          videotype: videotype,
          title: title,
          author: author,
          writtenTo: writtenTo,
          writtenBy: writtenBy,
          indianCalFrom: indianCalFrom,
          indianCalTo: indianCalTo,
        };
      }
      return item;
    });

    if (contentType == "VIDEO") {
      setValue([
        startDuration ? startDuration : 1,
        endDuration ? endDuration : 500,
      ]);
      setFromDate(startDate);
      setToDate(endDate);
      setFilLanguage(languages);
      setFilterSpeakers(speaker);
      setType(videotype);
      setInputTitle(title);
    } else if (contentType == "SYSTEM_PLAYLIST") {
      setPlaylistValue([
        startDuration ? startDuration : 0,
        endDuration ? endDuration : 6,
      ]);
      setFilterPlaylistSpeakers(speaker);
      setFilterPlayListlocation(videolocation ? videolocation : "");
      setPlayListToDate(endDate);
      setPlayListFromDate(startDate);
      setPlayListTitle(title);
    } else if (contentType == "EBOOK") {
      setEbookValue([
        startDuration ? startDuration : 80,
        endDuration ? endDuration : 240,
      ]);
      setEbookAuthor(author);
      setEbookLanguage(languages);
      setEbookPublisher(publisher);
      setEbookVersion(version);
      setEbookComments(comments);
    } else if (contentType == "PAD") {
      setPadValue([
        startDuration ? startDuration : 1,
        endDuration ? endDuration : 180,
      ]);
      setPadTitle(title);
      setPadFromDate(startDate);
      setPadToDate(endDate);
      setPadLanguage(languages);
      setPadAuthor(author);
      setPadWrittenTo(writtenTo);
      setPadIndianCalndrFromDate(indianCalFrom);
      setPadIndianCalndrToDate(indianCalTo);
    } else if (contentType == "PATRA") {
      setPatraValue([
        startDuration ? startDuration : 80,
        endDuration ? endDuration : 240,
      ]);
      setPatraFromDate(startDate ? startDate : "");
      setPatraToDate(endDate ? endDate : "");
      setPatraAuthor(author);
      setPatraWrittenTo(writtenTo);
      setPatraPatraNoStart(patraNoStart);
      setPatraPatraNoEnd(patraNoEnd);
      setYearPatraStart(yearOfPatraStart);
      setYearPatraEnd(yearOfPatraEnd);
      setIndianCalndrFromDate(indianCalFrom);
      setIndianCalndrToDate(indianCalTo);
    } else if (contentType == "TRANSCRIPT") {
      setTranscriptFromDate(startDate);
      setTranscriptToDate(endDate);
      setTranscriptLanguage(languages);
      setTranscriptSpeakers(speaker);
      setTranscriptTopic(topic);
      setTranscriptPatraNoStart(patraNoStart);
      setTranscriptPatraNoEnd(patraNoEnd);
    } else if (contentType == "EBOOK_SYSTEM_PLAYLIST") {
      setEbookSeriesTitle(title);
      setEbookSeriesAuthor(author);
      setEbookSeriesWrittenTo(writtenTo);
      setEbookSeriesWrittenBy(writtenBy)
      setEbookSeriesRecommendedBy(recommendedBy)

      setEbookSeriesFromDate(startDate)
      setEbookSeriesToDate(endDate);
      setFilterEbookSerieslocation(videolocation ? videolocation : "");

    } else if (contentType == "YOUTUBE_TRACK") {
      setBhaktiPadTitle(title);
      setBhaktiPadAuthor(author);
      setBhaktiPadFromDate(startDate);
      setBhaktiPadToDate(endDate);
    } else if (contentType == "YOUTUBE_ALBUM") {
      setAlbumFromDate(startDate);
      setAlbumToDate(endDate);
      // setAlbumAuthor(author);
      setAlbumTitle(title ? title : null)
    }
    setAdvaceFilterVal(updateArray);
    const par = updateArray.filter(
      (item) => item.contentType == advaceTabValue
    )[0];
    if (
      par.startDuration != startDuration ||
      par.endDuration != endDuration ||
      par.location != videolocation ||
      par.startDate != startDate ||
      par.videotype != videotype ||
      par.title != title ||
      par.endDate != endDate ||
      par.language != languages ||
      par.speaker != speaker ||
      par.author != author ||
      par.writtenTo != writtenTo ||
      par.writtenBy != writtenBy ||
      par.recommendedBy != recommendedBy ||
      par.publisher != publisher ||
      par.version != version ||
      par.comments != comments ||
      par.topic != topic ||
      par.patraNoStart != patraNoStart ||
      par.patraNoEnd != patraNoEnd ||
      par.yearOfPatraStart != yearOfPatraStart ||
      par.yearOfPatraEnd != yearOfPatraEnd ||
      par.uploadDuration != uploadDuration
    ) {
      if (contentType) {
        advanceSearch(1);
      } else {
        emptyAllValus();
      }
    } else {
      if (contentType) {
        navigate(`/advanceSearch/${advaceTabValue}/${searchValue}`);
        setIsOpenAdvance(false);
      } else {
        emptyAllValus();
      }
    }
  }

  const emptyAllValus = () => {
    const updateArray = advaceFilterVal.map((item) => {
      return {
        ...item,
        uploadDuration: null,
        searchValue: null,
        startDuration: null,
        endDuration: null,
        location: null,
        startDate: null,
        endDate: null,
        language: null,
        speaker: null,
        videotype: null,
        title: null,
        author: null,
        writtenTo: null,
        writtenBy: null,
        recommendedBy: null,
        indianCalTo: null,
        indianCalFrom: null,
      };
      return item;
    });

    if (contentType == "VIDEO") {
      setFilterLocation("");
      setAdvaceFilterVal(updateArray);
      setValue([1, 500]);
      setFromDate(null);
      setToDate(null);
      setFilLanguage("");
      setFilterSpeakers("");
      setType("");
      setInputTitle(null);
    } else if (contentType == "SYSTEM_PLAYLIST") {
      setFilterPlayListlocation("");
      setFilterPlaylistSpeakers("");
      setPlayListToDate(null);
      setPlayListFromDate(null);
      setPlayListTitle(null);
    } else if (contentType == "EBOOK") {
      setEbookAuthor(null);
      setEbookLanguage("");
      setEbookPublisher(null);
      setEbookVersion(null);
      setEbookComments(null);
    } else if (contentType == "PAD") {
      setPadTitle(null);
      setPadToDate(null);
      setPadFromDate(null);
      setPadLanguage(null);
      setPadAuthor(null);
      setPadWrittenTo(null);
      setPadIndianCalndrFromDate(null);
      setPadIndianCalndrToDate(null);
    } else if (contentType == "PATRA") {
      setPatraFromDate(null);
      setPatraToDate(null);
      setPatraAuthor(null);
      setPatraWrittenTo(null);
      setPatraPatraNoStart(null);
      setPatraPatraNoEnd(null);
      setYearPatraStart(null);
      setYearPatraEnd(null);
      setIndianCalndrFromDate(null);
      setIndianCalndrToDate(null);
    } else if (contentType == "TRANSCRIPT") {
      setTranscriptFromDate(null);
      setTranscriptToDate(null);
      setTranscriptLanguage(null);
      setTranscriptSpeakers(null);
      setTranscriptTopic(null);
      setTranscriptPatraNoStart(null);
      setTranscriptPatraNoEnd(null);
    } else if (contentType == "EBOOK_SYSTEM_PLAYLIST") {
      setEbookSeriesTitle(null);
      setEbookSeriesAuthor(null);
      setEbookSeriesWrittenTo(null);
      setEbookSeriesFromDate(null)
      setEbookSeriesToDate(null);
      setEbookSeriesWrittenBy(null)
      setEbookSeriesRecommendedBy(null)
      setFilterEbookSerieslocation("");
    } else if (contentType == "YOUTUBE_TRACK") {
      setBhaktiPadTitle(null);
      setBhaktiPadAuthor(null);
      setBhaktiPadFromDate(null)
      setBhaktiPadToDate(null);
    } else if (contentType == "YOUTUBE_ALBUM") {
      setAlbumAuthor(null);
      setAlbumFromDate(null);
      setAlbumToDate(null);
      setAlbumTitle(null);
    }
  };

  function navUserProfile(params) {
    navigate("/profile");
  }

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleChangeLanguage = (event) => {
    setLanguage(event.target.value);
  };

  //advance search
  const [isOpenAdvance, setIsOpenAdvance] = useState(false);
  const openAdvanceModal = () => {
    // if (searchValue == null || searchValue == '') {
    //   setIsValid(false);
    // } else {
    //   setIsOpenAdvance(true);
    // }
    setIsOpenAdvance(true);
  };
  const closeAdvanceModal = () => {
    setIsOpenAdvance(false);
  };

  const onChangeSlider = (e, value) => {
    const [min, max] = value;
    if (advaceTabValue == "VIDEO") {
      setValue(value);
    } else if (advaceTabValue == "EBOOK") {
      setEbookValue(value);
    } else if (advaceTabValue == "PATRA") {
      setPatraValue(value);
    } else if (advaceTabValue == "SYSTEM_PLAYLIST") {
      setPlaylistValue(value);
    } else if (advaceTabValue == "PAD") {
      setPadValue(value);
    } else if (advaceTabValue == "TRANSCRIPT") {
      setTranscriptValue(value);
    } else if (advaceTabValue == "YOUTUBE_TRACK") {
      setBhaktiPadDuration(value)
    } else if (advaceTabValue == "YOUTUBE_ALBUM") {
      setAlbumDuration(value)
    }

    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          pageNo: 0,
          pageSize: 10,
          searchValue: searchValue,
          startDuration: min,
          endDuration: max,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    // setFilterValChnage(true)
  };

  const handleTitle = (e) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          title: e.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    if (contentType == "VIDEO") {
      setInputTitle(e.target.value);
    } else if (contentType == "SYSTEM_PLAYLIST") {
      setPlayListTitle(e.target.value);
    } else if (contentType == "EBOOK") {
    } else if (contentType == "PAD") {
      setPadTitle(e.target.value);
    } else if (contentType == "EBOOK_SYSTEM_PLAYLIST") {
      setEbookSeriesTitle(e.target.value);
    } else if (contentType == "YOUTUBE_TRACK") {
      setBhaktiPadTitle(e.target.value)
    } else if (contentType == "YOUTUBE_ALBUM") {
      setAlbumTitle(e.target.value)
    }
  };
  const hasError = (field) => {
    const id = errorArray.findIndex((error) => error.field == field);

    if (id || id === 0) {
      return errorArray[id];
    } else {
      return null;
    }
  };
  const handleAuthor = (e) => {
    const updatedAuthorValue = e.target.value;

    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          author: e.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    if (contentType == "VIDEO") {
    } else if (contentType == "SYSTEM_PLAYLIST") {
    } else if (contentType == "EBOOK") {
      setEbookAuthor(e.target.value);
    } else if (contentType == "PAD") {
      setPadAuthor(e.target.value);
    } else if (contentType == "PATRA") {
      setPatraAuthor(e.target.value);
    } else if (contentType == "EBOOK_SYSTEM_PLAYLIST") {
      setEbookSeriesAuthor(e.target.value);
    } else if (contentType == "YOUTUBE_TRACK") {
      setBhaktiPadAuthor(e.target.value);
    } else if (contentType == "YOUTUBE_ALBUM") {
      setAlbumAuthor(updatedAuthorValue);
    }
  };

  const handleWrittenTo = (e) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          writtenTo: e.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    if (contentType == "VIDEO") {
    } else if (contentType == "SYSTEM_PLAYLIST") {
    } else if (contentType == "EBOOK") {
    } else if (contentType == "PAD") {
      setPadWrittenTo(e.target.value);
    } else if (contentType == "PATRA") {
      setPatraWrittenTo(e.target.value);
    } else if (contentType == "EBOOK_SYSTEM_PLAYLIST") {
      setEbookSeriesWrittenTo(e.target.value);
    }
  };

  const handleWrittenBy = (e) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          writtenBy: e.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    if (contentType == "EBOOK_SYSTEM_PLAYLIST") {
      setEbookSeriesWrittenBy(e.target.value);
    }
  };

  const handleRecommendedBy = (e) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          recommendedBy: e.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    if (contentType == "EBOOK_SYSTEM_PLAYLIST") {
      setEbookSeriesRecommendedBy(e.target.value);
    }
  };

  const handlePublisher = (e) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          publisher: e.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    setEbookPublisher(e.target.value);
  };

  const handleVersion = (e) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          version: e.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    setEbookVersion(e.target.value);
  };

  const handleComments = (e) => {
    {
      const updateArray = advaceFilterVal.map((item) => {
        if (item.contentType === advaceTabValue) {
          return {
            ...item,
            comments: e.target.value,
          };
        }
        return item;
      });
      setAdvaceFilterVal(updateArray);
      setEbookComments(e.target.value);
    }
  };

  const handleTopics = (e) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          topic: e.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    setTranscriptTopic(e.target.value);
  };

  const handlePatraNoStart = (e) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          patraNoStart: e.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    if (contentType == "TRANSCRIPT") {
      setTranscriptPatraNoStart(e.target.value);
    } else if (contentType == "PATRA") {
      setPatraPatraNoStart(e.target.value);
    }
  };

  const handlePatraNoEnd = (e) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          patraNoEnd: e.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    if (contentType == "TRANSCRIPT") {
      setTranscriptPatraNoEnd(e.target.value);
    } else if (contentType == "PATRA") {
      setPatraPatraNoEnd(e.target.value);
    }
  };

  const handleYearPatraStart = (e) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          yearOfPatraStart: e.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    if (contentType == "PATRA") {
      setYearPatraStart(e.target.value);
    }
  };

  const handleYearPatraEnd = (e) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          yearOfPatraEnd: e.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    if (contentType == "PATRA") {
      setYearPatraEnd(e.target.value);
    }
  };

  const uploadDurationselect = async (value) => {
    const params = advaceFilterVal.filter(
      (item) => item.contentType == advaceTabValue
    )[0];
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          uploadDuration: value == params.uploadDuration ? null : value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
  };
  function validateFields(params) {
    const currentYear = new Date().getFullYear();
    const errorArray = [];

    // Validate the author field if it's not empty
    if (params.author && !isStringValid(params.author)) {
      errorArray.push({
        field: "author",
        message: "Author should contain at least one alphabet character",
      });
    }

    // if (params.title && !isStringValid(params.title)) {
    //   errorArray.push({
    //     field: "Title",
    //     message: "Title should contain at least one alphabet character",
    //   });
    // }

    // Validate the publisher field if it's not empty
    if (params.publisher && !isStringValid(params.publisher)) {
      errorArray.push({
        field: "publisher",
        message: "Publisher should contain at least one alphabet character",
      });
    }

    // Validate the comments field if it's not empty
    if (params.comments && !isStringValid(params.comments)) {
      errorArray.push({
        field: "comments",
        message: "Comments should contain at least one alphabet character",
      });
    }

    // Validate the speaker field if it's not empty
    if (params.speaker && !isStringValid(params.speaker)) {
      errorArray.push({
        field: "speaker",
        message: "Speaker should contain at least one alphabet character",
      });
    }

    // Validate the topic field if it's not empty
    if (params.topic && !isStringValid(params.topic)) {
      errorArray.push({
        field: "topic",
        message: "Topic should contain at least one alphabet character",
      });
    }

    // Validate the written to field if it's not empty
    if (params.writtenTo && !isStringValid(params.writtenTo)) {
      errorArray.push({
        field: "writtenTo",
        message: "Written To should contain at least one alphabet character",
      });
    }
    if (params.writtenBy && !isStringValid(params.writtenBy)) {
      errorArray.push({
        field: "writtenBy",
        message: "Written By should contain at least one alphabet character",
      });
    }
    if (params.recommendedBy && !isStringValid(params.recommendedBy)) {
      errorArray.push({
        field: "recommendedBy",
        message: "Recommended By should contain at least one alphabet character",
      });
    }

    if (params.patraNoStart && !isNumberValid(params.patraNoStart)) {
      errorArray.push({
        field: "patraNoStart",
        message: "Patra number  should contain numbers only",
      });
    }
    if (params.patraNoEnd && !isNumberValid(params.patraNoEnd)) {
      errorArray.push({
        field: "patraNoEnd",
        message: "Patra number  should contain numbers only",
      });
    }
    if (params.yearOfPatraEnd && !isNumberValid(params.yearOfPatraEnd)) {
      errorArray.push({
        field: "yearOfPatraEnd",
        message: "Patra year To should  contain numbers only",
      });
    }
    if (params.yearOfPatraStart && !isNumberValid(params.yearOfPatraStart)) {
      errorArray.push({
        field: "yearOfPatraStart",
        message: "Patra year To should  contain numbers only",
      });
    }
    if (
      !errorArray.some((error) => error.field === "Patra Year Start") &&
      params.yearOfPatraStart &&
      (!/^\d{4}$/.test(params.yearOfPatraStart) ||
        params.yearOfPatraStart > currentYear)
    ) {
      errorArray.push({
        field: "yearOfPatraStart",
        message: `Invalid Patra Year Start. It should be in "YYYY" format and less than or equal to ${currentYear}.`,
      });
    }

    if (
      !errorArray.some((error) => error.field === "Patra Year End") &&
      params.yearOfPatraEnd &&
      (!/^\d{4}$/.test(params.yearOfPatraEnd) ||
        params.yearOfPatraEnd > currentYear)
    ) {
      errorArray.push({
        field: "yearOfPatraEnd",
        message: `Invalid Patra Year End. It should be in "YYYY" format and less than or equal to ${currentYear}.`,
      });
    }
    if (
      !errorArray.some((error) => error.field === "Patra No End") &&
      params.patraNoStart &&
      params.patraNoEnd &&
      params.patraNoStart > params.patraNoEnd
    ) {
      errorArray.push({
        field: "patraNoStart",
        message: "Patra No End should not be less than Patra No Start",
      });
    }

    if (
      !errorArray.some((error) => error.field === "Patra Year End") &&
      params.yearOfPatraStart &&
      params.yearOfPatraEnd &&
      params.yearOfPatraStart > params.yearOfPatraEnd
    ) {
      errorArray.push({
        field: "patraNoEnd",
        message: "Patra Year End should not be less than Patra Year Start",
      });
    }

    if (errorArray.length === 0) {
      // No errors, all fields are valid or empty
      return true;
    } else {
      // Handle errors
      if (currentTimeOut) {
        clearTimeout(currentTimeOut);
        setCurrentTimeOut(null);
      }
      setErrorArray(errorArray);
      const timeOut = setTimeout(() => {
        setErrorArray([]);
      }, 3000)
      // errorArray.forEach((error) => {
      //   toast.error(`${error.field}: ${error.message}`);
      // });
      return false;
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      advanceSearch(1);
    }
  };

  async function advanceSearch(value) {
    setIsValid(true);
    if (searchValue != searchKey || isOpenAdvance) {
      if (
        `/advanceSearch/${advaceTabValue}/${searchValue}` !=
        location.pathname ||
        value == 2
      ) {
        const params = advaceFilterVal.filter(
          (item) => item.contentType == advaceTabValue
        )[0];

        if (validateFields(params)) {
          if (value == 1) {
            setAdvaceData([]);
            setFilterValChnage(false);
            const navigateUrl =
              `/advanceSearch/${encodeURIComponent(
                advaceTabValue
              )}/${encodeURIComponent(
                searchValue?.trim() === "" ? null : searchValue
              )}?` +
              `${params.startDuration
                ? `startDuration=${encodeURIComponent(params.startDuration)}`
                : ""
              }` +
              `${params.endDuration
                ? `&endDuration=${encodeURIComponent(params.endDuration)}`
                : ""
              }` +
              `${params.location
                ? `&videolocation=${encodeURIComponent(params.location)}`
                : ""
              }` +
              `${params.startDate
                ? `&startDate=${encodeURIComponent(params.startDate)}`
                : ""
              }` +
              `${params.videotype
                ? `&videotype=${encodeURIComponent(params.videotype)}`
                : ""
              }` +
              `${params.title ? `&title=${encodeURIComponent(params.title)}` : ""
              }` +
              `${params.endDate
                ? `&endDate=${encodeURIComponent(params.endDate)}`
                : ""
              }` +
              `${params.language
                ? `&language=${encodeURIComponent(params.language)}`
                : ""
              }` +
              `${params.speaker
                ? `&speaker=${encodeURIComponent(params.speaker)}`
                : ""
              }` +
              `${params.author
                ? `&author=${encodeURIComponent(params.author)}`
                : ""
              }` +
              `${params.publisher
                ? `&publisher=${encodeURIComponent(params.publisher)}`
                : ""
              }` +
              `${params.version
                ? `&version=${encodeURIComponent(params.version)}`
                : ""
              }` +
              `${params.comments
                ? `&comments=${encodeURIComponent(params.comments)}`
                : ""
              }` +
              `${params.writtenTo
                ? `&writtenTo=${encodeURIComponent(params.writtenTo)}`
                : ""
              }` +
              `${params.writtenBy
                ? `&writtenBy=${encodeURIComponent(params.writtenBy)}`
                : ""
              }` +
              `${params.recommendedBy
                ? `&recommendedBy=${encodeURIComponent(params.recommendedBy)}`
                : ""
              }` +
              `${params.topic ? `&topic=${encodeURIComponent(params.topic)}` : ""
              }` +
              `${params.patraNoStart
                ? `&patraNoStart=${encodeURIComponent(params.patraNoStart)}`
                : ""
              }` +
              `${params.patraNoEnd
                ? `&patraNoEnd=${encodeURIComponent(params.patraNoEnd)}`
                : ""
              }` +
              `${params.yearOfPatraStart
                ? `&yearOfPatraStart=${encodeURIComponent(
                  params.yearOfPatraStart
                )}`
                : ""
              }` +
              `${params.yearOfPatraEnd
                ? `&yearOfPatraEnd=${encodeURIComponent(
                  params.yearOfPatraEnd
                )}`
                : ""
              }` +
              `${params.uploadDuration
                ? `&uploadDuration=${encodeURIComponent(
                  params.uploadDuration
                )}`
                : ""
              }` +
              `${params.indianStartdate
                ? `&indianCalFrom=${encodeURIComponent(
                  params.indianStartdate
                )}`
                : ""
              }` +
              `${params.indianEndDate
                ? `&indianCalTo=${encodeURIComponent(params.indianEndDate)}`
                : ""
              }`;
            navigate(navigateUrl);
          } else {
            if (
              params.startDuration != startDuration ||
              params.endDuration != endDuration ||
              params.location != videolocation ||
              params.startDate != startDate ||
              params.endDate != endDate ||
              params.videotype != videotype ||
              params.title != title ||
              params.language != languages ||
              params.speaker != speaker ||
              params.author != author ||
              params.publisher != publisher ||
              params.version != version ||
              params.author != author ||
              params.comments != comments ||
              params.topic != topic ||
              params.patraNoStart != patraNoStart ||
              params.patraNoEnd != patraNoEnd ||
              params.yearOfPatraStart != yearOfPatraStart ||
              params.yearOfPatraEnd != yearOfPatraEnd ||
              params.indianStartdate != indianCalFrom ||
              params.indianEndDate != indianCalTo ||
              params.writtenTo != writtenTo ||
              params.writtenBy != writtenBy ||
              params.recommendedBy != recommendedBy ||
              params.uploadDuration != uploadDuration
            ) {
              setAdvaceData([]);
              setIsOpenAdvance(false);
              setIsLoading(false);
              setFilterValChnage(false);
              setDataNotFound(false);
              const navigateUrl =
                `/advanceSearch/${encodeURIComponent(
                  advaceTabValue
                )}/${encodeURIComponent(
                  searchValue?.trim() === "" ? null : searchValue
                )}?` +
                `${params.startDuration
                  ? `startDuration=${encodeURIComponent(
                    params.startDuration
                  )}`
                  : ""
                }` +
                `${params.endDuration
                  ? `&endDuration=${encodeURIComponent(params.endDuration)}`
                  : ""
                }` +
                `${params.location
                  ? `&videolocation=${encodeURIComponent(params.location)}`
                  : ""
                }` +
                `${params.startDate
                  ? `&startDate=${encodeURIComponent(params.startDate)}`
                  : ""
                }` +
                `${params.videotype
                  ? `&videotype=${encodeURIComponent(params.videotype)}`
                  : ""
                }` +
                `${params.title
                  ? `&title=${encodeURIComponent(params.title)}`
                  : ""
                }` +
                `${params.endDate
                  ? `&endDate=${encodeURIComponent(params.endDate)}`
                  : ""
                }` +
                `${params.language
                  ? `&language=${encodeURIComponent(params.language)}`
                  : ""
                }` +
                `${params.speaker
                  ? `&speaker=${encodeURIComponent(params.speaker)}`
                  : ""
                }` +
                `${params.author
                  ? `&author=${encodeURIComponent(params.author)}`
                  : ""
                }` +
                `${params.publisher
                  ? `&publisher=${encodeURIComponent(params.publisher)}`
                  : ""
                }` +
                `${params.version
                  ? `&version=${encodeURIComponent(params.version)}`
                  : ""
                }` +
                `${params.comments
                  ? `&comments=${encodeURIComponent(params.comments)}`
                  : ""
                }` +
                `${params.writtenTo
                  ? `&writtenTo=${encodeURIComponent(params.writtenTo)}`
                  : ""
                }` +
                `${params.writtenBy
                  ? `&writtenBy=${encodeURIComponent(params.writtenBy)}`
                  : ""
                }` +
                `${params.recommendedBy
                  ? `&recommendedBy=${encodeURIComponent(params.recommendedBy)}`
                  : ""
                }` +
                `${params.topic
                  ? `&topic=${encodeURIComponent(params.topic)}`
                  : ""
                }` +
                `${params.patraNoStart
                  ? `&patraNoStart=${encodeURIComponent(params.patraNoStart)}`
                  : ""
                }` +
                `${params.patraNoEnd
                  ? `&patraNoEnd=${encodeURIComponent(params.patraNoEnd)}`
                  : ""
                }` +
                `${params.yearOfPatraStart
                  ? `&yearOfPatraStart=${encodeURIComponent(
                    params.yearOfPatraStart
                  )}`
                  : ""
                }` +
                `${params.yearOfPatraEnd
                  ? `&yearOfPatraEnd=${encodeURIComponent(
                    params.yearOfPatraEnd
                  )}`
                  : ""
                }` +
                `${params.uploadDuration
                  ? `&uploadDuration=${encodeURIComponent(
                    params.uploadDuration
                  )}`
                  : ""
                }` +
                `${params.indianStartdate
                  ? `&indianCalFrom=${encodeURIComponent(
                    params.indianStartdate
                  )}`
                  : ""
                }` +
                `${params.indianEndDate
                  ? `&indianCalTo=${encodeURIComponent(params.indianEndDate)}`
                  : ""
                }`;


              navigate(navigateUrl);
            } else {

              setIsOpenAdvance(false);
            }
          }
        }
      } else {
        setIsOpenAdvance(false);
        navigate(`/advanceSearch/${advaceTabValue}/${searchValue}`);
      }
    }
  }

  const handleSearchBox = (event) => {
    if (!emojiRegex.test(event.target.value)) {
      if (event.target.value) {
        if (event.target.value.includes("/")) {
          return;
        }
        setSearchValue(event.target.value);
      } else {
        setSearchValue(null);
      }
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the state when the checkbox is clicked
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
  };

  async function EbookSearchPoc() {
    if (inputValue) {
      try {
        let url;
        switch (contentType) {
          case "EBOOK":
            url = `${auth ? ApiEndPoint.EPUBSEARCH : ApiEndPoint.NONEPUBSEARCH
              }/${inputValue.trim()}`
            break;
          case "PATRA":
            url = `${auth ? ApiEndPoint.PATRASEARCH : ApiEndPoint.NONPATRASEARCH
              }/${inputValue.trim()}`
            break;
          case "TRANSCRIPT":
            url = `${auth ? ApiEndPoint.TRANSCRIPTSEARCH : ApiEndPoint.NONTRANSCRIPTSEARCH
              }/${inputValue.trim()}`
            break;

        }
        const { data } = await apiCall(
          "GET",
          url
        );
        if (data.length > 0) {
          setAdvaceData(data);
          closeAdvanceModal();
          setIsLoading(false);
          setDataNotFound(false);
        } else {
          setAdvaceData([]);
          setDataNotFound(true);
          closeAdvanceModal();
          setIsLoading(false);
        }
      } catch (err) {
        setDataNotFound(true);
        closeAdvanceModal();
        setAdvaceData([]);
        setIsLoading(false);
      }
    }
  }

  const indianDateFormat = new Intl.DateTimeFormat("hi-IN", {
    year: "numeric",
    month: "long",
    day: "numeric",
    calendar: "indian",
  });

  const handleHinduCalStart = (date, index) => {
    if (index === "from") {
      setIndianCalndrFromDateObj(date);
      const updateArray = advaceFilterVal.map((item) => {
        if (item.contentType === advaceTabValue) {
          return {
            ...item,
            indianStartdate: indianDateFormat.format(date).toString(),
          };
        }
        return item;
      });
      if (contentType == "PAD") {
        setPadIndianCalndrFromDate(indianDateFormat.format(date).toString());
      } else if (contentType == "PATRA") {
        setIndianCalndrFromDate(indianDateFormat.format(date).toString());
      }
      setAdvaceFilterVal(updateArray);
    } else if (index === "to") {
      const updateArray = advaceFilterVal.map((item) => {
        if (item.contentType === advaceTabValue) {
          return {
            ...item,
            indianEndDate: indianDateFormat.format(date).toString(),
          };
        }
        return item;
      });
      setAdvaceFilterVal(updateArray);
      setIndianCalndrToDateObj(date);
      if (contentType == "PAD") {
        setPadIndianCalndrToDate(indianDateFormat.format(date).toString());
      } else if (contentType == "PATRA") {
        setIndianCalndrToDate(indianDateFormat.format(date).toString());
      }
    } else {
      return;
    }
  };

  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.up("480"));

  return (
    splitLocation[1] != "fileViewer" && (
      <>
        {/* viewMore hider header */}
        {!Object.values(hideHeader).includes(splitLocation[1]) && (
          <div
            className={
              Object.values(MobileHideHeader).includes(splitLocation[1])
                ? "header-wrapper header-advacepage"
                : "header-wrapper "
            }
          >
            {/* backArrow  */}
            {splitLocation[1] !== "" && (
              <div className="d-flex d-hide min-h header-jc-sp-c">
                <div className="d-flex">
                  {/* myspace and ebook hide backArrow */}
                  {
                    splitLocation[1] === "sparsh" ||
                      splitLocation[1] === "MySpace" ||
                      splitLocation[1] === "surpriseMe" ? null : (
                      <img
                        onClick={() => navigate(-1)}
                        src={AllImages.YellowArrow}
                        alt="Back Arrow"
                        title="Back Arrow"
                        className="notification-icon"
                      />
                    )}
                  <p
                    className={
                      splitLocation[1] === "sparsh" ||
                        splitLocation[1] === "MySpace" ||
                        splitLocation[1] === "surpriseMe"
                        ? "mob-header-title mob-header-title-ml-0"
                        : "mob-header-title"
                    }
                  >
                    {splitLocation[1] ? HeaderArray?.[splitLocation[1]] : null}
                  </p>
                </div>
                {HeaderArray?.[splitLocation[1]] == "My Created Playlist" ? (
                  <div onClick={() => setShowCreatePlayListModel(true)}>
                    <p className="header-create-btn">CREATE</p>
                  </div>
                ) : //Language dropdown
                  HeaderArray?.[splitLocation[1]] == "FAQs" ? (
                    // <div>
                    //   <p className="header-create-btn">Engalish</p>
                    // </div>
                    <div className="language">
                      <select
                        name="language"
                        id="language"
                        value={language}
                        onChange={handleChangeLanguage}
                      >
                        <option value="english" key="0">
                          English
                        </option>
                        <option value="hindi" key="1">
                          हिंदी
                        </option>
                        <option value="gujarati" key="2">
                          ગુજરાતી
                        </option>
                      </select>
                    </div>
                  ) : //My Profile
                    HeaderArray?.[splitLocation[1]] == "Profile" ? (
                      <div onClick={() => navigate("/editProfile")}>
                        <p className="header-create-btn">Edit</p>
                      </div>
                    ) : null}

                {/* </div> */}
              </div>
            )}
            {/* backArrow  */}

            <div className="header-inside-wrapper">
              {/* Mobile header logo  */}
              {splitLocation[1] === "" && (
                <div className="d-hide">
                  <img
                    className="main-logo"
                    src={AllImages?.Logo}
                    alt="Logo"
                    title="Logo"
                  />
                </div>
              )}
              {/* Desktop */}
              <div className="search-wrap-header">
                <div
                  className={`search-input-header m-hide ${isValid ? "" : "invalid"
                    }`}
                >
                  <span className="search-icon-header searchTooltip">
                    <img
                      onClick={() => advanceSearch(1)}
                      src={AllImages.Search}
                      alt="Search"
                      title="Search"
                    />
                    <span className="tooltiptext">{"search"}</span>
                  </span>
                  <input
                    className={`f-poppins`}
                    type="text"
                    placeholder="Search for Satsang,Bhakti Pad,Playlist etc..."
                    onChange={handleSearchBox}
                    value={searchValue}
                    onKeyUp={handleKeyPress}
                  />
                  <div className="filter-icon-header filterTooltip">
                    <img
                      onClick={() => openAdvanceModal()}
                      src={AllImages.SearchToggle2}
                      alt="Search"
                      title="Search"
                    />
                    <span className="tooltiptext">{"Filter"}</span>
                  </div>
                </div>
              </div>
              {/* Desktop */}

              {splitLocation[1] === "" && (
                <div className="d-flex align-center">
                  {/* <img src={AllImages.MultiLangSearch} onClick={() => openModal()} alt="Multi Language Search" title="Multi Language Search" className="multiLangSearch-icon" /> */}
                  <div className="notificationTooltip">
                    <img
                      src={Bell}
                      alt="Notification"
                      className="notification-icon"
                    />
                    <span className="tooltiptext">{"Notification"}</span>
                  </div>
                  {auth ? (
                    <div className="userTooltip">
                      <img
                        onClick={() => navUserProfile()}
                        className="logout-icon"
                        src={
                          userDetails?.user?.imageUrl
                            ? userDetails?.user?.imageUrl
                            : AllImages?.UserImage
                        }
                        alt="User Image"
                        title="User image"
                      ></img>
                      <span className="tooltiptext">{"User"}</span>
                    </div>
                  ) : (
                    <div className="userTooltip">
                      <img
                        onClick={() => onChange({ true: true })}
                        className="g-icon logout-icon"
                        src={Google}
                        alt="Google"
                        title="Google"
                      ></img>
                      <span className="tooltiptext">{"User"}</span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
        {/* {openRecaptcha && (
          <Dialog
            open={openRecaptcha}
            className="sortBy captcha"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent
              style={{
                overflowY: "hidden",
                overflowX: "hidden",
              }}
            >
              <ReCAPTCHA
                sitekey={TEST_SITE_KEY}
                onChange={onChange}
                style={{ display: "inline-block" }}
              />
           
          

            </DialogContent>
          </Dialog>
       
        )} */}

        {isOpen && (
          <Dialog
            open={openModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="dictionaryPopup"
          >
            <DialogContent>
              <div className="download-ebook-modal-content">
                <p className="download-ebook-modal-msg-text">{"Dictionary"}</p>
                <span
                  className="download-ebook-modal-close-button"
                  onClick={() => closeModal()}
                >
                  &times;
                </span>
              </div>
              <div className="filter-search-modal-mt-15">
                <p className="filter-search-modal-comm-font-13">From</p>

                <div className="filter-search-modal-location-div">
                  <FormControl
                    fullWidth
                    size="small"
                    style={{ paddingTop: "5px" }}
                  >
                    <Select
                      sx={{
                        boxShadow: "none",
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          border: 0,
                        },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          border: 0,
                        },
                        ".MuiSvgIcon-root ": {
                          fill: "#FFB200 !important",
                        },
                      }}
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                    >
                      <MenuItem value={10}>English</MenuItem>
                      <MenuItem value={20}>Marathi</MenuItem>
                      <MenuItem value={30}>Gujrathi</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <p className="filter-search-modal-comm-font-13">To</p>

                <div className="filter-search-modal-location-div">
                  <FormControl
                    fullWidth
                    size="small"
                    style={{ paddingTop: "5px" }}
                  >
                    <Select
                      sx={{
                        boxShadow: "none",
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          border: 0,
                        },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          border: 0,
                        },
                        ".MuiSvgIcon-root ": {
                          fill: "#FFB200 !important",
                        },
                      }}
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                    >
                      <MenuItem value={10}>English</MenuItem>
                      <MenuItem value={20}>Marathi</MenuItem>
                      <MenuItem value={30}>Gujrathi</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              {/* <div className="search-wrap-header"> */}
              <div className="search-input-header">
                {/* <span className="search-icon-header">
                      <img src={AllImages.Search} alt="" />
                    </span> */}
                <input
                  className="f-poppins"
                  type="text"
                  placeholder="Satsang"
                />
                <div className="filter-icon-header">
                  <img
                    src={AllImages.Keyboard}
                    alt="Keyboard"
                    title="Keyboard"
                    className="keyboard"
                  />
                  <img
                    src={AllImages.SearchOrange}
                    alt="Search"
                    title="Search"
                    className="searchOrange"
                  />
                </div>
              </div>
              {/* </div> */}

              <div className="translation">
                <p className="translationText">સત્સંગ</p>
                <div className="translationBox">
                  <p className="translationType">Type: ના○</p>
                  <p className="translationPronunciation">
                    Pronunciation: સેટ્સૂમૅ
                  </p>
                  <p className="translationMeaning">
                    Meaning: એક જાતનું ચીની કે જાપાની સંતરું
                  </p>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <div className="download-ebook-modal-action-content">
                {/* <button className="download-ebook-modal-yes-btn">{"Yes"}</button> */}
                <button
                  className="download-ebook-modal-dont-delete-btn"
                  onClick={closeModal}
                >
                  {"Close"}
                </button>
              </div>
            </DialogActions>
          </Dialog>
        )}

        {/* advace search */}
        {isOpenAdvance && (
          <Dialog
            open={openAdvanceModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="filterPopup"
          >
            <DialogContent
              style={{
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {advaceTabValue == "VIDEO" ? (
                //Video filter
                <div>
                  <div className="filter-search-modal-content">
                    <p className="filter-search-modal-msg-text">
                      {"Filter for Video"}
                    </p>
                    <span
                      className="filter-search-modal-close-button"
                      onClick={() => closeAdvanceModal()}
                    >
                      &times;
                    </span>
                  </div>

                  {/*----- Video Duration -----*/}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">
                      Video Duration
                    </p>
                    <Box sx={{ width: "92%", paddingLeft: "10px" }}>
                      <Slider
                        sx={{
                          "& .MuiSlider-thumb": {
                            color: "#FFB200",
                          },
                          "& .MuiSlider-track": {
                            color: "#FFB200",
                          },
                          "& .MuiSlider-rail": {
                            color: "#212121",
                          },
                          "& .MuiSlider-active": {
                            color: "#212121",
                          },
                        }}
                        valueLabelDisplay="auto"
                        value={value}
                        step={8}
                        marks={generateDynamicMarksArray(1, 500, 8)}
                        min={1}
                        max={500}
                        onChange={onChangeSlider}
                      />
                    </Box>
                  </div>

                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Title</p>
                    <input
                      className="filter-search-modal-location-div"
                      style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                      value={inputTitle}
                      onChange={handleTitle}
                    />
                  </div>

                  {/* fromDate */}
                  <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">
                      From Date
                    </p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        // label="Date desktop"
                        className="form-control input-font"
                        inputFormat="DD/MM/YYYY"
                        name="fromDate"
                        // minDate={"2022-11-30"}
                        maxDate={new Date().toISOString().slice(0, 10)}
                        value={fromDate}
                        onChange={handleChangeFromDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ width: "100%" }}
                          // value={fromDate}
                          // onChange={handleChangeFromDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  {/* todateI */}
                  <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">To Date</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        // label="Date desktop"
                        className="form-control input-font"
                        inputFormat="DD/MM/YYYY"
                        name="todate"
                        minDate={fromDate}
                        maxDate={new Date().toISOString().slice(0, 10)}
                        value={toDate}
                        onChange={handleChangeToDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="input-font"
                            style={{ width: "100%" }}
                          // value={toDate}
                          // onChange={handleChangeToDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  {/* Type */}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Type</p>
                    <div className="filter-search-modal-location-div">
                      <FormControl
                        fullWidth
                        size="small"
                        style={{ paddingTop: "5px" }}
                      >
                        <Select
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                            ".MuiSvgIcon-root ": {
                              fill: "#FFB200 !important",
                            },
                          }}
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={type ? type?.trim() : null}
                          onChange={handleChangeType}
                        >
                          <MenuItem value="">Select</MenuItem>
                          <MenuItem value="audio">Audio</MenuItem>
                          <MenuItem value="video">Video</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  {/* //Language */}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Language</p>
                    <div className="filter-search-modal-location-div">
                      <FormControl
                        fullWidth
                        size="small"
                        style={{ paddingTop: "5px" }}
                      >
                        <Select
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                            ".MuiSvgIcon-root ": {
                              fill: "#FFB200 !important",
                            },
                          }}
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={filLanguage ? filLanguage?.trim() : null}
                          onChange={handleChangeLanguages}
                        >
                          <MenuItem value="">Select</MenuItem>
                          <MenuItem value="English">English</MenuItem>
                          <MenuItem value="Hindi"> हिंदी</MenuItem>
                          <MenuItem value="Gujrati">ગુજરાતી</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  {/* speaker */}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">speaker</p>
                    <div className="filter-search-modal-location-div">
                      <FormControl
                        fullWidth
                        size="small"
                        style={{ paddingTop: "5px" }}
                      >
                        <Select
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                            ".MuiSvgIcon-root ": {
                              fill: "#FFB200 !important",
                            },
                          }}
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={filterSpeakers ? filterSpeakers?.trim() : null}
                          onChange={handleSpeakers}
                        >
                          {hasError("speaker") && (
                            <p className="filter-search-modal-comm-font-10">
                              {hasError("speaker").message}
                            </p>
                          )}
                          <MenuItem value="">Select</MenuItem>
                          {satsangSpeaker.map((item) => {
                            return <MenuItem value={item}>{item}</MenuItem>;
                          })}
                          {/* <MenuItem value=''></MenuItem>
                        <MenuItem value='Ahmedabad'>Ahmedabad</MenuItem>
                        <MenuItem value='Mumbai'>Mumbai</MenuItem>
                        <MenuItem value='Pune'>Pune</MenuItem>
                        <MenuItem value='india'>India</MenuItem> */}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  {/*----- Location -----*/}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Location</p>
                    <div className="filter-search-modal-location-div">
                      <FormControl
                        fullWidth
                        size="small"
                        style={{ paddingTop: "5px" }}
                      >
                        <Select
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                            ".MuiSvgIcon-root ": {
                              fill: "#FFB200 !important",
                            },
                          }}
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={filterlocation ? filterlocation?.trim() : null}
                          onChange={handleLocationChange}
                        >
                          <MenuItem value="">Select</MenuItem>
                          {satsangLocations.map((item) => {
                            return <MenuItem value={item}>{item}</MenuItem>;
                          })}
                          {/* <MenuItem value=''></MenuItem>
                        <MenuItem value='Ahmedabad'>Ahmedabad</MenuItem>
                        <MenuItem value='Mumbai'>Mumbai</MenuItem>
                        <MenuItem value='Pune'>Pune</MenuItem>
                        <MenuItem value='india'>India</MenuItem> */}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              ) : advaceTabValue == "SYSTEM_PLAYLIST" ? (
                //playlists
                <div>
                  <div className="filter-search-modal-content">
                    <p className="filter-search-modal-msg-text">
                      {"Filter for Playlist"}
                    </p>
                    <span
                      className="filter-search-modal-close-button"
                      onClick={() => closeAdvanceModal()}
                    >
                      &times;
                    </span>
                  </div>

                  {/*----- Video Duration -----*/}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">
                      Playlist Duration
                    </p>
                    <Box
                      sx={{
                        width: matchesMD === true ? 310 : 270,
                        paddingLeft: "10px",
                      }}
                    >
                      <Slider
                        sx={{
                          "& .MuiSlider-thumb": {
                            color: "#FFB200",
                          },
                          "& .MuiSlider-track": {
                            color: "#FFB200",
                          },
                          "& .MuiSlider-rail": {
                            color: "#212121",
                          },
                          "& .MuiSlider-active": {
                            color: "#212121",
                          },
                        }}
                        valueLabelDisplay="auto"
                        value={playlistValue}
                        step={1}
                        marks={[
                          { value: 0, label: "0hr" },
                          { value: 2, label: "2hr" },
                          { value: 4, label: "4hr" },
                          { value: 6, label: "6hr" },
                          { value: 8, label: "8hr" },
                          { value: 10, label: "10hr" },
                        ]}
                        min={1}
                        max={10}
                        onChange={onChangeSlider}
                      />
                    </Box>
                  </div>

                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Title</p>
                    <input
                      className="filter-search-modal-location-div"
                      style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                      value={playListTitle}
                      onChange={handleTitle}
                    />
                  </div>

                  {/* fromDate */}
                  <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">
                      From Date
                    </p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        // label="Date desktop"
                        className="form-control input-font"
                        inputFormat="DD/MM/YYYY"
                        name="fromDate"
                        // minDate={"2022-11-30"}
                        maxDate={new Date().toISOString().slice(0, 10)}
                        value={playListFromDate}
                        onChange={handleChangeFromDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ width: "100%" }}
                          // value={fromDate}
                          // onChange={handleChangeFromDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  {/* todate */}
                  <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">To Date</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        // label="Date desktop"
                        className="form-control input-font"
                        inputFormat="DD/MM/YYYY"
                        name="todate"
                        minDate={playListFromDate}
                        maxDate={new Date().toISOString().slice(0, 10)}
                        value={playListToDate}
                        onChange={handleChangeToDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="input-font"
                            style={{ width: "100%" }}
                          // value={toDate}
                          // onChange={handleChangeToDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  {/* speaker */}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">speaker</p>
                    <div className="filter-search-modal-location-div">
                      <FormControl
                        fullWidth
                        size="small"
                        style={{ paddingTop: "5px" }}
                      >
                        <Select
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                            ".MuiSvgIcon-root ": {
                              fill: "#FFB200 !important",
                            },
                          }}
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={
                            filterPlaylistSpeakers
                              ? filterPlaylistSpeakers?.trim()
                              : null
                          }
                          onChange={handleSpeakers}
                        >
                          {hasError("speaker") && (
                            <p className="filter-search-modal-comm-font-10">
                              {hasError("speaker").message}
                            </p>
                          )}

                          <MenuItem value="">Select</MenuItem>
                          {satsangSpeaker.map((item) => {
                            return <MenuItem value={item}>{item}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  {/*----- Location -----*/}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Location</p>
                    <div className="filter-search-modal-location-div">
                      <FormControl
                        fullWidth
                        size="small"
                        style={{ paddingTop: "5px" }}
                      >
                        <Select
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                            ".MuiSvgIcon-root ": {
                              fill: "#FFB200 !important",
                            },
                          }}
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={
                            filterPlayListlocation
                              ? filterPlayListlocation?.trim()
                              : null
                          }
                          onChange={handleLocationChange}
                        >
                          <MenuItem value="">Select</MenuItem>
                          {satsangLocations.map((item) => {
                            return <MenuItem value={item}>{item}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  {/*----- Upload Duration -----*/}
                  {/* <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">
                      Upload Duration
                    </p>
                    <div className="filter-search-modal-d-flex filter-search-modal-mt-5">
                      {uploadDurationArray.map((item) => {
                        return (
                          <div
                            className={`filter-search-modal-upload-dur-div filter-search-modal-mlr-8  ${
                              advaceFilterVal.find(
                                (item) => item.contentType === advaceTabValue
                              )?.uploadDuration == item.value
                                ? "active"
                                : null
                            }`}
                            onClick={() => uploadDurationselect(item.value)}
                          >
                            <p className="filter-search-modal-today">
                              {item.lable}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div> */}
                </div>
              ) : advaceTabValue == "EBOOK" ? (
                //ebook
                <div>
                  <div className="filter-search-modal-content">
                    <p className="filter-search-modal-msg-text">
                      {"Filter for E-Books"}
                    </p>
                    <span
                      className="filter-search-modal-close-button"
                      onClick={() => closeAdvanceModal()}
                    >
                      &times;
                    </span>
                  </div>

                  <div>
                    <p className="toggleText">
                      Enhance Your Search Abilities Within Books
                    </p>
                    <div className="customToggle">
                      <label class="switch">
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                  {isChecked ? (
                    <div className={inputValue ? "" : "epubinvalid"}>
                      <input
                        type="text"
                        className="custom-input"
                        placeholder="Enter text here"
                        value={inputValue}
                        onChange={handleInputChange}
                      />
                    </div>
                  ) : (
                    <>
                      {/*----- Video Duration -----*/}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Pages
                        </p>
                        <Box
                          sx={{
                            width: matchesMD === true ? 310 : 270,
                            paddingLeft: "10px",
                          }}
                        >
                          <Slider
                            sx={{
                              "& .MuiSlider-thumb": {
                                color: "#FFB200",
                              },
                              "& .MuiSlider-track": {
                                color: "#FFB200",
                              },
                              "& .MuiSlider-rail": {
                                color: "#212121",
                              },
                              "& .MuiSlider-active": {
                                color: "#212121",
                              },
                            }}
                            valueLabelDisplay="auto"
                            value={ebookValue}
                            step={6}
                            marks={[
                              { value: 80, label: "80pp" },
                              { value: 160, label: "160pp" },
                              { value: 240, label: "240pp" },
                              { value: 320, label: "320pp" },
                              { value: 400, label: "400pp" },
                              { value: 480, label: "480pp" },
                            ]}
                            min={80}
                            max={480}
                            onChange={onChangeSlider}
                          />
                        </Box>
                      </div>

                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Author
                        </p>
                        <input
                          className="filter-search-modal-location-div"
                          type="text"
                          style={{
                            fontSize: 17,
                            paddingLeft: 8,
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          value={ebookAuthor}
                          onChange={handleAuthor}
                        />
                        {hasError("author") && (
                          <p className="filter-search-modal-comm-font-10">
                            {hasError("author").message}
                          </p>
                        )}
                      </div>
                      {/* ebookPublisher */}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Publisher
                        </p>
                        <input
                          className="filter-search-modal-location-div"
                          type="text"
                          style={{
                            fontSize: 17,
                            paddingLeft: 8,
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          value={ebookPublisher}
                          onChange={handlePublisher}
                        />
                        {hasError("publisher") && (
                          <p className="filter-search-modal-comm-font-10">
                            {hasError("publisher").message}
                          </p>
                        )}
                      </div>
                      {/* ebookVersion */}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Version
                        </p>
                        <input
                          className="filter-search-modal-location-div"
                          style={{
                            fontSize: 17,
                            paddingLeft: 8,
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          value={ebookVersion}
                          onChange={handleVersion}
                        />
                      </div>
                      {/* ebookComments */}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Comments
                        </p>
                        <input
                          className="filter-search-modal-location-div"
                          style={{
                            fontSize: 17,
                            paddingLeft: 8,
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          value={ebookComments}
                          onChange={handleComments}
                        />
                        {hasError("comments") && (
                          <p className="filter-search-modal-comm-font-10">
                            {hasError("comments").message}
                          </p>
                        )}
                      </div>

                      {/* //Language */}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Language
                        </p>
                        <div className="filter-search-modal-location-div">
                          <FormControl
                            fullWidth
                            size="small"
                            style={{ paddingTop: "5px" }}
                          >
                            <Select
                              sx={{
                                boxShadow: "none",
                                ".MuiOutlinedInput-notchedOutline": {
                                  border: 0,
                                },
                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  border: 0,
                                },
                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: 0,
                                },
                                ".MuiSvgIcon-root ": {
                                  fill: "#FFB200 !important",
                                },
                              }}
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              value={
                                ebookLanguage ? ebookLanguage?.trim() : null
                              }
                              onChange={handleChangeLanguages}
                            >
                              <MenuItem value="">Select</MenuItem>
                              <MenuItem value="English">English</MenuItem>
                              <MenuItem value="Hindi"> हिंदी</MenuItem>
                              <MenuItem value="Gujrati">ગુજરાતી</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>

                      {/* ----- Upload Duration ----- */}
                      {/* <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Upload Duration
                        </p>
                        <div className="filter-search-modal-d-flex filter-search-modal-mt-5">
                          {uploadDurationArray.map((item) => {
                            return (
                              <div
                                className={`filter-search-modal-upload-dur-div filter-search-modal-mlr-8  ${advaceFilterVal.find(
                                  (item) =>
                                    item.contentType === advaceTabValue
                                )?.uploadDuration == item.value
                                  ? "active"
                                  : null
                                  }`}
                                onClick={() => uploadDurationselect(item.value)}
                              >
                                <p className="filter-search-modal-today">
                                  {item.lable}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      </div> */}
                    </>
                  )}
                </div>
              ) : advaceTabValue == "PATRA" ? (
                //patra
                <div>
                  <div className="filter-search-modal-content">
                    <p className="filter-search-modal-msg-text">
                      {"Filter for Patra"}
                    </p>
                    <span
                      className="filter-search-modal-close-button"
                      onClick={() => closeAdvanceModal()}
                    >
                      &times;
                    </span>
                  </div>
                  <div>
                    <p className="toggleText">
                      Enhance Your Search Abilities Within Books
                    </p>
                    <div className="customToggle">
                      <label class="switch">
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                  {isChecked ? (
                    <div className={inputValue ? "" : "epubinvalid"}>
                      <input
                        type="text"
                        className="custom-input"
                        placeholder="Enter text here"
                        value={inputValue}
                        onChange={handleInputChange}
                      />
                    </div>
                  ) : (
                    <>
                      {/*----- Video Duration -----*/}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">Pages</p>
                        <Box
                          sx={{
                            width: matchesMD === true ? 310 : 270,
                            paddingLeft: "10px",
                          }}
                        >
                          <Slider
                            sx={{
                              "& .MuiSlider-thumb": {
                                color: "#FFB200",
                              },
                              "& .MuiSlider-track": {
                                color: "#FFB200",
                              },
                              "& .MuiSlider-rail": {
                                color: "#212121",
                              },
                              "& .MuiSlider-active": {
                                color: "#212121",
                              },
                            }}
                            valueLabelDisplay="auto"
                            value={patraValue}
                            step={6}
                            marks={[
                              { value: 80, label: "80pp" },
                              { value: 160, label: "160pp" },
                              { value: 240, label: "240pp" },
                              { value: 320, label: "320pp" },
                              { value: 400, label: "400pp" },
                              { value: 480, label: "480pp" },
                            ]}
                            min={80}
                            max={480}
                            onChange={onChangeSlider}
                          />
                        </Box>
                      </div>

                      {/* fromDate */}
                      <div className="filter-search-modal-mt-15 customDate">
                        <p className="filter-search-modal-comm-font-13">
                          From Date
                        </p>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            // label="Date desktop"
                            className="form-control input-font"
                            inputFormat="DD/MM/YYYY"
                            name="fromDate"
                            // minDate={"2022-11-30"}
                            maxDate={new Date().toISOString().slice(0, 10)}
                            value={patraFromDate}
                            onChange={handleChangeFromDate}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ width: "100%" }}
                              // value={fromDate}
                              // onChange={handleChangeFromDate}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </div>

                      {/* todate */}
                      <div className="filter-search-modal-mt-15 customDate">
                        <p className="filter-search-modal-comm-font-13">To Date</p>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            // label="Date desktop"
                            className="form-control input-font"
                            inputFormat="DD/MM/YYYY"
                            name="todate"
                            minDate={patraFromDate}
                            maxDate={new Date().toISOString().slice(0, 10)}
                            value={patraToDate}
                            onChange={handleChangeToDate}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                className="input-font"
                                style={{ width: "100%" }}
                              // value={toDate}
                              // onChange={handleChangeToDate}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </div>

                      {/*indian fromdate */}
                      {/* <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">
                      India calendar Start
                    </p>
                    <div
                      style={{ position: "relative" }}
                      className="indian-calender"
                    >
                      <input
                        className="filter-search-modal-location-div"
                        readOnly
                        style={{
                          fontSize: 17,
                          paddingLeft: 8,
                          width: "100%",
                          boxSizing: "border-box",
                          outline: "none",
                          MozUserFocus: "none",
                        }}
                        value={indianCalndrFromDate}
                      />
                      {console.log(
                        "indianCalndrFromDate: ",
                        indianCalndrFromDate
                      )}
                      <DatePicker
                        id="datepicker"
                        value={indianCalndrFromDateObj}
                        className="input-font "
                        render={<Icon />}
                        onChange={(date) => handleHinduCalStart(date, "from")}
                        format="DD/MM/YYYY"
                        calendar={indian}
                        locale={indian_hi}
                        calendarPosition={"left-bottom"}
                        maxDate={new Date()}
                      />
                    </div>
                  </div> */}
                      {/*indian todate */}

                      {/* <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">
                      India calendar End
                    </p>
                    <div
                      style={{ position: "relative" }}
                      className="indian-calender"
                    >
                      <input
                        className="filter-search-modal-location-div"
                        readOnly
                        style={{
                          fontSize: 17,
                          paddingLeft: 8,
                          width: "100%",
                          boxSizing: "border-box",
                          outline: "none",
                          MozUserFocus: "none",
                        }}
                        value={indianCalndrToDate}
                      />
                      <DatePicker
                        id="datepicker"
                        render={<Icon />}
                        value={indianCalndrToDateObj}
                        className="input-font"
                        onChange={(date) => handleHinduCalStart(date, "to")}
                        format="DD/MM/YYYY"
                        calendar={indian}
                        locale={indian_hi}
                        maxDate={new Date()}
                        minDate={indianCalndrFromDateObj}
                        calendarPosition={"left-bottom"}
                      />
                    </div>
                  </div> */}

                      {/* author */}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">Author</p>
                        <input
                          className="filter-search-modal-location-div"
                          style={{
                            fontSize: 17,
                            paddingLeft: 8,
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          value={patraAuthor}
                          onChange={handleAuthor}
                        />
                      </div>
                      {hasError("author") && (
                        <p className="filter-search-modal-comm-font-10">
                          {hasError("author").message}
                        </p>
                      )}

                      {/* writtenTo */}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Written To
                        </p>
                        <input
                          className="filter-search-modal-location-div"
                          style={{
                            fontSize: 17,
                            paddingLeft: 8,
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          value={patraWrittenTo}
                          onChange={handleWrittenTo}
                        />
                        {hasError("writtenTo") && (
                          <p className="filter-search-modal-comm-font-10">
                            {hasError("writtenTo").message}
                          </p>
                        )}
                      </div>
                      {/* patraStartNumber */}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Patra Start No.
                        </p>
                        <input
                          className="filter-search-modal-location-div"
                          style={{
                            fontSize: 17,
                            paddingLeft: 8,
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          value={patrapatraNoStart}
                          onChange={handlePatraNoStart}
                        />
                        {hasError("patraNoStart") && (
                          <p className="filter-search-modal-comm-font-10">
                            {hasError("patraNoStart").message}
                          </p>
                        )}
                      </div>
                      {/* patraEndINumber */}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Patra End No.
                        </p>
                        <input
                          className="filter-search-modal-location-div"
                          style={{
                            fontSize: 17,
                            paddingLeft: 8,
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          value={patrapatraNoEnd}
                          onChange={handlePatraNoEnd}
                        />
                        {hasError("patraNoEnd") && (
                          <p className="filter-search-modal-comm-font-10">
                            {hasError("patraNoEnd").message}
                          </p>
                        )}
                      </div>

                      {/* Year of patra start */}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Year of Patra Start
                        </p>
                        <input
                          className="filter-search-modal-location-div"
                          style={{
                            fontSize: 17,
                            paddingLeft: 8,
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          value={yearPatraStart}
                          onChange={handleYearPatraStart}
                        />
                        {hasError("yearOfPatraStart") && (
                          <p className="filter-search-modal-comm-font-10">
                            {hasError("yearOfPatraStart").message}
                          </p>
                        )}
                      </div>
                      {/* year of patra end */}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Year of Patra End
                        </p>
                        <input
                          className="filter-search-modal-location-div"
                          style={{
                            fontSize: 17,
                            paddingLeft: 8,
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          value={yearPatraEnd}
                          onChange={handleYearPatraEnd}
                        />
                        {hasError("yearOfPatraEnd") && (
                          <p className="filter-search-modal-comm-font-10">
                            {hasError("yearOfPatraEnd").message}
                          </p>
                        )}
                      </div>

                      {/*----- Upload Duration -----*/}
                      {/* <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">
                      Upload Duration
                    </p>
                    <div className="filter-search-modal-d-flex filter-search-modal-mt-5">
                      {uploadDurationArray.map((item) => {
                        return (
                          <div
                            className={`filter-search-modal-upload-dur-div filter-search-modal-mlr-8  ${advaceFilterVal.find(
                              (item) => item.contentType === advaceTabValue
                            )?.uploadDuration == item.value
                              ? "active"
                              : null
                              }`}
                            onClick={() => uploadDurationselect(item.value)}
                          >
                            <p className="filter-search-modal-today">
                              {item.lable}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div> */}
                    </>)}
                </div>
              ) : advaceTabValue == "PAD" ? (
                //pad
                <div>
                  <div className="filter-search-modal-content">
                    <p className="filter-search-modal-msg-text">
                      {"Filter for Pad"}
                    </p>
                    <span
                      className="filter-search-modal-close-button"
                      onClick={() => closeAdvanceModal()}
                    >
                      &times;
                    </span>
                  </div>

                  {/*----- Video Duration -----*/}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">
                      Audio Duration
                    </p>
                    <Box
                      sx={{
                        width: matchesMD === true ? 310 : 270,
                        paddingLeft: "10px",
                      }}
                    >
                      <Slider
                        sx={{
                          "& .MuiSlider-thumb": {
                            color: "#FFB200",
                          },
                          "& .MuiSlider-track": {
                            color: "#FFB200",
                          },
                          "& .MuiSlider-rail": {
                            color: "#212121",
                          },
                          "& .MuiSlider-active": {
                            color: "#212121",
                          },
                        }}
                        valueLabelDisplay="auto"
                        value={padValue}
                        step={6}
                        marks={[
                          { value: 1, label: "1m" },
                          { value: 60, label: "60m" },
                          { value: 90, label: "90m" },
                          { value: 120, label: "120m" },
                          { value: 150, label: "150m" },
                          { value: 180, label: "180m" },
                        ]}
                        min={1}
                        max={180}
                        onChange={onChangeSlider}
                      />
                    </Box>
                  </div>

                  {/* title */}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Title</p>
                    <input
                      className="filter-search-modal-location-div"
                      style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                      value={padTitle}
                      onChange={handleTitle}
                    />
                  </div>
                  {/* author */}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Author</p>
                    <input
                      className="filter-search-modal-location-div"
                      style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                      value={padAuthor}
                      onChange={handleAuthor}
                    />
                    {hasError("author") && (
                      <p className="filter-search-modal-comm-font-10">
                        {hasError("author").message}
                      </p>
                    )}
                  </div>
                  {/* writtenTo */}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">
                      Written To
                    </p>
                    <input
                      className="filter-search-modal-location-div"
                      style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                      value={padWrittenTo}
                      onChange={handleWrittenTo}
                    />
                    {hasError("writtenTo") && (
                      <p className="filter-search-modal-comm-font-10">
                        {hasError("writtenTo").message}
                      </p>
                    )}
                  </div>

                  {/* fromDate */}
                  <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">
                      From Date
                    </p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        // label="Date desktop"
                        className="form-control input-font"
                        inputFormat="DD/MM/YYYY"
                        name="fromDate"
                        // minDate={"2022-11-30"}
                        maxDate={new Date().toISOString().slice(0, 10)}
                        value={padFromDate}
                        onChange={handleChangeFromDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ width: "100%" }}
                          // value={fromDate}
                          // onChange={handleChangeFromDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  {/* todate */}
                  <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">To Date</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        // label="Date desktop"
                        className="form-control input-font"
                        inputFormat="DD/MM/YYYY"
                        name="todate"
                        minDate={padFromDate}
                        maxDate={new Date().toISOString().slice(0, 10)}
                        value={padToDate}
                        onChange={handleChangeToDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="input-font"
                            style={{ width: "100%" }}
                          // value={toDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  {/* <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">
                      India calendar Start
                    </p>
                    <div
                      style={{ position: "relative" }}
                      className="indian-calender"
                    >
                      <input
                        className="filter-search-modal-location-div"
                        readOnly
                        style={{
                          fontSize: 17,
                          paddingLeft: 8,
                          width: "100%",
                          boxSizing: "border-box",
                          outline: "none",
                          MozUserFocus: "none",
                        }}
                        value={padIndianCalndrFromDate}
                      />

                      <DatePicker
                        id="datepicker"
                        value={indianCalndrFromDateObj}
                        className="input-font"
                        render={<Icon />}
                        onChange={(date) => handleHinduCalStart(date, "from")}
                        format="DD/MM/YYYY"
                        calendar={indian}
                        locale={indian_hi}
                        maxDate={new Date()}
                        calendarPosition={"left-bottom"}
                      />
                    </div>
                  </div> */}

                  {/* <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">
                      India calendar End
                    </p>
                    <div
                      style={{ position: "relative" }}
                      className="indian-calender"
                    >
                      <input
                        readOnly
                        className="filter-search-modal-location-div"
                        style={{
                          fontSize: 17,
                          paddingLeft: 8,
                          width: "100%",
                          boxSizing: "border-box",
                          outline: "none",
                          MozUserFocus: "none",
                        }}
                        value={padIndianCalndrToDate}
                      />
                      <DatePicker
                        id="datepicker"
                        value={indianCalndrToDateObj}
                        className="input-font"
                        render={<Icon />}
                        onChange={(date) => handleHinduCalStart(date, "to")}
                        format="DD/MM/YYYY"
                        calendar={indian}
                        locale={indian_hi}
                        minDate={indianCalndrFromDateObj}
                        maxDate={new Date()}
                        calendarPosition={"left-bottom"}
                      />
                    </div>
                  </div> */}

                  {/* //Language */}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Language</p>
                    <div className="filter-search-modal-location-div">
                      <FormControl
                        fullWidth
                        size="small"
                        style={{ paddingTop: "5px" }}
                      >
                        <Select
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                            ".MuiSvgIcon-root ": {
                              fill: "#FFB200 !important",
                            },
                          }}
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={padLanguage ? padLanguage?.trim() : null}
                          onChange={handleChangeLanguages}
                        >
                          <MenuItem value="">Select</MenuItem>
                          <MenuItem value="English">English</MenuItem>
                          <MenuItem value="Hindi"> हिंदी</MenuItem>
                          <MenuItem value="Gujrati">ગુજરાતી</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              ) : advaceTabValue == "TRANSCRIPT" ? (
                //patra
                <div>
                  <div className="filter-search-modal-content">
                    <p className="filter-search-modal-msg-text">
                      {"Filter for Transcript"}
                    </p>
                    <span
                      className="filter-search-modal-close-button"
                      onClick={() => closeAdvanceModal()}
                    >
                      &times;
                    </span>
                  </div>
                  <div>
                    <p className="toggleText">
                      Enhance Your Search Abilities Within Books
                    </p>
                    <div className="customToggle">
                      <label class="switch">
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                  {isChecked ? (
                    <div className={inputValue ? "" : "epubinvalid"}>
                      <input
                        type="text"
                        className="custom-input"
                        placeholder="Enter text here"
                        value={inputValue}
                        onChange={handleInputChange}
                      />
                    </div>
                  ) : (
                    <>
                      {/*----- Video Duration -----*/}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">Pages</p>
                        <Box
                          sx={{
                            width: matchesMD === true ? 310 : 270,
                            paddingLeft: "10px",
                          }}
                        >
                          <Slider
                            sx={{
                              "& .MuiSlider-thumb": {
                                color: "#FFB200",
                              },
                              "& .MuiSlider-track": {
                                color: "#FFB200",
                              },
                              "& .MuiSlider-rail": {
                                color: "#212121",
                              },
                              "& .MuiSlider-active": {
                                color: "#212121",
                              },
                            }}
                            valueLabelDisplay="auto"
                            value={transcriptValue}
                            step={6}
                            marks={[
                              { value: 80, label: "80pp" },
                              { value: 160, label: "160pp" },
                              { value: 240, label: "240pp" },
                              { value: 320, label: "320pp" },
                              { value: 400, label: "400pp" },
                              { value: 480, label: "480pp" },
                            ]}
                            min={80}
                            max={480}
                            onChange={onChangeSlider}
                          />
                        </Box>
                      </div>

                      {/* fromDate */}
                      <div className="filter-search-modal-mt-15 customDate">
                        <p className="filter-search-modal-comm-font-13">
                          From Date
                        </p>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            // label="Date desktop"
                            className="form-control input-font"
                            inputFormat="DD/MM/YYYY"
                            name="fromDate"
                            // minDate={"2022-11-30"}
                            maxDate={new Date().toISOString().slice(0, 10)}
                            value={transcriptFromDate}
                            onChange={handleChangeFromDate}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ width: "100%" }}
                              // value={fromDate}
                              // onChange={handleChangeFromDate}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </div>

                      {/* todate */}
                      <div className="filter-search-modal-mt-15 customDate">
                        <p className="filter-search-modal-comm-font-13">To Date</p>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            // label="Date desktop"
                            className="form-control input-font"
                            inputFormat="DD/MM/YYYY"
                            name="todate"
                            minDate={transcriptFromDate}
                            maxDate={new Date().toISOString().slice(0, 10)}
                            value={transcriptToDate}
                            onChange={handleChangeToDate}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                className="input-font"
                                style={{ width: "100%" }}
                              // value={toDate}
                              // onChange={handleChangeToDate}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </div>

                      {/* transcriptTopic */}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">Topic</p>
                        <input
                          className="filter-search-modal-location-div"
                          style={{
                            fontSize: 17,
                            paddingLeft: 8,
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          value={transcriptTopic}
                          onChange={handleTopics}
                        />
                        {hasError("topic") && (
                          <p className="filter-search-modal-comm-font-10">
                            {hasError("topic").message}
                          </p>
                        )}
                      </div>

                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Transcript Start No.
                        </p>
                        <input
                          className="filter-search-modal-location-div"
                          style={{
                            fontSize: 17,
                            paddingLeft: 8,
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          value={transcriptPatraNoStart}
                          onChange={handlePatraNoStart}
                        />
                        {hasError("patraNoStart") && (
                          <p className="filter-search-modal-comm-font-10">
                            {hasError("patraNoStart").message}
                          </p>
                        )}
                      </div>

                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Transcript End No.
                        </p>
                        <input
                          className="filter-search-modal-location-div"
                          style={{
                            fontSize: 17,
                            paddingLeft: 8,
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          value={transcriptPatraNoEnd}
                          onChange={handlePatraNoEnd}
                        />
                        {hasError("patraNoEnd") && (
                          <p className="filter-search-modal-comm-font-10">
                            {hasError("patraNoEnd").message}
                          </p>
                        )}
                      </div>
                      {/* //Language */}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">Language</p>
                        <div className="filter-search-modal-location-div">
                          <FormControl
                            fullWidth
                            size="small"
                            style={{ paddingTop: "5px" }}
                          >
                            <Select
                              sx={{
                                boxShadow: "none",
                                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  border: 0,
                                },
                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: 0,
                                },
                                ".MuiSvgIcon-root ": {
                                  fill: "#FFB200 !important",
                                },
                              }}
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              value={
                                transcriptLanguage
                                  ? transcriptLanguage?.trim()
                                  : null
                              }
                              onChange={handleChangeLanguages}
                            >
                              <MenuItem value="">Select</MenuItem>
                              <MenuItem value="English">English</MenuItem>
                              <MenuItem value="Hindi"> हिंदी</MenuItem>
                              <MenuItem value="Gujrati">ગુજરાતી</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>

                      {/* speaker */}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">speaker</p>
                        <div className="filter-search-modal-location-div">
                          <FormControl
                            fullWidth
                            size="small"
                            style={{ paddingTop: "5px" }}
                          >
                            <Select
                              sx={{
                                boxShadow: "none",
                                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  border: 0,
                                },
                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: 0,
                                },
                                ".MuiSvgIcon-root ": {
                                  fill: "#FFB200 !important",
                                },
                              }}
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              value={
                                transcriptSpeakers
                                  ? transcriptSpeakers?.trim()
                                  : null
                              }
                              onChange={handleSpeakers}
                            >
                              {hasError("speaker") && (
                                <p className="filter-search-modal-comm-font-10">
                                  {hasError("speaker").message}
                                </p>
                              )}
                              <MenuItem value="">Select</MenuItem>
                              {satsangSpeaker.map((item) => {
                                return <MenuItem value={item}>{item}</MenuItem>;
                              })}
                              {/* <MenuItem value=''></MenuItem>
                        <MenuItem value='Ahmedabad'>Ahmedabad</MenuItem>
                        <MenuItem value='Mumbai'>Mumbai</MenuItem>
                        <MenuItem value='Pune'>Pune</MenuItem>
                        <MenuItem value='india'>India</MenuItem> */}
                            </Select>
                          </FormControl>
                        </div>
                      </div>

                      {/*----- Upload Duration -----*/}
                      {/* <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">
                      Upload Duration
                    </p>
                    <div className="filter-search-modal-d-flex filter-search-modal-mt-5">
                      {uploadDurationArray.map((item) => {
                        return (
                          <div
                            className={`filter-search-modal-upload-dur-div filter-search-modal-mlr-8  ${advaceFilterVal.find(
                              (item) => item.contentType === advaceTabValue
                            )?.uploadDuration == item.value
                              ? "active"
                              : null
                              }`}
                            onClick={() => uploadDurationselect(item.value)}
                          >
                            <p className="filter-search-modal-today">
                              {item.lable}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div> */}
                    </>)}
                </div>
              ) : advaceTabValue == "EBOOK_SYSTEM_PLAYLIST" ? (

                <div>
                  <div className="filter-search-modal-content">
                    <p className="filter-search-modal-msg-text">
                      {"Filter for ebook series"}
                    </p>
                    <span
                      className="filter-search-modal-close-button"
                      onClick={() => closeAdvanceModal()}
                    >
                      &times;
                    </span>
                  </div>


                  {/* title */}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Title</p>
                    <input
                      className="filter-search-modal-location-div"
                      style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                      value={ebookSeriesTitle}
                      onChange={handleTitle}
                    />
                  </div>
                  {/* author */}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Author</p>
                    <input
                      className="filter-search-modal-location-div"
                      style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                      value={ebookSeriesAuthor}
                      onChange={handleAuthor}
                    />
                    {hasError("author") && (
                      <p className="filter-search-modal-comm-font-10">
                        {hasError("author").message}
                      </p>
                    )}
                  </div>
                  {/* writtenTo */}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">
                      Written To
                    </p>
                    <input
                      className="filter-search-modal-location-div"
                      style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                      value={ebookSeriesWrittenTo}
                      onChange={handleWrittenTo}
                    />
                    {hasError("writtenTo") && (
                      <p className="filter-search-modal-comm-font-10">
                        {hasError("writtenTo").message}
                      </p>
                    )}
                  </div>

                  {/* writtenBy */}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">
                      Written By
                    </p>
                    <input
                      className="filter-search-modal-location-div"
                      style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                      value={ebookSeriesWrittenBy}
                      onChange={handleWrittenBy}
                    />
                    {hasError("writtenBy") && (
                      <p className="filter-search-modal-comm-font-10">
                        {hasError("writtenBy").message}
                      </p>
                    )}
                  </div>

                  {/* RecommendedBy */}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">
                      Recommended By
                    </p>
                    <input
                      className="filter-search-modal-location-div"
                      style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                      value={ebookSeriesRecommendedBy}
                      onChange={handleRecommendedBy}
                    />
                    {hasError("recommendedBy") && (
                      <p className="filter-search-modal-comm-font-10">
                        {hasError("recommendedBy").message}
                      </p>
                    )}
                  </div>




                  {/* fromDate */}
                  <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">
                      From Date
                    </p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        // label="Date desktop"
                        className="form-control input-font"
                        inputFormat="DD/MM/YYYY"
                        name="fromDate"
                        // minDate={"2022-11-30"}
                        maxDate={new Date().toISOString().slice(0, 10)}
                        value={ebookSeriesFromDate}
                        onChange={handleChangeFromDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ width: "100%" }}
                          // value={fromDate}
                          // onChange={handleChangeFromDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  {/* todate */}
                  <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">To Date</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        // label="Date desktop"
                        className="form-control input-font"
                        inputFormat="DD/MM/YYYY"
                        name="todate"
                        minDate={ebookSeriesFromDate}
                        maxDate={new Date().toISOString().slice(0, 10)}
                        value={ebookSeriesToDate}
                        onChange={handleChangeToDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="input-font"
                            style={{ width: "100%" }}
                          // value={toDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  {/*----- Location -----*/}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Location</p>
                    <div className="filter-search-modal-location-div">
                      <FormControl
                        fullWidth
                        size="small"
                        style={{ paddingTop: "5px" }}
                      >
                        <Select
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                            ".MuiSvgIcon-root ": {
                              fill: "#FFB200 !important",
                            },
                          }}
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={
                            filterEbookSerieslocation
                              ? filterEbookSerieslocation?.trim()
                              : null
                          }
                          onChange={handleLocationChange}
                        >
                          <MenuItem value="">Select</MenuItem>
                          {satsangLocations.map((item) => {
                            return <MenuItem value={item}>{item}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                </div>
              ) : advaceTabValue == "YOUTUBE_TRACK" ? (
                <div>
                  <div className="filter-search-modal-content">
                    <p className="filter-search-modal-msg-text">
                      {"Filter for Bhakti Pad"}
                    </p>
                    <span
                      className="filter-search-modal-close-button"
                      onClick={() => closeAdvanceModal()}
                    >
                      &times;
                    </span>
                  </div>

                  {/*----- Video Duration -----*/}

                  {/* <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">
                      Audio Duration
                    </p>
                    <Box sx={{ width: "92%", paddingLeft: "10px" }}>
                      <Slider
                        sx={{
                          "& .MuiSlider-thumb": {
                            color: "#FFB200",
                          },
                          "& .MuiSlider-track": {
                            color: "#FFB200",
                          },
                          "& .MuiSlider-rail": {
                            color: "#212121",
                          },
                          "& .MuiSlider-active": {
                            color: "#212121",
                          },
                        }}
                        valueLabelDisplay="auto"
                        value={bhaktiPadDuration}
                        step={8}
                        marks={generateDynamicMarksArray(1, 500, 8)}
                        min={1}
                        max={500}
                        onChange={onChangeSlider}
                      />
                    </Box>
                  </div> */}

                  {/* <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Title</p>
                    <input
                      className="filter-search-modal-location-div"
                      style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                      value={bhaktiPadTitle}
                      onChange={handleTitle}
                    />
                  </div> */}

                  {/* fromDate */}
                  <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">
                      From Date
                    </p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        // label="Date desktop"
                        className="form-control input-font"
                        inputFormat="DD/MM/YYYY"
                        name="fromDate"
                        // minDate={"2022-11-30"}
                        maxDate={new Date().toISOString().slice(0, 10)}
                        value={bhaktiPadFromDate}
                        onChange={handleChangeFromDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ width: "100%" }}
                          // value={fromDate}
                          // onChange={handleChangeFromDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  {/* todateI */}
                  <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">To Date</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        // label="Date desktop"
                        className="form-control input-font"
                        inputFormat="DD/MM/YYYY"
                        name="todate"
                        minDate={bhaktiPadFromDate}
                        maxDate={new Date().toISOString().slice(0, 10)}
                        value={bhaktiPadToDate}
                        onChange={handleChangeToDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="input-font"
                            style={{ width: "100%" }}
                          // value={toDate}
                          // onChange={handleChangeToDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  {/* author */}
                  {/* <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Author</p>
                    <input
                      className="filter-search-modal-location-div"
                      style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                      value={bhaktiPadAuthor}
                      onChange={handleAuthor}
                    />
                  </div> */}



                  {/* speaker */}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Artist</p>
                    <div className="filter-search-modal-location-div">
                      <FormControl
                        fullWidth
                        size="small"
                        style={{ paddingTop: "5px" }}
                      >
                        <Select
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                            ".MuiSvgIcon-root ": {
                              fill: "#FFB200 !important",
                            },
                          }}
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={
                            bhaktiPadArtist
                              ? bhaktiPadArtist?.trim()
                              : null
                          }
                          onChange={handleSpeakers}
                        >
                          {hasError("speaker") && (
                            <p className="filter-search-modal-comm-font-10">
                              {hasError("speaker").message}
                            </p>
                          )}
                          <MenuItem value="">Select</MenuItem>
                          {satsangSpeaker.map((item) => {
                            return <MenuItem value={item}>{item}</MenuItem>;
                          })}
                          {/* <MenuItem value=''></MenuItem>
                        <MenuItem value='Ahmedabad'>Ahmedabad</MenuItem>
                        <MenuItem value='Mumbai'>Mumbai</MenuItem>
                        <MenuItem value='Pune'>Pune</MenuItem>
                        <MenuItem value='india'>India</MenuItem> */}
                        </Select>
                      </FormControl>
                    </div>
                  </div>


                  {hasError("author") && (
                    <p className="filter-search-modal-comm-font-10">
                      {hasError("author").message}
                    </p>
                  )}
                </div>
              ) : advaceTabValue == "YOUTUBE_ALBUM" ? (
                <div>
                  <div className="filter-search-modal-content">
                    <p className="filter-search-modal-msg-text">
                      {"Filter for Album"}
                    </p>
                    <span
                      className="filter-search-modal-close-button"
                      onClick={() => closeAdvanceModal()}
                    >
                      &times;
                    </span>
                  </div>

                  {/*----- Video Duration -----*/}
                  {/* <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">
                      Audio Duration
                    </p>
                    <Box sx={{ width: "92%", paddingLeft: "10px" }}>
                      <Slider
                        sx={{
                          "& .MuiSlider-thumb": {
                            color: "#FFB200",
                          },
                          "& .MuiSlider-track": {
                            color: "#FFB200",
                          },
                          "& .MuiSlider-rail": {
                            color: "#212121",
                          },
                          "& .MuiSlider-active": {
                            color: "#212121",
                          },
                        }}
                        valueLabelDisplay="auto"
                        value={albumDuration}
                        step={8}
                        marks={generateDynamicMarksArray(1, 500, 8)}
                        min={1}
                        max={500}
                        onChange={onChangeSlider}
                      />
                    </Box>
                  </div> */}

                  {/* <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Title</p>
                    <input
                      className="filter-search-modal-location-div"
                      style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                      value={albumTitle}
                      onChange={handleTitle}
                    />
                  </div> */}

                  {/* fromDate */}
                  <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">
                      From Date
                    </p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        // label="Date desktop"
                        className="form-control input-font"
                        inputFormat="DD/MM/YYYY"
                        name="fromDate"
                        // minDate={"2022-11-30"}
                        maxDate={new Date().toISOString().slice(0, 10)}
                        value={albumFromDate}
                        onChange={handleChangeFromDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ width: "100%" }}
                          // value={fromDate}
                          // onChange={handleChangeFromDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  {/* todateI */}
                  <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">To Date</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        // label="Date desktop"
                        className="form-control input-font"
                        inputFormat="DD/MM/YYYY"
                        name="todate"
                        minDate={albumFromDate}
                        maxDate={new Date().toISOString().slice(0, 10)}
                        value={albumToDate}
                        onChange={handleChangeToDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="input-font"
                            style={{ width: "100%" }}
                          // value={toDate}
                          // onChange={handleChangeToDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  {/* author */}
                  {/* <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Author</p>
                    <input
                      className="filter-search-modal-location-div"
                      style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                      value={albumAuthor}
                      onChange={handleAuthor}
                    />
                  </div> */}
                  {hasError("author") && (
                    <p className="filter-search-modal-comm-font-10">
                      {hasError("author").message}
                    </p>
                  )}


                </div>
              ) : null}
            </DialogContent>

            <DialogActions>
              <div className="filter-search-modal-action-content">
                <button
                  className="filter-search-modal-cancel-btn"
                  onClick={() => ClearFilter()}
                >
                  {"Clear"}
                </button>
                <button
                  className="filter-search-modal-apply-btn"
                  onClick={() =>
                    isChecked ? EbookSearchPoc() : advanceSearch(2)
                  }
                >
                  {"Apply"}
                </button>
              </div>
            </DialogActions>
          </Dialog>
        )}
        {isLoginModalOpen && (
          <Dialog
            open={isLoginModalOpen}
            className="login-modal-popup"
       
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent
              style={{
                overflowY: "hidden",
                overflowX: "hidden",
          
              }}
              className="login_modal"
            >
              <div className="card">
                <div className="info__padding">
                  <h3>{`You are logged out. Do you want to log in ? `}</h3>
                  {/* <h3>{!auth ? "Sign In" : ""}</h3> */}
                </div>

                <div className="button__group">
                  <Button
                    onClick={() => {
                      onChange({ true: true });
                      handleCloseLoginModal();
                    }}
                    variant="contained"
                    className="signBtnHome"
                  >
                    <img
                      style={{ height: "23px", width: "23px" }}
                      className="g-icon"
                      src={Google}
                    ></img>
                    Sign-in
                  </Button>
                  <button className="no-btn" onClick={handleCloseLoginModal}>
                    Cancel
                  </button>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        )}
      </>
    )
  );
}

export default Header;
