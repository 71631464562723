import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "./style.css";
import {
  GLOBAL_URL,
  AllImages,
  RemoveDuplicates,
  DateTimeAgo,
  emojiRegex,
  getImageNameFromResolution,
  MinutesToHoursMinutes,
  VideoPlaylistShareUrl,
} from "../../../utils/constants";
import {
  LoaderContext,
  VideoListContext,
  UserloggedContext,
} from "../../Context/UserContext";
import ApiEndPoint from "../../../utils/apiEndPoints";
import { setDefaultHeader, apiCall } from "../../../utils/httpClient";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import NoDataAvailable from "../../common/NoDataFound/NoDataAvailable";
import loader from "../../../assets/images/logo.png";
import InfiniteScroll from "react-infinite-scroll-component";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Button from "@material-ui/core/Button";
import { RWebShare } from "react-web-share";
const SortingOptions = {
  NEWLY_ADDED: "NEWLY_ADDED",
  PREVIOUSLY_ADDED: "PREVIOUSLY_ADDED",
  POPULAR_VIDEOS: "POPULAR_VIDEOS",
  NEWLY_PUBLISHED: "NEWLY_PUBLISHED",
  PREVIOUSLY_PUBLISHED: "PREVIOUSLY_PUBLISHED",
  // LAST_MODIFIED_DATE: "LAST_MODIFIED_DATE",
  Sort_A_To_Z: "A_TO_Z",
  Sort_Z_To_A: "Z_TO_A",
};
function MyCreatePlayListSelectVideo(params) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [auth, setAuth] = useContext(UserloggedContext);
  const location = useLocation();
  const { id } = location.state || {};
  const [scrollHashMap, setScrollHashMap] = useState(true);
  const [count, setCount] = useState(9);
  const [start, setStart] = useState(0);
  const [scrollLast, setScrollLast] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [isOpenSortBy, setIsOpenSortBy] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedFilterOp, setSelectedFilterOp] = useState(
    SortingOptions.NEWLY_ADDED
  );
  useEffect(() => {
    fetchData();
  }, [selectedFilterOp]);
  const fetchData = () => {
    setScrollHashMap(true);

    fetchVideoList(1);
  };
  const fetchMoreData = () => {
    fetchVideoList();
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchData();
    }
  };

  async function fetchVideoList(fetchPage = 0) {
    // await window.scrollTo(0, 0);

    try {
      const params = {
        searchValue: searchText,
        sortColumn: selectedFilterOp,
        sortDirection: "",
        pageNo: fetchPage ? fetchPage - 1 : pageNo,
        pageSize: 20,
        playListId: id,
      };

      setIsLoading(true);

      const { data } = await apiCall(
        "POST",
        ApiEndPoint.VIDEOMETALISTFORPLAYLIST,
        params
      );
      if (data.length > 0) {
        setVideoList(
          RemoveDuplicates(fetchPage ? data : videoList.concat(data))
        );
        setIsLoading(false);
        setPageNo(fetchPage ? fetchPage : pageNo + 1);
        setScrollHashMap(data.length == 20);
        setScrollLast(true);
      } else {
        fetchPage && setVideoList([]);
        setPageNo(0);
        setIsLoading(false);
        setScrollHashMap(false);
        setScrollLast(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log("err: ", err);
      setScrollHashMap(false);
      setScrollLast(false);
    }
    isOpenSortBy && closeModalSortBy();
  }

  const addFavorite = async (item, index) => {
    if (auth) {
      videoList[index].myFavourite = item.myFavourite ? false : true;
      try {
        const { data } = await apiCall("POST", `/my-favourites/${item.id}`);
        if (data) {
          setRefresh(!refresh);
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
      }
    } else {
      // setGoogleLoginShow(true)
    }
  };
  const videoWatchLater = async (item, index) => {
    if (auth) {
      videoList[index].watchLater = item.watchLater ? false : true;
      try {
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.VIDEOWATCHLATER}/${item.id}`
        );
        if (data) {
          setRefresh(!refresh);
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
      }
    } else {
      // setGoogleLoginShow(true)
    }
  };
  const RemoveWatchLaterVideo = async (item, index) => {
    if (auth) {
      videoList[index].watchLater = item.watchLater ? false : true;
      try {
        const { data } = await apiCall(
          "DELETE",
          `${ApiEndPoint.WATCHLATEDREMOVE}/${item.id}`
        );
        if (data) {
          setRefresh(!refresh);
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
      }
    } else {
      // setGoogleLoginShow(true)
    }
  };

  async function addVideoInPlaylist(contentId, index) {
    try {
      if (auth == "01") {
        const params = {
          contentId: contentId,
          myPlayList: { id: id },
          contentType: "VIDEO",
        };
        const { data } = await apiCall(
          "POST",
          ApiEndPoint.USERPLAYLSITADDVIDEO,
          params
        );
        if (data.length > 0) {
          setIsLoading(false);
          setVideoList((prev) => {
            const updatedList = [...prev];
            if (updatedList[index]) {
              updatedList[index].isAddedToPlaylist = true;
            }
            return updatedList;
          });
        } else {
          setIsLoading(false);
        }
      }
    } catch (err) {
      setIsLoading(false);
      console.log("err: ", err);
    }
  }

  const openModalSortBy = () => {
    setIsOpenSortBy(true);
  };

  const closeModalSortBy = () => {
    setIsOpenSortBy(false);
  };
  function handleSearch(e) {
    if (!emojiRegex.test(e.target.value)) {
      if (e.target.value) {
        setSearchText(e.target.value);
      } else {
        setSearchText(null);
      }
    }
  }

  const handleRadioChange = (event) => {
    setSelectedFilterOp(event.target.value);
  };
  function capitalizeFirstWord(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function takeSubcription(params) {
    params?.subscriptionName
      ? confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="card">
                <div className="info__padding">
                  <h3>
                    {`Please Subscribe to ${params?.subscriptionName} plan to play this video. `}
                  </h3>
                  <h3>{!auth ? "Sign In To Subscribe..." : ""}</h3>
                </div>
                {!auth ? (
                  <>
                    <div className="button__group">
                      <Button
                        onClick={() => {
                          // signIn();
                          onClose();
                        }}
                        variant="contained"
                        className="signBtnHome"
                      >
                        {/* <img className="g-icon" src={Google}></img> */}
                        Sign-in
                      </Button>
                      <button className="no-btn" onClick={onClose}>
                        Cancel
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="button__group">
                      <Button
                        onClick={() => {
                          navigate("/subscriptionPlanList");
                          onClose();
                        }}
                        variant="contained"
                        className="subscriptionBtn"
                        // className="signBtnHome"
                      >
                        {/* <img className="g-icon" src={Google}></img> */}
                        Subscribe
                      </Button>
                      <button className="no-btn" onClick={onClose}>
                        Cancel
                      </button>
                    </div>
                  </>
                )}
              </div>
            );
          },
        })
      : confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="card">
                <div className="info__padding">
                  <h3>{`This Video is not yet available`}</h3>
                </div>
                <div className="button__group">
                  <button className="button btn-ok" onClick={onClose}>
                    Ok
                  </button>
                </div>
              </div>
            );
          },
        });
  }

  return (
    <>
      <div className="MyCreatePlayListSelectVideo">
        <div className="my-created-playlist-title-search-div">
          {/*----- Created Playlist Title Start -----*/}
          <div className="my-created-playlist-header">
            <div className="my-created-playlist-title-mt-20">
              <span className="my-created-playlist-d-flex">
                <img
                  onClick={() => navigate(-1)}
                  className="my-created-playlist-c-pointer"
                  src={AllImages.YellowArrow}
                  alt="Back Arrow"
                  title="Back Arrow"
                ></img>
                <p className="my-created-playlist-title">Select Video</p>
              </span>
            </div>
            {/* <div>
                <p className="header-create-btn">CREATE</p>
              </div> */}
          </div>
          {/*----- Created Playlist Title End -----*/}

          {/*----- Search Box Start -----*/}
          <div className="my-created-playlist-search-wrap-outer">
            <div className="my-created-playlist-search-wrap">
              <div className="search-input">
                <span onClick={fetchData} className="search-icon">
                  {isLoading ? (
                    <img
                      src={loader}
                      alt="loading..."
                      style={{ height: 15, width: 15 }}
                    />
                  ) : (
                    <div className="searchTooltip">
                      <img src={AllImages.Search} alt="Search" title="Search" />
                      <span className="tooltiptext">{"Search"}</span>
                    </div>
                  )}
                  {/* <img src={AllImages.Search} alt="Search" title="Search" /> */}
                </span>
                <img
                  onClick={() => navigate(-1)}
                  src={AllImages.YellowArrow}
                  alt="Back Arrow"
                  title="Back Arrow"
                  className="notification-icon"
                />
                <input
                  className="f-poppins"
                  type="text"
                  placeholder="Search for Video"
                  onChange={(e) => handleSearch(e)}
                  onKeyUp={handleKeyPress}
                />
              </div>
              <div className="searchIcon-outer">
                <img
                  src={AllImages.SearchIcon}
                  alt="Search"
                  title="Search"
                  className="searchIcon"
                />
              </div>
              <div
                className="filter-icon sortbyTooltip"
                onClick={() => openModalSortBy()}
              >
                <img src={AllImages.SearchToggle} alt="Search" title="Search" />
                <span className="tooltiptext">{"Sort By"}</span>
              </div>
            </div>
          </div>
          {/*----- Search Box End -----*/}
        </div>

        {/*----- My Create Playlists  -----*/}
        <InfiniteScroll
          dataLength={videoList.length}
          next={() => fetchMoreData()}
          hasMore={scrollHashMap}
          loader={
            scrollLast ? (
              isLoading && (
                <center>
                  <img
                    src={loader}
                    alt="loading..."
                    style={{ height: 25, width: 25, marginTop: 20 }}
                  />
                </center>
              )
            ) : (
              <p className="subtitle fancy">
                <span>End of results</span>
              </p>
            )
          }
        >
          <div className="my-created-playlist-col-3 custom-row">
            {videoList && videoList.length > 0
              ? videoList?.map((item, index) => {
                  return (
                    <div className="custom-col-4 custom-col">
                      {item.videoUrl ? (
                        <div className="my-created-playlist-card-wrap">
                          <div className="my-created-playlist-inside-wrap">
                            <div className="list-image">
                              <img
                                className={`img-banner ${
                                  getImageNameFromResolution(
                                    item?.thumbnails
                                  ) != "maxresdefault" && "image-resolution"
                                }`}
                                src={
                                  item.thumbnails
                                    ? item.thumbnails
                                    : AllImages?.VideoImage
                                }
                                alt="Card Image"
                                title="Card Image"
                              />
                              <div className="playIconTooltip">
                                <img
                                  className="my-created-playlist-play-icon"
                                  src={AllImages?.PlayIcon}
                                  alt="play Icon"
                                  title="Play Icon"
                                />
                                <span className="tooltiptext">
                                  {"Play Video"}
                                </span>
                              </div>
                            </div>
                            <div className="my-created-playlist-content-item">
                              <div className="my-created-playlist-video-content">
                                <div>
                                  <p className="ebook-title-text f-poppins">
                                    {item.videoTitle}
                                  </p>
                                  <div className="ebook-textDetails ebook-text-hrs f-poppins">
                                    <p className="ebook-text-hrs">
                                      {item.videoDuration
                                        ? MinutesToHoursMinutes(
                                            item.videoDuration
                                          )
                                        : null}{" "}
                                      {item.videoDuration ? "hrs" : null}{" "}
                                    </p>
                                    {/* <img
                                      className="dot"
                                      src={AllImages?.Dot}
                                      alt="dot"
                                      title="dot"
                                    ></img>
                                    <p className="ebook-text-hrs">
                                      {DateTimeAgo(item.createdDate)}
                                    </p> */}
                                  </div>
                                  {/* <p className="ebook-text-hrs f-poppins">22</p> */}
                                </div>
                                <div className="my-created-playlist-like-icon-div-outer">
                                  <div className="my-created-playlist-like-icon-div d-flex align-center">
                                    <div className="timerTooltip">
                                      <img
                                        onClick={() =>
                                          item?.watchLater
                                            ? RemoveWatchLaterVideo(item, index)
                                            : videoWatchLater(item, index)
                                        }
                                        src={
                                          item?.watchLater
                                            ? AllImages.SelectedTimer
                                            : AllImages.Timer
                                        }
                                        alt="Timer"
                                        title="Timer"
                                      ></img>
                                      <span className="tooltiptext">
                                        {!item.watchLater
                                          ? "Watch Later"
                                          : "Remove Watch Later"}
                                      </span>
                                    </div>
                                    <RWebShare
                                      data={{
                                        url: `${VideoPlaylistShareUrl}/videoPlayPage/${item.id}`,
                                        title: "Video Share",
                                      }}
                                      // onClick={() => console.log('share')}
                                    >
                                      <img src={AllImages.Copy}></img>
                                    </RWebShare>

                                    <div className="favTooltip">
                                      <img
                                        src={
                                          item?.myFavourite
                                            ? AllImages.Favorite_sel
                                            : AllImages.Fav
                                        }
                                        onClick={() => addFavorite(item, index)}
                                        alt="Favourite"
                                        title="Favourite"
                                      ></img>
                                      <span className="tooltiptext">
                                        {!item.myFavourite
                                          ? "Mark Favorite"
                                          : "Unfavorite"}
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    className="download-ebook-read addVideoTooltip"
                                    onClick={
                                      item?.isAddedToPlaylist
                                        ? null
                                        : () =>
                                            addVideoInPlaylist(item.id, index)
                                    }
                                  >
                                    {item.isAddedToPlaylist ? "Added" : "Add"}
                                    <span className="tooltiptext">
                                      {!item.isAddedToPlaylist
                                        ? "Add Video"
                                        : "Video added already"}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        //Lock video full card
                        <div className="my-created-playlist-card-wrap">
                          <div
                            onClick={() => takeSubcription(item)}
                            className="my-created-playlist-inside-wrap"
                          >
                            <div>
                              <div className="list-image">
                                <img
                                  className={`img-banner ${
                                    getImageNameFromResolution(
                                      item?.thumbnails
                                    ) != "maxresdefault" && "image-resolution"
                                  }`}
                                  src={
                                    item.thumbnails
                                      ? item.thumbnails
                                      : AllImages?.VideoImage
                                  }
                                  alt="Card Image"
                                  title="Card Image"
                                />
                                <img
                                  className="my-created-playlist-play-icon"
                                  src={AllImages?.PlayIcon}
                                  alt="play Icon"
                                  title="Play Icon"
                                />
                              </div>
                              <div className="layerImage"></div>
                            </div>
                            <div className="star1 badgestarInvisible">
                              <div className="LikeCount badgeShow">
                                <i
                                  className="fa fa-lock"
                                  onClick={() => takeSubcription(item)}
                                ></i>
                              </div>
                              <span className="tooltiptext">
                                {"Subscribe for more Videos"}
                              </span>
                            </div>
                            <div className="my-created-playlist-content-item">
                              <div className="my-created-playlist-video-content">
                                <div>
                                  <p className="ebook-title-text f-poppins">
                                    {item.videoTitle}
                                  </p>
                                  <div className="ebook-textDetails ebook-text-hrs f-poppins">
                                    <p className="ebook-text-hrs">
                                      {item.videoDuration
                                        ?  MinutesToHoursMinutes(item.videoDuration)
                                        : null}{" "}
                                      {item.videoDuration ? "hrs" : null}{" "}
                                    </p>
                                    {/* <img
                                      className="dot"
                                      src={AllImages?.Dot}
                                      alt="dot"
                                      title="dot"
                                    ></img>
                                    <p className="ebook-text-hrs">
                                      {DateTimeAgo(item.createdDate)}
                                    </p> */}
                                  </div>
                                  {/* <p className="ebook-text-hrs f-poppins">22</p> */}
                                </div>
                                <div className="my-created-playlist-like-icon-div-outer">
                                  <div className="my-created-playlist-like-icon-div">
                                    <img
                                      className="my-created-playlist-c-pointer"
                                      src={AllImages?.Timer}
                                      alt="Timer"
                                      title="Timer"
                                    ></img>
                                    <img
                                      className="my-created-playlist-c-pointer"
                                      src={AllImages?.Copy}
                                      alt="Copy"
                                      title="Copy"
                                    ></img>
                                    <img
                                      className="my-created-playlist-c-pointer"
                                      src={AllImages?.Fav}
                                      alt="Favourite"
                                      title="Favourite"
                                    ></img>
                                  </div>
                                  <p
                                    className="download-ebook-read"
                                    onClick={
                                      item?.isAddedToPlaylist
                                        ? null
                                        : () =>
                                            addVideoInPlaylist(item.id, index)
                                    }
                                  >
                                    {item.isAddedToPlaylist ? "Added" : "Add"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })
              : !isLoading && <NoDataAvailable />}
          </div>
        </InfiniteScroll>
      </div>
      {isOpenSortBy && (
        <Dialog
          open={openModalSortBy}
          className="sortBy"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent
            style={{
              overflowY: "hidden",
              overflowX: "hidden",
            }}
          >
            <div className="qu-history-modal-msg-content">
              <p className="qu-history-modal-msg-text">{"Sort by"}</p>
              <span
                className="qu-history-modal-close-button"
                onClick={() => closeModalSortBy()}
              >
                &times;
              </span>
            </div>
            <ul>
              {Object.keys(SortingOptions).map((key) => (
                <li key={key}>
                  <label className="customRadio">
                    {SortingOptions[key] === "A_TO_Z" ||
                    SortingOptions[key] === "Z_TO_A"
                      ? key.replace(/_/g, " ")
                      : capitalizeFirstWord(
                          key.replace(/_/g, " ").toLowerCase()
                        )}
                    <input
                      type="radio"
                      value={SortingOptions[key]}
                      checked={selectedFilterOp === SortingOptions[key]}
                      onChange={handleRadioChange}
                      name="radio"
                    />
                    <span className="checkmark"></span>
                  </label>
                </li>
              ))}
            </ul>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

export default MyCreatePlayListSelectVideo;
