import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { useContext, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useNavigate } from "react-router-dom";
import { RWebShare } from "react-web-share";
import loader from "../../../assets/images/loading_wbg.gif";
import ApiEndPoint from "../../../utils/apiEndPoints";
import {
  AllImages,
  emojiRegex,
  getImageNameFromResolution,
  MinutesToHoursMinutes,
  RemoveDuplicates,
  VideoPlaylistShareUrl,
} from "../../../utils/constants";
import { apiCall } from "../../../utils/httpClient";
import {
  ShowCreatePlayListContext,
  UserloggedContext
} from "../../Context/UserContext";
import "./style.css";
const SortingOptions = {
  NEWLY_ADDED: "NEWLY_ADDED",
  PREVIOUSLY_ADDED: "PREVIOUSLY_ADDED",
  // LAST_MODIFIED_DATE: "LAST_MODIFIED_DATE",
  POPULAR_BHAKTI_PADS: "POPULAR_VIDEOS",
  Sort_A_To_Z: "A_TO_Z",
  Sort_Z_To_A: "Z_TO_A",
};
const CreatedPlaylist = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [BtnLoading, setBtnLoading] = useState(false);
  const [auth, setAuth] = useContext(UserloggedContext);
  const { viewMore } = location.state || {};
  const [showCreatePlayListModel, setShowCreatePlayListModel] = useContext(ShowCreatePlayListContext);
  const [userPlayList, setUserPlayList] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [scrollHashMap, setScrollHashMap] = useState(false);
  const [scrollLast, setScrollLast] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [playlistName, setPlaylistName] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isImageError, setImageError] = useState(false);
  const [file, setFile] = useState(null);
  const [fileShow, setFileShow] = useState(null);
  const [isPreview, setPreview] = useState(false);
  const [playListDeleteId, setPlayListDeleteId] = useState(null);
  const [isOpenSortBy, setIsOpenSortBy] = useState(false);
  const [selectedFilterOp, setSelectedFilterOp] = useState(
    SortingOptions.NEWLY_ADDED
  );
  const [searchText, setSearchText] = useState(null);

  // const inputRef = useRef(null);

  // useEffect(() => {
  //   if (inputRef.current) {
  //     inputRef.current.focus();
  //   }
  // }, [playlistName]);
  
  useEffect(() => {
    window.history.scrollRestoration = "manual";
    fetchData();
    return () => {
      setShowCreatePlayListModel(false);
    };
  }, [selectedFilterOp]);
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchData();
    }
  };
  const fetchData = () => {
    userPlayListFun(1);
  };
  const fetchMoreData = () => {
    userPlayListFun();
  };
  function handleSearch(e) {
    if (!emojiRegex.test(e.target.value)) {
      if (e.target.value) {
        setSearchText(e.target.value);
      } else {
        setSearchText(e.target.value);
      }
    }
  }
  const openModal = (id) => {
    setIsOpen(true);
    setPlayListDeleteId(id);
  };
  const closeModal = () => {
    setIsOpen(false);
    setPlayListDeleteId(null);
  };
  const openCreatedPlayListModal = () => {
    setShowCreatePlayListModel(true);
  };
  const closeCreatedPlayListModal = () => {
    setShowCreatePlayListModel(false);
  };
  async function userPlayListFun(fetchPage) {
    if (auth) {
      try {
        const params = {
          searchValue: searchText,
          sortColumn: selectedFilterOp,
          pageNo: fetchPage ? fetchPage - 1 : pageNo,
          pageSize: 20,
        };
        setIsLoading(true);
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.USERPLAYLIST}`,
          params
        );
        if (data.length > 0) {
          setIsLoading(false);
          // setUserPlayList(fetchPage?data:prev=>[...prev,...data]);
          setUserPlayList(
            RemoveDuplicates(fetchPage ? data : userPlayList.concat(data))
          );
          setPageNo(fetchPage ? fetchPage : pageNo + 1);
          setScrollHashMap(data.length == 20);
          setScrollLast(true);
        } else {
          setIsLoading(false);
          setScrollLast(false);
          setScrollHashMap(false);
          fetchPage && setUserPlayList([]);
          setPageNo(0);
        }
      } catch (err) {
        setIsLoading(false);
        setScrollLast(false);
        setScrollHashMap(false);
      }
    }
    isOpenSortBy && closeModalSortBy();
  }
  const individualUserPlayListNav = (item, type) => {
    navigate("/individualUserPlayList", {
      state: { playListDetails: item, playListType: type },
    });
  };
  const validateInput = (text) => {
    if (/^\s+/.test(text)) {
      return false;
    }
    const emojiRegexs = emojiRegex; // your emoji regex here;
    if (emojiRegexs.test(text)) {
      return false;
    }
    if (text.length > 35) {
      return false;
    }
    return true;
  };
  const handlePlaylistNameChange = (event) => {
    const inputValue = event.target.value;
    if (validateInput(inputValue)) {
      setPlaylistName(inputValue);
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };
  const validateForm = () => {
    if (playlistName == "") {
      return false;
    }
    return true;
  };
  const handleSubmit = async (event) => {
    const valid = validateForm();
    if (auth) {
      if (valid) {
        const params = {
        playListName : playlistName,
        contentType : 'YOUTUBE_TRACK'
        };
        setBtnLoading(true);
        try {
          const { data } = await apiCall(
            "POST",
            `${ApiEndPoint.CREATEUSERPLAYLISTFORAUDIO}`,
            params
          );
          if (data) {
            navigate("/myCreatePlayListVideoAddBtnSuggested", {
              state: { id: data.id, title: data.playListName },
            });
            setRefresh(!refresh);
          } else {
            setRefresh(!refresh);
          }
        } catch (err) {
          setShowCreatePlayListModel(false);
          setRefresh(!refresh);
          setBtnLoading(false);
        }
      } else {
        setIsValid(true);
      }
    } else {
      // setGoogleLoginShow(true)
    }
    // setIsOpen(false);
  };
  const playlistLogo = async (id, params) => {
    try {
      const formData = new FormData();
      const header = {
        "Content-Type": "multipart/form-data",
        accept: "application/json",
      };
      if (file?.name) {
        formData.append("file", file);
        formData.append("playListid", id);
      } else {
      }
      const { data } = await apiCall(
        "POST",
        `${ApiEndPoint.UPLOADPLAYLISTLOGO}/${id}`,
        formData,
        header
      );
      if (data) {
        userPlayListFun();
        setShowCreatePlayListModel(false);
        setRefresh(!refresh);
        setBtnLoading(false);
        setPlaylistName(null);
        setFile(null);
        setFileShow(null);
      } else {
        setRefresh(!refresh);
        setShowCreatePlayListModel(false);
        setBtnLoading(false);
      }
    } catch (err) {
      setRefresh(!refresh);
      setBtnLoading(false);
      setShowCreatePlayListModel(false);
    }
  };
  const userPlayListDelete = async () => {
    try {
      const { data } = await apiCall(
        "DELETE",
        `${ApiEndPoint.DELTEUSERPLAYLIST}/${playListDeleteId}`
      );
      if (data) {
        // userPlayListFun();
        const removeUserPlaylist = userPlayList.filter(
          (x) => x.id != playListDeleteId
        );
        setUserPlayList(removeUserPlaylist);
        closeModal();
        setRefresh(!refresh);
        if (removeUserPlaylist.length <= 1) {
          //  window.location.reload();
          fetchData();
        }
      } else {
        setRefresh(!refresh);
      }
    } catch (err) {
      setRefresh(!refresh);
      console.log("err: ", err);
    }
  };
  const openModalSortBy = () => {
    setIsOpenSortBy(true);
  };
  const closeModalSortBy = () => {
    setIsOpenSortBy(false);
  };
  const handleRadioChange = (event) => {
    setSelectedFilterOp(event.target.value);
  };
  function capitalizeFirstWord(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return (
    <>
      <div className="myCreatedPlaylist createdPlayListForAudio">
        <InfiniteScroll
          dataLength={
            userPlayList &&
            userPlayList?.slice(0, viewMore ? userPlayList.length : 3)?.length
          }
          next={() => fetchMoreData()}
          // hasMore={true}
          hasMore={scrollHashMap}
          loader={
            viewMore && scrollLast
              ? isLoading && (
                  <center>
                    <img
                      src={loader}
                      alt="loading..."
                      style={{ height: 25, width: 25, marginTop: 20 }}
                    />
                  </center>
                )
              : viewMore && (
                  <p className="subtitle fancy">
                    <span>End of results</span>
                  </p>
                )
          }
        >
          {viewMore && (
            <div className="my-created-playlist-title-search-div">
              {/*----- Created Playlist Title Start -----*/}
              <div className="my-created-playlist-header">
                <div className="my-created-playlist-title-mt-20">
                  <span
                    className="my-created-playlist-d-flex"
                  >
                    <img
                      className="my-created-playlist-c-pointer"
                      src={AllImages.YellowArrow}
                      alt="Back Arrow"
                      title="Back Arrow"
                      onClick={() => navigate(-1)}
                    ></img>
                    <p className="my-created-playlist-title">
                      Created Playlist
                    </p>
                  </span>
                </div>
                <div className="my-created-playlist-header-right">
                  {/*----- Search Box Start -----*/}
                  <div className="my-created-playlist-search-wrap-outer">
                    <div className="my-created-playlist-search-wrap">
                      <div className="search-input">
                        <span className="search-icon">
                          {isLoading ? (
                            <img
                              src={loader}
                              alt="loading..."
                              style={{ height: 15, width: 15 }}
                            />
                          ) : (
                            <div className="searchTooltip">
                              <img
                                src={AllImages.Search}
                                alt="Search"
                                title="Search"
                              />
                              <span className="tooltiptext">{"Search"}</span>
                            </div>
                          )}
                          {/* <img
                            src={AllImages.Search}
                            alt="Search"
                            title="Search"
                          /> */}
                        </span>
                        <input
                          className="f-poppins"
                          type="text"
                          placeholder="Search for Playlists"
                          onChange={(e) => handleSearch(e)}
                          onKeyUp={handleKeyPress}
                        />
                      </div>
                      <div className="searchIcon-outer">
                        <img
                          src={AllImages.SearchIcon}
                          alt="Search"
                          title="Search"
                          className="searchIcon"
                        />
                      </div>
                      <div
                        className="filter-icon sortbyTooltip"
                        onClick={() => openModalSortBy()}
                      >
                        <img
                          src={AllImages.SearchToggle}
                          alt="Search"
                          title="Search"
                        />
                        <span className="tooltiptext">{"Sort By"}</span>
                      </div>
                    </div>
                  </div>
                  {/*----- Search Box End -----*/}
                  <div onClick={() => setShowCreatePlayListModel(true)}>
                    <p className="header-create-btn">CREATE</p>
                  </div>
                </div>
              </div>
              {/*----- Created Playlist Title End -----*/}
            </div>
          )}
          {/*----- My Create Playlists  -----*/}
          {userPlayList.length > 0 ? (
            <div className="my-created-playlist-col-3 custom-row">
              {userPlayList &&
                userPlayList
                  ?.slice(0, viewMore ? userPlayList.length : 3)
                  ?.map((item, index) => (
                    <div className="custom-col-4 custom-col">
                      <div
                        key={index}
                        className="my-created-playlist-card-wrap"
                      >
                        <div className="my-created-playlist-inside-wrap">
                          <div
                            className="list-image"
                            onClick={() => {
                              individualUserPlayListNav(item, "user");
                            }}
                          >
                            <img
                              className={`img-banner ${
                                item.thumbnail
                                  ? getImageNameFromResolution(
                                      item?.thumbnail
                                    ) != "maxresdefault" && "image-resolution"
                                  : "image-resolution"
                              }`}
                              src={
                                item.thumbnail
                                  ? item.thumbnail
                                  : AllImages.HomeBanner
                              }
                              key={item.id}
                              alt="Card Image"
                              title="Card Image"
                            />
                            <div className="playIconTooltip">
                              <img
                                className="my-created-playlist-play-icon"
                                src={AllImages?.PlayIcon}
                                alt="Play Icon"
                                title="Play Icon"
                              />
                              <span className="tooltiptext">
                                {"Play Video"}
                              </span>
                            </div>
                          </div>
                          <div className="my-created-playlist-content-item">
                            <div className="my-created-playlist-video-content">
                              <div>
                                <p className="my-created-playlist-title-text f-poppins">
                                  {item.playListName}
                                </p>
                                <div
                                  style={{ gap: "10px" }}
                                  className="general-textDetails general-text-hrs f-poppins"
                                >
                                  <p className="general-text-hrs">
                                    {" "}
                                    {item?.videoDuration
                                      ? MinutesToHoursMinutes(
                                          item?.videoDuration
                                        )
                                      : null}{" "}
                                    {item?.videoDuration ? "hrs" : "0 hrs"}
                                  </p>
                                  <p className="general-text-hrs">•</p>
                                  <p className="general-text-hrs">
                                    {item.noOfVideos}{" "}
                                    {item.noOfVideos ? "videos" : "0 videos"}
                                  </p>
                                </div>
                              </div>
                              <div
                                className="my-created-playlist-like-icon-div d-flex align-center"
                                style={{ justifyContent: "end" }}
                              >
                                {/* <div className="copyTooltip">
                              <img
                                className="my-created-playlist-c-pointer"
                                src={AllImages?.Copy}
                                alt="Copy"
                                title="Copy"
                              ></img>
                              <span className='tooltiptext'>
                                {"Copy"}
                              </span>
                            </div> */}
                                <RWebShare
                                  data={{
                                    url: `${VideoPlaylistShareUrl}/PlayListShared/${item.id}`,
                                    // url: `http://localhost:3000/PlayListShared/${item.id}`,
                                    title: "Playlist Share",
                                  }}
                                  onClick={() => console.log("share")}
                                >
                                  <div className="readTooltip">
                                    <img
                                      className="video-play-mr-10"
                                      src={AllImages?.ShareOutline}
                                      alt="Share"
                                      title="Share"
                                    ></img>
                                    <span className="tooltiptext">
                                      {"Share Playlist"}
                                    </span>
                                  </div>
                                </RWebShare>
                                <div className="deleteTooltip">
                                  <img
                                    className="my-created-playlist-c-pointer"
                                    src={AllImages?.DeleteCard}
                                    onClick={() => openModal(item.id)}
                                    style={{ height: 20, width: 20 }}
                                  ></img>
                                  <span className="tooltiptext">
                                    {"Delete"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          ) : (
            <div className="my-space-empty-card-wrap-outer">
              <div className="my-space-empty-card-wrap">
                <span className="my-space-empty-msg">
                  {"You haven’t created playlist yet"}
                </span>
              </div>
            </div>
          )}
        </InfiniteScroll>
        {/*----- Delete Modal -----*/}
        {isOpen && (
          <Dialog
            open={openModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <div className="my-created-playlist-modal-content">
                <p className="my-created-playlist-modal-msg-text">
                  {"Are you sure you want to Delete Playlist?"}
                </p>
                <span
                  className="my-created-playlist-modal-close-btn"
                  onClick={() => closeModal()}
                >
                  &times;
                </span>
              </div>
            </DialogContent>
            <DialogActions>
              <div className="my-created-playlist-modal-action-content">
                <button
                  className="my-created-playlist-modal-yes-btn"
                  onClick={() => userPlayListDelete()}
                >
                  {"Yes"}
                </button>
                <button
                  className="my-created-playlist-modal-dont-delete-btn"
                  onClick={() => closeModal()}
                >
                  {"DON’T delete"}
                </button>
              </div>
            </DialogActions>
          </Dialog>
        )}
        {/*----- Create a Playlist Modal -----*/}
        {showCreatePlayListModel && (
          <Dialog
            open={showCreatePlayListModel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="createPlaylistModal"
          >
            <DialogContent>
              <div className="my-create-playlist-modal-content">
                <div className="modal-d-flex">
                  <p className="my-created-playlist-modal-msg-text">
                    {"Create a Playlist"}
                  </p>
                  <span
                    className="my-created-playlist-modal-close-btn"
                    onClick={() => closeCreatedPlayListModal()}
                  >
                    &times;
                  </span>
                </div>
                <div>
                  <p className="my-create-playlist-modal-playlist-name">
                    {"Playlist Name"}
                  </p>
                  <input
                    placeholder="Spiritual Playlist"
                    className={`f-poppins my-create-playlist-modal-search-input ${!isValid ? "invalid" : null} `}
                    // className={`f-poppins my-create-playlist-modal-search-input`}
                    type="text"
                    id="playlistName"
                    value={playlistName}
                    onChange={handlePlaylistNameChange}
                    // ref={inputRef} 
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <div className="my-created-playlist-modal-action-content">
                <button
                  className="my-create-playlist-cancel-btn"
                  onClick={() => closeCreatedPlayListModal()}
                >
                  {"Cancel"}
                </button>
                <button
                  className="my-create-playlist-create-btn"
                  onClick={() => handleSubmit()}
                >
                  {BtnLoading ? (
                    <img src={loader} style={{ width: 20, height: 20 }} />
                  ) : (
                    "Create"
                  )}
                </button>
              </div>
            </DialogActions>
          </Dialog>
        )}
        {isOpenSortBy && (
          <Dialog
            open={openModalSortBy}
            className="sortBy"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent
              style={{
                overflowY: "hidden",
                overflowX: "hidden",
              }}
            >
              <div className="qu-history-modal-msg-content">
                <p className="qu-history-modal-msg-text">{"Sort by"}</p>
                <span
                  className="qu-history-modal-close-button"
                  onClick={() => closeModalSortBy()}
                >
                  &times;
                </span>
              </div>
              <ul>
                {Object.keys(SortingOptions).map((key) => (
                  <li key={key}>
                    <label className="customRadio">
                      {SortingOptions[key] === "A_TO_Z" ||
                      SortingOptions[key] === "Z_TO_A"
                        ? key.replace(/_/g, " ")
                        : capitalizeFirstWord(
                            key.replace(/_/g, " ").toLowerCase()
                          )}
                      <input
                        type="radio"
                        value={SortingOptions[key]}
                        checked={selectedFilterOp === SortingOptions[key]}
                        onChange={handleRadioChange}
                        name="radio"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                ))}
              </ul>
            </DialogContent>
          </Dialog>
        )}
      </div>
    </>
  );
};
export default CreatedPlaylist;
