import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useGoogleLoginFlow from "components/common/useGoogleLoginFlow";
import { CurrentTrackIndex, MusicDataContext, MusicPlayerVisibilityContext, MusicTrackContext, MusicTracksContext, PlayingAlbumIdContext, PlayingShuffledAlbumIdContext, PlayNextContext, ShowCreatePlayListContext, userDetailsContext, UserloggedContext } from "components/Context/UserContext";
import { useContext, useEffect, useRef, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { RWebShare } from "react-web-share";
import { Table } from "reactstrap";
import apiEndPoints from "utils/apiEndPoints";
import { apiCall } from "utils/httpClient";
import Google from "../../../assets/images/google.png";
import loader from "../../../assets/images/loading_wbg.gif";
import ApiEndPoint from "../../../utils/apiEndPoints";
import { AllImages, emojiRegex, RemoveDuplicates, VideoPlaylistShareUrl } from "../../../utils/constants";
import NoDataAvailable from "../../common/NoDataFound/NoDataAvailable";
import "./style.css";
import { useDispatch, useSelector } from 'react-redux';
import { addTrack } from "actions/Index";

function NewlyReleasedBhaktiPads() {
  const dispatch = useDispatch();
  const playlistTracks = useSelector(state => state.tempPlaylist.playlistTracks); // Use playlistTracks
  const [auth, setAuth] = useContext(UserloggedContext);
  const [musicData, setMusicData] = useContext(MusicDataContext);
  const { track, setTrack } = useContext(MusicTracksContext);
  const { singleTrack, setSingleTrack } = useContext(MusicTrackContext);
  const { playNextTrack, setPlayNextTrack } = useContext(PlayNextContext);
  const { playingAlbumId, setPlayingAlbumId } = useContext(PlayingAlbumIdContext);
  const { playingShuffledAlbumId, setPlayingShuffledAlbumId } = useContext(PlayingShuffledAlbumIdContext);
  const { currentTrackIndex, setCurrentTrackIndex } = useContext(CurrentTrackIndex);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [searchParams] = useSearchParams();
  const [isMusicPlayerVisible, setIsMusicPlayerVisible] = useContext(MusicPlayerVisibilityContext);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [scrollLast, setScrollLast] = useState(true);
  const [scrollHashMap, setScrollHashMap] = useState(false);
  const [isOpenSortBy, setIsOpenSortBy] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { openRecaptcha, recaptchaFun, onChange } = useGoogleLoginFlow();
  const [isOpen, setIsOpen] = useState(false);
  const [isMoreOpen, setIsMoreOpen] = useState(false);
  const [showCreatePlayListModel, setShowCreatePlayListModel] = useContext(ShowCreatePlayListContext);
  const [userPlayList, setUserPlayList] = useState([]);
  const [unselectedPlaylistArray, setUnselectedPlaylistArray] = useState([]);
  const [currentlyPlaylingVidId, setCurrentlyPlaylingVidId] = useState();
  const [previouslySelectedUserPlaylistArray, setPreviouslySelectedUserPlaylistArray,] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [currentlySelectedUserPlaylistArray, setCurrentlylySelectedUserPlaylistArray,] = useState([]);
  const [playlistName, setPlaylistName] = useState("");
  const [isValidPlaylistName, setIsValidPlaylistName] = useState(false);
  const [isValidNote, setIsValidNote] = useState(false);
  const [createPlaylistBtnLding, setPlaylistBtnLding] = useState(false);
  const [createNoteBtnLding, setNoteBtnLding] = useState(false);
  const [showSaveInfo, setShowSaveInfo] = useState(false);
  const [noteTitle, setNoteTitle] = useState("Shabdarth");
  const [note, setNote] = useState(null);
  const [startTime, setStartTime] = useState("00:00:00");
  const [endTime, setEndTime] = useState(null);
  const [video, setVideo] = useState();
  const [isPopupVisible, setPopupVisible] = useState(null);
  const [trackDetailsForShare, setTrackDetailsForShare] = useState(null);
  const [isMobileView, setIsMobileView] = useState(false);
  const [userDetails, setUserDetails] = useContext(userDetailsContext);
  const fetchMoreUserPlaylistData = () => {
    userPlayListFun();
  };
  const isPlaylistSelected = (playlistId) => {
    return (
      previouslySelectedUserPlaylistArray.some(
        (item) => item?.id == playlistId
      ) ||
      currentlySelectedUserPlaylistArray.some((item) => item?.id === playlistId)
    );
  };
  const SortingOptions = {
    NEWLY_ADDED: "NEWLY_ADDED",
    PREVIOUSLY_ADDED: "PREVIOUSLY_ADDED",
    POPULAR_BHAKTI_PADS: "POPULAR_VIDEOS",
    Sort_A_To_Z: "A_TO_Z",
    Sort_Z_To_A: "Z_TO_A",
  };
  const initialSortOption = params.get("sort") || SortingOptions.NEWLY_ADDED;
  const [selectedFilterOp, setSelectedFilterOp] = useState(initialSortOption);
  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 991);
  };
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchValueFromUrl = params.get("search");
    if (searchValueFromUrl) {
      setSearchText(searchValueFromUrl);
    }
    if (selectedFilterOp) {
      params.set("sort", selectedFilterOp);
      navigate({ search: params.toString() }, { replace: true });
    }

    fetchData(searchValueFromUrl);
  }, [selectedFilterOp]);
  async function handlePopularTracks(fetchPage = 0, searchValue) {
    setIsLoading(true);
    try {
      const body = {
        pageNo: fetchPage ? fetchPage - 1 : pageNo,
        pageSize: 20,
        sortColumn: selectedFilterOp,
        sortDirection: "asc",
        searchValue: searchValue,
      };
      if (auth) {
        const res = await apiCall("POST", `${apiEndPoints.ALLTRACKS}`, body);
        const data = res.data.content;
        if (data.length > 0) {
          setIsLoading(false);
          setMusicData(
            RemoveDuplicates(fetchPage ? data : musicData.concat(data))
          );
          setPageNo(fetchPage ? fetchPage : pageNo + 1);
          setScrollHashMap(data.length == 20);
          setScrollLast(true);
        } else {
          setIsLoading(false);
          fetchPage && setMusicData([]);
          setScrollLast(false);
          setScrollHashMap(false);
          setPageNo(0);
        }
      } else {
        takeSubscription();
      }
    } catch (error) {
      setIsLoading(false);
      setScrollLast(false);
      setScrollHashMap(false);
      console.error("Error fetching audio tracks:", error);
    }
    isOpenSortBy && closeModalSortBy();
  }
  async function infiniteScroll() {
    setIsLoading(true);
    try {
      const body = {
        pageNo: pageNo,
        pageSize: 20,
        sortColumn: selectedFilterOp,
        sortDirection: "asc",
        searchValue: searchText,
      };
      const res = await apiCall("POST", `${apiEndPoints.ALLTRACKS}`, body);
      const data = res.data.content;
      if (data.length > 0) {
        setIsLoading(false);
        setMusicData(musicData.concat(data));
        setPageNo(pageNo + 1);
        setScrollHashMap(true);
        setScrollLast(true);
      } else {
        setIsLoading(false);
        !pageNo && setMusicData([]);
        setScrollLast(false);
        setScrollHashMap(false);
        setPageNo(0);
      }
    } catch (error) {
      setIsLoading(false);
      setScrollLast(false);
      setScrollHashMap(false);
      console.error("Error fetching audio tracks:", error);
    }
    isOpenSortBy && closeModalSortBy();
  }
  const handleSearchInputChange = (e) => {
    if (!emojiRegex.test(e.target.value)) {
      const value = e.target.value;
      // console.log("setSearchText", value);
      setSearchText(value);
    }
  };
  const updateURLParams = (value) => {
    searchParams.set("search", value);
    navigate({ search: searchParams.toString() }, { replace: true });
  };
  const fetchData = (searchValue) => {
    handlePopularTracks(1, searchValue);
  };
  const closeModal = () => {
    setPreviouslySelectedUserPlaylistArray([]);
    setCurrentlylySelectedUserPlaylistArray([]);
    setUnselectedPlaylistArray([]);
    setIsOpen(false);
  };
  const handleTitleClick = (item) => {
    setSingleTrack([])
    setTrack([])
    setPlayNextTrack([])
    setPlayingShuffledAlbumId(null)
    setPlayingAlbumId(null)
    setPlayingAlbumId(null);
    let trackList = [];
    trackList[0] = item;
    setTrack(trackList);
    setIsMusicPlayerVisible(true);
  };
  const removeFromExisting = (playlistId) => {
    if (
      previouslySelectedUserPlaylistArray.some(
        (playlist) => playlist.id === playlistId
      )
    ) {
      const updatedExistingPlaylist =
        previouslySelectedUserPlaylistArray.filter(
          (playlist) => playlist.id !== playlistId
        );
      setPreviouslySelectedUserPlaylistArray(updatedExistingPlaylist);
      setUnselectedPlaylistArray((prev) => {
        return [...prev, { id: playlistId }];
      });
    } else if (
      currentlySelectedUserPlaylistArray.some(
        (playlist) => playlist.id === playlistId
      )
    ) {
      const updateSelectedPlaylistData =
        currentlySelectedUserPlaylistArray.filter(
          (playlist) => playlist.id !== playlistId
        );
      setCurrentlylySelectedUserPlaylistArray(updateSelectedPlaylistData);
    } else {
      return;
    }
  };
  const handleCheckboxChange = (event) => {
    const playlistId = parseInt(event.target.dataset.playlistId);
    const checked = event.target.checked;
    !isPlaylistSelected(playlistId)
      ? setCurrentlylySelectedUserPlaylistArray((prevSelectedPlaylist) => {
        let updatedSelectedPlaylist = [...prevSelectedPlaylist];
        if (checked) {
          if (
            !updatedSelectedPlaylist.some(
              (playlist) => playlist?.id === playlistId
            )
          ) {
            updatedSelectedPlaylist.push({ id: playlistId });
          }
        } else {
          updatedSelectedPlaylist = updatedSelectedPlaylist.filter(
            (playlist) => playlist.id !== playlistId
          );
        }
        return updatedSelectedPlaylist;
      })
      : removeFromExisting(playlistId);
  };
  async function toggleFavoriteTrack(item, index) {
    try {
      const isCurrentlyFavorite = item.isFavourite;
      const method = isCurrentlyFavorite ? "DELETE" : "POST";
      const endpoint = isCurrentlyFavorite
        ? `${apiEndPoints.TRACKSFAVORITEDELETE}/${item.id}`
        : `${apiEndPoints.TRACKSFAVORITE}/${item.id}`;
      musicData[index].isFavourite = !isCurrentlyFavorite;
      const data = await apiCall(method, endpoint);
      setRefresh(!refresh);
      setIsLoading(false);
      const message = isCurrentlyFavorite
        ? "Removed from favorites successfully!"
        : "Added to favorites successfully!";
      // toast.success(message);
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
      toast.error("Something went wrong, try again");
    }
  }
  const handleRadioChange = (event) => {
    setSelectedFilterOp(event.target.value);
  };
  const openModalSortBy = () => {
    setIsOpenSortBy(true);
  };
  const closeModalSortBy = () => {
    setIsOpenSortBy(false);
  };
  function capitalizeFirstWord(str) {
    return str.replace(/\b\w/g, char => char.toUpperCase());
  }
  function takeSubscription() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card">
            <div className="info__padding">
              <h3>{`Please Subscribe to Paid Yearly (12 months) plan to play this Bhakti Pad`}</h3>
            </div>
            {!auth ? (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      onChange({ true: true });
                      onClose();
                    }}
                    variant="contained"
                    className="signBtnHome"
                  >
                    <img
                      style={{ height: "23px", width: "23px" }}
                      className="g-icon"
                      src={Google}
                      alt="img"
                    ></img>
                    Sign-in
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      navigate("/subscriptionPlanList");
                      onClose();
                    }}
                    variant="contained"
                    className="subscriptionBtn"
                  >
                    Subscribe
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        );
      },
    });
  }
  const openModal = (id) => {
    setIsOpen(true);
    setCurrentlyPlaylingVidId(id)
  };
  async function getSelectedPlaylistData() {
    if (auth) {
      try {
        setIsLoading(true);
        const { data } = await apiCall(
          "GET",
          `${ApiEndPoint.GETCHECKEDCHECKBOXFORAUDIO}/${currentlyPlaylingVidId}`
        );
        if (data.length > 0) {
          setIsLoading(false);
          setPreviouslySelectedUserPlaylistArray(data);
        } else {
          setPreviouslySelectedUserPlaylistArray([]);
        }
      } catch (err) {
        setIsLoading(false);
      }
    }
  }
  useEffect(() => {
    if (isOpen) {
      fetchUserPlaylistData();
      getSelectedPlaylistData();
    }
  }, [isOpen]);
  const fetchUserPlaylistData = () => {
    userPlayListFun(1);
  };
  async function userPlayListFun(fetchPage = 0) {
    if (auth) {
      try {
        const params = {
          searchValue: '',
          sortColumn: "A_TO_Z",
          pageNo: fetchPage ? fetchPage - 1 : pageNo,
          pageSize: 8,
          sortDirection: ''
        };
        setIsLoading(true);
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.USERPLAYLISTFORAUDIO}`,
          params
        );
        if (data.length > 0) {
          setIsLoading(false);
          setUserPlayList(fetchPage ? data : prev => [...prev, ...data]);
          setUserPlayList(
            RemoveDuplicates(fetchPage ? data : userPlayList.concat(data))
          );
          setPageNo(fetchPage ? fetchPage : pageNo + 1);
          setScrollHashMap(true);
          setScrollLast(true);
        } else {
          setIsLoading(false);
          setScrollLast(false);
          fetchPage && setUserPlayList([]);
          setPageNo(0);
          setScrollHashMap(false);
        }
      } catch (err) {
        setIsLoading(false);
      }
    }
  }
  const handlePlaylistNameChange = (event) => {
    const inputValue = event.target.value;
    if (validateInput(inputValue)) {
      setPlaylistName(inputValue);
      setIsValidPlaylistName(true);
    } else {
      setIsValidPlaylistName(false);
    }
  };
  const validateForm = () => {
    if (playlistName == "") {
      return false;
    }
    return true;
  };
  const validateNoteForm = () => {
    if (note == "" || noteTitle == "") {
      return false;
    }
    return true;
  };
  const handleSubmit = async (event) => {
    const valid = validateForm();
    if (auth) {
      if (valid) {
        const params = {
          playListName: playlistName,
          contentType: 'YOUTUBE_TRACK'
        };
        setPlaylistBtnLding(true);
        try {
          const { data } = await apiCall(
            "POST",
            `${ApiEndPoint.CREATEUSERPLAYLIST}`,
            params
          );
          if (data) {
            setShowCreatePlayListModel(false);
            setPlaylistName(null);
            setRefresh(!refresh);
            setPlaylistBtnLding(false);
            openModal(currentlyPlaylingVidId);
            fetchUserPlaylistData();
          } else {
            setRefresh(!refresh);
            setPlaylistBtnLding(false);
          }
        } catch (err) {
          setShowCreatePlayListModel(false);
          setRefresh(!refresh);
          setPlaylistBtnLding(false);
        }
      } else {
        setIsValidPlaylistName(true);
      }
    } else {
    }
  };
  const validateInput = (text, isNote = false) => {
    const emojiRegexs = emojiRegex;
    if (/^\s+/.test(text)) {
      return false;
    } else if (emojiRegexs.test(text)) {
      return false;
    } else if (!isNote && text.length > 35) {
      return false;
    } else return true;
  };
  const createPlaylist = () => {
    setIsOpen(false);
    setShowCreatePlayListModel(true);
  };
  const closeCreatedPlayListModal = () => {
    setShowCreatePlayListModel(false);
  };
  async function addVideoAgainstPlaylist() {
    if (auth) {
      if (
        currentlySelectedUserPlaylistArray.length > 0 ||
        unselectedPlaylistArray.length > 0
      ) {
        try {
          const params = {
            videoId: currentlyPlaylingVidId,
            selected: currentlySelectedUserPlaylistArray,
            unSelected: unselectedPlaylistArray,
            contentType: 'YOUTUBE_TRACK'
          };
          setIsLoading(true);
          const { status } = await apiCall(
            "POST",
            `${ApiEndPoint.ADDAUDIOAGAINSTMULTIPLEPLAYLIST}`,
            params
          );
          if (status === 200) {
            setIsLoading(false);
            toast.success("Added successfully");
            closeModal();
          } else {
            setIsLoading(false);
            toast.error("Something went wrong , try again");
          }
        } catch (err) {
          setIsLoading(false);
        }
      }
    }
  }
  const popupRef = useRef(null);
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setTimeout(() => {
        setPopupVisible(null);
      }, 500);
    }
  };
  useEffect(() => {
    if (isPopupVisible !== null) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPopupVisible]);
  const togglePopup = (id) => {
    setIsMoreOpen(true);
    setPopupVisible(isPopupVisible === id ? null : id);
  }
  // const handlePlayNext = (id) => {
  //   if (!isMusicPlayerVisible) {
  //     toast.warn("No Bhakti pad is playing.");
  //     setPopupVisible(null);
  //     return;
  //   }
  //   const trackDetails = musicData?.find(track => track?.id == id);
  //   if (trackDetails) {
  //     const newTrackList = [...track];
  //     newTrackList.splice(currentTrackIndex + 1, 0, trackDetails);
  //     setTrack(newTrackList);
  //   }
  //   setPopupVisible(null);
  //   toast.success("Bhakti Pad will Play Next.");
  // };

  const handlePlayNext = (id) => {
    if (!isMusicPlayerVisible) {
      toast.warn("No Bhakti pad is playing.");
      setPopupVisible(null);
      return;
    }
    const trackDetails = musicData?.find(track => track?.id == id);
    if (trackDetails) {
      let newTrackList = [...track];
      const existingTrackIndex = newTrackList.findIndex(track => track.id === id);
      if (existingTrackIndex !== -1) {
        newTrackList.splice(existingTrackIndex, 1);
      }
      newTrackList.splice(currentTrackIndex + 1, 0, trackDetails);
      setTrack(newTrackList);
      dispatch(addTrack(newTrackList));
      toast.success("Bhakti Pad will Play Next.");
    }
    setPopupVisible(null);
  };


  const handleCopyLink = (id) => {
    const trackDetails = musicData?.find(track => track?.id == id);
    setTrackDetailsForShare(trackDetails?.audioAlbum?.id)
    // setPopupVisible(null);
  };
  const formatDuration = (duration) => {
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  }
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      updateURLParams(searchText);
      fetchData(searchText);
    }
  };
  return (
    <section class={`popular-bhaktiPads-page${isMusicPlayerVisible ? " ytPlayerOpen" : ""}`}>
      <div className="popular-bhaktiPads-page-title-outer">
        <div className="popular-bhaktiPads-page-title">
          <div className="title-wrapper">
            <span className="my-favorites-d-flex"
            >
              <img
                className="my-favorites-c-pointer"
                src={AllImages.YellowArrow}
                alt="Back Arrow"
                title="Back Arrow"
                onClick={() => navigate('/sparsh')}
              ></img>
              <p className="my-favorites-title">{"New Release Bhakti Pads"}</p>
            </span>
          </div>
        </div>
        {/*----- Search Bar Start -----*/}
        <div className="list-of-play-search">
          <div className="p-list-search-wrap">
            <div className="search-input">
              <span className="search-icon">
                {isLoading ? (
                  <img
                    src={loader}
                    alt="loading..."
                    style={{ height: 15, width: 15 }}
                  />
                ) : (
                  <div className="searchTooltip">
                    <img src={AllImages.Search} alt="Search" title="Search" />
                    <span className="tooltiptext">{"Search"}</span>
                  </div>
                )}
              </span>
              <input
                className="f-poppins"
                type="text"
                placeholder="Search for Bhakti Pads"
                value={searchText}
                onChange={handleSearchInputChange}
                onKeyUp={handleKeyPress}
              />
            </div>
            <div className="searchIcon-outer searchTooltip">
              <img
                src={AllImages.SearchIcon}
                alt="Search"
                title="Search"
                className="searchIcon"
              />
              <span className="tooltiptext">{"Search"}</span>
            </div>
            <div
              className="filter-icon sortbyTooltip"
              onClick={() => openModalSortBy()}
            >
              <img src={AllImages.SearchToggle} alt="Search" title="Search" />
              <span className="tooltiptext">{"Sort By"}</span>
            </div>
          </div>
        </div>
        {/*----- Search Bar End -----*/}
      </div>
      <div class="controlsTop">
      </div>
      <InfiniteScroll
        className="mt-15"
        dataLength={musicData.length}
        next={() => infiniteScroll()}
        hasMore={scrollHashMap}
        loader={
          scrollLast ? (
            isLoading && (
              <center>
                <img
                  src={loader}
                  alt="loading..."
                  style={{ height: 25, width: 25, marginTop: 20 }}
                />
              </center>
            )
          ) : (
            <p className="subtitle fancy">
              <span>End of results</span>
            </p>
          )
        }
      >
        {isOpen && (
          <Dialog
            open={openModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            scroll={"paper"}
          >
            <DialogContent
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                maxHeight: "320px",
                margin: 5,
              }}
            >
              <div className="add-my-playlist-modal-content">
                <div className="add-my-playlist-modal-d-flex modal-mb-5">
                  <p className="add-my-playlist-modal-msg-text">
                    {"Add to My Playlists"}
                  </p>
                  <span
                    className="add-my-playlist-modal-close-button"
                    onClick={closeModal}
                  >
                    &times;
                  </span>
                </div>
                <div>
                  {userPlayList &&
                    userPlayList?.map((item, index) => (
                      <div
                        key={item.id}
                        className="add-my-playlist-modal-d-flex modal-mt-15"
                      >
                        <input
                          type="checkbox"
                          id={`checkbox_${item.id}`}
                          name={`checkbox_${item.id}`}
                          data-playlist-id={item.id}
                          checked={isPlaylistSelected(item.id)}
                          onChange={handleCheckboxChange}
                        />
                        <label
                          className="add-my-playlist-checkbox-text"
                          htmlFor={`checkbox_${item.id}`}
                        >
                          {item.playListName}
                        </label>
                      </div>
                    ))}
                  {userPlayList && userPlayList.length > 6 && scrollHashMap && (
                    <p
                      onClick={fetchMoreUserPlaylistData}
                      className="fetch-more-playlist"
                    >
                      MORE+
                    </p>
                  )}
                </div>
                <div className="modal-mt-15" onClick={createPlaylist}>
                  <label className="add-my-playlist-create-playlist">
                    Create Playlist
                  </label>
                </div>
              </div>
              {isLoading && (
                <div className="loader">
                  <img src={loader} alt="loading..." />
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <div className="add-my-playlist-modal-action">
                <button
                  onClick={closeModal}
                  className="add-my-playlist-modal-cancel-btn"
                >
                  {"Cancel"}
                </button>
                <button
                  className="add-my-playlist-modal-add-btn"
                  onClick={addVideoAgainstPlaylist}
                >
                  {"confirm"}
                </button>
              </div>
            </DialogActions>
          </Dialog>
        )}
        <Table responsive striped>
          <thead class="mobHide">
            <tr>
              <th>No.</th>
              <th>Title</th>
              <th class="none">Album</th>
              <th class="none">Duration</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody class="mobHide">
            {musicData.length > 0 ? musicData?.map((item, index) => (
              <tr key={item?.id} class="tableHover">
                <td>
                  <div class="srNo" onClick={() => { item?.url ? handleTitleClick(item) : takeSubscription(item) }} >
                    <p class="no mobHide">{index + 1}</p>
                    <img src={item?.thumbnailUrl} class="bhaktiImg" alt="img" />
                    {item?.url ?
                      <div className="bhaktipadPlayIconTooltip">
                        <img src={AllImages?.PlayIcon} class="trackPlayButton" alt="img" />
                        <div className="bhaktipadPlayIconTooltipInner">
                          <span className="tooltiptext">
                            {"Play Bhakti Pad"}
                          </span>
                        </div>
                      </div>
                      :
                      <div className="star1 badgestarInvisible tableLock">
                        <div className="LikeCount badgeShow">
                          <i
                            className="fa fa-lock"
                            onClick={() => takeSubscription(item)}
                          ></i>
                        </div>
                        <span className="tooltiptext">
                          {"Subscribe to use this feature"}
                        </span>
                      </div>
                    }
                  </div>
                </td>
                <td>
                  <div
                    class="titleSection"
                    onClick={() => { item?.url ? handleTitleClick(item) : takeSubscription(item) }}
                  >
                    <p class="title">{item.title}</p>
                    <p class="subTitle">{item?.audioAlbum?.title}</p>
                  </div>
                </td>
                <td class="none">
                  <p class="album">{item?.audioAlbum?.title}</p>
                </td>
                <td class="none">
                  <p class="duration">
                    {formatDuration(item.duration)}
                  </p>
                </td>
                <td>
                  <div class="actions">
                    <div className="favTooltip">
                      <img
                        onClick={() => 
                          item?.url ?
                          toggleFavoriteTrack(item, index)
                          :  takeSubscription(item)
                        }
                        src={item.isFavourite ? AllImages.Favorite_sel : AllImages?.Fav}
                        alt="IMG"
                      ></img>
                      <span className="tooltiptext">
                        {!item.isFavourite ? "Mark Favorite" : "Unfavorite"}
                      </span>
                    </div>
                    <div className="favTooltip">
                      <img
                        className="video-play-mr-10"
                        class="mobHide"
                        alt="img"
                        src={AllImages?.TablePlus}
                        onClick={() => 
                          item?.url ?
                          openModal(item?.id)
                          :  takeSubscription(item)
                        }
                      ></img>
                      <span className="tooltiptext">
                        {" "}
                        {"Add to My Playlists"}
                      </span>
                    </div>
                    <div key={item.id} className="popupOuter moreTooltip">
                      <img
                        src={AllImages?.More}
                        className="moreImg"
                        alt="img"
                        onClick={() => 
                          item?.url ?
                          togglePopup(item?.id)
                          :  takeSubscription(item)
                        }
                        ref={popupRef}
                      />
                      <div className="moreTooltipInner">
                        <span className="tooltiptext">
                          {"View More"}
                        </span>
                      </div>
                    </div>
                    {isPopupVisible === item.id && (
                      !isMobileView ? (
                        <div className="morePopup"
                          ref={popupRef}
                        >
                          <div className="outer" onClick={() => handlePlayNext(item.id)}>
                            <img src={AllImages?.Next} className="more" alt="img" />
                            <p className="label">Play Next</p>
                          </div>
                          <div className="outer" onClick={() => handleCopyLink(item.id)}>
                            <RWebShare
                              data={{
                                url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                                title: 'Bhakti Pad Share',
                              }}
                              onClick={() => {
                                handleCopyLink(item.id);
                                console.log('share');
                              }}
                            >
                              <div className="copyTooltip">
                                <img src={AllImages?.CopyGray} className="more" id="myCopy" alt="img" />
                                <span className="tooltiptext">Copy</span>
                              </div>
                            </RWebShare>
                            <div>
                              <RWebShare
                                data={{
                                  url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                                  title: 'Bhakti Pad Share',
                                }}
                                onClick={() => {
                                  handleCopyLink(item.id);
                                  console.log('share');
                                }}
                              >
                                <p className="label">Copy Link</p>
                              </RWebShare>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="mobileViewDialogOuter" ref={popupRef}>
                          <Dialog
                            open={togglePopup}
                            className="sortBy mobileViewDialog"
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            ref={popupRef}
                          >
                            <DialogContent
                              style={{
                                overflowY: "hidden",
                                overflowX: "hidden",
                              }}
                              ref={popupRef}
                            >
                              <div className="qu-history-modal-msg-content" ref={popupRef}>
                                <p className="qu-history-modal-msg-text">{"More Option"}</p>
                                <span
                                  className="qu-history-modal-close-button"
                                  onClick={togglePopup}
                                >
                                  &times;
                                </span>
                              </div>
                              <div class="actionPopupMob" ref={popupRef}>
                                <div class="outer tabHide"
                                  ref={popupRef}
                                  onClick={() => toggleFavoriteTrack(item, index)}>
                                  <img
                                    ref={popupRef}
                                    src={item.isFavourite ? AllImages.Favorite_sel : AllImages?.Fav}
                                    alt="IMG"
                                  ></img>
                                  <p class="label">Add to Favourites</p>
                                </div>
                                <div class="outer tabHide" ref={popupRef} onClick={() => openModal(item?.id)}>
                                  <img
                                    className="plus"
                                    alt="img"
                                    src={AllImages?.TablePlus}
                                  />
                                  <p class="label">Add to Playlist</p>
                                </div>
                                <div className="outer" ref={popupRef} onClick={() => handlePlayNext(item.id)}>
                                  <img src={AllImages?.Next} className="more" alt="img" />
                                  <p className="label">Play Next</p>
                                </div>
                                <div className="outer" onClick={() => handleCopyLink(item.id)}>
                                  <RWebShare
                                    data={{
                                      url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                                      title: 'Bhakti Pad Share',
                                    }}
                                    onClick={() => {
                                      handleCopyLink(item.id);
                                      console.log('share');
                                    }}
                                  >
                                    <div className="copyTooltip">
                                      <img src={AllImages?.CopyGray} className="more" id="myCopy" alt="img" />
                                      <span className="tooltiptext">Copy</span>
                                    </div>
                                  </RWebShare>
                                  <div>
                                    <RWebShare
                                      data={{
                                        url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                                        title: 'Bhakti Pad Share',
                                      }}
                                      onClick={() => {
                                        handleCopyLink(item.id);
                                        console.log('share');
                                      }}
                                    >
                                      <p className="label">Copy Link</p>
                                    </RWebShare>
                                  </div>
                                </div>
                              </div>
                            </DialogContent>
                          </Dialog>
                        </div>
                      )
                    )}
                    {/* three dots END */}
                  </div>
                </td>
              </tr>
            ))
              :
              <tr>
                <td colspan="5" style={{ textAlign: 'center' }}>
                  {isLoading ? (
                    <img
                      src={loader}
                      alt="loading..."
                      style={{ height: 35, width: 35, marginTop: 20 }}
                    />
                  ) : (
                    <NoDataAvailable />
                  )}
                </td>
              </tr>
            }
          </tbody>
        </Table>
      </InfiniteScroll>
      {isOpenSortBy && (
        <Dialog
          open={openModalSortBy}
          className="sortBy"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent
            style={{
              overflowY: "hidden",
              overflowX: "hidden",
            }}
          >
            <div className="qu-history-modal-msg-content">
              <p className="qu-history-modal-msg-text">{"Sort by"}</p>
              <span
                className="qu-history-modal-close-button"
                onClick={() => closeModalSortBy()}
              >
                &times;
              </span>
            </div>
            <ul>
              {Object?.keys(SortingOptions).map((key) => (
                <li key={key}>
                  <label className="customRadio">
                    {SortingOptions[key] === "A_TO_Z" ||
                      SortingOptions[key] === "Z_TO_A"
                      ? key.replace(/_/g, " ")
                      : capitalizeFirstWord(
                        key.replace(/_/g, " ").toLowerCase()
                      )}
                    <input
                      type="radio"
                      value={SortingOptions[key]}
                      checked={selectedFilterOp === SortingOptions[key]}
                      onChange={handleRadioChange}
                      name="radio"
                    />
                    <span className="checkmark"></span>
                  </label>
                </li>
              ))}
            </ul>
          </DialogContent>
        </Dialog>
      )}
      {showCreatePlayListModel && (
        <Dialog
          open={showCreatePlayListModel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="createPlaylistModal"
        >
          <DialogContent>
            <div className="my-create-playlist-modal-content">
              <div className="modal-d-flex">
                <p className="my-created-playlist-modal-msg-text">
                  {"Create a Playlist"}
                </p>
                <span
                  className="my-created-playlist-modal-close-btn"
                  onClick={() => closeCreatedPlayListModal()}
                >
                  &times;
                </span>
              </div>
              <div>
                <p className="my-create-playlist-modal-playlist-name">
                  {"Playlist Name"}
                </p>
                <input
                  placeholder="Spiritual Playlist"
                  className={`f-poppins my-create-playlist-modal-search-input ${isValidPlaylistName ? "invalid" : null
                    } `}
                  type="text"
                  id="playlistName"
                  value={playlistName}
                  onChange={handlePlaylistNameChange}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="my-created-playlist-modal-action-content">
              <button
                className="my-create-playlist-cancel-btn"
                onClick={() => closeCreatedPlayListModal()}
              >
                {"Cancel"}
              </button>
              <button
                className="my-create-playlist-create-btn"
                onClick={() => handleSubmit()}
              >
                {createPlaylistBtnLding ? (
                  <img src={loader} style={{ width: 20, height: 20 }} />
                ) : (
                  "Create"
                )}
              </button>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </section>
  );
}
export default NewlyReleasedBhaktiPads;
