import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import loader from "../../../assets/images/logo.png";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Button from "@material-ui/core/Button";
import {
  UserloggedContext,
  GoogleLoginShowContext,
} from "../../Context/UserContext";
import ApiEndPoint from "../../../utils/apiEndPoints";
import { AuthContext } from "../../Context/context";
import { setDefaultHeader, apiCall } from "../../../utils/httpClient";
import {
  AllImages,
  GLOBAL_URL,
  VideoPlaylistShareUrl,
  RemoveDuplicates,
  emojiRegex,
  getImageNameFromResolution,
  googleClientId,
} from "../../../utils/constants";
import { RWebShare } from "react-web-share";
import "./style.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import NoDataAvailable from "../../common/NoDataFound/NoDataAvailable";
import { toast } from "react-toastify";
import {
  GoogleLogin,
  useGoogleLogout,
  useGoogleLogin,
} from "react-google-login";
import { gapi } from "gapi-script";
import ReCAPTCHA from "react-google-recaptcha";
import useGoogleLoginFlow from "components/common/useGoogleLoginFlow";
import Google from "../../../assets/images/google.png";
const clientId = googleClientId;
const TEST_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";

const SortingOptions = {
  NEWLY_ADDED: "NEWLY_ADDED",
  PREVIOUSLY_ADDED: "PREVIOUSLY_ADDED",
  POPULAR_VIDEOS: "POPULAR_VIDEOS",
  NEWLY_PUBLISHED: "NEWLY_PUBLISHED",
  PREVIOUSLY_PUBLISHED: "PREVIOUSLY_PUBLISHED",
  // LAST_MODIFIED_DATE: "LAST_MODIFIED_DATE",
  Sort_A_To_Z: "A_TO_Z",
  Sort_Z_To_A: "Z_TO_A",
};

const IndividualUserPlayList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [auth, setAuth] = useContext(UserloggedContext);
  const { signInFun, signOutFun } = React.useContext(AuthContext);

  const [googleLoginShow, setGoogleLoginShow] = useContext(
    GoogleLoginShowContext
  );
  const location = useLocation();
  const { pathname } = location;
  const path = pathname.slice(1);
  const navigate = useNavigate();
  const { playListDetails } = location.state || {};
  const [pageNo, setPageNo] = useState(0);
  const [playListVideo, setPlayListVideo] = useState([]);
  const [playListEbookPad, setPlayLisEbookPad] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [scrollHashMap, setScrollHashMap] = useState(true);
  const [scrollLast, setScrollLast] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilterOp, setSelectedFilterOp] = useState(
    SortingOptions.NEWLY_ADDED
  );
  const {
    openRecaptcha,

    recaptchaFun,

    onChange,
  } = useGoogleLoginFlow();
  const [isOpenSortBy, setIsOpenSortBy] = useState(false);
  const [googleLogin, setGoogleLogin] = useState(false);

  // const [openRecaptcha, setOpenRecaptcha] = useState(false)

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    window.history.scrollRestoration = "manual";
    fetchData();
    fetchBooksAudio();
  }, [selectedFilterOp]);

  useEffect(() => {
    fetchBooksAudio();
  }, []);

  const fetchData = () => {
    setScrollHashMap(true);
    fetchPlayListVideos(1);
  };
  const fetchMoreData = () => {
    fetchPlayListVideos();
  };
  // console.log(playListDetails);
  async function fetchPlayListVideos(fetchPage = 0) {

    const requestUrl =
      path === "sharedIndividualPlayList"
        ? ApiEndPoint.SHAREDPLAYLISTCONTENTS
        : ApiEndPoint.PLAYLISTVIDEOLOGIN;
    try {
      const params = {
        searchValue: searchText,
        playListId: playListDetails.id,
        contentType: "VIDEO", // VIDEO, PAD,PATRA
        pageNo: fetchPage ? fetchPage - 1 : pageNo,
        pageSize: 20,
        sortColumn: selectedFilterOp,
      };

      if (auth) {
        setIsLoading(true);
        const { data } = await apiCall("POST", `${requestUrl}`, params);
        if (data.length > 0) {
          setRefresh(!refresh);
          setIsLoading(false);
          setPlayListVideo(
            RemoveDuplicates(fetchPage ? data : playListVideo.concat(data))
          );
          setPageNo(fetchPage ? fetchPage : pageNo + 1);
          setScrollHashMap(data.length == 20);
          setScrollLast(true);
        } else {
          setIsLoading(false);
          fetchPage && setPlayListVideo([]);
          setScrollHashMap(false);
          setPageNo(0);
          setScrollLast(false);
          setRefresh(!refresh);
        }
      } else {
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.PLAYLISTVIDEONONLOGIN}`,
          params
        );
        if (data.length > 0) {
          setIsLoading(false);
          setPageNo(fetchPage ? fetchPage : pageNo + 1);
          setScrollHashMap(data.length == 20);
          setScrollLast(true);

          setPlayListVideo(
            RemoveDuplicates(fetchPage ? data : playListVideo.concat(data))
          );
          // setPlayListVideo(fetchPage ? data : (prev) => [...prev, ...data]);

          setRefresh(!refresh);
        } else {
          setIsLoading(false);
          fetchPage && setPlayListVideo([]);
          setPageNo(0);

          setScrollHashMap(false);
          setScrollLast(false);
          setRefresh(!refresh);
        }
      }
    } catch (err) {
      setIsLoading(false);
      setScrollLast(false);
      setScrollHashMap(false);
      console.log("err: ", err);
    }
    isOpenSortBy && closeModalSortBy();
  }

  async function fetchBooksAudio() {
    setIsLoading(true);
    try {
      const params = {
        searchValue: null,
        playListId: playListDetails.id,
        contentType: null, // VIDEO, PAD,PATRA
        pageNo: 0,
        pageSize: 10,
      };

      if (auth) {
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.PLAYLISTVIDEOLOGIN}`,
          params
        );
        if (data.length > 0) {
          setPlayLisEbookPad(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else {
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.PLAYLISTVIDEONONLOGIN}`,
          params
        );
        if (data.length > 0) {
          setPlayLisEbookPad(data);
          setRefresh(!refresh);
        } else {
          setIsLoading(false);
        }
      }
    } catch (err) {
      setIsLoading(false);
    }
  }
  const handleVideoClick = (item) => {
    navigate(`/videoPlayPage/${item.id}`);
  };

  const handlePlayListPlay = () => {
    if (playListVideo?.length > 0) {
      navigate(`/playListPlayPage/${playListDetails.id}`, {
        state: {
          documentData: playListEbookPad,
          type: path === "sharedIndividualPlayList" ? "shared" : "admin",
        },
      });
    }
  };

  const addFavorite = async (item, index) => {
    if (auth) {
      playListVideo[index].myFavourite = item.myFavourite ? false : true;
      try {
        const { data } = await apiCall("POST", `/my-favourites/${item.id}`);
        if (data) {
          setRefresh(!refresh);
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
      }
    } else {
      // setGoogleLoginShow(true)
    }
  };

  const videoWatchLater = async (item, index) => {
    if (auth) {
      playListVideo[index].watchLater = item.watchLater ? false : true;
      try {
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.VIDEOWATCHLATER}/${item.id}`
        );
        if (data) {
          setRefresh(!refresh);
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
      }
    } else {
      // setGoogleLoginShow(true)
    }
  };

  const RemoveWatchLaterVideo = async (item, index) => {
    if (auth) {
      playListVideo[index].watchLater = item.watchLater ? false : true;
      try {
        const { data } = await apiCall(
          "DELETE",
          `${ApiEndPoint.WATCHLATEDREMOVE}/${item.id}`
        );
        if (data) {
          setRefresh(!refresh);
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
      }
    } else {
      // setGoogleLoginShow(true)
    }
  };

  //remove playlist from favourite
  async function myFavouritePlaylistRemove(item) {
    try {
      if (auth) {
        playListDetails.favourite = item.favourite ? false : true;
        const { data } = await apiCall(
          "DELETE",
          `${ApiEndPoint.MYFAVOURITEPLAYLISTREMOVE}/${item.id}`
        );
        if (data.length > 0) {
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }

  //playlist favourite
  async function myFavouritePlaylist(item) {
    try {
      if (auth) {
        playListDetails.favourite = item.favourite ? false : true;
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.MYFAVOURITEPLAYLIST}/${item.id}`
        );
        if (data.length > 0) {
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }

  function takeSubcription(params) {
    params?.subscriptionName
      ? confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="card">
                <div className="info__padding">
                  <h3>
                    {`Please Subscribe to ${params?.subscriptionName} plan to play this video. `}
                  </h3>
                  <h3>{!auth ? "Sign In To Subscribe..." : ""}</h3>
                </div>
                {!auth ? (
                  <>
                    <div className="button__group">
                      <Button
                        onClick={() => {
                          onChange({ true: true });
                          onClose();
                        }}
                        variant="contained"
                        className="signBtnHome"
                      >
                        <img
                          style={{ height: "23px", width: "23px" }}
                          className="g-icon"
                          src={Google}
                        ></img>
                        Sign-in
                      </Button>
                      <button className="no-btn" onClick={onClose}>
                        Cancel
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="button__group">
                      <Button
                        onClick={() => {
                          navigate("/subscriptionPlanList");
                          onClose();
                        }}
                        variant="contained"
                        className="subscriptionBtn"
                        // className="signBtnHome"
                      >
                        {/* <img className="g-icon" src={Google}></img> */}
                        Subscribe
                      </Button>
                      <button className="no-btn" onClick={onClose}>
                        Cancel
                      </button>
                    </div>
                  </>
                )}
              </div>
            );
          },
        })
      : confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="card">
                <div className="info__padding">
                  <h3>{`This Video is not yet available`}</h3>
                </div>
                <div className="button__group">
                  <button className="button btn-ok" onClick={onClose}>
                    Ok
                  </button>
                </div>
              </div>
            );
          },
        });
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchData();
    }
  };
  function playListVideoSearchHandle(e) {
    if (!emojiRegex.test(e.target.value)) {
      if (e.target.value) {
        setSearchText(e.target.value);
      } else {
        setSearchText(null);
      }
    }
  }

  const openModalSortBy = () => {
    setIsOpenSortBy(true);
  };

  const closeModalSortBy = () => {
    setIsOpenSortBy(false);
  };

  const contentTypeMappings = {
    EBOOK: "E-Book",
    AUDIO: "Audio",
    PAD: "Pad",
    PATRA: "Patra",
    TRANSCRIPT: "Transcript",
    // Add more mappings if needed
  };

  function getFileExtension(url) {
    const parts = url.split(".");
    return parts[parts.length - 1];
  }
  const [fileDownloadLoader, setFileDownloadLoader] = useState(null);
  const handleDownloadClick = async (contentUrl, index) => {
    if (auth) {
      try {
        setFileDownloadLoader(index);
        let fileUrl = "";
        let fileExtension = getFileExtension(contentUrl);

        switch (fileExtension) {
          case "epub":
          case "pdf":
          case "mp3":
            fileUrl = contentUrl;
            break;
          default:
            console.error("Invalid file type:", fileExtension);
            return;
        }

        const response = await fetch(fileUrl);
        if (!response.ok) {
          setFileDownloadLoader(null);
          throw new Error(`Failed to download file (HTTP ${response.status})`);
        }
        let fileName = contentUrl?.substring(contentUrl.lastIndexOf("/") + 1);

        fileName = fileName?.replace(
          /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/,
          ""
        );

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        setFileDownloadLoader(null);
        // Display a success toast message
        toast.success("File downloaded successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } catch (error) {
        console.error("Error downloading the file:", error);

        // Display an error toast message
        toast.error("Error downloading the file. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      closeModal();
      takeOtherSubcription();
    }
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleReadOrPlayClick = (contentUrl, contentType) => {
    const newTabUrl = `/fileViewer?contentUrl=${encodeURIComponent(
      contentUrl
    )}`;
    window.open(newTabUrl, "_blank");
  };

  const handleRadioChange = (event) => {
    setSelectedFilterOp(event.target.value);
  };

  function capitalizeFirstWord(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  function openLinkInNewTab(url) {
    window.open(url, "_blank");
  }
  function takeOtherSubcription() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card">
            <div className="info__padding">
              <h3>{`Please sign in to use this feature. `}</h3>
              {/* <h3>{!auth ? "Sign In" : ""}</h3> */}
            </div>
            {!auth ? (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      onChange({ true: true });
                      onClose();
                    }}
                    variant="contained"
                    className="signBtnHome"
                  >
                    <img
                      style={{ height: "23px", width: "23px" }}
                      className="g-icon"
                      src={Google}
                    ></img>
                    Sign-in
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      navigate("/subscriptionPlanList");
                      onClose();
                    }}
                    variant="contained"
                    className="subscriptionBtn"
                    // className="signBtnHome"
                  >
                    {/* <img className="g-icon" src={Google}></img> */}
                    Subscribe
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        );
      },
    });
  }

  return (
    <>
      <div className="individualPlaylist">
        <InfiniteScroll
          dataLength={playListVideo?.length}
          next={() => fetchMoreData()}
          hasMore={scrollHashMap}
          loader={
            scrollLast ? (
              isLoading && (
                <center>
                  <img
                    src={loader}
                    alt="loading..."
                    style={{ height: 25, width: 25, marginTop: 20 }}
                  />
                </center>
              )
            ) : (
              <p className="subtitle fancy">
                <span>End of results</span>
              </p>
            )
          }
        >
          {/*----- Individual Top Banner Start -----*/}
          {path === "sharedIndividualPlayList" ? (
            <div className="ind-list-main-banner ind-list-top-banner">
              <img
                className="ind-list-top-img-banner ind-list-b-radius-27"
                src={
                  playListDetails.thumbnail
                    ? playListDetails.thumbnail
                    : AllImages.HomeBanner
                }
                alt="Banner"
                title="Banner"
              />
              <div className="ind-list-top-header">
                <span onClick={() => navigate(-1)}>
                  {" "}
                  <img
                    src={AllImages.YellowArrow}
                    alt="Back Arrow"
                    title="Back Arrow"
                  ></img>
                </span>
                <span className="favTooltip">
                  {/* <img
           onClick={() =>
             playListDetails.favourite
               ? myFavouritePlaylistRemove(playListDetails)
               : myFavouritePlaylist(playListDetails)
           }
           src={
             playListDetails.favourite
               ? AllImages.Favorite_sel
               : AllImages?.Fav
           }
           alt="Favourite"
           title="Favourite"
         ></img>
         <span className='tooltiptext'>
           {!playListDetails.favourite
             ? "Mark Favorite"
             : "Unfavorite"}
         </span> */}

                  {/* <img
           className="ind-list-pl-15"
           src={AllImages.CopyWhiteIcon}
           alt="Copy"
           title="Copy"
         ></img> */}
                </span>
              </div>
              <div className="ind-list-banner-content">
                <p className="ind-list-banner-title f-poppins">
                  {playListDetails?.playListName}
                </p>
                {/* <p className="ind-list-banner-text f-poppins">
         <span className="individual-truncate">
           {isExpanded
             ? playListDetails?.description
             : `${playListDetails?.description?.slice(0, 50)}...`}
           <a className="read-more" onClick={() => toggleExpand()}>
             Read {isExpanded ? "less" : "more"}
           </a>
         </span>
       </p> */}
              </div>
              {/* <div className="ind-list-get-referal f-poppins">
       Get{" "}
       <span
         className="ind-list-yellow-text"
         onClick={() => openModal()}
       >
         Reference Material
       </span>
     </div> */}
              <div className="playIconTooltip">
                <img
                  onClick={() => handlePlayListPlay()}
                  className="ind-list-play-icon-img"
                  src={AllImages.PlayIconBig}
                  alt="Play Icon"
                  title="Play Icon"
                />
                <span className="tooltiptext">{"Play Video"}</span>
              </div>
            </div>
          ) : (
            <div className="ind-list-main-banner ind-list-top-banner">
              <img
                className="ind-list-top-img-banner ind-list-b-radius-27"
                src={`${GLOBAL_URL}/system/playlist/thumbnail/${playListDetails.id}`}
                alt="Banner"
                title="Banner"
              />
              <div className="ind-list-top-header">
                <span onClick={() => navigate(-1)}>
                  {" "}
                  <img
                    src={AllImages.YellowArrow}
                    alt="Back Arrow"
                    title="Back Arrow"
                  ></img>
                </span>
                <span className="favTooltip">
                  <img
                    onClick={() =>
                      playListDetails.favourite
                        ? myFavouritePlaylistRemove(playListDetails)
                        : myFavouritePlaylist(playListDetails)
                    }
                    src={
                      playListDetails.favourite
                        ? AllImages.Favorite_sel
                        : AllImages?.Fav
                    }
                    alt="Favourite"
                    title="Favourite"
                  ></img>
                  <span className="tooltiptext">
                    {!playListDetails.favourite
                      ? "Mark Favorite"
                      : "Unfavorite"}
                  </span>

                  {/* <img
                  className="ind-list-pl-15"
                  src={AllImages.CopyWhiteIcon}
                  alt="Copy"
                  title="Copy"
                ></img> */}
                </span>
              </div>
              <div className="ind-list-banner-content">
                <p className="ind-list-banner-title f-poppins">
                  {playListDetails.playlistName}
                </p>
                <p className="ind-list-banner-text f-poppins">
                  <span className="individual-truncate">
                    {isExpanded
                      ? playListDetails?.description
                      : `${playListDetails?.description?.slice(0, 50)}...`}
                    <a className="read-more" onClick={() => toggleExpand()}>
                      Read {isExpanded ? "less" : "more"}
                    </a>
                  </span>
                </p>
              </div>
              <div className="ind-list-get-referal f-poppins">
                Get{" "}
                <span
                  className="ind-list-yellow-text"
                  onClick={() => openModal()}
                >
                  Reference Material
                </span>
              </div>
              <div className="playIconTooltip">
                <img
                  onClick={() => handlePlayListPlay()}
                  className="ind-list-play-icon-img"
                  src={AllImages.PlayIconBig}
                  alt="Play Icon"
                  title="Play Icon"
                />
                <span className="tooltiptext">{"Play Video"}</span>
              </div>
            </div>
          )}
          {/*----- Individual Top Banner End -----*/}

          {/*----- Search Bar Start -----*/}
          <div className="ind-list-play-outer-wrap">
            <div className="search-wrap">
              <div className="search-input">
                <span className="search-icon" onClick={() => fetchData()}>
                  {isLoading ? (
                    <img
                      src={loader}
                      alt="loading..."
                      style={{ height: 15, width: 15 }}
                    />
                  ) : (
                    <div className="searchTooltip">
                      <img src={AllImages.Search} alt="Search" title="Search" />
                      <span className="tooltiptext">{"Search"}</span>
                    </div>
                  )}
                </span>
                <input
                  className="f-poppins"
                  type="text"
                  placeholder="Search for Video"
                  onChange={(e) => playListVideoSearchHandle(e)}
                  onKeyUp={handleKeyPress}
                />
              </div>
              <div className="searchIcon-outer">
                <img
                  src={AllImages.SearchIcon}
                  alt="Search"
                  title="Search"
                  className="searchIcon"
                />
              </div>
              <div
                className="filter-icon sortbyTooltip"
                onClick={() => openModalSortBy()}
              >
                <img src={AllImages.SearchToggle} alt="Search" title="Search" />
                <span className="tooltiptext">{"Sort By"}</span>
              </div>
            </div>
            {/*----- Search Bar End -----*/}

            {/*----- Individual Play List Card Start -----*/}
            <div className="ind-card-column-count-3 custom-row">
              {playListVideo.length > 0
                ? playListVideo?.map((item, index) => (
                    <div className="custom-col-4 custom-col">
                      <div className="ind-card-play-card-wrap" key={index}>
                        <div
                          className="ind-card-play-inside-wrap"
                          style={{ position: "relative" }}
                        >
                          {item.videoUrl ? (
                            <div
                              className="ind-card-play-list-image"
                              onClick={() => handleVideoClick(item)}
                            >
                              <img
                                className="ind-card-img-banner"
                                src={
                                  item?.thumbnails
                                    ? `${item?.thumbnails}`
                                    : `${AllImages.DefaultThumbnail}`
                                }
                                key={item.id}
                                alt="Card Image"
                                title="Card Image"
                              />
                              <div className="playIconTooltip">
                                <img
                                  className="ind-card-play-play-icon"
                                  src={AllImages.PlayIcon}
                                  alt="Play Icon"
                                  title="Play Icon"
                                />
                                <span className="tooltiptext">
                                  {"Play Video"}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div
                              onClick={() => takeSubcription(item)}
                              className="ind-card-play-list-image"
                              style={{ position: "static" }}
                            >
                              <div>
                                <img
                                  className={`ind-card-img-banner ${
                                    getImageNameFromResolution(
                                      item.thumbnails
                                    ) != "maxresdefault" && "image-resolution"
                                  }`}
                                  src={
                                    item?.thumbnails
                                      ? `${item?.thumbnails}`
                                      : `${AllImages.DefaultThumbnail}`
                                  }
                                  alt="Card Image"
                                  title="Card Image"
                                />
                                <div className="layerImage"></div>
                              </div>
                              <div className="star1 badgestarInvisible">
                                <div className="LikeCount badgeShow">
                                  <i
                                    className="fa fa-lock"
                                    onClick={() => takeSubcription(item)}
                                  ></i>
                                </div>
                                <span className="tooltiptext">
                                  {"Subscribe for more Videos"}
                                </span>
                              </div>
                            </div>
                          )}
                          <div className="ind-card-play-card-content-item">
                            <div className="ind-card-play-video-content">
                              <div>
                                <p className="ind-card-play-title-text f-poppins">
                                  {item.videoTitle}
                                </p>
                                <p className="ind-card-play-text-hrs f-poppins">
                                  {item?.videoDuration}{" "}
                                  {item?.videoDuration ? "hrs" : null}{" "}
                                </p>
                              </div>
                              <div className="ind-card-mt-10 d-flex align-center">
                                <div className="timerTooltip">
                                  <img
                                    onClick={() =>
                                      item?.watchLater
                                        ? RemoveWatchLaterVideo(item, index)
                                        : videoWatchLater(item, index)
                                    }
                                    src={
                                      item?.watchLater
                                        ? AllImages.SelectedTimer
                                        : AllImages.Timer
                                    }
                                    alt="Timer"
                                    title="Timer"
                                  ></img>
                                  <span className="tooltiptext">
                                    {!item.watchLater
                                      ? "Watch Later"
                                      : "Remove Watch Later"}
                                  </span>
                                </div>
                                <div className="favTooltip">
                                  <img
                                    src={
                                      item?.myFavourite
                                        ? AllImages.Favorite_sel
                                        : AllImages.Fav
                                    }
                                    onClick={() => addFavorite(item, index)}
                                    alt="Favourite"
                                    title="Favourite"
                                  ></img>
                                  <span className="tooltiptext">
                                    {!item.myFavourite
                                      ? "Mark Favorite"
                                      : "Unfavorite"}
                                  </span>
                                </div>
                                <RWebShare
                                  data={{
                                    // text: "Like humans, flamingos make friends for life",
                                    url: `${VideoPlaylistShareUrl}/videoPlayPage/${item.id}`,
                                    title: "Video Share",
                                  }}
                                  onClick={() => console.log("share")}
                                >
                                  <div className="copyTooltip">
                                    <img
                                      src={AllImages.Copy}
                                      alt="Copy"
                                      title="Copy"
                                    ></img>
                                    <span className="tooltiptext">
                                      {"Copy"}
                                    </span>
                                  </div>
                                </RWebShare>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : !isLoading && <NoDataAvailable />}
            </div>
            {/*----- Individual Play List Card End -----*/}
          </div>
        </InfiniteScroll>

        {/*----- Reference Material -----*/}
        {isOpen && (
          <Dialog
            open={openModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              {/* <div>
                <div className="ref-material-modal-content">
                  <p className="ref-material-modal-msg-text">{"Reference Material"}</p>
                  <span className="ref-material-modal-close-button" onClick={() => closeModal()}>&times;</span>
                </div>

                <div className="ref-material-position-rel">
                  <img className="ref-material-img" src={AllImages?.ReferenceMaterial}></img>
                  <div class="ref-material-top-left">
                    <p className="ref-material-left-title">{"Patra"}</p>
                    <p className="ref-material-left-title-type">{"(PDF)"}</p>
                  </div>
                  <div class="ref-material-top-right">
                    <img className="ref-material-yellow-download-img" src={AllImages?.YellowDownload}></img>
                    <p className="ref-material-right-title-read">{"READ"}</p>
                  </div>
                </div>

                <div className="ref-material-position-rel">
                  <img className="ref-material-img" src={AllImages?.ReferenceMaterial}></img>
                  <div class="ref-material-top-left">
                    <p className="ref-material-left-title">{"Pad"}</p>
                    <p className="ref-material-left-title-type">{"(Audio)"}</p>
                  </div>
                  <div class="ref-material-top-right">
                    <img className="ref-material-yellow-download-img" src={AllImages?.YellowDownload}></img>
                    <p className="ref-material-right-title-read">{"PLAY"}</p>
                  </div>
                </div>

                <div className="ref-material-position-rel">
                  <img className="ref-material-img" src={AllImages?.ReferenceMaterial}></img>
                  <div class="ref-material-top-left">
                    <p className="ref-material-left-title">{"E-Book"}</p>
                    <p className="ref-material-left-title-type">{"(PDF)"}</p>
                  </div>
                  <div class="ref-material-top-right">
                    <img className="ref-material-yellow-download-img" src={AllImages?.YellowDownload}></img>
                    <p className="ref-material-right-title-read">{"READ"}</p>
                  </div>
                </div>
              </div> */}
              <div>
                <div className="ref-material-modal-content">
                  <p className="ref-material-modal-msg-text">
                    {"Reference Material"}
                  </p>
                  <span
                    className="ref-material-modal-close-button"
                    onClick={() => closeModal()}
                  >
                    &times;
                  </span>
                </div>
                {playListEbookPad.map((content, index) => (
                  <div key={index} className="ref-material-position-rel">
                    <img
                      className="ref-material-img"
                      src={AllImages?.ReferenceMaterial}
                      alt="Reference Material"
                      title="Reference Material"
                    ></img>
                    <div class="ref-material-top-left">
                      <p className="ref-material-left-title">
                        {" "}
                        {contentTypeMappings[content.contentType]}
                      </p>
                      <p className="ref-material-left-title-type">
                        {content.contentType === "EBOOK" ||
                        content.contentType === "PATRA"
                          ? `(${capitalizeFirstLetter(
                              getFileExtension(content.contentUrl)
                            )})`
                          : content.contentType === "AUDIO" ||
                            content.contentType === "PAD"
                          ? "(Audio)"
                          : "(PDF)"}
                      </p>
                    </div>
                    <div class="ref-material-top-right">
                      {content.contentType === "AUDIO" ||
                      content.contentType ===
                        "PAD" ? null : fileDownloadLoader == index ? (
                        <img
                          src={loader}
                          alt="loading..."
                          style={{ height: 20, width: 20 }}
                        />
                      ) : (
                        <img
                          className="ref-material-yellow-download-img"
                          onClick={() =>
                            handleDownloadClick(content.contentUrl, index)
                          }
                          alt="Download"
                          src={AllImages?.YellowDownload}
                          title="Download"
                        ></img>
                      )}
                      {content.contentType === "AUDIO" ||
                      content.contentType === "PAD" ? null : (
                        <p
                          className="ref-material-right-title-read"
                          onClick={() =>
                            handleReadOrPlayClick(
                              content.contentUrl,
                              content.contentType
                            )
                          }
                        >
                          READ
                        </p>
                      )}
                      {(content.contentType === "AUDIO" ||
                        content.contentType === "PAD") && (
                        <div style={{ display: "flex", gap: "5px" }}>
                          {content?.contentUrlApple && (
                            <img
                              src={AllImages?.appleMusicSvg}
                              className="musicIcons read-play-type-btn"
                              onClick={() =>
                                openLinkInNewTab(content.contentUrlApple)
                              }
                              alt="Apple Music"
                              title="Apple Music"
                            />
                          )}
                          {content?.contentUrlSpotify && (
                            <img
                              src={AllImages?.spotifyMusicSvg}
                              className="musicIcons read-play-type-btn"
                              onClick={() =>
                                openLinkInNewTab(content.contentUrlSpotify)
                              }
                              alt="Spotify"
                              title="Spotify"
                            />
                          )}
                        </div>
                      )}
                      {/* <iframe
                        src={`https://docs.google.com/gview?url=${encodeURIComponent(content.contentUrl)}&embedded=true`}
                        style={{ width: '100%', height: '600px', border: 'none' }}
                        title="PDF Viewer"
                      /> */}
                    </div>
                  </div>
                ))}
              </div>
            </DialogContent>
          </Dialog>
        )}

        {isOpenSortBy && (
          <Dialog
            open={openModalSortBy}
            className="sortBy"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent
              style={{
                overflowY: "hidden",
                overflowX: "hidden",
              }}
            >
              <div className="qu-history-modal-msg-content">
                <p className="qu-history-modal-msg-text">{"Sort by"}</p>
                <span
                  className="qu-history-modal-close-button"
                  onClick={() => closeModalSortBy()}
                >
                  &times;
                </span>
              </div>
              <ul>
                {Object.keys(SortingOptions).map((key) => (
                  <li key={key}>
                    <label className="customRadio">
                      {SortingOptions[key] === "A_TO_Z" ||
                      SortingOptions[key] === "Z_TO_A"
                        ? key.replace(/_/g, " ")
                        : capitalizeFirstWord(
                            key.replace(/_/g, " ").toLowerCase()
                          )}
                      <input
                        type="radio"
                        value={SortingOptions[key]}
                        checked={selectedFilterOp === SortingOptions[key]}
                        onChange={handleRadioChange}
                        name="radio"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                ))}
              </ul>
            </DialogContent>
          </Dialog>
        )}

        {/* {openRecaptcha &&
          <Dialog
            open={openRecaptcha}
            className="sortBy captcha"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent style={{
              overflowY: "hidden",
              overflowX: "hidden",
            }}>

              <ReCAPTCHA
                sitekey={TEST_SITE_KEY}
                onChange={onChange}
                style={{ display: "inline-block", }}
              />
            </DialogContent>
          </Dialog>
        } */}
      </div>
    </>
  );
};
export default IndividualUserPlayList;
