import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { AllVideoPlayListContext } from "../../Context/UserContext";
import ApiEndPoint from "../../../utils/apiEndPoints";
import { setDefaultHeader, apiCall } from "../../../utils/httpClient";
import loader from "../../../assets/images/logo.png";
import Delete from "../../../assets/Phase2/delete.png";
import {
  GLOBAL_URL,
  AllImages,
  RemoveDuplicates,
  VideoPlaylistShareUrl,
  emojiRegex,
  DateTimeAgo,
} from "../../../utils/constants";
import {
  LoaderContext,
  UserloggedContext,
  GetDowloadedEbooksContext,
} from "../../Context/UserContext";
import "./style.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import NoDataAvailable from "../../common/NoDataFound/NoDataAvailable";
import { RWebShare } from "react-web-share";

const SortingOptions = {
  NEWLY_ADDED: "NEWLY_ADDED",
  PREVIOUSLY_ADDED: "PREVIOUSLY_ADDED",

  LAST_MODIFIED_DATE: "LAST_MODIFIED_DATE",
  Sort_A_To_Z: "A_TO_Z",
  Sort_Z_To_A: "Z_TO_A",
};
const DownloadedEbooks = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [downloadedEBookList, setGetDownloadedEbookList] = useContext(
    GetDowloadedEbooksContext
  );
  const [auth, setAuth] = useContext(UserloggedContext);
  const { viewMore } = location.state || {};
  const [pageNo, setPageNo] = useState(0);
  const [scrollHashMap, setScrollHashMap] = useState(viewMore);
  const [scrollLast, setScrollLast] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [ebookDeleteId, setEbookDeleteId] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [isOpenSortBy, setIsOpenSortBy] = useState(false);

  const [selectedFilterOp, setSelectedFilterOp] = useState(
    SortingOptions.LAST_MODIFIED_DATE
  );
  const openModal = (id) => {
    setEbookDeleteId(id);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setEbookDeleteId(null);
    // setLogoPreview(null)
  };

  useEffect(() => {
    fetchData();
  }, [selectedFilterOp]);
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchData();
    }
  };
  const fetchData = () => {
    setScrollHashMap(true);

    getDownloadedEbooks(1);
  };
  const fetchMoreData = () => {
    getDownloadedEbooks();
  };
  function handleSearch(e) {
    if (!emojiRegex.test(e.target.value)) {
      if (e.target.value) {
        setSearchText(e.target.value);
      } else {
        setSearchText(null);
      }
    }
  }

  async function getDownloadedEbooks(fetchPage = 0) {
    // setIsLoading(true)
    try {
      const params = {
        searchValue: searchText,
        sortColumn: selectedFilterOp,
        pageNo: fetchPage ? fetchPage - 1 : pageNo,
        pageSize: 20,
      };
      setIsLoading(true);
      const { data } = await apiCall(
        "POST",
        `${ApiEndPoint.GETDOWNLOADEBOOK}`,
        params
      );
      if (data.data.length > 0) {
        setIsLoading(false);

        setPageNo(fetchPage ? fetchPage : pageNo + 1);
        setGetDownloadedEbookList(
          RemoveDuplicates(
            fetchPage ? data.data : downloadedEBookList.concat(data.data)
          )
        );

        setScrollHashMap(data.data.length == 20);
        setScrollLast(true);
      } else {
        setIsLoading(false);
        fetchPage && setGetDownloadedEbookList([]);
        setPageNo(0);
        setScrollHashMap(false);
        setScrollLast(false);
      }
    } catch (err) {
      setIsLoading(false);
      setScrollLast(false);
      setScrollHashMap(false);
    }
    openModalSortBy && closeModalSortBy();
  }

  async function deleteDownloadedEbooks() {
    // setIsLoading(true)

    setIsOpen(false);
    const param = {
      id: ebookDeleteId,
    };
    try {
      const { data } = await apiCall(
        "DELETE",
        `${ApiEndPoint.DETELEDOWNLOADEDEBOOKS}`,
        param
      );
      if (data.data.length > 0) {
        const reData = downloadedEBookList.filter(
          (item) => item.id != ebookDeleteId
        );
        setGetDownloadedEbookList(reData);
        if (reData.length <= 1) {
          fetchData();
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  }

  const handleReadOrPlayClick = (contentUrl, contentType) => {
    const newTabUrl = `/fileViewer?contentUrl=${encodeURIComponent(
      contentUrl
    )}`;
    window.open(newTabUrl, "_blank");
  };

  const openModalSortBy = () => {
    setIsOpenSortBy(true);
  };

  const closeModalSortBy = () => {
    setIsOpenSortBy(false);
  };
  const handleRadioChange = (event) => {
    setSelectedFilterOp(event.target.value);
  };
  function capitalizeFirstWord(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <>
      <div className="downloadEBook">
        <InfiniteScroll
          dataLength={
            downloadedEBookList?.slice(
              0,
              viewMore ? downloadedEBookList.length : 5
            )?.length
          }
          next={fetchMoreData}
          // hasMore={true}
          hasMore={scrollHashMap}
          loader={
            scrollLast ? (
              isLoading && (
                <center>
                  <img
                    src={loader}
                    alt="loading..."
                    style={{ height: 25, width: 25, marginTop: 20 }}
                  />
                </center>
              )
            ) : (
              <p className="subtitle fancy">
                <span>End of results</span>
              </p>
            )
          }
        >
          {viewMore && (
            <div className="download-ebook-title-search-div">
              {/*----- Downloaded E-Books Title Start -----*/}
              <div>
                <div className="download-ebook-title-mt-20">
                  <span
                    className="download-ebook-d-flex"
                    onClick={() => navigate(-1)}
                  >
                    <img
                      className="download-ebook-c-pointer"
                      src={AllImages.YellowArrow}
                      alt="Back Arrow"
                      title="Back Arrow"
                    ></img>
                    <p className="download-ebook-title">Downloaded E-Books</p>
                  </span>
                </div>
              </div>
              {/*----- Downloaded E-Books Title End -----*/}

              {/*----- Search Box Start -----*/}
              <div className="all-search-wrap-outer">
                <div className="download-ebook-search-wrap">
                  <div className="search-input">
                    <span onClick={fetchData} className="search-icon">
                      {isLoading ? (
                        <img
                          src={loader}
                          alt="loading..."
                          style={{ height: 15, width: 15 }}
                        />
                      ) : (
                        <div className="searchTooltip">
                          <img
                            src={AllImages.Search}
                            alt="Search"
                            title="Search"
                          />
                          <span className="tooltiptext">{"Search"}</span>
                        </div>
                      )}
                      {/* <img src={AllImages.Search} alt="Search" title="Search" /> */}
                    </span>
                    <input
                      className="f-poppins"
                      type="text"
                      placeholder="Search for E-Books"
                      onChange={(e) => handleSearch(e)}
                      onKeyUp={handleKeyPress}
                    />
                  </div>
                  <div className="searchIcon-outer">
                    <img
                      src={AllImages.SearchIcon}
                      alt="Search"
                      title="Search"
                      className="searchIcon"
                    />
                  </div>
                  <div
                    className="filter-icon sortbyTooltip"
                    onClick={() => openModalSortBy()}
                  >
                    <img
                      src={AllImages.SearchToggle}
                      alt="Search"
                      title="Search"
                    />
                    <span className="tooltiptext">{"Sort By"}</span>
                  </div>
                </div>
              </div>
              {/*----- Search Box Start -----*/}
            </div>
          )}

          {/*----- Download EBooks Card  -----*/}
          <div className="download-ebook-col-count-3 custom-row">
            {downloadedEBookList.length > 0
              ? downloadedEBookList
                  ?.slice(0, viewMore ? downloadedEBookList.length : 5)
                  ?.map((item, index) => (
                    <div key={index} className="custom-col-4 custom-col">
                      <div className="download-ebook-card-wrap">
                        <div className="download-ebook-inside-wrap">
                          <div className="download-ebook-list-image">
                            <img
                              className="download-ebook-img-banner"
                              src={item.thumbnailImageUrl}
                              key={item.id}
                              alt="Card Image"
                            />
                            {/* <img className="download-ebook-play-icon" src={AllImages?.PlayIcon} alt="play Icon" /> */}
                          </div>
                          <div className="download-ebook-card-content-item">
                            <div className="download-ebook-video-content">
                              <div>
                                <p className="download-ebook-title-text f-poppins">
                                  {item.title}
                                </p>
                                {/* <p className="download-ebook-text-hrs f-poppins">22</p> */}
                              </div>
                              <div
                                style={{
                                  gap: "10px",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "left",
                                }}
                              >
                                <p className="ebook-text-hrs">
                                  {item?.pageCount} pages
                                </p>
                                <p className="ebook-text-hrs">•</p>
                                {item.createdDate ? (
                                  <p className="ebook-text-hrs">
                                    <p className="ebook-text-hrs">
                                      {DateTimeAgo(item.createdDate)}
                                    </p>
                                  </p>
                                ) : (
                                  <p className="ebook-text-hrs">&nbsp;</p>
                                )}
                              </div>
                              <div className="download-ebook-like-icon-div">
                                <div className="d-flex align-center">
                                  <RWebShare
                                    data={{
                                      // text: "Like humans, flamingos make friends for life",
                                      url: `${VideoPlaylistShareUrl}/fileViewer?contentUrl=${encodeURIComponent(
                                        item?.contentUrl?.replace(/ /g, "%20")
                                      )}`,
                                      title: "Ebook Share",
                                    }}
                                    onClick={() => console.log("share")}
                                  >
                                    <div className="copyTooltip">
                                      <img
                                        src={AllImages.Copy}
                                        alt="Copy"
                                        title="Copy"
                                      ></img>
                                      <span className="tooltiptext">
                                        {"Copy"}
                                      </span>
                                    </div>
                                  </RWebShare>
                                  <div className="deleteTooltip">
                                    <img
                                      className="download-ebook-c-pointer"
                                      src={AllImages?.DeleteCard}
                                      onClick={() => openModal(item.id)}
                                      alt="Delete"
                                    ></img>
                                    <span className="tooltiptext">
                                      {"Delete"}
                                    </span>
                                  </div>
                                </div>
                                <p
                                  className="download-ebook-read readTooltip"
                                  onClick={() => {
                                    handleReadOrPlayClick(item.contentUrl);
                                  }}
                                >
                                  Read
                                  <span className="tooltiptext">{"Read"}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
              : !isLoading && <NoDataAvailable />}
          </div>
        </InfiniteScroll>

        {isOpen && (
          <Dialog
            open={openModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <div className="download-ebook-modal-content">
                <p className="download-ebook-modal-msg-text">
                  {"Are you sure you want to Delete this E-Book?"}
                </p>
                <span
                  className="download-ebook-modal-close-button"
                  onClick={() => closeModal()}
                >
                  &times;
                </span>
              </div>
            </DialogContent>
            <DialogActions>
              <div className="download-ebook-modal-action-content">
                <button
                  className="download-ebook-modal-yes-btn"
                  onClick={() => deleteDownloadedEbooks()}
                >
                  {"Yes"}
                </button>
                <button
                  className="download-ebook-modal-dont-delete-btn"
                  onClick={() => closeModal()}
                >
                  {"DON’T delete"}
                </button>
              </div>
            </DialogActions>
          </Dialog>
        )}

        {isOpenSortBy && (
          <Dialog
            open={openModalSortBy}
            className="sortBy"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent
              style={{
                overflowY: "hidden",
                overflowX: "hidden",
              }}
            >
              <div className="qu-history-modal-msg-content">
                <p className="qu-history-modal-msg-text">{"Sort by"}</p>
                <span
                  className="qu-history-modal-close-button"
                  onClick={() => closeModalSortBy()}
                >
                  &times;
                </span>
              </div>
              <ul>
                {Object.keys(SortingOptions).map((key) => (
                  <li key={key}>
                    <label className="customRadio">
                      {SortingOptions[key] === "A_TO_Z" ||
                      SortingOptions[key] === "Z_TO_A"
                        ? key.replace(/_/g, " ")
                        : capitalizeFirstWord(
                            key.replace(/_/g, " ").toLowerCase()
                          )}
                      <input
                        type="radio"
                        value={SortingOptions[key]}
                        checked={selectedFilterOp === SortingOptions[key]}
                        onChange={handleRadioChange}
                        name="radio"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                ))}
              </ul>
            </DialogContent>
          </Dialog>
        )}
      </div>
    </>
  );
};
export default DownloadedEbooks;
