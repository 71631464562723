import { Box } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { styled } from "@mui/material/styles";
import React, { useContext, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useNavigate } from "react-router-dom";
import { RWebShare } from "react-web-share";
import loader from "../../../assets/images/loading_wbg.gif";
import ApiEndPoint from "../../../utils/apiEndPoints";

import {
  AllImages,
  BhaktiPadPlaylistShareUrl,
  emojiRegex,
  getImageNameFromResolution,
  MinutesToHoursMinutes,
  RemoveDuplicates,
  VideoPlaylistShareUrl,
} from "../../../utils/constants";
import { apiCall } from "../../../utils/httpClient";
import {
  ShowCreatePlayListContext,
  UserloggedContext,
  ActiveTabsContext
} from "../../Context/UserContext";
import BhaktiPadsTabBar from "../BhaktiPadsTabBar";
import "./style.css";
import PlaylistImage from "components/common/canvas";
import { generateImageDataURL } from "utils/generateImageDataURL";
const SortingOptions = {
  NEWLY_ADDED: "NEWLY_ADDED",
  PREVIOUSLY_ADDED: "PREVIOUSLY_ADDED",
  // LAST_MODIFIED_DATE: "LAST_MODIFIED_DATE",
  POPULAR_BHAKTI_PADS: "POPULAR_VIDEOS",
  Sort_A_To_Z: "A_TO_Z",
  Sort_Z_To_A: "Z_TO_A",
};
const CreatedPlaylist = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [BtnLoading, setBtnLoading] = useState(false);
  const [auth, setAuth] = useContext(UserloggedContext);
  const { viewMore } = location.state || {};
  const [showCreatePlayListModel, setShowCreatePlayListModel] = useContext(ShowCreatePlayListContext);
  const [userPlayList, setUserPlayList] = useState([]);
  const [userPlayListAudio, setUserPlayListAudio] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [scrollHashMap, setScrollHashMap] = useState(false);
  const [scrollLast, setScrollLast] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAudio, setIsOpenAudio] = useState(false);
  const [playlistName, setPlaylistName] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isImageError, setImageError] = useState(false);
  const [file, setFile] = useState(null);
  const [fileShow, setFileShow] = useState(null);
  const [isPreview, setPreview] = useState(false);
  const [playListDeleteId, setPlayListDeleteId] = useState(null);
  const [playListDeleteIdAudio, setPlayListDeleteIdAudio] = useState(null);
  const [isOpenSortBy, setIsOpenSortBy] = useState(false);
  const [albums, setAlbumData] = useState([]);
  const [selectedFilterOp, setSelectedFilterOp] = useState(SortingOptions.NEWLY_ADDED);
  const [activetabs, setActiveTabs] = useContext(ActiveTabsContext);
  const [tabValue, setTabValue] = React.useState(activetabs.filter((item) => item.page == "createdPlaylist")[0].active);
  const [tabValueModal, setTabValueModal] = useState("videos");
  const [searchText, setSearchText] = useState(null);

  // const inputRef = useRef(null);

  // useEffect(() => {
  //   if (inputRef.current) {
  //     inputRef.current.focus();
  //   }
  // }, [playlistName]);
 

  useEffect(() => {
    window.history.scrollRestoration = "manual";
    fetchData();
    // fetchDataAudio();
    return () => {
      setShowCreatePlayListModel(false);
    };
  }, [tabValue, selectedFilterOp]);
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchData();
    }
  };
  const fetchData = () => {

    setScrollHashMap(true);
    if (tabValue === "videos") {
      userPlayListFun(1);
    } else if (tabValue === "bhaktiPad") {
      userPlayListFunAudio(1);
    }
  };

  const fetchDataAudio = () => {
    userPlayListFunAudio(1);
  };
  const fetchMoreData = () => {
    userPlayListFun();
  };
  const handleSearch = (e) => {
    if (!emojiRegex.test(e.target.value)) {
      if (e.target.value) {
        setSearchText(e.target.value);
      } else {
        setSearchText(e.target.value);
      }
    }
  }

  const openModal = (id) => {
    setIsOpen(true);
    setPlayListDeleteId(id);
  };
  const closeModal = () => {
    setIsOpen(false);
    setPlayListDeleteId(null);
  };
  const openModalAudio = (id) => {
    setIsOpenAudio(true);
    setPlayListDeleteIdAudio(id);
  };
  const closeModalAudio = () => {
    setIsOpenAudio(false);
    setPlayListDeleteIdAudio(null);
  };
  const openCreatedPlayListModal = () => {
    setShowCreatePlayListModel(true);
  };
  const closeCreatedPlayListModal = () => {
    setShowCreatePlayListModel(false);
  };
  async function userPlayListFun(fetchPage) {
    if (auth) {
      try {
        const params = {
          searchValue: searchText,
          sortColumn: selectedFilterOp,
          pageNo: fetchPage ? fetchPage - 1 : pageNo,
          pageSize: 20,
        };
        setIsLoading(true);
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.USERPLAYLIST}`,
          params
        );
        if (data.length > 0) {
          setIsLoading(false);
          // setUserPlayList(fetchPage?data:prev=>[...prev,...data]);
          setUserPlayList(
            RemoveDuplicates(fetchPage ? data : userPlayList.concat(data))
          );
          setPageNo(fetchPage ? fetchPage : pageNo + 1);
          setScrollHashMap(data.length == 20);
          setScrollLast(true);
        } else {
          setIsLoading(false);
          setScrollLast(false);
          setScrollHashMap(false);
          fetchPage && setUserPlayList([]);
          setPageNo(0);
        }
      } catch (err) {
        setIsLoading(false);
        setScrollLast(false);
        setScrollHashMap(false);
      }
    }
    isOpenSortBy && closeModalSortBy();
  }
  async function userPlayListFunAudio(fetchPage) {
    setIsLoading(true);
    if (auth) {
      try {
        const body = {
          pageNo: fetchPage ? fetchPage - 1 : pageNo,
          pageSize: 20,
          sortColumn: selectedFilterOp,
          sortDirection: "asc",
          searchValue: searchText
        };
        if (auth) {
          const res = await apiCall(
            "POST",
            `${ApiEndPoint.USERPLAYLISTFORAUDIO}`,
            body
          );
          const data = res?.data;
          if (data?.length > 0) {
            setIsLoading(false);
            // setAlbumData(data);
            setAlbumData(
              RemoveDuplicates(fetchPage ? data : albums.concat(data))
            );
            setPageNo(fetchPage ? fetchPage : pageNo + 1);
            setScrollHashMap(data.length == 20);
            setScrollLast(true);
          } else {
            setIsLoading(false);
            setScrollLast(false);
            setScrollHashMap(false);
            fetchPage && setAlbumData([]); // Clear musicData if fetching a new page
            setPageNo(0);
          }

        }
      } catch (error) {
        setIsLoading(false);
        setScrollLast(false);
        setScrollHashMap(false);
        console.error("Error fetching data:", error);
      }
    }
    isOpenSortBy && closeModalSortBy();
  }

  const individualUserPlayListNav = (item, type) => {
    navigate("/individualUserPlayList", {
      state: { playListDetails: item, playListType: type },
    });
  };


  const validateInput = (text, isNote = false) => {
    const emojiRegexs = emojiRegex;
    if (/^\s+/.test(text)) {
      return false;
    } else if (emojiRegexs.test(text)) {
      return false;
    } else if (!isNote && text.length > 35) {
      return false;
    } else return true;
  };
  const handlePlaylistNameChange = (event) => {
    const inputValue = event.target.value;
    if (validateInput(inputValue)) {
      setPlaylistName(inputValue);
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };
  const validateForm = () => {
    if (playlistName == "") {
      return false;
    }
    return true;
  };
  const handleSubmit = async (event) => {
    const valid = validateForm();
    var params = {}
    if (auth) {
      if (valid) {

        if (event === 'bhaktiPad') {
          params = {
            playListName: playlistName,
            contentType: 'YOUTUBE_TRACK'
          };
        }

        else {
          params = {
            playListName: playlistName,
            contentType: 'VIDEO'
          };
        }

        setBtnLoading(true);
        try {
          const { data } = await apiCall(
            "POST",
            `${ApiEndPoint.CREATEUSERPLAYLIST}`,
            params
          );
          if (data) {

            if (event === 'videos')
              navigate("/myCreatePlayListVideoAddBtnSuggested", {
                state: { id: data.id, title: data.playListName },
              });
            else {
              navigate("/myCreatePlayListTrackAddBtnSuggested")
            }


            setRefresh(!refresh);
          } else {
            setRefresh(!refresh);
          }
        } catch (err) {
          setShowCreatePlayListModel(false);
          setRefresh(!refresh);
          setBtnLoading(false);
        }
      } else {
        setIsValid(true);
      }
    } else {
      // setGoogleLoginShow(true)
    }
    // setIsOpen(false);
  };
  const userPlayListDelete = async () => {
    try {
      const { data } = await apiCall(
        "DELETE",
        `${ApiEndPoint.DELTEUSERPLAYLIST}/${playListDeleteId}`
      );
      if (data) {
        // userPlayListFun();
        const removeUserPlaylist = userPlayList.filter(
          (x) => x.id != playListDeleteId
        );
        setUserPlayList(removeUserPlaylist);
        closeModal();
        setRefresh(!refresh);
        if (removeUserPlaylist.length <= 1) {
          //  window.location.reload();
          fetchData();
        }
      } else {
        setRefresh(!refresh);
      }
    } catch (err) {
      setRefresh(!refresh);
      console.log("err: ", err);
    }
  };
  const userPlayListDeleteAudio = async () => {
    try {
      const { data } = await apiCall(
        "DELETE",
        `${ApiEndPoint.DELTEUSERPLAYLISTAUDIO}/${playListDeleteIdAudio}`
      );
      if (data) {
        // userPlayListFun();
        const removeUserPlaylist = albums.filter(
          (x) => x.id != playListDeleteIdAudio
        );
        setAlbumData(removeUserPlaylist);
        closeModalAudio();
        setRefresh(!refresh);
        if (removeUserPlaylist.length <= 1) {
          //  window.location.reload();
          fetchDataAudio();
        }
      } else {
        setRefresh(!refresh);
      }
    } catch (err) {
      setRefresh(!refresh);
    }
  };
  const openModalSortBy = () => {
    setIsOpenSortBy(true);
  };
  const closeModalSortBy = () => {
    setIsOpenSortBy(false);
  };
  const handleRadioChange = (event) => {
    setSelectedFilterOp(event.target.value);
  };
  function capitalizeFirstWord(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = remainingMinutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
  }



  function convertSecondsToHoursAndMinutes(seconds) {
    const hours = Math.floor(seconds / 3600);
    const remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
  }


  const StyledTab = styled(Tab)({
    "&.Mui-selected": {
      color: "#FFB200",
      fontSize: 15,
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      textTransform: "uppercase",
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 5,
      alignItems: "flex-start",
      marginRight: 15,
      justifyContent: "end",
    },
    "&.MuiTab-root": {
      fontSize: 15,
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      textTransform: "uppercase",
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 5,
      alignItems: "flex-start",
      marginRight: 15,
      justifyContent: "end",
      minWidth: "auto",
    },
  });

  const handleTabChangeForModal = (event, newValue) => {
    setTabValueModal(newValue);
  };

  const handleTabChange = (event, newValue) => {
    setPageNo(0);
    setScrollHashMap(true);
    setScrollLast(true);
    setTabValue(newValue);
    const updateArray = activetabs.map((item) => {
      if (item.page === "createdPlaylist") {
        return {
          ...item,
          active: newValue,
        };
      } else {
        return {
          ...item,
          active: "videos",
        };
      }
    });
    setActiveTabs(updateArray);
  };


  return (
    <>
      <div className="myCreatedPlaylist">
        <InfiniteScroll
          dataLength={
            userPlayList &&
            userPlayList?.slice(0, viewMore ? userPlayList.length : 3)?.length
          }
          next={() => fetchMoreData()}
          // hasMore={true}
          hasMore={scrollHashMap}
          loader={
            viewMore && scrollLast
              ? isLoading && (
                <center>
                  <img
                    src={loader}
                    alt="loading..."
                    style={{ height: 25, width: 25, marginTop: 20 }}
                  />
                </center>
              )
              : viewMore && (
                <p className="subtitle fancy">
                  <span>End of results</span>
                </p>
              )
          }
        >

          {/* {viewMore && ( */}
          <div className="my-created-playlist-title-search-div">
            {/*----- Created Playlist Title Start -----*/}

            {viewMore && (
              <div className="my-created-playlist-header">
                <div className="my-created-playlist-title-mt-20">
                  <span
                    className="my-created-playlist-d-flex"
                    onClick={() => navigate(-1)}
                  >
                    <img
                      className="my-created-playlist-c-pointer"
                      src={AllImages.YellowArrow}
                      alt="Back Arrow"
                      title="Back Arrow"
                    ></img>
                    <p className="my-created-playlist-title">
                      My Created Playlists
                    </p>
                  </span>
                </div>
                <div className="my-created-playlist-header-right">
                  <div onClick={() => setShowCreatePlayListModel(true)}>
                    <p className="header-create-btn">CREATE</p>
                  </div>
                </div>
              </div>
            )}
            {/*----- Created Playlist Title End -----*/}

            <div className="my-created-playlist-title-search-div-second-outer">
              <div  className="my-created-playlist-title-search-div-second-inner">
                <div className="py-pl-prr-5" style={{ alignContent: 'center' }}>
                  <Box className="my-space-tab-a-center">
                    <Tabs
                      value={tabValue}
                      onChange={handleTabChange}
                      sx={{
                        "& .MuiTabs-indicator": {
                          backgroundColor: "#FFB200",
                          minWidth: "auto",
                        },
                      }}
                      aria-label="secondary tabs example"
                    >
                      <StyledTab value="videos" label="Satsang"/>
                      <StyledTab value="bhaktiPad" label="Bhakti Pad" />
                    </Tabs>
                  </Box>
                </div>


                {viewMore && (
                  <div className="my-created-playlist-search-wrap-outer">
                    {/*----- Search Box Start -----*/}
                    <div className="my-created-playlist-search-wrap">
                      <div className="search-input">
                        <span onClick={fetchData} className="search-icon">
                          {isLoading ? (
                            <img
                              src={loader}
                              alt="loading..."
                              style={{ height: 15, width: 15 }}
                            />
                          ) : (
                            <div className="searchTooltip">
                              <img
                                src={AllImages.Search}
                                alt="Search"
                                title="Search"
                              />
                              <span className="tooltiptext">{"Search"}</span>
                            </div>
                          )}

                        </span>
                        <input
                          className="f-poppins"
                          type="text"
                          // placeholder="Search for Playlists"
                          placeholder="Search"
                          value={searchText}
                          onChange={handleSearch}
                          onKeyUp={handleKeyPress}
                        />
                      </div>
                      <div className="searchIcon-outer">
                        <img
                          src={AllImages.SearchIcon}
                          alt="Search"
                          title="Search"
                          className="searchIcon"
                        />
                      </div>
                      <div
                        className="filter-icon sortbyTooltip"
                        onClick={() => openModalSortBy()}
                      >
                        <img
                          src={AllImages.SearchToggle}
                          alt="Search"
                          title="Search"
                        />
                        <span className="tooltiptext">{"Sort By"}</span>
                      </div>
                    </div>
                    {/*----- Search Box End -----*/}
                  </div>
                )}
              </div>
            </div>

          </div>

          {/*----- Bhakti Pads TabBar -----*/}
          {tabValue == 'bhaktiPad' &&
            albums?.length > 0 ? (
            <div className="my-created-playlist-col-3 custom-row myCreatedBhaktiPad">
              {albums?.slice(0, viewMore ? albums?.length : 4)?.map((item, index) => (
                <div className="custom-col-4 custom-col">
                  <div
                    key={index}
                    className="my-created-playlist-card-wrap"
                  >
                    <div className="my-created-playlist-inside-wrap">
                      <div
                        className="list-image"
                        // onClick={() => {individualUserPlayListNav(item, "user");}}
                        // onClick={() => navigate(`/AudioPlaylist/${item?.id}`)}
                        onClick={() => navigate(`/AudioPlaylist/${item?.id}`, { state: { playListDetails: item } })}
                      >
                        <img
                          className={`img-banner ${item?.thumbnail ? getImageNameFromResolution(item?.thumbnail) !== "maxresdefault" && "image-resolution" : "image-resolution"}`}
                          src={generateImageDataURL(item?.playListName || 'Bhakti pad Playlist')}
                          key={item.id}
                          alt="Card Image"
                          title="Card Image"
                        />
                        <div className="playIconTooltip">
                          <img
                            className="my-created-playlist-play-icon"
                            src={AllImages?.PlayIcon}
                            alt="Play Icon"
                            title="Play Icon"
                          />
                          <span className="tooltiptext">
                            {"Listen Bhakti Pad"}
                          </span>
                        </div>
                      </div>
                      <div className="my-created-playlist-content-item">
                        <div className="my-created-playlist-video-content">
                          <div>
                            <p className="my-created-playlist-title-text f-poppins">
                              {item.playListName}
                            </p>
                            <div
                              style={{ gap: "10px" }}
                              className="general-textDetails general-text-hrs f-poppins"
                            >
                              <p className="general-text-hrs">
                                {`${convertSecondsToHoursAndMinutes(item.youtubeTrackDuration)} hrs`} </p>
                              <p className="general-text-hrs">
                                {`${item.noOfContents}` + " Bhakti Pad"} </p>
                            </div>
                          </div>
                          <div
                            className="my-created-playlist-like-icon-div d-flex align-center"
                            style={{ justifyContent: "end" }}
                          >
                            <RWebShare
                              data={{
                                url: `${BhaktiPadPlaylistShareUrl}/PlayListShared/${item.id}`,
                                // url: `http://localhost:3000/PlayListShared/${item.id}`,
                                title: "Playlist Share",
                              }}
                              onClick={() => console.log("share")}
                            >
                              <div className="readTooltip">
                                <img
                                  className="video-play-mr-10"
                                  src={AllImages?.ShareOutline}
                                  alt="Share"
                                  title="Share"
                                ></img>
                                <span className="tooltiptext">
                                  {"Share Playlist"}
                                </span>
                              </div>
                            </RWebShare>
                            <div className="deleteTooltip">
                              <img
                                className="my-created-playlist-c-pointer"
                                src={AllImages?.DeleteCard}
                                onClick={() => openModalAudio(item.id)}
                                style={{ height: 20, width: 20 }}
                              ></img>
                              <span className="tooltiptext">
                                {"Delete"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (

            tabValue == 'bhaktiPad' &&
            <div className="my-space-empty-card-wrap-outer">
              <div className="my-space-empty-card-wrap">
                <span className="my-space-empty-msg">
                  {"You haven't created Bhakti Pad playlist yet "}
                </span>
              </div>
            </div>
          )
            // <div>
            //   <BhaktiPadsTabBar />
            // </div>
          }
          {/*----- My Create Playlists  -----*/}
          <>
            {(tabValue == 'videos') &&
              userPlayList?.length > 0 ? (
              <div className="my-created-playlist-col-3 custom-row">
                {userPlayList &&
                  userPlayList
                    ?.slice(0, viewMore ? userPlayList.length : 3)
                    ?.map((item, index) => (
                      <div className="custom-col-4 custom-col">
                        <div
                          key={index}
                          className="my-created-playlist-card-wrap"
                        >
                          <div className="my-created-playlist-inside-wrap">
                            <div
                              className="list-image"
                              onClick={() => {
                                individualUserPlayListNav(item, "user");
                              }}
                            >
                              <img
                                className={`img-banner ${item.thumbnail
                                  ? getImageNameFromResolution(
                                    item?.thumbnail
                                  ) != "maxresdefault" && "image-resolution"
                                  : "image-resolution"
                                  }`}
                                src={
                                  item.thumbnail
                                    ? item.thumbnail
                                    : AllImages.HomeBanner
                                }
                                key={item.id}
                                alt="Card Image"
                                title="Card Image"
                              />
                              <div className="playIconTooltip">
                                <img
                                  className="my-created-playlist-play-icon"
                                  src={AllImages?.PlayIcon}
                                  alt="Play Icon"
                                  title="Play Icon"
                                />
                                <span className="tooltiptext">
                                  {"Play Video"}
                                </span>
                              </div>
                            </div>
                            <div className="my-created-playlist-content-item">
                              <div className="my-created-playlist-video-content">
                                <div>
                                  <p className="my-created-playlist-title-text f-poppins">
                                    {item.playListName}
                                  </p>
                                  <div
                                    style={{ gap: "10px" }}
                                    className="general-textDetails general-text-hrs f-poppins"
                                  >
                                    <p className="general-text-hrs">
                                      {" "}
                                      {item?.videoDuration
                                        ? MinutesToHoursMinutes(
                                          item?.videoDuration
                                        )
                                        : null}{" "}
                                      {item?.videoDuration ? "hrs" : "0 hrs"}
                                    </p>
                                    <p className="general-text-hrs">•</p>
                                    <p className="general-text-hrs">
                                      {item.noOfVideos}{" "}
                                      {item.noOfVideos ? "videos" : "0 videos"}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className="my-created-playlist-like-icon-div d-flex align-center"
                                  style={{ justifyContent: "end" }}
                                >
                                  <RWebShare
                                    data={{
                                      url: `${VideoPlaylistShareUrl}/PlayListShared/${item.id}`,
                                      // url: `http://localhost:3000/PlayListShared/${item.id}`,
                                      title: "Playlist Share",
                                    }}
                                    onClick={() => console.log("share")}
                                  >
                                    <div className="readTooltip">
                                      <img
                                        className="video-play-mr-10"
                                        src={AllImages?.ShareOutline}
                                        alt="Share"
                                        title="Share"
                                      ></img>
                                      <span className="tooltiptext">
                                        {"Share Playlist"}
                                      </span>
                                    </div>
                                  </RWebShare>
                                  <div className="deleteTooltip">
                                    <img
                                      className="my-created-playlist-c-pointer"
                                      src={AllImages?.DeleteCard}
                                      onClick={() => openModal(item.id)}
                                      style={{ height: 20, width: 20 }}
                                    ></img>
                                    <span className="tooltiptext">
                                      {"Delete"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            ) : (
              tabValue == 'videos' &&
              <div className="my-space-empty-card-wrap-outer">
                <div className="my-space-empty-card-wrap">
                  <span className="my-space-empty-msg">
                    {"You haven't created Satsang playlist yet "}
                  </span>
                </div>
              </div>
            )
            }
          </>
        </InfiniteScroll>
        {/*----- Delete Modal -----*/}
        {isOpen && (
          <Dialog
            open={openModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <div className="my-created-playlist-modal-content">
                <p className="my-created-playlist-modal-msg-text">
                  {"Are you sure you want to Delete Playlist?"}
                </p>
                <span
                  className="my-created-playlist-modal-close-btn"
                  onClick={() => closeModal()}
                >
                  &times;
                </span>
              </div>
            </DialogContent>
            <DialogActions>
              <div className="my-created-playlist-modal-action-content">
                <button
                  className="my-created-playlist-modal-yes-btn"
                  onClick={() => userPlayListDelete()}
                >
                  {"Yes"}
                </button>
                <button
                  className="my-created-playlist-modal-dont-delete-btn"
                  onClick={() => closeModal()}
                >
                  {"DON’T delete"}
                </button>
              </div>
            </DialogActions>
          </Dialog>
        )}
        {/*----- Delete Modal Audio -----*/}
        {isOpenAudio && (
          <Dialog
            open={openModalAudio}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <div className="my-created-playlist-modal-content">
                <p className="my-created-playlist-modal-msg-text">
                  {"Are you sure you want to Delete Playlist?"}
                </p>
                <span
                  className="my-created-playlist-modal-close-btn"
                  onClick={() => closeModalAudio()}
                >
                  &times;
                </span>
              </div>
            </DialogContent>
            <DialogActions>
              <div className="my-created-playlist-modal-action-content">
                <button
                  className="my-created-playlist-modal-yes-btn"
                  onClick={() => userPlayListDeleteAudio()}
                >
                  {"Yes"}
                </button>
                <button
                  className="my-created-playlist-modal-dont-delete-btn"
                  onClick={() => closeModalAudio()}
                >
                  {"DON'T delete"}
                </button>
              </div>
            </DialogActions>
          </Dialog>
        )}
        {/*----- Create a Playlist Modal -----*/}
        {showCreatePlayListModel && (
          <Dialog
            open={showCreatePlayListModel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="createPlaylistModal"
          >
            <DialogContent>
              <div className="my-create-playlist-modal-content">
                <div className="modal-d-flex">
                  <p className="my-created-playlist-modal-msg-text">
                    {"Create a Playlist"}
                  </p>
                  <span
                    className="my-created-playlist-modal-close-btn"
                    onClick={() => closeCreatedPlayListModal()}
                  >
                    &times;
                  </span>
                </div>
                {/*----- Modal Tab Bar -----*/}
                <div>
                  <Box className="my-space-tab-a-center">
                    <Tabs
                      value={tabValueModal}
                      onChange={handleTabChangeForModal}
                      sx={{
                        "& .MuiTabs-indicator": {
                          backgroundColor: "#FFB200",
                          minWidth: "auto",
                        },
                      }}
                      aria-label="secondary tabs example"
                    >
                      <StyledTab value="videos" label="Satsang" />
                      <StyledTab value="bhaktiPad" label="Bhakti Pad" />
                    </Tabs>
                  </Box>
                </div>
                <div>
                  <p className="my-create-playlist-modal-playlist-name">
                    {"Playlist Name"}
                  </p>
                  <input
                    placeholder="Spiritual Playlist"
                    className={`f-poppins my-create-playlist-modal-search-input ${isValid ? "invalid" : null
                      } `}
                    type="text"
                    id="playlistName"
                    value={playlistName}
                    onChange={handlePlaylistNameChange}
                    // ref={inputRef} 
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <div className="my-created-playlist-modal-action-content">
                <button
                  className="my-create-playlist-cancel-btn"
                  onClick={() => closeCreatedPlayListModal()}
                >
                  {"Cancel"}
                </button>
                <button
                  className="my-create-playlist-create-btn"
                  onClick={() => handleSubmit(tabValueModal)}
                >
                  {BtnLoading ? (
                    <img src={loader} style={{ width: 20, height: 20 }} />
                  ) : (
                    "Create"
                  )}
                </button>
              </div>
            </DialogActions>
          </Dialog>
        )}
        {isOpenSortBy && (
          <Dialog
            open={openModalSortBy}
            className="sortBy"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent
              style={{
                overflowY: "hidden",
                overflowX: "hidden",
              }}
            >
              <div className="qu-history-modal-msg-content">
                <p className="qu-history-modal-msg-text">{"Sort by"}</p>
                <span
                  className="qu-history-modal-close-button"
                  onClick={() => closeModalSortBy()}
                >
                  &times;
                </span>
              </div>
              <ul>
                {Object.keys(SortingOptions).map((key) => (
                  <li key={key}>
                    <label className="customRadio">
                      {SortingOptions[key] === "A_TO_Z" ||
                        SortingOptions[key] === "Z_TO_A"
                        ? key.replace(/_/g, " ")
                        : capitalizeFirstWord(
                          key.replace(/_/g, " ").toLowerCase()
                        )}
                      <input
                        type="radio"
                        value={SortingOptions[key]}
                        checked={selectedFilterOp === SortingOptions[key]}
                        onChange={handleRadioChange}
                        name="radio"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                ))}
              </ul>
            </DialogContent>
          </Dialog>
        )}

      </div>
    </>
  );
};
export default CreatedPlaylist;
