const initialState = {
  playlistTracks: [] 
};

const changeTracks = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_TRACK':
      return {
        ...state,
        playlistTracks: action.payload,
      };
      
    case 'DELETE_TRACK':
      return {
        ...state,
        playlistTracks: state.playlistTracks.filter(
          (track) => track.id !== action.payload
        ),
      };
      
    case 'DELETE_TRACKS':
      return {
        ...state,
        playlistTracks: [], 
      };
    default:
      return state;
  }
};

export default changeTracks;


