import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import loader from "../../../assets/images/logo.png";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Button from "@material-ui/core/Button";
import {
  UserloggedContext,
  GoogleLoginShowContext,
} from "../../Context/UserContext";
import ApiEndPoint from "../../../utils/apiEndPoints";
import { setDefaultHeader, apiCall } from "../../../utils/httpClient";
import {
  AllImages,
  GLOBAL_URL,
  RemoveDuplicates,
  VideoPlaylistShareUrl,
  emojiRegex,
  getImageNameFromResolution,
} from "../../../utils/constants";
import "./style.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import NoDataAvailable from "../../common/NoDataFound/NoDataAvailable";
import { toast } from "react-toastify";
import { RWebShare } from "react-web-share";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
const SortingOptions = {
  NEWLY_ADDED: "NEWLY_ADDED",
  PREVIOUSLY_ADDED: "PREVIOUSLY_ADDED",
  POPULAR_VIDEOS: "POPULAR_VIDEOS",
  NEWLY_PUBLISHED: "NEWLY_PUBLISHED",
  PREVIOUSLY_PUBLISHED: "PREVIOUSLY_PUBLISHED",
  LAST_MODIFIED_DATE: "LAST_MODIFIED_DATE",
  Sort_A_To_Z: "A_TO_Z",
  Sort_Z_To_A: "Z_TO_A",
};

const SortableItem = SortableElement(
  ({
    item,
    index,
    handleVideoClick,
    takeSubcription,
    removeWatchLater,
    videoWatchLater,
    addFavorite,
    removeFromPlaylist,
  }) => 

    (
    <div className=" custom-col-4 custom-col" key={index}>
      <div className="play-card-wrap">
        <div className="play-inside-wrap" style={{ position: "relative" }}>
          {item.videoUrl ? (
            <div
              className="play-list-image"
              onClick={() => handleVideoClick(item)}
            >
              <img
                className={`img-banner ${
                  getImageNameFromResolution(item?.thumbnails) !=
                    "maxresdefault" && "image-resolution"
                }`}
                src={
                  item?.thumbnails
                    ? `${item?.thumbnails}`
                    : `${AllImages.DefaultThumbnail}`
                }
                key={item.id}
                alt="Card Image"
                title="Card Image"
              />
              <img
                className="play-play-icon"
                src={AllImages.PlayIcon}
                alt="Play Icon"
                title="Play Icon"
              />
            </div>
          ) : (
            <div className="play-list-image" style={{ position: "static" }}>
              <div onClick={() => takeSubcription(item)}>
                <img
                  className={`img-banner ${
                    getImageNameFromResolution(item.thumbnails) !=
                      "maxresdefault" && "image-resolution"
                  }`}
                  src={
                    item?.thumbnails
                      ? `${item?.thumbnails}`
                      : `${AllImages.DefaultThumbnail}`
                  }
                  alt="Card Image"
                  title="Card Image"
                />
                <div className="layerImage"></div>
              </div>
              <div className="star1 badgestarInvisible">
                <div className="LikeCount badgeShow">
                  <i
                    className="fa fa-lock"
                    onClick={() => takeSubcription(item)}
                  ></i>
                </div>
                <span className="tooltiptext">
                  {"Subscribe for more Videos"}
                </span>
              </div>
            </div>
          )}
          <div className="play-card-content-item">
            <div className="play-video-content">
              <div>
                <p className="play-title-text f-poppins">{item.videoTitle}</p>
                <p className="play-text-hrs f-poppins">
                  {item?.videoDuration ? item?.videoDuration : null}{" "}
                  {item?.videoDuration ? "hrs" : null}{" "}
                </p>
              </div>
              <div className="mt-10 userPlaylistOptions">
                <div>
                  <img
                    onClick={() =>
                      item.watchLater
                        ? removeWatchLater(item, index)
                        : videoWatchLater(item, index)
                    }
                    src={
                      item.watchLater
                        ? AllImages.SelectedTimer
                        : AllImages.Timer
                    }
                    alt="Timer"
                    title="Timer"
                  ></img>
                  <img
                    src={
                      item?.myFavourite ? AllImages.Favorite_sel : AllImages.Fav
                    }
                    onClick={() => addFavorite(item, index)}
                    alt="Favourite"
                    title="Favourite"
                  ></img>
                  <RWebShare
                    data={{
                      url: `${VideoPlaylistShareUrl}/videoPlayPage/${item.id}`,
                      title: "Video Share",
                    }}
                    onClick={() => console.log("share")}
                  >
                    <img src={AllImages.Copy} alt="Copy" title="Copy"></img>
                  </RWebShare>
                </div>
                <div
                  onClick={() => removeFromPlaylist(item, index)}
                  className="playlistRemoveContentBtn"
                >
                  <p>REMOVE</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
);

const SortableList = SortableContainer(
  ({
    items,
    handleVideoClick,
    takeSubcription,
    removeWatchLater,
    videoWatchLater,
    addFavorite,
    removeFromPlaylist,
    isLoading,
  }) => {
    return (
      <div className="custom-row">
        {items.length > 0
          ? items.map((value, index) => 
             
               (
                <SortableItem
                  key={value.id}
                  item={value}
                  index={index}
                  handleVideoClick={handleVideoClick}
                  takeSubcription={takeSubcription}
                  removeWatchLater={removeWatchLater}
                  videoWatchLater={videoWatchLater}
                  addFavorite={addFavorite}
                  removeFromPlaylist={removeFromPlaylist}
                />
              )
            )
          : !isLoading && <NoDataAvailable />}
      </div>
    );
  }
);
const IndividualPlayList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [auth, setAuth] = useContext(UserloggedContext);
  const [googleLoginShow, setGoogleLoginShow] = useContext(
    GoogleLoginShowContext
  );
  const location = useLocation();
  const navigate = useNavigate();
  const { playListDetails } = location.state || {};
  const [pageNo, setPageNo] = useState(0);
  const [playListVideo, setPlayListVideo] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [scrollHashMap, setScrollHashMap] = useState(false);
  const [scrollLast, setScrollLast] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [isNewSearch, setIsNewSearch] = useState(true);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const [isOpenSortBy, setIsOpenSortBy] = useState(false);
  const [selectedFilterOp, setSelectedFilterOp] = useState(
    SortingOptions.NEWLY_ADDED
  );
  useEffect(() => {
    window.history.scrollRestoration = "manual";
    fetchData();
  }, [selectedFilterOp]);
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchData();
    }
  };
  const fetchData = () => {
    setScrollHashMap(true);
    fetchPlayListVideos(1);
  };
  const fetchMoreData = () => {
    fetchPlayListVideos();
  };

  
  async function fetchPlayListVideos(fetchPage = 0) {
    try {
      if (auth) {
        const params = {
          searchValue: searchText,
          playListId: playListDetails.id,
          sortColumn: "SERIAL_ASC",
          pageNo: fetchPage ? fetchPage - 1 : pageNo,
          pageSize: 20,
          contentType: "VIDEO",
        };

        setIsLoading(true);

        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.USERPLAYLISTCONTENTS}`,
          params
        );
        if (data.length > 0) {
          setIsLoading(false);
          setPlayListVideo(
            RemoveDuplicates(fetchPage ? data : playListVideo.concat(data))
          );
          setPageNo(fetchPage ? fetchPage : pageNo + 1);
          setRefresh(!refresh);
          setIsNewSearch(false);
          setScrollHashMap(data.length == 20);
          setScrollLast(true);
        } else {
          fetchPage && setPlayListVideo([]);
          setPageNo(0);
          setIsLoading(false);
          setScrollHashMap(false);
          setScrollLast(false);
          setRefresh(!refresh);
        }
      } else {
      }
    } catch (err) {
      setIsLoading(false);
      setScrollLast(false);
      setScrollHashMap(false);

      console.log("err: ", err);
    }
    isOpenSortBy && closeModalSortBy();
  }

  const handleVideoClick = (item) => {
    navigate(`/videoPlayPage/${item.id}`);
  };

  const addFavorite = async (item, index) => {
    if (auth) {
      const ind= playListVideo.findIndex((value)=>value.id == item.id)
      playListVideo[ind].myFavourite = item.myFavourite ? false : true;
      try {
        const { data } = await apiCall("POST", `/my-favourites/${item.id}`);
        if (data) {
          setRefresh(!refresh);
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
      }
    } else {
    }
  };
  const changeSequesnce = async (changedSerialNo, changedWithContentId) => {
    if (auth) {
      const params = {
        myPlayList: { id: playListDetails.id },
        serialNumber: changedSerialNo,
        contentId: changedWithContentId,
      };
      try {
        const { data } = await apiCall(
          "PUT",
          `my/playlist/contents/updateSerialNO`,
          params
        );
        if (data) {
          setRefresh(!refresh);
          return true;
        } else {
          setRefresh(!refresh);
          return false;
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
        return true;
      }
    } else {
      return false;
    }
  };
  const removeFromPlaylist = async (item, index) => {
    if (auth) {
      try {
        const { status } = await apiCall(
          "DELETE",
          `${ApiEndPoint.REMOVECONTENTFROMPLAYLIST}/${item.id}/playlistId/${playListDetails.id}`
        );
        if (status === 200) {
          setRefresh(!refresh);

          const removePlayListVideo = playListVideo.filter(
            (x) => x.id != item.id
          );

          setPlayListVideo(removePlayListVideo);
          if (removePlayListVideo.length <= 1) {
            fetchData();
          }
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.error("err: ", err);
      }
    } else {
      toast.error("Something went wrong");
    }
  };

  const videoWatchLater = async (item, index) => {
    console.log("hit", item, index);
    if (auth) {
      const ind= playListVideo.findIndex((value)=>value.id == item.id)
      playListVideo[ind].watchLater = item.watchLater ? false : true;
      try {
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.VIDEOWATCHLATER}/${item.id}`
        );
        if (data) {
          setRefresh(!refresh);
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
      }
    } else {
    }
  };

  const removeWatchLater = async (item, index) => {
    if (auth) {
      const ind= playListVideo.findIndex((value)=>value.id == item.id)
      playListVideo[ind].watchLater = item.watchLater ? false : true;
      try {
        const { data } = await apiCall(
          "DELETE",
          `${ApiEndPoint.WATCHLATEDREMOVE}/${item.id}`
        );
        if (data) {
          setRefresh(!refresh);
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
      }
    } else {
    }
  };

  function takeSubcription(params) {
    params?.subscriptionName
      ? confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="card">
                <div className="info__padding">
                  <h3>
                    {`Please Subscribe to ${params?.subscriptionName} plan to play this video. `}
                  </h3>
                  <h3>{!auth ? "Sign In To Subscribe..." : ""}</h3>
                </div>
                {!auth ? (
                  <>
                    <div className="button__group">
                      <Button
                        onClick={() => {
                        }}
                        variant="contained"
                        className="signBtnHome"
                      >
                        {/* <img className="g-icon" src={Google}></img> */}
                        Sign-in
                      </Button>
                      <button
                        className="no-btn"
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="button__group">
                      <Button
                        onClick={() => {
                          navigate("/subscriptionPlanList");
                          onClose();
                        }}
                        variant="contained"
                        className="subscriptionBtn"
                      >
                        {/* <img className="g-icon" src={Google}></img> */}
                        Subscribe
                      </Button>
                      <button className="no-btn" onClick={onClose}>
                        Cancel
                      </button>
                    </div>
                  </>
                )}
              </div>
            );
          },
        })
      : confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="card">
                <div className="info__padding">
                  <h3>{`This Video is not yet available`}</h3>
                </div>
                <div className="button__group">
                  <button className="button btn-ok" onClick={onClose}>
                    Ok
                  </button>
                </div>
              </div>
            );
          },
        });
  }

  function playListVideoSearchHandle(e) {
    if (!emojiRegex.test(e.target.value)) {
      if (e.target.value) {
        setSearchText(e.target.value);
      } else {
        setSearchText(null);
      }
    }
  }

  const openModalSortBy = () => {
    setIsOpenSortBy(true);
  };

  const closeModalSortBy = () => {
    setIsOpenSortBy(false);
  };
  const handleSearchClick = () => {
    setPageNo(0);
    setIsNewSearch(true);
  };

  const addVideoBtn = (id) => {
    navigate("/myCreatePlayListSelectVideo", { state: { id: id } });
  };

  const handlePlayListPlay = () => {
    navigate(`/playListPlayPage/${playListDetails.id}`, {
      state: { documentData: [], type: "user" },
    });
  };
  const handleRadioChange = (event) => {
    setSelectedFilterOp(event.target.value);
  };
  function capitalizeFirstWord(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const handleOnDragStart = (result) => {
    const { source } = result;
    const draggedIndex = source.index;
    setDraggedIndex(draggedIndex); // For visual cue
  };
  const handleOnDragEnd = async ({ oldIndex, newIndex }) => {
    // console.log(oldIndex, newIndex, "impppppppppppppppppppppp");
    // console.log(
    //   playListVideo[oldIndex],
    //   playListVideo[newIndex],
    //   "moved",
    //   "replaced"
    // );
    setDraggedIndex(null);
    const movedSerialNo = newIndex + 1;
    const replacedContentId = playListVideo[oldIndex].id;
    const newPlaylist = [...playListVideo];
    const [movedItem] = newPlaylist.splice(oldIndex, 1);
    newPlaylist.splice(newIndex, 0, movedItem);
    const flag = await changeSequesnce(movedSerialNo, replacedContentId);
    if (flag) {
      setPlayListVideo(newPlaylist);
    }
  };

  return (
    <>
      <div className="individualUserPlaylist">
        <InfiniteScroll
          dataLength={playListVideo?.length}
          next={() => fetchMoreData()}
          hasMore={scrollHashMap}
          loader={
            scrollLast ? (
              isLoading && (
                <center>
                  <img
                    src={loader}
                    alt="loading..."
                    style={{ height: 25, width: 25, marginTop: 20 }}
                  />
                </center>
              )
            ) : (
              <p className="subtitle fancy">
                <span>End of results</span>
              </p>
            )
          }
        >
          <div className="main-banner">
            <img
              className={`img-banner ${
                playListDetails.thumbnail
                  ? getImageNameFromResolution(playListDetails?.thumbnail) !=
                      "maxresdefault" && "image-resolution"
                  : "image-resolution"
              }`}
              src={
                playListDetails.thumbnail
                  ? playListDetails.thumbnail
                  : AllImages.HomeBanner
              }
              alt="Banner"
              title="Banner"
            />
            <div className="top-header">
              <span className="backArrow" onClick={() => navigate(-1)}>
                {" "}
                <img
                  src={AllImages.BackArrow}
                  alt="Back Arrow"
                  title="Back Arrow"
                ></img>
              </span>
            </div>
            <div className="banner-content">
              <p className="banner-title f-poppins">
                {playListDetails.playListName}
              </p>
            </div>
            <img
              onClick={() => handlePlayListPlay()}
              className="ind-list-play-icon-img"
              src={AllImages.PlayIconBig}
              alt="Play Icon"
              title="Play Icon"
            />
          </div>
          <div className="play-outer-wrap">
            <div className="individualHeader">
              <div className="search-wrap">
                <div className="search-input">
                  <span className="search-icon" onClick={fetchData}>
                    {isLoading ? (
                      <img
                        src={loader}
                        alt="loading..."
                        style={{ height: 15, width: 15 }}
                      />
                    ) : (
                      <img src={AllImages.Search} alt="Search" title="Search" />
                    )}
                  </span>
                  <input
                    className="f-poppins"
                    type="text"
                    placeholder="Search for Video"
                    onChange={(e) => playListVideoSearchHandle(e)}
                    onKeyUp={handleKeyPress}
                  />
                </div>
                <div className="searchIcon-outer">
                  <img
                    src={AllImages.SearchIcon}
                    alt="Search"
                    title="Search"
                    className="searchIcon"
                  />
                </div>
                {/* <div className="filter-icon" onClick={() => openModalSortBy()}>
                  <img
                    src={AllImages.SearchToggle}
                    alt="Search"
                    title="Search"
                  />
                </div> */}
                <div onClick={() => addVideoBtn(playListDetails.id)}>
                  <p className="header-create-btn">Add</p>
                </div>
              </div>
            </div>
            {/* <div className="custom-row">
              {playListVideo.length > 0
                ? playListVideo?.map((item, index) => (
                    <div className=" custom-col-4 custom-col" key={index}>
                      <div className="play-card-wrap">
                        <div
                          className="play-inside-wrap"
                          style={{ position: "relative" }}
                        >
                          {item.videoUrl ? (
                            <div
                              className="play-list-image"
                              onClick={() => handleVideoClick(item)}
                            >
                              <img
                                className={`img-banner ${
                                  getImageNameFromResolution(
                                    item?.thumbnails
                                  ) != "maxresdefault" && "image-resolution"
                                }`}
                                src={
                                  item?.thumbnails
                                    ? `${item?.thumbnails}`
                                    : `${AllImages.DefaultThumbnail}`
                                }
                                key={item.id}
                                alt="Card Image"
                                title="Card Image"
                              />
                              <img
                                className="play-play-icon"
                                src={AllImages.PlayIcon}
                                alt="Play Icon"
                                title="Play Icon"
                              />
                            </div>
                          ) : (
                            <div
                              className="play-list-image"
                              style={{ position: "static" }}
                            >
                              <div onClick={() => takeSubcription(item)}>
                                <img
                                  className={`img-banner ${
                                    getImageNameFromResolution(
                                      item.thumbnails
                                    ) != "maxresdefault" && "image-resolution"
                                  }`}
                                  src={
                                    item?.thumbnails
                                      ? `${item?.thumbnails}`
                                      : `${AllImages.DefaultThumbnail}`
                                  }
                                  alt="Card Image"
                                  title="Card Image"
                                />
                                <div className="layerImage"></div>
                              </div>
                              <div className="star1 badgestarInvisible">
                                <div className="LikeCount badgeShow">
                                  <i
                                    className="fa fa-lock"
                                    onClick={() => takeSubcription(item)}
                                  ></i>
                                </div>
                                <span className="tooltiptext">
                                  {"Subscribe for more Videos"}
                                </span>
                              </div>
                            </div>
                          )}
                          <div className="play-card-content-item">
                            <div className="play-video-content">
                              <div>
                                <p className="play-title-text f-poppins">
                                  {item.videoTitle}
                                </p>
                                <p className="play-text-hrs f-poppins">
                                  {item?.videoDuration
                                    ? item?.videoDuration
                                    : null}{" "}
                                  {item?.videoDuration ? "hrs" : null}{" "}
                                </p>
                              </div>
                              <div className="mt-10 userPlaylistOptions">
                                <div>
                                  <img
                                    onClick={() =>
                                      item?.watchLater
                                        ? RemoveWatchLaterVideo(item, index)
                                        : videoWatchLater(item, index)
                                    }
                                    src={
                                      item?.watchLater
                                        ? AllImages.SelectedTimer
                                        : AllImages.Timer
                                    }
                                    alt="Timer"
                                    title="Timer"
                                  ></img>
                                  <img
                                    src={
                                      item?.myFavourite
                                        ? AllImages.Favorite_sel
                                        : AllImages.Fav
                                    }
                                    onClick={() => addFavorite(item, index)}
                                    alt="Favourite"
                                    title="Favourite"
                                  ></img>
                                  <RWebShare
                                    data={{
                                      // text: "Like humans, flamingos make friends for life",
                                      url: `${VideoPlaylistShareUrl}/videoPlayPage/${item.id}`,
                                      title: "Video Share",
                                    }}
                                    onClick={() => console.log("share")}
                                  >
                                    <img
                                      src={AllImages.Copy}
                                      alt="Copy"
                                      title="Copy"
                                    ></img>
                                  </RWebShare>
                                </div>
                                <div
                                  onClick={() =>
                                    removeFromPlaylist(item, index)
                                  }
                                  className="playlistRemoveContentBtn"
                                >
                                  <p>REMOVE</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                
                  ))
                : !isLoading && <NoDataAvailable />}
            </div> */}

            {/* {playListVideo && playListVideo.length > 0 && (
              <DragDropContext
                // onDragStart={handleOnDragStart}
                onDragEnd={handleOnDragEnd}
                
              >
                <Droppable droppableId="playlistVideo">
                  {(droppableProvided) => (
                    <div
                      ref={droppableProvided.innerRef}
                      className="custom-row"
                      {...droppableProvided.droppableProps}
                    >
                      {playListVideo.map((item, index) => (
                        <Draggable

                          draggableId={item.id.toString()}
                          index={index}
                          key={item.id}
                        >
                          {(draggableProvided) => (
                            <div
                              ref={draggableProvided.innerRef}
                              className={`custom-col-4 custom-col ${
                                draggedIndex === index ? "hidden" : ""
                              }`}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                            >
                              <div className="play-card-wrap">
                                <div
                                  className="play-inside-wrap"
                                  style={{ position: "relative" }}
                                >
                                  {item.videoUrl ? (
                                    <div
                                      className="play-list-image"
                                      onClick={() => handleVideoClick(item)}
                                    >
                                      <img
                                        className={`img-banner ${
                                          getImageNameFromResolution(
                                            item?.thumbnails
                                          ) != "maxresdefault" &&
                                          "image-resolution"
                                        }`}
                                        src={
                                          item?.thumbnails
                                            ? `${item?.thumbnails}`
                                            : `${AllImages.DefaultThumbnail}`
                                        }
                                        key={item.id}
                                        alt="Card Image"
                                        title="Card Image"
                                      />
                                      <img
                                        className="play-play-icon"
                                        src={AllImages.PlayIcon}
                                        alt="Play Icon"
                                        title="Play Icon"
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className="play-list-image"
                                      style={{ position: "static" }}
                                    >
                                      <div
                                        onClick={() => takeSubcription(item)}
                                      >
                                        <img
                                          className={`img-banner ${
                                            getImageNameFromResolution(
                                              item.thumbnails
                                            ) != "maxresdefault" &&
                                            "image-resolution"
                                          }`}
                                          src={
                                            item?.thumbnails
                                              ? `${item?.thumbnails}`
                                              : `${AllImages.DefaultThumbnail}`
                                          }
                                          alt="Card Image"
                                          title="Card Image"
                                        />
                                        <div className="layerImage"></div>
                                      </div>
                                      <div className="star1 badgestarInvisible">
                                        <div className="LikeCount badgeShow">
                                          <i
                                            className="fa fa-lock"
                                            onClick={() =>
                                              takeSubcription(item)
                                            }
                                          ></i>
                                        </div>
                                        <span className="tooltiptext">
                                          {"Subscribe for more Videos"}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  <div className="play-card-content-item">
                                    <div className="play-video-content">
                                      <div>
                                        <p className="play-title-text f-poppins">
                                          {item.videoTitle}
                                        </p>
                                        <p className="play-text-hrs f-poppins">
                                          {item?.videoDuration
                                            ? item?.videoDuration
                                            : null}{" "}
                                          {item?.videoDuration ? "hrs" : null}{" "}
                                        </p>
                                      </div>
                                      <div className="mt-10 userPlaylistOptions">
                                        <div>
                                          <img
                                            onClick={() =>
                                              item?.watchLater
                                                ? RemoveWatchLaterVideo(
                                                    item,
                                                    index
                                                  )
                                                : videoWatchLater(item, index)
                                            }
                                            src={
                                              item?.watchLater
                                                ? AllImages.SelectedTimer
                                                : AllImages.Timer
                                            }
                                            alt="Timer"
                                            title="Timer"
                                          ></img>
                                          <img
                                            src={
                                              item?.myFavourite
                                                ? AllImages.Favorite_sel
                                                : AllImages.Fav
                                            }
                                            onClick={() =>
                                              addFavorite(item, index)
                                            }
                                            alt="Favourite"
                                            title="Favourite"
                                          ></img>
                                          <RWebShare
                                            data={{
                                              // text: "Like humans, flamingos make friends for life",
                                              url: `${VideoPlaylistShareUrl}/videoPlayPage/${item.id}`,
                                              title: "Video Share",
                                            }}
                                            onClick={() => console.log("share")}
                                          >
                                            <img
                                              src={AllImages.Copy}
                                              alt="Copy"
                                              title="Copy"
                                            ></img>
                                          </RWebShare>
                                        </div>
                                        <div
                                          onClick={() =>
                                            removeFromPlaylist(item, index)
                                          }
                                          className="playlistRemoveContentBtn"
                                        >
                                          <p>REMOVE</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {droppableProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )} */}

            {playListVideo && (
              <SortableList
                items={playListVideo}
                onSortEnd={handleOnDragEnd}
                handleVideoClick={handleVideoClick}
                takeSubcription={takeSubcription}
                removeWatchLater={removeWatchLater}
                videoWatchLater={videoWatchLater}
                addFavorite={addFavorite}
                removeFromPlaylist={removeFromPlaylist}
                isLoading={isLoading}
                axis="xy"
                useWindowAsScrollContainer={true}
                pressDelay={200}
                // offsetValue={`30%`}
              />
            )}

          </div>
        </InfiniteScroll>

        {isOpenSortBy && (
          <Dialog
            open={openModalSortBy}
            className="sortBy"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent
              style={{
                overflowY: "hidden",
                overflowX: "hidden",
              }}
            >
              <div className="qu-history-modal-msg-content">
                <p className="qu-history-modal-msg-text">{"Sort by"}</p>
                <span
                  className="qu-history-modal-close-button"
                  onClick={() => closeModalSortBy()}
                >
                  &times;
                </span>
              </div>
              <ul>
                {Object.keys(SortingOptions).map((key) => (
                  <li key={key}>
                    <label className="customRadio">
                      {SortingOptions[key] === "A_TO_Z" ||
                      SortingOptions[key] === "Z_TO_A"
                        ? key.replace(/_/g, " ")
                        : capitalizeFirstWord(
                            key.replace(/_/g, " ").toLowerCase()
                          )}
                      <input
                        type="radio"
                        value={SortingOptions[key]}
                        checked={selectedFilterOp === SortingOptions[key]}
                        onChange={handleRadioChange}
                        name="radio"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                ))}
              </ul>
            </DialogContent>
          </Dialog>
        )}
      </div>
    </>
  );
};
export default IndividualPlayList;
