import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./style.css";
import { AllImages } from "../../../utils/constants";
import { Table } from "reactstrap";

function Artist() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <section class="artist-page">
      {/* <div class="top">
        <img src={AllImages?.SparshTopImg} class="topImg" />
        <p class="label">sparsh international</p>
      </div> */}
      <div className="artist-page-title-outer">
        <div className="artist-page-title">
          <span className="my-favorites-d-flex" >
            <img
              className="my-favorites-c-pointer"
              src={AllImages.YellowArrow}
              alt="Back Arrow"
              title="Back Arrow"
            ></img>
            {/* <p className="my-favorites-title">All Bhakti Pads</p> */}
            <div class="top">
              <img src={AllImages?.Album3} class="topImg" />
              <div class="padDetail">
                <p class="category">Album</p>
                <p class="label">sparsh international</p>
                <div class="padDetailInner">
                  <p class="year">2021</p>
                  <p class="dot">&nbsp;</p>
                  <p class="time">00.53hrs</p>
                  <p class="dot">&nbsp;</p>
                  <p class="count">7 bhakti pads</p>
                </div>
              </div>
            </div>
          </span>
        </div>
        {/*----- Search Bar Start -----*/}

        <div className="list-of-play-search">
          <div className="p-list-search-wrap">
            <div className="search-input">
              {/* onClick={fetchData}  */}
              <span className="search-icon">
                <img src={AllImages.Search} alt="Search" title="Search" />
                {/* {isLoading ? (
                  <img
                    src={loader}
                    alt="loading..."
                    style={{ height: 15, width: 15 }}
                  />
                ) : (
                  <div className="searchTooltip">
                    <img src={AllImages.Search} alt="Search" title="Search" />
                    <span className="tooltiptext">{"Search"}</span>
                  </div>
                )} */}
              </span>
              <input
                className="f-poppins"
                type="text"
                placeholder="Search for Video"
              // onChange={(e) => handleSearchPlaylist(e)}
              // onKeyUp={handleKeyPress}
              />
            </div>
            <div className="searchIcon-outer searchTooltip">
              <img
                src={AllImages.SearchIcon}
                alt="Search"
                title="Search"
                className="searchIcon"
              />
              <span className="tooltiptext">{"Search"}</span>
            </div>
            <div
              className="filter-icon sortbyTooltip"
            // onClick={() => openModalSortBy()}
            >
              <img src={AllImages.SearchToggle} alt="Search" title="Search" />
              <span className="tooltiptext">{"Sort By"}</span>
            </div>
          </div>
        </div>

        {/*----- Search Bar End -----*/}
      </div>
      {/* <div class="mainTitleOuter">
        <h1 class="mainTitle">List of Albums</h1>
        <p class="viewMore">view more</p>
      </div> */}
      <Table responsive striped>
        <thead class="mobHide">
          <tr>
            <th>No.</th>
            <th>Title</th>
            <th class="none">Album</th>
            <th class="none">Duration</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="mobHide">
          <tr>
            <td>
              <div class="srNo">
                <p class="no mobHide">1</p>
                <img src={AllImages?.Bhakti} class="bhaktiImg" />
              </div>
            </td>
            <td>
              <div class="titleSection" onClick={() => navigate('/individualBhaktipad')}>
                <p class="title">Atmaprapt Sadguru Ne Pranaam</p>
                <p class="subTitle">Rachit Hemani, Shree Prem Acharyaji, Urvi Shah</p>
              </div>
            </td>
            <td class="none"><p class="album">Purusharth Prerak Vachaano</p></td>
            <td class="none"><p class="duration">10:43</p></td>
            <td>
              <div class="actions">
                <img src={AllImages?.Favorite_sel} class="mobHide" />
                <img src={AllImages?.TablePlus} class="mobHide" />
                <div class="popupOuter">
                  <img src={AllImages?.More} class="more" />
                  <div class="actionPopup">
                    <div class="outer">
                      <img src={AllImages?.Next} class="next" />
                      <p class="label">Play Next</p>
                    </div>
                    <div class="outer">
                      <img src={AllImages?.CopyGray} class="more" />
                      <p class="label">Copy Link</p>
                    </div>
                  </div>

                  <div class="actionPopupMob">
                    <div class="outer tabHide">
                      <img src={AllImages?.FavouriteOutline} class="fav" />
                      <p class="label">Add to Favourites</p>
                    </div>
                    <div class="outer tabHide">
                      <img src={AllImages?.TablePlus} class="plus" />
                      <p class="label">Add ro Playlist</p>
                    </div>
                    <div class="outer">
                      <img src={AllImages?.PlayNextAction} class="next" />
                      <p class="label">Play Next</p>
                    </div>
                    {/* <div class="outer">
                      <img src={AllImages?.Song} class="artist" />
                      <p class="label">View Artists</p>
                    </div> */}
                    <div class="outer">
                      <img src={AllImages?.CopyGray} class="copy" />
                      <p class="label">Copy Link</p>
                    </div>
                  </div>

                  {/* <div class="detailsPopupMob">
                    <div class="outer">
                      <p class="label">Main Artist</p>
                      <p class="title">SPARSH INTERNATIONAL</p>
                    </div>
                    <div class="outer">
                      <p class="label">Featured Artist</p>
                      <p class="title">Chaitali Shah</p>
                    </div>
                    <div class="outer">
                      <p class="label">Composer</p>
                      <p class="title">SPARSH INTERNATIONAL</p>
                    </div>
                  </div> */}

                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="srNo">
                <p class="no mobHide">2</p>
                <img src={AllImages?.Bhakti} class="bhaktiImg" />
              </div>
            </td>
            <td>
              <div class="titleSection">
                <p class="title">He Prabhu Maara Jagat Ni Vismruti Karaavo</p>
                <p class="subTitle">Shree Prem Acharyaji, Priyal Parikh, Urvi Shah, Kunal Doshi, Rachit Hemani</p>
              </div>
            </td>
            <td class="none"><p class="album">Purusharth Prerak Vachaano</p></td>
            <td class="none"><p class="duration">10:43</p></td>
            <td>
              <div class="actions">
                <img src={AllImages?.Favorite_sel} class="mobHide" />
                <img src={AllImages?.TableMinus} class="mobHide" />
                <img src={AllImages?.More} class="more" />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="srNo">
                <p class="no mobHide">2</p>
                <img src={AllImages?.Bhakti} class="bhaktiImg" />
              </div>
            </td>
            <td>
              <div class="titleSection">
                <p class="title">Saptapurush Ne Ratata Ratata Jeev Paame Aatam Gyaan Re</p>
                <p class="subTitle">Shree Prem Acharyaji, Rachit Hemani, Urvi Shah </p>
              </div>
            </td>
            <td class="none"><p class="album">Purusharth Prerak Vachaano</p></td>
            <td class="none"><p class="duration">10:43</p></td>
            <td>
              <div class="actions">
                <img src={AllImages?.Favorite_sel} class="mobHide" />
                <img src={AllImages?.TablePlus} class="mobHide" />
                <img src={AllImages?.More} class="more" />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="srNo">
                <p class="no mobHide">4</p>
                <img src={AllImages?.Bhakti} class="bhaktiImg" />
              </div>
            </td>
            <td>
              <div class="titleSection">
                <p class="title">He Prabhu Maara Jagat Ni Vismruti Karaavo</p>
                <p class="subTitle">Shree Prem Acharyaji, Priyal Parikh, Urvi Shah, Kunal Doshi, Rachit Hemani</p>
              </div>
            </td>
            <td class="none"><p class="album">Purusharth Prerak Vachaano</p></td>
            <td class="none"><p class="duration">10:43</p></td>
            <td>
              <div class="actions">
                <img src={AllImages?.Favorite_sel} class="mobHide" />
                <img src={AllImages?.TableMinus} class="mobHide" />
                <img src={AllImages?.More} class="more" />
              </div>
            </td>
          </tr>
        </tbody>
      </Table>

      <p class="albumTitle">Appears on Albums</p>
      <div class="albumOuter">
        <div class="album" onClick={() => navigate('/playlist')}>
          <div class="albumTop">
            <img src={AllImages?.Album1} class="albumImg" />
            <div class="albumHover">
              <div class="albumHoverInner">
                {/* <img src={AllImages?.Time} class="albumInnerImg" /> */}
                <img src={AllImages?.Heart} class="albumInnerImg" />
                <img src={AllImages?.Copy} class="albumInnerImg" />
              </div>
              <div class="albumInnerPlayIconOuter">
                <img src={AllImages?.PlayIconBig} class="albumInnerPlayIcon" />
              </div>
            </div>
          </div>
          <div class="albumBottom">
            <p class="name">Divine Melodies, Vol.1</p>
            <div class="desc">
              <p class="time">00:41 hrs</p>
              <p class="dot">&nbsp;</p>
              <p class="count">06 bhakti pads</p>
            </div>
          </div>
        </div>
        <div class="album" onClick={() => navigate('/playlist')}>
          <div class="albumTop">
            <img src={AllImages?.Album2} class="albumImg" />
            <div class="albumHover">
              <div class="albumHoverInner">
                {/* <img src={AllImages?.Time} class="albumInnerImg" /> */}
                <img src={AllImages?.Heart} class="albumInnerImg" />
                <img src={AllImages?.Copy} class="albumInnerImg" />
              </div>
              <div class="albumInnerPlayIconOuter">
                <img src={AllImages?.PlayIconBig} class="albumInnerPlayIcon" />
              </div>
            </div>
          </div>
          <div class="albumBottom">
            <p class="name">Sparsh Singles 2019-2020</p>
            <div class="desc">
              <p class="time">00:41 hrs</p>
              <p class="dot">&nbsp;</p>
              <p class="count">06 bhakti pads</p>
            </div>
          </div>
        </div>
        <div class="album" onClick={() => navigate('/playlist')}>
          <div class="albumTop">
            <img src={AllImages?.Album2} class="albumImg" />
            <div class="albumHover">
              <div class="albumHoverInner">
                {/* <img src={AllImages?.Time} class="albumInnerImg" /> */}
                <img src={AllImages?.Heart} class="albumInnerImg" />
                <img src={AllImages?.Copy} class="albumInnerImg" />
              </div>
              <div class="albumInnerPlayIconOuter">
                <img src={AllImages?.PlayIconBig} class="albumInnerPlayIcon" />
              </div>
            </div>
          </div>
          <div class="albumBottom">
            <p class="name">Sparsh Singles 2018-2019</p>
            <div class="desc">
              <p class="time">00:41 hrs</p>
              <p class="dot">&nbsp;</p>
              <p class="count">06 bhakti pads</p>
            </div>
          </div>
        </div>
        <div class="album" onClick={() => navigate('/playlist')}>
          <div class="albumTop">
            <img src={AllImages?.Album3} class="albumImg" />
            <div class="albumHover">
              <div class="albumHoverInner">
                {/* <img src={AllImages?.Time} class="albumInnerImg" /> */}
                <img src={AllImages?.Heart} class="albumInnerImg" />
                <img src={AllImages?.Copy} class="albumInnerImg" />
              </div>
              <div class="albumInnerPlayIconOuter">
                <img src={AllImages?.PlayIconBig} class="albumInnerPlayIcon" />
              </div>
            </div>
          </div>
          <div class="albumBottom">
            <p class="name">Sakhi Ri Mai To Govind Paayo</p>
            <div class="desc">
              <p class="time">00:41 hrs</p>
              <p class="dot">&nbsp;</p>
              <p class="count">06 bhakti pads</p>
            </div>
          </div>
        </div>
        <div class="album" onClick={() => navigate('/playlist')}>
          <div class="albumTop">
            <img src={AllImages?.Album4} class="albumImg" />
            <div class="albumHover">
              <div class="albumHoverInner">
                {/* <img src={AllImages?.Time} class="albumInnerImg" /> */}
                <img src={AllImages?.Heart} class="albumInnerImg" />
                <img src={AllImages?.Copy} class="albumInnerImg" />
              </div>
              <div class="albumInnerPlayIconOuter">
                <img src={AllImages?.PlayIconBig} class="albumInnerPlayIcon" />
              </div>
            </div>
          </div>
          <div class="albumBottom">
            <p class="name">Sahib Ka Ghar</p>
            <div class="desc">
              <p class="time">00:41 hrs</p>
              <p class="dot">&nbsp;</p>
              <p class="count">06 bhakti pads</p>
            </div>
          </div>
        </div>
      </div>



    </section>
  );
}
export default Artist;
