// web
import React, { useEffect, useContext, useState, useLayoutEffect } from "react";
import "./App.css";
import ApiEndPoint from "./utils/apiEndPoints";
import { setDefaultHeader, apiCall } from "./utils/httpClient";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  useLocation,
} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./components/Context/context";
import Header from "./components/common/header/header";
import SideMenu from "./components/common/SideMenu";
// import Header from './components/common/header_old/header';
import Footer from "./components/common/footer";
// import Home from "./components/home";


import {
  UserloggedContext,
  userDetailsContext,
  LoaderContext,
} from "./components/Context/UserContext";
import { Authentication } from "./utils/constants";
import Loder from "./components/common/loder";

//second phase
import Dashboard from "./components/Pages/Dashboard";
import Sparsh from "./components/Pages/Sparsh";
import AlbumList from "./components/Pages/AlbumList";
import AllBhaktiPads from "./components/Pages/AllBhaktiPads";

import BhaktiPadsTrackList from "./components/Pages/BhaktiPadsTrackList";
import PopularBhaktiPads from "./components/Pages/PopularBhaktiPads";
import NewReleasePads from "./components/Pages/NewReleasePads";
import IndividualBhaktipad from "./components/Pages/IndividualBhaktipad";
import Artist from "./components/Pages/Artist";
import Playlist from "./components/Pages/Playlist";
import IndividualPlayList from "./components/Pages/IndividualPlayList";
import AllPlayList from "./components/Pages/AllPlayList";
import WatchLater from "./components/Pages/WatchLater";
import MyFavorites from "./components/Pages/MyFavorites";
import IndividualUserPlayList from "./components/Pages/IndividualUserPlayList";
import VideoPlayPage from "./components/Pages/VideoPlayPage";
import MySpace from "./components/Pages/MySpace";
import CreatedPlaylist from "./components/Pages/CreatedPlaylist";
import SharedPlaylist from "./components/Pages/SharedPlaylist";
import QuestionHistory from "./components/Pages/QuestionHistory";
import DownloadedEbooks from "./components/Pages/DownloadedEbooks";
import EBooks from "./components/Pages/EBooks";
import PlayListSharedAccept from "./components/Pages/PlayListSharedAccept";
import ViewMore from "./components/Pages/ViewMore";
import AdvanceSearch from "./components/Pages/AdvanceSearch";
import ActiveSubscriptionPlan from "./components/Pages/ActiveSubscriptionPlan";
import SubscriptionPlanList from "./components/Pages/SubscriptionPlanList";
import VideosIndex from "./components/Pages/VideosIndex";
import AboutUs from "./components/Pages/AboutUs";
import ContactUs from "./components/Pages/ContactUs";
import FAQS from "./components/Pages/FAQS";
import EditProfile from "./components/Pages/EditProfile";
import Profile from "./components/Pages/Profile";
import RecentWatchedVideos from "./components/Pages/RecentWatchedVideos";
import LiveSatsang from "./components/Pages/LiveSatsang";
import SurpriseMe from "./components/Pages/SurpriseMe";
import MyCreatePlayListSelectVideo from "./components/Pages/MyCreatePlayListSelectVideo";
import MyCreatePlayListVideoAddBtnSuggested from "./components/Pages/MyCreatePlayListVideoAddBtnSuggested";
import MyCreatePlayListTrackAddBtnSuggested from "./components/Pages/MyCreatePlayListTrackAddBtnSuggested";
import PlayListPlayPage from "./components/Pages/PlayListPlayPage";
import FileViewer from "./components/Pages/FileViewer";
import EBookSeries from "./components/Pages/EBookSeries";
import Loader from "./assets/images/loading_wbg.gif";
import UnderMaintenance from "components/common/UnderMaintenancePage/UnderMaintenance";



//third phase
import MusicPlayer from "./components/common/MusicPlayer"
import CreatedPlaylistForAudio from "./components/Pages/CreatedPlaylistForAudio";
import AudioPlaylist from "components/Pages/AudioPlaylist";
import AudioPlaylistShared from "components/Pages/SharedPlaylistBhaktiPad";
import registerServiceWorker from './NotificationService';
import PrivacyPolicy from "components/Pages/PrivacyPolicy";
import CurrentlyPlayingAudioPlaylist from "components/Pages/CurrentlyPlayingPlaylist";



// import { generateSubscriptionObject } from '../public/sw'; // Adjust the import path based on your project structure


function RoutesFile() {
  let isLogged = false;
  const [footer, setFooter] = useState(true);
  const [IsLoading, setIsLoading] = useContext(LoaderContext);
  const [auth, setAuth] = useContext(UserloggedContext);
  const [userDetails, setUserDetails] = useContext(userDetailsContext);
  const [isPlayerVisible, setIsPlayerVisible] = useState(true);

  // const Alert = useAlert()
  // const navigate = useNavigate();
  const initialLoginState = {
    isLoading: true,
    userName: null,
    userToken: null,
  };

  const loginReducer = (prevState, action) => {
    switch (action.type) {
      case "RETRIEVE_TOKEN":
        return {
          ...prevState,
          userToken: action.token,
          isLoading: false,
        };
      case "LOGIN":
        return {
          ...prevState,
          userName: action.id,
          userToken: action.token,
          isLoading: false,
        };
      case "LOGOUT":
        return {
          ...prevState,
          userName: null,
          userToken: null,
          isLoading: false,
        };
      case "REGISTER":
        return {
          ...prevState,
          userName: action.id,
          userToken: action.token,
          isLoading: false,
        };
    }
  };
  const [loginState, dispatch] = React.useReducer(
    loginReducer,
    initialLoginState
  );
  const authContext = React.useMemo(
    () => ({
      signInFun: async (foundUser) => {
        const userToken = String(foundUser[0].userToken);
        const userName = foundUser[0].username;
        try {
          await localStorage.setItem("userToken", userToken);
          // window.location.assign('/')
          window.location.reload("/");
          // navigate('/')
        } catch (e) {
          console.log(e);
        }
        // console.log('user token: ', userToken);
        dispatch({ type: "LOGIN", id: userName, token: userToken });
      },
      signOutFun: async (user_id) => {
        try {
          await localStorage.removeItem("userData");
          await localStorage.removeItem("userToken");
          await localStorage.removeItem("searchData");
          window.location.assign("/");
        } catch (e) {
          console.log(e);
        }
        dispatch({ type: "LOGOUT" });
      },
    }),
    []
  );

  async function setSubscriptionDetails() {
    try {
      const subscription = localStorage.getItem("subscriptionObject");

      if (subscription) {
        const { data } = await apiCall(
          "post",
          ApiEndPoint.SAVESUBSCRIPTION,
          subscription
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function removeSubscriptionDetails() {
    try {
      const subscription = localStorage.getItem("subscriptionObject");

      if (subscription) {
        const { data } = await apiCall(
          "delete",
          ApiEndPoint.DELETESUBSCRIPTION,
          subscription
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  useLayoutEffect(() => {
    setFooter(false);
    setTimeout(() => {
      setFooter(true);
    }, 1500);
    getToken();
    setTimeout(async () => {
      let userToken;
      userToken = null;
      try {
        userToken = await localStorage.getItem("userToken");
      } catch (e) {
        console.log(e);
      }
      dispatch({ type: "RETRIEVE_TOKEN", token: userToken });
    }, 1000);
  }, []);

  async function getUserDetails() {
    try {
      const Auth = await localStorage.getItem("userToken");
      if (Auth == "01") {
        const { data } = await apiCall(
          "GET",
          ApiEndPoint.GETSUBCRIPTIONDETAILS
        );
        if (data) {
          setUserDetails(data);
          // registerServiceWorker();
          // setSubscriptionDetails();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getToken() {
    try {
      const Auth = await Authentication();
      const userData = await localStorage.getItem("userData");
      if (Auth == "01") {
        setAuth(Auth);
        await setDefaultHeader("Authorization", "Bearer" + " " + userData);
        getUserDetails();
      }
    } catch (error) {
      console.log(error);
    }
  }

  if (loginState.isLoading) {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Loder />
        {/* <img
          className="logo"
          src={logo}
        /> */}
      </div>
    );
  }
  const maintenanceStacks = () => {
    return (
      <AuthContext.Provider value={authContext}>
        {auth &&
          (userDetails?.user?.id == process.env.REACT_APP_ADMIN1_USER_ID ||
            userDetails?.user?.id == process.env.REACT_APP_ADMIN2_USER_ID ||
            userDetails?.user?.id == process.env.REACT_APP_ADMIN3_USER_ID ||
            userDetails?.user?.id == process.env.REACT_APP_ADMIN4_USER_ID ||
            userDetails?.user?.id == process.env.REACT_APP_ADMIN5_USER_ID ||
            userDetails?.user?.id == process.env.REACT_APP_ADMIN6_USER_ID ||
            userDetails?.user?.id == process.env.REACT_APP_ADMIN7_USER_ID ||
            userDetails?.user?.id == process.env.REACT_APP_ADMIN8_USER_ID ||
            userDetails?.user?.id == process.env.REACT_APP_ADMIN9_USER_ID) ? (
              <Router>
              <div className="container">
                <div>
                  <Header />
                </div>
                {/* <div className="justify-content-between d-flex"> */}
                <div className="d-flex">
                  <div>
                    <SideMenu />
                  </div>
                  <div className="routes-w-80">
                    <Routes>
                      {/* phase two */}
                      <Route path="/" element={<Dashboard />} />
                      <Route path="/sparsh/:TrackId?" element={<Sparsh />} />
                      <Route path="/albumList" element={<AlbumList />} />
                      <Route path="/allBhaktiPads" element={<AllBhaktiPads />} />
                      <Route path="/currentlyPlayingAudioPlaylist" element={<CurrentlyPlayingAudioPlaylist />} />
                      <Route path="/popularBhaktiPads" element={<PopularBhaktiPads />} />
                      <Route path="/newReleasePads" element={<NewReleasePads />} />
                      <Route path="/playlist/:AlbumId" element={<Playlist />} />
                      <Route path="/Audioplaylist/:PlaylistId" element={<AudioPlaylist />} />
                      <Route path="/AudioplaylistShared/:PlaylistId" element={<AudioPlaylistShared />} />
                      <Route path="/individualBhaktipad/:TrackId?" element={<IndividualBhaktipad />} />
                      <Route path="/artist" element={<Artist />} />
    
                      <Route
                        path="/individualPlayList"
                        element={<IndividualPlayList />}
                      />
                      <Route path="/sharedIndividualPlayList" element={<IndividualPlayList />} />
                      <Route path="/allPlayList" element={<AllPlayList />} />
                      <Route path="/watchLater" element={<WatchLater />} />
                      <Route path="/myFavorites" element={<MyFavorites />} />
                      <Route
                        path="/individualUserPlayList"
                        element={<IndividualUserPlayList />}
                      />
                      <Route
                        path="/videoPlayPage/:vidId"
                        element={<VideoPlayPage />}
                      />
                      <Route path="/mySpace" element={<MySpace />} />
                      <Route
                        path="/createdPlaylist"
                        element={<CreatedPlaylist />}
                      />
                      <Route
                        path="/CreatedPlaylistForAudio"
                        element={<CreatedPlaylistForAudio />}
                      />
                      <Route
                        path="/myCreatePlayListTrackAddBtnSuggested"
                        element={<MyCreatePlayListTrackAddBtnSuggested />}
                      />
                      <Route
                        path="/bhaktiPadsTrackList"
                        element={<BhaktiPadsTrackList />}
                      />
                      <Route path="/sharedPlaylist" element={<SharedPlaylist />} />
                      <Route
                        path="/downloadedEbooks"
                        element={<DownloadedEbooks />}
                      />
                      <Route
                        path="/questionHistory"
                        element={<QuestionHistory />}
                      />
                      <Route path="/eBooks" element={<EBooks />} />
                      <Route
                        path="/PlayListShared/:playId"
                        element={<PlayListSharedAccept />}
                      />
                      <Route path="/viewMore" element={<ViewMore />} />
                      <Route
                        path="/advanceSearch/:contentType/:searchKeys"
                        element={<AdvanceSearch />}
                      />
                      <Route
                        path="/activeSubscriptionPlan"
                        element={<ActiveSubscriptionPlan />}
                      />
                      <Route
                        path="/subscriptionPlanList"
                        element={<SubscriptionPlanList />}
                      />
                      <Route path="/videosIndex" element={<VideosIndex />} />
                      <Route path="/aboutUs" element={<AboutUs />} />
                      <Route path="/contactUs" element={<ContactUs />} />
                      <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                      <Route path="/fAQS" element={<FAQS />} />
                      <Route path="/editProfile" element={<EditProfile />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route
                        path="/recentWatchedVideos"
                        element={<RecentWatchedVideos />}
                      />
                      <Route path="/liveSatsang" element={<LiveSatsang />} />
                      <Route path="/surpriseMe" element={<SurpriseMe />} />
                      <Route
                        path="/myCreatePlayListSelectVideo"
                        element={<MyCreatePlayListSelectVideo />}
                      />
                      <Route
                        path="/myCreatePlayListVideoAddBtnSuggested"
                        element={<MyCreatePlayListVideoAddBtnSuggested />}
                      />
                      <Route
                        path="/playListPlayPage/:vidId"
                        element={<PlayListPlayPage />}
                      />
                      <Route path="/fileViewer" element={<FileViewer />} />
                      <Route
                        path="/eBookSeries/:seriesId"
                        element={<EBookSeries />}
                      />
    
                      {/* phase one */}
                    </Routes>
                  </div>
                </div>
                <div class="youTubeMusicPlayer">
                  <MusicPlayer />
                </div>
                {footer && <Footer />}
              </div>
            </Router>
        ) : (
          <Router>
            <Routes>
              <Route path="/" element={<UnderMaintenance />} />
            </Routes>
          </Router>
        )}
      </AuthContext.Provider>
    );
  };
  const productionStack = () => {
    return (
      <AuthContext.Provider value={authContext}>
        <Router>
          <div className="container">
            <div>
              <Header />
            </div>
            {/* <div className="justify-content-between d-flex"> */}
            <div className="d-flex">
              <div>
                <SideMenu />
              </div>
              <div className="routes-w-80">
                <Routes>
                  {/* phase two */}
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/sparsh/:TrackId?" element={<Sparsh />} />
                  <Route path="/albumList" element={<AlbumList />} />
                  <Route path="/allBhaktiPads" element={<AllBhaktiPads />} />
                  <Route path="/currentlyPlayingAudioPlaylist" element={<CurrentlyPlayingAudioPlaylist />} />
                  <Route path="/popularBhaktiPads" element={<PopularBhaktiPads />} />
                  <Route path="/newReleasePads" element={<NewReleasePads />} />
                  <Route path="/playlist/:AlbumId" element={<Playlist />} />
                  <Route path="/Audioplaylist/:PlaylistId" element={<AudioPlaylist />} />
                  <Route path="/AudioplaylistShared/:PlaylistId" element={<AudioPlaylistShared />} />
                  <Route path="/individualBhaktipad/:TrackId?" element={<IndividualBhaktipad />} />
                  <Route path="/artist" element={<Artist />} />

                  <Route
                    path="/individualPlayList"
                    element={<IndividualPlayList />}
                  />
                  <Route path="/sharedIndividualPlayList" element={<IndividualPlayList />} />
                  <Route path="/allPlayList" element={<AllPlayList />} />
                  <Route path="/watchLater" element={<WatchLater />} />
                  <Route path="/myFavorites" element={<MyFavorites />} />
                  <Route
                    path="/individualUserPlayList"
                    element={<IndividualUserPlayList />}
                  />
                  <Route
                    path="/videoPlayPage/:vidId"
                    element={<VideoPlayPage />}
                  />
                  <Route path="/mySpace" element={<MySpace />} />
                  <Route
                    path="/createdPlaylist"
                    element={<CreatedPlaylist />}
                  />
                  <Route
                    path="/CreatedPlaylistForAudio"
                    element={<CreatedPlaylistForAudio />}
                  />
                  <Route
                    path="/myCreatePlayListTrackAddBtnSuggested"
                    element={<MyCreatePlayListTrackAddBtnSuggested />}
                  />
                  <Route
                    path="/bhaktiPadsTrackList"
                    element={<BhaktiPadsTrackList />}
                  />
                  <Route path="/sharedPlaylist" element={<SharedPlaylist />} />
                  <Route
                    path="/downloadedEbooks"
                    element={<DownloadedEbooks />}
                  />
                  <Route
                    path="/questionHistory"
                    element={<QuestionHistory />}
                  />
                  <Route path="/eBooks" element={<EBooks />} />
                  <Route
                    path="/PlayListShared/:playId"
                    element={<PlayListSharedAccept />}
                  />
                  <Route path="/viewMore" element={<ViewMore />} />
                  <Route
                    path="/advanceSearch/:contentType/:searchKeys"
                    element={<AdvanceSearch />}
                  />
                  <Route
                    path="/activeSubscriptionPlan"
                    element={<ActiveSubscriptionPlan />}
                  />
                  <Route
                    path="/subscriptionPlanList"
                    element={<SubscriptionPlanList />}
                  />
                  <Route path="/videosIndex" element={<VideosIndex />} />
                  <Route path="/aboutUs" element={<AboutUs />} />
                  <Route path="/contactUs" element={<ContactUs />} />
                  <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                  <Route path="/fAQS" element={<FAQS />} />
                  <Route path="/editProfile" element={<EditProfile />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route
                    path="/recentWatchedVideos"
                    element={<RecentWatchedVideos />}
                  />
                  <Route path="/liveSatsang" element={<LiveSatsang />} />
                  <Route path="/surpriseMe" element={<SurpriseMe />} />
                  <Route
                    path="/myCreatePlayListSelectVideo"
                    element={<MyCreatePlayListSelectVideo />}
                  />
                  <Route
                    path="/myCreatePlayListVideoAddBtnSuggested"
                    element={<MyCreatePlayListVideoAddBtnSuggested />}
                  />
                  <Route
                    path="/playListPlayPage/:vidId"
                    element={<PlayListPlayPage />}
                  />
                  <Route path="/fileViewer" element={<FileViewer />} />
                  <Route
                    path="/eBookSeries/:seriesId"
                    element={<EBookSeries />}
                  />

                  {/* phase one */}
                </Routes>
              </div>
            </div>
            <div class="youTubeMusicPlayer">
              <MusicPlayer />
            </div>
            {footer && <Footer />}
          </div>
        </Router>
      </AuthContext.Provider>
    );
  };

  return (
    <div>
      {IsLoading && (
        <div className="loaderMain">
          <Loder />
        </div>
      )}
      {process.env.REACT_APP_ENVIRONMENT == "maintenance"
        ? maintenanceStacks()
        : productionStack()}
    </div>
  );
}
export default RoutesFile;
