import React, { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AllImages } from "../../../utils/constants";
import { UserloggedContext, SurpriseMeContext, SurpriseMeDataContext, MusicPlayerVisibilityContext } from "../../Context/UserContext";
import ApiEndPoint from "../../../utils/apiEndPoints";
import { setDefaultHeader, apiCall } from "../../../utils/httpClient";
import "./style.css";

import useGoogleLoginFlow from "components/common/useGoogleLoginFlow";
import { confirmAlert } from "react-confirm-alert";
import Button from "@material-ui/core/Button";
import Google from "../../../assets/images/google.png";
export default function Pricing(props) {
  const [active, setActive] = useState("layer1");
  const navigate = useNavigate();
  const [surpriseMeShow, setSurpriseMeShow] = useContext(SurpriseMeContext)
  const [surpriseMeData, setSurpriseMeData] = useContext(SurpriseMeDataContext)
  const [isMusicPlayerVisible, setIsMusicPlayerVisible] = useContext(MusicPlayerVisibilityContext)
  const {

    openRecaptcha,

    recaptchaFun,

    onChange,
  } = useGoogleLoginFlow()
  const [auth, setAuth] = useContext(UserloggedContext);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const onToggleClick = (item) => {
    if (item !== active) {
      setActive(item);
    } else {
      setActive("");
    }
  };

  const handleMySpace = (item) => {
    if (auth) {
      navigate(`/MySpace`)
    } else {
      takeOtherSubcription()
    }
  }

  // const handleEBooks = (item) => {
  //   navigate(`/eBooks`)
  // }
  const handleSparsh = (item) => {
    navigate(`/sparsh`)
  }
  const handleViewMore = (item) => {
    navigate(`/viewMore`)
  }
  const handleSurpriseMe = async (item) => {
    if (auth) {
      try {
        const { data } = await apiCall("GET", ApiEndPoint.SURPRISEME);
        if (data) {
          setSurpriseMeData(data)
          setSurpriseMeShow(true)
          navigate(`/`)
        }
      } catch (error) {
        // console.log(error);
      }
    } else {
      takeOtherSubcription()
    }
  }



  const handleHome = (item) => {
    onToggleClick(item)
    navigate(`/`)
  }

  const shouldHideFooter = splitLocation[1] === 'liveSatsang' || splitLocation[1] === 'fileViewer';
  function takeOtherSubcription() {

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card">
            <div className="info__padding">
              <h3>
                {`Please sign in to use this feature. `}
              </h3>
              {/* <h3>{!auth ? "Sign In" : ""}</h3> */}
            </div>
            {!auth ? (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      onChange({ true: true });
                      onClose();
                    }}
                    variant="contained"
                    className="signBtnHome"
                  >
                    <img style={{ height: '23px', width: '23px' }} className="g-icon" src={Google}></img>

                    Sign-in
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      navigate("/subscriptionPlanList");
                      onClose();
                    }}
                    variant="contained"
                    className="subscriptionBtn"
                  // className="signBtnHome"
                  >
                    {/* <img className="g-icon" src={Google}></img> */}
                    Subscribe
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        );
      },
    })

  }
  return (
    shouldHideFooter ? null :
      <div className="footerStripOuter">
        <div className="footerStrip">
          <div className="footer-wtapper">
            <div
              onClick={() => handleHome("layer1")}
              className={`menu-item ${splitLocation[1] == "" ? surpriseMeShow ? "" : "activeClass" : ""}`}
            >
              <div className="inside-menu">
                <span className="active-yellow">
                  <img src={AllImages.HomeWhite} alt="Home" title="Home" />
                </span>
                <img className="icon icon1" src={AllImages.Home} alt="Home" title="Home" />
                <p className="menu-text f-poppins">Home</p>
              </div>
            </div>
            <div
              onClick={() => handleSparsh()}
              className={`menu-item ${splitLocation[1] == "sparsh" ? "activeClass" : ""}`} ///////
            >
              <div className="inside-menu">
                <span className="active-yellow">
                  <img src={AllImages.sparshWhite} alt="Sparsh" title="Sparsh" />
                </span>
                <img className="icon icon3" src={AllImages.Sparsh} alt="Sparsh" title="Sparsh" />
                <p className="menu-text f-poppins">Sparsh</p>
              </div>
            </div>
            {/* <div
              onClick={() => handleEBooks()}
              className={`menu-item ${splitLocation[1] == "eBooks" ? "activeClass" : ""}`}
            >
              <div className="inside-menu">
                <span className="active-yellow">
                  <img src={AllImages.EBbookWhite} alt="Ebook" title="Ebook" />
                </span>
                <img className="icon icon2" src={AllImages.EBbook} alt="Ebook" title="Ebook" />
                <p className="menu-text f-poppins">E-Books</p>
              </div>
            </div> */}
            <div
              onClick={() => handleSurpriseMe()}
              className={`menu-item ${surpriseMeShow ? "activeClass" : ""}`}
            >
              <div className="inside-menu">
                <span className="active-yellow">
                  <img src={AllImages.GiftWhite} alt="Gift" title="Gift" />
                </span>
                <img className="icon icon3" src={AllImages.Gift} alt="Gift" title="Gift" />
                <p className="menu-text f-poppins">Surprise</p>
              </div>
            </div>
            <div
              onClick={() => handleMySpace()}
              className={`menu-item ${splitLocation[1] == "MySpace" ? "activeClass" : ""}`}
            >
              <div className="inside-menu">
                <span className="active-yellow">
                  <img src={AllImages.UserWhite} alt="User" title="User" />
                </span>
                <img className="icon icon4" src={AllImages.User} alt="User" title="User" />
                <p className="menu-text f-poppins">My Space</p>
              </div>
            </div>
            <div
              onClick={() => handleViewMore()}
              className={`menu-item ${splitLocation[1] == "viewMore" ? "activeClass" : ""}`}
            >
              <div className="inside-menu">
                <span className="active-yellow">
                  <img src={AllImages.MoreWhite} alt="More" title="More" />
                </span>
                <img className="icon icon5" src={AllImages.More} alt="More" title="More" />
                <p className="menu-text f-poppins">More</p>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          onClick={() => handleSurpriseMe()}
          className={`menu-item ${surpriseMeShow ? "activeClass" : ""}`}>
          <div className={`surpriseMe menu-item activeClass ${isMusicPlayerVisible ? "playerOpen" : ""}`}  >
            <span className="active-yellow">
              <img src={AllImages.GiftWhite} alt="Gift" title="Gift" />
            </span>
          </div>
        </div> */}
        {/* {console.log(isMusicPlayerVisible)} */}
      </div>

  );
}
