import React, { useState, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./style.css";
import {
  GLOBAL_URL,
  AllImages,
  RemoveDuplicates,
} from "../../../utils/constants";
import { userDetailsContext, GSTContext } from "../../Context/UserContext";

function Profile() {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useContext(userDetailsContext);
  return (
    <section className="profile">
      {/* <div className="heading">
        <img className='dot' src={AllImages?.leftArrowOrange}></img>
        <p>Profile</p>
      </div> */}
      <div class="d-flex m-hide min-h header-jc-sp-c">
        <div class="d-flex">
          <img
            onClick={() => navigate(-1)}
            src={AllImages?.YellowArrow}
            alt="Back Arrow"
            title="Back Arrow"
            class="notification-icon"
          />
          <p class="mob-header-title">Profile</p>
        </div>
        <div onClick={() => navigate("/editProfile")}>
          <p class="header-create-btn">Edit</p>
        </div>
      </div>

      <div className="profileInner">
        <div className="subscriptionPlanListInner">
          <div className="second">
            <div className="userImage">
              <img
                className="dot"
                src={
                  userDetails?.user?.imageUrl
                    ? userDetails?.user?.imageUrl
                    : AllImages?.UserImage
                }
                alt="dot"
                title="dot"
              ></img>
            </div>
            {/* <p className="historyTitle">Subscription History</p> */}
            <div className="SubscriptionCard">
              <div className="history">
                <div className="left">
                  <p className="title">First Name</p>
                  <p className="subTitle">{userDetails?.user?.firstName} </p>
                </div>
                <div className="right">
                  <p className="title">Last Name</p>
                  <p className="subTitle">{userDetails?.user?.lastName}</p>
                </div>
              </div>
              <div className="history">
                <div className="full">
                  <p className="title">Email ID</p>
                  <p className="subTitle">{userDetails?.user?.email}</p>
                </div>
              </div>
              <div className="history">
                <div className="full">
                  <p className="title">Mobile Number</p>
                  <p className="subTitle">
                    {userDetails?.user?.countryCode}{" "}
                    {userDetails?.user?.phoneNumber}
                  </p>
                </div>
              </div>
              <div className="history">
                <div className="left">
                  <p className="title">Country</p>
                  <p className="subTitle">{userDetails?.user?.country}</p>
                </div>
                <div className="right">
                  <p className="title">State</p>
                  <p className="subTitle">{userDetails?.user?.state}</p>
                </div>
              </div>
              <div className="history">
                <div className="left">
                  <p className="title">City</p>
                  <p className="subTitle">{userDetails?.user?.city}</p>
                </div>
                <div className="right">
                  <p className="title">Pincode</p>
                  <p className="subTitle">{userDetails?.user?.pinCode}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <img className='profileBg' src={AllImages?.ProfileBg}></img> */}

      {/* <div className="editBtn">
        <button className="button btn edit">
          <p class="title" onClick={() => navigate('/editProfile')}>Edit</p>
        </button>
      </div> */}
    </section>
  );
}
export default Profile;
