import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./style.css";
import { AllImages, RemoveDuplicates } from "../../../utils/constants";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Table } from "reactstrap";

import { Typography, Box, Grid, ListItem, List } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { VideoListContext, VideoIndexContext, MusicTracksContext, MusicTrackContext, PlayNextContext } from "../../Context/UserContext";
import InfiniteScroll from "react-infinite-scroll-component";
import { ToastContainer, toast } from "react-toastify";
import ApiEndPoint from "../../../utils/apiEndPoints";
import { setDefaultHeader, apiCall } from "../../../utils/httpClient";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
// import SliderSingle from "./sliderSingle";
import ReactPlayer from "react-player/lazy";
// import { Tab } from "bootstrap";
import Modal from "@mui/material/Modal";
import { gapi } from "gapi-script";
import {
  GoogleLogin,
  useGoogleLogout,
  useGoogleLogin,
} from "react-google-login";
import { AuthContext } from "../../Context/context";
import Google from "../../../assets/images/google.png";
import { googleClientId } from "../../../utils/constants";
import loader from "../../../assets/images/logo.png";
import ReCAPTCHA from "react-google-recaptcha";
import useGoogleLoginFlow from "components/common/useGoogleLoginFlow";
const SortingOptions = {
  NEWLY_ADDED: "NEWLY_ADDED",
  PREVIOUSLY_ADDED: "PREVIOUSLY_ADDED",
  POPULAR_VIDEOS: "POPULAR_VIDEOS",
  NEWLY_PUBLISHED: "NEWLY_PUBLISHED",
  PREVIOUSLY_PUBLISHED: "PREVIOUSLY_PUBLISHED",
  LAST_MODIFIED_DATE: "LAST_MODIFIED_DATE",
  Sort_A_To_Z: "A_TO_Z",
  Sort_Z_To_A: "Z_TO_A",
};
const clientId = googleClientId;
const TEST_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
function VideosIndex() {
  const location = useLocation();
  const {
    openRecaptcha,

    recaptchaFun,

    onChange,
  } = useGoogleLoginFlow();
  const navigate = useNavigate();
  const [count, setCount] = useState(9);
  const [start, setStart] = useState(1);
  const [videoIndexData, setVideIndexData] = useContext(VideoIndexContext);
  const { signInFun, signOutFun } = React.useContext(AuthContext);

  const [auth, setauth] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [video, setVideo] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [sortType, setSortType] = useState(false);
  const [sortBy, setSortBy] = useState("serial_number");
  const [searchText, setSearchText] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [isOpenSortBy, setIsOpenSortBy] = useState(false);
  const [scrollHashMap, setScrollHashMap] = useState(true);
  const [scrollLast, setScrollLast] = useState(true);
  const [dataLength, setDataLength] = useState(null);
  const [refresh, setRefresh] = useState(true);
  const [showLoader, setShowLoader] = useState(false);

    // STOP THE MUSIC PLAYER  STATE
    const { track, setTrack } = useContext(MusicTracksContext);
    const { singleTrack, setSingleTrack } = useContext(MusicTrackContext);
    const { playNextTrack, setPlayNextTrack } = useContext(PlayNextContext);

  const [selectedFilterOp, setSelectedFilterOp] = useState(
    SortingOptions.NEWLY_ADDED
  );

  function takeSubcription(params) {
    params?.subscriptionName
      ? confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="card">
                <div className="info__padding">
                  <h3>
                    {`Please Subscribe to ${params?.subscriptionName} plan to play this video. `}
                  </h3>
                  {!auth && <h3>Sign In To Subscribe...</h3>}
                </div>
                {!auth ? (
                  <>
                    <div className="button__group">
                      <Button
                        onClick={() => {
                          onChange({ true: true });
                          onClose();
                        }}
                        variant="contained"
                        className="signBtnHome"
                      >
                        <img
                          style={{ height: "23px", width: "23px" }}
                          className="g-icon"
                          src={Google}
                        ></img>
                        Sign-in
                      </Button>
                      <button className="no-btn" onClick={onClose}>
                        Cancel
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="button__group">
                      <Button
                        onClick={() => {
                          navigate("/subscription");
                          onClose();
                        }}
                        variant="contained"
                        className="subscriptionBtn"
                        // className="signBtnHome"
                      >
                        {/* <img className="g-icon" src={Google}></img> */}
                        Subscribe
                      </Button>
                      <button className="no-btn" onClick={onClose}>
                        Cancel
                      </button>
                    </div>
                  </>
                )}
              </div>
            );
          },
        })
      : confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="card">
                <div className="info__padding">
                  <h3>{`This Video is not yet available`}</h3>
                </div>
                <div className="button__group">
                  <button className="button btn-ok" onClick={onClose}>
                    Ok
                  </button>
                </div>
              </div>
            );
          },
        });
  }
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchNextUsers("serial_number", false);
    }
  };

  const handleClose = () => setOpen(false);
  useEffect(() => {
    fetchNextUsers("serial_number", false);
  }, []);

  function RemoveDuplicates(data) {
    const newdata = data.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    return newdata;
  }

  // async function fetchNextUsers(sort, type) {
  //   setSortBy(sort)
  //   try {
  //     !videoIndexData?.length > 0 && setIsLoading(true);
  //     const Auth = await localStorage.getItem("userToken");
  //     if (Auth == "01") {
  //       const { data } = await apiCall(
  //         "GET",
  //         `video-meta-data/videoIndexingForLoginUser?searchValue=${searchText}&page=0&size=${15}&sort=${sort},${type ? 'asc' : 'desc'}`,
  //       );
  //       if (data.length > 0) {
  //         // const filter = RemoveDuplicates(data)
  //         setStart(1)
  //         setVideIndexData(data);
  //         setIsLoading(false);
  //         setScrollLast(true)

  //         // if (filter.length != dataLength) {
  //         //   setScrollLast(true)
  //         //   setDataLength(filter.length)
  //         // } else {
  //         //   setScrollLast(false)
  //         // }
  //       } else {
  //         setIsLoading(false);
  //         setScrollLast(false)

  //       }
  //     } else {
  //       const { data } = await apiCall("GET",
  //         `open/video-meta-data/videoIndexingForNonLoginUser?searchValue=${searchText}&page=0&size=${15}&sort=${sort},${type ? 'asc' : 'desc'}`
  //       );
  //       if (data.length > 0) {
  //         // const filter = RemoveDuplicates(data)
  //         setStart(1)
  //         setVideIndexData(data);
  //         setIsLoading(false);
  //         setScrollLast(true)
  //         // if (data.length != dataLength) {
  //         //   setDataLength(data.length)
  //         // } else {
  //         //   setScrollLast(false)
  //         // }
  //       } else {
  //         setScrollLast(false)
  //         setIsLoading(false);
  //       }
  //     }
  //   } catch (err) {
  //     setIsLoading(false);
  //     console.log("err: ", err);
  //   }
  // }
  async function fetchNextUsers(sort, type) {
    setSortBy(sort);
    try {
      !videoIndexData?.length > 0 && setIsLoading(true);
      const Auth = await localStorage.getItem("userToken");
      if (Auth == "01") {
        const { data } = await apiCall(
          "GET",
          `video-meta-data/videoIndexingForLoginUser?searchValue=${searchText}&page=0&size=${15}&sort=${sort},${
            type ? "asc" : "desc"
          }`
        );
        if (data.length > 0) {
          setStart(1);
          const filter = RemoveDuplicates(data);
          setVideIndexData(data);
          setIsLoading(false);
          setScrollLast(true);

          // if (filter.length != dataLength) {
          //   setScrollLast(true)
          //   setDataLength(filter.length)
          // } else {
          //   setScrollLast(false)
          // }
        } else {
          setIsLoading(false);
          setScrollLast(false);
        }
      } else {
        const { data } = await apiCall(
          "GET",
          `open/video-meta-data/videoIndexingForNonLoginUser?searchValue=${searchText}&page=0&size=${15}&sort=${sort},${
            type ? "asc" : "desc"
          }`
        );
        if (data.length > 0) {
          setStart(1);
          const filter = RemoveDuplicates(data);
          setVideIndexData(data);
          setIsLoading(false);
          setScrollLast(true);
          // if (data.length != dataLength) {
          //   setDataLength(data.length)
          // } else {
          //   setScrollLast(false)
          // }
        } else {
          setScrollLast(false);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setIsLoading(false);
      console.log("err: ", err);
    }
  }
  const sortFun = async (e,sort) => {
    // if (videoIndexData.length > 15) {
    //   const sortedusers = videoIndexData.sort(function (a, b) {
    //     var nameA = sort == 'serial_number' ? a.serial_number : a[sort];
    //     var nameB = sort == 'serial_number' ? b.serial_number : b[sort];
    //     if (nameA < nameB) {
    //       return !sortType ? 1 : -1
    //     }
    //     if (nameA > nameB) {
    //       return !sortType ? -1 : 1;
    //     }
    //     return 0;
    //   });
    //   setSortType(!sortType)
    //   setVideIndexData(sortedusers)
    //   setRefresh(!refresh)
    // } else {

    // }
    e.stopPropagation();
    fetchNextUsers(sort, sortType ? false : true);
    setSortType(!sortType);
  };

  // async function handleVideoSearch(sort) {
  //   try {
  //     // setScrollLast(true)
  //     !videoIndexData?.length > 0 && setIsLoading(true);
  //     const Auth = await localStorage.getItem("userToken");
  //     if (Auth == "01") {
  //       const { data } = await apiCall(
  //         "GET",
  //         `video-meta-data/videoIndexingForLoginUser?searchValue=${searchText}&page=${0}&size=${15}&sort=${sort},${sortType ? 'asc' : 'desc'}`,
  //       );
  //       if (data.length > 0) {
  //         // setVideIndexData(RemoveDuplicates(videoIndexData.concat(data)));
  //         setVideIndexData(data);
  //         setStart(1)
  //         setIsLoading(false);
  //         setScrollLast(true)

  //       } else {
  //         setScrollLast(false)
  //         setIsLoading(false);
  //       }
  //     } else {
  //       const { data } = await apiCall("GET",
  //         `video-meta-data/videoIndexingForNonLoginUser?searchValue=${searchText}&page=${0}&size=${15}&sort=${sort},${sortType ? 'asc' : 'desc'}`
  //       );
  //       if (data.length > 0) {
  //         // setVideIndexData(RemoveDuplicates(videoIndexData.concat(data)));
  //         setVideIndexData(data);
  //         setStart(1)
  //         setIsLoading(false);
  //         setScrollLast(true)
  //       } else {
  //         setScrollLast(false)
  //         setIsLoading(false);
  //       }
  //     }
  //   } catch (err) {
  //     setIsLoading(false);
  //     console.log("err: ", err);
  //   }
  //   // setTimeout(() => {
  //   //   setScrollLast(false)
  //   // }, 5000);
  // }

  async function infiniteScroll(sort) {
    try {
      // setScrollLast(true)
      !videoIndexData?.length > 0 && setIsLoading(true);
      const Auth = await localStorage.getItem("userToken");
      if (Auth == "01") {
        const { data } = await apiCall(
          "GET",
          `video-meta-data/videoIndexingForLoginUser?searchValue=${searchText}&page=${start}&size=${15}&sort=${sort},${
            sortType ? "asc" : "desc"
          }`
        );
        if (data.length > 0) {
          // setVideIndexData(RemoveDuplicates(videoIndexData.concat(data)));
          setVideIndexData(videoIndexData.concat(data));
          setStart(start + 1);
          setIsLoading(false);
          setScrollLast(true);
        } else {
          setScrollLast(false);
          setIsLoading(false);
        }
      } else {
        const { data } = await apiCall(
          "GET",
          `open/video-meta-data/videoIndexingForNonLoginUser?searchValue=${searchText}&page=${start}&size=${15}&sort=${sort},${
            sortType ? "asc" : "desc"
          }`
        );
        if (data.length > 0) {
          // setVideIndexData(RemoveDuplicates(videoIndexData.concat(data)));
          setVideIndexData(videoIndexData.concat(data));
          setStart(start + 1);
          setIsLoading(false);
          setScrollLast(true);
        } else {
          setScrollLast(false);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setIsLoading(false);
      console.log("err: ", err);
    }
    // setTimeout(() => {
    //   setScrollLast(false)
    // }, 5000);
  }

  useEffect(() => {
    authentication();
  }, []);

  async function authentication() {
    let userToken;
    userToken = null;
    try {
      userToken = await localStorage.getItem("userToken");
      if (userToken) {
        setauth(userToken);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const videoPlayBtn = (url) => {
    // STOP THE MUSIC PLAYER 
    setTrack([])
    setSingleTrack([])
    setPlayNextTrack([])
    setVideo(url);
    setOpen(true);
    setShowLoader(true);
    setTimeout(() => {
      setShowLoader(false);
    }, 2000);
  };

  function handleSearch(e) {
    if (e.target.value) {
      setSearchText(e.target.value);
    } else {
      setSearchText(null);
    }
  }

  // const handleRadioChange = (event) => {
  //   setSelectedFilterOp(event.target.value);
  // };
  // function capitalizeFirstWord(str) {
  //   return str.charAt(0).toUpperCase() + str.slice(1);
  // }
  return (
    <section className="videoPlan">
      <div className="qu-history-title-search-div">
        {/*----- Question History Title Start -----*/}
        <div className="qu-history-title-mt-20">
          <h1 className="mainTitle">Video Index</h1>
        </div>
        {/*----- Question History Title End -----*/}
        {/*----- Search Box Start -----*/}
        <div className="video-plan-search-wrap-outer">
          <div className="video-plan-search-wrap">
            <div className="search-input">
              <span
                onClick={() => fetchNextUsers("serial_number", false)}
                className="search-icon"
              >
                {isLoading ? (
                  <img
                    src={loader}
                    alt="loading..."
                    style={{ height: 15, width: 15 }}
                  />
                ) : (
                  <div className="searchTooltip">
                    <img src={AllImages.Search} alt="" />
                    <span className="tooltiptext">{"Search"}</span>
                  </div>
                )}
                {/* <img src={AllImages.Search} alt="Search" title="Search" /> */}
              </span>
              <input
                className="f-poppins"
                type="text"
                placeholder="Video Title, Speaker Name,..."
                onChange={(e) => handleSearch(e)}
                onKeyDown={handleKeyPress}
              />
            </div>
            <div className="searchIcon-outer">
              <img
                src={AllImages.SearchIcon}
                alt="Search"
                title="Search"
                className="searchIcon"
              />
            </div>
            {/* <div className="filter-icon sortbyTooltip" onClick={() => openModalSortBy()}>
              <img src={AllImages.SearchToggle} alt="Search" title="Search" />
              <span className='tooltiptext'>
                {"Sort By"}
              </span>
            </div> */}
          </div>
        </div>
        {/*----- Search Box End -----*/}
      </div>

      {/*----- Search Box Start -----*/}
      <div className="video-plan-search-box-mobile"></div>
      {/*----- Search Box Start -----*/}

      <InfiniteScroll
        dataLength={videoIndexData?.length}
        next={() => infiniteScroll(sortBy)}
        hasMore={scrollHashMap}
        loader={
          scrollLast ? (
            <center>
              <img
                src={loader}
                alt="loading..."
                style={{ height: 25, width: 25, marginTop: 20 }}
              />
            </center>
          ) : (
            <p className="subtitle fancy">
              <span>End of results</span>
            </p>
          )
        }
      >
        {/* mobile */}
        <div className="subscriptionPlanListInner">
          {videoIndexData.map((item) => (
            <div
              className="second custom-row"
             
            >
              {/* <p className="historyTitle">Subscription History</p> */}
              <div className="custom-col-4 custom-col">
                <div  className="SubscriptionCard">
                  <div className="history">
                    <div
                      className="left"
                      
                    >
                      <p onClick={(e) => sortFun(e,"serial_number")} className="title">Sr No</p>
                      <p className="subTitle">
                        {item.serialNumber ? item.serialNumber : "--"}
                      </p>
                    </div>
                    <div
                      className="right"
                      
                    >
                      <p onClick={(e) => sortFun(e,"created_date")} className="title">Date</p>
                      <p className="subTitle">
                        {" "}
                        {item.videoRecordDate ? item.videoRecordDate : "--"}
                      </p>
                    </div>
                  </div>
                  <div
                    className="history"
                 
                  >
                    <div className="full">
                      <p    onClick={(e) => sortFun(e,"video_title")} className="title">Video Title</p>
                      <p className="subTitle">
                        {" "}
                        {item.videoTitle ? item.videoTitle : "--"}
                      </p>
                    </div>
                  </div>
                  <div className="history">
                    <div className="left" >
                      <p onClick={(e) => sortFun(e,"speaker")} className="title">Speaker Name</p>
                      <p className="subTitle">
                        {" "}
                        {item.speaker ? item.speaker : "--"}
                      </p>
                    </div>
                    <div
                      className="right"
                      
                    >
                      <p onClick={(e) => sortFun(e,"subscription_plan_id")} className="title">Video Tier</p>
                      <p className="subTitle">
                        {item.subscriptionName ? item.subscriptionName : "--"}
                      </p>
                    </div>
                  </div>
                  <div className="history" >
                    <div className="left">
                      <p onClick={(e) => sortFun(e,"location")} className="title">Location</p>
                      <p className="subTitle">
                        {" "}
                        {item.location ? item.location : "--"}
                      </p>
                    </div>
                    <div  className="history mobile-play-now">
                    <div
                      className="title"
                     
                    >
                     <p  onClick={() =>
                        item?.videoUrl
                          ? videoPlayBtn(item?.videoUrl)
                          : takeSubcription(item)
                      } className="mobile-play-now-title">PLAY NOW</p> 
                    </div>
        
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          ))}
        </div>

        <div
          className="table-responsive table-response-videoIndex"
          style={{ marginTop: 30 }}
        >
          <Table responsive striped>
            <thead>
              <tr>
                <th
                  className="descTitle hand"
                  onClick={(e) => sortFun(e,"serial_number")}
                >
                  <div className="tableHead">
                    Sr No
                    <span style={{ float: "right", paddingLeft: "10px" }}>
                      <img
                        src={AllImages.TableArrowDown}
                        alt="Arrow"
                        title="Arrow"
                        className="tableArrowDown"
                      />
                    </span>
                  </div>
                </th>
                <th
                  className="descTitle hand"
                  onClick={(e) => sortFun(e,"video_title")}
                >
                  <div className="tableHead">
                    Video Title
                    <span style={{ float: "right", paddingLeft: "10px" }}>
                      <img
                        src={AllImages.TableArrowDown}
                        alt="Arrow"
                        title="Arrow"
                        className="tableArrowDown"
                      />
                    </span>
                  </div>
                </th>
                <th
                  className="descTitle hand"
                  onClick={(e) => sortFun(e,"created_date")}
                >
                  <div className="tableHead" style={{ minWidth: "90px" }}>
                    Date
                    <span style={{ float: "right", paddingLeft: "10px" }}>
                      <img
                        src={AllImages.TableArrowDown}
                        alt="Arrow"
                        title="Arrow"
                        className="tableArrowDown"
                      />
                    </span>
                  </div>
                </th>
                <th
                  className="descTitle hand"
                  onClick={(e) => sortFun(e,"speaker")}
                >
                  <div className="tableHead">
                    Speaker Name
                    <span style={{ float: "right", paddingLeft: "10px" }}>
                      <img
                        src={AllImages.TableArrowDown}
                        alt="Arrow"
                        title="Arrow"
                        className="tableArrowDown"
                      />
                    </span>
                  </div>
                </th>
                <th
                  className="descTitle hand"
                  onClick={(e) => sortFun(e,"location")}
                >
                  <div className="tableHead">
                    Location
                    <span style={{ float: "right", paddingLeft: "10px" }}>
                      <img
                        src={AllImages.TableArrowDown}
                        alt="Arrow"
                        title="Arrow"
                        className="tableArrowDown"
                      />
                    </span>
                  </div>
                </th>
                <th
                  className="descTitle hand"
                  onClick={(e) => sortFun(e,"subscription_plan_id")}
                >
                  <div className="tableHead" style={{ minWidth: "110px" }}>
                    Video Tier
                    <span style={{ float: "right", paddingLeft: "10px" }}>
                      <img
                        src={AllImages.TableArrowDown}
                        alt="Arrow"
                        title="Arrow"
                        className="tableArrowDown"
                      />
                    </span>
                  </div>
                </th>
                <th className="descTitle hand">
                  <div className="tableHead" style={{ minWidth: "90px" }}>
                    Action
                    {/* <span style={{ float: 'right', paddingLeft: '10px' }}><img src={AllImages.TableArrowDown} alt="" className="tableArrowDown" /></span> */}
                  </div>
                </th>
              </tr>
            </thead>
            {videoIndexData.map((item) => (
              <tbody>
                <tr style={{ cursor: "pointer" }} className="hoverable-row">
                  <td>
                    <div className="tableData">
                      {item.serialNumber ? item.serialNumber : "--"}
                    </div>
                  </td>
                  <td>
                    <div className="tableData">
                      {item.videoTitle ? item.videoTitle : "--"}
                    </div>
                  </td>
                  <td>
                    <div className="tableData">
                      {item.videoRecordDate ? item.videoRecordDate : "--"}
                    </div>
                  </td>
                  <td>
                    <div className="tableData">
                      {item.speaker ? item.speaker : "--"}
                    </div>
                  </td>
                  <td>
                    <div className="tableData">
                      {item.location ? item.location : "--"}
                    </div>
                  </td>
                  <td>
                    <div className="tableData">
                      {item.subscriptionName ? item.subscriptionName : "--"}
                    </div>
                  </td>
                  <td>
                    <div
                      className="tableData playNow"
                      onClick={() =>
                        item?.videoUrl
                          ? videoPlayBtn(item?.videoUrl)
                          : takeSubcription(item)
                      }
                    >
                      PLAY NOW
                    </div>
                  </td>
                </tr>
              </tbody>
            ))}
          </Table>
        </div>

        {/* {isOpenSortBy && (
          <Dialog
            open={openModalSortBy}
            className="sortBy"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent
              style={{
                overflowY: "hidden",
                overflowX: "hidden",
              }}
            >
              <div className="qu-history-modal-msg-content">
                <p className="qu-history-modal-msg-text">{"Sort by"}</p>
                <span
                  className="qu-history-modal-close-button"
                  onClick={() => closeModalSortBy()}
                >
                  &times;
                </span>
              </div>
              <ul>
                {Object.keys(SortingOptions).map((key) => (
                  <li key={key}>
                    <label className="customRadio">
                      {capitalizeFirstWord(
                        SortingOptions[key].replace(/_/g, " ").toLowerCase()
                      )}
                      <input
                        type="radio"
                        value={SortingOptions[key]}
                        checked={selectedFilterOp === SortingOptions[key]}
                        onChange={handleRadioChange}
                        name="radio"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                ))}
              </ul>
            </DialogContent>
          </Dialog>
        )} */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {video ? (
            <Box className="video-index-media">
              {/* <Box sx={style}> */}
              {showLoader && (
                <div
                  style={{
                    width: "100%",
                    height: "400px",
                    position: "absolute",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#000",
                      width: "100%",
                      height: "400px",
                      textAlign: "center",
                      display: "flex",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={loader} alt="loading..." />
                  </div>
                </div>
              )}

              <ReactPlayer
                url={video}
                controls={true}
                playing={true}
                pip={true}
                stopOnUnmount={false}
                config={{
                  youtube: {
                    playerVars: {
                      showinfo: 0,
                      fs: 1,
                      modestbranding: 1,
                    },
                  },
                }}
                width="100%"
                height="400px"
              />

              <div className="close-video-index badgestar-video-index">
                <div className="LikeCount badgeShow">
                  <i className="fa fa-close" onClick={handleClose}></i>
                </div>
              </div>
            </Box>
          ) : (
            // <Box className="video-index-media">
            // {/* <Box sx={style}> */}
            // <h3>This Video is not yet available</h3>

            // <div className="close-video-index badgestar-video-index">
            //   <div className="LikeCount badgeShow">
            //     <i className="fa fa-close" onClick={handleClose}></i>
            //   </div>
            // </div>
            // </Box>
            <Box className="video-index-media-alert card">
              {/* <Box sx={style}> */}
              {/* <div className="card"> */}
              <div className="info__padding">
                <h3
                  style={{ textAlign: "center" }}
                >{`This Video is not yet available`}</h3>
              </div>
              <div className="button__group">
                <button className="button btn-ok" onClick={handleClose}>
                  Ok
                </button>
                {/* </div> */}
              </div>
            </Box>
          )}
        </Modal>
      </InfiniteScroll>
      {/* {openRecaptcha &&
          <Dialog
            open={openRecaptcha}
            className="sortBy captcha"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent style={{
              overflowY: "hidden",
              overflowX: "hidden",
            }}>

              <ReCAPTCHA
                sitekey={TEST_SITE_KEY}
                onChange={onChange}
                style={{ display: "inline-block", }}
              />
            </DialogContent>
          </Dialog>
        } */}
    </section>
  );
}
export default VideosIndex;
