import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import loader from "../../../assets/images/logo.png";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Button from "@material-ui/core/Button";
import { CiCircleList } from "react-icons/ci";
import { CiGrid2V } from "react-icons/ci";
import {
  UserloggedContext,
  WatchLaterVideosContext,
  RecentWatchedVideosListContext,
  RecentlyPlayListContext,
  ActiveTabsContext,
} from "../../Context/UserContext";
import ApiEndPoint from "../../../utils/apiEndPoints";
import { setDefaultHeader, apiCall } from "../../../utils/httpClient";
import {
  AllImages,
  VideoPlaylistShareUrl,
  GLOBAL_URL,
  RemoveDuplicates,
  emojiRegex,
  getImageNameFromResolution,
} from "../../../utils/constants";
import "./style.css";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { RWebShare } from "react-web-share";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { faL } from "@fortawesome/free-solid-svg-icons";
import NoDataAvailable from "../../common/NoDataFound/NoDataAvailable";
const SortingOptions = {
  NEWLY_ADDED: "NEWLY_ADDED",
  PREVIOUSLY_ADDED: "PREVIOUSLY_ADDED",
  POPULAR_VIDEOS: "POPULAR_VIDEOS",
  NEWLY_PUBLISHED: "NEWLY_PUBLISHED",
  PREVIOUSLY_PUBLISHED: "PREVIOUSLY_PUBLISHED",
  LAST_MODIFIED_DATE: "LAST_MODIFIED_DATE",
  Sort_A_To_Z: "A_TO_Z",
  Sort_Z_To_A: "Z_TO_A",
};

const RecentWatchedVideos = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [auth, setAuth] = useContext(UserloggedContext);
  const [activetabs, setActiveTabs] = useContext(ActiveTabsContext);

  const [watchLaterVideos, setWatchLaterVideos] = useContext(
    WatchLaterVideosContext
  );
  const [recentWatchedVideoList, setRecentWatchedVideoList] = useContext(
    RecentWatchedVideosListContext
  );
  const [recentlyPlayListData, setRecentlyPlayListData] = useContext(
    RecentlyPlayListContext
  );
  const [isListView, setIsListView] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const [tabValue, setTabValue] = React.useState(
    activetabs.filter((item) => item.page == "recentWatchedVideos")[0].active
  );
  const [searchText, setSearchText] = useState(null);
  const [watchLaterPlaylist, setWatchLaterPlayList] = useState([]);
  const [selectedFilterOp, setSelectedFilterOp] = useState(
    SortingOptions.LAST_MODIFIED_DATE
  );
  const [scrollLast, setScrollLast] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [isOpenSortBy, setIsOpenSortBy] = useState(false);

  useEffect(() => {
    window.history.scrollRestoration = "manual";
    fetchData();
  }, [tabValue, selectedFilterOp]);
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchData();
    }
  };
  const fetchData = () => {
    setHasMore(true);
    tabValue === "videos" ? recentWatchVideolist(1) : recentlyPlaylistFun(1);
  };
  const fetchMoreData = () => {
    tabValue === "videos" ? recentWatchVideolist() : recentlyPlaylistFun();
  };
  async function recentWatchVideolist(fetchPage = 0) {
    try {
      if (auth) {
        const params = {
          searchValue: searchText,
          sortColumn: selectedFilterOp,
          sortDirection: "",
          pageNo: fetchPage ? fetchPage - 1 : pageNo,
          pageSize: 20,
        };
        setIsLoading(true);

        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.RECENTWATCHEDVIDEOLIST}`,
          params
        );
        if (data.length > 0) {
          // setRecentWatchedVideoList(
          //   fetchPage ? data : (prev) => [...prev, ...data]
          // );

          setIsLoading(false);
          setRecentWatchedVideoList(
            RemoveDuplicates(
              fetchPage ? data : recentWatchedVideoList.concat(data)
            )
          );

          setPageNo(fetchPage ? fetchPage : pageNo + 1);

          setHasMore(data.length == 20);
          setScrollLast(true);
        } else {
          setIsLoading(false);
          fetchPage && setRecentWatchedVideoList([]);
          setHasMore(false);
          setScrollLast(false);
          setPageNo(0);
        }
      }
    } catch (err) {
      setIsLoading(false);
      setHasMore(false);
      setScrollLast(false);
    }
    isOpenSortBy && closeModal();
  }

  async function recentlyPlaylistFun(fetchPage = 0) {
    if (
      ![
        "NEWLY_ADDED",
        "LAST_MODIFIED_DATE",
        "PREVIOUSLY_ADDED",
        "A_TO_Z",
        "Z_TO_A",
      ].includes(selectedFilterOp)
    ) {
      setSelectedFilterOp(SortingOptions.NEWLY_ADDED);
      return;
    }
    try {
      if (auth) {
        const params = {
          pageNo: fetchPage ? fetchPage - 1 : pageNo,
          pageSize: 20,
          searchValue: searchText,
          sortColumn: selectedFilterOp,
          sortDirection: "",
        };
        setIsLoading(true);

        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.RECENTLYPLAYLIST}`,
          params
        );
        if (data.length > 0) {
          // setRecentlyPlayListData(
          //   fetchPage ? data : (prev) => [...prev, ...data]
          // );
          setIsLoading(false);
          setRecentlyPlayListData(
            RemoveDuplicates(
              fetchPage ? data : recentlyPlayListData.concat(data)
            )
          );

          setPageNo(fetchPage ? fetchPage : pageNo + 1);
          setHasMore(data.length == 20);
          setScrollLast(true);
        } else {
          setIsLoading(false);
          fetchPage && setRecentlyPlayListData([]);
          setHasMore(false);
          setScrollLast(false);
          setPageNo(0);
        }
      }
    } catch (err) {
      setIsLoading(false);
      setHasMore(false);
      setScrollLast(false);
    }
    isOpenSortBy && closeModal();
  }

  const handleVideoClick = (item) => {
    navigate(`/videoPlayPage/${item.id}`);
  };

  const addFavorite = async (item, index) => {
    if (auth) {
      recentWatchedVideoList[index].myFavourite = item.myFavourite
        ? false
        : true;
      try {
        const { data } = await apiCall("POST", `/my-favourites/${item.id}`);
        if (data) {
          setRefresh(!refresh);
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
      }
    } else {
      // setGoogleLoginShow(true)
    }
  };

  const videoWatchLater = async (item, index) => {
    if (auth) {
      recentWatchedVideoList[index].watchLater = item.watchLater ? false : true;
      try {
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.VIDEOWATCHLATER}/${item.id}`
        );
        if (data) {
          setRefresh(!refresh);
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
      }
    } else {
      // setGoogleLoginShow(true)
    }
  };

  const RemoveWatchLaterVideo = async (item, index) => {
    if (auth) {
      recentWatchedVideoList[index].watchLater = item.watchLater ? false : true;
      try {
        const { data } = await apiCall(
          "DELETE",
          `${ApiEndPoint.WATCHLATEDREMOVE}/${item.id}`
        );
        if (data) {
          setRefresh(!refresh);
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
      }
    } else {
      // setGoogleLoginShow(true)
    }
  };

  function takeSubcription(params) {
    params?.subscriptionName
      ? confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="card">
                <div className="info__padding">
                  <h3>
                    {`Please Subscribe to ${params?.subscriptionName} plan to play this video. `}
                  </h3>
                  <h3>{!auth ? "Sign In To Subscribe..." : ""}</h3>
                </div>
                {!auth ? (
                  <>
                    <div className="button__group">
                      <Button
                        onClick={() => {
                          // signIn();
                          // onClose();
                        }}
                        variant="contained"
                        className="signBtnHome"
                      >
                        {/* <img className="g-icon" src={Google}></img> */}
                        Sign-in
                      </Button>
                      <button
                        className="no-btn"
                        // onClick={onClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="button__group">
                      <Button
                        onClick={() => {
                          navigate("/subscriptionPlanList");
                          onClose();
                        }}
                        variant="contained"
                        className="subscriptionBtn"
                        // className="signBtnHome"
                      >
                        {/* <img className="g-icon" src={Google}></img> */}
                        Subscribe
                      </Button>
                      <button className="no-btn" onClick={onClose}>
                        Cancel
                      </button>
                    </div>
                  </>
                )}
              </div>
            );
          },
        })
      : confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="card">
                <div className="info__padding">
                  <h3>{`This Video is not yet available`}</h3>
                </div>
                <div className="button__group">
                  <button className="button btn-ok" onClick={onClose}>
                    Ok
                  </button>
                </div>
              </div>
            );
          },
        });
  }

  function handleSearch(e) {
    if (!emojiRegex.test(e.target.value)) {
      if (e.target.value) {
        setSearchText(e.target.value);
      } else {
        setSearchText(null);
      }
    }
  }

  const handleTabChange = (event, newValue) => {
    setPageNo(0);
    setHasMore(true);
    setScrollLast(true);
    setTabValue(newValue);
    const updateArray = activetabs.map((item) => {
      if (item.page === "recentWatchedVideos") {
        return {
          ...item,
          active: newValue,
        };
      } else {
        return {
          ...item,
          active: "videos",
        };
      }
    });
    setActiveTabs(updateArray);
  };

  const StyledTab = styled(Tab)({
    "&.Mui-selected": {
      color: "#FFB200",
      fontSize: 15,
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      textTransform: "uppercase",
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 5,
      alignItems: "flex-start",
      marginRight: 15,
      justifyContent: "end",
    },

    "&.MuiTab-root": {
      fontSize: 15,
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      textTransform: "uppercase",
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 5,
      alignItems: "flex-start",
      marginRight: 15,
      justifyContent: "end",
      minWidth: "auto",
    },
  });

  async function watchLaterSavePlayListFun(id) {
    if (auth) {
      try {
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.PLAYLISTWATCHLATERSAVE}/${id}`
        );
        if (data.length > 0) {
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
      }
    }
  }

  const individualPlayListNav = (item, type) => {
    watchLaterSavePlayListFun(item.id);
    navigate("/individualPlayList", {
      state: { playListDetails: item, playListType: type },
    });
  };

  async function myFavouritePlaylist(item, index) {
    try {
      if (auth) {
        recentlyPlayListData[index].favourite = item.favourite ? false : true;
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.MYFAVOURITEPLAYLIST}/${item.id}`
        );
        if (data.length > 0) {
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }
  async function myFavouritePlaylistRemove(item, index) {
    try {
      if (auth) {
        recentlyPlayListData[index].favourite = item.favourite ? false : true;
        const { data } = await apiCall(
          "DELETE",
          `${ApiEndPoint.MYFAVOURITEPLAYLISTREMOVE}/${item.id}`
        );
        if (data.length > 0) {
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }

  async function PlaylistWatchLaterRemove(item, index) {
    try {
      if (auth) {
        recentlyPlayListData[index].watchLater = item.watchLater ? false : true;
        const { data } = await apiCall(
          "DELETE",
          `${ApiEndPoint.PLAYLISTWATCHLATERREMOVE}/${item.id}`
        );
        if (data.length > 0) {
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }

  async function PlaylistWatchLater(item, index) {
    try {
      if (auth) {
        recentlyPlayListData[index].watchLater = item.watchLater ? false : true;
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.PLAYLISTWATCHLATER}/${item.id}`
        );
        if (data.length > 0) {
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }

  const openModal = () => {
    setIsOpenSortBy(true);
  };

  const closeModal = () => {
    setIsOpenSortBy(false);
  };

  function MinutesToHoursMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return hours + ":" + remainingMinutes;
  }

  // Event handler for radio button change
  const handleRadioChange = (event) => {
    setSelectedFilterOp(event.target.value);
  };
  function capitalizeFirstWord(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const handleToggleListView = () => {
    setIsListView((prevView) => !prevView);
  };
  return (
    <>
      <InfiniteScroll
        dataLength={
          tabValue === "videos"
            ? recentWatchedVideoList?.length
            : recentlyPlayListData?.length
        }
        next={() => fetchMoreData()}
        hasMore={hasMore}
        loader={
          scrollLast ? (
            isLoading && (
              <center>
                <img
                  src={loader}
                  alt="loading..."
                  style={{ height: 25, width: 25, marginTop: 20 }}
                />
              </center>
            )
          ) : (
            <p className="subtitle fancy">
              <span>End of results</span>
            </p>
          )
        }
      >
        <div className="recent-watched-play-outer-wrap">
          <div className="recent-watched-title-mt-20">
            <span className="recent-watched-d-flex">
              <img
                className="recent-watched-c-pointer"
                src={AllImages.YellowArrow}
                onClick={() => navigate(-1)}
                alt="Back Arrow"
                title="Back Arrow"
              ></img>
              <p className="recent-watched-title">Recently Watched</p>
            </span>
          </div>
          <div className="recent-watched-tab-div">
            <Box className="recent-watched-tab-a-center">
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#FFB200",
                    minWidth: "auto",
                  },
                }}
                aria-label="secondary tabs example"
              >
                <StyledTab value="videos" label="Satsang" />
                <StyledTab value="playlists" label="Playlists" />
              </Tabs>
            </Box>
            <div className="search-wrap">
              <div className="search-input">
                <span onClick={fetchData} className="search-icon">
                  {isLoading ? (
                    <img
                      src={loader}
                      alt="loading..."
                      style={{ height: 15, width: 15 }}
                    />
                  ) : (
                    <div className="searchTooltip">
                      <img src={AllImages.Search} alt="Search" title="Search" />
                      <span className="tooltiptext">{"Search"}</span>
                    </div>
                  )}
                  {/* <img src={AllImages.Search} alt="Search" title="Search" /> */}
                </span>
                <input
                  className="f-poppins"
                  type="text"
                  placeholder="Search for Video"
                  onChange={(e) => handleSearch(e)}
                  onKeyUp={handleKeyPress}
                />
              </div>
              <div className="searchIcon-outer">
                <img
                  src={AllImages.SearchIcon}
                  alt="Search"
                  title="Search"
                  className="searchIcon"
                />
              </div>
              <div
                className="filter-icon sortbyTooltip"
                onClick={() => openModal()}
              >
                <img src={AllImages.SearchToggle} alt="Search" title="Search" />
                <span className="tooltiptext">{"Sort By"}</span>
              </div>
              <span 
              className="view-toggle"
              onClick={handleToggleListView}
            >
              { isListView ? (
                <CiGrid2V  strokeWidth={1} size={20} color="#F6AE06" className="viewToggleIcon" />
              ) : (
                <CiCircleList strokeWidth={1}  size={20} color="#F6AE06" className="viewToggleIcon" />
              )}
            </span>
            </div>
          </div>
          {/*----- Videos, Playlists Tab Bar and Search Bar -----*/}

          {tabValue == "videos" && (
            <div className={`custom-row ${isListView ? `listView` : null}`}>
              {recentWatchedVideoList.length > 0
                ? recentWatchedVideoList?.map((item, index) => (
                    <div className="custom-col-4 custom-col">
                      <div
                        className="recent-watched-play-card-wrap"
                        key={index}
                      >
                        <div
                          className="recent-watched-play-inside-wrap"
                          style={{ position: "relative" }}
                        >
                          {item.videoUrl ? (
                            <div
                              className="recent-watched-play-list-image cursor"
                              onClick={() => handleVideoClick(item)}
                            >
                              <img
                                // className="recent-watched-img-banner"
                                className={`recent-watched-img-banner ${
                                  getImageNameFromResolution(
                                    item?.thumbnails
                                  ) != "maxresdefault" && "image-resolution"
                                }`}
                                src={
                                  item?.thumbnails
                                    ? `${item?.thumbnails}`
                                    : `${AllImages.DefaultThumbnail}`
                                }
                                key={item.id}
                                alt="Card Image"
                                title="Card Image"
                              />
                              <div className="playIconTooltip">
                                <img
                                  className="recent-watched-play-play-icon"
                                  src={AllImages.PlayIcon}
                                  alt="play Icon"
                                  title="Play Icon"
                                />
                                <span className="tooltiptext">
                                  {"Play Video"}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="recent-watched-play-list-image"
                              style={{ position: "static" }}
                            >
                              <div onClick={() => takeSubcription(item)}>
                                <img
                                  // className='recent-watched-img-banner'
                                  className={`recent-watched-img-banner ${
                                    getImageNameFromResolution(
                                      item?.thumbnails
                                    ) != "maxresdefault" && "image-resolution"
                                  }`}
                                src={
                                  item?.thumbnails
                                    ? `${item?.thumbnails}`
                                    : `${AllImages.DefaultThumbnail}`
                                }
                                alt="Banner"
                                title="Banner"
                              />
                              <div className="layerImage"></div>
                            </div>
                            <div className="star1 badgestarInvisible">
                              <div className="LikeCount badgeShow">
                                <i
                                  className="fa fa-lock"
                                // onClick={() => takeSubcription(item)}
                                ></i>
                              </div>
                              <span className="tooltiptext">
                                {"Subscribe for more Videos"}
                              </span>
                            </div>
                          </div>
                        )}
                        <div className="recent-watched-play-card-content-item">
                          <div className="recent-watched-play-video-content">
                            <div>
                              <p className="recent-watched-play-title-text f-poppins">
                                {item.videoTitle}
                              </p>
                              <p className="recent-watched-play-text-hrs f-poppins">
                                {MinutesToHoursMinutes(item?.videoDuration)}{" "}
                                hrs
                              </p>
                            </div>
                            <div className="recent-watched-mt-10 d-flex align-center">
                              <div className="timerTooltip">
                                <img
                                  className="cursor"
                                  onClick={() =>
                                    item?.watchLater
                                      ? RemoveWatchLaterVideo(item, index)
                                      : videoWatchLater(item, index)
                                  }
                                  src={
                                    item?.watchLater
                                      ? AllImages.SelectedTimer
                                      : AllImages.Timer
                                  }
                                  alt="Timer"
                                  title="Timer"
                                ></img>
                                <span className="tooltiptext">
                                  {!item.watchLater
                                    ? "Watch Later"
                                    : "Remove Watch Later"}
                                </span>
                              </div>
                              <div className="favTooltip">
                                <img
                                  className="cursor"
                                  src={
                                    item?.myFavourite
                                      ? AllImages.Favorite_sel
                                      : AllImages.Fav
                                  }
                                  alt="Favourite"
                                  title="Favourite"
                                  onClick={() => addFavorite(item, index)}
                                ></img>
                                <span className="tooltiptext">
                                  {!item.myFavourite
                                    ? "Mark Favorite"
                                    : "Unfavorite"}
                                </span>
                              </div>
                              <RWebShare
                                data={{
                                  // text: "Like humans, flamingos make friends for life",
                                  url: `${VideoPlaylistShareUrl}/videoPlayPage/${item.id}`,
                                  title: "Video Share",
                                }}
                                onClick={() => console.log("share")}
                              >
                                <div className="copyTooltip">
                                  <img
                                    className="cursor"
                                    src={AllImages.Copy}
                                    alt="Copy"
                                    title="Copy"
                                  ></img>
                                  <span className="tooltiptext">
                                    {"Copy"}
                                  </span>
                                </div>
                              </RWebShare>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
                : !isLoading && <NoDataAvailable />}
            </div>
          )}

          {/*----- Play list Card  -----*/}
          {tabValue == "playlists" && (
            <div className={`custom-row ${isListView ? `listView` : null}`}>
              {recentlyPlayListData.length > 0
                ? recentlyPlayListData?.map((item, index) => (
                  <div className="custom-col-4 custom-col">
                    <div
                      className="recent-watched-play-card-wrap"
                      key={index}
                    >
                      <div
                        className="recent-watched-play-inside-wrap"
                        style={{ position: "relative" }}
                      >
                        <div
                          className="recent-watched-play-list-image"
                          onClick={() => {
                            individualPlayListNav(item, "admin");
                          }}
                        >
                          <img
                            className="recent-watched-img-banner"
                            src={
                              item?.thumbnail
                                ? `${GLOBAL_URL}/system/playlist/thumbnail/${item.id}`
                                : `${AllImages.DefaultThumbnail}`
                            }
                            key={item.id}
                            alt="Card Image"
                            title="Card Image"
                          />
                          <div className="playIconTooltip">
                            <img
                              className="recent-watched-play-play-icon"
                              src={AllImages.PlayIcon}
                              alt="play Icon"
                              title="play Icon"
                            />
                            <span className="tooltiptext">
                              {"Play Video"}
                            </span>
                          </div>
                        </div>
                        <div className="recent-watched-play-card-content-item">
                          <div className="recent-watched-play-video-content">
                             <div className="recent-watched-play-video-content-inner">
                              <p className="recent-watched-play-title-text f-poppins">
                                {item.playlistName}
                              </p>
                              <div
                                style={{ gap: "10px" }}
                                className="general-textDetails general-text-hrs f-poppins"
                              >
                                <p className="general-text-hrs">
                                  {/* {item.videoDuration}{" "} */}
                                  {item.videoDuration
                                    ? `${item.videoDuration} hrs`
                                    : "0 hrs"}
                                </p>
                                <p className="general-text-hrs">•</p>
                                <p className="general-text-hrs">
                                  {/* {item.noOfVideos}{" "} */}
                                  {item.noOfVideos
                                    ? `${item.noOfVideos} videos`
                                    : "0 videos"}
                                </p>
                              </div>
                            </div>
                            <div className="recent-watched-mt-10 d-flex align-center">
                              <div className="timerTooltip">
                                <img
                                  className="cursor"
                                  onClick={() =>
                                    item.watchLater
                                      ? PlaylistWatchLaterRemove(item, index)
                                      : PlaylistWatchLater(item, index)
                                  }
                                  src={
                                    item.watchLater
                                      ? AllImages.SelectedTimer
                                      : AllImages?.Timer
                                  }
                                  alt="Timer"
                                  title="Timer"
                                ></img>
                                <span className="tooltiptext">
                                  {!item.watchLater
                                    ? "Watch Later"
                                    : "Remove Watch Later"}
                                </span>
                              </div>
                              <div className="favTooltip">
                                <img
                                  className="cursor"
                                  onClick={() =>
                                    item.favourite
                                      ? myFavouritePlaylistRemove(item, index)
                                      : myFavouritePlaylist(item, index)
                                  }
                                  src={
                                    item.favourite
                                      ? AllImages.Favorite_sel
                                      : AllImages?.Fav
                                  }
                                  alt="Favourite"
                                  title="Favourite"
                                ></img>
                                <span className="tooltiptext">
                                  {!item.favourite
                                    ? "Mark Favorite"
                                    : "Unfavorite"}
                                </span>
                              </div>
                              {/* <div className='copyTooltip'>
                              <img
                                className="cursor"
                                src={AllImages.Copy}
                                alt="Copy"
                                title="Copy"
                              ></img>
                              <span className='tooltiptext'>
                                {"Copy"}
                              </span>
                            </div> */}

                              {/* <RWebShare
                            data={{
                              url: `${VideoPlaylistShareUrl}/videoPage/${item.id}`,
                              title: "Video Share",
                            }}
                            onClick={() => console.log('share')}
                          >
                            <img src={AllImages.Copy}></img>
                          </RWebShare> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
                : !isLoading && <NoDataAvailable />}
            </div>
          )}
        </div>
      </InfiniteScroll>

      {isOpenSortBy && (
        <Dialog
          open={openModal}
          className="sortBy"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent
            style={{
              overflowY: "hidden",
              overflowX: "hidden",
            }}
          >
            <div className="qu-history-modal-msg-content">
              <p className="qu-history-modal-msg-text">{"Sort by"}</p>
              <span
                className="qu-history-modal-close-button"
                onClick={() => closeModal()}
              >
                &times;
              </span>
            </div>
            <ul>
              {(tabValue === "videos"
                ? Object.keys(SortingOptions)
                : [
                  "NEWLY_ADDED",
                  "LAST_MODIFIED_DATE",
                  "PREVIOUSLY_ADDED",
                  "Sort_A_To_Z",
                  "Sort_Z_To_A",
                ]
              ).map((key) => (
                <li key={key}>
                  <label className="customRadio">
                    {SortingOptions[key] === "A_TO_Z" ||
                      SortingOptions[key] === "Z_TO_A"
                      ? key.replace(/_/g, " ")
                      : capitalizeFirstWord(
                        key.replace(/_/g, " ").toLowerCase()
                      )}
                    <input
                      type="radio"
                      value={SortingOptions[key]}
                      checked={selectedFilterOp === SortingOptions[key]}
                      onChange={handleRadioChange}
                      name="radio"
                    />
                    <span className="checkmark"></span>
                  </label>
                </li>
              ))}
            </ul>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
export default RecentWatchedVideos;
