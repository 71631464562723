import {
  CurrentTrackIndex,
  MusicContext,
  MusicPlayerVisibilityContext,
  MusicTrackContext,
  MusicTracksContext,
  PlayingAlbumIdContext,
  PlayingShuffledAlbumIdContext,
  PlayNextContext,
  userDetailsContext,
  UserloggedContext,
  AdvanceFilterValContext,
  AdvanceSearchTabContext,
  AdvanceSearchTextContext
} from "components/Context/UserContext";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Table } from "reactstrap";
import apiEndPoints from "utils/apiEndPoints";
import { apiCall } from "utils/httpClient";
import { AllImages, BhaktiPadPlaylistShareUrl } from "../../../utils/constants";
import "./style.css";
import { Button } from "@material-ui/core";
import useGoogleLoginFlow from "components/common/useGoogleLoginFlow";
import { confirmAlert } from "react-confirm-alert";
import Google from "../../../assets/images/google.png";
//playlist
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { ShowCreatePlayListContext } from "components/Context/UserContext";
import { toast } from "react-toastify";
import loader from "../../../assets/images/loading_wbg.gif";
import ApiEndPoint from "../../../utils/apiEndPoints";
import { emojiRegex, RemoveDuplicates, VideoPlaylistShareUrl } from "../../../utils/constants";
import { RWebShare } from "react-web-share";
import { useDispatch, useSelector } from 'react-redux';
import { deleteTrack } from "actions/Index";
// import { useDispatch, useSelector } from 'react-redux';
import { addTrack } from "actions/Index";


function Sparsh() {
  const playerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [isMusicPlayerVisible, setIsMusicPlayerVisible] = useContext(
    MusicPlayerVisibilityContext
  );
  const playlistTracks = useSelector(state => state.tempPlaylist.playlistTracks); // Use playlistTracks

  // const [musicData, setMusicData] = useState({
  //   allPads: [],
  //   popularPads: [],
  //   newPads: [],
  //   albums: []
  // });
  const { musicData, setMusicData } = useContext(MusicContext);

  const { track, setTrack } = useContext(MusicTracksContext);
  const { currentTrackIndex, setCurrentTrackIndex } = useContext(CurrentTrackIndex);

  const { singleTrack, setSingleTrack } = useContext(MusicTrackContext);
  const { playNextTrack, setPlayNextTrack } = useContext(PlayNextContext);
  const { playingAlbumId, setPlayingAlbumId } = useContext(PlayingAlbumIdContext);
  const { playingShuffledAlbumId, setPlayingShuffledAlbumId } = useContext(PlayingShuffledAlbumIdContext);


  const [auth, setAuth] = useContext(UserloggedContext);
  const [isLoading, setIsLoading] = useState(false); // State for loading indicator
  const [pageNo, setPageNo] = useState(0); // State for page number
  const [scrollLast, setScrollLast] = useState(true);
  const [scrollHashMap, setScrollHashMap] = useState(false);
  const [isOpenSortBy, setIsOpenSortBy] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { openRecaptcha, recaptchaFun, onChange } = useGoogleLoginFlow();
  const [isOpen, setIsOpen] = useState(false);
  const [showCreatePlayListModel, setShowCreatePlayListModel] = useContext(
    ShowCreatePlayListContext
  );
  const [userPlayList, setUserPlayList] = useState([]);
  const [unselectedPlaylistArray, setUnselectedPlaylistArray] = useState([]);
  const [currentlyPlaylingVidId, setCurrentlyPlaylingVidId] = useState();
  const [
    previouslySelectedUserPlaylistArray,
    setPreviouslySelectedUserPlaylistArray,
  ] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [
    currentlySelectedUserPlaylistArray,
    setCurrentlylySelectedUserPlaylistArray,
  ] = useState([]);
  const [playlistName, setPlaylistName] = useState("");
  const [isValidPlaylistName, setIsValidPlaylistName] = useState(false);
  const [isValidNote, setIsValidNote] = useState(false);
  const [createPlaylistBtnLding, setPlaylistBtnLding] = useState(false);
  const [createNoteBtnLding, setNoteBtnLding] = useState(false);
  const [showSaveInfo, setShowSaveInfo] = useState(false);
  const [noteTitle, setNoteTitle] = useState("Shabdarth");
  const [note, setNote] = useState(null);
  const [startTime, setStartTime] = useState("00:00:00");
  const [endTime, setEndTime] = useState(null);
  const [video, setVideo] = useState();
  const [isPopupVisible, setPopupVisible] = useState(null);
  const dispatch = useDispatch();


  const SortingOptions = {
    NEWLY_ADDED: "NEWLY_ADDED",
    PREVIOUSLY_ADDED: "PREVIOUSLY_ADDED",
    POPULAR_BHAKTIPAD: "POPULAR_VIDEOS",
    Sort_A_To_Z: "A_TO_Z",
    Sort_Z_To_A: "Z_TO_A",
  };


  const [isPopupVisiblePopularBhaktiPad, setPopupVisiblePopularBhaktiPad] = useState(null);
  const [isPopupVisibleCurrentPlyalist, setPopupVisibleCurrentPlyalist] = useState(null);
  const [isPopupVisibleNewReleased, setPopupVisibleNewReleased] = useState(null);
  const [isPopupVisibleAllPad, setPopupVisibleAllPad] = useState(null);
  const [allPadtrackDetailsForShare, setTrackDetailsForShareAllPad] = useState(null);
  const [newReleasedtrackDetailsForShare, setTrackDetailsForShareNewReleased] = useState(null);
  const [popularBhaktiPadtrackDetailsForShare, setTrackDetailsForSharePopularBhaktiPad] = useState(null);
  const [isMobileView, setIsMobileView] = useState(false);
  const [userDetails, setUserDetails] = useContext(userDetailsContext);
  const { TrackId } = useParams();
  const [trackDeatilsUsingParams, setTrackDeatilsUsingParam] = useState();


  // const [planStatus, setPlanStatus] = useState()
  // setPlanStatus()


  useEffect(() => {
    // const lastArray = playlistTracks[playlistTracks.length - 1];
    // console.log('Current state:', playlistTracks);
  }, [playlistTracks]);



  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 991);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);








  useEffect(() => {
    handlePopularTracks();
    handleAllBhaktiPads();
    handleNewReleasePads();
    // handleCurrentlyPlayingPlaylist();
    getAlbums();
  }, []);
  useEffect(() => {
    if (isOpen) {
      fetchUserPlaylistData();
      getSelectedPlaylistData();
    }
  }, [isOpen]);
  //playlist
  const openModal = (id) => {
    if (auth) {
      setIsOpen(true);
      setCurrentlyPlaylingVidId(id);
    } else {
      // takeOtherSubcription();
    }
  };
  const openCreatedPlayListModal = () => {
    setShowCreatePlayListModel(true);
  };
  const closeCreatedPlayListModal = () => {
    setShowCreatePlayListModel(false);
  };
  const validateInput = (text, isNote = false) => {
    const emojiRegexs = emojiRegex; // your emoji regex here;
    if (/^\s+/.test(text)) {
      return false;
    } else if (emojiRegexs.test(text)) {
      return false;
    } else if (!isNote && text.length > 35) {
      return false;
    } else return true;
  };
  const handlePlaylistNameChange = (event) => {
    const inputValue = event.target.value;
    if (validateInput(inputValue)) {
      setPlaylistName(inputValue);
      setIsValidPlaylistName(true);
    } else {
      setIsValidPlaylistName(false);
    }
  };
  const handleNoteChange = (event) => {
    const inputValue = event.target.value;
    if (validateInput(inputValue, true)) {
      setNote(inputValue);
      setIsValidNote(true);
    } else {
      setIsValidNote(false);
    }
  };
  const validateForm = () => {
    if (playlistName == "") {
      return false;
    }
    return true;
  };
  const validateNoteForm = () => {
    if (note == "" || noteTitle == "") {
      return false;
    }
    return true;
  };
  const handleSubmit = async (event) => {
    const valid = validateForm();
    if (auth) {
      if (valid) {
        const params = {
          playListName: playlistName,
          contentType: 'YOUTUBE_TRACK'
        };
        setPlaylistBtnLding(true);
        try {
          const { data } = await apiCall(
            "POST",
            `${ApiEndPoint.CREATEUSERPLAYLIST}`,
            params
          );
          if (data) {
            setShowCreatePlayListModel(false);
            setPlaylistName(null);
            setRefresh(!refresh);
            setPlaylistBtnLding(false);
            openModal(currentlyPlaylingVidId);
            fetchUserPlaylistData();
          } else {
            setRefresh(!refresh);
            setPlaylistBtnLding(false);
          }
        } catch (err) {
          setShowCreatePlayListModel(false);
          setRefresh(!refresh);
          setPlaylistBtnLding(false);
        }
      } else {
        setIsValidPlaylistName(true);
      }
    } else {
    }
    // setIsOpen(false);
  };
  async function getSelectedPlaylistData() {
    if (auth) {
      try {
        setIsLoading(true);
        const { data } = await apiCall(
          "GET",
          `${ApiEndPoint.GETCHECKEDCHECKBOXFORAUDIO}/${currentlyPlaylingVidId}`
        );
        if (data.length > 0) {
          setIsLoading(false);
          setPreviouslySelectedUserPlaylistArray(data);
        } else {
          setPreviouslySelectedUserPlaylistArray([]);
        }
      } catch (err) {
        setIsLoading(false);
      }
    }
  }
  const fetchUserPlaylistData = () => {
    userPlayListFun(1);
  };
  async function userPlayListFun(fetchPage = 0) {
    if (auth) {
      try {
        const params = {
          searchValue: "",
          sortColumn: "A_TO_Z",
          pageNo: fetchPage ? fetchPage - 1 : pageNo,
          pageSize: 8,
          sortDirection: "",
        };
        setIsLoading(true);
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.USERPLAYLISTFORAUDIO}`,
          params
        );
        if (data.length > 0) {
          setIsLoading(false);
          setUserPlayList(fetchPage ? data : (prev) => [...prev, ...data]);
          setUserPlayList(
            RemoveDuplicates(fetchPage ? data : userPlayList.concat(data))
          );
          setPageNo(fetchPage ? fetchPage : pageNo + 1);
          setScrollHashMap(true);
          setScrollLast(true);
        } else {
          setIsLoading(false);
          setScrollLast(false);
          fetchPage && setUserPlayList([]);
          setPageNo(0);
          setScrollHashMap(false);
        }
      } catch (err) {
        setIsLoading(false);
        // setScrollLast(false);
      }
    }
  }
  async function addVideoAgainstPlaylist() {
    if (auth) {
      if (
        currentlySelectedUserPlaylistArray.length > 0 ||
        unselectedPlaylistArray.length > 0
      ) {
        try {
          const params = {
            videoId: currentlyPlaylingVidId,
            selected: currentlySelectedUserPlaylistArray,
            unSelected: unselectedPlaylistArray,
            contentType: "YOUTUBE_TRACK",
          };
          setIsLoading(true);
          const { status } = await apiCall(
            "POST",
            `${ApiEndPoint.ADDAUDIOAGAINSTMULTIPLEPLAYLIST}`,
            params
          );
          if (status === 200) {
            setIsLoading(false);
            toast.success("Added successfully");
            closeModal();
          } else {
            setIsLoading(false);
            toast.error("Something went wrong , try again");
          }
        } catch (err) {
          setIsLoading(false);
        }
      }
    }
  }
  const handleCheckboxChange = (event) => {
    const playlistId = parseInt(event.target.dataset.playlistId);
    const checked = event.target.checked;
    !isPlaylistSelected(playlistId)
      ? setCurrentlylySelectedUserPlaylistArray((prevSelectedPlaylist) => {
        let updatedSelectedPlaylist = [...prevSelectedPlaylist];
        if (checked) {
          if (
            !updatedSelectedPlaylist.some(
              (playlist) => playlist?.id === playlistId
            )
          ) {
            updatedSelectedPlaylist.push({ id: playlistId });
          }
        } else {
          updatedSelectedPlaylist = updatedSelectedPlaylist.filter(
            (playlist) => playlist.id !== playlistId
          );
        }
        return updatedSelectedPlaylist;
      })
      : removeFromExisting(playlistId);
  };
  const removeFromExisting = (playlistId) => {
    if (
      previouslySelectedUserPlaylistArray.some(
        (playlist) => playlist.id === playlistId
      )
    ) {
      const updatedExistingPlaylist =
        previouslySelectedUserPlaylistArray.filter(
          (playlist) => playlist.id !== playlistId
        );
      setPreviouslySelectedUserPlaylistArray(updatedExistingPlaylist);
      setUnselectedPlaylistArray((prev) => {
        return [...prev, { id: playlistId }];
      });
    } else if (
      currentlySelectedUserPlaylistArray.some(
        (playlist) => playlist.id === playlistId
      )
    ) {
      const updateSelectedPlaylistData =
        currentlySelectedUserPlaylistArray.filter(
          (playlist) => playlist.id !== playlistId
        );
      setCurrentlylySelectedUserPlaylistArray(updateSelectedPlaylistData);
    } else {
      return;
    }
  };
  const isPlaylistSelected = (playlistId) => {
    return (
      previouslySelectedUserPlaylistArray.some(
        (item) => item?.id == playlistId
      ) ||
      currentlySelectedUserPlaylistArray.some((item) => item?.id === playlistId)
    );
  };
  const fetchMoreUserPlaylistData = () => {
    userPlayListFun();
  };
  const createPlaylist = () => {
    setIsOpen(false);
    // navigate("/CreatedPlaylistForAudio");
    setShowCreatePlayListModel(true);
  };
  const closeModal = () => {
    setPreviouslySelectedUserPlaylistArray([]);
    setCurrentlylySelectedUserPlaylistArray([]);
    setUnselectedPlaylistArray([]);
    setIsOpen(false);
  };
  function hanldePopularPads() {
    if (auth) {
      navigate("/popularBhaktiPads");
      // setIsMusicPlayerVisible(true);
    } else {
      takeSubscription();
    }
  }
  function handleCurrentlyPlayingPlaylist() {
    if (auth) {
      navigate("/currentlyPlayingAudioPlaylist");
      // setIsMusicPlayerVisible(true);
    } else {
      takeSubscription();
    }
  }
  function handleAllPads() {
    if (auth) {
      navigate("/allBhaktiPads");
      // setIsMusicPlayerVisible(true);
    } else {
      takeSubscription();
    }
  }
  function handleNewPads() {
    if (auth) {
      navigate("/newReleasePads");
      // setIsMusicPlayerVisible(true);
    } else {
      takeSubscription();
    }
  }
  function handleAlbumPads() {
    if (auth) {
      navigate("/albumList");
      // setIsMusicPlayerVisible(true);
    } else {
      takeSubscription();
    }
  }
  const handlePopularTracks = async () => {
    try {
      const body = {
        pageNo: 0,
        pageSize: 3,
        sortColumn: SortingOptions.POPULAR_BHAKTIPAD,
        sortDirection: "",
        searchValue: null,
      };
      const res = await apiCall(
        "POST",
        `${apiEndPoints.ALLTRACKS}`, body
      );
      const data = res.data.content;
      setMusicData(prevMusicData => ({
        ...prevMusicData,
        popularPads: data
      }));
      // setPopularPads(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleAllBhaktiPads = async () => {
    try {
      const body = {
        pageNo: 0,
        pageSize: 5,
        sortColumn: "",
        sortDirection: "",
        searchValue: null,
      };
      const res = await apiCall(
        "POST",
        `${apiEndPoints.ALLTRACKS}`, body
      );
      const data = res.data.content;
      setMusicData(prevMusicData => ({
        ...prevMusicData,
        allPads: data
      }));
      // setAllPads(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getAlbums = async () => {
    try {
      const body = {
        pageNo: 0,
        pageSize: 5,
        sortColumn: "id,desc",
        sortDirection: "",
        searchValue: null,
      };
      // const page = 0;
      // const size = 5;
      // const sort = "id,desc";
      const res = await apiCall(
        "POST",
        `${apiEndPoints.GETALLALBUMS}`, body
      );
      const data = res?.data?.content;
      setMusicData(prevMusicData => ({
        ...prevMusicData,
        albums: data
      }));
      // setAlbums(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleNewReleasePads = async () => {
    try {
      const body = {
        pageNo: 0,
        pageSize: 3,
        sortColumn: SortingOptions.NEWLY_ADDED,
        sortDirection: "",
        searchValue: null,
      };
      // const page = 0;
      // const size = 3;
      // const sort = "published_date,desc";
      const res = await apiCall(
        "POST",
        `${apiEndPoints.ALLTRACKS}`, body
      );
      const data = res.data.content;
      setMusicData(prevMusicData => ({
        ...prevMusicData,
        newPads: data
      }));
      // setNewPads(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleTitleClick = (item) => {
    setSingleTrack([])
    setTrack([])
    setPlayNextTrack([])
    setPlayingShuffledAlbumId(null)
    setPlayingAlbumId(null)
    setPlayingAlbumId(null);
    let trackList = [];
    trackList[0] = item;
    setTrack(trackList);
    setIsMusicPlayerVisible(true);
  };

  const handleFavoriteTracks = async (item, type, action) => {
    try {
      setMusicData((prevMusicData) => {
        let updatedMusicData = { ...prevMusicData };
        if (type === 'popularPads') {
          const watchIndex = updatedMusicData.popularPads.findIndex((x) => x.id === item.id);
          if (watchIndex !== -1) {
            updatedMusicData.popularPads[watchIndex].isFavourite = !updatedMusicData.popularPads[watchIndex].isFavourite;
          }
        } else if (type === 'albums') {
          const watchIndex = updatedMusicData.albums.findIndex((x) => x.id === item.id);
          if (watchIndex !== -1) {
            updatedMusicData.albums[watchIndex].isFavourite = !updatedMusicData.albums[watchIndex].isFavourite;
          }
        } else if (type === 'newPads') {
          const watchIndex = updatedMusicData.newPads.findIndex((x) => x.id === item.id);
          if (watchIndex !== -1) {
            updatedMusicData.newPads[watchIndex].isFavourite = !updatedMusicData.newPads[watchIndex].isFavourite;
          }
        } else if (type === 'allPads') {
          const watchIndex = updatedMusicData.allPads.findIndex((x) => x.id === item.id);
          if (watchIndex !== -1) {
            updatedMusicData.allPads[watchIndex].isFavourite = !updatedMusicData.allPads[watchIndex].isFavourite;
          }
        }
        return updatedMusicData;
      });
      var apiUrl = ''
      if (type === 'albums') {
        apiUrl =
          action === "add"
            ? `${apiEndPoints.ALBUMSFAVORITE}/${item.id}`
            : `${apiEndPoints.ALBUMSFAVORITEDELETE}/${item.id}`;
      }
      else {
        apiUrl =
          action === "add"
            ? `${apiEndPoints.TRACKSFAVORITE}/${item.id}`
            : `${apiEndPoints.TRACKSFAVORITEDELETE}/${item.id}`;
      }
      const method = action === "add" ? "POST" : "DELETE";
      const response = await apiCall(method, apiUrl);
      if (response) {
      }
    } catch (error) {
      console.error("Error updating favorite tracks:", error);
    }
  };

  function takeSubscription() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card">
            <div className="info__padding">
              <h3>{`Please Subscribe to Paid Yearly (12 months) plan to play this Bhakti Pad`}</h3>
              {/* <h3>{!auth ? "Sign In" : ""}</h3> */}
            </div>
            {!auth ? (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      onChange({ true: true });
                      onClose();
                    }}
                    variant="contained"
                    className="signBtnHome"
                  >
                    <img
                      style={{ height: "23px", width: "23px" }}
                      className="g-icon"
                      src={Google}
                      alt="img"
                    ></img>
                    Sign-in
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      navigate("/subscriptionPlanList");
                      onClose();
                    }}
                    variant="contained"
                    className="subscriptionBtn"
                  // className="signBtnHome"
                  >
                    {/* <img className="g-icon" src={Google}></img> */}
                    Subscribe
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        );
      },
    });
  }

  function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = remainingMinutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
  }

  const togglePopupPopularBhaktiPad = (id) => {
    setPopupVisiblePopularBhaktiPad(isPopupVisiblePopularBhaktiPad === id ? null : id);
  };


  const toggleCurrentPlaylist = (id) => {
    setPopupVisibleCurrentPlyalist(isPopupVisibleCurrentPlyalist === id ? null : id);
  };


  // const handlePlayNext = (trackDetails) => {
  //   if (!isMusicPlayerVisible) {
  //     toast.warn("No Bhakti pad is playing.");
  //     setPopupVisible(null);
  //     setPopupVisiblePopularBhaktiPad(null);
  //     setPopupVisibleAllPad(null);
  //     setPopupVisibleNewReleased(null);
  //     return
  //   }
  //   if (trackDetails) {
  //     const newTrackList = [...track];
  //     newTrackList.splice(currentTrackIndex + 1, 0, trackDetails);
  //     setTrack(newTrackList);
  //   }
  //   setPopupVisible(null);
  //   setPopupVisiblePopularBhaktiPad(null);
  //   setPopupVisibleAllPad(null);
  //   setPopupVisibleNewReleased(null);

  //   toast.success("Bhakti Pad will Play Next.");
  // };

  const handlePlayNext = (trackDetails) => {
    if (!isMusicPlayerVisible) {
      toast.warn("No Bhakti pad is playing.");
      setPopupVisible(null);
      setPopupVisiblePopularBhaktiPad(null);
      setPopupVisibleAllPad(null);
      setPopupVisibleNewReleased(null);
      return
    }  
    // const trackDetails = musicData?.find(track => track?.id == id);
    if (trackDetails) {
      let newTrackList = [...track];
      const existingTrackIndex = newTrackList.findIndex(track => track.id === trackDetails?.id);
      if (existingTrackIndex !== -1) {
        newTrackList.splice(existingTrackIndex, 1);
      }
      newTrackList.splice(currentTrackIndex + 1, 0, trackDetails);
      setTrack(newTrackList);
      dispatch(addTrack(newTrackList));
      setPopupVisible(null);
      setPopupVisiblePopularBhaktiPad(null);
      setPopupVisibleAllPad(null);
      setPopupVisibleNewReleased(null);

    toast.success("Bhakti Pad will Play Next.");
    }
    setPopupVisible(null);
  };

  const handleCopyLinkPopularBhaktiPad = (id) => {
    const trackDetails = musicData?.popularPads?.find(track => track?.id == id);
    setTrackDetailsForSharePopularBhaktiPad(trackDetails?.audioAlbum?.id)
    // console.log(`Copy link for track: ${id}`);
    // setPopupVisiblePopularBhaktiPad(null);
  };

  const togglePopupNewReleased = (id) => {
    setPopupVisibleNewReleased(isPopupVisibleNewReleased === id ? null : id);
  };

  const popupRef = useRef(null);

  const togglePopup = (id) => {
    setPopupVisible(isPopupVisible === id ? null : id);
  }
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
     setTimeout(() => {
       setPopupVisibleNewReleased(null);
       setPopupVisibleAllPad(null)
       setPopupVisiblePopularBhaktiPad(null)
     }, 500);
    }
  }

  useEffect(() => {
    if (isPopupVisibleAllPad || isPopupVisibleNewReleased || isPopupVisiblePopularBhaktiPad !== null) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPopupVisibleAllPad || isPopupVisibleNewReleased || isPopupVisiblePopularBhaktiPad]);

  const handleCopyLinkNewReleased = (id) => {
    const trackDetails = musicData?.newPads?.find(track => track?.id == id);
    setTrackDetailsForShareNewReleased(trackDetails?.audioAlbum?.id)
    // console.log(`Copy link for track: ${id}`);
    // setPopupVisibleNewReleased(null);
  };


  const togglePopupAllPad = (id) => {
    setPopupVisibleAllPad(isPopupVisibleAllPad === id ? null : id);
  };


  const handleCopyLinkAllPad = (id) => {
    const trackDetails = musicData?.allPads.find(track => track?.id == id);
    setTrackDetailsForShareAllPad(trackDetails?.audioAlbum?.id)
    // console.log(`Copy link for track: ${id}`);
    // setPopupVisibleAllPad(null);
  };


  const formatDuration = (duration) => {
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  }
  

  
  const [isValid, setIsValid] = useState(true);
  const [advaceFilterVal, setAdvaceFilterVal] = useContext(AdvanceFilterValContext);
  const [advaceTabValue, setAdvaceTabValue] = useContext(AdvanceSearchTabContext);
  const [searchValue, setSearchValue] = useContext(AdvanceSearchTextContext);
  const [isOpenAdvance, setIsOpenAdvance] = useState(false);



  const deleteItem = (trackId) => {
    dispatch(deleteTrack(trackId));
  };
  const handleSearchBox = (event) => {
    setSearchValue(event.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      advanceSearch();
    }
  };





  const openAdvanceModal = () => {
    // if (searchValue == null || searchValue == '') {
    //   setIsValid(false);
    // } else {
    setIsValid(true);
    setIsOpenAdvance(true);
    // }
  };






  async function advanceSearch(value) {
    setIsValid(true);
    const path = location.pathname;
    const pathSegments = path.split('/').filter(Boolean);
    const isSparsh = pathSegments.includes('sparsh');
    const params = advaceFilterVal.filter(
      (item) => item.contentType == 'YOUTUBE_ALBUM'
    )[0];
    const navigateUrl =
      `/advanceSearch/${encodeURIComponent(
        'YOUTUBE_ALBUM'
      )}/${encodeURIComponent(
        searchValue?.trim() === "" ? null : searchValue
      )}?` +
      `${params.startDuration
        ? `startDuration=${encodeURIComponent(params.startDuration)}`
        : ""
      }` +
      `${params.endDuration
        ? `&endDuration=${encodeURIComponent(params.endDuration)}`
        : ""
      }` +
      `${params.location
        ? `&videolocation=${encodeURIComponent(params.location)}`
        : ""
      }` +
      `${params.startDate
        ? `&startDate=${encodeURIComponent(params.startDate)}`
        : ""
      }` +
      `${params.videotype
        ? `&videotype=${encodeURIComponent(params.videotype)}`
        : ""
      }` +
      `${params.title ? `&title=${encodeURIComponent(params.title)}` : ""}` +
      `${params.endDate ? `&endDate=${encodeURIComponent(params.endDate)}` : ""
      }` +
      `${params.language
        ? `&language=${encodeURIComponent(params.language)}`
        : ""
      }` +
      `${params.speaker ? `&speaker=${encodeURIComponent(params.speaker)}` : ""
      }` +
      `${params.uploadDuration
        ? `&uploadDuration=${encodeURIComponent(params.uploadDuration)}`
        : ""
      }`;


    // navigate(navigateUrl);
    if (isSparsh) {
      navigate(navigateUrl + "&onlyShowTabs=YOUTUBE_ALBUM,YOUTUBE_TRACK");
    } else {
      navigate(navigateUrl);
    }
  }


  return (
    <section className={`sparsh-page${isMusicPlayerVisible ? " ytPlayerOpen" : ""}`}>
      <div class="top">
        <img src={AllImages?.SparshTopImg} class="topImg" />
        <p class="label">sparsh international</p>
      </div>


      <div className="home-search-box-mobile" style={{ marginTop: '20px', marginLeft: '-15px' }}>
      <div className="home-search-wrap">
        <div className={`search-input ${isValid ? "" : "invalid"}`}>
          <span onClick={() => advanceSearch(1)} className="search-icon">
            <img src={AllImages.Search} alt="Search" title="Search" />
          </span>
          <input
            className="f-poppins"
            type="text"
            placeholder="Search for Bhakti Pad, Albums"
            onChange={handleSearchBox}
            value={searchValue}
            onKeyUp={handleKeyPress}
          />
        </div>
        <div className="searchIcon-outer">
          <img
            src={AllImages.SearchIcon}
            alt="Search"
            title="Search"
            className="searchIcon"
          />
        </div>
      </div>
    </div>



      {isOpen && (
        <Dialog
          open={openModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          scroll={"paper"}
        >
          <DialogContent
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              maxHeight: "320px",
              margin: 5,
            }}
          >
            <div className="add-my-playlist-modal-content">
              <div className="add-my-playlist-modal-d-flex modal-mb-5">
                <p className="add-my-playlist-modal-msg-text">
                  {"Add to My Playlists"}
                </p>
                <span
                  className="add-my-playlist-modal-close-button"
                  onClick={closeModal}
                >
                  &times;
                </span>
              </div>
              <div>
                {userPlayList &&
                  userPlayList?.map((item, index) => (
                    <div
                      key={item.id}
                      className="add-my-playlist-modal-d-flex modal-mt-15"
                    >
                      <input
                        type="checkbox"
                        id={`checkbox_${item.id}`}
                        name={`checkbox_${item.id}`}
                        data-playlist-id={item.id}
                        checked={isPlaylistSelected(item.id)}
                        onChange={handleCheckboxChange}
                      />
                      <label
                        className="add-my-playlist-checkbox-text"
                        htmlFor={`checkbox_${item.id}`}
                      >
                        {item.playListName}
                      </label>
                    </div>
                  ))}
                {userPlayList && userPlayList.length > 6 && scrollHashMap && (
                  <p
                    onClick={fetchMoreUserPlaylistData}
                    className="fetch-more-playlist"
                  >
                    MORE+
                  </p>
                )}
              </div>
              <div className="modal-mt-15" onClick={createPlaylist}>
                <label className="add-my-playlist-create-playlist">
                  Create Playlist
                </label>
              </div>
            </div>
            {isLoading && (
              <div className="loader">
                <img src={loader} alt="loading..." />
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <div className="add-my-playlist-modal-action">
              <button
                onClick={closeModal}
                className="add-my-playlist-modal-cancel-btn"
              >
                {"Cancel"}
              </button>
              <button
                className="add-my-playlist-modal-add-btn"
                onClick={addVideoAgainstPlaylist}
              >
                {"confirm"}
              </button>
            </div>
          </DialogActions>
        </Dialog>
      )}
      <div class="mainTitleOuter">
        <h1 class="mainTitle">Popular Bhakti Pads</h1>
        <p class="viewMore" onClick={() => {hanldePopularPads()}}>
          view more
        </p>
      </div>
      <Table responsive striped>
        <thead class="mobHide">
          <tr>
            <th>No.</th>
            <th>Title</th>
            <th class="none">Album</th>
            <th class="none">Duration</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody class="mobHide">
          {musicData?.popularPads?.map((item, index) => (
            <tr key={item.id} class="tableHover">

              <td>
                <div class="srNo" onClick={() => {  item?.url  ? handleTitleClick(item) : takeSubscription(item) }}
                >
                  <p class="no mobHide">{index + 1}</p>
                  <img src={item?.thumbnailUrl} class="bhaktiImg" alt="img" />
                  {item?.url   ?

                    <div className="bhaktipadPlayIconTooltip">
                      <img src={AllImages?.PlayIcon} class="trackPlayButton" alt="img" />
                      <div className="bhaktipadPlayIconTooltipInner">
                        <span className="tooltiptext">
                          {"Play Bhakti Pad"}
                        </span>
                      </div>
                    </div>

                    :
                    <div className="star1 badgestarInvisible tableLock">
                      <div className="LikeCount badgeShow">
                        <i
                          className="fa fa-lock"
                          onClick={() => takeSubscription(item)}
                        ></i>
                      </div>
                      <span className="tooltiptext">
                        {"Subscribe to use this feature"}
                      </span>
                    </div>
                  }
                </div>
              </td>

              <td>
                <div
                  class="titleSection"
                  onClick={() => { item?.url  ? handleTitleClick(item) : takeSubscription(item) }}
                >
                  <p class="title">{item.title}</p>
                  <p class="subTitle">{item?.audioAlbum?.title}</p>
                </div>
              </td>

              <td class="none">
                <p class="album">{item?.audioAlbum?.title}</p>
              </td>

              <td class="none">
                <p class="duration">
                  {formatDuration(item.duration)}
                </p>
              </td>

              <td>
                <div className="actionsOuter">
                  <div className="actions">

                    <div className="favTooltip">
                      <img
                        onClick={() => item?.url ?
                          handleFavoriteTracks(item, 'popularPads', item.isFavourite ? 'delete' : 'add')
                          :  takeSubscription(item)
                        }
                        src={
                          item.isFavourite
                            ? AllImages.Favorite_sel
                            : AllImages?.Fav
                        }
                        alt="IMG"
                      ></img>
                      <span className="tooltiptext">
                        {!item.isFavourite ? "Mark Favorite" : "Unfavorite"}
                      </span>
                    </div>


                    <div className="favTooltip">
                      <img
                        className="video-play-mr-10"
                        class="mobHide"
                        alt="img"
                        src={AllImages?.TablePlus}
                        onClick={() => 
                          item?.url ?
                          openModal(item?.id)
                          :  takeSubscription(item)
                        }
                      ></img>
                      <span className="tooltiptext">
                        {"Add to My Playlists"}
                      </span>
                    </div>
                    <div key={item.id} className="popupOuter moreTooltip">
                      <img
                        src={AllImages?.More}
                        className="moreImg"
                        alt="img"

                        onClick={() => 
                          item?.url ?
                          togglePopupPopularBhaktiPad(item?.id)
                          :  takeSubscription(item)
                        }
                        ref={popupRef}
                      />
                      <div className="moreTooltipInner">
                        <span className="tooltiptext">
                          {"View More"}
                        </span>
                      </div>
                    </div>


                  </div>



                  {isPopupVisiblePopularBhaktiPad === item.id && (

                    !isMobileView ? (
                      <div className="morePopup"
                        ref={popupRef}>
                        <div className="outer" onClick={() =>  handlePlayNext(item)}>
                          <img src={AllImages?.Next} className="more" alt="img" />
                          <p className="label">Play Next</p>
                        </div>
                        <div className="outer" onClick={() => handleCopyLinkPopularBhaktiPad(item.id)}>
                          <RWebShare
                            data={{
                              url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                              title: 'Bhakti Pad Share',
                            }}
                            onClick={() => {
                              handleCopyLinkPopularBhaktiPad(item.id);
                              console.log('shares');
                            }}
                          >
                            <div className="copyTooltip">
                              <img src={AllImages?.CopyGray} className="more" id="myCopy" alt="img" />
                              <span className="tooltiptext">Copy</span>
                            </div>
                          </RWebShare>
                          <div>
                            <RWebShare
                              data={{
                                url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                                title: 'Bhakti Pad Share',
                              }}
                              onClick={() => {
                                handleCopyLinkPopularBhaktiPad(item.id);
                                console.log('share');
                              }}
                            >
                              <p className="label">Copy Link</p>
                            </RWebShare>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="mobileViewDialogOuter"
                        ref={popupRef}>
                        <Dialog
                          open={togglePopupPopularBhaktiPad}
                          className="sortBy mobileViewDialog"
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                          ref={popupRef}
                        >
                          <DialogContent
                            style={{
                              overflowY: "hidden",
                              overflowX: "hidden",
                            }}
                            ref={popupRef}
                          >
                            <div className="qu-history-modal-msg-content">
                              <p className="qu-history-modal-msg-text">{"More Option"}</p>
                              <span
                                className="qu-history-modal-close-button"
                                onClick={togglePopupPopularBhaktiPad}
                              >
                                &times;
                              </span>
                            </div>
                            <div class="actionPopupMob"
                              ref={popupRef}>
                              <div class="outer tabHide" onClick={() => item?.url ?
                                handleFavoriteTracks(item, 'popularPads', item.isFavourite ? 'delete' : 'add')
                                :  takeSubscription(item)
                              } ref={popupRef}>
                                <img
                                  src={item.isFavourite ? AllImages.Favorite_sel : AllImages?.Fav}
                                  alt="IMG"
                                  ref={popupRef}
                                ></img>
                                <p class="label">Add to Favourites</p>
                              </div>
                              <div class="outer tabHide" onClick={() => 
                                item?.url ?
                                openModal(item?.id)
                                :  takeSubscription(item)
                              } 
                                ref={popupRef}>
                                <img
                                  className="plus"
                                  alt="img"
                                  src={AllImages?.TablePlus}
                                />
                                <p class="label">Add to Playlist</p>
                              </div>
                              <div className="outer" onClick={() => handlePlayNext(item)} ref={popupRef}>
                                <img src={AllImages?.Next} className="more" alt="img" />
                                <p className="label">Play Next</p>
                              </div>
                              <div className="outer" onClick={() => handleCopyLinkPopularBhaktiPad(item.id)} >
                                <RWebShare
                                  data={{
                                    url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                                    title: 'Bhakti Pad Share',
                                  }}
                                  onClick={() => {
                                    handleCopyLinkPopularBhaktiPad(item.id);
                                    console.log('shares');
                                  }}
                                >
                                  <div className="copyTooltip">
                                    <img src={AllImages?.CopyGray} className="more" id="myCopy" alt="img" />
                                    <span className="tooltiptext">Copy</span>
                                  </div>
                                </RWebShare>
                                <div>
                                  <RWebShare
                                    data={{
                                      url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                                      title: 'Bhakti Pad Share',
                                    }}
                                    onClick={() => {
                                      handleCopyLinkPopularBhaktiPad(item.id);
                                      console.log('share');
                                    }}
                                  >
                                    <p className="label">Copy Link</p>
                                  </RWebShare>
                                </div>
                              </div>

                            </div>
                          </DialogContent>
                        </Dialog>
                      </div>
                    )
                  )}



                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* List of Albums */}
      <div class="mainTitleOuter">
        <h1 class="mainTitle">List of Albums</h1>
        <p class="viewMore" onClick={() => {handleAlbumPads()}}>
          view more
        </p>
      </div>
      <div className="albumOuter">
        {musicData?.albums?.map((album, index) => (
          <div
            className="album"
            key={album?.id}
          >
            <div className={album?.hasFreeSongs ? "albumTop" : "albumTop albumLockOuter"}>             
              <img src={album.thumbnailUrl ? album.thumbnailUrl : AllImages?.Album5} className="albumImg" alt="Album" />
              <div className="albumHover">
                <div className="albumHoverInner">
                  <div className="favTooltip">
                    
                  <img
                    onClick={() => 
                      
                       handleFavoriteTracks(album, 'albums', album.isFavourite ? 'delete' : 'add')
                      
                    }
                    src={album.isFavourite ? AllImages.Favorite_sel : AllImages?.Heart}
                    alt="IMG"
                  />


                    <span className="tooltiptext">
                      {!album.isFavourite ? "Mark Favorite" : "Unfavorite"}
                    </span>
                  </div>

                  <div className="favTooltip">
                    <RWebShare
                      data={{
                        url: `${BhaktiPadPlaylistShareUrl}/playlist/${album.id}`,
                        title: "Playlist Share",
                      }}
                      onClick={() => console.log("share")}
                    >
                      <div className="copyTooltip">
                        <img
                          className="cursor"
                          src={AllImages.CopyPNG}
                          alt="Copy"
                          title="Copy"
                        ></img>
                        <span className="tooltiptext">
                          {"Copy"}
                        </span>
                      </div>
                    </RWebShare>


                  </div>


                </div>
                <div className="albumInnerPlayIconOuter"
                  onClick={() => { album?.hasFreeSongs ? navigate(`/playlist/${album?.id}`) : takeSubscription() }}>
                  {album?.hasFreeSongs ?
                    <div className="bhaktipadPlayIconTooltip">
                      <img src={AllImages?.PlayIconBig} class="albumInnerPlayIcon" alt="img" />
                      <div className="bhaktipadPlayIconTooltipInner">
                        <span className="tooltiptext">
                          {"Play BhaktiPad Playlist"}
                        </span>
                      </div>
                    </div>
                    :
                    <div className="star1 badgestarInvisible albumLock">
                      <div className="LikeCount badgeShow">
                        <i
                          className="fa fa-lock"
                          onClick={() => takeSubscription(album)}
                        ></i>
                      </div>
                      <span className="tooltiptext">
                        {"Subscribe to use this feature"}
                      </span>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="albumBottom"
              onClick={() => { album?.hasFreeSongs ? navigate(`/playlist/${album?.id}`) : takeSubscription() }}>
              <p className="name">{album?.title}</p>
              <div className="desc">
                <p class="time">{convertMinutesToHoursAndMinutes(album?.durationTotalMin)} hrs</p>
                <p className="dot">&nbsp;</p>
                <p className="count"> {album?.totalActiveTracks} bhakti pads</p>
              </div>
            </div>
          </div>
        ))}
      
      </div>
      {/* New Release Bhakti Pads */}
      <div class="mainTitleOuter">
        <h1 class="mainTitle">New Release Bhakti Pads</h1>
        <p class="viewMore" onClick={() => { handleNewPads()}}>
          view more
        </p>
      </div>
      <Table responsive striped>
        <thead class="mobHide">
          <tr>
            <th>No.</th>
            <th>Title</th>
            <th class="none">Album</th>
            <th class="none">Duration</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody class="mobHide">
          {musicData?.newPads?.map((item, index) => (
            <tr key={item.id} class="tableHover">
              <td>
                <div class="srNo" onClick={() => { item?.url ? handleTitleClick(item) : takeSubscription(item) }}
                >
                  <p class="no mobHide">{index + 1}</p>
                  <img src={item?.thumbnailUrl} class="bhaktiImg" alt="img" />
                  {item?.url ?
                    <div className="bhaktipadPlayIconTooltip">
                      <img src={AllImages?.PlayIcon} class="trackPlayButton" alt="img" />
                      <div className="bhaktipadPlayIconTooltipInner">
                        <span className="tooltiptext">
                          {"Play Bhakti Pad"}
                        </span>
                      </div>
                    </div> :
                    <div className="star1 badgestarInvisible tableLock">
                      <div className="LikeCount badgeShow">
                        <i
                          className="fa fa-lock"
                          onClick={() => takeSubscription(item)}
                        ></i>
                      </div>
                      <span className="tooltiptext">
                        {"Subscribe to use this feature"}
                      </span>
                    </div>
                  }
                </div>
              </td>
              <td>
                <div
                  class="titleSection"
                  onClick={() => { item?.url? handleTitleClick(item) : takeSubscription(item) }}
                >
                  <p class="title">{item?.title}</p>
                  <p class="subTitle">{item.audioAlbum?.title}</p>
                </div>
              </td>
              <td class="none">
                <p class="album">{item.audioAlbum?.title}</p>
              </td>
              <td class="none">
                <p class="duration">
                  {formatDuration(item.duration)}
                </p>
              </td>
              <td>
                <div class="actions">

                  <div className="favTooltip">
                    <img
                      onClick={() =>
                        item?.url ?
                        handleFavoriteTracks(item, 'newPads', item.isFavourite ? 'delete' : 'add')
                        :  takeSubscription(item)
                      }
                      src={
                        item.isFavourite
                          ? AllImages.Favorite_sel
                          : AllImages?.Fav
                      }
                      alt="IMG"
                    ></img>
                    <span className="tooltiptext">
                      {!item.isFavourite ? "Mark Favorite" : "Unfavorite"}
                    </span>
                  </div>
                  <div className="favTooltip">
                    <img
                      className="video-play-mr-10"
                      class="mobHide"
                      alt="img"
                      src={AllImages?.TablePlus}
                      onClick={() => 
                        item?.url ?
                        openModal(item?.id)
                        :  takeSubscription(item)

                      }
                    ></img>
                    <span className="tooltiptext">
                      {" "}
                      {"Add to My Playlists"}
                    </span>
                  </div>
                  <div key={item.id} className="popupOuter moreTooltip">
                    <img
                      src={AllImages?.More}
                      className="moreImg"
                      alt="img"
                      onClick={() => 
                        item?.url ?
                        togglePopupNewReleased(item?.id)
                        :  takeSubscription(item)
                      }
                      ref={popupRef}
                    />
                    <div className="moreTooltipInner">
                      <span className="tooltiptext">
                        {"View More"}
                      </span>
                    </div>
                  </div>
                </div>
                {isPopupVisibleNewReleased === item.id && (

                  !isMobileView ? (
                    <div className="morePopup"
                      ref={popupRef}>
                      <div className="outer" onClick={() => handlePlayNext(item)}>
                        <img src={AllImages?.Next} className="more" alt="img" />
                        <p className="label">Play Next</p>
                      </div>
                      <div className="outer" onClick={() => handleCopyLinkNewReleased(item.id)}>
                        <RWebShare
                          data={{
                            url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                            title: 'Bhakti Pad Share',
                          }}
                          onClick={() => {
                            handleCopyLinkNewReleased(item.id);
                            console.log('shares');
                          }}
                        >
                          <div className="copyTooltip">
                            <img src={AllImages?.CopyGray} className="more" id="myCopy" alt="img" />
                            <span className="tooltiptext">Copy</span>
                          </div>
                        </RWebShare>
                        <div>
                          <RWebShare
                            data={{
                              url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                              title: 'Bhakti Pad Share',
                            }}
                            onClick={() => {
                              handleCopyLinkNewReleased(item.id);
                              console.log('share');
                            }}
                          >
                            <p className="label">Copy Link</p>
                          </RWebShare>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="mobileViewDialogOuter" ref={popupRef}>
                      <Dialog
                        open={togglePopupNewReleased}
                        className="sortBy mobileViewDialog"
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        ref={popupRef}
                      >
                        <DialogContent
                          style={{
                            overflowY: "hidden",
                            overflowX: "hidden",
                          }}
                          ref={popupRef}
                        >
                          <div className="qu-history-modal-msg-content">
                            <p className="qu-history-modal-msg-text">{"More Option"}</p>
                            <span
                              className="qu-history-modal-close-button"
                              onClick={togglePopupNewReleased}
                            >
                              &times;
                            </span>
                          </div>
                          <div class="actionPopupMob"
                            ref={popupRef}>
                            <div class="outer tabHide" onClick={() =>
                            item?.url ?
                              handleFavoriteTracks(item, 'newPads', item.isFavourite ? 'delete' : 'add')
                              :  takeSubscription(item)
                            } ref={popupRef}>
                              <img
                                src={item.isFavourite ? AllImages.Favorite_sel : AllImages?.Fav}
                                alt="IMG"
                                ref={popupRef}
                              ></img>
                              <p class="label">Add to Favourites</p>
                            </div>
                            <div class="outer tabHide" 
                            onClick={() => 
                              item?.url ?
                              openModal(item?.id)
                              :  takeSubscription(item)
                            } ref={popupRef}>
                              <img
                                className="plus"
                                alt="img"
                                src={AllImages?.TablePlus}
                              />
                              <p class="label">Add to Playlist</p>
                            </div>
                            <div className="outer" onClick={() => handlePlayNext(item)} ref={popupRef}>
                              <img src={AllImages?.Next} className="more" alt="img" />
                              <p className="label">Play Next</p>
                            </div>
                            <div className="outer" onClick={() => handleCopyLinkNewReleased(item.id)} ref={popupRef}>
                              <RWebShare
                                data={{
                                  url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                                  title: 'Bhakti Pad Share',
                                }}
                                onClick={() => {
                                  handleCopyLinkNewReleased(item.id);
                                  console.log('shares');
                                }}
                              >
                                <div className="copyTooltip">
                                  <img src={AllImages?.CopyGray} className="more" id="myCopy" alt="img" />
                                  <span className="tooltiptext">Copy</span>
                                </div>
                              </RWebShare>
                              <div>
                                <RWebShare
                                  data={{
                                    url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                                    title: 'Bhakti Pad Share',
                                  }}
                                  onClick={() => {
                                    handleCopyLinkNewReleased(item.id);
                                    console.log('share');
                                  }}
                                >
                                  <p className="label">Copy Link</p>
                                </RWebShare>
                              </div>
                            </div>

                          </div>
                        </DialogContent>
                      </Dialog>
                    </div>
                  )
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* All Bhakti Pads */}
      <div class="mainTitleOuter">
        <h1 class="mainTitle">All Bhakti Pads</h1>
        <p class="viewMore" onClick={() => { handleAllPads() }}>
          view more
        </p>
      </div>
      <Table responsive striped>
        <thead class="mobHide">
          <tr>
            <th>No.</th>
            <th>Title</th>
            <th class="none">Album</th>
            <th class="none">Duration</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody class="mobHide">
          {musicData?.allPads?.map((item, index) => (
            <tr key={item.id} class="tableHover">
              <td>
                <div class="srNo" onClick={() => { item?.url ? handleTitleClick(item) : takeSubscription(item) }}
                >
                  <p class="no mobHide">{index + 1}</p>
                  <img src={item?.thumbnailUrl} class="bhaktiImg" alt="img" />
                  {item?.url ?
                    <div className="bhaktipadPlayIconTooltip">
                      <img src={AllImages?.PlayIcon} class="trackPlayButton" alt="img" />
                      <div className="bhaktipadPlayIconTooltipInner">
                        <span className="tooltiptext">
                          {"Play Bhakti Pad"}
                        </span>
                      </div>
                    </div> :
                    <div className="star1 badgestarInvisible tableLock">
                      <div className="LikeCount badgeShow">
                        <i
                          className="fa fa-lock"
                          onClick={() => takeSubscription(item)}
                        ></i>
                      </div>
                      <span className="tooltiptext">
                        {"Subscribe to use this feature"}
                      </span>
                    </div>
                  }
                </div>
              </td>
              <td>
                <div
                  class="titleSection"
                  onClick={() => { item?.url ? handleTitleClick(item) : takeSubscription(item) }}
                >
                  <p class="title">{item?.title}</p>
                  <p class="subTitle">{item.audioAlbum?.title}</p>
                </div>
              </td>
              <td class="none">
                <p class="album">{item.audioAlbum?.title}</p>
              </td>
              <td class="none">
                <p class="duration">
                  {formatDuration(item.duration)}
                </p>
              </td>
              <td>
                <div class="actions">
                  <div className="favTooltip">
                    <img
                      onClick={() =>item?.url ? handleFavoriteTracks(item, 'allPads', item.isFavourite ? 'delete' : 'add') :   takeSubscription()
                      }
                      src={
                        item.isFavourite
                          ? AllImages.Favorite_sel
                          : AllImages?.Fav
                      }
                      alt="IMG"
                    ></img>
                    <span className="tooltiptext">
                      {!item.isFavourite ? "Mark Favorite" : "Unfavorite"}
                    </span>
                  </div>
                  <div className="favTooltip">
                    <img
                      className="video-play-mr-10"
                      class="mobHide"
                      alt="img"
                      src={AllImages?.TablePlus}
                      onClick={() => 
                        item?.url ?
                        openModal(item?.id)
                        :  takeSubscription(item)
                      }
                    ></img>
                    <span className="tooltiptext">
                      {" "}
                      {"Add to My Playlists"}
                    </span>
                  </div>
                  <div key={item.id} className="popupOuter moreTooltip">
                    <img
                      src={AllImages?.More}
                      className="moreImg"
                      alt="img"
                      onClick={() => 
                        item?.url ?
                        togglePopupAllPad(item?.id)
                        :  takeSubscription(item)
                      }
                      ref={popupRef}
                    />
                    <div className="moreTooltipInner">
                      <span className="tooltiptext">
                        {"View More"}
                      </span>
                    </div>
                  </div>
                </div>
                {isPopupVisibleAllPad === item.id && (

                  !isMobileView ? (
                    <div className="morePopup"
                      ref={popupRef}>
                      <div className="outer" onClick={() => handlePlayNext(item)}>
                        <img src={AllImages?.Next} className="more" alt="img" />
                        <p className="label">Play Next</p>
                      </div>
                      <div className="outer" onClick={() => handleCopyLinkAllPad(item.id)}>
                        <RWebShare
                          data={{
                            url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                            title: 'Bhakti Pad Share',
                          }}
                          onClick={() => {
                            handleCopyLinkAllPad(item.id);
                            console.log('shares');
                          }}
                        >
                          <div className="copyTooltip">
                            <img src={AllImages?.CopyGray} className="more" id="myCopy" alt="img" />
                            <span className="tooltiptext">Copy</span>
                          </div>
                        </RWebShare>
                        <div>
                          <RWebShare
                            data={{
                              url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                              title: 'Bhakti Pad Share',
                            }}
                            onClick={() => {
                              handleCopyLinkAllPad(item.id);
                              console.log('share');
                            }}
                          >
                            <p className="label">Copy Link</p>
                          </RWebShare>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="mobileViewDialogOuter"
                      ref={popupRef}>
                      <Dialog
                        open={togglePopupAllPad}
                        className="sortBy mobileViewDialog"
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        ref={popupRef}
                      >
                        <DialogContent
                          style={{
                            overflowY: "hidden",
                            overflowX: "hidden",
                          }}
                          ref={popupRef}
                        >
                          <div className="qu-history-modal-msg-content">
                            <p className="qu-history-modal-msg-text">{"More Option"}</p>
                            <span
                              className="qu-history-modal-close-button"
                              onClick={togglePopupAllPad}
                            >
                              &times;
                            </span>
                          </div>
                          <div class="actionPopupMob"
                            ref={popupRef}
                          >
                            <div class="outer tabHide" onClick={() => item?.url ? handleFavoriteTracks(item, 'allPads', item.isFavourite ? 'delete' : 'add')  : takeSubscription(item)
                            } ref={popupRef}>
                              <img
                                src={item.isFavourite ? AllImages.Favorite_sel : AllImages?.Fav}
                                alt="IMG"
                                ref={popupRef}
                              ></img>
                              <p class="label">Add to Favourites</p>
                            </div>
                            <div class="outer tabHide" onClick={() => 
                            item?.url ?
                              openModal(item?.id)
                              :  takeSubscription(item)
                              } ref={popupRef}>
                              <img
                                className="plus"
                                alt="img"
                                src={AllImages?.TablePlus}
                              />
                              <p class="label">Add to Playlist</p>
                            </div>
                            <div className="outer" onClick={() => handlePlayNext(item)} ref={popupRef}>
                              <img src={AllImages?.Next} className="more" alt="img" />
                              <p className="label">Play Next</p>
                            </div>
                            <div className="outer" onClick={() => handleCopyLinkAllPad(item.id)} ref={popupRef}>
                              <RWebShare
                                data={{
                                  url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                                  title: 'Bhakti Pad Share',
                                }}
                                onClick={() => {
                                  handleCopyLinkAllPad(item.id);
                                  console.log('shares');
                                }}
                              >
                                <div className="copyTooltip">
                                  <img src={AllImages?.CopyGray} className="more" id="myCopy" alt="img" />
                                  <span className="tooltiptext">Copy</span>
                                </div>
                              </RWebShare>
                              <div>
                                <RWebShare
                                  data={{
                                    url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                                    title: 'Bhakti Pad Share',
                                  }}
                                  onClick={() => {
                                    handleCopyLinkAllPad(item.id);
                                    console.log('share');
                                  }}
                                >
                                  <p className="label">Copy Link</p>
                                </RWebShare>
                              </div>
                            </div>

                          </div>
                        </DialogContent>
                      </Dialog>
                    </div>
                  )

                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>


      {/* Temporary Playlist */}

      {playlistTracks.length > 0 && (
        <>
          <div class="mainTitleOuter">
          
            <h1 class="mainTitle">Temporary Playlist</h1>
            <p class="viewMore"
              onClick={() => { handleCurrentlyPlayingPlaylist() }}
            >
              view more
            </p>
          </div>
          <Table responsive striped>
            <thead class="mobHide">
              <tr>
                <th>No.</th>
                <th>Title</th>
                <th class="none">Album</th>
                <th class="none">Duration</th>
                <th>Actions</th>

              </tr>
            </thead>
            <tbody class="mobHide">
            {playlistTracks && playlistTracks.length > 0 && playlistTracks.slice(0, 5).map((item, index) => (
                <tr key={item.id} class="tableHover">
                  <td>
                    <div class="srNo" onClick={() => { item?.url   ? handleTitleClick(item) : takeSubscription(item) }}
                    >
                      <p class="no mobHide">{index + 1}</p>
                      <img src={item?.thumbnailUrl} class="bhaktiImg" alt="img" />
                      {item?.url  ?
                        <div className="bhaktipadPlayIconTooltip">
                          <img src={AllImages?.PlayIcon} class="trackPlayButton" alt="img" />
                          <div className="bhaktipadPlayIconTooltipInner">
                            <span className="tooltiptext">
                              {"Play Bhakti Pad"}
                            </span>
                          </div>
                        </div> :
                        <div className="star1 badgestarInvisible tableLock">
                          <div className="LikeCount badgeShow">
                            <i
                              className="fa fa-lock"
                              onClick={() => takeSubscription(item)}
                            ></i>
                          </div>
                          <span className="tooltiptext">
                            {"Subscribe to use this feature"}
                          </span>
                        </div>
                      }
                    </div>
                  </td>

                  <td>
                    <div
                      class="titleSection"
                      onClick={() => { item?.url  ? handleTitleClick(item) : takeSubscription(item) }}
                    >
                      <p class="title">{item?.title}</p>
                      <p class="subTitle">{item.audioAlbum?.title ? item.audioAlbum.title : "Not Available"}</p>
                    </div>
                  </td>

                  <td class="none">
                  <p class="album">{item.audioAlbum?.title ? item.audioAlbum.title : "Not Available"}</p>
                  </td>

                  <td class="none">
                  <p class="duration">
                  {item?.isVideo ? `${item?.duration}:00` : formatDuration(item?.duration)}
                  </p>

                  </td>

                  <td>
                    <div className="actions">
                      <div className="favTooltip">
                        <img
                          className="video-play-mr-10"
                          class="mobHide"
                          alt="img"
                          src={AllImages?.TableMinus}
                          onClick={() => deleteItem(item?.id)}
                        />
                        <span className="tooltiptext">
                        {" "}
                        {"Delete from My Playlists"}
                        </span>
                      </div>

                      <RWebShare
                        data={{
                          url: !item?.isVideo 
                            ? `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}` 
                            : `${VideoPlaylistShareUrl}/videoPlayPage/${item?.id}`,
                          title: !item?.isVideo ? "Bhakti Pad Share" : "Satsang Share"
                        }}
                        
                        onClick={() => console.log("share")}
                      >
                        <div className="favTooltip">
                          <img
                            className="video-play-mr-10 mobHide"
                            alt="img"
                            src={AllImages?.ShareOutline}
                          />
                          <span className="tooltiptext">
                          {!item?.isVideo ? "Bhakti Pad Share" : "Satsang Share"}
                          </span>
                        </div>
                      </RWebShare>

                      <div key={item.id} className="popupOuterForCurrentPlaylist moreTooltip">
                        <img
                          src={AllImages?.More}
                          className="moreImg"
                          alt="img"
                          onClick={() => 
                            item?.url ?
                            toggleCurrentPlaylist(item?.id)
                            :  takeSubscription(item)
                          }
                          ref={popupRef}
                        />
                        <div className="moreTooltipInner">
                          <span className="tooltiptext">
                            {"View More"}
                          </span>
                        </div>
                        {isPopupVisibleCurrentPlyalist === item?.id && (
                        (
                        <div className="mobileViewDialogOuter"
                          ref={popupRef}>
                          <Dialog
                            open={toggleCurrentPlaylist}
                            className="sortBy mobileViewDialog"
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            ref={popupRef}
                          >
                            <DialogContent
                              style={{
                                overflowY: "hidden",
                                overflowX: "hidden",
                              }}
                              ref={popupRef}
                            >
                              <div className="qu-history-modal-msg-content">
                                <p className="qu-history-modal-msg-text">{"More Option"}</p>
                                <span
                                  className="qu-history-modal-close-button"
                                  onClick={toggleCurrentPlaylist}
                                >
                                  &times;
                                </span>
                              </div>
                              <div class="actionPopupMobForCurrentPlaylist"
                                ref={popupRef}>


                              <div className="favTooltip">
                                <img
                                  className="video-play-mr-10 mobHide"
                                  alt="img"
                                  src={AllImages?.TableMinus}
                                  onClick={() => deleteItem(item?.id)}
                                />
                                <p className="label">Delete from My Playlists</p>
                                <span className="tooltiptext">
                                  {"Delete from My Playlists"}
                                </span>
                              </div>

                              <RWebShare
                                data={{
                                  url: !item?.isVideo 
                                    ? `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}` 
                                    : `${VideoPlaylistShareUrl}/videoPlayPage/${item?.id}`,
                                  title: !item?.isVideo ? "Bhakti Pad Share" : "Satsang Share"
                                }}
                                onClick={() => console.log("share")}
                              >
                                <div className="favTooltip">
                                  <img
                                    className="video-play-mr-10 mobHide"
                                    alt="img"
                                    src={AllImages?.ShareOutline}
                                  />
                                  <p className="label">{!item?.isVideo ? "Bhakti Pad Share" : "Satsang Share"}</p>

                                  <span className="tooltiptext">
                                  {!item?.isVideo ? "Bhakti Pad Share" : "Satsang Share"}
                                  </span>
                                </div>
                              </RWebShare>    

                              </div>
                            </DialogContent>
                          </Dialog>
                        </div>
                        )
                        )}
                      </div>

                      </div>
                  </td>

                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}

      {showCreatePlayListModel && (
        <Dialog
          open={showCreatePlayListModel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="createPlaylistModal"
        >
          <DialogContent>
            <div className="my-create-playlist-modal-content">
              <div className="modal-d-flex">
                <p className="my-created-playlist-modal-msg-text">
                  {"Create a Playlist"}
                </p>
                <span
                  className="my-created-playlist-modal-close-btn"
                  onClick={() => closeCreatedPlayListModal()}
                >
                  &times;
                </span>
              </div>
              <div>
                <p className="my-create-playlist-modal-playlist-name">
                  {"Playlist Name"}
                </p>
                <input
                  placeholder="Spiritual Playlist"
                  className={`f-poppins my-create-playlist-modal-search-input ${isValidPlaylistName ? "invalid" : null
                    } `}
                  type="text"
                  id="playlistName"
                  value={playlistName}
                  onChange={handlePlaylistNameChange}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="my-created-playlist-modal-action-content">
              <button
                className="my-create-playlist-cancel-btn"
                onClick={() => closeCreatedPlayListModal()}
              >
                {"Cancel"}
              </button>
              <button
                className="my-create-playlist-create-btn"
                onClick={() => handleSubmit()}
              >
                {createPlaylistBtnLding ? (
                  <img src={loader} alt="img" style={{ width: 20, height: 20 }} />
                ) : (
                  "Create"
                )}
              </button>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </section>
  );
}
export default Sparsh;
