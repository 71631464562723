import React, { useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { ReactReader } from "react-reader";
import "./style.css";
import {
  AllImages,
  GLOBAL_URL,
  RemoveDuplicates,
} from "../../../utils/constants";
import Previous from "../../../assets/Phase2/previous.png";
import Pause from "../../../assets/Phase2/pause.png";
import Play from "../../../assets/Phase2/play.png";
import Forward from "../../../assets/Phase2/forward.png";
// import {
//   Worker,
//   Viewer,
//   SpecialZoomLevel,
//   ViewMode,
// } from "@react-pdf-viewer/core";
// import {
//   pageNavigationPlugin,
//   RenderGoToPageProps,
// } from "@react-pdf-viewer/page-navigation";

// import "@react-pdf-viewer/core/lib/styles/index.css";

function FileViewer() {
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
  // const location = useLocation()
  // const { contentUrl } = location.state || {};
  // console.log('contentUrl: ', contentUrl);

  const audioRef = useRef(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // const pageNavigationPluginInstance = pageNavigationPlugin();
  // const { GoToFirstPage, GoToLastPage, GoToNextPage, GoToPreviousPage } =
  //   pageNavigationPluginInstance;
  const contentUrl = queryParams.get("contentUrl");

  const [numPages, setNumPages] = useState(null);
  const [selectedWord, setSelectedWord] = useState("");
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  function handleSelectionChange(selection) {}
  const [epubcifi, setEpubcifi] = useState();

  const handleLocationChanged = (epubcfi) => {
    setEpubcifi(epubcfi);
  };

  function getFileExtension(url) {
    const parts = url?.split(".");

    return parts[parts.length - 1];
  }
  const defaultStyle = {
    fontSize: 40, // Adjust the font size as needed
  };

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);

  const playPauseHandler = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const updateTime = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const seekBackward = () => {
    audioRef.current.currentTime -= 10;
  };

  const seekForward = () => {
    audioRef.current.currentTime += 10;
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const handleProgressBarClick = (e) => {
    const progressBar = e.currentTarget;
    const rect = progressBar.getBoundingClientRect();
    const clickPosition = e.clientX - rect.left;
    const percentage = (clickPosition / rect.width) * 100;
    const newTime = (percentage / 100) * audioRef.current.duration;
    // Set the audio's current time and update the state
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const [pageNumber, setPageNumber] = useState(1);

  const handleNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };
  const handleGoToPage = (pageNumber = 0) => {};

  return getFileExtension(contentUrl) == "pdf" ? (
    <>
      {/* <Document
        file={contentUrl} // Replace with your actual PDF URL
        onLoadSuccess={onDocumentLoadSuccess}
        onItemClick={() => console.log("sss")}
        defaultStyle={defaultStyle}
      >
        {Array.from(new Array(numPages), (_, index) => (
          <Page
            // key={`page_${index + 1}`}
            pageNumber={index + 1}
            // onLoadSuccess={handleTextSelection}
            onSelectionChange={handleSelectionChange}
            renderInteractiveForms={false}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            // onItemClick={() => console.log('sss')}
            // renderAnnotationLayer={true} // Disable annotation layer for better text selection
          />
        ))}
      </Document> */}
      
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            position: "fixed",
            bottom: "0",
            left: "0px",
            right: "0",
            textAlign: "center",
            zIndex: "1",
            background: "#fffcf5",
            gap: "20px",
            display: "flex",
            justifyContent: "center",
            border: "1px solid #000",
            width: "fit-content",
            padding: "5px 10px",
            margin: "5px auto",
            borderRadius: "10px",
          }}
        >
          <button onClick={handlePrevPage} disabled={pageNumber === 1}>
            Previous
          </button>
          <span>{`Page ${pageNumber} of ${numPages || "--"}`}</span>
          <button onClick={handleNextPage} disabled={pageNumber === numPages}>
            Next
          </button>
        </div>
        <div>
          <Document
            file={contentUrl} // Replace with your actual PDF URL
            onLoadSuccess={onDocumentLoadSuccess}
            defaultStyle={defaultStyle}

          >
            <Page
              pageNumber={pageNumber}
              renderInteractiveForms={false}
              renderAnnotationLayer={false}
              renderTextLayer={false}
            />
          </Document>
        </div>
      </div>
      {/* main code */}
    </>
  // <div style={{ width: "100vw", height: "100vh" }}>
  //   <div className="pdf-view-styles">
  //     <Worker
  //       workerUrl={`${process.env.PUBLIC_URL}/pdf.worker.min.js`}
  //       workerOptions={{ workerPort: -1 }}
  //     >
  //       <Viewer
  //         fileUrl={contentUrl}
  //         style={{ width: "100%", height: "100%" }}
  //         defaultScale={SpecialZoomLevel.PageFit}
  //         onPageLoadSuccess={onDocumentLoadSuccess}
  //       />
  //     </Worker>
  //   </div>
  // </div>
  ):getFileExtension(contentUrl) == "epub" ? (
    <div className="pdfReaderOuter">
      <ReactReader
        url={contentUrl}
        title="EPUB Viewer"
        // locationChanged={(epubcfi) => console.log(epubcfi)}
        location={epubcifi} // Set the location (EPUB cifi) of the current page
        locationChanged={handleLocationChanged}
        fontSize={60}
      />
    </div>
  ) : (
    <>
      <div className="player-container-outer">
        <div className="player-container">
          <div className="player-image">
            {/* Your image goes here */}
            <img
              src="https://i.ytimg.com/vi/9LtujvoJZkw/sddefault.jpg"
              alt="Player"
            />
          </div>
          {/* <div className="player-details">
                            <h2>Song Title</h2>
                            <p>Artist Name</p>
                            Other details
                        </div> */}
          <div className="m-hide">
            <div className="player-controls ">
              <button onClick={seekBackward}>
                <img className="previous" src={Previous} alt="play Icon" />
              </button>
              <button onClick={playPauseHandler}>
                {isPlaying ? (
                  <img className="pause" src={Pause} alt="play Icon" />
                ) : (
                  <img className="play" src={Play} alt="play Icon" />
                )}
              </button>
              <button onClick={seekForward}>
                <img className="next" src={Forward} alt="play Icon" />
              </button>
            </div>
          </div>
          <div className="d-hide">
            <div className="player-controls">
              <button onTouchStart={seekBackward}>
                <img className="previous" src={Previous} alt="play Icon" />
              </button>
              <button onTouchStart={playPauseHandler}>
                {isPlaying ? (
                  <img className="pause" src={Pause} alt="play Icon" />
                ) : (
                  <img className="play" src={Play} alt="play Icon" />
                )}
              </button>
              <button onTouchStart={seekForward}>
                <img className="next" src={Forward} alt="play Icon" />
              </button>
            </div>
          </div>
          <div className="player-progress">
            <span>{formatTime(currentTime)}</span>
            <progress
              onClick={handleProgressBarClick}
              value={currentTime}
              max={audioRef.current?.duration || 0}
            ></progress>
            <span>{formatTime(audioRef.current?.duration || 0)}</span>
          </div>
          <audio
            ref={audioRef}
            src={contentUrl}
            onTimeUpdate={updateTime}
          ></audio>
        </div>
      </div>
    </>
  );
}
export default FileViewer;
