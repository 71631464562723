import React, { useContext, useState, useRef } from 'react';
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Button from "@material-ui/core/Button";
import Slider from 'react-slick';
import {
  UserloggedContext,
} from "../../Context/UserContext";
import './style.css';
import { RWebShare } from "react-web-share";
import { AllImages, VideoPlaylistShareUrl, getImageNameFromResolution } from "../../../utils/constants";
const CommonCarousel = (props) => {
  const { carousalVideoList } = props
  const navigate = useNavigate();
  const [auth, setAuth] = useContext(UserloggedContext);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const sliderRef = useRef(null);
  const observer = useRef(null);

  const sliderSettings = {
    slidesToShow: 3,
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    responsive: [
      {
        breakpoint: 767, // Mobile breakpoint
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1, // Show 1 slide on mobile
        },
      },
      {
        breakpoint: 991, // Mobile breakpoint
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1, // Show 1 slide on mobile
        },
      },
    ],
    // initialSlide: Math.floor(data.length / 2), // Set the initial slide to the middle of the carousel
  };

  const handleVideoClick = (item) => {
    navigate(`/videoPlayPage/${item.id}`)
  }

  function MinutesToHoursMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return hours + ':' + remainingMinutes
  }





  function takeSubcription(params) {
    params?.subscriptionName
      ? confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="card">
              <div className="info__padding">
                <h3>
                  {`Please Subscribe to ${params?.subscriptionName} plan to play this video. `}
                </h3>
                <h3>{!auth ? "Sign In To Subscribe..." : ""}</h3>
              </div>
              {!auth ? (
                <>
                  <div className="button__group">
                    <Button
                      onClick={() => {
                        // signIn();
                        onClose();
                      }}
                      variant="contained"
                      className="signBtnHome"
                    >
                      {/* <img className="g-icon" src={Google}></img> */}
                      Sign-in
                    </Button>
                    <button className="no-btn" onClick={onClose}>
                      Cancel
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="button__group">
                    <Button
                      onClick={() => {
                        navigate("/subscriptionPlanList");
                        onClose();
                      }}
                      variant="contained"
                      className="subscriptionBtn"
                    // className="signBtnHome"
                    >
                      {/* <img className="g-icon" src={Google}></img> */}
                      Subscribe
                    </Button>
                    <button className="no-btn" onClick={onClose}>
                      Cancel
                    </button>
                  </div>
                </>
              )}
            </div>
          );
        },
      })
      : confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="card">
              <div className="info__padding">
                <h3>{`This Video is not yet available`}</h3>
              </div>
              <div className="button__group">
                <button className="button btn-ok" onClick={onClose}>
                  Ok
                </button>
              </div>
            </div>
          );
        },
      });
  }













  return (
    <Slider  {...sliderSettings} ref={sliderRef} key={carousalVideoList}>
      {carousalVideoList?.map((item, index) => (
        <div className="carousel" key={index}>
          <div className="carousel-play-card-wrap slider">
            <div className="play-inside-wrap">
              {item.videoUrl ? (
                <div className="play-list-image" onClick={() => handleVideoClick(item)} style={{ position: "relative" }}>
                  <img
                    className={`img-banner ${getImageNameFromResolution(item.thumbnails) != 'maxresdefault' && 'image-resolution'}`}
                    src={item.thumbnails}
                    alt="Card Image"
                    title="Card Image"
                  />
                  <div className='playIconTooltip'>
                    <img
                      className="play-play-icon"
                      src={AllImages?.PlayIcon}
                      alt="Play Icon"
                      title="Play Icon"
                    />
                    <span className='tooltiptext'>
                      {"Play Video"}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="play-list-image">
                  <div
                    onClick={() => takeSubcription(item)}
                  >
                    <img
                      className={`img-banner ${getImageNameFromResolution(item.thumbnails) != 'maxresdefault' && 'image-resolution'}`}
                      src={
                        item?.thumbnails
                          ? `${item?.thumbnails}`
                          : `${AllImages.DefaultThumbnail}`
                      }
                      alt="Card Image" title="Card Image"
                    />
                    <div className="layerImage"></div>
                  </div>
                  <div className="star1 badgestarInvisible">
                    <div className="LikeCount badgeShow">
                      <i
                        className="fa fa-lock"
                      onClick={() => takeSubcription(item)}
                      ></i>
                    </div>
                    <span className='tooltiptext'>
                      {"Subscribe for more Videos"}
                    </span>
                  </div>
                </div>
              )}
              <div className="play-card-content-item">
                <div className="play-video-content">
                  <div>
                    <p className="play-title-text f-poppins">{item.videoTitle}</p>
                    <p className="play-text-hrs f-poppins">{MinutesToHoursMinutes(item?.videoDuration)} hrs  </p>
                  </div>
                  <div className="mt-10 iconsOuter">
                    <div className='timerTooltip'>
                      <img className='cursor' onClick={() => props.videoWatchLater(item, index)} src={item?.watchLater ? AllImages.SelectedTimer : AllImages.Timer} alt="Timer" title="Timer"></img>
                      <span className='tooltiptext'>
                        {!item.watchLater
                          ? "Watch Later"
                          : "Remove Watch Later"}
                      </span>
                    </div>
                    <div className='favTooltip'>
                      <img className='cursor' src={item?.myFavourite ? AllImages.Favorite_sel : AllImages.Fav} alt="Favourite" title="Favourite"
                        onClick={() => props.addFavorite(item, index)}
                      ></img>
                      <span className='tooltiptext'>
                        {!item.myFavourite
                          ? "Mark Favorite"
                          : "Unfavorite"}
                      </span>
                    </div>
                    <RWebShare
                      data={{
                        // text: "Like humans, flamingos make friends for life",
                        url: `${VideoPlaylistShareUrl}/videoPlayPage/${item.id}`,
                        title: "Video Share",
                      }}
                      onClick={() => console.log('share')}
                    >
                      <div className='copyTooltip'>
                        <img className='cursor' src={AllImages.Copy} alt="Copy" title="Copy"></img>
                        <span className='tooltiptext'>
                          {"Copy"}
                        </span>
                      </div>
                    </RWebShare>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};
export default CommonCarousel