import React, { useState, useContext, useEffect, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import './style.css';
import { GLOBAL_URL, AllImages, RemoveDuplicates } from '../../../utils/constants';
import { UserloggedContext, LoaderContext, userDetailsContext, SubcriptionHistoryContext, SubcriptionDetailsContext, GSTContext, SelectedSubcriptionContext, SubcriptionListContext } from "../../Context/UserContext";
import ApiEndPoint from "../../../utils/apiEndPoints";
import { setDefaultHeader, apiCall } from "../../../utils/httpClient";
import moment from 'moment';
import { Button } from "@material-ui/core";
import useGoogleLoginFlow from "components/common/useGoogleLoginFlow";
import useRazorpay from "react-razorpay";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import Google from "../../../assets/images/google.png";
import { Modal } from "reactstrap";

const SubscriptionRenewalModal = ({ isOpen, onClose }) => {
    const location = useLocation();
    const [auth, setAuth] = useContext(UserloggedContext);
    const [subscriptionDetails, setSubcriptionDetails] = useContext(SubcriptionDetailsContext);
    const [subscriptionHistory, setSubscriptionHistory] = useContext(SubcriptionHistoryContext);
    const [selectedSubscription, setSelectedSubscription] = useContext(SelectedSubcriptionContext);
    const [subscriptionList, setSubcriptionList] = useContext(SubcriptionListContext);
    const [IsLoading, setIsLoading] = useContext(LoaderContext);
    const [userDetails, setUserDetails] = useContext(userDetailsContext);
    const [gstpagemanage, setGstpagemanage] = useContext(GSTContext);

    const { openRecaptcha, recaptchaFun, onChange } = useGoogleLoginFlow();
    const Razorpay = useRazorpay();
    const navigate = useNavigate();

    useEffect(() => {
        getSubcriptionList();
        getSubcriptionDetails();
        getSubcriptionHistoryDetails();
    }, []);

    async function getSubcriptionDetails() {
        try {
            !subscriptionDetails?.id && setIsLoading(true);
            if (auth === "01") {
                const { data } = await apiCall("GET", ApiEndPoint.GETSUBCRIPTIONDETAILS);
                if (data) {
                    setSubcriptionDetails(data);
                    setIsLoading(false);
                }
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }

    async function getSubcriptionHistoryDetails() {
        try {
            !subscriptionHistory && setIsLoading(true);
            if (auth === "01") {
                const { data } = await apiCall("GET", ApiEndPoint.GETSUBSCRIPTIONHISTORY);
                if (data) {
                    setSubscriptionHistory(data);
                    setIsLoading(false);
                }
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }

    const handlePayment = useCallback(async (data, subscriptionId, phoneNumber) => {

        const options: RazorpayOptions = {
            key: data.keyId,
            amount: data.amount,
            currency: "INR",
            name: "Satshrut",
            description: "",
            image: "https://vitraagvigyaan.org/img/logo.png",
            order_id: data.orderId,
            handler: (res) => {
                getUserDetails()
                setGstpagemanage('')
                toast.success('Payment successful', {
                    position: toast.POSITION.TOP_RIGHT
                });
                navigate('/')
                // console.log(res);
            },
            prefill: {
                name: userDetails?.user?.firstName,
                email: userDetails?.user?.email,
                contact: phoneNumber ? phoneNumber : 'UNIQUE_IDENTIFIER',
            },
            notes: {
                address: "Razorpay Corporate Office",
            },
            theme: {
                color: "#3399cc",
            },
            method: {
                netbanking: true,
                card: true,
                wallet: false,
                upi: false,
            },
            config: {
                display: {
                    hide: [{ method: 'paylater' }]
                }
            }
        };
        const rzpay = new Razorpay(options);
        rzpay.on("payment.failed", function (response) {
            console.error(response);
        });
        rzpay.open();
    }, [Razorpay, getUserDetails, navigate, setGstpagemanage, toast, userDetails]);

    function takeSubcription() {
        confirmAlert({
            customUI: ({ onClose }) => (
                <div className="card">
                    <div className="info__padding">
                        <h3>Please Sign In To Subscribe...</h3>
                    </div>
                    <div className="button__group">
                        <Button
                            onClick={() => {
                                onChange({ true: true });
                                onClose();
                            }}
                            variant="contained"
                            className="signBtnHome"
                        >
                            <img style={{ height: '23px', width: '23px' }} className="g-icon" src={Google} alt="Google" />
                            Sign-in
                        </Button>
                        <button className="no-btn" onClick={onClose}>Cancel</button>
                    </div>
                </div>
            ),
        });
    }

    async function getSubcriptionList() {
        try {
            if (auth === "01") {
                const { data } = await apiCall("GET", ApiEndPoint.LOGINSUBCRIPTION);
                if (data?.length > 0) {
                    setSubcriptionList(data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } else {
                const { data } = await apiCall("GET", ApiEndPoint.SUBCRIPTIONPLANWITHOUTLOGIN);
                if (data?.length > 0) {
                    setSubcriptionList(data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }

    async function addSubcriptionFun(item) {
        try {
            const Auth = await localStorage.getItem("userToken");
            if (Auth === "01") {
                const params = {
                    amount: item.price,
                    subscriptionId: item.id,
                };
                const { data } = await apiCall("POST", `orders`, params);
                if (data) {
                    handlePayment(data, item.id, userDetails?.user?.phoneNumber);
                    document.cookie = 'rzp_contact=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                    setIsLoading(false);
                }
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }

    async function getUserDetails() {
        try {
            const Auth = await localStorage.getItem("userToken");
            if (Auth === "01") {
                const { data } = await apiCall("GET", ApiEndPoint.GETSUBCRIPTIONDETAILS);
                if (data) {
                    setUserDetails(data);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }


    async function addSubcriptionFun(item) {
        try {
            // setIsLoading(true);
            const Auth = await localStorage.getItem("userToken");
            if (Auth == "01") {
                const params = {
                    amount: item.price,
                    subscriptionId: item.id,
                };
                const { data } = await apiCall("POST", `orders`, params);

                if (data) {
                    handlePayment(data, item.id, userDetails?.user?.phoneNumber);
                    document.cookie = 'rzp_contact=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                    setIsLoading(false);
                    // toast.success('Successfully', {
                    //   position: toast.POSITION.TOP_RIGHT
                    // });
                }
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }
    
    
    


    async function Subcription(item) {
        if (userDetails?.user?.country && userDetails?.user?.state && userDetails?.user?.city && userDetails?.user?.pinCode && userDetails?.user?.phoneNumber) {
            addSubcriptionFun(item);
        } else {
            if (gstpagemanage === 'user') {
                addSubcriptionFun(item);
            } else {
                setSelectedSubscription(item);
                navigate('/editProfile');
                setGstpagemanage('subscription');
            }
        }
    }

    function isFreePlanAcitve(currentDate, endDate) {
        const currentMoment = moment(currentDate, 'DD MMM YYYY');
        const endMoment = moment(endDate, 'DD MMM YYYY');
        return endMoment.isAfter(currentMoment);
    }

    const [endDateOfSubscription, setEndDateOfSubscription] = useState(userDetails?.endDate);
    const endDate = moment(endDateOfSubscription).format("DD MMM YYYY");
    const currentDate = moment().format("DD MMM YYYY");


    const [checkPlan, setCheckPlan] = useState(isFreePlanAcitve(currentDate, endDate) && userDetails?.subscriptionPlan?.subscriptionName === "FREE");


    return (
        <Modal isOpen={isOpen} toggle={onClose} className="subscription-renewal-modal">
            <div className="modal-header">
                <h5 className="modal-title">Plan Reminder</h5>
                <button type="button" className="close" onClick={onClose}>
                    <span>&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <p>
                    {checkPlan
                        ? 'Buy a subscription to start enjoying our premium services.'
                        : `Your current plan is about to expire. Renew now to continue enjoying all the benefits.`}
                </p>
            </div>
            <div className="modal-footer">
                {subscriptionList.map((item) => (
                    <div key={item.id} className="buySubscriptionOuter"
                        onClick={() => auth === "01" ? Subcription(item) : takeSubcription()}>
                        <button className="buySubscriptions">
                            {checkPlan ? 'Buy Subscription' : 'Renew Subscription'}
                        </button>
                    </div>
                ))}
            </div>
        </Modal>
    );
};

export default SubscriptionRenewalModal;
