export default {
  // Auth API Start
  JWTTOKEN: "tokenGenrate/jwtToken",
  SIGNIN: "socialUser/authenticate",
  VIDEOLIST: "video-meta-data/videoListForNonLoginUser",
  LOGINVIDEOLIST: "video-meta-data/videoListForLoginUser",
  LOGINSUBCRIPTION: "subscription-plans-with-login",
  MYFAVOURITES: "my-favourites/forUser",
  LIVEVIDEO: "youtube-active-live-videos",
  SUBCRIPTIONPLANWITHOUTLOGIN: "subscription-plans-without-login",
  USERDETAILS: "users/currentuser",
  USERUPDATE: "users",
  UPDATEUSERPROFILEIMG: "users/profileImage",
  GETSUBSCRIPTIONHISTORY: "subscription-histories/all/currentuser",
  GETSUBCRIPTIONDETAILS: "subscription-histories/currentuser",
  GETEXISTINGUSER: "users/firstTimeUser",
  VIDEOINEXFORNONLOGINUSER: "video-meta-data/videoIndexingForNonLoginUser",
  VIDEOINDEXFORLOGINUSER: "video-meta-data/videoIndexingForLoginUser",
  GETOFFERISAVAILBLE: "getOfferisAvailble",
  MAILSEND: "mail/send",
  PLAYVIDFORLOGINUSERS: `video-meta-data/login/findById/`,
  PLAYVIDFORNONLOGINUSERS: `video-meta-data/nonLogin/findById/`,
  REMOVEPROFILEPICTURE: "users/deleteImage",
  SAVEWATCHVIDEOTIME: "video-meta-datas/lastWatchtime",

  // Second Phase2
  ALLVIDEOPLAYLIST: "system/playlists/login/user",
  ALLVIDEOPLAYLISTNONLOGIN: "system/playlists/nonlogin/user",
  PLAYLISTVIDEONONLOGIN: "system/playlist/contents/nonlogin/user",
  PLAYLISTVIDEOLOGIN: "system/playlist/contents/login/user",
  //favourite play list
  MYFAVOURITEPLAYLIST: "myfavourite/system/playlist/save",
  MYFAVOURITEPLAYLISTAll: "myfavourite/system/playlists",
  MYFAVOURITEPLAYLISTREMOVE: "myfavourite/system/playlist/delete",
  MYFAVOURITEPLAYLISTSHOW: "myfavourite/system/playlists",
  MYFAVOURITESLIST: "my-favourites",

  //watchLaterVideo
  VIDEOWATCHLATER: "watchlater/video/save",
  ALLWATCHLATERVIDEOS: "watchlater/videos",
  WATCHLATEDREMOVE: "watchlater/video/delete",
  PLAYLISTWATCHLATER: "watch/later/system/playlist/save",
  PLAYLISTWATCHLATERREMOVE: "watch/later/system/playlist/delete",
  WATCHLATERPLAYLIST: "watch/later/system/playlists",

  //watch later playlist
  PLAYLISTWATCHLATERSAVE: "recently/watch/system/playlist/save",
  RECENTLYPLAYLIST: "recently/watch/system/playlists",

  //userPlayList
  CREATEUSERPLAYLIST: "my/playlist/save",
  UPLOADPLAYLISTLOGO: "my/playlist/upload/thumbnail",
  DELTEUSERPLAYLIST: "my/playlist/delete",
  USERPLAYLISTCONTENTS: "my/playlist/contents",
  USERPLAYLIST: "my/playlists?contentType=VIDEO",
  USERPLAYLSITADDVIDEO: "my/playlist/contents/save",
  VIDEOMETALISTFORPLAYLIST: "video-meta-datas/my/playlist",
  REMOVECONTENTFROMPLAYLIST: "my/playlist/contents/deleteByContentId",
  REMOVECONTENTFROMPLAYLIST: "my/playlist/contents/deleteByContentId",
  PLAYLISTDATAASSOCIATEDWITHVIDEO: "my/playlists/video",
  ADDVIDEOAGAINSTMULTIPLEPLAYLIST:
    "my/playlist/contents/SelectUnselectedPlalist",
  //Recent watched
  RECENTWATCHEDVIDEOLIST: "recently/watch/videos",

  //PLAY COUNT
  PLAYCOUNT: `v3/youtube-audio-metadata/played`,




  //SHARE PLAYLIST
  SHAREPLAYLISTGET: "my/playlist/share",
  SHAREPLAYLISTSAVE: "my/playlist/share/save",
  GETMYALLSHAREPLAYLIST: "my/playlist/share/contentType",
  DELETESHAREDPLAYLIST: "my/playlist/share/delete",
  SHAREDPLAYLISTCONTENTS: "my/playlist/share/contents",

  //EBooks
  GETEBOOKSLISTFORLOGGEDINUSER: "ebooks/logedin/users",
  GETEBOOKSLISTFORNONLOGGEDINUSER: "open/ebooks/nonlogedin/users",
  EBOOKFAVOURITE: "my/favourite/ebook/save",
  DELETEFAVOURITE: "my/favourite/ebook/delete",
  EBOOKSFAVOURITELIST: "my/favourite/ebooks",
  EBOOKSERIESFAVOURITELIST: "my/favourite/ebook/playlists",
  EBOOKDOWNLOAD: "ebooks/downloads",
  GETDOWNLOADEBOOK: "ebooks/getdownloads",
  GETDOWNLOADEBOOK: "ebooks/getdownloads",
  DETELEDOWNLOADEDEBOOKS: "ebooks/downloadsDelete",
  EBOOKSERIESLOGIN: "system/playlist/ebook/login/user",
  EBOOKSERIESNONLOGIN: "open/system/playlist/ebook/nonlogin/user",
  MYFAVOURITEREMOVEEBOOKSERIES: "my/favourite/ebook/playlist/delete",
  MYFAVOURITEADDEBOOKSERIES: "my/favourite/ebook/playlist/save",
  EBOOKSERIESLISTNONLOGIN:
    "open/system/playlist/content/ebookPlaylist/nonlogin/user",
  EBOOKSERIESLISTLOGIN: "system/playlist/content/ebookPlaylist/login/user",

  PATRAFAVOURITE: "my/favourite/patra/save",
  DELETEFAVOURITEPATRA: "my/favourite/patra/delete",

  TRANSCRIPTFAVOURITE: "my/favourite/transcript/save",
  DELETEFAVOURITETRANSCRIPT: "my/favourite/transcript/delete",

  //suggested
  SUGGESTEDVIDEO: "video-meta-datas/suggested",

  //Live chat
  GETALLQUESTOINS: "live/video/getQuestionAll",
  GETALLQUESTIONSFORQUESTIONHISTORY: "live/video/questionAll",
  GETQUESTIONREPLY: "live/video/getAllComments",

  //surpriseGift
  SURPRISEME: "surpriseGift",
  // eslint-disable-next-line no-dupe-keys
  UPDATEUSERPROFILEIMG: "users/profileImage",

  //advance search
  ADVANCESEARCH: "search/advance",
  NONLOGINADVANCESEARCH: "open/search/advance/nonlogin",
  //  search poc for login users
  EPUBSEARCH: "search",
  TRANSCRIPTSEARCH: "transcript/search",
  PATRASEARCH: "patra/search",
  //  search poc for non-login users
  NONEPUBSEARCH: "open/search",
  NONTRANSCRIPTSEARCH: "open/transcript/search",
  NONPATRASEARCH: "open/patra/search",

  //location
  GETALLLOCATOIN: "open/getAllLoations",
  GETALLSPEAKERS: "open/AllSpeakers",

  //Question History
  QUESTIONHISTORY: 'live/video/Question/History',
  QUESTIONDELETE: 'live/video/Question/Delete',

  //Service Worker
  SAVESUBSCRIPTION: 'open/webnotification',

  //PHASE3
  GETALLALBUMS: `v3/youtube-audio-album`,
  GETTRACKSBYALBUM: `v3/youtube-audio-metadata/album`,
  ALLTRACKS: 'v3/youtube-audio-metadata',
  TRACKSFAVORITE: 'v3/my-favourite-youtube-audio-track',
  TRACKSFAVORITEDELETE: 'v3/my-favourite-youtube-audio-track',
  ALBUMSFAVORITE: 'v3/my-favourite-youtube-audio-album',
  ALBUMSFAVORITEDELETE: 'v3/my-favourite-youtube-audio-album',
  GETFAVORITETRACKS: 'v3/my-favourite-youtube-audio-track/currentUser',
  GETALLFAVORITEALBUMS: 'v3/my-favourite-youtube-audio-album/currentUser',
  GETLYRICSBYTRACKID: 'v3/youtube-audio-metadata/getLyrics',

  GETALBUMDETAILSBYALBUMID: 'v3/youtube-audio-album',
  GETTRACKDETAILSBYTRACKID: 'v3/youtube-audio-metadata',

  //AUDIO PLAYLIST
  CREATEUSERPLAYLISTFORAUDIO: 'my/playlist/save',
  USERPLAYLISTFORAUDIO: "my/playlists?contentType=YOUTUBE_TRACK",
  ADDAUDIOAGAINSTMULTIPLEPLAYLIST:
  "my/playlist/contents/all/SelectUnselectedPlalist",
  GETCHECKEDCHECKBOXFORAUDIO : 'my/playlists/contentType/YOUTUBE_TRACK/contentId',
  GETALLTRACKSBYPLAYLISTIDSHARED : 'my/playlist/share/contents',
  GETALLTRACKSBYPLAYLISTID : 'my/playlist/contents',
  DELETETRACKBYID : 'my/playlist/contents/deleteByContentId',
  DELTEUSERPLAYLISTAUDIO : 'my/playlist/delete',
  UPDATECUSTOMSORTNUMBER : 'my/playlist/contents/updateCustomSortNo',
  GETPLAYLISTDETAILSUSINGID:'my/playlist'
}
