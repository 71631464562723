import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useGoogleLoginFlow from "components/common/useGoogleLoginFlow";
import {
  MusicPlayerVisibilityContext,
  MusicTracksContext,
  MusicTrackContext,
  ShowCreatePlayListContext,
  UserloggedContext,
  PlayNextContext,
  userDetailsContext,
  MusicTracksTestContext,
  PlayingShuffledAlbumIdContext,
  PlayingAlbumIdContext,
  CurrentTrackIndex,
  MusicDataContext
} from "components/Context/UserContext";
import { useContext, useEffect, useRef, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Table } from "reactstrap";
import apiEndPoints from "utils/apiEndPoints";
import { apiCall } from "utils/httpClient";
import Google from "../../../assets/images/google.png";
import loader from "../../../assets/images/loading_wbg.gif";

import {
  AllImages,
  emojiRegex,
  RemoveDuplicates,
  VideoPlaylistShareUrl,
} from "../../../utils/constants";
import NoDataAvailable from "../../common/NoDataFound/NoDataAvailable";
import "./style.css";
//playlist
import { toast } from "react-toastify";
import ApiEndPoint from "../../../utils/apiEndPoints";
import { RWebShare } from "react-web-share";

import { useDispatch, useSelector } from 'react-redux';
import { addTrack } from "actions/Index";
import { generateImageDataURL } from "utils/generateImageDataURL";

function AudioPlaylist() {
  const dispatch = useDispatch();
  const playlistTracks = useSelector(state => state.tempPlaylist.playlistTracks); // Use playlistTracks

  const navigate = useNavigate();
  const location = useLocation();

  const { playListDetails } = location.state || {};
  const { playNextTrack, setPlayNextTrack } = useContext(PlayNextContext);
  const { playingAlbumId, setPlayingAlbumId } = useContext(PlayingAlbumIdContext);
  const { playingShuffledAlbumId, setPlayingShuffledAlbumId } = useContext(PlayingShuffledAlbumIdContext);
  // console.log('playListDetailsplayListDetails', playListDetails)
  const [auth, setAuth] = useContext(UserloggedContext);
  const [musicData, setMusicData] = useContext(MusicDataContext);
  const { track, setTrack } = useContext(MusicTracksContext);
  const { currentTrackIndex, setCurrentTrackIndex } = useContext(CurrentTrackIndex);

  const { testTrack, setTestTrack } = useContext(MusicTracksTestContext);
  const { singleTrack, setSingleTrack } = useContext(MusicTrackContext);
  const [isMusicPlayerVisible, setIsMusicPlayerVisible] = useContext(
    MusicPlayerVisibilityContext
  );
  const [searchText, setSearchText] = useState(""); // State for search text
  const [isLoading, setIsLoading] = useState(false); // State for loading indicator
  const [pageNo, setPageNo] = useState(0); // State for page number
  const [scrollLast, setScrollLast] = useState(true);
  const [scrollHashMap, setScrollHashMap] = useState(false);
  const [isOpenSortBy, setIsOpenSortBy] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { openRecaptcha, recaptchaFun, onChange } = useGoogleLoginFlow();
  const { PlaylistId } = useParams();
  const [tracks, setTracks] = useState([]);

  const SortingOptions = {
    NEWLY_ADDED: "NEWLY_ADDED",
    PREVIOUSLY_ADDED: "PREVIOUSLY_ADDED",
    POPULAR_BHAKTIPAD: "POPULAR_VIDEOS",
    // LAST_MODIFIED_DATE: "LAST_MODIFIED_DATE",
    Sort_A_To_Z: "A_TO_Z",
    Sort_Z_To_A: "Z_TO_A",
  };
  const [isOpen, setIsOpen] = useState(false);
  const [showCreatePlayListModel, setShowCreatePlayListModel] = useContext(
    ShowCreatePlayListContext
  );
  const [userPlayList, setUserPlayList] = useState([]);
  const [unselectedPlaylistArray, setUnselectedPlaylistArray] = useState([]);
  const [currentlyPlaylingVidId, setCurrentlyPlaylingVidId] = useState();
  const [
    previouslySelectedUserPlaylistArray,
    setPreviouslySelectedUserPlaylistArray,
  ] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [
    currentlySelectedUserPlaylistArray,
    setCurrentlylySelectedUserPlaylistArray,
  ] = useState([]);
  const [selectedFilterOp, setSelectedFilterOp] = useState(
    SortingOptions.Sort_A_To_Z
  );

  const [isPopupVisible, setPopupVisible] = useState(null);
  const [isMobileView, setIsMobileView] = useState(false);
  const [userDetails, setUserDetails] = useContext(userDetailsContext);

  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 991);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  //playlist
  useEffect(() => {
    if (isOpen) {
      fetchUserPlaylistData();
      getSelectedPlaylistData();
    }
  }, [isOpen]);
  const openModal = (id) => {
    if (auth) {
      setIsOpen(true);
      setCurrentlyPlaylingVidId(id);
    } else {
      // takeOtherSubcription();
    }
  };
  useEffect(() => {
    handleAllTrack();
    fetchData()
  }, [selectedFilterOp]);
  async function handleAllTrack(fetchPage = 0) {
    setIsLoading(true);
    try {
      const body = {
        pageNo: fetchPage ? fetchPage - 1 : pageNo,
        pageSize: 20,
        sortColumn: selectedFilterOp,
        searchValue: searchText,
        contentType: "YOUTUBE_TRACK",
        playListId: PlaylistId,
      };
      if (auth) {
        const res = await apiCall('POST', `${apiEndPoints.GETALLTRACKSBYPLAYLISTIDSHARED}`, body);
        const data = res.data.content;
        if (data.length > 0) {
          setIsLoading(false);
          setTracks(data);
          setMusicData(
            RemoveDuplicates(fetchPage ? data : musicData.concat(data))
          );
          setPageNo(fetchPage ? fetchPage : pageNo + 1);
          setScrollHashMap(data.length === 20);
          setScrollLast(true);
        } else {
          setIsLoading(false);
          fetchPage && setMusicData([]); // Clear musicData if fetching a new page
          setScrollLast(false);
          setScrollHashMap(false);
          setPageNo(0);
        }
      } else {
        takeSubscription();
      }
    } catch (error) {
      setIsLoading(false);
      setScrollLast(false);
      setScrollHashMap(false);
      console.error('Error fetching audio tracks:', error);
    }
    isOpenSortBy && closeModalSortBy();
  }
  async function getSelectedPlaylistData() {
    if (auth) {
      try {
        setIsLoading(true);
        const { data } = await apiCall(
          "GET",
          `${ApiEndPoint.GETCHECKEDCHECKBOXFORAUDIO}/${currentlyPlaylingVidId}`
        );
        if (data.length > 0) {
          setIsLoading(false);
          setPreviouslySelectedUserPlaylistArray(data);
        } else {
          setPreviouslySelectedUserPlaylistArray([]);
        }
      } catch (err) {
        setIsLoading(false);
      }
    }
  }
  const fetchUserPlaylistData = () => {
    userPlayListFun(1);
  };
  async function userPlayListFun(fetchPage = 0) {
    if (auth) {
      try {
        const params = {
          searchValue: "",
          sortColumn: selectedFilterOp,
          pageNo: fetchPage ? fetchPage - 1 : pageNo,
          pageSize: 8,
          sortDirection: "",
        };
        setIsLoading(true);
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.USERPLAYLISTFORAUDIO}`,
          params
        );
        if (data.length > 0) {
          setIsLoading(false);
          setUserPlayList(fetchPage ? data : (prev) => [...prev, ...data]);
          setUserPlayList(
            RemoveDuplicates(fetchPage ? data : userPlayList.concat(data))
          );
          setPageNo(fetchPage ? fetchPage : pageNo + 1);
          setScrollHashMap(true);
          setScrollLast(true);
        } else {
          setIsLoading(false);
          setScrollLast(false);
          fetchPage && setUserPlayList([]);
          setPageNo(0);
          setScrollHashMap(false);
        }
      } catch (err) {
        setIsLoading(false);
        // setScrollLast(false);
      }
    }
  }
  async function addVideoAgainstPlaylist() {
    if (auth) {
      if (
        currentlySelectedUserPlaylistArray.length > 0 ||
        unselectedPlaylistArray.length > 0
      ) {
        try {
          const params = {
            videoId: currentlyPlaylingVidId,
            selected: currentlySelectedUserPlaylistArray,
            unSelected: unselectedPlaylistArray,
            contentType: "YOUTUBE_TRACK",
          };
          setIsLoading(true);
          const { status } = await apiCall(
            "POST",
            `${ApiEndPoint.ADDAUDIOAGAINSTMULTIPLEPLAYLIST}`,
            params
          );
          if (status === 200) {
            setIsLoading(false);
            toast.success("Added successfully");
            closeModal();
          } else {
            setIsLoading(false);
            toast.error("Something went wrong , try again");
          }
        } catch (err) {
          setIsLoading(false);
        }
      }
    }
  }
  const handleCheckboxChange = (event) => {
    const playlistId = parseInt(event.target.dataset.playlistId);
    const checked = event.target.checked;
    !isPlaylistSelected(playlistId)
      ? setCurrentlylySelectedUserPlaylistArray((prevSelectedPlaylist) => {
        let updatedSelectedPlaylist = [...prevSelectedPlaylist];
        if (checked) {
          if (
            !updatedSelectedPlaylist.some(
              (playlist) => playlist?.id === playlistId
            )
          ) {
            updatedSelectedPlaylist.push({ id: playlistId });
          }
        } else {
          updatedSelectedPlaylist = updatedSelectedPlaylist.filter(
            (playlist) => playlist.id !== playlistId
          );
        }
        return updatedSelectedPlaylist;
      })
      : removeFromExisting(playlistId);
  };
  const removeFromExisting = (playlistId) => {
    if (
      previouslySelectedUserPlaylistArray.some(
        (playlist) => playlist.id === playlistId
      )
    ) {
      const updatedExistingPlaylist =
        previouslySelectedUserPlaylistArray.filter(
          (playlist) => playlist.id !== playlistId
        );
      setPreviouslySelectedUserPlaylistArray(updatedExistingPlaylist);
      setUnselectedPlaylistArray((prev) => {
        return [...prev, { id: playlistId }];
      });
    } else if (
      currentlySelectedUserPlaylistArray.some(
        (playlist) => playlist.id === playlistId
      )
    ) {
      const updateSelectedPlaylistData =
        currentlySelectedUserPlaylistArray.filter(
          (playlist) => playlist.id !== playlistId
        );
      setCurrentlylySelectedUserPlaylistArray(updateSelectedPlaylistData);
    } else {
      return;
    }
  };
  const isPlaylistSelected = (playlistId) => {
    return (
      previouslySelectedUserPlaylistArray.some(
        (item) => item?.id == playlistId
      ) ||
      currentlySelectedUserPlaylistArray.some((item) => item?.id === playlistId)
    );
  };
  const fetchMoreUserPlaylistData = () => {
    userPlayListFun();
  };
  const createPlaylist = () => {
    setIsOpen(false);
    navigate("/CreatedPlaylistForAudio");
    setShowCreatePlayListModel(true);
  };
  const closeModal = () => {
    setPreviouslySelectedUserPlaylistArray([]);
    setCurrentlylySelectedUserPlaylistArray([]);
    setUnselectedPlaylistArray([]);
    setIsOpen(false);
  };
  //------------------------
  async function infiniteScroll() {
    setIsLoading(true);
    try {
      const body = {
        pageNo: pageNo,
        pageSize: 10,
        sortColumn: selectedFilterOp,
        sortDirection: "",
        searchValue: "",
        contentType: "YOUTUBE_TRACK",
        playListId: PlaylistId,
      };
      if (auth) {
        const res = await apiCall("POST", `${apiEndPoints.GETALLTRACKSBYPLAYLISTIDSHARED}`, body);
        const data = res.data.content;
        if (data.length > 0) {
          setIsLoading(false);
          setMusicData(musicData.concat(data)); // Update musicData correctly
          setPageNo(pageNo + 1);
          setScrollHashMap(true);
          setScrollLast(true);
          setTracks(data);
        } else {
          setIsLoading(false);
          !pageNo && setMusicData([]); // Clear musicData if fetching a new page
          setScrollLast(false);
          setScrollHashMap(false);
          setPageNo(0);
          setTracks([]);
        }
      } else {
        takeSubscription();
      }
    } catch (error) {
      setIsLoading(false);
      setScrollLast(false);
      setScrollHashMap(false);
      console.error("Error fetching audio tracks:", error);
    }
    isOpenSortBy && closeModalSortBy();
  }
  const handleSearchInputChange = (e) => {
    if (!emojiRegex.test(e.target.value)) {
      if (e.target.value) {
        setSearchText(e.target.value);
      } else {
        setSearchText(e.target.value);
      }
    }
  };
  const fetchData = () => {
    handleAllTrack(1);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchData();
    }
  };
  const handleTitleClick = (item) => {
    setSingleTrack([])
    setTrack([])
    setPlayNextTrack([])
    setPlayingShuffledAlbumId(null)
    setPlayingAlbumId(null)
    setPlayingAlbumId(null);
    let trackList = [];
    trackList[0] = item;
    setTrack(trackList);
    setIsMusicPlayerVisible(true);
  };
  async function toggleFavoriteTrack(item, index) {
    try {
      const isCurrentlyFavorite = item.isFavourite;
      const method = isCurrentlyFavorite ? "DELETE" : "POST";
      const endpoint = isCurrentlyFavorite
        ? `${apiEndPoints.TRACKSFAVORITEDELETE}/${item.id}`
        : `${apiEndPoints.TRACKSFAVORITE}/${item.id}`;
      tracks[index].isFavourite = !isCurrentlyFavorite;
      const data = await apiCall(method, endpoint);
      setRefresh(!refresh);
      setIsLoading(false);
      const message = isCurrentlyFavorite
        ? "Removed from favorites successfully!"
        : "Added to favorites successfully!";
      // toast.success(message);
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
      toast.error("Something went wrong, try again");
    }
  }
  const handleRadioChange = (event) => {
    setSelectedFilterOp(event.target.value);
  };
  const openModalSortBy = () => {
    setIsOpenSortBy(true);
  };
  const closeModalSortBy = () => {
    setIsOpenSortBy(false);
  };
  function capitalizeFirstWord(str) {
    return str.replace(/\b\w/g, char => char.toUpperCase());
  }
  const deletePlaylistItem = async (trackId) => {
    if (auth) {
      setIsLoading(true);
      try {
        const response = await apiCall(
          "DELETE",
          `${ApiEndPoint.DELETETRACKBYID}/${trackId}/playlistId/${PlaylistId}/contentType/YOUTUBE_TRACK`
        );
        const data = await response.json();

        if (response.status === 200) {
          setRefresh(!refresh);
          toast.success(data.message || "Deleted successfully");
        } else {
          toast.error(data.message || "Failed to delete");
        }
      } catch (error) {
        toast.error("Failed to delete. Please try again.");
      } finally {
        setIsLoading(false);
      }
    } else {
      // Handle case when user is not authenticated
    }
    // setIsOpen(false);
  };
  function takeSubscription() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card">
            <div className="info__padding">
              <h3>{`Please Subscribe to Paid Yearly (12 months) plan to play this Bhakti Pad`}</h3>
              {/* <h3>{!auth ? "Sign In" : ""}</h3> */}
            </div>
            {!auth ? (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      onChange({ true: true });
                      onClose();
                    }}
                    variant="contained"
                    className="signBtnHome"
                  >
                    <img
                      style={{ height: "23px", width: "23px" }}
                      className="g-icon"
                      src={Google}
                      alt="img"
                    ></img>
                    Sign-in
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      navigate("/subscriptionPlanList");
                      onClose();
                    }}
                    variant="contained"
                    className="subscriptionBtn"
                  // className="signBtnHome"
                  >
                    {/* <img className="g-icon" src={Google}></img> */}
                    Subscribe
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        );
      },
    });
  }
  useEffect(() => {
    if (isOpen) {
      fetchUserPlaylistData();
    }
  }, [isOpen]);


  const handleTitleClickAlbum = (item) => {


    const filteredItem = item.filter(track => track?.url);

    if (!filteredItem.length) {
      toast.warn("No Bhakti Pad Available to Play");
      return;
    }

    if (playListDetails?.noOfContents > 0) {
      setPlayingShuffledAlbumId(null)
      if (playingAlbumId) {
        if (playingAlbumId !== PlaylistId) {
          setTrack([]);
          setSingleTrack([]);
          setPlayNextTrack([]);
          setIsMusicPlayerVisible(false);
          setPlayingAlbumId(PlaylistId);
          setTrack(filteredItem);
          setIsMusicPlayerVisible(true);
        } else {
          setTrack([]);
          setSingleTrack([]);
          setPlayNextTrack([]);
          setIsMusicPlayerVisible(false);
          setPlayingAlbumId(null);
        }
      } else {
        setTrack([]);
        setSingleTrack([]);
        setPlayNextTrack([]);
        setPlayingAlbumId(PlaylistId);
        setTrack(filteredItem);
        setIsMusicPlayerVisible(true);
      }
    }
  };

  const shuffleArray = (item) => {
    let currentIndex = item.length, randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [item[currentIndex], item[randomIndex]] = [
        item[randomIndex], item[currentIndex]];
    }
    return item;
  };

  const handleTitleClickAlbumShuffled = (item) => {


    const filteredItem = item.filter(track => track?.url);

    if (!filteredItem.length) {
      toast.warn("No Bhakti Pad Available to Play");
      return;
    }



    if (playListDetails?.noOfContents > 0) {
      setPlayingAlbumId(null)
      const shuffledTracks = shuffleArray([...filteredItem]);
      if (playingShuffledAlbumId) {
        if (playingShuffledAlbumId !== PlaylistId) {
          setTrack([]);
          setSingleTrack([]);
          setPlayNextTrack([]);
          setIsMusicPlayerVisible(false);
          setPlayingShuffledAlbumId(PlaylistId);
          setTrack(shuffledTracks);
          setIsMusicPlayerVisible(true);
        } else {
          setTrack([]);
          setSingleTrack([]);
          setPlayNextTrack([]);
          setIsMusicPlayerVisible(false);
          setPlayingShuffledAlbumId(null);
        }
      } else {
        setTrack([]);
        setSingleTrack([]);
        setPlayNextTrack([]);
        setPlayingShuffledAlbumId(PlaylistId);
        setTrack(shuffledTracks);
        setIsMusicPlayerVisible(true);
      }
    }
  };

  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setTimeout(() => {
        setPopupVisible(null);
      }, 500);
    }
  };

  useEffect(() => {
    if (isPopupVisible !== null) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPopupVisible]);

  const togglePopup = (id) => {
    setPopupVisible(isPopupVisible === id ? null : id);
  };

  const handlePlayNextContext = (item) => {

    setPlayNextTrack([])
    setPlayNextTrack(item)
    // let trackList = [];
    // trackList[0] = item;
    // setSingleTrack(trackList[0]);
    // setIsMusicPlayerVisible(true);
  };

  // const handlePlayNext = (id) => {
  //   if (!isMusicPlayerVisible) {
  //     toast.warn("No Bhakti pad is playing.");
  //     setPopupVisible(null);
  //     return;
  //   }

  //   const trackDetails = musicData?.find(track => track?.id == id);
  //   if (trackDetails) {
  //     const newTrackList = [...track];      
  //     newTrackList.splice(currentTrackIndex + 1, 0, trackDetails);
  //     setTrack(newTrackList);
  //   }

  //   setPopupVisible(null);
  //   toast.success("Bhakti Pad will Play Next.");
  // };

  const handlePlayNext = (id) => {
    if (!isMusicPlayerVisible) {
      toast.warn("No Bhakti pad is playing.");
      setPopupVisible(null);
      return;
    }
    const trackDetails = musicData?.find(track => track?.id == id);
    if (trackDetails) {
      let newTrackList = [...track];
      const existingTrackIndex = newTrackList.findIndex(track => track.id === id);
      if (existingTrackIndex !== -1) {
        newTrackList.splice(existingTrackIndex, 1);
      }
      newTrackList.splice(currentTrackIndex + 1, 0, trackDetails);
      setTrack(newTrackList);
      dispatch(addTrack(newTrackList));
      toast.success("Bhakti Pad will Play Next.");
    }
    setPopupVisible(null);
  };

  const [trackDetailsForShare, setTrackDetailsForShare] = useState(null);


  const handleCopyLink = (id) => {
    // console.log(`Copy link for track: ${id}`);
    const trackDetails = musicData?.find(track => track?.id == id);
    // console.log('trackDetails',trackDetails?.audioAlbum?.id);
    setTrackDetailsForShare(trackDetails?.audioAlbum?.id)
    setPopupVisible(null);
  };

  const formatDuration = (duration) => {

    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  }

  const addVideoBtn = (id) => {
    navigate("/myCreatePlayListTrackAddBtnSuggested");
  };

  function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = remainingMinutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
  }
  function convertSecondsToHoursMinutes(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  }

  return (
    <section class={`playlist-page${isMusicPlayerVisible ? " ytPlayerOpen" : ""}`}>
      {/* <section class="playlist-page ytPlayerOpen"> */}
      <div className="playlist-page-title-outer">
        <div className="playlist-page-title">
          <span className="my-favorites-d-flex"
          >
            <img
              onClick={() => navigate(-1)}
              className="my-favorites-c-pointer"
              src={AllImages.YellowArrow}
              alt="Back Arrow"
              title="Back Arrow"
            ></img>
            <div class="top">
              {/* <img src={playListDetails?.thumbnail} class="topImg" /> */}
              <img
                src={generateImageDataURL(playListDetails?.playListName || 'Shared Playlist')}
                className="topImg"
              />

              <div class="padDetail">
                <p class="category">Shared Playlist</p>
                <p class="label">{playListDetails?.playListName}</p>
                <div class="padDetailInner">
                  {/* <p class="year">2021</p> */}
                  <p class="dot">&nbsp;</p>
                  <p class="time">{convertSecondsToHoursMinutes(playListDetails?.youtubeTrackDuration)} hrs</p>
                  <p class="dot">&nbsp;</p>
                  <p class="count">{playListDetails?.noOfContents} bhakti pads</p>
                </div>
              </div>
            </div>
          </span>
        </div>
      </div>
      <div class="controlsTop">
        <div class="controlsTopInner">
          <div class="padPlayInner">
            <img src={(playingAlbumId == PlaylistId) ? AllImages.StopAlbum : AllImages?.PlayAlbum} class="" alt="img"
              onClick={() => {  handleTitleClickAlbum(tracks)  }}
            />

          </div>

          <img src={(playingShuffledAlbumId == PlaylistId) ? AllImages.ShuffleActive : AllImages?.Shuffle} class="" alt="img"
            onClick={() => {  handleTitleClickAlbumShuffled(tracks)  }}
          // onClick={() => handleTitleClickAlbumShuffled(tracks)}
          />
          {/* <img src={AllImages?.TablePlus} class="" alt="img" /> */}
          {/* <img src={AllImages?.More} class="moreImg" alt="img" /> */}
        </div>
        {/*----- Search Bar Start -----*/}
        <div>
          <div className="p-list-search-wrap">
            <div className="search-input">
              <span className="search-icon">
                {isLoading ? (
                  <img
                    src={loader}
                    alt="loading..."
                    style={{ height: 15, width: 15 }}
                  />
                ) : (
                  <div className="searchTooltip">
                    <img src={AllImages.Search} alt="Search" title="Search" />
                    <span className="tooltiptext">{"Search"}</span>
                  </div>
                )}
              </span>
              <input
                className="f-poppins"
                type="text"
                placeholder="Search for Bhakti Pad"
                value={searchText}
                onChange={handleSearchInputChange}
                onKeyUp={handleKeyPress}
              />
            </div>
            <div className="searchIcon-outer searchTooltip">
              <img
                src={AllImages.SearchIcon}
                alt="Search"
                title="Search"
                className="searchIcon"
              />
              <span className="tooltiptext">{"Search"}</span>
            </div>
            <div
              className="filter-icon sortbyTooltip"
              onClick={() => openModalSortBy()}
            >
              <img src={AllImages.SearchToggle} alt="Search" title="Search" />
              <span className="tooltiptext">{"Sort By"}</span>
            </div>

            <div
            // onClick={() => addVideoBtn(playListDetails.id)}
            >
              {/* <p className="header-create-btn">Add</p> */}
            </div>

          </div>
        </div>
        {/*----- Search Bar End -----*/}
      </div>
      <InfiniteScroll
        className="mt-15"
        dataLength={musicData.length}
        next={() => infiniteScroll()}
        hasMore={scrollHashMap}
        loader={
          scrollLast ? (
            isLoading && (
              <center>
                <img
                  src={loader}
                  alt="loading..."
                  style={{ height: 25, width: 25, marginTop: 20 }}
                />
              </center>
            )
          ) : (
            <p className="subtitle fancy">
              <span>End of results</span>
            </p>
          )
        }
      >
        {isOpen && (
          <Dialog
            open={openModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            scroll={"paper"}
          >
            <DialogContent
              // ref={dialogContentRef}
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                maxHeight: "320px", // Adjust the maximum height as needed
                margin: 5,
              }}
            // onScroll={handlePlaylistScroll}
            >
              <div className="add-my-playlist-modal-content">
                <div className="add-my-playlist-modal-d-flex modal-mb-5">
                  <p className="add-my-playlist-modal-msg-text">
                    {"Add to My Playlists"}
                  </p>
                  <span
                    className="add-my-playlist-modal-close-button"
                    onClick={closeModal}
                  >
                    &times;
                  </span>
                </div>
                <div>
                  {userPlayList &&
                    userPlayList?.map((item, index) => (
                      <div
                        key={item.id}
                        className="add-my-playlist-modal-d-flex modal-mt-15"
                      >
                        <input
                          type="checkbox"
                          id={`checkbox_${item.id}`}
                          name={`checkbox_${item.id}`}
                          data-playlist-id={item.id}
                          checked={isPlaylistSelected(item.id)}
                          onChange={handleCheckboxChange}
                        />
                        <label
                          className="add-my-playlist-checkbox-text"
                          htmlFor={`checkbox_${item.id}`}
                        >
                          {item.playListName}
                        </label>
                      </div>
                    ))}
                  {userPlayList && userPlayList.length > 6 && scrollHashMap && (
                    <p
                      onClick={fetchMoreUserPlaylistData}
                      className="fetch-more-playlist"
                    >
                      MORE+
                    </p>
                  )}
                </div>
                <div className="modal-mt-15" onClick={createPlaylist}>
                  <label className="add-my-playlist-create-playlist">
                    Create Playlist
                  </label>
                </div>
              </div>
              {isLoading && (
                <div className="loader">
                  <img src={loader} alt="loading..." />
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <div className="add-my-playlist-modal-action">
                <button
                  onClick={closeModal}
                  className="add-my-playlist-modal-cancel-btn"
                >
                  {"Cancel"}
                </button>
                <button
                  className="add-my-playlist-modal-add-btn"
                  onClick={addVideoAgainstPlaylist}
                >
                  {"confirm"}
                </button>
              </div>
            </DialogActions>
          </Dialog>
        )}


        <Table responsive striped>
          {musicData.length > 0 && (
            <thead class="mobHide">
              <tr>
                <th>No.</th>
                <th>Title</th>
                <th class="none">Album</th>
                <th class="none">Duration</th>
                <th>Actions</th>
              </tr>
            </thead>
          )}

          <tbody className="mobHide">
            {musicData.length > 0 ? musicData.map((item, index) => (
              <tr key={item.id} class="tableHover">
                <td>
                  <div class="srNo"
                    onClick={() => { item?.url ? handleTitleClick(item) : takeSubscription(item) }}
                  >
                    <p class="no mobHide">{index + 1}</p>
                    <img src={item?.thumbnailUrl} class="bhaktiImg" alt="img" />
                    {item?.url ?
                      <img src={AllImages?.PlayIcon} class="trackPlayButton" alt="img" />
                      :
                      <div className="star1 badgestarInvisible tableLock">
                        <div className="LikeCount badgeShow">
                          <i
                            className="fa fa-lock"
                            onClick={() => takeSubscription(item)}
                          ></i>
                        </div>
                        <span className="tooltiptext">
                          {"Subscribe to use this feature"}
                        </span>
                      </div>
                    }
                  </div>
                </td>
                <td>
                  <div class="titleSection"
                    onClick={() => { item?.url ? handleTitleClick(item) : takeSubscription(item) }}
                  >
                    {/* <div class="titleSection" onClick={() => navigate('/playlist')}> */}
                    <p class="title">{item.title}</p>
                    <p class="subTitle">{item?.audioAlbum?.title}</p>
                  </div>
                </td>
                <td class="none"><p class="album">{item?.audioAlbum?.title}</p></td>
                <td class="none"><p class="duration">{formatDuration(item.duration)}</p></td>
                <td>
                  <div class="actions">
                    {/* <img src={AllImages?.Favorite_sel} class="mobHide" alt="img" /> */}
                    <div className="favTooltip">
                      <img
                        onClick={() => 
                          item?.url ?
                          toggleFavoriteTrack(item, index)
                          : takeSubscription(item)
                        }
                        src={item.isFavourite ? AllImages.Favorite_sel : AllImages?.Fav}
                        alt="IMG"
                      ></img>
                      <span className="tooltiptext">
                        {!item.isFavourite ? "Mark Favorite" : "Unfavorite"}
                      </span>
                    </div>
                    {/* <div className="favTooltip">
                      <img
                        className="video-play-mr-10"
                        class="mobHide"
                        alt="img"
                        src={AllImages?.TableMinus}
                        onClick={() => deletePlaylistItem(item?.id)}
                      ></img>
                      <span className="tooltiptext">
                        {"Delete from My Playlists"}
                      </span>
                    </div> */}
                    {/* three dots START */}
                    <div key={item.id} ref={popupRef} className="popupOuter">
                      <img
                        src={AllImages?.More}
                        className="moreImg"
                        alt="img"
                        onClick={() => 
                          item?.url ?
                          togglePopup(item?.id)
                          : takeSubscription(item)
                        }
                      />
                    </div>
                    {/* three dots END */}
                  </div>
                  {isPopupVisible === item.id && (
                    !isMobileView ? (
                      <div className="morePopup" ref={popupRef}>
                        <div className="outer" onClick={() => handlePlayNext(item.id)}>
                          <img src={AllImages?.Next} className="more" alt="img" />
                          <p className="label">Play Next</p>
                        </div>
                        <div className="outer" onClick={() => handleCopyLink(item.id)}>
                          <RWebShare
                            data={{
                              url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                              title: 'Bhakti Pad Share',
                            }}
                            onClick={() => {
                              handleCopyLink(item.id);
                              console.log('shares');
                            }}
                          >
                            <div className="copyTooltip">
                              <img src={AllImages?.CopyGray} className="more" id="myCopy" alt="img" />
                              <span className="tooltiptext">Copy</span>
                            </div>
                          </RWebShare>
                          <div>
                            <RWebShare
                              data={{
                                url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                                title: 'Bhakti Pad Share',
                              }}
                              onClick={() => {
                                handleCopyLink(item.id);
                                console.log('share');
                              }}
                            >
                              <p className="label">Copy Link</p>
                            </RWebShare>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="mobileViewDialogOuter">
                        <Dialog
                          open={togglePopup}
                          className="sortBy mobileViewDialog"
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogContent
                            style={{
                              overflowY: "hidden",
                              overflowX: "hidden",
                            }}
                          >
                            <div className="qu-history-modal-msg-content">
                              <p className="qu-history-modal-msg-text">{"More Option"}</p>
                              <span
                                className="qu-history-modal-close-button"
                                onClick={
                                  item?.url ?
                                  togglePopup
                                  : takeSubscription(item)
                                }
                              >
                                &times;
                              </span>
                            </div>
                            <div class="actionPopupMob">
                              <div class="outer tabHide" onClick={() => deletePlaylistItem(item, index)}>
                                {/* <img src={AllImages?.FavouriteOutline} class="fav" /> */}
                                <img
                                  src={item.isFavourite ? AllImages.Favorite_sel : AllImages?.Fav}
                                  alt="IMG"
                                ></img>
                                <p class="label">Add to Favourites</p>
                              </div>
                              <div class="outer tabHide" onClick={() => openModal(item?.id)}>
                                <img
                                  className="plus"
                                  alt="img"
                                  src={AllImages?.TablePlus}
                                />
                                <p class="label">Add to Playlist</p>
                              </div>
                              <div className="outer" onClick={() => handlePlayNext(item.id)}>
                                <img src={AllImages?.Next} className="more" alt="img" />
                                <p className="label">Play Next</p>
                              </div>
                              <div className="outer" onClick={() => handleCopyLink(item.id)}>
                                <RWebShare
                                  data={{
                                    url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                                    title: 'Bhakti Pad Share',
                                  }}
                                  onClick={() => {
                                    handleCopyLink(item.id);
                                    console.log('shares');
                                  }}
                                >
                                  <div className="copyTooltip">
                                    <img src={AllImages?.CopyGray} className="more" id="myCopy" alt="img" />
                                    <span className="tooltiptext">Copy</span>
                                  </div>
                                </RWebShare>
                                <div>
                                  <RWebShare
                                    data={{
                                      url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                                      title: 'Bhakti Pad Share',
                                    }}
                                    onClick={() => {
                                      handleCopyLink(item.id);
                                      console.log('share');
                                    }}
                                  >
                                    <p className="label">Copy Link</p>
                                  </RWebShare>
                                </div>
                              </div>
                            </div>
                          </DialogContent>
                        </Dialog>
                      </div>
                    )
                  )}
                </td>
              </tr>
            ))
              :
              <tr>
                <td colspan="5" style={{ textAlign: 'center' }}>
                  {isLoading ? (
                    <img
                      src={loader}
                      alt="loading..."
                      style={{ height: 35, width: 35, marginTop: 20 }}
                    />
                  ) : (
                    <NoDataAvailable />
                  )}
                </td>
              </tr>
            }
          </tbody>

        </Table>
      </InfiniteScroll>
      {isOpenSortBy && (
        <Dialog
          open={openModalSortBy}
          className="sortBy"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent
            style={{
              overflowY: "hidden",
              overflowX: "hidden",
            }}
          >
            <div className="qu-history-modal-msg-content">
              <p className="qu-history-modal-msg-text">{"Sort by"}</p>
              <span
                className="qu-history-modal-close-button"
                onClick={() => closeModalSortBy()}
              >
                &times;
              </span>
            </div>
            <ul>
              {Object.keys(SortingOptions).map((key) => (
                <li key={key}>
                  <label className="customRadio">
                    {SortingOptions[key] === "A_TO_Z" ||
                      SortingOptions[key] === "Z_TO_A"
                      ? key.replace(/_/g, " ")
                      : capitalizeFirstWord(
                        key.replace(/_/g, " ").toLowerCase()
                      )}
                    <input
                      type="radio"
                      value={SortingOptions[key]}
                      checked={selectedFilterOp === SortingOptions[key]}
                      onChange={handleRadioChange}
                      name="radio"
                    />
                    <span className="checkmark"></span>
                  </label>
                </li>
              ))}
            </ul>
          </DialogContent>
        </Dialog>
      )}
    </section>
  );
}
export default AudioPlaylist;
