import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { AllImages } from "../../../utils/constants";
import "./style.css";
import { Table } from "reactstrap";


const BhaktiPadsTrackList = (props) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="bhaktiPadsTrackAllList">
        {/* <InfiniteScroll> */}
        <div>
          {/*----- Created Playlist Title Start -----*/}
          <div className="bhaktiPads-track-list-header">
            <div className="bhaktiPads-track-list-title-mt-20">
              <span
                className="bhaktiPads-track-list-d-flex"
              >
                <img
                  className="bhaktiPads-track-list-c-pointer"
                  src={AllImages.YellowArrow}
                  alt="Back Arrow"
                  title="Back Arrow"
                  onClick={() => navigate(-1)}
                ></img>
                <p className="bhaktiPads-track-list-title">
                  BhaktiPads Track List
                </p>
              </span>
            </div>
            <div className="bhaktiPads-track-list-header-right">
              <div>
                <p className="header-create-btn">CREATE</p>
              </div>
            </div>
          </div>
          {/*----- Created Playlist Title End -----*/}

          <div>
            <Table responsive striped>
              <thead class="mobHide">
                <tr>
                  <th>No.</th>
                  <th>Title</th>
                  <th class="none">Album</th>
                  <th class="none">Duration</th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody class="mobHide">
                {/* {popularPads?.map((item, index) => ( */}
                <tr>
                  <td>
                    <div class="srNo">
                      <p class="no mobHide">{1}</p>
                      <img src={AllImages?.Bhakti} class="bhaktiImg" alt="img" />
                    </div>
                  </td>
                  <td>
                    <div
                      class="titleSection"
                    //onClick={() => handleTitleClick(item)}
                    >
                      <p class="title">{"Aatmaprapt Sadguru ne Pranaam (feat. Shree Prem Acharyaji, Rachit Hemani & Urvi Shah)"}</p>
                      <p class="subTitle">{"Album - Purusharth Prerak Vachano"}</p>
                    </div>
                  </td>
                  <td class="none">
                    <p class="album">{"Album - Purusharth Prerak Vachano"}</p>
                  </td>
                  <td class="none">
                    <p class="duration">
                      {/* {Math.floor(item.duration / 60)}:{item.duration % 60} */}
                      {"10:44"}
                    </p>
                  </td>
                  <td>
                    <div class="actions">
                      <img
                        src={AllImages?.Favorite_sel}
                        class="mobHide"
                        alt="img"
                      />
                      <img src={AllImages?.TablePlus} class="mobHide" alt="img" />
                      <img src={AllImages?.More} class="more" alt="img" />
                    </div>
                  </td>
                </tr>
                {/* ))} */}
              </tbody>
            </Table>
          </div>
        </div>
        {/* </InfiniteScroll> */}
      </div>
    </>
  );
};
export default BhaktiPadsTrackList;
