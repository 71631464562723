import React, { useState, useContext, useEffect, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import './style.css';
import { GLOBAL_URL, AllImages, RemoveDuplicates } from '../../../utils/constants'
import { UserloggedContext, LoaderContext, userDetailsContext, SubcriptionHistoryContext, SubcriptionDetailsContext, GSTContext, SelectedSubcriptionContext, SubcriptionListContext } from "../../Context/UserContext";
import ApiEndPoint from "../../../utils/apiEndPoints";
import { setDefaultHeader, apiCall } from "../../../utils/httpClient";
import moment from 'moment'
import { Button } from "@material-ui/core";
import useGoogleLoginFlow from "components/common/useGoogleLoginFlow";
import useRazorpay from "react-razorpay";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import Google from "../../../assets/images/google.png";


function ActiveSubscriptionPlan() {
  const location = useLocation();
  const [auth, setAuth] = useContext(UserloggedContext);
  const [subscriptionDetails, setSubcriptionDetails] = useContext(SubcriptionDetailsContext);
  const [subscriptionHistory, setSubscriptionHistory] = useContext(SubcriptionHistoryContext)
  const [selectedSubscription, setSelectedSubscription] = useContext(SelectedSubcriptionContext)
  const [subscriptionList, setSubcriptionList] = useContext(SubcriptionListContext);
  const [IsLoading, setIsLoading] = useContext(LoaderContext);
  const [userDetails, setUserDetails] = useContext(userDetailsContext);
  const [gstpagemanage, setGstpagemanage] = useContext(GSTContext)

  const {
    openRecaptcha,
    recaptchaFun,
    onChange,
  } = useGoogleLoginFlow()

  const Razorpay = useRazorpay();
  const navigate = useNavigate();



  useEffect(() => {
    getSubcriptionList();
    getSubcriptionDetails();
    getSubcriptionHistoryDetails()
  }, []);


  async function getSubcriptionDetails() {
    try {
      !subscriptionDetails?.id && setIsLoading(true);
      if (auth == "01") {
        const { data } = await apiCall(
          "GET",
          ApiEndPoint.GETSUBCRIPTIONDETAILS
        );
        if (data) {
          setSubcriptionDetails(data);
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  async function getSubcriptionHistoryDetails() {
    try {
      !subscriptionHistory && setIsLoading(true)
      if (auth == '01') {
        const { data } = await apiCall('GET', ApiEndPoint.GETSUBSCRIPTIONHISTORY)
        if (data) {
          setSubscriptionHistory(data)
          setIsLoading(false)
        }
      }
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }










  const handlePayment = useCallback(async (data, subscriptionId, phoneNumber) => {

    const options: RazorpayOptions = {
      key: data.keyId,
      amount: data.amount,
      currency: "INR",
      name: "Satshrut",
      description: "",
      image: "https://vitraagvigyaan.org/img/logo.png",
      order_id: data.orderId,
      handler: (res) => {
        getUserDetails()
        setGstpagemanage('')
        toast.success('Payment successful', {
          position: toast.POSITION.TOP_RIGHT
        });
        navigate('/')
        console.log(res);
      },
      prefill: {
        name: userDetails?.user?.firstName,
        email: userDetails?.user?.email,
        // contact: 'UNIQUE_IDENTIFIER',
        contact: phoneNumber ? phoneNumber : 'UNIQUE_IDENTIFIER',
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
      method: {
        netbanking: true,
        card: true,
        wallet: false,
        upi: false,
      },
      config: {
        display: {
          hide: [{ method: 'paylater' }]
        }
      }
    };
    // localStorage.removeItem('rzp_contact');
    const rzpay = new Razorpay(options);
    rzpay.on("payment.failed", function (response) {

    });
    rzpay.open();
    // rzpay.clearContact();
  },
    [Razorpay]
  );


  function takeSubcription() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card">
            <div className="info__padding">
              <h3>
                {`Please Sign In To Subscribe...`}
              </h3>
            </div>
            <div className="button__group">

              <Button
                onClick={() => {
                  onChange({ true: true });
                  onClose();
                }}
                variant="contained"
                className="signBtnHome"
              >
                {/* Sign-in with  */}
                <img style={{ height: '23px', width: '23px' }} className="g-icon" src={Google} alt="img"></img>
                Sign-in
              </Button>
              <button
                className="no-btn"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        );
      },
    });
  }

  async function getSubcriptionList() {
    try {
      if (auth == "01") {
        const { data } = await apiCall("GET", ApiEndPoint.LOGINSUBCRIPTION);
        if (data?.length > 0) {
          setSubcriptionList(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else {
        const { data } = await apiCall(
          "GET",
          ApiEndPoint.SUBCRIPTIONPLANWITHOUTLOGIN
        );
        if (data?.length > 0) {
          setSubcriptionList(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }


  async function addSubcriptionFun(item) {
    try {
      // setIsLoading(true);
      const Auth = await localStorage.getItem("userToken");
      if (Auth == "01") {
        const params = {
          amount: item.price,
          subscriptionId: item.id,
        };
        const { data } = await apiCall("POST", `orders`, params);

        if (data) {
          handlePayment(data, item.id, userDetails?.user?.phoneNumber);
          document.cookie = 'rzp_contact=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
          setIsLoading(false);
          // toast.success('Successfully', {
          //   position: toast.POSITION.TOP_RIGHT
          // });
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }





  async function getUserDetails() {
    try {
      const Auth = await localStorage.getItem("userToken");
      if (Auth == "01") {
        const { data } = await apiCall(
          "GET",
          ApiEndPoint.GETSUBCRIPTIONDETAILS
        );
        if (data) {
          setUserDetails(data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }


  async function addSubcriptionFun(item) {
    try {
      // setIsLoading(true);
      const Auth = await localStorage.getItem("userToken");
      if (Auth == "01") {
        const params = {
          amount: item.price,
          subscriptionId: item.id,
        };
        const { data } = await apiCall("POST", `orders`, params);

        if (data) {
          handlePayment(data, item.id, userDetails?.user?.phoneNumber);
          document.cookie = 'rzp_contact=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
          setIsLoading(false);
          // toast.success('Successfully', {
          //   position: toast.POSITION.TOP_RIGHT
          // });
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }


  async function Subcription(item) {
    if (userDetails?.user?.country && userDetails?.user?.state && userDetails?.user?.city && userDetails?.user?.pinCode && userDetails?.user?.phoneNumber) {
      addSubcriptionFun(item)
    } else {
      if (gstpagemanage == 'user') {
        addSubcriptionFun(item)
      } else {
        setSelectedSubscription(item)
        navigate('/editProfile')
        setGstpagemanage('subscription')
      }
    }
  }







  const [subcriptionButtonValue, setSubcriptionButtonValue] = useState(userDetails?.user?.subscriptionRenew)




  return (
    <section className="activeSubscriptionPlanList">
      <h1 className="mainTitle">Subscription</h1>
      <div className="subscriptionPlanListInner">
        {subscriptionDetails && <div className="first">
          <p className="plansTitle">Our Valuable Plans</p>

          {/* <div className="SubscriptionCard">
            <div className="header">
              <p className="title">Free Plan</p>
              <div className="status">
                <div className="active">&nbsp;</div>
                <p className="text activeText">Active</p>
              </div>
            </div>
            <p className="desc">Free Forever</p>
          </div> */}
          {subscriptionList.map((item) => (
            <div className="SubscriptionCard">
              <div className="header">
                <p className="title">Annual Plan</p>
                <div className="statusOuter">
                  <div className="status">
                    <div className="active">&nbsp;</div>
                    <p className="text activeText">Active</p>
                  </div>
                  <div className="status">
                    <p className="text">{subscriptionDetails?.subscriptionPlan?.subscriptionName}</p>
                  </div>
                </div>
              </div>
              <p className="desc">&#8377;<span className="amount">{subscriptionDetails?.subscriptionPlan?.price}</span><span className="subText">(Including GST)</span></p>

              <div className="benefits">
                <p className="title">Benefits Included</p>
                {subscriptionDetails?.subscriptionPlan?.specifications?.map((x) => (
                  <ul>
                    <li>{x.specificationName}</li>
                  </ul>
                ))}
              </div>

              <div className="buySubscriptionOuter"
                onClick={() =>
                  auth == "01"
                    ? Subcription(item)
                    : takeSubcription()
                }
              >
                {
                  (subcriptionButtonValue === true) ?
                    <button className="button btn buySubscription">Renew Subscription</button>
                    :
                    ""
                  // <button className="button btn buySubscription">Buy Subscription</button>
                }
              </div>

            </div>
          ))}
        </div>

        }

        {subscriptionHistory?.length > 0 && <div className="second">
          <p className="historyTitle">Subscription History</p>
          <div className="SubscriptionCardOuter">
            {subscriptionHistory?.map((item) => (
              <div className="SubscriptionCard">
                <div className="history">
                  <div className="left">
                    <p className="title">Type of Plan</p>
                    <p className="subTitle">{item?.subscriptionPlan?.subscriptionName ? item?.subscriptionPlan?.subscriptionName : '--'}</p>
                  </div>
                  <div className="right">
                    <p className="title">Price</p>
                    <p className="subTitle">{item?.subscriptionPlan?.price ? item?.subscriptionPlan?.price : '--'}</p>
                  </div>
                </div>
                <div className="history">
                  <div className="left">
                    <p className="title">Start Date</p>
                    <p className="subTitle">{item?.startDate ? moment(item?.startDate).format('YYYY-MM-DD HH:mm') : '--'}</p>
                  </div>
                  <div className="right">
                    <p className="title">End Date</p>
                    <p className="subTitle">{item?.endDate ? moment(item?.endDate).format('YYYY-MM-DD HH:mm') : '--'}</p>
                  </div>
                </div>
                <div className="history">
                  <div className="left">
                    <p className="title">Transaction ID</p>
                    {/* <p className="subTitle">{item?.transactionId ? item?.transactionId : '--'}</p> */}
                    <div className='playIconTooltip'>
                      <p className="subTitle">{item?.transactionId ? item?.transactionId : '--'}</p>
                      <span className='tooltiptext'>
                        {item?.transactionId ? item?.transactionId : '--'}                      </span>
                    </div>
                  </div>
                  <div className="right">
                    <p className="title">Transaction Date</p>
                    <p className="subTitle">{item?.transactionDate ? item?.transactionDate : '--'}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>

        </div>
        }
      </div>
    </section>
  );
}
export default ActiveSubscriptionPlan;
