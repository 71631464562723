import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { RWebShare } from "react-web-share";
import InfiniteScroll from "react-infinite-scroll-component";
import { AllVideoPlayListContext } from "../../Context/UserContext";
import ApiEndPoint from "../../../utils/apiEndPoints";
import { setDefaultHeader, apiCall } from "../../../utils/httpClient";
import loader from "../../../assets/images/logo.png";
import Google from "../../../assets/images/google.png";
import Delete from "../../../assets/Phase2/delete.png";
import { CiCircleList } from "react-icons/ci";
import { CiGrid2V } from "react-icons/ci";
import {
  GLOBAL_URL,
  VideoPlaylistShareUrl,
  AllImages,
  RemoveDuplicates,
  emojiRegex,
} from "../../../utils/constants";
import {
  LoaderContext,
  userDetailsContext,
  UserloggedContext,
  UserPlayListContext,
} from "../../Context/UserContext";
import "./style.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import NoDataAvailable from "../../common/NoDataFound/NoDataAvailable";
import useGoogleLoginFlow from "components/common/useGoogleLoginFlow";
import { confirmAlert } from "react-confirm-alert";
import Button from "@material-ui/core/Button";
const SortingOptions = {
  NEWLY_ADDED: "NEWLY_ADDED",
  PREVIOUSLY_ADDED: "PREVIOUSLY_ADDED",

  LAST_MODIFIED_DATE: "LAST_MODIFIED_DATE",
  Sort_A_To_Z: "A_TO_Z",
  Sort_Z_To_A: "Z_TO_A",
};
const AllPlayList = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isListView, setIsListView] = useState(false);
  const [auth, setAuth] = useContext(UserloggedContext);
  const { viewMore } = location.state || {};
  const [allVideoPlayList, setAllVideoPlayList] = useContext(
    AllVideoPlayListContext
  );
  const [userPlayList, setUserPlayList] = useContext(UserPlayListContext);
  const [userDetails, setUserDetails] = useContext(userDetailsContext);
  const [pageNo, setPageNo] = useState(0);
  const [scrollHashMap, setScrollHashMap] = useState(true);
  const [scrollLast, setScrollLast] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [isOpenSortBy, setIsOpenSortBy] = useState(false);
  const {
    openRecaptcha,

    recaptchaFun,

    onChange,
  } = useGoogleLoginFlow();
  const [selectedFilterOp, setSelectedFilterOp] = useState(
    SortingOptions.NEWLY_ADDED
  );
  const fetchData = () => {
    setScrollHashMap(true);

    fetchVideoPlayList(1);
  };
  const handleToggleListView = () => {
    setIsListView((prevView) => !prevView);
  };
  const fetchMoreData = () => {
    fetchVideoPlayList();
  };

  useEffect(() => {
    window.history.scrollRestoration = "manual";
    fetchData();
    return () => {
      const array = allVideoPlayList?.slice(0, 3);
      setAllVideoPlayList(array);
    };
  }, [selectedFilterOp]);

  async function watchLaterSavePlayListFun(id) {
    if (auth) {
      try {
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.PLAYLISTWATCHLATERSAVE}/${id}`
        );
        if (data.length > 0) {
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
      }
    }
  }

  async function fetchVideoPlayList(fetchPage = 0) {
    // setIsLoading(true)
    try {
      const params = {
        pageNo: fetchPage ? fetchPage - 1 : pageNo,
        pageSize: 20,
        searchValue: searchText,
        sortColumn: selectedFilterOp,
        sortDirection: "",
      };
      setIsLoading(true);
      const { data } = await apiCall(
        "POST",
        `${
          auth
            ? ApiEndPoint.ALLVIDEOPLAYLIST
            : ApiEndPoint.ALLVIDEOPLAYLISTNONLOGIN
        }`,
        params
      );
      if (data.length > 0) {
        setIsLoading(false);
        setAllVideoPlayList(
          RemoveDuplicates(fetchPage ? data : allVideoPlayList.concat(data))
        );
        setPageNo(fetchPage ? fetchPage : pageNo + 1);
        // setAllVideoPlayList(fetchPage ? data : (prev) => [...prev, ...data]);
        setScrollHashMap(data.length == 20);
        setScrollLast(true);
      } else {
        setIsLoading(false);

        fetchPage && setAllVideoPlayList([]);
        setScrollLast(false);
        setScrollHashMap(false);
        setPageNo(0);
      }
    } catch (err) {
      setIsLoading(false);
      setScrollLast(false);
      setScrollHashMap(false);
    }
    isOpenSortBy && closeModalSortBy();
  }

  async function myFavouritePlaylist(item, index) {
    try {
      if (auth) {
        allVideoPlayList[index].favourite = item.favourite ? false : true;
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.MYFAVOURITEPLAYLIST}/${item.id}`
        );
        if (data.length > 0) {
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      } else {
        takeSubcription();
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }
  async function myFavouritePlaylistRemove(item, index) {
    try {
      if (auth) {
        allVideoPlayList[index].favourite = item.favourite ? false : true;
        const { data } = await apiCall(
          "DELETE",
          `${ApiEndPoint.MYFAVOURITEPLAYLISTREMOVE}/${item.id}`
        );
        if (data.length > 0) {
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }

  async function PlaylistWatchLater(item, index) {
    try {
      if (auth) {
        allVideoPlayList[index].watchLater = item.watchLater ? false : true;
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.PLAYLISTWATCHLATER}/${item.id}`
        );
        if (data.length > 0) {
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      } else {
        takeSubcription();
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }

  async function PlaylistWatchLaterRemove(item, index) {
    try {
      if (auth) {
        allVideoPlayList[index].watchLater = item.watchLater ? false : true;
        const { data } = await apiCall(
          "DELETE",
          `${ApiEndPoint.PLAYLISTWATCHLATERREMOVE}/${item.id}`
        );
        if (data.length > 0) {
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }

  const individualPlayListNav = (item, type) => {
    navigate("/individualPlayList", {
      state: { playListDetails: item, playListType: type },
    });
    watchLaterSavePlayListFun(item.id);
  };

  const handleRadioChange = (event) => {
    setSelectedFilterOp(event.target.value);
  };

  const openModalSortBy = () => {
    setIsOpenSortBy(true);
  };

  const closeModalSortBy = () => {
    setIsOpenSortBy(false);
  };
  function capitalizeFirstWord(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  function handleSearchPlaylist(e) {
    if (!emojiRegex.test(e.target.value)) {
      if (e.target.value) {
        setSearchText(e.target.value);
      } else {
        setSearchText(null);
      }
    }
  }
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchData();
    }
  };
  function takeSubcription() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card">
            <div className="info__padding">
              <h3>{`Please sign in to use this feature. `}</h3>
              {/* <h3>{!auth ? "Sign In" : ""}</h3> */}
            </div>
            {!auth ? (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      onChange({ true: true });
                      onClose();
                    }}
                    variant="contained"
                    className="signBtnHome"
                  >
                    {/* <img className="g-icon" src={Google}></img> */}
                    <img
                      style={{ height: "23px", width: "23px" }}
                      className="g-icon"
                      src={Google}
                    ></img>
                    Sign-in
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      navigate("/subscriptionPlanList");
                      onClose();
                    }}
                    variant="contained"
                    className="subscriptionBtn"
                    // className="signBtnHome"
                  >
                    {/* <img className="g-icon" src={Google}></img> */}
                    Subscribe
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        );
      },
    });
  }
  return (
    <>
      {viewMore && (
        <div className="allPlaylistHead">
          {/*----- List of Playlists Title -----*/}

          <div className="my-favorites-title-mt-20">
            <span className="my-favorites-d-flex" onClick={() => navigate(-1)}>
              <img
                className="my-favorites-c-pointer"
                src={AllImages.YellowArrow}
                alt="Back Arrow"
                title="Back Arrow"
              ></img>
              <p className="my-favorites-title">{"List of Playlists"}</p>
            </span>
          </div>

          {/*----- Search Bar Start -----*/}

          <div className="list-of-play-search">
          
            <div className="p-list-search-wrap">
      
              <div className="search-input">
                <span onClick={fetchData} className="search-icon">
                  {/* <img src={AllImages.Search} alt="Search" title="Search" /> */}
                  {isLoading ? (
                    <img
                      src={loader}
                      alt="loading..."
                      style={{ height: 15, width: 15 }}
                    />
                  ) : (
                    <div className="searchTooltip">
                      <img src={AllImages.Search} alt="Search" title="Search" />
                      <span className="tooltiptext">{"Search"}</span>
                    </div>
                  )}
                </span>
                <input
                  className="f-poppins"
                  type="text"
                  placeholder="Search for Video"
                  onChange={(e) => handleSearchPlaylist(e)}
                  onKeyUp={handleKeyPress}
                />
              </div>
              <div className="searchIcon-outer searchTooltip">
                <img
                  src={AllImages.SearchIcon}
                  alt="Search"
                  title="Search"
                  className="searchIcon"
                />
                <span className="tooltiptext">{"Search"}</span>
              </div>
              <div
                className="filter-icon sortbyTooltip"
                onClick={() => openModalSortBy()}
              >
                <img src={AllImages.SearchToggle} alt="Search" title="Search" />
                <span className="tooltiptext">{"Sort By"}</span>
              </div>
              <span 
              className="view-toggle"
              onClick={handleToggleListView}
            >
              {isListView ? (
                <CiGrid2V  strokeWidth={1} size={20} color="#F6AE06" className="viewToggleIcon" />
              ) : (
                <CiCircleList strokeWidth={1}  size={20} color="#F6AE06" className="viewToggleIcon" />
              )}
            </span>
            </div>
          </div>

          {/*----- Search Bar End -----*/}
        </div>
      )}
      <div className={`allPlaylist ${isListView ? `listView` : null}`}>
        <InfiniteScroll
          dataLength={
            allVideoPlayList?.slice(0, viewMore ? allVideoPlayList.length : 6)
              ?.length
          }
          next={() => fetchMoreData()}
          // hasMore={true}
          hasMore={scrollHashMap}
          loader={
            viewMore && scrollLast
              ? isLoading && (
                  <center>
                    <img
                      src={loader}
                      alt="loading..."
                      style={{ height: 25, width: 25, marginTop: 20 }}
                    />
                  </center>
                )
              : viewMore && (
                  <p className="subtitle fancy">
                    <span>End of results</span>
                  </p>
                )
          }
        >
          <div className="outer-wrap custom-row">
            {allVideoPlayList.length > 0
              ? allVideoPlayList
                  ?.slice(0, viewMore ? allVideoPlayList.length : 6)
                  ?.map((item, index) => (
                    <div key={index} className="custom-col-4 custom-col">
                      <div className="card-wrap">
                        <div className="inside-wrap">
                          <div
                            className="list-image"
                            onClick={() => {
                              individualPlayListNav(item, "admin");
                            }}
                          >
                            <img
                              className="img-banner"
                              src={`${GLOBAL_URL}/system/playlist/thumbnail/${item.id}`}
                              key={item.id}
                              alt="Card Image"
                            />
                            <div className="playIconTooltip">
                              <img
                                className="play-icon"
                                src={AllImages?.PlayIcon}
                                alt="Play Icon"
                                title="Play Icon"
                              />
                              <span className="tooltiptext">
                                {"Play Video"}
                              </span>
                            </div>
                          </div>
                          <div className="card-content-item">
                            <div className="video-content">
                              <div className="video-content-inner">
                                <p className="title-text f-poppins">
                                  {item.playlistName}
                                </p>
                                <div
                                  style={{ gap: "10px" }}
                                  className="general-textDetails general-text-hrs f-poppins"
                                >
                                  <p className="general-text-hrs time">
                                    {/* {item.videoDuration}{" "} */}
                                    {item.videoDuration
                                      ? `${item.videoDuration} hrs`
                                      : "0 hrs"}
                                  </p>
                                  <p className="general-text-hrs">•</p>
                                  <p className="general-text-hrs">
                                    {/* {item.noOfVideos}{" "} */}
                                    {item.noOfVideos
                                      ? `${item.noOfVideos} videos`
                                      : "0 videos"}
                                  </p>
                                </div>
                              </div>
                              <div className="list-of-like-icon-div">
                                <div className="timerTooltip">
                                  <img
                                    onClick={() =>
                                      item.watchLater
                                        ? PlaylistWatchLaterRemove(item, index)
                                        : PlaylistWatchLater(item, index)
                                    }
                                    src={
                                      item.watchLater
                                        ? AllImages.SelectedTimer
                                        : AllImages?.Timer
                                    }
                                    alt="Time"
                                    title="Time"
                                  ></img>
                                  <span className="tooltiptext">
                                    {!item.watchLater
                                      ? "Watch Later"
                                      : "Remove Watch Later"}
                                  </span>
                                </div>
                                <div className="favTooltip">
                                  <img
                                    onClick={() =>
                                      item.favourite
                                        ? myFavouritePlaylistRemove(item, index)
                                        : myFavouritePlaylist(item, index)
                                    }
                                    src={
                                      item.favourite
                                        ? AllImages.Favorite_sel
                                        : AllImages?.Fav
                                    }
                                    alt="Favourite"
                                    title="Favourite"
                                  ></img>
                                  <span className="tooltiptext">
                                    {!item.favourite
                                      ? "Mark Favorite"
                                      : "Unfavorite"}
                                  </span>
                                </div>
                                {/* <img
                              src={AllImages.Copy}
                              alt="Copy"
                              title="Copy"
                            ></img> */}
                                {/* <RWebShare
                                                data={{
                                                    // text: "Like humans, flamingos make friends for life",
                                                    url: `${VideoPlaylistShareUrl}/PlayListShared/${item.id}`,
                                                    title: "Video Share",
                                                }}
                                                onClick={() => console.log('share')}
                                            >
                                                <img src={AllImages.Copy}></img>
                                            </RWebShare> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
              : !isLoading && <NoDataAvailable />}
          </div>
        </InfiniteScroll>
      </div>

      {isOpenSortBy && (
        <Dialog
          open={openModalSortBy}
          className="sortBy"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent
            style={{
              overflowY: "hidden",
              overflowX: "hidden",
            }}
          >
            <div className="qu-history-modal-msg-content">
              <p className="qu-history-modal-msg-text">{"Sort by"}</p>
              <span
                className="qu-history-modal-close-button"
                onClick={() => closeModalSortBy()}
              >
                &times;
              </span>
            </div>
            <ul>
              {Object.keys(SortingOptions).map((key) => (
                <li key={key}>
                  <label className="customRadio">
                    {SortingOptions[key] === "A_TO_Z" ||
                    SortingOptions[key] === "Z_TO_A"
                      ? key.replace(/_/g, " ")
                      : capitalizeFirstWord(
                          key.replace(/_/g, " ").toLowerCase()
                        )}
                    <input
                      type="radio"
                      value={SortingOptions[key]}
                      checked={selectedFilterOp === SortingOptions[key]}
                      onChange={handleRadioChange}
                      name="radio"
                    />
                    <span className="checkmark"></span>
                  </label>
                </li>
              ))}
            </ul>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
export default AllPlayList;
