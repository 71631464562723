import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
import ApiEndPoint from "../../../utils/apiEndPoints";
import loader from "../../../assets/images/logo.png";
import Google from "../../../assets/images/google.png";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactPlayer from "react-player/lazy";
import { useEffect, useContext } from "react";
import {
  VideoListContext,
  PageReloadContext,
  userDetailsContext,
  UserloggedContext,
} from "../../Context/UserContext";
import { setDefaultHeader, apiCall } from "../../../utils/httpClient";
import { AuthContext } from "../../Context/context";
import {
  GoogleLogin,
  useGoogleLogout,
  useGoogleLogin,
} from "react-google-login";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./style.css";
import Button from "@material-ui/core/Button";
import {
  AllImages,
  googleClientId,
  GLOBAL_URL,
  getImageNameFromResolution,
} from "../../../utils/constants";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { toast } from "react-toastify";
import { generateImageDataURL } from "utils/generateImageDataURL";

const clientId = googleClientId;

function VideoPlayPage() {
  const location = useLocation();
  // const { item } = location.state;
  const { playId } = useParams();
  // console.log("playId: ", playId);
  const [showLoader, setShowLoader] = useState(true);
  const [videoList, setVideoList] = useContext(VideoListContext);
  const [isLoading, setIsLoading] = useState(false);
  const { signInFun, signOutFun } = React.useContext(AuthContext);
  const [auth, setAuth] = useContext(UserloggedContext);
  const [item, setItem] = useState();
  const navigate = useNavigate();
  const [shareplaylistData, setSharePlayListData] = useState(null);
  // console.log("shareplaylistData: ", shareplaylistData);

  useEffect(() => {
    fetchPlayList();
  }, []);

  async function fetchPlayList() {
    if (auth) {
      setIsLoading(true);
      try {
        const { data } = await apiCall(
          "GET",
          `${ApiEndPoint.SHAREPLAYLISTGET}/${playId}`
        );
        if (data) {
          setSharePlayListData(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
        console.log("err: ", err);
      }
    }
    else {
      toast.error("Please log in and try again.");
      navigate("/");
    }

   
  }

  const handleAccept = async () => {
    try {
      // const params = {
      //   "playListId": playId
      // }
      const { data } = await apiCall(
        "POST",
        `${ApiEndPoint.SHAREPLAYLISTSAVE}/${playId}`
      );
      // console.log("data: ", data);
      if (data) {
        setIsLoading(false);
        navigate("/sharedPlaylist", {
          state: { viewMore: true },
          replace: true,
        });
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);

      console.log("err: ", err);
    }
    // Add your logic for accepting here
  };

  const handleReject = () => {
    navigate("/");
  };

  return (
    shareplaylistData && (
      <section className="video-play-top sharedPlaylistVideo">
        <div className="sharedPlaylistVideoInner">
          <div className="list-image">
            <img
              className={`img-banner ${
                shareplaylistData?.thumbnail
                  ? getImageNameFromResolution(shareplaylistData?.thumbnail) !=
                      "maxresdefault" && "image-resolution"
                  : "image-resolution"
              }`}
              // src={shareplaylistData.thumbnail? shareplaylistData.thumbnail: AllImages.HomeBanner}
              src={generateImageDataURL(shareplaylistData?.playListName || 'Bhakti pad Playlist')}
              key={shareplaylistData.id}
              alt="Card Image"
              title="Card Image"
            />
          </div>
          <div>
            <p className="playlist-title"> {shareplaylistData?.playListName}</p>
          </div>
          <div className="button-container">
            <button className="accept-button" onClick={handleAccept}>
              Accept
            </button>
            <button className="reject-button" onClick={handleReject}>
              Reject
            </button>
          </div>
        </div>
      </section>
    )
  );
}
export default VideoPlayPage;
