// useGoogleLoginFlow.js

import React, { useEffect, useState,useContext } from "react";
import "./style.css";

import ApiEndPoint from "../../utils/apiEndPoints";
import { setDefaultHeader, apiCall } from "../../utils/httpClient";
import { googleClientId } from "../../utils/constants";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import { useGoogleLogout, useGoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { AuthContext } from "../Context/context";
import { LoaderContext } from "../Context/UserContext";
import * as detectDevice from "mobile-device-detect";

const useGoogleLoginFlow = () => {
  const clientId = googleClientId;
  const [googleLogin, setGoogleLogin] = useState(false);
  const [openRecaptcha, setOpenRecaptcha] = useState(true);
  const [IsLoading, setIsLoading] = useContext(LoaderContext);
  const { signInFun, signOutFun } = React.useContext(AuthContext);
  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: "email",
        plugin_name: "App Name that you used in google developer console API",
    
      });
    };
    gapi.load("client:auth2", initClient);
  }, []);
  
  useEffect(() => {
    const captchaVerified = sessionStorage.getItem("captchaVerified");
    if (captchaVerified === "true") {
      setOpenRecaptcha(false);
    }
  }, []);
  const onSuccess = (res) => {
    signOut();
    googleLoginFun(res);
  };

  async function googleLoginFun(res) {
    var userLoginRes = [
      {
        userToken: "01",
        username: res.profileObj.email,
      },
    ];

    try {
      setIsLoading(true);
      var params = {
        email: res.profileObj.email,
        firstName: res.profileObj.givenName,
        lastName: res.profileObj.familyName,
        createdBy: "google",
        login: res.profileObj.email,
        activated: true,
      };
      const { data } = await apiCall("POST", ApiEndPoint.SIGNIN, params);
      if (data.code == 401) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="card">
                <div className="info__padding">
                  <h3>{`Blocked by admin.`}</h3>
                  <h3>{`Please contact admin.`}</h3>
                </div>
                <div className="button__group">
                  <button className="button btn-ok" onClick={onClose}>
                    Ok
                  </button>
                </div>
              </div>
            );
          },
        });
      } else if (data.id_token) {
        setIsLoading(false);
        await setDefaultHeader("Authorization", "Bearer" + data.token);
        var userLoginRes = [
          {
            userToken: "01",
            username: data.id_token,
          },
        ];
        await signInFun(userLoginRes);
        await localStorage.setItem("userData", data.id_token);
        await setDefaultHeader("Authorization", "Bearer" + data.id_token);

      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log("err: ", err);
    }
  }

  const openBrowserSettings = () => {
    if (detectDevice.isAndroid) {
      if (detectDevice.isChrome) {
        // Navigate Android users to Chrome settings
        window.location.href = `chrome://settings/content/siteDetails?site=${window.location.origin};`;
      } else if (detectDevice.isFirefox) {
        // Navigate Android users to Firefox settings
        window.location.href = "about:preferences#privacy";
      }
    } else if (detectDevice.isIOS) {
      if (detectDevice.isSafari) {
        // Navigate iOS users to Safari settings
        // window.location.href = 'safari://settings';
        askConfirmation("Enhance your browsing on ISO Safari by enabling popups and cross-site tracking . Simply open Safari on your iOS device, tap 'Settings,' find 'Safari,' and turn off 'Block Pop-ups' and 'Prevent Cross-Site Tracking.'")
        
      } else if (detectDevice.isChrome) {
        // Navigate iOS users to Chrome settings
        window.location.href = "googlechrome://settings";
      }
    }
  };
  const askConfirmation = async (message = null) => {
    const result = new Promise((resolve) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="card">
              <div className="info__padding">
                {!message ? (
                  <>
                    <h3>{`Popups Blocked`}</h3>
                    <h3>{`Popups are blocked. Please enable it from the settings.`}</h3>
                  </>
                ) : (
                  <h3>{message}</h3>
                )}
              </div>
              <div className="button__group">
                <button
                  className="button btn-ok"
                  onClick={() => {
                    onClose();
                    resolve(true); // User wants to proceed
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          );
        },
      });
    });
    if (!message && (await result)) {
      return openBrowserSettings(); // Pop-ups are blocked
    }
  };

  const onFailure = async (err) => {
    console.log(err);
    if (err.error == "popup_blocked_by_browser") {
      await askConfirmation();
    } else if (err.error == "popup_closed_by_user") {
      return;
    } else {
      await askConfirmation(err.error);
    }
  };

  const onLogoutSuccess = (res) => {
    // console.log("res:sssssssssonLogoutSuccess ", res);
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    isSignedIn: true,
    // cookiePolicy: "single_host_origin",
  });

  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
  });

  async function onChange(value) {
    if (value) {
      setOpenRecaptcha(false);
      sessionStorage.setItem("captchaVerified", "true");
      // if (googleLogin) {
        signIn();
      // }
    }
  }

  const recaptchaFun = () => {
    setOpenRecaptcha(true);
    setGoogleLogin(true);
  };

  return {
    googleLogin,
    openRecaptcha,
    onSuccess,
    onFailure,
    recaptchaFun,
    signIn,
    signOut,
    onChange,
    
  };
};

export default useGoogleLoginFlow;
