import React, { useState, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AllImages } from "../../../utils/constants";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import "./style.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { ChangeFAQsLangContext } from "../../Context/UserContext";

function FAQS() {
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(null);
  const [language, setLanguage] = useContext(ChangeFAQsLangContext);

  const handleChangeLanguage = (event) => {
    setLanguage(event.target.value);
  };

  const toogleQuestion = (value) => {
    if (activeIndex == value) {
      setActiveIndex(null);
    } else {
      setActiveIndex(value);
    }
  };

  const [isOpenSortBy, setIsOpenSortBy] = useState(false);

  const openModalSortBy = () => {
    setIsOpenSortBy(true);
  };

  const closeModalSortBy = () => {
    setIsOpenSortBy(false);
  };

  return (
    <section className="faqs">
      <div className="faqs-search-box-mobile-outer">
        <h1 class="mainTitle">FAQ's</h1>

        {/*----- Search Box Start -----*/}
        <div className="faqs-search-box-mobile">
          {/* <div className="faqs-search-wrap">
            <div className="search-input">
              <span className="search-icon">
                <img src={AllImages.Search} alt="Search" title="Search" />
              </span>
              <input
                className="f-poppins"
                type="text"
                placeholder="Search for Questions"
              />
            </div>
            <div className="searchIcon-outer">
              <img src={AllImages.SearchIcon} alt="Search" title="Search" className="searchIcon" />
            </div>
            <div className="filter-icon" onClick={() => openModalSortBy()}>
              <img src={AllImages.SearchToggle} alt="Search" title="Search" />
            </div>
          </div> */}
          <div className="language">
            <select
              name="language"
              id="language"
              value={language}
              onChange={(event) => handleChangeLanguage(event)}
            >
              <option value="english" key="0">
                English
              </option>
              <option value="hindi" key="1">
                हिंदी
              </option>
              <option value="gujarati" key="2">
                ગુજરાતી
              </option>
            </select>
          </div>
        </div>
        {/*----- Search Box Start -----*/}
      </div>
      {language === "english" && (
        <>
          <p className="heading">Login & Profile</p>
          <div className="accordian">
            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(0)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  How do I login?
                </p>
                {activeIndex == 0 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 0 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    You can login using your existing Gmail ID. Click on the
                    "Sign in with Google" button if you are on laptop/computer
                    OR the G button if you are on Mobile/tablet on top right of
                    the Satshut homepage and enter your Gmail ID and password.{" "}
                  </p>
                </div>
              )}
            </div>
            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(1)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  What should I do if I forgot my Gmail password?
                </p>
                {activeIndex == 1 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 1 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    Click on the "Sign in with Google" button on top right of
                    the Satshrut homepage and enter your Gmail ID and click
                    "Next". On the password page, click on "Forgot Password" and
                    follow the instructions on screen.
                  </p>
                </div>
              )}
            </div>
            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(2)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  What shall I do if i do not have a Gmail ID?
                </p>
                {activeIndex == 2 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 2 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    Please go to https://mail.google.com and click on "Create an
                    Account" to create a Gmail ID.{" "}
                  </p>
                </div>
              )}
            </div>
            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(3)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  Why do I need to fill all the fields on my profile page?
                </p>
                {activeIndex == 3 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 3 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    The information is required to help us reach you in the
                    future with latest updates about SatShrut.{" "}
                  </p>
                </div>
              )}
            </div>
          </div>

          <p className="heading">
            Homepage, Live Stream, Browse & Video Index Page
          </p>
          <div className="accordian">
            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(4)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  How can I sort videos on Video Index page?
                </p>
                {activeIndex == 4 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 4 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    Click on the top of any column to sort it. Eg. Click on the
                    Date column to sort the videos from oldest to newest OR
                    Click on the Video Title column to sort videos from A to Z.{" "}
                  </p>
                </div>
              )}
            </div>
          </div>

          <p className="heading" style={{ paddingTop: "20px" }}>
            Search & Filters
          </p>
          <div className="accordian">
            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(5)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  How do I search for a video?
                </p>
                {activeIndex == 5 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 5 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    On the homepage of SatShrut, you can type any topic in the
                    search bar present on the top right. Minimum requirement for
                    search is 2 letters. You can search for a specific topic
                    like Mokshmala OR Patrank 76 OR Pappaji's interview etc. to
                    search for videos related to that. You can only type in
                    English language in the search bar for now.{" "}
                  </p>
                </div>
              )}
            </div>

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(6)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  Why am I getting the error message "No Video Found" in Search?
                </p>
                {activeIndex == 6 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 6 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    This error message can occur in 2 conditions : 1. There is a
                    spelling difference between the topic that you are seaching
                    for and actual title of the video. Please check the spelling
                    and try again. For eg. Mokshmaala (incorrect) vs Mokshmala
                    (correct) OR Atma (incorrect) vs Aatma (correct) 2. The
                    video is currently not available on SatShrut
                  </p>
                </div>
              )}
            </div>

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(7)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  How can I use filters after getting search results?
                </p>
                {activeIndex == 7 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 7 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    After you search for a particular satsang, a list of
                    satsang(s) will show on the screen that matches search
                    criteria. You can further filter output list of satsang.
                    Please click on Filter button on top of the search result
                    output. Enter value of various parameters listed there and
                    click on search to further filter list of search results.
                  </p>
                </div>
              )}
            </div>

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(8)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  Why am I getting the error message "No Video Found" after
                  applying filters?
                </p>
                {activeIndex == 8 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 8 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    This is because the video you are looking for is currently
                    not available on SatShrut.
                  </p>
                </div>
              )}
            </div>

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(9)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  How can I share my search results with other Mumukshus?
                </p>
                {activeIndex == 9 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 9 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    You can share the link in the address bar of your web
                    browser to other Mumukshus. Eg.
                    https://satshrut.vitraagvigyaan.org/search/mokshmala?&videotype=video%20&language=gujarati{" "}
                  </p>
                </div>
              )}
            </div>

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(10)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  Why am I not able to type in Hindi or Gujurati language in the
                  Search bar?
                </p>
                {activeIndex == 10 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 10 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    For now you can only type in English language in the search
                    bar. In the future, we may have an option to search by
                    typing in Gujarati & Hindi language.
                  </p>
                </div>
              )}
            </div>
          </div>

          <p className="heading" style={{ paddingTop: "20px" }}>
            Favorites
          </p>
          <div className="accordian">
            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(11)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  How can I Favorite a video?
                </p>
                {activeIndex == 11 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 11 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    To mark a video as Favourite, click on the star icon in the
                    bottom right corner of the video. This will allow you to
                    save videos which you want to watch later. These videos can
                    be found in the "My Favourites" tab.
                  </p>
                </div>
              )}
            </div>

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(12)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  How can I remove a video from Favorites?
                </p>
                {activeIndex == 12 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 12 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    To remove a video from Favourites, click on the "My
                    Favourites" tab and click on the Star Icon in the bottom
                    right corner of the video.
                  </p>
                </div>
              )}
            </div>

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(13)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  Why can't I find my Favorite videos?
                </p>
                {activeIndex == 13 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 13 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    You cannot find your favourite videos because either you
                    have not logged in to Satshrut OR you have not marked any
                    videos as favourite.
                  </p>
                </div>
              )}
            </div>
          </div>

          <p className="heading" style={{ paddingTop: "20px" }}>
            General Troubleshooting
          </p>
          <div className="accordian">
            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(14)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  How can I clear my cache on my browser?
                </p>
                {activeIndex == 14 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 14 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    For Laptop/Computer users : Click on the settings button on
                    your browser, then find Clear Browsing Data in the settings
                    and you will find the option to Clear your Cache. For more
                    details, click - rb.gy/5xjyvv For mobile users : Click the
                    Tools menu (three dotted lines in the upper-right corner) on
                    your browser, then find Clear browsing data in the settings.
                    There you will fine the option to Clear your Cache. For more
                    details, click - rb.gy/n6fpuv.
                  </p>
                </div>
              )}
            </div>

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(15)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  Who can assist me on IT related issues?
                </p>
                {activeIndex == 15 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 15 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    You can email our IT support team on -
                    ss@vitraagvigyaan.org.
                  </p>
                </div>
              )}
            </div>
          </div>

          <p className="heading" style={{ paddingTop: "20px" }}>
            Payment
          </p>
          <div className="accordian">
            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(16)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  What is the cost of the Paid Subscription Plan of SatShrut?
                </p>
                {activeIndex == 16 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 16 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    The cost for annual Paid Subscription Plan for SatShrut is
                    INR 3000 (including GST). With the Paid Subscription Plan
                    you will be able to watch all videos in the SaShrut library.
                  </p>
                </div>
              )}
            </div>

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(17)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  How long is the Paid Subscription plan valid for?
                </p>
                {activeIndex == 17 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 17 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    The Paid Subscription Plan is valid for 12 months from the
                    date you buy it. For example, if you have buy the Paid
                    Subscription Plan on April 18, 2023 then the Plan will be
                    active till April 17, 2024
                  </p>
                </div>
              )}
            </div>
            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(18)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  What are the payment methods that I can use to buy the
                  Subscription Plan for SatShrut?
                </p>
                {activeIndex == 18 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 18 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    You can pay for the Subscription Plan using UPI, Online
                    Wallet (Paytm/PhonePe etc.), Credit card, Debit Card or Net
                    Banking.
                  </p>
                </div>
              )}
            </div>

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(19)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  Can I watch any videos without buying the SatShrut Paid
                  Subscription Plan?
                </p>
                {activeIndex == 19 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 19 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    Live Param Gyaan Sabha and some past Satsang videos will be
                    available for free. To access all the videos, you would need
                    to purchase the Paid Subscription Plan.
                  </p>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {language === "hindi" && (
        <>
          <p className="heading">लॉगिन और प्रोफाइल</p>
          <div className="accordian">
            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(0)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  मैं कैसे लॉग इन करूं?
                </p>
                {activeIndex == 0 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 0 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    आप अपनी मौजूदा Gmail ID का उपयोग करके लॉगिन कर सकते हैं। यदि
                    आप लैपटॉप/कंप्यूटर पर हैं तो होमपेज के दाईं ओर "Sign-in with
                    Google" बटन क्लिक करें और यदि आप मोबाइल/टैबलेट पर हैं तो
                    सत‌्‌श्रुत होमपेज के दाईं ओर "G" बटन पर क्लिक करें, और अपना
                    Gmail ID और पासवर्ड दर्ज करें।{" "}
                  </p>
                </div>
              )}
            </div>
            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(1)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  अगर मैं अपना Gmail पासवर्ड भूल जाऊं तो मुझे क्या करना चाहिए ?
                </p>
                {activeIndex == 1 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 1 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    यदि आप लैपटॉप/कंप्यूटर पर हैं तो सत‌्‌श्रुतहोमपेज के दाईं ओर
                    "Sign-in wih Google" पर, या यदि आप मोबाइल/टैबलेट पर हैं तो
                    सत‌्‌श्रुत होमपेज के दाईं ओर " G " बटन पर क्लिक करें और अपनी
                    Gmail ID दर्ज करें और "Next" बटन क्लिक करें। पासवर्ड पेज पर,
                    "Forgot Password" पर क्लिक करें और ऑन-स्क्रीन निर्देशों का
                    पालन करें।
                  </p>
                </div>
              )}
            </div>
            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(2)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  अगर मेरे पास Gmail ID नहीं है तो मैं क्या करूँ?
                </p>
                {activeIndex == 2 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 2 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    Gmail ID बनाने के लिए https://mail.google.com पर जाएं और
                    "Create an Account" पर क्लिक करें ।
                  </p>
                </div>
              )}
            </div>
            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(3)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  मुझे अपने प्रोफ़ाइल पेज़ पर सभी फ़ील्ड/जानकारी भरने की
                  आवश्यकता क्यों है?
                </p>
                {activeIndex == 3 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 3 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    जानकारी की आवश्यकता है की इससे भविष्य में हम सत‌्‌श्रुतके
                    बारे में नवीनतम अपडेट आप तक पहुंचा सकें ।{" "}
                  </p>
                </div>
              )}
            </div>
          </div>

          <p className="heading">
            होमपेज, लाइव स्ट्रीम, ब्राउज और वीडियो इंडेक्स पेज
          </p>
          <div className="accordian">
            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(4)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  मैं Video Index पेज़ पर वीडियो कैसे क्रमित/क्रमबद्ध करूँ?
                </p>
                {activeIndex == 4 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 4 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    किसी भी कॉलम को क्रमित करने के लिए उसके उपर क्लिक करें। उदा.
                    वीडियो को सबसे पुराने से नए में क्रमबद्ध करने के लिए Date
                    कॉलम पर क्लिक करें, या वीडियो को A से Z तक सॉर्ट करने के लिए
                    Video Title कॉलम पर क्लिक करें।
                  </p>
                </div>
              )}
            </div>
          </div>

          <p className="heading" style={{ paddingTop: "20px" }}>
            खोज और फ़िल्टर
          </p>
          <div className="accordian">
            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(5)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  मैं वीडियो कैसे खोजूं?
                </p>
                {activeIndex == 5 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 5 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    सत‌्‌श्रुतके होमपेज पर, आप ऊपर दाईं ओर मौजूद सर्च बार में
                    कोई भी विषय टाइप कर सकते हैं। खोज के लिए न्यूनतम आवश्यकता 2
                    वर्ण है। आप किसी विशिष्ट विषय पर वीडियो पा सकते हैं, जैसे
                    मोक्षमाला या पत्रांक 76 या पप्पाजी का इंटरव्यू आदि। अभी आप
                    सर्च बार में केवल अंग्रेजी भाषा में टाइप कर सकते हैं।{" "}
                  </p>
                </div>
              )}
            </div>

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(6)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  मुझे सर्च में "कोई वीडियो नहीं मिला" संदेश क्यों मिल रहा है?
                </p>
                {activeIndex == 6 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 6 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    यह त्रुटि संदेश 2 स्थितियों में हो सकता है: 1. आप जिस विषय
                    की तलाश कर रहे हैं और वीडियो के वास्तविक शीर्षक के बीच
                    स्पेलिंग की गलती है। कृपया स्पेलिंग जांचें और पुनः प्रयास
                    करें। उदा. Mokshmaala (गलत) vs Mokshmala (सही) OR Atma (गलत)
                    vs Aatma (सही)। 2. वीडियो वर्तमान में सत‌्‌श्रुतपर उपलब्ध
                    नहीं है।
                  </p>
                </div>
              )}
            </div>

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(7)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  सर्च परिणाम प्राप्त करने के बाद मैं फ़िल्टर का उपयोग कैसे कर
                  सकता हूँ?
                </p>
                {activeIndex == 7 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 7 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    आपके द्वारा किसी विशेष सत्संग की खोज करने के बाद, सत्संगों
                    की एक सूची स्क्रीन पर दिखाई देगी जो खोज मानदंड से मेल खाती
                    है। आप सत्संग की आउटपुट सूची को और फ़िल्टर कर सकते हैं।
                    कृपया खोज परिणाम आउटपुट के शीर्ष पर फ़िल्टर बटन पर क्लिक
                    करें। वहां सूचीबद्ध विभिन्न मापदंडों का मान दर्ज करें और खोज
                    परिणामों की आगे की सूची को फ़िल्टर करने के लिए खोज पर क्लिक
                    करें।
                  </p>
                </div>
              )}
            </div>

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(8)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  फ़िल्टर लगाने के बाद मुझे "कोई वीडियो नहीं मिला" संदेश क्यों
                  मिल रहा है?
                </p>
                {activeIndex == 8 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 8 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    ऐसा इसलिए है क्योंकि आप जिस वीडियो की तलाश कर रहे हैं वह
                    वर्तमान में सत‌्‌श्रुतपर उपलब्ध नहीं है।
                  </p>
                </div>
              )}
            </div>

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(9)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  मैं अपने सर्च परिणामों को अन्य मुमुक्षुओं के साथ कैसे शेयर कर
                  सकता हूँ?
                </p>
                {activeIndex == 9 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 9 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    आप अपने वेब ब्राउज़र के एड्रेस बार में लिंक को अन्य
                    मुमुक्षुओं के साथ साझा कर सकते हैं। उदाहरण के लिए
                    https://satshrut.vitraagvigyaan.org/search/mokshmala?&videotype=video%20&language=gujarati{" "}
                  </p>
                </div>
              )}
            </div>

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(10)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  मैं सर्च बार में हिंदी या गुजराती भाषा में टाइप क्यों नहीं
                  सकता हूँ?
                </p>
                {activeIndex == 10 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 10 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    अभी के लिए आप सर्च बार में केवल अंग्रेजी भाषा में टाइप कर
                    सकते हैं। भविष्य में हमारे पास गुजराती और हिंदी भाषा में
                    टाइप करके खोजने का विकल्प हो सकता है।
                  </p>
                </div>
              )}
            </div>
          </div>

          <p className="heading" style={{ paddingTop: "20px" }}>
            पसंदीदा (Favorites)
          </p>
          <div className="accordian">
            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(11)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  मैं किसी वीडियो को फ़ेवरिट कैसे बना सकता हूं?
                </p>
                {activeIndex == 11 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 11 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    किसी वीडियो को फ़ेवरिट करने के लिए, वीडियो के दाएं कोने में
                    स्थित स्टार आइकन पर क्लिक करें। इससे आप उन वीडियो को सेव कर
                    पाएंगे जिन्हें आप बाद में देख सकते हैं। ये वीडियो "My
                    Favourites" टैब में मिल सकते हैं।
                  </p>
                </div>
              )}
            </div>

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(12)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  मैं फ़ेवरिट में से वीडियो कैसे हटा सकता हूं?
                </p>
                {activeIndex == 12 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 12 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    किसी वीडियो को फ़ेवरिट से हटाने के लिए, "My Favourites" टैब
                    पर क्लिक करें और वीडियो के दाएं कोने में स्टार आइकन पर क्लिक
                    करें।
                  </p>
                </div>
              )}
            </div>

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(13)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  मुझे अपने फ़ेवरिट वीडियो क्यों नहीं मिल रहे हैं?
                </p>
                {activeIndex == 13 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 13 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    आप अपने फ़ेवरिट वीडियो नहीं ढूंढ सकते क्योंकि, या तो आपने
                    सत‌्‌श्रुतमें लॉग इन नहीं किया है या आपने किसी वीडियो को
                    फ़ेवरिट मार्क नहीं किया है।
                  </p>
                </div>
              )}
            </div>
          </div>

          <p className="heading" style={{ paddingTop: "20px" }}>
            सामान्य समस्या निवारण
          </p>
          <div className="accordian">
            {/* <div className='accordian-element'>
              <div onClick={() => toogleQuestion(14)} className='accordian-title'>
                <p className="question" style={{ textAlign: "left" }}>
                  How can I clear my cache on my browser?
                </p>
                {activeIndex == 14 ? (<RemoveCircleOutlineIcon />) : (<ControlPointIcon />)}
              </div>
              {activeIndex == 14 && (
                <div className='accordian-content'> <p className=''>For Laptop/Computer users : Click on the settings button on your browser, then find Clear Browsing Data in the settings and you will find the option to Clear your Cache. For more details, click - rb.gy/5xjyvv
                  For mobile users : Click the Tools menu (three dotted lines in the upper-right corner) on your browser, then find Clear browsing data in the settings. There you will fine the option to Clear your Cache. For more details, click - rb.gy/n6fpuv.</p>
                </div>
              )}
            </div> */}

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(15)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  IT से संबंधित समस्याओं पर मेरी सहायता कौन कर सकता है?
                </p>
                {activeIndex == 15 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 15 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    आप हमारी IT सपोर्ट टीम को - ss@vitraagvigyaan.org पर Email
                    कर सकते हैं
                  </p>
                </div>
              )}
            </div>
          </div>

          <p className="heading" style={{ paddingTop: "20px" }}>
            भुगतान
          </p>
          <div className="accordian">
            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(16)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  सतश्रुत के पेइड सब्सक्रिप्शन प्लान का मूल्य क्या है?
                </p>
                {activeIndex == 16 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 16 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    सतश्रुत के वार्षिक पेइड सब्सक्रिप्शन प्लान की कीमत 3000
                    (GSTसहित) है। पेइड सब्सक्रिप्शन प्लान के साथ, आप सतश्रुत की
                    लाइब्रेरी में सभी वीडियो देख सकते हैं।
                  </p>
                </div>
              )}
            </div>

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(17)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  पेड सब्सक्रिप्शन प्लान कितने समय के लिए मान्य है?
                </p>
                {activeIndex == 17 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 17 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    पेइड सब्सक्रिप्शन प्लान आपके खरीदने की तारीख से 12 महीनों के
                    लिए मान्य है। उदाहरण के लिए, अगर आपने 18 अप्रैल, 2023 को पेड
                    सब्सक्रिप्शन प्लान खरीदा है, तो यह प्लान 17 अप्रैल, 2024 तक
                    मान्य रहेगा।
                  </p>
                </div>
              )}
            </div>
            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(18)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  सतश्रुत के सब्सक्रिप्शन प्लान को खरीदने के लिए मैं पेमेंट के
                  किन-किन तरीकों का उपयोग कर सकता हूं?
                </p>
                {activeIndex == 18 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 18 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    सब्सक्रिप्शन प्लान खरीदने के लिए आप UPI, ऑनलाइन वॉलेट
                    (पेटीएम/फोनपे आदि), क्रेडिट कार्ड, डेबिट कार्ड या नेट
                    बैंकिंग का इस्तेमाल कर सकते हैं।
                  </p>
                </div>
              )}
            </div>

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(19)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  क्या मैं सतश्रुत पेड सब्सक्रिप्शन प्लान खरीदे बिना कोई वीडियो
                  देख सकता हूं?
                </p>
                {activeIndex == 19 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 19 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    लाइव परम ज्ञान सभा और कुछ पिछले वीडियो नि:शुल्क उपलब्ध
                    होंगे। सभी वीडियो देखने के लिए, आपको पेइड सब्सक्रिप्शन प्लान
                    खरीदना होगा।
                  </p>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {language === "gujarati" && (
        <>
          <p className="heading">લૉગિન અને પ્રોફાઇલ</p>
          <div className="accordian">
            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(0)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  હું કેવી રીતે લોગીન કરૂં ?
                </p>
                {activeIndex == 0 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 0 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    તમે તમારા હાલના Gmail ID ઉપયોગ કરીને લોગીન કરી શકો છો. જો
                    તમે લેપટોપ/કોમ્પ્યુટર પર હો તો સત્‌શ્રુત હોમપેજની જમણી બાજુએ
                    "Sign-in with Google" બટન પર ક્લિક કરો, અથવા જો તમે
                    મોબાઈલ/ટેબ્લેટ પર હો તો સત્‌શ્રુત હોમપેજની જમણી બાજુએ "G"
                    બટન પર ક્લિક કરી Gmail ID અને પાસવર્ડ દાખલ કરો.{" "}
                  </p>
                </div>
              )}
            </div>
            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(1)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  જો હું મારો Gmail પાસવર્ડ ભૂલી જાઉં તો મારે શું કરવું જોઈએ ?
                </p>
                {activeIndex == 1 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 1 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    જો તમે લેપટોપ/કોમ્પ્યુટર પર હોવ તો સત્‌શ્રુત હોમપેજની જમણી
                    બાજુએ "Sign-in with Google" અથવા જો તમે મોબાઈલ/ટેબ્લેટ પર
                    હોવ તો સત્‌શ્રુત હોમપેજની જમણી બાજુએ "G" બટન પર ક્લિક કરી
                    અને તમારું Gmail ID દાખલ કરી અને "Next" ઉપર ક્લિક કરો.
                    પાસવર્ડ પેજ પર, "Forgot Password" પર ક્લિક કરો અને સ્ક્રીન
                    પરની સૂચનાઓને અનુસરો.
                  </p>
                </div>
              )}
            </div>
            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(2)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  જો મારી પાસે Gmail ID ન હોય તો મારે શું કરવું જોઈએ?
                </p>
                {activeIndex == 2 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 2 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    https://mail.google.com પર જાઓ અને Gmail ID બનાવવા માટે
                    "Create an Account" પર ક્લિક કરો.
                  </p>
                </div>
              )}
            </div>
            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(3)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  મારે મારા પ્રોફાઈલ પેજ પરના તમામ ફીલ્ડ/માહિતી શા માટે ભરવાની
                  જરૂર છે?
                </p>
                {activeIndex == 3 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 3 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    આ માહિતી જરૂરી છે અમને ભવિષ્યમાં સત્‌શ્રુત વિશે નવી અપડેટ્સ
                    સાથે તમારા સુધી પહોંચવામાં માટે.
                  </p>
                </div>
              )}
            </div>
          </div>

          <p className="heading">
            હોમપેજ, લાઈવ સ્ટ્રીમ, બ્રાઉઝ અને વિડિયો ઈન્ડેક્સ પેજ
          </p>
          <div className="accordian">
            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(4)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  હું Video Index પેજ પર વિડિયો કેવી રીતે સૉર્ટ/ક્રમમાં કરી
                  શકું?
                </p>
                {activeIndex == 4 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 4 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    તેને સૉર્ટ કરવા માટે કોઈપણ કૉલમની ઉપર ક્લિક કરો. દા.ત.
                    વીડિયોને સૌથી જૂનાથી નવામાં સૉર્ટ કરવા માટે Date કૉલમ પર
                    ક્લિક કરો અથવા A થી Z સુધીના વીડિયોને સૉર્ટ કરવા માટે Video
                    Title કૉલમ પર ક્લિક કરો.
                  </p>
                </div>
              )}
            </div>
          </div>

          <p className="heading" style={{ paddingTop: "20px" }}>
            શોધ અને ફિલ્ટર્સ
          </p>
          <div className="accordian">
            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(5)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  હું વિડિઓ કેવી રીતે શોધી શકું?
                </p>
                {activeIndex == 5 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 5 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    સત્‌શ્રુત ના હોમપેજ પર, તમે ઉપર જમણી બાજુએ હાજર સર્ચ બારમાં
                    કોઈપણ વિષય ટાઈપ કરી શકો છો. શોધ માટે ન્યૂનતમ આવશ્યકતા 2
                    અક્ષરોની છે. તમે વિશિષ્ટ વિષય, જેમ કે મોક્ષમાલા અથવા પત્રાંક
                    76 અથવા પપ્પાજીનો ઈન્ટરવ્યુ વગેરે, વિડિયોઝ શોધી શકો છો. તમે
                    હમણાં માટે સર્ચ બારમાં ફક્ત અંગ્રેજી ભાષામાં જ ટાઇપ કરી શકો
                    છો.
                  </p>
                </div>
              )}
            </div>

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(6)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  મને સર્ચ "કોઈ વિડિયો મળ્યો નથી" એવો સંદેશ કેમ મળી રહ્યો છે?
                </p>
                {activeIndex == 6 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 6 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    આ ભૂલ સંદેશ 2 પરિસ્થિતિઓમાં આવી શકે છે: 1. તમે જે વિષય શોધી
                    રહ્યા છો અને વિડિયોના વાસ્તવિક શીર્ષક વચ્ચે સ્પેલિંગની ભૂલ
                    છે. કૃપા કરીને સ્પેલિંગ તપાસો અને ફરી પ્રયાસ કરો. દા.ત.
                    Mokshmaala (ખોટું) vs Mokshmala (સાચું) OR Atma (ખોટું) vs
                    Aatma (સાચું). 2. વિડિયો હાલમાં સત્‌શ્રુત પર ઉપલબ્ધ નથી.
                  </p>
                </div>
              )}
            </div>

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(7)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  સર્ચ પરિણામો મેળવ્યા પછી હું ફિલ્ટર્સનો ઉપયોગ કેવી રીતે કરી
                  શકું?
                </p>
                {activeIndex == 7 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 7 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    તમે કોઈ ચોક્કસ સત્સંગ શોધો પછી, સત્સંગ(ઓ)ની યાદી સ્ક્રીન પર
                    દેખાશે જે શોધ માપદંડ સાથે મેળ ખાતી હોય. તમે સત્સંગની આઉટપુટ
                    યાદીને વધુ ફિલ્ટર કરી શકો છો. કૃપા કરીને શોધ પરિણામ આઉટપુટની
                    ટોચ પર ફિલ્ટર બટન પર ક્લિક કરો. ત્યાં સૂચિબદ્ધ વિવિધ
                    પરિમાણોનું મૂલ્ય દાખલ કરો અને શોધ પરિણામોની વધુ ફિલ્ટર સૂચિ
                    માટે શોધ પર ક્લિક કરો.
                  </p>
                </div>
              )}
            </div>

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(8)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  ફિલ્ટર્સ લાગુ કર્યા પછી મને "કોઈ વિડિયો મળ્યો નથી" એવો સંદેશ
                  કેમ મળી રહ્યો છે?
                </p>
                {activeIndex == 8 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 8 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    કારણ કે તમે જે વિડિયો શોધી રહ્યા છો તે હાલમાં સત્‌શ્રુત પર
                    ઉપલબ્ધ નથી.
                  </p>
                </div>
              )}
            </div>

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(9)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  હું મારા સર્ચ પરિણામો અન્ય મુમુક્ષુઓ સાથે કેવી રીતે શેર કરી
                  શકું?
                </p>
                {activeIndex == 9 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 9 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    તમે તમારા વેબ બ્રાઉઝરના એડ્રેસ બારમાંની લિંક અન્ય
                    મુમુક્ષુઓને શેર કરી શકો છો. દા.ત
                    https://satshrut.vitraagvigyaan.org/search/mokshmala?&videotype=video%20&language=gujarati
                  </p>
                </div>
              )}
            </div>

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(10)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  શા માટે હું સર્ચ બારમાં હિન્દી કે ગુજરાતી ભાષામાં ટાઈપ કરી
                  શકતો નથી?
                </p>
                {activeIndex == 10 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 10 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    અત્યારે તમે સર્ચ બારમાં માત્ર અંગ્રેજી ભાષામાં જ ટાઈપ કરી
                    શકો છો. ભવિષ્યમાં આપણી પાસે ગુજરાતી અને હિન્દી ભાષામાં ટાઈપ
                    કરીને સર્ચ કરવાનો વિકલ્પ હોઈ શકે છે.
                  </p>
                </div>
              )}
            </div>
          </div>

          <p className="heading" style={{ paddingTop: "20px" }}>
            મનપસંદ (Favorites)
          </p>
          <div className="accordian">
            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(11)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  હું વિડિઓને ફેવરિટ કેવી રીતે કરી શકું?
                </p>
                {activeIndex == 11 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 11 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    વિડિયોને ફેવરિટ કરવા માટે, વિડિયોના જમણા ખૂણે સ્ટાર આઇકન પર
                    ક્લિક કરો. આ રીતે તમે વિડિયો સેવ કરી શકો છો, જે તમે પછીથી
                    જોઈ શકો છો. આ વિડિઓઝ "My Favourites" ટૅબમાં મળી શકે છે.
                  </p>
                </div>
              )}
            </div>

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(12)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  હું ફેવરિટમાંથી વિડિઓ કેવી રીતે હટાવી શકું છું?
                </p>
                {activeIndex == 12 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 12 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    ફેવરિટમાંથી વિડિઓ હટાવા માટે, "My Favourites" ટેબ પર ક્લિક
                    કરો પછી વિડિઓના જમણા ખૂણે સ્ટાર આઇકોન પર ક્લિક કરો.
                  </p>
                </div>
              )}
            </div>

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(13)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  હું મારા ફેવરિટ વિડિઓઝ કેમ શોધી શકતો નથી?
                </p>
                {activeIndex == 13 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 13 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    તમે તમારા ફેવરિટ વિડિઓઝ શોધી શકતા નથી કારણકે, કાં તો તમે
                    સત્‌શ્રુતમાં લૉગ ઇન નથી કર્યું અથવા તમે કોઈપણ વિડિઓને ફેવરિટ
                    માર્ક કર્યા નથી.
                  </p>
                </div>
              )}
            </div>
          </div>

          <p className="heading" style={{ paddingTop: "20px" }}>
            સામાન્ય મુશ્કેલીનિવારણ
          </p>
          <div className="accordian">
            {/* <div className='accordian-element'>
              <div onClick={() => toogleQuestion(14)} className='accordian-title'>
                <p className="question" style={{ textAlign: "left" }}>
                  How can I clear my cache on my browser?
                </p>
                {activeIndex == 14 ? (<RemoveCircleOutlineIcon />) : (<ControlPointIcon />)}
              </div>
              {activeIndex == 14 && (
                <div className='accordian-content'> <p className=''>For Laptop/Computer users : Click on the settings button on your browser, then find Clear Browsing Data in the settings and you will find the option to Clear your Cache. For more details, click - rb.gy/5xjyvv
                  For mobile users : Click the Tools menu (three dotted lines in the upper-right corner) on your browser, then find Clear browsing data in the settings. There you will fine the option to Clear your Cache. For more details, click - rb.gy/n6fpuv.</p>
                </div>
              )}
            </div> */}

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(15)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  IT સંબંધિત સમસ્યામાં મને કોણ મદદ કરી શકે?
                </p>
                {activeIndex == 15 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 15 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    તમે અમારી IT સપોર્ટ ટીમને - ss@vitraagvigyaan.org પર Email
                    કરી શકો છો
                  </p>
                </div>
              )}
            </div>
          </div>

          <p className="heading" style={{ paddingTop: "20px" }}>
            ચુકવણી
          </p>
          <div className="accordian">
            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(16)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  સત્‌શ્રુતના પેઇડ સબ્સ્ક્રિપ્શન પ્લાનનું મૂલ્ય શું છે?
                </p>
                {activeIndex == 16 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 16 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    સત્‌શ્રુતના વાર્ષિક પેઇડ સબસ્ક્રિપ્શન પ્લાનની કિંમત INR 3000
                    (GST સહિત) છે. પેઇડ સબ્સ્ક્રિપ્શન પ્લાન સાથે તમે સત્‌શ્રુતની
                    લાઇબ્રેરીમાં તમામ વિડિયો જોઈ શકશો.
                  </p>
                </div>
              )}
            </div>

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(17)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  પેઇડ સબ્સ્ક્રિપ્શન પ્લાન કેટલા સમય માટે માન્ય છે?
                </p>
                {activeIndex == 17 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 17 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    પેઇડ સબ્સ્ક્રિપ્શન પ્લાન તમે ખરીદો તે તારીખથી 12 મહિના માટે
                    માન્ય છે. ઉદાહરણ તરીકે જો તમે 18 એપ્રિલ, 2023 ના રોજ પેઇડ
                    સબ્સ્ક્રિપ્શન પ્લાન ખરીદ્યો હોય તો પ્લાન 17 એપ્રિલ, 2024
                    સુધી માન્ય રહેશે
                  </p>
                </div>
              )}
            </div>
            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(18)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  સત્‌શ્રુતનું સબ્સ્ક્રિપ્શન પ્લાન ખરીદવા માટે હું પેમેન્ટ ના
                  ક્યા ક્યા તરીકાઓ નો ઉપયોગ કરી શકુ છું?
                </p>
                {activeIndex == 18 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 18 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    તમે સબસ્ક્રિપ્શન પ્લાન ખરીદવા માટે UPI, ઓનલાઈન વોલેટ
                    (Paytm/PhonePe વગેરે), ક્રેડિટ કાર્ડ, ડેબિટ કાર્ડ અથવા નેટ
                    બેંકિંગનો ઉપયોગ કરી શકો છો.
                  </p>
                </div>
              )}
            </div>

            <div className="accordian-element">
              <div
                onClick={() => toogleQuestion(19)}
                className="accordian-title"
              >
                <p className="question" style={{ textAlign: "left" }}>
                  શું હું સત્‌શ્રુત પેઇડ સબ્સ્ક્રિપ્શન પ્લાન ખરીદ્યા વિના કોઈપણ
                  વિડિયો જોઈ શકું?
                </p>
                {activeIndex == 19 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <ControlPointIcon />
                )}
              </div>
              {activeIndex == 19 && (
                <div className="accordian-content">
                  {" "}
                  <p className="">
                    લાઈવ પરમ જ્ઞાન સભા અને ભૂતકાળના અમુક વિડીયો નિઃશુલ્ક ઉપલબ્ધ
                    રહેશે. બધા વિડિયોઝ જોવા માટે, તમારે પેઇડ સબ્સ્ક્રિપ્શન પ્લાન
                    ખરીદવાની જરૂર પડશે.
                  </p>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {isOpenSortBy && (
        <Dialog
          open={openModalSortBy}
          className="sortBy"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent
            style={{
              overflowY: "hidden",
              overflowX: "hidden",
            }}
          >
            <div className="qu-history-modal-msg-content">
              <p className="qu-history-modal-msg-text">{"Sort by"}</p>
              <span
                className="qu-history-modal-close-button"
                onClick={() => closeModalSortBy()}
              >
                &times;
              </span>
            </div>
            <ul>
              <li>
                <label class="customRadio">
                  Newly added
                  <input type="radio" checked="checked" name="radio" />
                  <span class="checkmark"></span>
                </label>
              </li>
              <li>
                <label class="customRadio">
                  Previously added
                  <input type="radio" name="radio" />
                  <span class="checkmark"></span>
                </label>
              </li>
              <li>
                <label class="customRadio">
                  Popular videos
                  <input type="radio" name="radio" />
                  <span class="checkmark"></span>
                </label>
              </li>
              <li>
                <label class="customRadio">
                  Newly published
                  <input type="radio" name="radio" />
                  <span class="checkmark"></span>
                </label>
              </li>
              <li>
                <label class="customRadio">
                  Previously published
                  <input type="radio" name="radio" />
                  <span class="checkmark"></span>
                </label>
              </li>
            </ul>
          </DialogContent>
        </Dialog>
      )}
    </section>
  );
}
export default FAQS;
