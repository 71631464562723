import { useRef } from "react";
import { io } from "socket.io-client";

const SERVER = process.env.REACT_APP_URL
// export const socket = io(SERVER);
export const socket = io(SERVER, {
  transports: ["websocket"], // forces websocket only
});
socket.on("connect", () => {
  // console.log(`Socket Connected`, socket);
});

socket.on('connect_error', (err) => {
  if (err.type === 'TransportError') {
    console.error('connection broke =====>>>>', err);
  }
});
