import React, { useState, useRef, useLayoutEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
import ApiEndPoint from "../../../utils/apiEndPoints";
import loader from "../../../assets/images/logo.png";
import Google from "../../../assets/images/google.png";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactPlayer from "react-player/lazy";
import { FaBackward, FaForward } from "react-icons/fa";

import { useEffect, useContext } from "react";
import {
  VideoListContext,
  PageReloadContext,
  UserloggedContext,
  ShowCreatePlayListContext,
} from "../../Context/UserContext";
import { setDefaultHeader, apiCall } from "../../../utils/httpClient";
import { AuthContext } from "../../Context/context";
import {
  GoogleLogin,
  useGoogleLogout,
  useGoogleLogin,
} from "react-google-login";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./style.css";
import Button from "@material-ui/core/Button";
import {
  AllImages,
  googleClientId,
  GLOBAL_URL,
  VideoPlaylistShareUrl,
  RemoveDuplicates,
  getImageNameFromResolution,
  emojiRegex,
  formatTimeForTimeStamp,
} from "../../../utils/constants";
import { useTheme, useMediaQuery } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import NoDataAvailable from "../../common/NoDataFound/NoDataAvailable";
import { toast } from "react-toastify";
import { RWebShare } from "react-web-share";
import useGoogleLoginFlow from "components/common/useGoogleLoginFlow";
import ReCAPTCHA from "react-google-recaptcha";
const clientId = googleClientId;
const TEST_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
function PlayListPlayPage() {
  const location = useLocation();
  // const { item } = location.state;
  const { vidId } = useParams();
  const { documentData, type } = location.state || {};
  const [auth, setAuth] = useContext(UserloggedContext);
  const [showLoader, setShowLoader] = useState(true);
  const [videoList, setVideoList] = useContext(VideoListContext);
  const [isLoading, setIsLoading] = useState(false);
  const { signInFun, signOutFun } = React.useContext(AuthContext);
  const [showSaveInfo, setShowSaveInfo] = useState(false);
  const [isValidNote, setIsValidNote] = useState(false);
  const [createNoteBtnLding, setNoteBtnLding] = useState(false);

  const {
    openRecaptcha,

    recaptchaFun,

    onChange,
  } = useGoogleLoginFlow();
  const [item, setItem] = useState(null);
  const [playList, setPlayList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const dialogContentRef = useRef(null);
  const [scrollHashMap, setScrollHashMap] = useState(true);
  const [pageNo, setPageNo] = useState(0);
  const [userPlayList, setUserPlayList] = useState([]);
  const [scrollLast, setScrollLast] = useState(true);
  const [playlistName, setPlaylistName] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [BtnLoading, setBtnLoading] = useState(false);

  const [noteTitle, setNoteTitle] = useState("Shabdarth");
  const [note, setNote] = useState(null);
  const [startTime, setStartTime] = useState("00:00:00");
  const [endTime, setEndTime] = useState(null);
  const playerRef = React.useRef(null);

  const [
    previouslySelectedUserPlaylistArray,
    setPreviouslySelectedUserPlaylistArray,
  ] = useState([]);
  const [
    currentlySelectedUserPlaylistArray,
    setCurrentlylySelectedUserPlaylistArray,
  ] = useState([]);
  const [unselectedPlaylistArray, setUnselectedPlaylistArray] = useState([]);
  const [showAdminSuggestions, setShowAdminSuggestions] = useState(false);
  const [showUserSuggestions, setShowUserSuggestions] = useState(false);

  const [showCreatePlayListModel, setShowCreatePlayListModel] = useContext(
    ShowCreatePlayListContext
  );

  const [documentDataAudio, setDocumentDataAudio] = useState([]);
  const [documentDataPad, setDocumentDataPad] = useState([]);
  const [documentDataPatra, setDocumentDataPatra] = useState([]);
  const [documentDataEbook, setDocumentDataEbook] = useState([]);
  const [documentDataTranscript, setDocumentDataTranscript] = useState([]);
  const openModal = () => {
    if (auth) {
      setIsOpen(true);
    } else {
      takeOtherSubcription();
    }
  };
  const [currentlyPlaylingVidId, setCurrentlyPlaylingVidId] = useState(vidId);

  const closeModal = () => {
    setPreviouslySelectedUserPlaylistArray([]);
    setCurrentlylySelectedUserPlaylistArray([]);
    setUnselectedPlaylistArray([]);
    setIsOpen(false);
  };

  useEffect(() => {
    if (type == "admin") {
      fetchAdminPlaylist();
    } else if (type == "shared") {
      fetchSharedPlaylist();
    } else {
      fetchPlayListVideos();
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      fetchUserPlaylistData();
      getSelectedPlaylistData();
    }
  }, [isOpen]);
  const fetchUserPlaylistData = () => {
    userPlayListFun(1);
  };
  // useEffect(() => {
  //   fetchVideoAdded(vidId);
  // }, []);
  async function getSelectedPlaylistData() {
    if (auth) {
      try {
        setIsLoading(true);

        const { data } = await apiCall(
          "GET",
          `${ApiEndPoint.PLAYLISTDATAASSOCIATEDWITHVIDEO}/${currentlyPlaylingVidId}`
        );
        if (data.length > 0) {
          setIsLoading(false);

          setPreviouslySelectedUserPlaylistArray(data);
        } else {
          setPreviouslySelectedUserPlaylistArray([]);
        }
      } catch (err) {
        setIsLoading(false);
      }
    }
  }

  //Admin  playlist videos
  const fetchAdminPlaylist = async () => {
    try {
      const Auth = await localStorage.getItem("userToken");
      const params = {
        searchValue: null,
        playListId: vidId,
        contentType: "VIDEO", // VIDEO, PAD,PATRA
        pageNo: 0,
        pageSize: 50,
      };
      if (Auth == "01") {
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.PLAYLISTVIDEOLOGIN}`,
          params
        );
        if (data) {
          setPlayList(data);
          setTimeout(() => {
            setShowLoader(false);
            setShowAdminSuggestions(true);
          }, 2000);
        } else {
          setTimeout(() => {
            setShowLoader(false);
          }, 2000);
        }
      } else {
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.PLAYLISTVIDEONONLOGIN}`,
          params
        );
        if (data) {
          setPlayList(data);
          setTimeout(() => {
            setShowLoader(false);
          }, 2000);
        } else {
          setTimeout(() => {
            setShowLoader(false);
          }, 2000);
        }
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  const handlePause = async (playerRef) => {
    if (playerRef.current) {
      const stopTime = playerRef.current.getCurrentTime();
      if (auth) {
        try {
          const params = {
            id: currentlyPlaylingVidId,
            lastWatchTime: stopTime,
          };

          const { data } = await apiCall(
            "POST",
            `${ApiEndPoint.SAVEWATCHVIDEOTIME}`,
            params
          );

          if (data.length > 0) {
            return true;
          } else {
            return true;
          }
        } catch (err) {
          console.error("API request failed:", err);
          return true; // Handle the error and return false
        }
      }
    }
    return true;
  };

  useLayoutEffect(() => {
    const handleBeforeUnload = async (event) => {
      event.preventDefault(); // Ensure the event doesn't navigate away immediately

      try {
        const result = await handlePause(playerRef);

        if (result) {
          // Allow navigation when the API call is successful
          window.removeEventListener("beforeunload", handleBeforeUnload);
          window.location.assign(event.currentTarget.href);
        } else {
          // Handle errors here, such as showing a warning to the user
          event.returnValue = "Are you sure you want to leave?";
        }
      } catch (err) {
        console.error("Error handling beforeunload:", err);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [item]);
  useLayoutEffect(() => {
    return async () => {
      await handlePause(playerRef);
    };
  }, [location]);

  const fetchVideoAdded = async (id) => {
    setCurrentlyPlaylingVidId(id);
    window.scrollTo(0, 0);
    try {
      const Auth = await localStorage.getItem("userToken");
      if (Auth == "01") {
        const { data } = await apiCall(
          "GET",
          ApiEndPoint.PLAYVIDFORLOGINUSERS + id
        );
        if (data) {
          setItem(data);
          // setDocumentData([...data.audioContents, ...data.ebookContents, ...data.padContents, ...data.patraContents, ...data.transcriptContents])
          setDocumentDataAudio(data?.audioContents);
          setDocumentDataPad(data?.padContents);
          setDocumentDataPatra(data?.patraContents);
          setDocumentDataEbook(data?.ebookContents);
          setDocumentDataTranscript(data?.transcriptContents);
          setTimeout(() => {
            setShowLoader(false);
          }, 2000);
        } else {
          setTimeout(() => {
            setShowLoader(false);
          }, 2000);
        }
      } else {
        const { data } = await apiCall(
          "GET",
          ApiEndPoint.PLAYVIDFORNONLOGINUSERS + vidId
        );
        if (data) {
          setItem(data);
          setTimeout(() => {
            setShowLoader(false);
          }, 2000);
        } else {
          setTimeout(() => {
            setShowLoader(false);
          }, 2000);
        }
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  //user Playlist videos
  async function fetchPlayListVideos() {
    setIsLoading(true);
    try {
      if (auth) {
        const params = {
          searchValue: null,
          playListId: vidId,
          pageNo: 0,
          pageSize: 50,
          contentType: "VIDEO",
        };
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.USERPLAYLISTCONTENTS}`,
          params
        );
        if (data.length > 0) {
          if (data) {
            setPlayList(data);
            setShowUserSuggestions(true);
            setTimeout(() => {
              setShowLoader(false);
            }, 2000);
          } else {
            setTimeout(() => {
              setShowLoader(false);
            }, 2000);
          }
        }
      } else {
      }
    } catch (err) {
      setIsLoading(false);

      console.log("err: ", err);
    }
  }
  async function fetchSharedPlaylist() {
    setIsLoading(true);
    try {
      if (auth) {
        // console.log("sachin patidar111");

        const params = {
          searchValue: null,
          playListId: vidId,
          pageNo: 0,
          pageSize: 50,
          contentType: "VIDEO",
        };
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.SHAREDPLAYLISTCONTENTS}`,
          params
        );
        // console.log(data);
        if (data.length > 0) {
          if (data) {
            setPlayList(data);
            setShowUserSuggestions(true);
            setTimeout(() => {
              setShowLoader(false);
            }, 2000);
          } else {
            setTimeout(() => {
              setShowLoader(false);
            }, 2000);
          }
        }
      } else {
      }
    } catch (err) {
      setIsLoading(false);

      console.log("err: ", err);
    }
  }

  function takeSubcription(params) {
    params?.subscriptionName
      ? confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="card">
                <div className="info__padding">
                  <h3>
                    {`Please Subscribe to ${params?.subscriptionName} plan to play this video. `}
                  </h3>
                  <h3>{!auth ? "Sign In To Subscribe..." : ""}</h3>
                </div>
                {!auth ? (
                  <>
                    <div className="button__group">
                      <Button
                        onClick={() => {
                          onChange({ true: true });
                          onClose();
                        }}
                        variant="contained"
                        className="signBtnHome"
                      >
                        <img
                          style={{ height: "23px", width: "23px" }}
                          className="g-icon"
                          src={Google}
                        ></img>
                        Sign-in
                      </Button>
                      <button className="no-btn" onClick={onClose}>
                        Cancel
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="button__group">
                      <Button
                        onClick={() => {
                          navigate("/subscription");
                          onClose();
                        }}
                        variant="contained"
                        className="subscriptionBtn"
                        // className="signBtnHome"
                      >
                        {/* <img className="g-icon" src={Google}></img> */}
                        Subscribe
                      </Button>
                      <button className="no-btn" onClick={onClose}>
                        Cancel
                      </button>
                    </div>
                  </>
                )}
              </div>
            );
          },
        })
      : confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="card">
                <div className="info__padding">
                  <h3>{`This Video is not yet available`}</h3>
                </div>
                <div className="button__group">
                  <button className="button btn-ok" onClick={onClose}>
                    Ok
                  </button>
                </div>
              </div>
            );
          },
        });
  }
  function takeOtherSubcription() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card">
            <div className="info__padding">
              <h3>{`Please sign in to use this feature. `}</h3>
              {/* <h3>{!auth ? "Sign In" : ""}</h3> */}
            </div>
            {!auth ? (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      onChange({ true: true });
                      onClose();
                    }}
                    variant="contained"
                    className="signBtnHome"
                  >
                    <img
                      style={{ height: "23px", width: "23px" }}
                      className="g-icon"
                      src={Google}
                    ></img>
                    Sign-in
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      navigate("/subscriptionPlanList");
                      onClose();
                    }}
                    variant="contained"
                    className="subscriptionBtn"
                    // className="signBtnHome"
                  >
                    {/* <img className="g-icon" src={Google}></img> */}
                    Subscribe
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        );
      },
    });
  }
  const addFavorite = async (item, index) => {
    if (auth) {
      playList[index].myFavourite = item.myFavourite ? false : true;
      try {
        const { data } = await apiCall("POST", `/my-favourites/${item.id}`);
        if (data) {
          setRefresh(!refresh);
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
      }
    } else {
      takeOtherSubcription();
    }
  };

  const videoWatchLater = async (item, index) => {
    if (auth) {
      playList[index].watchLater = item.watchLater ? false : true;
      try {
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.VIDEOWATCHLATER}/${item.id}`
        );
        if (data) {
          setRefresh(!refresh);
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
      }
    } else {
      // setGoogleLoginShow(true)
    }
  };

  const RemoveWatchLaterVideo = async (item, index) => {
    if (auth) {
      playList[index].watchLater = item.watchLater ? false : true;
      try {
        const { data } = await apiCall(
          "DELETE",
          `${ApiEndPoint.WATCHLATEDREMOVE}/${item.id}`
        );
        if (data) {
          setRefresh(!refresh);
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
      }
    } else {
      // setGoogleLoginShow(true)
    }
  };

  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.up("767"));

  useEffect(() => {
    // Find the index of the first video with a URL
    const startIndex = playList.findIndex((item) => item.videoUrl);

    if (startIndex !== -1) {
      setCurrentTrackIndex(startIndex);
      fetchVideoAdded(playList[startIndex].id);
    }
  }, [playList]);

  const handleEnded = () => {
    // Find the index of the next video with a URL
    const nextIndex = playList.findIndex(
      (item, index) => index > currentTrackIndex && item.videoUrl
    );

    if (nextIndex !== -1) {
      setCurrentTrackIndex(nextIndex);
    }
  };

  const suggestedVideo = async (index, id) => {
    window.scrollTo(0, 0);
    const isSaved = await handlePause(playerRef);
    if (isSaved) {
      setCurrentTrackIndex(index);
      fetchVideoAdded(id);
    }
  };

  const contentTypeMappings = {
    EBOOK: "E-Book",
    AUDIO: "Audio",
    PAD: "Pad",
    PATRA: "Patra",
    TRANSCRIPT: "Transcript",
    // Add more mappings if needed
  };

  function getFileExtension(url) {
    const parts = url.split(".");
    return parts[parts.length - 1];
  }
  const [fileDownloadLoader, setFileDownloadLoader] = useState(null);
  const handleDownloadClick = async (contentUrl, index) => {
    try {
      setFileDownloadLoader(index);
      let fileUrl = "";
      let fileExtension = getFileExtension(contentUrl);

      switch (fileExtension) {
        case "epub":
        case "pdf":
        case "mp3":
          fileUrl = contentUrl;
          break;
        default:
          console.error("Invalid file type:", fileExtension);
          return;
      }

      const response = await fetch(fileUrl);
      if (!response.ok) {
        setFileDownloadLoader(null);
        throw new Error(`Failed to download file (HTTP ${response.status})`);
      }
      let fileName = contentUrl?.substring(contentUrl.lastIndexOf("/") + 1);

      fileName = fileName?.replace(
        /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/,
        ""
      );

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      setFileDownloadLoader(null);
      // Display a success toast message
      toast.success("File downloaded successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error("Error downloading the file:", error);

      // Display an error toast message
      toast.error("Error downloading the file. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleReadOrPlayClick = (contentUrl, contentType) => {
    const newTabUrl = `/fileViewer?contentUrl=${encodeURIComponent(
      contentUrl
    )}`;
    window.open(newTabUrl, "_blank");
  };

  const handlePlaylistScroll = () => {
    const { current } = dialogContentRef;
    if (
      current &&
      current.scrollTop + current.clientHeight >= current.scrollHeight &&
      scrollHashMap
    ) {
      fetchMoreUserPlaylistData();
    }
  };

  const fetchMoreUserPlaylistData = () => {
    userPlayListFun();
  };

  async function addVideoAgainstPlaylist() {
    if (auth) {
      if (
        currentlySelectedUserPlaylistArray.length > 0 ||
        unselectedPlaylistArray.length > 0
      ) {
        try {
          const params = {
            videoId: currentlyPlaylingVidId,
            selected: currentlySelectedUserPlaylistArray,
            unSelected: unselectedPlaylistArray,
          };
          setIsLoading(true);

          const { status } = await apiCall(
            "POST",
            `${ApiEndPoint.ADDVIDEOAGAINSTMULTIPLEPLAYLIST}`,
            params
          );
          if (status === 200) {
            setIsLoading(false);
            toast.success("Added successfully");
            closeModal();
          } else {
            setIsLoading(false);
            toast.error("Something went wrong , try again");
          }
        } catch (err) {
          setIsLoading(false);
        }
      }
    }
  }

  async function userPlayListFun(fetchPage = 0) {
    if (auth) {
      try {
        const params = {
          searchValue: null,
          sortColumn: "NEWLY_ADDED",
          pageNo: fetchPage ? fetchPage - 1 : pageNo,
          pageSize: 8,
        };
        setIsLoading(true);

        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.USERPLAYLIST}`,
          params
        );
        if (data.length > 0) {
          setIsLoading(false);
          // setUserPlayList(fetchPage?data:prev=>[...prev,...data]);
          setUserPlayList(
            RemoveDuplicates(fetchPage ? data : userPlayList.concat(data))
          );

          setPageNo(fetchPage ? fetchPage : pageNo + 1);
          setScrollHashMap(true);
          setScrollLast(true);
        } else {
          setIsLoading(false);
          setScrollLast(false);
          fetchPage && setUserPlayList([]);
          setPageNo(0);
          setScrollHashMap(false);
        }
      } catch (err) {
        setIsLoading(false);
        setScrollLast(false);
      }
    }
  }
  const createPlaylist = () => {
    setIsOpen(false);
    navigate("/createdPlaylist");
    setShowCreatePlayListModel(true);
  };
  const openCreatedPlayListModal = () => {
    setShowCreatePlayListModel(true);
  };

  const closeCreatedPlayListModal = () => {
    setShowCreatePlayListModel(false);
  };
  const validateInput = (text, isNote = false) => {
    const emojiRegexs = emojiRegex; // your emoji regex here;
    if (/^\s+/.test(text)) {
      return false;
    } else if (emojiRegexs.test(text)) {
      return false;
    } else if (!isNote && text.length > 20) {
      return false;
    } else return true;
  };

  const validateNoteForm = () => {
    if (note == "" || noteTitle == "") {
      return false;
    }
    return true;
  };
  const handleNoteChange = (event) => {
    const inputValue = event.target.value;

    if (validateInput(inputValue, true)) {
      setNote(inputValue);
      setIsValidNote(true);
    } else {
      setIsValidNote(false);
    }
  };
  const handlePlaylistNameChange = (event) => {
    const inputValue = event.target.value;

    if (validateInput(inputValue)) {
      setPlaylistName(inputValue);
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };
  const validateForm = () => {
    if (playlistName == "") {
      return false;
    }
    return true;
  };
  const handleSubmitNote = async (event) => {
    const valid = validateNoteForm();
    if (auth) {
      if (valid) {
        const params = {
          videoTitle: playList[currentTrackIndex].videoTitle,
          contentId: playList[currentTrackIndex].id,
          videoUrl: window.location.href,
          startTimeFrame: startTime,
          endTimeFrame: endTime,
          incidentType: noteTitle,
          incidentWord: note,
        };
        setNoteBtnLding(true);
        try {
          const { data } = await apiCall(
            "POST",
            `${ApiEndPoint.SAVACREATENOTE}`,
            params
          );
          if (data) {
            setShowSaveInfo(false);

            setNote(null);
            setRefresh(!refresh);
            setNoteBtnLding(false);
            toast.success("Saved successfully");
          } else {
           
      
            setRefresh(!refresh);
            setNoteBtnLding(false);
            toast.error("Something went wrong");
          }
        } catch (err) {
          setShowSaveInfo(false);
          setRefresh(!refresh);
          setNoteBtnLding(false);
          toast.error("Something went wrong");
        }
      } else {
        setIsValidNote(true);
      }
    } else {
    }
    // setIsOpen(false);
  };
  const handleSubmit = async (event) => {
    const valid = validateForm();
    if (auth) {
      if (valid) {
        const params = {
          playListName: playlistName,
        };
        setBtnLoading(true);
        try {
          const { data } = await apiCall(
            "POST",
            `${ApiEndPoint.CREATEUSERPLAYLIST}`,
            params
          );
          if (data) {
            setShowCreatePlayListModel(false);
            setPlaylistName(null);
            setRefresh(!refresh);
            setBtnLoading(false);
            openModal();
            fetchUserPlaylistData();
          } else {
            setRefresh(!refresh);
          }
        } catch (err) {
          setShowCreatePlayListModel(false);
          setRefresh(!refresh);
          setBtnLoading(false);
        }
      } else {
        setIsValid(true);
      }
    } else {
    }
    // setIsOpen(false);
  };

  const isPlaylistSelected = (playlistId) => {
    return (
      previouslySelectedUserPlaylistArray.some(
        (item) => item?.id == playlistId
      ) ||
      currentlySelectedUserPlaylistArray.some((item) => item?.id === playlistId)
    );
  };

  const handleCheckboxChange = (event) => {
    const playlistId = parseInt(event.target.dataset.playlistId);
    const checked = event.target.checked;

    !isPlaylistSelected(playlistId)
      ? setCurrentlylySelectedUserPlaylistArray((prevSelectedPlaylist) => {
          let updatedSelectedPlaylist = [...prevSelectedPlaylist];

          if (checked) {
            if (
              !updatedSelectedPlaylist.some(
                (playlist) => playlist?.id === playlistId
              )
            ) {
              updatedSelectedPlaylist.push({ id: playlistId });
            }
          } else {
            updatedSelectedPlaylist = updatedSelectedPlaylist.filter(
              (playlist) => playlist.id !== playlistId
            );
          }

          return updatedSelectedPlaylist;
        })
      : removeFromExisting(playlistId);
  };
  const removeFromExisting = (playlistId) => {
    if (
      previouslySelectedUserPlaylistArray.some(
        (playlist) => playlist.id === playlistId
      )
    ) {
      const updatedExistingPlaylist =
        previouslySelectedUserPlaylistArray.filter(
          (playlist) => playlist.id !== playlistId
        );
      setPreviouslySelectedUserPlaylistArray(updatedExistingPlaylist);
      setUnselectedPlaylistArray((prev) => {
        return [...prev, { id: playlistId }];
      });
    } else if (
      currentlySelectedUserPlaylistArray.some(
        (playlist) => playlist.id === playlistId
      )
    ) {
      const updateSelectedPlaylistData =
        currentlySelectedUserPlaylistArray.filter(
          (playlist) => playlist.id !== playlistId
        );
      setCurrentlylySelectedUserPlaylistArray(updateSelectedPlaylistData);
    } else {
      return;
    }
  };
  const handleGoBack = async () => {
    const scrollY = window.scrollY;

    const isTimeSaved = await handlePause(playerRef);
    if (isTimeSaved) {
      window.history.scrollRestoration = "manual";
      navigate(-1);

      // Restore the scroll position to the top of the page.
      window.scrollTo(0, scrollY);
    }
  };
  function openLinkInNewTab(url) {
    window.open(url, "_blank");
  }
  const handleFastForward = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      playerRef.current.seekTo(currentTime + 10, "seconds");
    }
  };

  const handleRewind = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      playerRef.current.seekTo(currentTime - 10, "seconds");
    }
  };
  const openSaveInfoModal = () => {
    if (auth) {
      setShowSaveInfo(true);
      setEndTime(getTimeStamp());
    } else {
      takeOtherSubcription();
    }
  };
  const closeSaveInfoModal = () => {
    setShowSaveInfo(false);
  };

  const getTimeStamp = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      return formatTimeForTimeStamp(currentTime);
    }
  };

  return (
    <section className="playListPlayPage video-play-top">
      {/*----- Individual Video Title -----*/}
      <div className="video-play-title-mt-20">
        <span className="video-play-title-d-flex" onClick={handleGoBack}>
          <img
            className="video-play-c-pointer"
            src={AllImages.YellowArrow}
            alt="Back Arrow"
            title="Back Arrow"
          ></img>
          <p className="video-play-heading-title">{"Playlist Video"}</p>
        </span>
      </div>
      {showLoader && (
        <div
          style={{
            widthy: "100%",
            boxSizing: "border-box",
            position: "relative",
          }}
        >
          <div
            className="video-div1"
            style={{
              width: "100%",
              boxSizing: "borderBox",
              height: matchesMD === true ? 550 : 300,
              position: "absolute",
              boxSizing: "border-box",
              right: "0",
              left: "0",
            }}
          >
            <div
              className="video-div2"
              style={{
                backgroundColor: "#000",
                width: "100%",
                boxSizing: "border-box",
                height: matchesMD === true ? 550 : 300,
                textAlign: "center",
                display: "flex",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={loader} alt="loading..." />
            </div>
          </div>
        </div>
      )}
      {playList[currentTrackIndex] &&
      playList[currentTrackIndex].videoUrl != null ? (
        // <div>
        //   {/*----- Video Player -----*/}
        //   <ReactPlayer
        //     url={playList[currentTrackIndex].videoUrl}
        //     controls={true}
        //     ref={playerRef}
        //     playing={true}
        //     pip={true}
        //     stopOnUnmount={false}
        //     onEnded={handleEnded}
        //     config={{
        //       youtube: {
        //         playerVars: {
        //           showinfo: 0,
        //           fs: 1,
        //           modestbranding: 1,
        //           rel: 0,
        //           showRelatedVideos: false,
        //           start: playList[currentTrackIndex].lastWatchTime,
        //         },
        //       },
        //     }}
        //     width="100%"
        //     height={matchesMD === true ? 550 : 300}
        //   />
        // </div>
        <div className="playlist-play-page-video-player-container">
          {/*----- Video Player -----*/}
          <ReactPlayer
            url={playList[currentTrackIndex].videoUrl}
            controls={true}
            ref={playerRef}
            playing={true}
            pip={true}
            stopOnUnmount={false}
            onEnded={handleEnded}
            config={{
              youtube: {
                playerVars: {
                  showinfo: 0,
                  fs: 1,
                  modestbranding: 1,
                  rel: 0,
                  showRelatedVideos: false,
                  start: playList[currentTrackIndex].lastWatchTime,
                },
              },
            }}
            width="100%"
            height={matchesMD === true ? 550 : 300}
          />

          <div className="playlist-play-page-video-play-controls">
            <span className="video-play-control-d-flex" onClick={handleRewind}>
              <FaBackward className="playlist-play-page-video-play-backward-icon" />
            </span>

            <span
              className="video-play-control-d-flex"
              onClick={handleFastForward}
            >
              <FaForward className="playlist-play-page-video-play-forward-icon" />
            </span>
          </div>
          {/*----- Mark Favorite and Unfavorite -----*/}
        </div>
      ) : (
        playList[currentTrackIndex] && (
          <div
            className="video-play-container"
            style={{ backgroundColor: "black" }}
            onClick={() => takeSubcription(playList[currentTrackIndex])}
          >
            {/*----- Thumbnail and Lock -----*/}
            <div className="video-play-thumbnail-div">
              <img
                className="video-play-thumbnail"
                src={playList[currentTrackIndex].thumbnails}
              />
              <div className="video-play-layer-image"></div>
              <div className="video-play-lock-div">
                <div className="video-play-like-count badgeShow">
                  <i
                    className="fa fa-lock"
                    onClick={() => takeSubcription(playList[currentTrackIndex])}
                  ></i>
                </div>
              </div>
            </div>
          </div>
        )
      )}

      <div className="video-play-title-div">
        {playList[currentTrackIndex] && (
          <div className="videoPlayPageOuter">
            <div className="videoPlayPage">
              {/*----- Video Title -----*/}
              <p className="video-title">
                {playList[currentTrackIndex]?.videoTitle}
              </p>

              {/*----- Time and Month -----*/}
              <div className="video-play-d-flex video-play-mt-5">
                <div className="video-play-d-flex">
                  <span className="video-play-hrs-time">
                    {playList[currentTrackIndex].videoDuration}
                  </span>
                  <span className="video-play-hrs-txt">hrs</span>
                </div>
                {/* <div className="video-play-dot"></div>
                <div className="video-play-d-flex">
                  <span className="video-play-hrs-time">{playList[currentTrackIndex]?.numberOfLike}</span>
                  <span className="video-play-hrs-txt"> ago</span>
                </div> */}
              </div>
            </div>
            {/*----- Share Icon -----*/}
            <div className="share-options">
              <div className="video-play-d-flex video-play-mt-5">
                <img
                  className="video-play-mr-10"
                  onClick={() =>
                    addFavorite(playList[currentTrackIndex], currentTrackIndex)
                  }
                  src={
                    playList[currentTrackIndex]?.myFavourite
                      ? AllImages.Favorite_sel
                      : AllImages.Fav
                  }
                  alt="Favourite"
                  title="Favourite"
                ></img>
                {/* <RWebShare
                  data={{
                    // text: "Like humans, flamingos make friends for life",
                    url: `${VideoPlaylistShareUrl}/videoPlayPage/${playList[currentTrackIndex]?.id}`,
                    title: "Video Share",
                  }}
                  onClick={() => console.log("share")}
                >
                  <img
                    className="video-play-mr-10"
                    src={AllImages?.Copy}
                    alt="Copy"
                    title="Copy"
                  ></img>
                </RWebShare> */}
                {/* <img
                  className="video-play-mr-10"
                  src={AllImages?.ReaderMode}
                  alt="Read more"
                  title="Read more"
                ></img> */}
                {/* <div className="readTooltip">
                <button disabled={!playList[currentTrackIndex].crowed || playList[currentTrackIndex].videoUserMapping} onClick={() => openSaveInfoModal()} style={{background:"none",border:"none",padding:0,width:'fit-content'}} className="readTooltip">
                <img
                    style={{ padding: "2px", width: "18px", height: "18px" }}
                    onClick={() => openSaveInfoModal()}
                    className="video-play-mr-10"
                    src={AllImages?.CreateNote}
                    alt="Create Note"
                    title="Create Note"
              />
                  <span className="tooltiptext">{!playList[currentTrackIndex].crowed || playList[currentTrackIndex].videoUserMapping?"Feature not available":"Create a note"}</span>
                </button>
                </div> */}
                {/* {item?.crowed && item?.incidentComments && (
                  <div className="readTooltip">
                    <button
                      onClick={() => openSaveInfoModal()}
                      style={{
                        background: "none",
                        border: "none",
                        padding: 0,
                        width: "fit-content",
                      }}
                      className="readTooltip"
                    >
                      <img
                        style={{
                          padding: "3px",
                          width: "18px",
                          height: "18px",
                        }}
                        className="video-play-mr-10"
                        src={AllImages?.CreateNote}
                        alt="Create Note"
                        title="Create Note"
                      />
                      <span className="tooltiptext">{"Create a note"}</span>
                    </button>
                  </div>
                )} */}

                <img
                  className="video-play-mr-10"
                  src={AllImages?.ControlPoint}
                  onClick={() => openModal()}
                  alt="control point"
                  title="control point"
                ></img>
                <RWebShare
                  data={{
                    url: `${VideoPlaylistShareUrl}/videoPlayPage/${playList[currentTrackIndex]?.id}`,
                    title: "Video Share",
                  }}
                  onClick={() => console.log("share")}
                >
                  <img
                    className="video-play-mr-10"
                    src={AllImages?.ShareOutline}
                    alt="Share"
                    title="Share"
                  ></img>
                </RWebShare>
              </div>
            </div>
          </div>
        )}
      </div>

      {/*----- Reference Material -----*/}
      {showAdminSuggestions && (
        <div className="mobile-ref-material">
          <p className="video-play-reference-material">
            {"Reference Material"}
          </p>
          <div className="document_card">
            {documentData.length > 0 ? (
              documentData?.map((content, index) => (
                <div key={index} className="video-play-reference-material-card">
                  <div className="video-play-d-flex ">
                    <div className="video-play-d-flex-title">
                    <p className="reference-material-card-title">
                     <span> {contentTypeMappings[content.contentType]}</span>
                     
                      <span className="reference-material-card-type">
                        {content.contentType === "EBOOK" ||
                        content.contentType === "PATRA" ||
                        content.contentType === "TRANSCRIPT"
                          ? `(${capitalizeFirstLetter(
                              getFileExtension(content.contentUrl)
                            )})`
                          : content.contentType === "AUDIO" ||
                            content.contentType === "PAD"
                          ? "(Audio)"
                          : "(Pdf)"}
                      </span>
                    </p>
                    <p className="reference-material-card-title">
                      {content.title}
                    </p>
                    </div>
                  </div>
                  <div className="video-play-d-flex video-play-jc-sb video-play-mt-30">
                    {content.contentType === "AUDIO" ||
                    content.contentType === "PAD" ? null : fileDownloadLoader ==
                      index ? (
                      <img
                        src={loader}
                        alt="loading..."
                        style={{ height: 20, width: 20 }}
                      />
                    ) : (
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleDownloadClick(content.contentUrl, index)
                        }
                        src={AllImages?.YellowDownload}
                        alt="Download"
                        title="Download"
                      ></img>
                    )}
                    {content.contentType === "AUDIO" ||
                    content.contentType === "PAD" ? null : (
                      <span
                        className="reference-material-download-type"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleReadOrPlayClick(
                            content.contentUrl,
                            content.contentType
                          )
                        }
                      >
                        {" "}
                        {content.contentType === "AUDIO" ||
                        content.contentType === "PAD"
                          ? "PLAY"
                          : "READ"}
                      </span>
                    )}
                    {(content.contentType === "AUDIO" ||
                      content.contentType === "PAD") && (
                      <div style={{ display: "flex", gap: "5px" }}>
                        {content?.contentUrlApple && (
                          <img
                            src={AllImages?.appleMusicSvg}
                            className="musicIcons read-play-type-btn"
                            onClick={() =>
                              openLinkInNewTab(content.contentUrlApple)
                            }
                            alt="Apple Music"
                            title="Apple Music"
                          />
                        )}
                        {content?.contentUrlSpotify && (
                          <img
                            src={AllImages?.spotifyMusicSvg}
                            className="musicIcons read-play-type-btn"
                            onClick={() =>
                              openLinkInNewTab(content.contentUrlSpotify)
                            }
                            alt="Spotify"
                            title="Spotify"
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <NoDataAvailable />
            )}
          </div>
        </div>
      )}

      {/*----- Suggested Video -----*/}
      {(showAdminSuggestions || showUserSuggestions) && (
        <div className="mobile-ref-material suggestedVideo">
          <p className="video-play-suggested-video">{"Suggested Video"}</p>
          <div className="suggested-video-column-count-3 custom-row">
            {playList.length > 0 ? (
              playList.map((item, index) => {
                return (
                  <div className="custom-col-4 custom-col">
                    <div className="suggested-video-play-card-wrap">
                      <div
                        className="suggested-video-play-inside-wrap"
                        style={{ position: "relative" }}
                      >
                        {item.videoUrl ? (
                          <div
                            className="suggested-video-play-list-image"
                            onClick={() => suggestedVideo(index, item.id)}
                          >
                            <img
                              className="suggested-video-img-banner"
                              src={item?.thumbnails}
                              // key={item.id}
                              alt="Card Image"
                            />
                            <img
                              className="suggested-video-play-play-icon"
                              src={AllImages.PlayIcon}
                              alt="play Icon"
                              title="Play Icon"
                            />
                          </div>
                        ) : (
                          <div
                            className="ind-card-play-list-image"
                            style={{ position: "static" }}
                          >
                            <div onClick={() => takeSubcription(item)}>
                              <img
                                className={`ind-card-img-banner ${
                                  getImageNameFromResolution(item.thumbnails) !=
                                    "maxresdefault" && "image-resolution"
                                }`}
                                src={
                                  item?.thumbnails
                                    ? `${item?.thumbnails}`
                                    : `${AllImages.DefaultThumbnail}`
                                }
                                alt="Banner"
                                title="Banner"
                              />
                              <div className="layerImage"></div>
                            </div>
                            <div className="star1 badgestarInvisible">
                              <div className="LikeCount badgeShow">
                                <i
                                  className="fa fa-lock"
                                  // onClick={() => takeSubcription(item)}
                                ></i>
                              </div>
                              <span className="tooltiptext">
                                {"Subscribe for more Videos"}
                              </span>
                            </div>
                          </div>
                        )}
                        <div className="suggested-video-play-card-content-item">
                          <div className="suggested-video-play-video-content">
                            <div>
                              <p className="suggested-video-play-title-text f-poppins">
                                {item?.videoTitle}
                              </p>
                              <p className="suggested-video-play-text-hrs f-poppins">
                                {item?.videoDuration
                                  ? item?.videoDuration
                                  : null}{" "}
                                {item?.videoDuration ? "hrs" : null}
                              </p>
                            </div>
                            <div className="suggested-video-mt-10">
                              <img
                                onClick={() =>
                                  item?.watchLater
                                    ? RemoveWatchLaterVideo(item, index)
                                    : videoWatchLater(item, index)
                                }
                                src={
                                  item?.watchLater
                                    ? AllImages.SelectedTimer
                                    : AllImages.Timer
                                }
                                alt="Timer"
                                title="Timer"
                              ></img>
                              <img
                                onClick={() => addFavorite(item, index)}
                                src={
                                  item?.myFavourite
                                    ? AllImages.Favorite_sel
                                    : AllImages.Fav
                                }
                                alt="Favourite"
                                title="Favourite"
                              ></img>
                              {/* <img
                                src={AllImages.Copy}
                                alt="Copy"
                                title="Copy"
                              ></img> */}

                              <RWebShare
                                data={{
                                  url: `${VideoPlaylistShareUrl}/videoPlayPage/${item.id}`,
                                  title: "Video Share",
                                }}
                                // onClick={() => console.log('share')}
                              >
                                <img src={AllImages.Copy}></img>
                              </RWebShare>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <NoDataAvailable />
            )}
          </div>
        </div>
      )}

      {/*----- Add to My Playlists Modal -----*/}
      {isOpen && (
        <Dialog
          open={openModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          scroll={"paper"}
        >
          <DialogContent
            // ref={dialogContentRef}
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              maxHeight: "320px", // Adjust the maximum height as needed
              margin: 5,
            }}
            // onScroll={handlePlaylistScroll}
          >
            <div className="add-my-playlist-modal-content">
              <div className="add-my-playlist-modal-d-flex modal-mb-5">
                <p className="add-my-playlist-modal-msg-text">
                  {"Add to My Playlists"}
                </p>
                <span
                  className="add-my-playlist-modal-close-button"
                  onClick={closeModal}
                >
                  &times;
                </span>
              </div>
              <div>
                {userPlayList &&
                  userPlayList.map((item, index) => (
                    <div
                      key={item.id}
                      className="add-my-playlist-modal-d-flex modal-mt-15"
                    >
                      <input
                        type="checkbox"
                        id={`checkbox_${item.id}`}
                        name={`checkbox_${item.id}`}
                        data-playlist-id={item.id}
                        checked={isPlaylistSelected(item.id)}
                        onChange={handleCheckboxChange}
                      />
                      <label
                        className="add-my-playlist-checkbox-text"
                        htmlFor={`checkbox_${item.id}`}
                      >
                        {item.playListName}
                      </label>
                    </div>
                  ))}
                {userPlayList && userPlayList.length > 6 && scrollHashMap && (
                  <p
                    onClick={fetchMoreUserPlaylistData}
                    className="fetch-more-playlist"
                  >
                    MORE+
                  </p>
                )}
              </div>
              <div className="modal-mt-15" onClick={createPlaylist}>
                <label className="add-my-playlist-create-playlist">
                  Create Playlist
                </label>
              </div>
            </div>
            {isLoading && (
              <div className="loader">
                <img src={loader} alt="loading..." />
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <div className="add-my-playlist-modal-action">
              <button
                onClick={closeModal}
                className="add-my-playlist-modal-cancel-btn"
              >
                {"Cancel"}
              </button>
              <button
                className="add-my-playlist-modal-add-btn"
                onClick={addVideoAgainstPlaylist}
              >
                {"confirm"}
              </button>
            </div>
          </DialogActions>
        </Dialog>
      )}
      {/* {openRecaptcha && (
        <Dialog
          open={openRecaptcha}
          className="sortBy captcha"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent
            style={{
              overflowY: "hidden",
              overflowX: "hidden",
            }}
          >
            <ReCAPTCHA
              sitekey={TEST_SITE_KEY}
              onChange={onChange}
              style={{ display: "inline-block" }}
            />
          </DialogContent>
        </Dialog>
      )} */}
      {showCreatePlayListModel && (
        <Dialog
          open={openCreatedPlayListModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="createPlaylistModal"
        >
          <DialogContent>
            <div className="my-create-playlist-modal-content">
              <div className="modal-d-flex">
                <p className="my-created-playlist-modal-msg-text">
                  {"Create a Playlist"}
                </p>
                <span
                  className="my-created-playlist-modal-close-btn"
                  onClick={() => closeCreatedPlayListModal()}
                >
                  &times;
                </span>
              </div>
              <div>
                <p className="my-create-playlist-modal-playlist-name">
                  {"Playlist Name"}
                </p>
                <input
                  placeholder="Spiritual Playlist"
                  className={`f-poppins my-create-playlist-modal-search-input ${
                    isValid ? "invalid" : null
                  } `}
                  type="text"
                  id="playlistName"
                  value={playlistName}
                  onChange={handlePlaylistNameChange}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="my-created-playlist-modal-action-content">
              <button
                className="my-create-playlist-cancel-btn"
                onClick={() => closeCreatedPlayListModal()}
              >
                {"Cancel"}
              </button>
              <button
                className="my-create-playlist-create-btn"
                onClick={() => handleSubmit()}
              >
                {BtnLoading ? (
                  <img src={loader} style={{ width: 20, height: 20 }} />
                ) : (
                  "Create"
                )}
              </button>
            </div>
          </DialogActions>
        </Dialog>
      )}
      {showSaveInfo && (
        <Dialog
          open={openSaveInfoModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="createPlaylistModal save-video-info"
        >
          <DialogContent>
            <div className="my-create-playlist-modal-content">
              <div className="modal-d-flex">
                <p className="my-created-playlist-modal-msg-text">
                  {"Create a Note"}
                </p>
                <span
                  className="my-created-playlist-modal-close-btn"
                  onClick={() => closeSaveInfoModal()}
                >
                  &times;
                </span>
              </div>
              <div>
                <p className="my-create-playlist-modal-playlist-name">
                  {"Video Title"}
                </p>
                <input
                  className={`f-poppins my-create-playlist-modal-search-input `}
                  type="text"
                  value={playList[currentTrackIndex].videoTitle}
                  disabled={true}
                />
              </div>
              <div>
                <p className="my-create-playlist-modal-playlist-name">
                  {"Video Link"}
                </p>
                <input
                  className={`f-poppins my-create-playlist-modal-search-input  `}
                  type="text"
                  value={window.location.href}
                  disabled={true}
                />
              </div>
              <div>
                <p className="my-create-playlist-modal-playlist-name">
                  {"Start Time"}
                </p>
                <input
                  className={`f-poppins my-create-playlist-modal-search-input `}
                  type="text"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                />
              </div>
              <div>
                <p className="my-create-playlist-modal-playlist-name">
                  {"End Time"}
                </p>
                <input
                  className={`f-poppins my-create-playlist-modal-search-input `}
                  type="text"
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                />
              </div>
              <div className="language">
                <p className="my-create-playlist-modal-playlist-name">
                  {"Note Title"}
                </p>
                <select
                  value={noteTitle}
                  onChange={(e) => setNoteTitle(e.target.value)}
                >
                  <option value="Shabdarth" key="0">
                    Shabdarth
                  </option>
                  <option value="Purusharth" key="1">
                    Purusharth
                  </option>
                  <option value="Shanka" key="2">
                    Shanka
                  </option>
                </select>
              </div>
              <div>
                <p className="my-create-playlist-modal-playlist-name">
                  {"Note"}
                </p>
                <textarea 
                  placeholder="Note"
                  className={`f-poppins my-create-playlist-modal-search-input ${
                    isValidNote ? "invalid" : null
                  } `}
                  style={{height: "100px",lineHeight:"20px",wordSpacing:0}}
                  type="textarea"
                  cols={10}
                  
                  value={note}
                  onChange={handleNoteChange}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="my-created-playlist-modal-action-content">
              <button
                className="my-create-playlist-cancel-btn"
                onClick={() => closeSaveInfoModal()}
              >
                {"Cancel"}
              </button>
              <button
                className="my-create-playlist-create-btn"
                onClick={() => handleSubmitNote()}
              >
                {createNoteBtnLding ? (
                  <img src={loader} style={{ width: 20, height: 20 }} />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </section>
  );
}
export default PlayListPlayPage;
