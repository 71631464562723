import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Typography,
  Box,
  Grid,
  ListItem,
  List,
  Button,
  TextField,
} from "@material-ui/core";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./style.css";
import {
  GLOBAL_URL,
  AllImages,
  RemoveDuplicates,
} from "../../../utils/constants";
import { userDetailsContext, GSTContext } from "../../Context/UserContext";
import { toast } from "react-toastify";

import { setDefaultHeader, apiCall } from "../../../utils/httpClient";
import ApiEndPoint from "../../../utils/apiEndPoints";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import { Autocomplete } from "@mui/material";
import Dropzone from "react-dropzone";
function EditProfile() {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const [subscriptionList, setSubcriptionList] = useState([]);
  const [currentUser, setCurrentUser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useContext(userDetailsContext);
  const [gstpagemanage, setGstpagemanage] = useContext(GSTContext);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [city, setCity] = useState(null);
  const [pinCode, setPinCode] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const userPreviousDetailsRef = useRef();
  const [countryCode, setCountryCode] = useState({
    name: "India",
    flag: "🇮🇳",
    code: "IN",
    dial_code: "+91",
  });
  const [country, setCountry] = useState({
    name: "India",
    flag: "🇮🇳",
    code: "IN",
    dial_code: "+91",
  });
  const [state, setState] = useState({
    name: "Gujarat",
    stateCode: 7,
  });
  const [stateInput, setStateInput] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [value, setValue] = useState();

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const dataArray = [
    {
      name: "India",
      flag: "🇮🇳",
      code: "IN",
      dial_code: "+91",
    },
    {
      name: "Afghanistan",
      flag: "🇦🇫",
      code: "AF",
      dial_code: "+93",
    },
    {
      name: "Åland Islands",
      flag: "🇦🇽",
      code: "AX",
      dial_code: "+358",
    },
    {
      name: "Albania",
      flag: "🇦🇱",
      code: "AL",
      dial_code: "+355",
    },
    {
      name: "Algeria",
      flag: "🇩🇿",
      code: "DZ",
      dial_code: "+213",
    },
    {
      name: "American Samoa",
      flag: "🇦🇸",
      code: "AS",
      dial_code: "+1684",
    },
    {
      name: "Andorra",
      flag: "🇦🇩",
      code: "AD",
      dial_code: "+376",
    },
    {
      name: "Angola",
      flag: "🇦🇴",
      code: "AO",
      dial_code: "+244",
    },
    {
      name: "Anguilla",
      flag: "🇦🇮",
      code: "AI",
      dial_code: "+1264",
    },
    {
      name: "Antarctica",
      flag: "🇦🇶",
      code: "AQ",
      dial_code: "+672",
    },
    {
      name: "Antigua and Barbuda",
      flag: "🇦🇬",
      code: "AG",
      dial_code: "+1268",
    },
    {
      name: "Argentina",
      flag: "🇦🇷",
      code: "AR",
      dial_code: "+54",
    },
    {
      name: "Armenia",
      flag: "🇦🇲",
      code: "AM",
      dial_code: "+374",
    },
    {
      name: "Aruba",
      flag: "🇦🇼",
      code: "AW",
      dial_code: "+297",
    },
    {
      name: "Australia",
      flag: "🇦🇺",
      code: "AU",
      dial_code: "+61",
    },
    {
      name: "Austria",
      flag: "🇦🇹",
      code: "AT",
      dial_code: "+43",
    },
    {
      name: "Azerbaijan",
      flag: "🇦🇿",
      code: "AZ",
      dial_code: "+994",
    },
    {
      name: "Bahamas",
      flag: "🇧🇸",
      code: "BS",
      dial_code: "+1242",
    },
    {
      name: "Bahrain",
      flag: "🇧🇭",
      code: "BH",
      dial_code: "+973",
    },
    {
      name: "Bangladesh",
      flag: "🇧🇩",
      code: "BD",
      dial_code: "+880",
    },
    {
      name: "Barbados",
      flag: "🇧🇧",
      code: "BB",
      dial_code: "+1246",
    },
    {
      name: "Belarus",
      flag: "🇧🇾",
      code: "BY",
      dial_code: "+375",
    },
    {
      name: "Belgium",
      flag: "🇧🇪",
      code: "BE",
      dial_code: "+32",
    },
    {
      name: "Belize",
      flag: "🇧🇿",
      code: "BZ",
      dial_code: "+501",
    },
    {
      name: "Benin",
      flag: "🇧🇯",
      code: "BJ",
      dial_code: "+229",
    },
    {
      name: "Bermuda",
      flag: "🇧🇲",
      code: "BM",
      dial_code: "+1441",
    },
    {
      name: "Bhutan",
      flag: "🇧🇹",
      code: "BT",
      dial_code: "+975",
    },
    {
      name: "Bolivia, Plurinational State of bolivia",
      flag: "🇧🇴",
      code: "BO",
      dial_code: "+591",
    },
    {
      name: "Bosnia and Herzegovina",
      flag: "🇧🇦",
      code: "BA",
      dial_code: "+387",
    },
    {
      name: "Botswana",
      flag: "🇧🇼",
      code: "BW",
      dial_code: "+267",
    },
    {
      name: "Bouvet Island",
      flag: "🇧🇻",
      code: "BV",
      dial_code: "+47",
    },
    {
      name: "Brazil",
      flag: "🇧🇷",
      code: "BR",
      dial_code: "+55",
    },
    {
      name: "British Indian Ocean Territory",
      flag: "🇮🇴",
      code: "IO",
      dial_code: "+246",
    },
    {
      name: "Brunei Darussalam",
      flag: "🇧🇳",
      code: "BN",
      dial_code: "+673",
    },
    {
      name: "Bulgaria",
      flag: "🇧🇬",
      code: "BG",
      dial_code: "+359",
    },
    {
      name: "Burkina Faso",
      flag: "🇧🇫",
      code: "BF",
      dial_code: "+226",
    },
    {
      name: "Burundi",
      flag: "🇧🇮",
      code: "BI",
      dial_code: "+257",
    },
    {
      name: "Cambodia",
      flag: "🇰🇭",
      code: "KH",
      dial_code: "+855",
    },
    {
      name: "Cameroon",
      flag: "🇨🇲",
      code: "CM",
      dial_code: "+237",
    },
    {
      name: "Canada",
      flag: "🇨🇦",
      code: "CA",
      dial_code: "+1",
    },
    {
      name: "Cape Verde",
      flag: "🇨🇻",
      code: "CV",
      dial_code: "+238",
    },
    {
      name: "Cayman Islands",
      flag: "🇰🇾",
      code: "KY",
      dial_code: "+345",
    },
    {
      name: "Central African Republic",
      flag: "🇨🇫",
      code: "CF",
      dial_code: "+236",
    },
    {
      name: "Chad",
      flag: "🇹🇩",
      code: "TD",
      dial_code: "+235",
    },
    {
      name: "Chile",
      flag: "🇨🇱",
      code: "CL",
      dial_code: "+56",
    },
    {
      name: "China",
      flag: "🇨🇳",
      code: "CN",
      dial_code: "+86",
    },
    {
      name: "Christmas Island",
      flag: "🇨🇽",
      code: "CX",
      dial_code: "+61",
    },
    {
      name: "Cocos (Keeling) Islands",
      flag: "🇨🇨",
      code: "CC",
      dial_code: "+61",
    },
    {
      name: "Colombia",
      flag: "🇨🇴",
      code: "CO",
      dial_code: "+57",
    },
    {
      name: "Comoros",
      flag: "🇰🇲",
      code: "KM",
      dial_code: "+269",
    },
    {
      name: "Congo",
      flag: "🇨🇬",
      code: "CG",
      dial_code: "+242",
    },
    {
      name: "Congo, The Democratic Republic of the Congo",
      flag: "🇨🇩",
      code: "CD",
      dial_code: "+243",
    },
    {
      name: "Cook Islands",
      flag: "🇨🇰",
      code: "CK",
      dial_code: "+682",
    },
    {
      name: "Costa Rica",
      flag: "🇨🇷",
      code: "CR",
      dial_code: "+506",
    },
    {
      name: "Cote d'Ivoire",
      flag: "🇨🇮",
      code: "CI",
      dial_code: "+225",
    },
    {
      name: "Croatia",
      flag: "🇭🇷",
      code: "HR",
      dial_code: "+385",
    },
    {
      name: "Cuba",
      flag: "🇨🇺",
      code: "CU",
      dial_code: "+53",
    },
    {
      name: "Cyprus",
      flag: "🇨🇾",
      code: "CY",
      dial_code: "+357",
    },
    {
      name: "Czech Republic",
      flag: "🇨🇿",
      code: "CZ",
      dial_code: "+420",
    },
    {
      name: "Denmark",
      flag: "🇩🇰",
      code: "DK",
      dial_code: "+45",
    },
    {
      name: "Djibouti",
      flag: "🇩🇯",
      code: "DJ",
      dial_code: "+253",
    },
    {
      name: "Dominica",
      flag: "🇩🇲",
      code: "DM",
      dial_code: "+1767",
    },
    {
      name: "Dominican Republic",
      flag: "🇩🇴",
      code: "DO",
      dial_code: "+1849",
    },
    {
      name: "Ecuador",
      flag: "🇪🇨",
      code: "EC",
      dial_code: "+593",
    },
    {
      name: "Egypt",
      flag: "🇪🇬",
      code: "EG",
      dial_code: "+20",
    },
    {
      name: "El Salvador",
      flag: "🇸🇻",
      code: "SV",
      dial_code: "+503",
    },
    {
      name: "Equatorial Guinea",
      flag: "🇬🇶",
      code: "GQ",
      dial_code: "+240",
    },
    {
      name: "Eritrea",
      flag: "🇪🇷",
      code: "ER",
      dial_code: "+291",
    },
    {
      name: "Estonia",
      flag: "🇪🇪",
      code: "EE",
      dial_code: "+372",
    },
    {
      name: "Ethiopia",
      flag: "🇪🇹",
      code: "ET",
      dial_code: "+251",
    },
    {
      name: "Falkland Islands (Malvinas)",
      flag: "🇫🇰",
      code: "FK",
      dial_code: "+500",
    },
    {
      name: "Faroe Islands",
      flag: "🇫🇴",
      code: "FO",
      dial_code: "+298",
    },
    {
      name: "Fiji",
      flag: "🇫🇯",
      code: "FJ",
      dial_code: "+679",
    },
    {
      name: "Finland",
      flag: "🇫🇮",
      code: "FI",
      dial_code: "+358",
    },
    {
      name: "France",
      flag: "🇫🇷",
      code: "FR",
      dial_code: "+33",
    },
    {
      name: "French Guiana",
      flag: "🇬🇫",
      code: "GF",
      dial_code: "+594",
    },
    {
      name: "French Polynesia",
      flag: "🇵🇫",
      code: "PF",
      dial_code: "+689",
    },
    {
      name: "French Southern Territories",
      flag: "🇹🇫",
      code: "TF",
      dial_code: "+262",
    },
    {
      name: "Gabon",
      flag: "🇬🇦",
      code: "GA",
      dial_code: "+241",
    },
    {
      name: "Gambia",
      flag: "🇬🇲",
      code: "GM",
      dial_code: "+220",
    },
    {
      name: "Georgia",
      flag: "🇬🇪",
      code: "GE",
      dial_code: "+995",
    },
    {
      name: "Germany",
      flag: "🇩🇪",
      code: "DE",
      dial_code: "+49",
    },
    {
      name: "Ghana",
      flag: "🇬🇭",
      code: "GH",
      dial_code: "+233",
    },
    {
      name: "Gibraltar",
      flag: "🇬🇮",
      code: "GI",
      dial_code: "+350",
    },
    {
      name: "Greece",
      flag: "🇬🇷",
      code: "GR",
      dial_code: "+30",
    },
    {
      name: "Greenland",
      flag: "🇬🇱",
      code: "GL",
      dial_code: "+299",
    },
    {
      name: "Grenada",
      flag: "🇬🇩",
      code: "GD",
      dial_code: "+1473",
    },
    {
      name: "Guadeloupe",
      flag: "🇬🇵",
      code: "GP",
      dial_code: "+590",
    },
    {
      name: "Guam",
      flag: "🇬🇺",
      code: "GU",
      dial_code: "+1671",
    },
    {
      name: "Guatemala",
      flag: "🇬🇹",
      code: "GT",
      dial_code: "+502",
    },
    {
      name: "Guernsey",
      flag: "🇬🇬",
      code: "GG",
      dial_code: "+44",
    },
    {
      name: "Guinea",
      flag: "🇬🇳",
      code: "GN",
      dial_code: "+224",
    },
    {
      name: "Guinea-Bissau",
      flag: "🇬🇼",
      code: "GW",
      dial_code: "+245",
    },
    {
      name: "Guyana",
      flag: "🇬🇾",
      code: "GY",
      dial_code: "+592",
    },
    {
      name: "Haiti",
      flag: "🇭🇹",
      code: "HT",
      dial_code: "+509",
    },
    {
      name: "Heard Island and Mcdonald Islands",
      flag: "🇭🇲",
      code: "HM",
      dial_code: "+672",
    },
    {
      name: "Holy See (Vatican City State)",
      flag: "🇻🇦",
      code: "VA",
      dial_code: "+379",
    },
    {
      name: "Honduras",
      flag: "🇭🇳",
      code: "HN",
      dial_code: "+504",
    },
    {
      name: "Hong Kong",
      flag: "🇭🇰",
      code: "HK",
      dial_code: "+852",
    },
    {
      name: "Hungary",
      flag: "🇭🇺",
      code: "HU",
      dial_code: "+36",
    },
    {
      name: "Iceland",
      flag: "🇮🇸",
      code: "IS",
      dial_code: "+354",
    },
    {
      name: "Indonesia",
      flag: "🇮🇩",
      code: "ID",
      dial_code: "+62",
    },
    {
      name: "Iran, Islamic Republic of Persian Gulf",
      flag: "🇮🇷",
      code: "IR",
      dial_code: "+98",
    },
    {
      name: "Iraq",
      flag: "🇮🇶",
      code: "IQ",
      dial_code: "+964",
    },
    {
      name: "Ireland",
      flag: "🇮🇪",
      code: "IE",
      dial_code: "+353",
    },
    {
      name: "Isle of Man",
      flag: "🇮🇲",
      code: "IM",
      dial_code: "+44",
    },
    {
      name: "Israel",
      flag: "🇮🇱",
      code: "IL",
      dial_code: "+972",
    },
    {
      name: "Italy",
      flag: "🇮🇹",
      code: "IT",
      dial_code: "+39",
    },
    {
      name: "Jamaica",
      flag: "🇯🇲",
      code: "JM",
      dial_code: "+1876",
    },
    {
      name: "Japan",
      flag: "🇯🇵",
      code: "JP",
      dial_code: "+81",
    },
    {
      name: "Jersey",
      flag: "🇯🇪",
      code: "JE",
      dial_code: "+44",
    },
    {
      name: "Jordan",
      flag: "🇯🇴",
      code: "JO",
      dial_code: "+962",
    },
    {
      name: "Kazakhstan",
      flag: "🇰🇿",
      code: "KZ",
      dial_code: "+7",
    },
    {
      name: "Kenya",
      flag: "🇰🇪",
      code: "KE",
      dial_code: "+254",
    },
    {
      name: "Kiribati",
      flag: "🇰🇮",
      code: "KI",
      dial_code: "+686",
    },
    {
      name: "Korea, Democratic People's Republic of Korea",
      flag: "🇰🇵",
      code: "KP",
      dial_code: "+850",
    },
    {
      name: "Korea, Republic of South Korea",
      flag: "🇰🇷",
      code: "KR",
      dial_code: "+82",
    },
    {
      name: "Kosovo",
      flag: "🇽🇰",
      code: "XK",
      dial_code: "+383",
    },
    {
      name: "Kuwait",
      flag: "🇰🇼",
      code: "KW",
      dial_code: "+965",
    },
    {
      name: "Kyrgyzstan",
      flag: "🇰🇬",
      code: "KG",
      dial_code: "+996",
    },
    {
      name: "Laos",
      flag: "🇱🇦",
      code: "LA",
      dial_code: "+856",
    },
    {
      name: "Latvia",
      flag: "🇱🇻",
      code: "LV",
      dial_code: "+371",
    },
    {
      name: "Lebanon",
      flag: "🇱🇧",
      code: "LB",
      dial_code: "+961",
    },
    {
      name: "Lesotho",
      flag: "🇱🇸",
      code: "LS",
      dial_code: "+266",
    },
    {
      name: "Liberia",
      flag: "🇱🇷",
      code: "LR",
      dial_code: "+231",
    },
    {
      name: "Libyan Arab Jamahiriya",
      flag: "🇱🇾",
      code: "LY",
      dial_code: "+218",
    },
    {
      name: "Liechtenstein",
      flag: "🇱🇮",
      code: "LI",
      dial_code: "+423",
    },
    {
      name: "Lithuania",
      flag: "🇱🇹",
      code: "LT",
      dial_code: "+370",
    },
    {
      name: "Luxembourg",
      flag: "🇱🇺",
      code: "LU",
      dial_code: "+352",
    },
    {
      name: "Macao",
      flag: "🇲🇴",
      code: "MO",
      dial_code: "+853",
    },
    {
      name: "Macedonia",
      flag: "🇲🇰",
      code: "MK",
      dial_code: "+389",
    },
    {
      name: "Madagascar",
      flag: "🇲🇬",
      code: "MG",
      dial_code: "+261",
    },
    {
      name: "Malawi",
      flag: "🇲🇼",
      code: "MW",
      dial_code: "+265",
    },
    {
      name: "Malaysia",
      flag: "🇲🇾",
      code: "MY",
      dial_code: "+60",
    },
    {
      name: "Maldives",
      flag: "🇲🇻",
      code: "MV",
      dial_code: "+960",
    },
    {
      name: "Mali",
      flag: "🇲🇱",
      code: "ML",
      dial_code: "+223",
    },
    {
      name: "Malta",
      flag: "🇲🇹",
      code: "MT",
      dial_code: "+356",
    },
    {
      name: "Marshall Islands",
      flag: "🇲🇭",
      code: "MH",
      dial_code: "+692",
    },
    {
      name: "Martinique",
      flag: "🇲🇶",
      code: "MQ",
      dial_code: "+596",
    },
    {
      name: "Mauritania",
      flag: "🇲🇷",
      code: "MR",
      dial_code: "+222",
    },
    {
      name: "Mauritius",
      flag: "🇲🇺",
      code: "MU",
      dial_code: "+230",
    },
    {
      name: "Mayotte",
      flag: "🇾🇹",
      code: "YT",
      dial_code: "+262",
    },
    {
      name: "Mexico",
      flag: "🇲🇽",
      code: "MX",
      dial_code: "+52",
    },
    {
      name: "Micronesia, Federated States of Micronesia",
      flag: "🇫🇲",
      code: "FM",
      dial_code: "+691",
    },
    {
      name: "Moldova",
      flag: "🇲🇩",
      code: "MD",
      dial_code: "+373",
    },
    {
      name: "Monaco",
      flag: "🇲🇨",
      code: "MC",
      dial_code: "+377",
    },
    {
      name: "Mongolia",
      flag: "🇲🇳",
      code: "MN",
      dial_code: "+976",
    },
    {
      name: "Montenegro",
      flag: "🇲🇪",
      code: "ME",
      dial_code: "+382",
    },
    {
      name: "Montserrat",
      flag: "🇲🇸",
      code: "MS",
      dial_code: "+1664",
    },
    {
      name: "Morocco",
      flag: "🇲🇦",
      code: "MA",
      dial_code: "+212",
    },
    {
      name: "Mozambique",
      flag: "🇲🇿",
      code: "MZ",
      dial_code: "+258",
    },
    {
      name: "Myanmar",
      flag: "🇲🇲",
      code: "MM",
      dial_code: "+95",
    },
    {
      name: "Namibia",
      flag: "🇳🇦",
      code: "NA",
      dial_code: "+264",
    },
    {
      name: "Nauru",
      flag: "🇳🇷",
      code: "NR",
      dial_code: "+674",
    },
    {
      name: "Nepal",
      flag: "🇳🇵",
      code: "NP",
      dial_code: "+977",
    },
    {
      name: "Netherlands",
      flag: "🇳🇱",
      code: "NL",
      dial_code: "+31",
    },
    {
      name: "Netherlands Antilles",
      flag: "",
      code: "AN",
      dial_code: "+599",
    },
    {
      name: "New Caledonia",
      flag: "🇳🇨",
      code: "NC",
      dial_code: "+687",
    },
    {
      name: "New Zealand",
      flag: "🇳🇿",
      code: "NZ",
      dial_code: "+64",
    },
    {
      name: "Nicaragua",
      flag: "🇳🇮",
      code: "NI",
      dial_code: "+505",
    },
    {
      name: "Niger",
      flag: "🇳🇪",
      code: "NE",
      dial_code: "+227",
    },
    {
      name: "Nigeria",
      flag: "🇳🇬",
      code: "NG",
      dial_code: "+234",
    },
    {
      name: "Niue",
      flag: "🇳🇺",
      code: "NU",
      dial_code: "+683",
    },
    {
      name: "Norfolk Island",
      flag: "🇳🇫",
      code: "NF",
      dial_code: "+672",
    },
    {
      name: "Northern Mariana Islands",
      flag: "🇲🇵",
      code: "MP",
      dial_code: "+1670",
    },
    {
      name: "Norway",
      flag: "🇳🇴",
      code: "NO",
      dial_code: "+47",
    },
    {
      name: "Oman",
      flag: "🇴🇲",
      code: "OM",
      dial_code: "+968",
    },
    {
      name: "Pakistan",
      flag: "🇵🇰",
      code: "PK",
      dial_code: "+92",
    },
    {
      name: "Palau",
      flag: "🇵🇼",
      code: "PW",
      dial_code: "+680",
    },
    {
      name: "Palestinian Territory, Occupied",
      flag: "🇵🇸",
      code: "PS",
      dial_code: "+970",
    },
    {
      name: "Panama",
      flag: "🇵🇦",
      code: "PA",
      dial_code: "+507",
    },
    {
      name: "Papua New Guinea",
      flag: "🇵🇬",
      code: "PG",
      dial_code: "+675",
    },
    {
      name: "Paraguay",
      flag: "🇵🇾",
      code: "PY",
      dial_code: "+595",
    },
    {
      name: "Peru",
      flag: "🇵🇪",
      code: "PE",
      dial_code: "+51",
    },
    {
      name: "Philippines",
      flag: "🇵🇭",
      code: "PH",
      dial_code: "+63",
    },
    {
      name: "Pitcairn",
      flag: "🇵🇳",
      code: "PN",
      dial_code: "+64",
    },
    {
      name: "Poland",
      flag: "🇵🇱",
      code: "PL",
      dial_code: "+48",
    },
    {
      name: "Portugal",
      flag: "🇵🇹",
      code: "PT",
      dial_code: "+351",
    },
    {
      name: "Puerto Rico",
      flag: "🇵🇷",
      code: "PR",
      dial_code: "+1939",
    },
    {
      name: "Qatar",
      flag: "🇶🇦",
      code: "QA",
      dial_code: "+974",
    },
    {
      name: "Romania",
      flag: "🇷🇴",
      code: "RO",
      dial_code: "+40",
    },
    {
      name: "Russia",
      flag: "🇷🇺",
      code: "RU",
      dial_code: "+7",
    },
    {
      name: "Rwanda",
      flag: "🇷🇼",
      code: "RW",
      dial_code: "+250",
    },
    {
      name: "Reunion",
      flag: "🇷🇪",
      code: "RE",
      dial_code: "+262",
    },
    {
      name: "Saint Barthelemy",
      flag: "🇧🇱",
      code: "BL",
      dial_code: "+590",
    },
    {
      name: "Saint Helena, Ascension and Tristan Da Cunha",
      flag: "🇸🇭",
      code: "SH",
      dial_code: "+290",
    },
    {
      name: "Saint Kitts and Nevis",
      flag: "🇰🇳",
      code: "KN",
      dial_code: "+1869",
    },
    {
      name: "Saint Lucia",
      flag: "🇱🇨",
      code: "LC",
      dial_code: "+1758",
    },
    {
      name: "Saint Martin",
      flag: "🇲🇫",
      code: "MF",
      dial_code: "+590",
    },
    {
      name: "Saint Pierre and Miquelon",
      flag: "🇵🇲",
      code: "PM",
      dial_code: "+508",
    },
    {
      name: "Saint Vincent and the Grenadines",
      flag: "🇻🇨",
      code: "VC",
      dial_code: "+1784",
    },
    {
      name: "Samoa",
      flag: "🇼🇸",
      code: "WS",
      dial_code: "+685",
    },
    {
      name: "San Marino",
      flag: "🇸🇲",
      code: "SM",
      dial_code: "+378",
    },
    {
      name: "Sao Tome and Principe",
      flag: "🇸🇹",
      code: "ST",
      dial_code: "+239",
    },
    {
      name: "Saudi Arabia",
      flag: "🇸🇦",
      code: "SA",
      dial_code: "+966",
    },
    {
      name: "Senegal",
      flag: "🇸🇳",
      code: "SN",
      dial_code: "+221",
    },
    {
      name: "Serbia",
      flag: "🇷🇸",
      code: "RS",
      dial_code: "+381",
    },
    {
      name: "Seychelles",
      flag: "🇸🇨",
      code: "SC",
      dial_code: "+248",
    },
    {
      name: "Sierra Leone",
      flag: "🇸🇱",
      code: "SL",
      dial_code: "+232",
    },
    {
      name: "Singapore",
      flag: "🇸🇬",
      code: "SG",
      dial_code: "+65",
    },
    {
      name: "Slovakia",
      flag: "🇸🇰",
      code: "SK",
      dial_code: "+421",
    },
    {
      name: "Slovenia",
      flag: "🇸🇮",
      code: "SI",
      dial_code: "+386",
    },
    {
      name: "Solomon Islands",
      flag: "🇸🇧",
      code: "SB",
      dial_code: "+677",
    },
    {
      name: "Somalia",
      flag: "🇸🇴",
      code: "SO",
      dial_code: "+252",
    },
    {
      name: "South Africa",
      flag: "🇿🇦",
      code: "ZA",
      dial_code: "+27",
    },
    {
      name: "South Sudan",
      flag: "🇸🇸",
      code: "SS",
      dial_code: "+211",
    },
    {
      name: "South Georgia and the South Sandwich Islands",
      flag: "🇬🇸",
      code: "GS",
      dial_code: "+500",
    },
    {
      name: "Spain",
      flag: "🇪🇸",
      code: "ES",
      dial_code: "+34",
    },
    {
      name: "Sri Lanka",
      flag: "🇱🇰",
      code: "LK",
      dial_code: "+94",
    },
    {
      name: "Sudan",
      flag: "🇸🇩",
      code: "SD",
      dial_code: "+249",
    },
    {
      name: "Suriname",
      flag: "🇸🇷",
      code: "SR",
      dial_code: "+597",
    },
    {
      name: "Svalbard and Jan Mayen",
      flag: "🇸🇯",
      code: "SJ",
      dial_code: "+47",
    },
    {
      name: "Swaziland",
      flag: "🇸🇿",
      code: "SZ",
      dial_code: "+268",
    },
    {
      name: "Sweden",
      flag: "🇸🇪",
      code: "SE",
      dial_code: "+46",
    },
    {
      name: "Switzerland",
      flag: "🇨🇭",
      code: "CH",
      dial_code: "+41",
    },
    {
      name: "Syrian Arab Republic",
      flag: "🇸🇾",
      code: "SY",
      dial_code: "+963",
    },
    {
      name: "Taiwan",
      flag: "🇹🇼",
      code: "TW",
      dial_code: "+886",
    },
    {
      name: "Tajikistan",
      flag: "🇹🇯",
      code: "TJ",
      dial_code: "+992",
    },
    {
      name: "Tanzania, United Republic of Tanzania",
      flag: "🇹🇿",
      code: "TZ",
      dial_code: "+255",
    },
    {
      name: "Thailand",
      flag: "🇹🇭",
      code: "TH",
      dial_code: "+66",
    },
    {
      name: "Timor-Leste",
      flag: "🇹🇱",
      code: "TL",
      dial_code: "+670",
    },
    {
      name: "Togo",
      flag: "🇹🇬",
      code: "TG",
      dial_code: "+228",
    },
    {
      name: "Tokelau",
      flag: "🇹🇰",
      code: "TK",
      dial_code: "+690",
    },
    {
      name: "Tonga",
      flag: "🇹🇴",
      code: "TO",
      dial_code: "+676",
    },
    {
      name: "Trinidad and Tobago",
      flag: "🇹🇹",
      code: "TT",
      dial_code: "+1868",
    },
    {
      name: "Tunisia",
      flag: "🇹🇳",
      code: "TN",
      dial_code: "+216",
    },
    {
      name: "Turkey",
      flag: "🇹🇷",
      code: "TR",
      dial_code: "+90",
    },
    {
      name: "Turkmenistan",
      flag: "🇹🇲",
      code: "TM",
      dial_code: "+993",
    },
    {
      name: "Turks and Caicos Islands",
      flag: "🇹🇨",
      code: "TC",
      dial_code: "+1649",
    },
    {
      name: "Tuvalu",
      flag: "🇹🇻",
      code: "TV",
      dial_code: "+688",
    },
    {
      name: "Uganda",
      flag: "🇺🇬",
      code: "UG",
      dial_code: "+256",
    },
    {
      name: "Ukraine",
      flag: "🇺🇦",
      code: "UA",
      dial_code: "+380",
    },
    {
      name: "United Arab Emirates",
      flag: "🇦🇪",
      code: "AE",
      dial_code: "+971",
    },
    {
      name: "United Kingdom",
      flag: "🇬🇧",
      code: "GB",
      dial_code: "+44",
    },
    {
      name: "United States",
      flag: "🇺🇸",
      code: "US",
      dial_code: "+1",
    },
    {
      name: "Uruguay",
      flag: "🇺🇾",
      code: "UY",
      dial_code: "+598",
    },
    {
      name: "Uzbekistan",
      flag: "🇺🇿",
      code: "UZ",
      dial_code: "+998",
    },
    {
      name: "Vanuatu",
      flag: "🇻🇺",
      code: "VU",
      dial_code: "+678",
    },
    {
      name: "Venezuela, Bolivarian Republic of Venezuela",
      flag: "🇻🇪",
      code: "VE",
      dial_code: "+58",
    },
    {
      name: "Vietnam",
      flag: "🇻🇳",
      code: "VN",
      dial_code: "+84",
    },
    {
      name: "Virgin Islands, British",
      flag: "🇻🇬",
      code: "VG",
      dial_code: "+1284",
    },
    {
      name: "Virgin Islands, U.S.",
      flag: "🇻🇮",
      code: "VI",
      dial_code: "+1340",
    },
    {
      name: "Wallis and Futuna",
      flag: "🇼🇫",
      code: "WF",
      dial_code: "+681",
    },
    {
      name: "Yemen",
      flag: "🇾🇪",
      code: "YE",
      dial_code: "+967",
    },
    {
      name: "Zambia",
      flag: "🇿🇲",
      code: "ZM",
      dial_code: "+260",
    },
    {
      name: "Zimbabwe",
      flag: "🇿🇼",
      code: "ZW",
      dial_code: "+263",
    },
  ];

  const stateArray = [
    {
      name: "Andhra Pradesh",
      stateCode: 1,
    },
    {
      name: "Arunachal Pradesh",
      stateCode: 2,
    },
    {
      name: "Assam",
      stateCode: 3,
    },
    {
      name: "Bihar",
      stateCode: 4,
    },
    {
      name: "Chhattisgarh",
      stateCode: 5,
    },
    {
      name: "Goa",
      stateCode: 6,
    },
    {
      name: "Gujarat",
      stateCode: 7,
    },
    {
      name: "Haryana",
      stateCode: 8,
    },
    {
      name: "Himachal Pradesh",
      stateCode: 9,
    },
    {
      name: "Jharkhand",
      stateCode: 10,
    },
    {
      name: "Karnataka",
      stateCode: 11,
    },
    {
      name: "Kerala",
      stateCode: 12,
    },
    {
      name: "Madhya Pradesh",
      stateCode: 13,
    },
    {
      name: "Maharashtra",
      stateCode: 14,
    },
    {
      name: "Manipur",
      stateCode: 15,
    },
    {
      name: "Meghalaya",
      stateCode: 16,
    },
    {
      name: "Mizoram",
      stateCode: 17,
    },
    {
      name: "Nagaland",
      stateCode: 18,
    },
    {
      name: "Odisha",
      stateCode: 19,
    },
    {
      name: "Punjab",
      stateCode: 20,
    },
    {
      name: "Rajasthan",
      stateCode: 21,
    },
    {
      name: "Sikkim",
      stateCode: 22,
    },
    {
      name: "Tamil Nadu",
      stateCode: 23,
    },
    {
      name: "Telangana",
      stateCode: 24,
    },
    {
      name: "Tripura",
      stateCode: 25,
    },
    {
      name: "Uttarakhand",
      stateCode: 26,
    },
    {
      name: "Uttar Pradesh",
      stateCode: 27,
    },
    {
      name: "West Bengal",
      stateCode: 28,
    },
    {
      name: "Andaman and Nicobar Islands",
      stateCode: 29,
    },
    {
      name: "Chandigarh",
      stateCode: 30,
    },
    {
      name: "Dadra and Nagar Haveli and Daman & Diu",
      stateCode: 31,
    },
    {
      name: "New Delhi",
      stateCode: 32,
    },
    {
      name: "Jammu and Kashmir",
      stateCode: 33,
    },
    {
      name: "Ladakk",
      stateCode: 34,
    },
    {
      name: "Lakshdweep",
      stateCode: 35,
    },
    {
      name: "Puducherry",
      stateCode: 36,
    },
  ];

  const handleChangeFirstName = (event) => {
    event?.target?.value
      ? setFirstName(event.target.value)
      : setFirstName(null);
  };

  const handleChangeLastName = (event) => {
    event?.target?.value ? setLastName(event.target.value) : setLastName(null);
  };

  const handleChangeCountryCode = (event) => {
    const code = dataArray.filter((val) => val.dial_code == event.target.value);
    setCountryCode(code[0]);
  };

  const handleChangeCountryName = (event) => {
    const code = dataArray.filter((val) => val.dial_code == event.target.value);
    setCountry(code[0]);
  };

  const handleChangePhoneNumber = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      event?.target?.value
        ? setPhoneNumber(event.target.value)
        : setPhoneNumber("");
    }
  };

  const handleChangeStateName = (event) => {
    const stateCode = stateArray.filter(
      (val) => val.name == event.target.value
    );
    setState(stateCode[0]);
  };

  const handleChangeStateNameInput = (event) => {
    const re = /^[A-Za-z]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setStateInput(event.target.value);
    }
  };

  const handleChangeCityName = (event) => {
    const { value } = event.target;
    const re = /^[A-Za-z]+$/;
    if (value === "" || re.test(value)) {
      event?.target?.value ? setCity(event.target.value) : setCity(null);
    }
  };

  const handleChangePinCode = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      event?.target?.value ? setPinCode(event.target.value) : setPinCode("");
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  const [error, setError] = useState(false);
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);

  const validation = () => {
    if (gstpagemanage.length > 0) {
      if (firstName?.length == "" || firstName?.length == null) {
        setError(true);
        return false;
      }
      if (lastName?.length == "" || lastName?.length == null) {
        setError(true);
        return false;
      }
      if (phoneNumber?.length != 10) {
        setError(true);
        return false;
      }
      if (
        countryCode?.dial_code.length == "" ||
        countryCode?.dial_code.length == null
      ) {
        setError(true);
        return false;
      }

      if (country?.name.length == "" || country?.name.length == null) {
        setError(true);
        return false;
      }

      if (country?.name == "India") {
        if (state?.name.length == "" || state?.name.length == null) {
          setError(true);
          return false;
        }
      } else {
        if (stateInput?.length == "" || stateInput?.length == null) {
          setError(true);
          return false;
        }
      }
      if (city?.length == "" || city?.length == null) {
        setError(true);
        return false;
      }

      if (pinCode?.length != 6) {
        setError(true);
        return false;
      }
      setError(false);
      return true;
    } else {
      if (phoneNumber?.length > 1) {
        if (phoneNumber?.length != 10) {
          setError1(true);
          return false;
        }
      }
      if (pinCode?.length > 1) {
        if (pinCode?.length != 6) {
          setError2(true);
          return false;
        }
      }
      setError1(false);
      setError2(false);
      return true;
    }
  };
  const areDetailsChanged = () => {
    const currentUserData = [
      firstName,
      lastName,
      email,
      phoneNumber,
      pinCode,
      city,
      stateInput,
      country.name,
      countryCode.dial_code,
    ];

    for (var value of currentUserData) {
      if (!Object.values(userDetails.user).includes(value)) {
        return true;
      }
    }

    return false;
  };

  const updateUser = async () => {
    const valid = validation();
    if (valid) {
      if (saveImageFile() && areDetailsChanged()) {
        try {
          const Auth = await localStorage.getItem("userToken");
          const params = {
            ...currentUser,
            firstName: firstName,
            lastName: lastName,
            email: email,
            countryCode: countryCode.dial_code,
            phoneNumber: phoneNumber,
            country: country.name,
            // state: state.name,
            state: country.name == "India" ? state.name : stateInput,
            city: city,
            pinCode: pinCode,
          };
          // console.log('params: ', params);
          setIsLoading(true);
          if (Auth == "01") {
            const { data } = await apiCall(
              "PUT",
              ApiEndPoint.USERUPDATE,
              params
            );

            if (data?.email) {
              setFirstName(data.firstName);
              setLastName(data.lastName);
              setEmail(data.email);
              const code = dataArray.filter(
                (val) => val.dial_code == data?.countryCode
              );
              setCountryCode(code[0]);
              setPhoneNumber(data.phoneNumber);
              const name = dataArray.filter((val) => val.name == data?.country);
              setCountry(name[0]);
              setState(name[0]);
              setCity(data.cityName);
              setPinCode(data.pinCode);
              setImageUrl(data.imageUrl);
              setIsLoading(false);
              getUserDetails();

              gstpagemanage == "subscription" && setGstpagemanage("user");
              gstpagemanage == "subscription" &&
                navigate("/subscriptionPlanList");

              // gstpagemanage == "" && navigate('/');
              toast.success("Profile Updated Successfully", {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else {
              setIsLoading(false);
            }
          }
        } catch (err) {
          setIsLoading(false);
          console.log("err: ", err);
        }
      }
    }
  };

  async function getCurrentUser() {
    try {
      setIsLoading(true);
      const Auth = await localStorage.getItem("userToken");
      if (Auth == "01") {
        const { data } = await apiCall("GET", ApiEndPoint.USERDETAILS);

        if (data) {
          setCurrentUser(data);
          setFirstName(data.firstName);
          setLastName(data.lastName);
          setEmail(data.email);
          setPhoneNumber(data.phoneNumber);
          const name = dataArray.filter((val) => val.name == data?.country);
          name.length > 0
            ? setCountry(name[0])
            : setCountry({
                name: "India",
                flag: "🇮🇳",
                code: "IN",
                dial_code: "+91",
              });
          setStateInput(data.state);
          const stateName = stateArray.filter((val) => val.name == data?.state);
          stateName.length > 0
            ? setState(stateName[0])
            : setState({
                name: "Gujarat",
                stateCode: 7,
              });

          setCity(data.city);
          setPinCode(data.pinCode);
          setImageUrl(data.imageUrl);
          const code = dataArray.filter(
            (val) => val.dial_code == data?.countryCode
          );
          if (code.length > 0) {
            setCountryCode(code[0]);
          }
          setIsLoading(false);
          getUserDetails();
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  async function getUserDetails() {
    try {
      const Auth = await localStorage.getItem("userToken");
      if (Auth == "01") {
        const { data } = await apiCall(
          "GET",
          ApiEndPoint.GETSUBCRIPTIONDETAILS
        );
        if (data) {
          setUserDetails(data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const countryCodeFun = (item) => {
    const code = dataArray.filter((val) => val.dial_code == item.key);
    setCountryCode(code[0]);
  };
  const [reload, setReload] = useState(true);
  const countryNameFun = (item) => {
    const name = dataArray.filter((val) => val.name == item.key);
    setCountry(name[0]);
    setStateInput("");
    setCity("");
    setPinCode("");
    // setReload(true)
  };

  const stateNameFun = (item) => {
    const name = stateArray.filter((val) => val.name == item.key);
    setState(name[0]);
  };

  const handleImageDrop = (acceptedFiles) => {
    const imageFile = acceptedFiles[0];
    setSelectedImageFile(imageFile);
    setSelectedImage(URL.createObjectURL(imageFile));
    closeModal();
  };

  const handleTakePhoto = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.capture = "camera";
    input.addEventListener("change", (event) => {
      const imageFile = event.target.files[0];
      setSelectedImageFile(imageFile);
      setSelectedImage(URL.createObjectURL(imageFile));
      closeModal();
    });
    input.click();
  };

  const handleRemovePicture = () => {
    removeProfileImage();
  };

  const saveImageFile = async () => {
    try {
      if (!selectedImageFile) {
        return true;
      }

      const formData = new FormData();
      formData.append("image", selectedImageFile);
      setIsLoading(true);
      const response = await apiCall(
        "POST",
        ApiEndPoint.UPDATEUSERPROFILEIMG,
        formData,
        { "Content-Type": "multipart/form-data" }
      );

      if (response.status === 200) {
        setSelectedImageFile(null);
        setSelectedImage(null);
        setIsLoading(false);
        setImageUrl(response.data.user.imageUrl);

        getUserDetails();

        !areDetailsChanged() && toast.success("Image uploaded successfully.");
        return true;
      } else {
        setSelectedImageFile(null);
        setSelectedImage(null);
        setIsLoading(false);
        toast.error("Image upload failed");
        return false;
      }
    } catch (error) {
      toast.error("Error uploading image");
      setIsLoading(false);
      return false;
    }
  };
  const removeProfileImage = async () => {
    try {
      if (!imageUrl) {
        return;
      }

      const formData = new FormData();
      formData.append("image", selectedImageFile);

      const response = await apiCall(
        "DELETE",
        ApiEndPoint.REMOVEPROFILEPICTURE
      );

      if (response.status === 200) {
        getCurrentUser();
        toast.success("Image Removed successfully.");
        setIsLoading(false);

        closeModal();
      } else {
        setSelectedImageFile(null);
        setSelectedImage(null);
        setIsLoading(false);

        toast.error("Could not remove the image, Try Again");
      }
    } catch (error) {
      setIsLoading(false);

      toast.error("Error removing image");
    }
  };

  return (
    <section className="editProfile">
      <div class="d-flex m-hide min-h header-jc-sp-c">
        <div class="d-flex">
          <img
            onClick={() => navigate(-1)}
            src={AllImages?.YellowArrow}
            alt="Back Arrow"
            title="Back Arrow"
            class="notification-icon"
          />
          <p class="mob-header-title">Edit Profile</p>
        </div>
        <div>
          {/* <p class="header-create-btn">Edit</p> */}
          <div className="saveCancelBtn">
            <button className="button btn cancel" onClick={() => navigate(-1)}>
              <p class="title">Cancel</p>
            </button>
            <button
              className={
                isLoading ? "button btn saveDisabled" : `button btn save`
              }
              disabled={isLoading}
              onClick={() => updateUser()}
            >
              <p class="title">Save</p>
            </button>
          </div>
        </div>
      </div>
      <div className="profileInner">
        <div className="subscriptionPlanListInner">
          <div className="second">
            <div className="userImage">
              <img
                className="user"
                src={
                  selectedImage
                    ? selectedImage
                    : imageUrl
                    ? imageUrl
                    : AllImages.UserImage
                }
                alt="User Image"
                title="User image"
              ></img>
              <img
                className="editIcon"
                src={AllImages?.EditIconOrange}
                onClick={() => openModal()}
                alt="Edit"
                title="Edit"
              ></img>
            </div>
            {/* <p className="historyTitle">Subscription History</p> */}
            <form>
              <div className="SubscriptionCard">
                <div className="history">
                  <div className="left">
                    <p className="title">First Name</p>
                    <input
                      type="text"
                      id="fname"
                      placeholder="First Name"
                      name="firstName"
                      value={firstName}
                      required
                      onChange={handleChangeFirstName}
                    />
                  </div>
                  <div className="right">
                    <p className="title">Last Name</p>
                    <input
                      type="text"
                      id="lname"
                      placeholder="Last Name"
                      name="lastName"
                      value={lastName}
                      required
                      onChange={handleChangeLastName}
                    />
                  </div>
                </div>
                <div className="history mobFull">
                  <div className="left">
                    <p className="title">Email ID</p>
                    <input
                      type="email"
                      id="email"
                      placeholder="email"
                      name="email"
                      value={email}
                      required
                      // onChange={handleChangeEmail}
                      disabled
                    />
                  </div>
                  <div className="right">
                    <div className="history mobNo">
                      <div className="left">
                        <p className="title">Code</p>
                        {/* <select name="code" id="code">
                          {dataArray?.map((item) => (
                            <option value={item.dial_code}> {item.flag} ({item.name}) {item.dial_code} </option>
                          ))}
                        </select> */}
                        <Autocomplete
                          id="country-select-demo"
                          options={dataArray}
                          autoHighlight
                          value={countryCode}
                          // defaultValue={data[0]}
                          getOptionLabel={(option) => option.dial_code}
                          // inputValue={countryCode}
                          renderOption={(props, option, index) => (
                            <div
                              key={index}
                              component="li"
                              // onClick={()=>{alert(option)}}
                              sx={{
                                "& > img": { mr: 2, flexShrink: 0 },
                              }}
                              {...props}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() => countryCodeFun(props)}
                              >
                                <img
                                  loading="lazy"
                                  width="20"
                                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                  alt=""
                                />
                                <p style={{ paddingLeft: 5 }}>
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      marginRight: 5,
                                    }}
                                  >
                                    ({option.name})
                                  </span>
                                  {option.dial_code}
                                </p>
                              </div>
                            </div>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // label="Choose a country"
                              value={countryCode}
                              onChange={handleChangeCountryCode}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="right">
                        <p className="title">Mobile Number</p>
                        <input
                          type="text"
                          id="mobNumber"
                          name="phoneNumber"
                          value={phoneNumber}
                          maxlength="10"
                          pattern="^[0-9]*[.,]?[0-9]*$"
                          onChange={handleChangePhoneNumber}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="history">
                  <div className="left">
                    <p className="title">Country</p>
                    {/* <select name="country" id="country">
                      <option value="volvo">India</option>
                      <option value="saab">America</option>
                      <option value="opel">Russia</option>
                      <option value="audi">Shri Lannka</option>
                    </select> */}
                    <Autocomplete
                      id="country-select-demo"
                      options={dataArray}
                      autoHighlight
                      value={country}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option, index) => (
                        <div
                          key={index}
                          component="li"
                          // onClick={()=>{alert(option)}}
                          sx={{
                            "& > img": { mr: 2, flexShrink: 0 },
                          }}
                          {...props}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                            }}
                            onClick={() => countryNameFun(props)}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              alt=""
                            />
                            <p style={{ paddingLeft: 5 }}>
                              <span
                                style={{
                                  marginLeft: 5,
                                  marginRight: 5,
                                }}
                              >
                                {option.name}
                              </span>
                            </p>
                          </div>
                        </div>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={country}
                          onChange={handleChangeCountryName}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </div>
                  {country?.name == "India" ? (
                    <div className="right">
                      <p className="title">State</p>
                      {/* <select name="country" id="country">
                        <option value="volvo">India</option>
                        <option value="saab">America</option>
                        <option value="opel">Russia</option>
                        <option value="audi">Shri Lannka</option>
                      </select> */}
                      <Autocomplete
                        id="state-select-demo"
                        options={stateArray}
                        autoHighlight
                        value={state}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option, index) => (
                          <div
                            key={index}
                            component="li"
                            // onClick={()=>{alert(option)}}
                            sx={{
                              "& > img": { mr: 2, flexShrink: 0 },
                            }}
                            {...props}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                              }}
                              onClick={() => stateNameFun(props)}
                            >
                              {/* <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                    alt=""
                                  /> */}
                              <p style={{ paddingLeft: 5 }}>
                                <span
                                  style={{
                                    marginLeft: 5,
                                    marginRight: 5,
                                  }}
                                >
                                  {option.name}
                                </span>
                              </p>
                            </div>
                          </div>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={state}
                            onChange={handleChangeStateName}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    </div>
                  ) : (
                    <div className="right">
                      <p className="title">State</p>
                      {/* <select name="country" id="country">
                        <option value="volvo">India</option>
                        <option value="saab">America</option>
                        <option value="opel">Russia</option>
                        <option value="audi">Shri Lannka</option>
                      </select> */}
                      <input
                        type="text"
                        id="stateName"
                        name="stateName"
                        placeholder="State Name"
                        value={stateInput}
                        onChange={handleChangeStateNameInput}
                      />
                    </div>
                  )}
                </div>
                <div className="history">
                  <div className="left">
                    <p className="title">City</p>
                    <input
                      type="text"
                      id="city"
                      placeholder="City Name"
                      name="cityName"
                      value={city}
                      onChange={handleChangeCityName}
                    />
                  </div>
                  <div className="right">
                    <p className="title">Pincode</p>
                    <input
                      type="text"
                      id="pincode"
                      placeholder="Pin Code"
                      name="pinCode"
                      value={pinCode}
                      maxlength="6"
                      onChange={handleChangePinCode}
                    />
                  </div>
                </div>

                <div className="saveCancelBtn">
                  <button
                    className="button btn cancel"
                    onClick={() => navigate(-1)}
                  >
                    <p class="title">Cancel</p>
                  </button>
                  <button
                    className={
                      isLoading ? "button btn saveDisabled" : `button btn save`
                    }
                    disabled={isLoading}
                    onClick={() => updateUser()}
                  >
                    <p class="title">Save</p>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {isOpen && (
        <Dialog
          open={openModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent className="editUserImage">
            <div className="modal-msg-content">
              <span className="modal-close-button" onClick={() => closeModal()}>
                &times;
              </span>
            </div>
            <div className="userImage">
              <img
                className="user"
                src={
                  selectedImage
                    ? selectedImage
                    : imageUrl
                    ? imageUrl
                    : AllImages.UserImage
                }
                alt="User Image"
                title="User Image"
              />
            </div>

            <div className="options">
              {/* <div class="optionsInner">
                <img className='user' src={AllImages?.Library} alt="Library" title="Library"></img>
                <p>Choose from library</p>
              </div> */}
              <div className="">
                <Dropzone onDrop={handleImageDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} className="dropzone optionsInner">
                      <input {...getInputProps()} />
                      <img
                        className="user"
                        src={AllImages.Library}
                        alt="Library"
                        title="Library"
                      />
                      <p>Choose from library</p>
                    </div>
                  )}
                </Dropzone>
              </div>
              {/* <img className='user' src={AllImages?.Photo} alt="Photo" title="Photo"></img>
                <p>Take photo</p> */}
              <div className="optionsInner" onClick={handleTakePhoto}>
                <img
                  className="user"
                  src={AllImages.Photo}
                  alt="Photo"
                  title="Photo"
                />
                <p>Take photo</p>
              </div>
              <div className="optionsInner" onClick={handleRemovePicture}>
                <img
                  className="user"
                  src={AllImages.DeleteCard}
                  alt="Delete"
                  title="Delete"
                />
                <p>Remove current picture</p>
              </div>
            </div>
          </DialogContent>
          {/* <DialogActions>
            <div className="modal-action-content">
              <Button className="modal-yes-btn">{"Yes"}</Button>
              <Button className="modal-dont-delete-btn" onClick={closeModal}>{"DON�T delete"}</Button>

            </div>
          </DialogActions> */}
        </Dialog>
      )}
    </section>
  );
}
export default EditProfile;
