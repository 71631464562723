import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./style.css";
import { AllImages } from "../../../utils/constants";

function AboutUs() {
  const location = useLocation();
  return (
    <section class="aboutUs-page">
      <h1 class="mainTitle">About Us</h1>
      <div class="aboutUs-img-div">
        <img
          className="AbousImage d-hide"
          src={AllImages?.AboutUS}
          alt="Banner"
          title="Banner"
        />
        <img
          className="AbousImage m-hide"
          src={AllImages?.AboutUS}
          alt="Banner"
          title="Banner"
        />
      </div>
      <div class="firstOuter">
        <div class="first">
          <div class="about-us-desc">
            <p class="description">
              Satshrut is a digital platform that is designed, curated,
              developed, and supported by
            </p>
            <p class="description-bold">
              Shrimad Rajchandra Aatma Tatva Research Centre [SRATRC]
            </p>
            <p class="description">
              To help with Mumukshu's Parmarth Purusharth. Satshrut, a digital
              platform, will host various digital items such as video Satsangs,
              Audio Satsangs, Bhakti pad, eBooks and many similar items. Our
              objective is to provide all possible digital items, avenues,
              platform and support to help with Mumukshu's Purusharth to
              understand Parma Pujya Shrimad Rajchandra and Parma Pujya
              Premacharyaji's preachings.
            </p>
            <p class="description">
              You can write to us at ss@vitraagvigyaan.org to provide your
              feedback , suggestion or to join us on a great journey to build
              this platform.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
export default AboutUs;
