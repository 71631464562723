import React from 'react';
import './NoDataAvailable.css'; // Import the CSS file
import { AllImages } from '../../../utils/constants';


const NoDataAvailable = () => {
  return (
    <div className="no-data">
      <img src={AllImages.NoDataAvailable} alt="No Data" className="no-data-image" />
    </div>
  );
};

export default NoDataAvailable;