import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import './style.css';

function SurpriseMe() {
  const location = useLocation();
  return (
    <section>
     <p>SurpriseMe</p>
    </section>
  );
}
export default SurpriseMe;
