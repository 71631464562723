import React from 'react'
import loader from '../../assets/images/logo.png'
export default ({ active }) => {
    return (
        <div style={{
            backgroundColor: '#fff',
            zIndex: 20,
            position: 'absolute',
            width: '100%',
            height: '100vh',
            textAlign: 'center',
            display: 'flex',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <img src={loader} alt="loading..." className='loader-size'/>
        </div>
    )
}



// style={{
//     position: 'absolute',
//     top: '41%',
//     left: '50%'
// }}