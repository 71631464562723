import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import './style.css';
import { GLOBAL_URL, AllImages, RemoveDuplicates } from '../../../utils/constants'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { setDefaultHeader, apiCall } from "../../../utils/httpClient";
import ApiEndPoint from "../../../utils/apiEndPoints";
import { toast } from "react-toastify";

function ContactUs() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const [contactDetails, setContactDetails] = useState({
    'name': '',
    'email': '',
    'phone': '',
    'message': ''
  })
  const [error, setError] = useState(false)
  const [emailCheck, setEmailCheck] = useState(true)
  const [validEmail, setValidEmail] = useState(false)

  const validation = () => {
    if (contactDetails.name?.length == "" || contactDetails.name?.length == null) {
      setError(true);
      return false;
    }
    if (contactDetails.email?.length == "" || contactDetails.email?.length == null) {
      setError(true);
      return false;
    }

    // var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const validRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!contactDetails.email.match(validRegex)) {
      setValidEmail(true)
      return false;
    } else {
      setValidEmail(false)
    }
    // if (emailCheck) {
    //   return false;
    // }
    if (contactDetails.phone?.length != 10) {
      setError(true);
      return false;
    }
    if (contactDetails.message?.length == "" || contactDetails.message?.length == null) {
      setError(true);
      return false;
    }
    setError(false);
    return true;
  };

  const handleChangePhoneNumber = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      event?.target?.value
        ? setContactDetails({
          ...contactDetails,
          phone: event.target.value
        })
        : setContactDetails({
          ...contactDetails,
          phone: ''
        });
    }
  };


  function isValidEmail(email) {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return regex.test(email);
  }

  const handleChangeEmail = (event) => {
    // if (!isValidEmail(event.target.value)) {
    //     setEmailCheck(false);
    // } else {
    //   setEmailCheck(true);
    // }

    event?.target?.value
      ? setContactDetails({
        ...contactDetails,
        email: event.target.value
      })
      : setContactDetails({
        ...contactDetails,
        email: ''
      });
    setValidEmail(false)
  };


  async function sendEmailFun() {
    const valid = validation()
    if (valid) {
      const params = {
        "name": contactDetails.name,
        "email": contactDetails.email,
        "massage": contactDetails.message,
        'contactDetails': contactDetails.phone
      };
      const { data } = await apiCall(
        "POST",
        ApiEndPoint.MAILSEND,
        params
      );
      if (data) {
        openModal()
        setContactDetails({
          "name": '',
          "email": '',
          "message": '',
          'phone': ''
        })
      }
    }
  }


  const openModal = () => {
    setIsOpen(true);
    setTimeout(() => {
      setIsOpen(false);
      navigate('/')
    }, 2000);
  };

  const closeModal = () => {
    setIsOpen(false);
  };


  return (
    <section className="contactUs">
      <h1 class="mainTitle">Contact Us</h1>
      <div className="contactUsInner">
        <div className="box">
          <p className="dropMsgTitle">Drop us your Message</p>
          <form action="/action_page.php">
            <div className="field">
              <p className="title">Name</p>
              <input type="text"
                id="name"
                placeholder="Name"
                value={contactDetails.name}
                onChange={(e) => setContactDetails({
                  ...contactDetails,
                  name: e.target.value
                })}
              />
              {error ? contactDetails?.name?.length > 0 ? null : <p style={{ color: "red", textAlign: "left" }}>
                Please Enter Name
              </p> : null}
            </div>
            <div className="field">
              <p className="title">Email ID</p>
              <input
                type="email"
                id="email"
                placeholder="Email"
                onChange={(e) => handleChangeEmail(e)}
                value={contactDetails.email}
                pattern=".+@globex\.com"
                size="30"
              />
              {error ? contactDetails?.email?.length > 0 ? null : <p style={{ color: "red", textAlign: "left" }}>
                Please Enter Email
              </p> : null}

              {validEmail && <p style={{ color: "red", textAlign: "left" }}>
                Please Enter Valid Email
              </p>}
            </div>
            <div className="field">
              <p className="title">Mobile Number </p>
              <input type="text"
                placeholder="Mobile Number"
                value={contactDetails.phone}
                maxlength='10'
                pattern="^[0-9]*[.,]?[0-9]*$"
                onChange={(e) => handleChangePhoneNumber(e)}
              />
              {error ? contactDetails?.phone?.length == 10 ? null : <p style={{ color: "red", textAlign: "left" }}>
                Please Enter 10 Digit Phone Number
              </p> : null}
            </div>
            <div className="field">
              <p className="title">Message</p>
              <textarea type="text"
                placeholder="Message"
                rows="5"
                value={contactDetails?.message}
                onChange={(e) => setContactDetails({
                  ...contactDetails,
                  message: e.target.value
                })}
              ></textarea>
              {error ? contactDetails?.message?.length > 0 ? null : <p style={{ color: "red", textAlign: "left" }}>
                Please Enter Message
              </p> : null}
            </div>

          </form>
          <div className="submitBtn">
            <button className="button btn save">
              <p class="title" onClick={() => sendEmailFun()}>Submit</p>
            </button>
          </div>

          <div class="writeUs">
            <p className="writeUsText">Or write us a mail at</p>
            <a className="link" href="mailto:ss@vitraagvigyaan.org">ss@vitraagvigyaan.org</a>
          </div>

        </div>
        <div className="locateUs">
          <p className="locateTitle">Locate Us</p>
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d414717.180576684!2d73.11412970326934!3d18.685127897251117!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be805c062ac4f8f%3A0xca8eacdc1275ab05!2sShrimad%20Rajchandra%20Aatma%20Tatva%20Research%20Centre!5e0!3m2!1sen!2sin!4v1690368381939!5m2!1sen!2sin" width="100%" height="450" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>

      {isOpen &&
        <Dialog
          open={openModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent className="contactUsModal">
            <div className="modal-msg-content">
              <p className="desc">Your inquiry has been submitted successfully!</p>
              <span className="modal-close-button" onClick={() => closeModal()}>&times;</span>
            </div>
            <div className="userImage">
              <img className='FormSubmit' src={AllImages?.FormSubmit} alt="Submit" title="Submit"></img>
            </div>

          </DialogContent>
        </Dialog>


      }

    </section>

  );
}
export default ContactUs;
